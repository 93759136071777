<template>
  <div rg-confirm-result>
    <div class="content">
      <FontIcon :class="{'error' : !type}" :name="icon" />
      <p v-html="message"></p>
      <GpDescription :message="subMessage" />
    </div>
  </div>
</template>

<script>
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
export default {
  name: 'ConfirmResult',
  components: { GpDescription, GpButton, FontIcon },
  props: {
    message: { type: String, default: null },
    subMessage: { type: String, default: null },
    type: { type: Boolean, default: null }
  },
  computed: {
    icon() {
      return this.type ? 'check-over-circle-regular' : 'exclamation-circle-regular';
      // info-circle-line
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-confirm-result] {
  [font-icon] {.fs(36); .c(@gp-green);}
  .content {.flex(); .flex-dc(); .justify-center(); .items-center(); .gap(15);
    .error {.c(#ED4646);}
    > p {.c(black); .fs(16); .pv(10); text-align: center;
      > a {.c(@c-link);}
    }
    [gp-description] {.p(0); }
  }
  .body {

  }
  .footer {.flex(); .gap(10);}
}
</style>
