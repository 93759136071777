export const apiErrorCode = {
  AUTHORIZATION_DENY: 'AUTHORIZATION_DENY',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
  INVALID_TOKEN: 'INVALID_TOKEN',
  AUTH_TOKEN_EXPIRED: 'AUTH_TOKEN_EXPIRED',
  ACCESS_DENIED: 'ACCESS_DENIED',
  ACCESS_DENIED_UNRECOGNIZED_SERVICE_MODE: 'ACCESS_DENIED_UNRECOGNIZED_SERVICE_MODE',
  ACCESS_DENIED_AGENT_HAS_NO_PERMISSION: 'ACCESS_DENIED_AGENT_HAS_NO_PERMISSION',
  ACCOUNT_LOGIN_LIMIT_ACCESS: 'ACCOUNT_LOGIN_LIMIT_ACCESS',
  ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
  ACCOUNT_SUSPENDED: 'ACCOUNT_SUSPENDED',
  ACCOUNT_LOGIN_ATTEMPS_FAILED_LOCKED: 'ACCOUNT_LOGIN_ATTEMPS_FAILED_LOCKED',
  ACCOUNT_LOGIN_REMAIN_LIMIT: 'ACCOUNT_LOGIN_REMAIN_LIMIT',
  ACCOUNT_LOGIN_REMAIN_LIMIT_OVER_LOCKED: 'ACCOUNT_LOGIN_REMAIN_LIMIT_OVER_LOCKED',
  ACCOUNT_UNVERIFIED: 'ACCOUNT_UNVERIFIED',
  ADD_KYC_DOCUMENT_FAILED: 'ADD_KYC_DOCUMENT_FAILED',
  AGENT_NOT_FOUND: 'AGENT_NOT_FOUND',
  ALREADY_VERIFIED_EMAIL: 'ALREADY_VERIFIED_EMAIL',
  ALREADY_HAVE_RG_PENDING_LIMIT: 'ALREADY_HAVE_RG_PENDING_LIMIT',
  AISG_SELF_EXCLUDED: 'AISG_SELF_EXCLUDED',
  AISG_REGISTRATION_INVALID_INFORMATION: 'AISG_REGISTRATION_INVALID_INFORMATION',
  AISG_REGISTRATION_INVALID_OPERATION: 'AISG_REGISTRATION_INVALID_OPERATION',
  AISG_REGISTRATION_NOT_VERIFIED: 'AISG_REGISTRATION_NOT_VERIFIED',
  AISG_REGISTRATION_INVALID_HID: 'AISG_REGISTRATION_INVALID_HID',
  AISG_REGISTRATION_INTERNAL_ERROR: 'AISG_REGISTRATION_INTERNAL_ERROR',
  AISG_FAILED_SELF_EXCLUSION_CHECK: 'AISG_FAILED_SELF_EXCLUSION_CHECK',
  AISG_UPDATE_INFORMATION_FAILED: 'AISG_UPDATE_INFORMATION_FAILED',
  AVAILABLE_EMAIL_ONLY: 'AVAILABLE_EMAIL_ONLY',
  ALREADY_VERIFIED_MOBILE: 'ALREADY_VERIFIED_MOBILE',
  BLOCKEDLISTACC_CHECK: 'BLOCKEDLISTACC_CHECK',
  CLOSED: 'CLOSED',
  PLAYER_ALREADY_EXISTS: 'PLAYER_ALREADY_EXISTS',
  NICKNAME_ALREADY_EXISTS: 'NICKNAME_ALREADY_EXISTS',
  USERNAME_ALREADY_EXISTS: 'USERNAME_ALREADY_EXISTS',
  EMAIL_EXISTS: 'EMAIL_EXISTS',
  EXIST_MOBILE: 'EXIST_MOBILE',
  EXISTING_MOBILE_NUMBER: 'EXISTING_MOBILE_NUMBER',
  USER_INFO_ALREADY_EXIST: 'USER_INFO_ALREADY_EXIST',
  COMPLIANCE_CHECK: 'COMPLIANCE_CHECK',
  COMPLIANCE_CHECK_PERIOD: 'COMPLIANCE_CHECK_PERIOD',
  CRUKS_SELF_EXCLUDED: 'CRUKS_SELF_EXCLUDED',
  DELETED_ACCOUNT: 'DELETED_ACCOUNT',
  DUPLICATE_IBAN: 'DUPLICATE_IBAN',
  DEPOSIT_LIMIT_BIGGER_THAN_MAX_LIMIT: 'DEPOSIT_LIMIT_BIGGER_THAN_MAX_LIMIT',
  DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY1: 'DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY1',
  DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY7: 'DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY7',
  DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY30: 'DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY30',
  EDIT_ACCOUNT_DETAIL_FAILED: 'EDIT_ACCOUNT_DETAIL_FAILED',
  EDIT_ACCOUNT_KYC_STATUS_FAILED: 'EDIT_ACCOUNT_KYC_STATUS_FAILED',
  EMAIL_RESEND_TERM_LIMITED: 'EMAIL_RESEND_TERM_LIMITED',
  PHONE_RESEND_TERM_LIMITED: 'PHONE_RESEND_TERM_LIMITED',
  RESEND_TERM_LIMITED: 'RESEND_TERM_LIMITED',
  RESEND_LIMITED: 'RESEND_LIMITED',
  ONEPASS_NOT_FOUND: 'ONEPASS_NOT_FOUND',
  FORGOT_ONEPASS_NOT_FOUND: 'FORGOT_ONEPASS_NOT_FOUND',
  EMAIL_RESEND_LIMITED: 'EMAIL_RESEND_LIMITED',
  MOBILE_RESEND_LIMITED: 'MOBILE_RESEND_LIMITED',
  ETC: 'ETC',
  EXCEED_LOGIN_ATTEMPTS: 'EXCEED_LOGIN_ATTEMPTS',
  EXCLUDEDE_BYSERVICE: 'EXCLUDEDE_BYSERVICE',
  EXCLUSION_CHECK: 'EXCLUSION_CHECK',
  EXPIRED_VERIFICATIONCODE: 'EXPIRED_VERIFICATIONCODE',
  FAILED_SELF_EXCLUSION_CHECK: 'FAILED_SELF_EXCLUSION_CHECK',
  FAILED_SEND_EMAIL_VERIFICATION: 'FAILED_SEND_EMAIL_VERIFICATION',
  FAILED_SEND_EMAIL_VERIFICATIONCODE: 'FAILED_SEND_EMAIL_VERIFICATIONCODE',
  FAILED_WALLET_MIGRATION: 'FAILED_WALLET_MIGRATION',
  GAMSTOP_SELF_EXCLUDED: 'GAMSTOP_SELF_EXCLUDED',
  REQUIRED_TNC_AGREEMENT: 'REQUIRED_TNC_AGREEMENT',
  REQUIRED_VERIFICATION_CODE: 'REQUIRED_VERIFICATION_CODE',
  UNMATCHED_VERIFICATION_CODE: 'UNMATCHED_VERIFICATION_CODE',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  INVALID_SINGLEWALLET_STATUS_CODE: 'INVALID_SINGLEWALLET_STATUS_CODE',
  INVALID_FIRSTNAME: 'INVALID_FIRSTNAME', // personalMyInfo에서 사용
  INVALID_LASTNAME: 'INVALID_LASTNAME', // personalMyInfo에서 사용
  INVALID_FIRST_NAME: 'INVALID_FIRST_NAME',
  INVALID_LAST_NAME: 'INVALID_LAST_NAME',
  INVALID_MAIDEN_NAME: 'INVALID_MAIDEN_NAME',
  INVALID_MOBILE_NUMBER: 'INVALID_MOBILE_NUMBER',
  INVALID_PLACEOFBIRTH: 'INVALID_PLACEOFBIRTH',
  INVALID_PLACE_OF_BIRTH: 'INVALID_PLACE_OF_BIRTH',
  INVALID_DOB: 'INVALID_DOB',
  INVALID_AGE: 'INVALID_AGE',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_VERIFICATION_STATUS: 'INVALID_VERIFICATION_STATUS',
  INVALID_GENDER: 'INVALID_GENDER',
  INVALID_ADDRESS: 'INVALID_ADDRESS',
  INVALID_ADDRESS_LENGTH: 'INVALID_ADDRESS_LENGTH',
  INVALID_ADDRESS_CHARACTER: 'INVALID_ADDRESS_CHARACTER',
  INVALID_BUILDING: 'INVALID_BUILDING',
  INVALID_STATE: 'INVALID_STATE',
  INVALID_BARANGAY: 'INVALID_BARANGAY',
  INVALID_CITY: 'INVALID_CITY',
  INVALID_CITY_LENGTH: 'INVALID_CITY_LENGTH',
  INVALID_CITY_CHARACTER: 'INVALID_CITY_CHARACTER',
  INVALID_STREET_NAME: 'INVALID_STREET_NAME',
  INVALID_STREET_NUMBER: 'INVALID_STREET_NUMBER',
  INVALID_HOUSE_NUMBER: 'INVALID_HOUSE_NUMBER',
  INVALID_POSTALCODE: 'INVALID_POSTALCODE',
  INVALID_POSTAL_CODE: 'INVALID_POSTAL_CODE',
  INVALID_POSTAL_CODE_LENGTH: 'INVALID_POSTAL_CODE_LENGTH',
  INVALID_POSTAL_CODE_CHARACTER: 'INVALID_POSTAL_CODE_CHARACTER',
  INVALID_DATEOFBIRTH: 'INVALID_DATEOFBIRTH',
  INVALID_UNDERAGE: 'INVALID_UNDERAGE',
  INVALID_UNDER21AGE: 'INVALID_UNDER21AGE',
  INVALID_ALIAS: 'INVALID_ALIAS',
  INVALD_KYC_STATUS: 'INVALD_KYC_STATUS',
  INVALID_OCCUPATION: 'INVALID_OCCUPATION',
  INVALID_EMAIL_VERIFICATION_CODE: 'INVALID_EMAIL_VERIFICATION_CODE',
  INVALID_IBANCODE: 'INVALID_IBANCODE',
  INVALID_DOCUMENT: 'INVALID_DOCUMENT',
  INVALID_SWIFTBIC: 'INVALID_SWIFTBIC',
  INVALID_NATIONALITY: 'INVALID_NATIONALITY',
  INVALID_EXPECTED_MONTHLY_DEPOSIT: 'INVALID_EXPECTED_MONTHLY_DEPOSIT',
  INVALID_ANNUAL_EARNING: 'INVALID_ANNUAL_EARNING',
  INVALID_INDUSTRY_TYPE: 'INVALID_INDUSTRY_TYPE',
  INVALID_AMOUNT: 'INVALID_AMOUNT',
  INVALID_FUND_PASSWORD: 'INVALID_FUND_PASSWORD',
  INVALID_TRANSACTION_AMOUNT: 'INVALID_TRANSACTION_AMOUNT',
  INVALID_TRANSACTION_AMOUNT_NOT_ZERO: 'INVALID_TRANSACTION_AMOUNT_NOT_ZERO',
  INVALID_PASSWORD_VERIFICATION: 'INVALID_PASSWORD_VERIFICATION',
  INVALID_VERIFICATIONCODE: 'INVALID_VERIFICATIONCODE',
  INVALID_BONUS_CODE: 'INVALID_BONUS_CODE',
  INVALID_ACCESS_TYPE: 'INVALID_ACCESS_TYPE',
  INVALID_AGENT: 'INVALID_AGENT',
  INVALID_REQUEST: 'INVALID_REQUEST',
  INVALID_EMAIL_VERIFICATIONCODE: 'INVALID_EMAIL_VERIFICATIONCODE',
  INVALID_BONUSCODE: 'INVALID_BONUSCODE',
  INVALID_PASSWORD_LENGTH: 'INVALID_PASSWORD_LENGTH',
  INVALID_PASSWORD_LETTER: 'INVALID_PASSWORD_LETTER',
  INVALID_PASSWORD_PARTS_EMAIL: 'INVALID_PASSWORD_PARTS_EMAIL',
  INVALID_PASSWORD_PARTS_MOBILENUMBER: 'INVALID_PASSWORD_PARTS_MOBILENUMBER',
  INVALID_PASSWORD_PARTS_USERNAME: 'INVALID_PASSWORD_PARTS_USERNAME',
  INVALID_PASSWORD_PARTS_NAME: 'INVALID_PASSWORD_PARTS_NAME',
  INVALID_LOGIN_PASSWORD: 'INVALID_LOGIN_PASSWORD',
  INVALID_PASSWORD_ALPHABET_NUMERIC_RULE: 'INVALID_PASSWORD_ALPHABET_NUMERIC_RULE',
  INVALID_PLAYER: 'INVALID_PLAYER',
  INVALID_DAY1_DEPOSIT_LIMIT: 'INVALID_DAY1_DEPOSIT_LIMIT',
  INVALID_DAY7_DEPOSIT_LIMIT: 'INVALID_DAY7_DEPOSIT_LIMIT',
  INVALID_WALLET_SERVICE_RESPONSE: 'INVALID_WALLET_SERVICE_RESPONSE',
  KYC_UPLOAD_REQUIRED: 'KYC_UPLOAD_REQUIRED',
  KYC_MANUAL_UPLOAD_REQUIRED: 'KYC_MANUAL_UPLOAD_REQUIRED',
  LOGIN_COUNT_LIMIT_REACHED: 'LOGIN_COUNT_LIMIT_REACHED',
  LOGIN_TIME_LIMIT_REACHED: 'LOGIN_TIME_LIMIT_REACHED',
  LOCK_BALANCE: 'LOCK_BALANCE',
  LOSS_LIMIT_NUMBER_OF_CHANGES_EXCEEDED: 'LOSS_LIMIT_NUMBER_OF_CHANGES_EXCEEDED',
  LUGAS_INTERNAL_ERROR: 'LUGAS_INTERNAL_ERROR',
  MOBILE_NOT_FOUND: 'MOBILE_NOT_FOUND',
  MULTIACC_CHECK: 'MULTIACC_CHECK',
  MOVE_TICKET_PROCESSING: 'MOVE_TICKET_PROCESSING',
  NOT_ENOUGH_BALANCE: 'NOT_ENOUGH_BALANCE',
  NEGATIVE_AMOUNT_NOT_ALLOWED: 'NEGATIVE_AMOUNT_NOT_ALLOWED',
  NEGATIVE_TIME_DURATION_NOT_ALLOWED: 'NEGATIVE_TIME_DURATION_NOT_ALLOWED',
  NEGATIVE_TIME_INTERVAL_NOT_ALLOWED: 'NEGATIVE_TIME_INTERVAL_NOT_ALLOWED',
  OASIS_BAN_EXIST: 'OASIS_BAN_EXIST',
  OASIS_CREATE_BAN_FAILED: 'OASIS_CREATE_BAN_FAILED',
  REQUEST_FAILED: 'REQUEST_FAILED',
  RE_LOGIN_TIME_LIMIT_REACHED: 'RE_LOGIN_TIME_LIMIT_REACHED',
  REJECT_FROM_BGC: 'REJECT_FROM_BGC',
  RESPONSIBLE_GAMING: 'RESPONSIBLE_GAMING',
  RESTRICTED_EMAIL_ADDRESS: 'RESTRICTED_EMAIL_ADDRESS',
  REQUIRE_CHANGE_PASSWORD: 'REQUIRE_CHANGE_PASSWORD',
  GGPASS_EMAIL_EMPTY: 'GGPASS_EMAIL_EMPTY',
  SECURITY: 'SECURITY',
  SECURITY_BLOCKED: 'SECURITY_BLOCKED',
  SERVICE_MAINTENANCE: 'SERVICE_MAINTENANCE',
  SUMSUB_REQUIRED_PERSONAL_DATA_RECHECK: 'SUMSUB_REQUIRED_PERSONAL_DATA_RECHECK',
  SUMSUB_TRY_COUNT_OVER: 'SUMSUB_TRY_COUNT_OVER',
  SUMSUB_ERROR: 'SUMSUB_ERROR',
  SUMSUB_REJECTED: 'SUMSUB_REJECTED',
  SUMSUB_INVALID_REQUEST: 'SUMSUB_INVALID_REQUEST',
  SYSTEM_CONFIGURATION_IS_NULL: 'SYSTEM_CONFIGURATION_IS_NULL',
  USER_INFO_NOT_VERIFIED: 'USER_INFO_NOT_VERIFIED',
  USER_INFO_NOT_VERIFIED_AND_RETRY: 'USER_INFO_NOT_VERIFIED_AND_RETRY',
  PARENT_AGENT_NOT_FOUND: 'PARENT_AGENT_NOT_FOUND',
  PHONE_RESEND_LIMITED: 'PHONE_RESEND_LIMITED',
  PANIC_FROM_USER: 'PANIC_FROM_USER',
  P2P_DUPLICATE_REQUEST: 'P2P_DUPLICATE_REQUEST',
  P2P_INVALID_TRANSFER_TYPE: 'P2P_INVALID_TRANSFER_TYPE',
  P2P_SOURCE_LOCKBALANCE: 'P2P_SOURCE_LOCKBALANCE',
  P2P_SOURCE_RESTRICTED_REGION: 'P2P_SOURCE_RESTRICTED_REGION',
  P2P_SOURCE_TRANSFER_EXCEEDED: 'P2P_SOURCE_TRANSFER_EXCEEDED',
  P2P_TARGET_NOT_FOUND: 'P2P_TARGET_NOT_FOUND',
  P2P_TARGET_TRANSFER_EXCEEDED: 'P2P_TARGET_TRANSFER_EXCEEDED',
  PLAYER_NICKNAME_NOT_SPECIFIED: 'PLAYER_NICKNAME_NOT_SPECIFIED',
  PLAYER_NOT_FOUND: 'PLAYER_NOT_FOUND',
  USER_INFO_NOT_VERIFIED_AND_PASS: 'USER_INFO_NOT_VERIFIED_AND_PASS',
  UNMATCHED_SUREPAY: 'UNMATCHED_SUREPAY',
  UNMATCHED_VERIFICATIONCODE: 'UNMATCHED_VERIFICATIONCODE',
  VERIFICATIONTOKEN_NOT_FOUND: 'VERIFICATIONTOKEN_NOT_FOUND',
  INVALID_RECAPTCHA: 'INVALID_RECAPTCHA',
  INVALID_RECAPTCHA_TOKEN: 'INVALID_RECAPTCHA_TOKEN',
  DOMAIN_NOT_ALLOWED: 'DOMAIN_NOT_ALLOWED',
  TELESIGN_INVALID_PHONE_NUMBER: 'TELESIGN_INVALID_PHONE_NUMBER',
  ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',
  VERIFICATIONCODE_NOT_FOUND: 'VERIFICATIONCODE_NOT_FOUND',
  TOO_MUCH_TRAFFIC: 'TOO_MUCH_TRAFFIC',
  OTHER_ERROR: 'OTHER_ERROR',
  INVALID_TRANSFER_INFO: 'INVALID_TRANSFER_INFO',
  ACCOUNT_LOCK_BALANCE: 'ACCOUNT_LOCK_BALANCE',
  ACCOUNT_CASHIER_BLOCK: 'ACCOUNT_CASHIER_BLOCK',
  NOT_SUPPORTED_REGION: 'NOT_SUPPORTED_REGION',
  DUPLICATED: 'Duplicated',
  INVALID_NATIONAL_ID: 'INVALID_NATIONAL_ID',
  INVALID_RECAPTCHA_ACTION: 'INVALID_RECAPTCHA_ACTION',
  INVALID_REFERENCE_KEY: 'INVALID_REFERENCE_KEY',
  ONEPASS_ACCOUNT_CITIZENSHIP_NOT_ALLOWED: 'ONEPASS_ACCOUNT_CITIZENSHIP_NOT_ALLOWED',
  NICKNAME_PROHIBITED: 'NICKNAME_PROHIBITED',
  AVATAR_NOT_FOUND: 'AVATAR_NOT_FOUND',
  AVATAR_SUSPENDED: 'AVATAR_SUSPENDED',
  AVATAR_PROHIBITED: 'AVATAR_PROHIBITED',
  NFT_WALLET_ALREADY_EXISTS: 'NFT_WALLET_ALREADY_EXISTS',
  NFT_WALLET_NOT_FOUND: 'NFT_WALLET_NOT_FOUND',
  ONEPASS_ACTIVE_ACCOUNT_ALREADY_EXISTS: 'ONEPASS_ACTIVE_ACCOUNT_ALREADY_EXISTS',
  ONEPASS_ACCOUNT_ALREADY_EXISTS: 'ONEPASS_ACCOUNT_ALREADY_EXISTS',
  NICKNAME_ALREADY_SET: 'NICKNAME_ALREADY_SET',
  NICKNAME_UNAVAILABLE: 'NICKNAME_UNAVAILABLE',
  USERNAME_NOT_FOUND: 'USERNAME_NOT_FOUND',
  BRAND_SITE_TEMPLATE_CONFIG_NOT_FOUND: 'BRAND_SITE_TEMPLATE_CONFIG_NOT_FOUND',
  ONEPASS_SIGNUP_INFO_NOT_FOUND: 'ONEPASS_SIGNUP_INFO_NOT_FOUND',
  INVALID_CURRENT_PASSWORD: 'INVALID_CURRENT_PASSWORD',
  PASSWORD_SAME_AS_OLD: 'PASSWORD_SAME_AS_OLD',
  INVALID_PASSWORD_PATTERN: 'INVALID_PASSWORD_PATTERN',
  PASSWORD_NOT_ALLOWED_INCLUDE_USERNAME: 'PASSWORD_NOT_ALLOWED_INCLUDE_USERNAME',
  INVALID_VERIFICATION_CODE: 'INVALID_VERIFICATION_CODE',
  USED_VERIFICATION_CODE: 'USED_VERIFICATION_CODE',
  EXPIRED_VERIFICATION_CODE: 'EXPIRED_VERIFICATION_CODE',
  MOBILE_SERVICE_NOT_SUPPORTED: 'MOBILE_SERVICE_NOT_SUPPORTED',
  ACCOUNT_LOCKED_SCREENING_CHECK: 'ACCOUNT_LOCKED_SCREENING_CHECK',
  PEP_WATCH_MATCHED: 'PEP_WATCH_MATCHED',
  ATTENDEE_ALREADY_OPTED_OUT: 'ATTENDEE_ALREADY_OPTED_OUT',
  ATTENDEE_ALREADY_EXISTS: 'ATTENDEE_ALREADY_EXISTS',
  ACCOUNT_CONDITION_UNAVAILABLE: 'ACCOUNT_CONDITION_UNAVAILABLE',
  INVALID_PROMOTION_STATUS: 'INVALID_PROMOTION_STATUS',
  PROMOTION_CONDITION_UNAVAILABLE: 'PROMOTION_CONDITION_UNAVAILABLE',
  ATTENDEE_NOT_FOUND: 'ATTENDEE_NOT_FOUND',
  PROMOTION_NOT_FOUND: 'PROMOTION_NOT_FOUND',
  INVALID_ATTENDEE_STATUS: 'INVALID_ATTENDEE_STATUS',
  PRIZE_CONDITION_UNAVAILABLE: 'PRIZE_CONDITION_UNAVAILABLE',
  PRIZE_EXPIRED: 'PRIZE_EXPIRED',
  ACCOUNT_TERMS_CONDITIONS_CONSENT_ALREADY_EXISTS: 'ACCOUNT_TERMS_CONDITIONS_CONSENT_ALREADY_EXISTS',
};

export const apiErrorType = {
  AUTH: 'AUTH',
  VERIFICATION: 'VERIFICATION',
};
