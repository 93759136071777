<template>
  <div gp-uk-employment-status>
    <!--    <DropSelect class="uk-employment-dropdown" :value="value" @input="input" :list="list" auto :translate="false" :placeholder="$t('myInfo.personalDetails.employmentStatus')" />-->
    <SearchDropSelect class="uk-employment-dropdown" select-only :value="currentValue" @input="input" :list="list" auto :translate="false" :placeholder="$t('myInfo.personalDetails.employmentStatus')" :hidden-icon="hiddenIcon" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import DropSelect from '@shared/components/common/input/DropSelect.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';

export default {
  name: 'UkEmploymentTypeDropDown',
  components: { SearchDropSelect, DropSelect },
  props: {
    value: Specific,
    current: Specific,
    disabled: { type: Boolean, default: null },
    currencySymbol: { type: String, default: null },
    hiddenIcon:{ type: Boolean, default : false},

  },
  data() {
    return {
      currentValue: null,
    };
  },
  watch: {
    'value': 'updateValue'
  },
  computed: {
    preset() { return { list: this.list, auto: true, translate: false, }; },
    list() {
      return [
        { label: this.$t('myInfo.employed'), value: 'Employed' },
        { label: this.$t('myInfo.selfEmployed'), value: 'SelfEmployed' },
        { label: this.$t('myInfo.retired'), value: 'Retired' },
        { label: this.$t('myInfo.student'), value: 'Student' },
        { label: this.$t('myInfo.unemployed'), value: 'Unemployed' },
      ];
    }
  },
  methods: {
    updateValue() {
      this.currentValue = this.value;
    },
    input(v) {
      if (!v) return;
      this.currentValue = v?.value;
      this.$emit('input', v?.value);
    }
  },
  mounted() {
    this.currentValue = this.value !== 'None' ? this.value : null;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-uk-employment-status] {
  .uk-employment-dropdown {.w(100%); .h(40);
    > a {.p(0);}
    &.disabled {.bgc(#EEEEEE); .c(#a1a1aa); cursor: default;
      > a > input {background-color: transparent;
        &::placeholder {.c(@c-b05); .fs(14);}
      }
    }

  }
}
</style>