import { render, staticRenderFns } from "./JumioDescription.vue?vue&type=template&id=73798987&scoped=true"
import script from "./JumioDescription.vue?vue&type=script&lang=js"
export * from "./JumioDescription.vue?vue&type=script&lang=js"
import style0 from "./JumioDescription.vue?vue&type=style&index=0&id=73798987&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73798987",
  null
  
)

export default component.exports