const view = path => () => import(/* webpackChunkName: "FindId" */ `../views/pages/find-id/${path}.vue`);

export default ({ local, findKey }) => {
  const path = local ? 'find-id/' : 'app/';
  const verify = { path: 'verify', name: 'FiVerify', component: view('FiProgress') };
  const complete = { path: 'complete', name: 'FiComplete', component: view('FiProgress'), };
  const create = { path: 'create', name: 'FiCreate', component: view('FiProgress'), };
  const password = { path: 'confirm-password', name: 'FiConfirmPw', component: view('FiProgress'), };
  let list;

  if(findKey) {
    list = [
      {
        path: `${path}`,
        redirect: `${path}create`,
        component: view('FindId'),
        children: [
          { path: 'email-guide', name: 'FiEmailGuide', component: view('FiProgress'), },
          { path: 'password-guide', name: 'FiPasswordGuide', component: view('FiProgress'), },
          create,
          password,
          verify,
          complete,
          { path: '*', redirect: '/not-found' },
        ],
      },
    ];
  } else {
    list = [
      {
        path: `${path}`,
        redirect: `${path}type`,
        component: view('FindId'),
        children: [
          { path: 'type', name: 'FindIdType', component: view('FindIdType'), },
          {
            path: 'email', name: 'FiEmail', component: view('FiProgress'),
          },
          {
            path: 'mobile', name: 'FiMobile', component: view('FiProgress'),
          },
          { path: 'credential', name: 'FiCredential', component: view('FiProgress'), },
          { path: 'email-guide', name: 'FiEmailGuide', component: view('FiProgress'), },
          { path: 'password-guide', name: 'FiPasswordGuide', component: view('FiProgress'), },
          create,
          password,
          verify,
          complete,
          { path: '*', redirect: '/not-found' },
        ]
      }
    ];
  }
  return list;
}