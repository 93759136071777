export default {
  data() {
    return {
      focus: false,
      dropListBox: null,
      selected: null,
      ready: false,
    };
  },
  props: {
    value: { default: null },
    list: { type: Array, default: null },
    split: { type: String, default: null },
    placeholder: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    useBlank: { type: Boolean, default: false },
    variant: { type: String, default: null },
    direction: { type: String, default: 'down' },
    name: { type: String },
    error: { type: Boolean, default: false },
    translate: { type: Boolean, default: true },
    theme: { type: String, default: 'white' },
    auto: { type: Boolean, default: false },
  },
  methods: {
    removeDropList() {
      if (!this.dropListBox) return;

      this.dropListBox.$destroy();
      this.dropListBox = null;
      this.focus = false;
      if(this.clearable) this.$emit('input', this.selected?.value || this.selected);
      this.$emit('blur');
    },
    clickOutSideHandler(e) {
      const { target } = e;
      if (!(this.$el === target || this.$el.contains(target))) {
        if (this.dropListBox) {
          const touch = 'ontouchstart' in window;
          const rect = this.dropListBox.$el.getBoundingClientRect();
          const x = touch ? e.touches[0].clientX : e.x;
          const y = touch ? e.touches[0].clientY : e.y;
          if (x < rect.x || x > rect.x + rect.width || y < rect.y || y > rect.y + rect.height) this.removeDropList();
        }
      }
    },
    outSideScrollHandler(e) {
      const { target } = e;
      if (this.dropListBox && !this.dropListBox.$el.contains(target)) {
        this.removeDropList();
      }
    },
    eventListenerHandler(destroy = false) {
      const touch = 'ontouchstart' in window;
      const down = touch ? 'touchstart' : 'mousedown';
      if (destroy) {
        document.body.removeEventListener(down, this.clickOutSideHandler);
        window.removeEventListener('resize', this.removeDropList);
        document.removeEventListener('scroll', this.outSideScrollHandler, true);
      } else {
        document.body.addEventListener(down, this.clickOutSideHandler);
        window.addEventListener('resize', this.removeDropList);
        document.addEventListener('scroll', this.outSideScrollHandler, true);
      }
    },
  }
};