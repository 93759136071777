<template>
  <div limit-table-row>
    <div class="label">{{ label }}</div>
    <div class="row">
      <div class="current">
        <InputComponent :label="$t('currentLimit')" :name="currentName" :component="TextInputBox" v-model="currentValue" :disabled="true" :preset="currentPreset" />
      </div>
      <div class="new">
        <RgLimitInput :label="$t('newLimit')" :name="newName" :mode="mode" :rules="rules" :value="value" :preset="{...preset, disable : noLimit}" @input="input" :currency-symbol="preset.currencySymbol" />
        <CheckboxItem v-if="showNoLimit" theme="light" :selected="noLimit" @change="checkChange">{{ $t('noLimit') }}</CheckboxItem>
      </div>
    </div>
  </div>
</template>

<script>
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import Specific from '@shared/types/Specific';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import RgLimitInput from '@/views/components/pages/responsible-gaming/template/common/RgLimitInput.vue';

export default {
  name: 'LimitTableRow',
  lexicon: 'responsibleGaming.modal.label',
  components: { InputComponent, CheckboxItem, RgLimitInput },
  props: {
    mode: {type: String, default: 'aggressive'},
    label: { type: String, default: '' },
    currentValue: { type: Specific },
    currentName: { type: String },
    value: { type: Specific },
    newName: { type: String },
    preset: { type: Specific },
    rules: { type: String, default: '' },
    noLimit: { type: Boolean, default: false, },
    noLimitName: { type: String, default: null },
    hiddenLimit: { type: Boolean, default: false, }
  },
  data() {
    return {
      TextInputBox,
    };
  },
  computed: {
    currentPreset() {
      return { type: 'amount', placeholder: this.$t('noLimit'), disable: true };
    },
    userInfo: state('user', 'userInfo'),
    site: state('env', 'site'),
    showNoLimit() {
      return this.$rg.checkNoLimitVisible(this.hiddenLimit);
    },
  },
  methods: {
    input(v) {
      this.$emit('input', v);
    },
    checkChange(v) {
      this.$emit('input', null);
      this.$emit('checked', this.noLimitName, v);
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[limit-table-row] { .w(100%);.flex(); .flex-dc(); .gap(10);
  .label {.c(#000); .fs(16); font-weight: 600; .pl(5);}
  .row {.flex(); .flex-dc(); .gap(10); .bgc(#EBEBEF); .br(16); .p(20);}

  //&:nth-last-of-type(1) {border-bottom: none;}
  //> div { .w(40%); .c(black); .fs(14); font-weight: 500;
  //  &:nth-of-type(1) {.w(20%);}
  //}
  //.current {.flex(); .flex-dc(); }
  .new {.rel();}
  [checkbox-item] { .abs(); .t(5); .r(5);
    > em {line-height: 16px; .c(#000); .fs(14);}
  }
}
</style>