export default () => ({
  namespaced: true,
  state: {
    token: null,
    country: null,
    depositInfo: null,
    dailyInfo: null,
    signUpInfo: null,
    referralInfo: null,
    promotionList: null,
    isClient: null,
  },
  mutations: {
    setToken(state, info) {
      state.token = info;
    },
    setCountry(state, info) {
      state.country = info;
    },
    setDepositInfo(state, info) {
      state.depositInfo = info;
    },
    setDailyInfo(state, info) {
      state.dailyInfo = info;
    },
    setSignUpInfo(state, info) {
      state.signUpInfo = info;
    },
    setReferralInfo(state, info) {
      state.referralInfo = info;
    },
    setPromotionList(state, info) {
      state.promotionList = info;
    },
    setIsClient(state, info) {
      state.isClient = info;
    }
  }
});
