<template>
  <div gp-btn-back>
    <button @click="click">
      <!--      &lt;!&ndash;      <FontIcon name="arrow-left" />&ndash;&gt;-->
      <!--      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">-->
      <!--        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8728 19.4507C15.2598 19.1067 15.2947 18.5141 14.9507 18.1271L9.50432 12L14.9507 5.87283C15.2947 5.48585 15.2598 4.89328 14.8728 4.54929C14.4858 4.20531 13.8933 4.24016 13.5493 4.62715L7.54929 11.3771C7.23355 11.7324 7.23355 12.2676 7.54929 12.6228L13.5493 19.3728C13.8933 19.7598 14.4858 19.7947 14.8728 19.4507Z" fill="#FEFEFE" />-->
      <!--      </svg>-->
      <FontIcon name="chevron-left" />
    </button>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
export default {
  name: 'BtnBack',
  props: {
    href: { type: String, }
  },
  components: { FontIcon },
  methods: {
    click() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-btn-back] { .block(); .mt(20); .wh(24);
  [font-icon] { .fs(20); .c(#A1A1AA); }
}
</style>