<template>
  <div image-selector>
    <label>
      <input type="file" ref="input" :accept="acceptFiles" :key="sq" @change="selected" :multiple="multiple">
      <slot :select="select" />
    </label>
    <slot name="selected" v-if="src && !multiple" :src="src" :reset="reset" />
  </div>
</template>

<script>
export default {
  name: 'ImageSelector',
  props: {
    showImage: { type: Boolean, default: false },
    uploadedImage: { type: String, default: null },
    multiple: { type: Boolean, default: false },
    useZip: { type: Boolean, default: false },
    imgOnly: { type: Boolean, default: true }, /** false - kyc */
    dragInput: Boolean,
  },
  data() {
    return {
      sq: 0,
      src: null,
      fileList: null,
    };
  },
  computed: {
    acceptFiles() {
      let files = this.imgOnly ? 'image/jpeg,image/png,.svg,.ico' : 'image/jpeg,image/png,.bmp,.docx,.pdf';
      if (this.useZip) files += ',.zip';
      return files;
    },
  },
  methods: {
    acceptFileTest(name) {
      if (this.imgOnly) return this.useZip ? !/\.(jpg|jpeg|png|svg|ico|zip)$/i.test(name) : !/\.(jpg|jpeg|svg|png|ico)$/i.test(name);
      else return this.useZip ? !/\.(jpg|jpeg|png|bmp|docx|pdf|zip)$/i.test(name) : !/\.(jpg|jpeg|png|bmp|docx|pdf)$/i.test(name);
    },
    async selected({ target: { files } }) {
      this.init();
      this.fileList = files;
      if (!this.fileList || !this.fileList[0]) {
        this.init();
        return;
      }
      await this.imageLoad();
    },
    async imageLoad() {
      const file = this.fileList[this.sq];

      if (this.acceptFileTest(file.name)) {
        this.$toast('FILE_TYPE_NOT_ALLOWED', { type: 'fail' });
        if (this.sq < this.fileList.length - 1) this.next();
        else return;
      }
      await this.process(file, this.fileList.length - 1 === this.sq);
      const reader = new FileReader();
      reader.onload = () => {
        this.src = reader.result;
        this.$emit('result', reader.result);
        if (this.sq < this.fileList.length - 1) this.next();
        else this.$refs.input.value = '';
      };

      reader.readAsDataURL(file);
    },
    process(file, isLast) {
      return new Promise(resolve => {
        this.$emit('select', { file, resolve, isLast });
      });
    },
    select() {
      this.$el.querySelector('label').click();
    },
    init() {
      this.sq = 0;
      this.src = null;
      this.fileList = null;
    },
    reset() {
      this.init();
      this.$emit('reset');
    },
    next() {
      this.sq += 1;
      this.imageLoad();
    },

    setDragInput() {
      this.$el.addEventListener("dragover", (e) => {
        e.preventDefault();
      });

      this.$el.addEventListener("drop", (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files?.length ) {
          this.selected({target: { files}});
        }
      });
    }
  },
  mounted() {
    if (this.uploadedImage) this.src = this.uploadedImage;
    if (this.dragInput) this.setDragInput();
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[image-selector] { .rel; .crop;
  label { .block; .f; .rel; .pointer;
    input { .hide; }
    .selected-image { .abs; .wf; object-fit: contain; }
  }
  a { .abs; .rt; }
}
</style>
