<template>
  <svg v-html="cont"></svg>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RSvg',
  props: {
    src: { type: String, default: null, required: true },
  },
  data() {
    return {
      cont: null,
    };
  },
  watch: {
    src: 'reset',
  },
  methods: {
    async reset() {
      if (!this.src) return;
      const { data } = await axios.get(this.src);
      const parent = document.createElement('div');
      parent.innerHTML = data;
      const svg = parent.querySelector('svg');
      try {
        this.cont = svg?.innerHTML;
        svg.getAttributeNames().forEach(n => this.$el.setAttribute(n, svg.getAttribute(n)));
      } catch(e) {
        console.log('disabled svg', this.src);
      }
    },
  },
  async mounted() {
    await this.reset();
  },
};
</script>
