<template>
  <div reaffirm-de-verify-form>
    <p>{{ $t('onBoarding.setting.verifyMobileCode.description') }} {{ modelInfo.EmailMasked }}</p>
    <VerifyResendButton :structure="structure" :model-info="modelInfo" :type="sendVerificationType" :action="action" @complete="resendComplete" />
    <ValidationProvider v-slot="{errors}" rules="requiredNone">
      <VerifyCode v-model="modelInfo.VerificationCode" :structure="structure" @input="updateCode" />
      <ValidationErrors v-if="structure.errorMsg" :errors="errors" :error-msg="structure.errorMsg" />
    </ValidationProvider>
    <ProgressButton :button-name="buttonName" @click="confirm" :progress="progress" :disabled="structure.error || invalid || !codeLengthCheck" />
  </div>
</template>
<script>

import { state } from '@shared/utils/storeUtils.mjs';
import VerifyCode from '@/views/components/common/VerifyPinCode.vue';
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Specific from '@shared/types/Specific';
import VerifyResendButton from '@/views/components/common/VerifyResendButton.vue';
import VerifyRequestMessageModal from '@/views/components/common/modal/VerifyRequestMessageModal.vue';

export default {
  name: 'ReaffirmDeVerifyForm',
  components: { VerifyResendButton, ProgressButton, VerifyCode },
  mixins: [baseTemplate],
  props: {
    template: { type: Specific },
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      progress: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    step() {
      return this.structure.step;
    },
    customStep() {
      return this.structure.customStep;
    },
    customSteps() {
      return this.structure.customSteps;
    },
    lastStep() {
      return this.customStep >= this.customSteps?.length - 1;
    },
    api() {
      return this.template?.api;
    },
    service() {
      return this.template?.service;
    },
    buttonName() {
      return this.item?.buttonName || (this.lastStep ? 'submit' : 'next');
    },
    isProd() {
      return this.env === 'production';
    },
    sendVerificationType() {
      return this.modelInfo.SendVerificationType;
    },
    email() {
      return this.sendVerificationType === 'EMAIL';
    },
    action() {
      return this.email ? 'EmailVerifySendCode' : 'MobileVerifySendCode';
    },
    codeLengthCheck() {
      return this.modelInfo.VerificationCode && this.modelInfo.VerificationCode.length >= 4;
    },
  },
  methods: {
    updateCode(v) {
      this.modelInfo.VerificationCode = v;
    },
    nextStep() {
      this.structure.customStep++;
    },
    async confirm() {
      await this.apiRequest();
    },
    async apiRequest() {
      if (!this.api) {
        this.nextStep();
      } else {
        this.progress = true;
        const r = await this.getApiResponse();
        this.progress = false;
        if (r?.error) {
          if (this.$onboarding.errorHandler(r, true)) return;
          this.structure.error = true;
          this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail });

          if (!this.isProd) {
            console.log(`Api Request Error : onboarding.${this.api}`, r);
            this.$toast(r.desc, { type: 'fail', translate: false });
          }
        } else {
          await this.nextStep();
        }
      }
    },
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services[this.service][this.api]({ ...this.structure.model }); } catch (e) { return e; }
    },
    async resendComplete(v) {
      await this.$modal(VerifyRequestMessageModal, { isSend: v?.isSend, type: this.modelInfo.SendVerificationType === 'EMAIL' ? 'email' : 'sms', error: v?.error });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reaffirm-de-verify-form] { .rel();
  > p { .c(#d8d8d8);
    em { .c(white); }
  }
  > [verify-resend] { .mt(20); .tr(); }
}
</style>
No newline at end of file
