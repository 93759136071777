import { render, staticRenderFns } from "./InputPersonalInfo.vue?vue&type=template&id=88817a68"
import script from "./InputPersonalInfo.vue?vue&type=script&lang=js"
export * from "./InputPersonalInfo.vue?vue&type=script&lang=js"
import style0 from "./InputPersonalInfo.vue?vue&type=style&index=0&id=88817a68&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports