<template>
  <div birth-cz>
    <ValidationComponent name=" _countryOfBirth" rules="required" :component="CountryDropSelect" :label="$t('_.countryOfBirth')" :preset="{size: 'sm'}" v-model="modelInfo.CountryOfBirth" />
    <CzechCountrySelector :model-info="modelInfo" />
    <ContactMobileNumber :model-info="modelInfo" :structure="structure" />
    <Citizenship v-if="useCitizenship" :model-info="modelInfo" :structure="structure" />
    <template v-if="useBirthNumber">
      <ValidationComponent name="_birthNumber" rules="required||birthNumber" :component="TextInput" :label="$t('_.birthNumber')" v-model="modelInfo.NationalId" :preset="{digit: true, maxLength: 10}"/>
    </template>
    <template v-else>
      <DateOfBirth :modelInfo="modelInfo" :structure="structure" :item="item" />
      <GenderSelector :model-info="modelInfo" :structure="structure" />
    </template>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { getBirth } from '@/utils/userUtil';
import { getYYYYMMDDTHHmmssZ } from '@shared/utils/timeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap.mjs';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import CountryDropSelect from '@/views/components/common/CountryDropSelect.vue';
import ContactMobileNumber from '@/views/components/pages/on-boarding/template/ContactMobileNumber.vue';
import Citizenship from '@/views/components/pages/on-boarding/template/extra/Citizenship.vue';
import baseTemplate from '@/mixins/baseTemplate';
import TextInput from '@shared/components/common/input/TextInput.vue';
import CzechCountrySelector from '@/views/components/pages/on-boarding/template/CzechCountrySelector.vue';
import GenderSelector from '@/views/components/extra/GenderSelector.vue';
import DateOfBirth from '@/views/components/extra/DateOfBirth.vue';

export default {
  name: 'BirthCz',
  mixins: [baseTemplate],
  lexicon: 'personalDetails',
  components: { DateOfBirth, GenderSelector, CzechCountrySelector, Citizenship, ContactMobileNumber, ValidationComponent },
  data() {
    return {
      birth: null,
      CountryDropSelect,
      TextInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    useCitizenship() {
      return [siteIds.GGPNL, siteIds.WSOPON, siteIds.GGPDE, siteIds.GGPCZ,siteIds.GGPPH].includes(this.site);
    },
    useBirthNumber() {
      return this.modelInfo.Nationality === 'CZ';
    }
  },
  watch: {
    'modelInfo.NationalId'() {
      const r = getBirth(this.modelInfo.NationalId);
      if(!Boolean(r)) {
        this.modelInfo.Gender = null;
      } else {
        this.modelInfo.Gender = r.gender;
        this.modelInfo.DateOfBirth = getYYYYMMDDTHHmmssZ(r.year + r.month + r.day);
      }
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[birth-cz] {
  > :not(:nth-of-type(1)) { .mt(8); }
}
</style>