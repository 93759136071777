<template>
  <span status-badge :class="name">{{ $t(`promotion.status.${name}`) }}</span>
</template>

<script>
export default {
  name: 'StatusBadge',
  props: {
    status: String,
  },
  computed: {
    name() {
      return this.status.toLowerCase();
    },
  }
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[status-badge] { .inline-flex(); .items-center(); .gap(8); .fs(12);
  &::before { .cnt(); .wh(6); .br(50%); }
  &.pending { .c(@c-gray1);
    &::before { .bgc(@c-gray1); }
  }
  &.in_progress { .c(@c-green);
    &::before { .bgc(@c-green); }
  }
  &.paused { .c(#d89d3a);
    &::before { .bgc(#d89d3a); }
  }
  &.completed { .c(@c-blue);
    &::before { .bgc(@c-blue); }
  }
  &.expired { .c(@c-red);
    &::before { .bgc(@c-red); }
  }
}
</style>
