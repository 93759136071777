<template>
  <div personal-birth :name="formName" class="kyc-date-of-birth-view">
    <div class="birth-holder">
      <DateOfBirth :modelInfo="modelInfo" :structure="structure" :item="item" />
    </div>
    <div class="extra-holder">
      <component v-for="(item,id) in extraComponent" :key="id" :is="item" :modelInfo="modelInfo" :structure="structure" />
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import _ from 'lodash';
import { siteIds } from '@/constants/base/siteMap.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import DateOfBirth from '@/views/components/extra/DateOfBirth.vue';

export default {
  name: 'PersonalBirth',
  components: { DateOfBirth },
  mixins: [baseTemplate],
  computed: {
    site: state('env', 'site'),
    extra() {
      return this.item?.extra && this.item.extra[this.site] ? this.item.extra[this.site] : {};
    },
    extraComponent() {
      return _.isArray(this.extra) ? this.extra.map(o => o.template) : [this.extra.template];
    },
    formName() {
      return this.site === siteIds.WSOPON ? 'WSOPca_Step13_Date_of_Birth_View' : 'Date_of_Birth_View';
    },
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[personal-birth] {
  .extra-holder {
    > * { .mt(8);}
  }
}
</style>
