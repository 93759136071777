<template>
  <div signicat-container>
    <slot />
  </div>
</template>

<script>

export default {
  name: 'SignicatContainer',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[signicat-container] {}
</style>