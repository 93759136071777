export const makePrice = value => Number(value).toLocaleString();

export const roundUp = (value, fix = 2) => value.toFixed(fix);

export const makeCurrency = (value, currency, option, fixedCurrency) => {
  if(fixedCurrency) currency = getCurrencySymbol(fixedCurrency);
  else if(currency) currency = getCurrencySymbol(currency);
  value = makePrice(value).replace(/^(-)?(.+)$/, `$1${(option ? `${option}$` : currency).replace(/\$/g, '$$$$')}$2`);
  const split = value.split('.');
  if (split.length === 1) value += '.00';
  else if (split[1].length === 1) value += '0';
  return value;
};

export const currencyValue = value => {
  value = makePrice(value);
  const split = value.split('.');
  if (split.length === 1) value += '.00';
  else if (split[1].length === 1) value += '0';
  return value;
}

export const getCurrencySymbol = value => {
  switch (value) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'CAD':
      return 'CA$';
    case 'CNY':
      return '¥';
    case 'GCO':
      return 'Coin';
    case 'RON':
      return 'lei';
    case 'INR':
      return '₹';
    case 'CZK':
      return 'Kč';
    case 'BRL':
      return 'R$';
    default:
      return '';
  }
};

export const valueK = value => (Math.abs(value) > 999 ? `${Math.sign(value) * ((Math.abs(value) / 1000).toFixed(1))}k` : Math.sign(value) * Math.abs(value));
