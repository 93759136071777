<template>
  <JumioContainer jumio-document-invalid>
    <h3>{{ $t('_.title') }}</h3>
    <div class="desc">
      <p>
        {{ $t('_.desc.notAcceptedIGO') }}
        {{ $t('_.desc.uploadIdentityDocuments') }}
      </p>
    </div>
    <div class="documents">
      <p>{{ $t('_.contents.acceptableDocumentsAre') }}</p>
      <ul>
        <li>{{ $t('_.contents.drivingLicense') }}</li>
        <li>{{ $t('_.contents.passport') }}</li>
        <li>{{ $t('_.contents.identityCard') }}</li>
        <li>{{ $t('_.contents.nEXUSCard') }}</li>
        <li>{{ $t('_.contents.globalEntryCard') }}</li>
        <li>{{ $t('_.contents.citizenshipCard') }}</li>
        <li>{{ $t('_.contents.permanentResidentCard') }}</li>
        <li>{{ $t('_.contents.provincialTerritorialIdentityCard') }}</li>
        <li>{{ $t('_.contents.provincialServicesCard') }}</li>
        <li>{{ $t('_.contents.secureCertificateIndianStatus') }}</li>
      </ul>
    </div>
    <ProgressButton button-name="back" @click="$emit('confirm')" />
  </JumioContainer>
</template>

<script>
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import JumioContainer from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioContainer.vue';

export default {
  name: 'JumioDocumentInvalid',
  lexicon: 'errorPages.jumio.documentInvalid',
  components: { JumioContainer, ProgressButton }
};
</script>

<style lang="less" scoped>
@import '~@/less/proj.less';
[jumio-document-invalid] {
  .documents { .mt(20); .p(16, 24); .bgc(#efefef); .c(#717171); .tl;
    ul { .mt(8); .ml(8); line-height: 1.3;
      li { .fs(14); list-style: disc inside;
        + li { .mt(4); }
      }
    }
  }
  //[processing-button] { .mt(24); }
}
</style>