import { state } from '@shared/utils/storeUtils.mjs';
import { STEP } from '@/constants/base/my-page';
export default {
  props: {
    step: { type: String, default: null },
  },
  computed: {
    site: state('env', 'site'),
    isDefaultStep() {
      return this.step === STEP.DEFAULT;
    },
    isInputStep() {
      return this.step === STEP.INPUT;
    },
    isVerifyStep() {
      return this.step === STEP.VERIFY;
    },
    stepInfo() {
      return STEP;
    }
  },
};