<template>
  <GpFormTemplate np-my-accoutns>
    <template #header>
      <GpDescription :message="$t('myInfo.myAccountsDesc')" />
    </template>
    <template #content>
      <div>
        <div class="header">
          <GpTitle :name="$t('_.currentAccount')" />
              <!--          6월 배포 제외-->
<!--          <TextButton v-if="model.CurrentAccount" @click="goToTransferHistory">{{ $t('myInfo.title.transferHistory') }}</TextButton>-->
        </div>
        <DisplayCurrentTransferAccount v-if="isPageLoaded" :model="model.CurrentAccount" :has-accounts="hasAccounts" @addCash="addCash" @cashOut="cashOut" />
        <div v-else class="fake-account-box"></div>
      </div>
      <div>
        <div class="header">
          <GpTitle :name="$t('_.linkedAccount')" />
          <!--          6월 배포 제외-->
<!--          <TextButton v-if="!model.CurrentAccount" @click="goToTransferHistory">{{ $t('myInfo.title.transferHistory') }}</TextButton>-->
        </div>
        <div class="link-account">
          <DisplayAccountsTable v-if="isPageLoaded" :model="model" :list="model.Accounts" @click="transferClick" />
          <div v-else class="fake-account-box"></div>
        </div>
      </div>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import DisplayAccountsTable from '@/views/components/pages/my-page/template/views/DisplayAccountsTable.vue';
import DisplayCurrentTransferAccount from '@/views/components/pages/my-page/template/views/DisplayCurrentTransferAccount.vue';
import { routeNames } from '@/constants/model/my-page';
import TextButton from '@/views/components/gg-pass/TextButton.vue';
import npErrorHandler from '@/mixins/my-info/npErrorHandler';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import Specific from '@shared/types/Specific';
import { bothErrorCodes, transferType } from '@/constants/base/my-page';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import moment from 'moment/moment'

const TRANSFERABLE_CODE = 'OK';

export default {
  name: 'NpMyAccounts',
  lexicon: 'myInfo.myAccounts',
  components: { TextButton, DisplayCurrentTransferAccount, DisplayAccountsTable, GpTitle, GpDescription, GpFormTemplate },
  mixins: [npErrorHandler],
  props: {
    model: Specific,
    structure: { type: Specific, default: null },
  },
  data() {
    return {
      originList: null,
      isPageLoaded: false,
      errorMsg: null,
    };
  },
  computed: {
    brandId: state('env', 'gpBrand'),
    brand: state('env', 'brand'),
    gpSite: state('env', 'gpSite'),
    site: state('env', 'site'),
    currencies: state('config', 'currencies'),
    token: state('user', 'token'),
    preset() {return { required: true, placeholder: 'Email Address' };},
    realMoneyCurrencyList() {
      if (!this.currencies) return [];
      return Object.keys(this.currencies).filter(currency => this.currencies[currency].IsRealMoney);
    },
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    hasAccounts() {
      return this.model.Accounts.length > 0;
    },
    notConfigError() {
      return this.$t(apiErrorCode.INVALID_TRANSFER_INFO);
    },
    isOnboardingAccount() {
      return this.model.CurrentAccount.IsOnboardingAccount;
    },
    // Credit / Debit 에 각각 에러코드 존재. ( 모두 존재 하면 prefix 추가 : BOTH_ )
    bothErrorCodeList() {
      return bothErrorCodes;
    },
    kycUrl() {
      return process.env[`VUE_APP_ON_BOARDING_URL_${this.site}`] || null;
    },
    onboardingCodes() {
      return [this.errorCodePrefix + apiErrorCode.ACCOUNT_UNVERIFIED, apiErrorCode.ACCOUNT_UNVERIFIED];
    },
    // transfer config 의 isEnabled 가 false여도 아래 에러코드일 경우에는 우선적으로 표시
    priorityCodeList() {
      return [apiErrorCode.ACCOUNT_SUSPENDED, apiErrorCode.RESPONSIBLE_GAMING];
    },
    errorCodePrefix() {
      return 'BOTH_';
    }
  },
  methods: {
    async initialize() {
      const { onePassInfo } = await this.$myinfo.fetch({payload : null, block : true});
      /** 6월 제외 */
      // if (!this.currencies) await this.$myinfo.setCurrencyList();

      const upperSite = this.site.toUpperCase();
      const upperBrand = this.brandId.toUpperCase();

      const currentAccount = onePassInfo.accounts.find(info => info.brandId.toUpperCase() === upperBrand && info.siteId.toUpperCase() === upperSite);

      this.model.CurrentAccount = this.createAccountInfo(currentAccount, true);
      this.originList = onePassInfo.accounts.map(account => {
        return this.createAccountInfo(account);
      });
      this.model.Accounts = this.createLinkedAccounts(this.model.CurrentAccount, this.originList);
      this.isPageLoaded = true;
    },

    createLinkedAccounts(current, accounts) {
      const list = accounts.filter(x => ((x !== null) && current.AccountId !== x.AccountId));
      return list.map(item => {
        let currentEnabled = current.IsEnabled;
        let ToolTipMessage = item.ToolTipMessage ? item.ToolTipMessage : null;
        let isEnabled = !currentEnabled ? true : item.IsEnabled;
        if (!item?.IsEnabled && typeof item?.IsEnabled === 'undefined') {
          ToolTipMessage = this.notConfigError;
          isEnabled = false;
        }
        if (!currentEnabled) {
          ToolTipMessage = null;
        }

        return {
          ...item,
          ToolTipMessage: ToolTipMessage,
          Disable: !isEnabled,
          ShowButton: currentEnabled || current.IsOnboardingAccount,
        };
      });
    },

    createAccountInfo(account, current = false) {
      const item = this.findAmount(account);
      const transferInfo = account?.transferInfo;
      const transfer = this.createTransferInfo(transferInfo, current);
      const siteName = account?.brandSite?.siteName ? account?.brandSite?.siteName : '';

      return {
        Amount: item?.amount || 0,
        Currency: item?.currencyId,
        CountryCode: account?.countryId,
        SiteName: siteName,
        SiteId: account?.siteId,
        BrandId: account?.brandId,
        AccountId: account.onepassAccountId,
        LogoUrl: account?.brandSite?.logoUrl,
        ...transfer,
      };
    },

    createTransferInfo(transferInfo, current = false) {
      const toolTipCode = this.createTransferableCode(transferInfo, current);
      const result = { IsEnabled: false, CreditAvailable: false, DebitAvailable: false, Credit: null, Debit: null, ToolTipMessage: this.$t(toolTipCode), IsOnboardingAccount: false };
      if (!transferInfo) {
        return result;
      }
      const configs = transferInfo?.configs;
      const config = configs[0];
      const creditTransferableCode = transferInfo?.credit?.transferableCode;
      const debitTransferableCode = transferInfo?.debit?.transferableCode;
      const creditDescription = transferInfo?.credit?.description;
      const debitDescription = transferInfo?.debit?.description;
      const creditAvailable = creditTransferableCode === TRANSFERABLE_CODE;
      const debitAvailable = debitTransferableCode === TRANSFERABLE_CODE;

      if (!config.isEnabled) return { ...result, ToolTipMessage: this.$t(toolTipCode) };
      if (toolTipCode) {
        let endTime = null;
        const isOnboardingAccount = this.onboardingCodes.includes(toolTipCode) && current;
        if(debitDescription){
          const jsonDebitDescription = JSON.parse(debitDescription);
          endTime = moment(jsonDebitDescription.blockEndDateTime).utc().format('YYYY-MM-DD HH:mm');
        }
        const message = this.$t(toolTipCode, {endTime : endTime})
        return { IsEnabled: isOnboardingAccount, CreditAvailable: creditAvailable, DebitAvailable: debitAvailable, Credit: transferInfo.credit, Debit: transferInfo.debit, IsOnboardingAccount: isOnboardingAccount, ToolTipMessage: message };
      }

      return { IsEnabled: true, CreditAvailable: creditAvailable, DebitAvailable: debitAvailable, Credit: transferInfo.credit, Debit: transferInfo.debit, ToolTipMessage: null, IsOnboardingAccount: false };
    },

    createTransferableCode(transferInfo, credit = false) {
      if (!transferInfo) return apiErrorCode.INVALID_TRANSFER_INFO;

      const configs = transferInfo?.configs;
      if (!configs) return apiErrorCode.INVALID_TRANSFER_INFO;

      const config = configs[0];
      const creditTransferableCode = transferInfo?.credit?.transferableCode;
      const debitTransferableCode = transferInfo?.debit?.transferableCode;
      const creditAvailable = creditTransferableCode === TRANSFERABLE_CODE;
      const debitAvailable = debitTransferableCode === TRANSFERABLE_CODE;

      if (!config.isEnabled) {
        const code = creditAvailable ? debitTransferableCode : creditTransferableCode;
        if (this.priorityCodeList.includes(code)) {
          return this.bothErrorCodeList.includes(code) ? this.errorCodePrefix + code : code;
        } else return apiErrorCode.NOT_SUPPORTED_REGION;
      }
      if (!creditAvailable || !debitAvailable) {
        if (this.bothErrorCodeList.includes(creditTransferableCode) && this.bothErrorCodeList.includes(debitTransferableCode)) {
          return this.errorCodePrefix + debitTransferableCode;
        } else {
          let code = null;
          if(creditAvailable){
            code = debitTransferableCode
          }else code = creditTransferableCode
            // debitAvailable ? creditTransferableCode : debitTransferableCode;
          if(credit && creditAvailable) return null;
          if(!credit && debitAvailable) return null;
          return this.bothErrorCodeList.includes(code) ? this.errorCodePrefix + code : code;
        }
      }

      return null;
    },

    findAmount(account) {
      if (!account) return null;
      if (!account?.balances) return [];
      const item = account?.balances.find(balance => this.realMoneyCurrencyList.includes(balance.currencyId));

      return item;
    },

    goToTransferHistory() {
      this.routeName(routeNames.NpTransferHistory);
    },

    async addCash() {
      if (await this.checkOnboarding(this.model.CurrentAccount.Credit)) return;
      this.model.Type = transferType.ADD_CASH;
      await this.routeName(routeNames.NpBalanceTransfer);
    },
    async cashOut() {
      if (await this.checkOnboarding(this.model.CurrentAccount.Credit)) return;
      this.model.Type = transferType.CASH_OUT;
      await this.routeName(routeNames.NpBalanceTransfer);
    },

    async transferClick(v) {
      if (this.isOnboardingAccount) {
        await this.redirectKycUrl();
        return;
      }
      const selectedAccount = this.model.Accounts.find(info => info.AccountId === v);
      const etcAccounts = this.model.Accounts.filter(x => x.AccountId !== v);
      const enableList = etcAccounts.filter(x => !x.Disable).sort(this.sortBySiteId);
      const disableList = etcAccounts.filter(x => x.Disable).sort(this.sortBySiteId);
      const linkedAccount = [selectedAccount, ...enableList, ...disableList];

      this.model.Type = transferType.ADD_CASH;
      // this.model.CurrentAccount = selectedAccount;
      this.model.Accounts = linkedAccount;

      await this.routeName(routeNames.NpBalanceTransfer);
    },

    sortBySiteId(a, b) {
      if (a.SiteId < b.SiteId) return -1;
      if (a.SiteId > b.SiteId) return 1;
      return 0;
    },

    async checkOnboarding(account) {
      if (this.isOnboardingAccount) {
        await this.redirectKycUrl();
        return true;
      }
      return false;
    },

    async redirectKycUrl() {
      await this.$myinfo.redirectKycUrl({ redirectPath: 'np-my-accounts', lang: this.$i18n('lang') });
    },
  },
  async mounted() {
    this.structure.title = this.$myinfo.title;
    if (!this.isGgpass) {
      this.replaceRouteName('MyInfo');
    }
    await this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-my-accoutns] {
  .header {.flex(); justify-content: space-between;
    > [gp-text-button] {
      > button {.fs(13); .c(@gp-green); font-weight: 500; text-decoration-line: none;}
    }
  }
  .current-account { .bgc(#fff); .p(20);
    .footer {.flex(); .flex-dr(); .gap(10);}
  }

  .fake-account-box{.h(68); .w(100%); .br(16); .bgc(white);}
}

</style>