<template>
  <p v-if="time && currentSec > 0" limit-timer class="error">{{ $t('signUp.tnc.resendTimer', { seconds: currentSec }) }}</p>
</template>

<script>
export default {
  name: 'LimitTimer',
  props: {
    time: { type: [String, Number], default: null },
  },
  data() {
    return {
      timerId: null,
      currentSec: null,
    };
  },
  methods: {
    loop() {
      const sec = this.currentSec - 1;
      this.$store.commit('setResendSumSecond', sec);

      if (sec <= 0) {
        this.clearTime();
        this.$emit('complete');
        return;
      }
      this.currentSec = sec;
    },
    clearTime() {
      clearTimeout(this.timerId);
    },
  },
  mounted() {
    if (this.time) {
      this.timerId = setInterval(this.loop, 1000);
      this.currentSec = +this.time;
    }
  },
  beforeDestroy() {
    this.clearTime();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[limit-timer] {}
</style>