<template>
  <span gp-radio-button>
    <i :class="['radio-icon', {'on' : active}]"></i>
    <label v-if="isLabel">{{ label }}</label>
  </span>
</template>

<script>

import { isString } from 'lodash';
export default {
  name: 'RadioButton',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    }
  },
  computed: {
    isLabel() {
      return isString(this.label);
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-radio-button] { .flex(); .pt(5); .flex-dr(); .items-center(); .gap(10);
  > i { .wh(16); .-a(#cccccc); .br(16); .bgc(#fff); .rel();
    &.on { .-a(@gp-green);
      &:after { content: ''; .abs(); .wh(8); .bgc(@gp-green); .br(8); .t(50%); .l(50%); .m(-4, 0, 0, -4); }
    }
  }
  > label {.c(black); .fs(14);}
}
</style>