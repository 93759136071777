<template>
  <div verify-rg-loss-kr-setting>
    <template v-if="isReady">
      <FontIcon name="shield-checker" />
      <p class="desc xlg" v-html="$t('_.lossLimitKr.subTitle')" />
      <p class="desc lg" v-html="$t('_.lossLimitKr.description')" />
      <div class="holder">
        <a class="desc mlg" :class="{'active': activeId === 1}" @click="activeId = 1">
          {{ `${SmallLossLimit} ${$t('coin')} (${SmallLossLimitKrw}${$t('ko.krw')})` }}
        </a>
        <a class="desc mlg" :class="{'active': activeId === 2}" @click="activeId = 2">
          {{ `${LargeLossLimit} ${$t('coin')} (${LargeLossLimitKrw}${$t('ko.krw')})` }}
        </a>
      </div>
      <p class="error-msg" v-if="errorMsg">{{ $t(errorMsg) }}</p>
      <ProgressButton button-name="set" @click="set" />
    </template>
    <template v-else>
      <FontIcon name="shield-check" />
      <p class="desc xlg" v-html="$t('_.lossLimitKr.setDescription')" />
      <p class="desc lg" v-html="$t('_.lossLimitKr.immediatelyApplication')" />
      <p class="desc lg" v-html="$t('_.lossLimitKr.YouCanCheckMyInformation')" />
      <div class="result">
        <p class="desc lg" v-html="$t('_.lossLimitKr.confirmChange')" />
        <p class="desc mlg" v-html="isSmall ? SmallLossLimit : LargeLossLimit" />
      </div>
      <p class="error-msg" v-if="errorMsg">{{ $t(errorMsg) }}</p>
      <ProgressButton button-name="confirm" @click="close" />
    </template>
  </div>
</template>

<script>
import { close } from '@/utils/clientUtil';
import { bi_divide, bi_mod, bi_multiply } from '@/utils/bistroUtils';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'VerifyRgLossKrSetting',
  lexicon: 'onBoarding.setting',
  components: { FontIcon, ProgressButton },
  mixins: [baseTemplate],
  data() {
    return {
      isReady: true,
      activeId: 1,
      LossLimitOptionSmall: 100000,
      LossLimitOptionLarge: 700000,
      SmallLossLimit: 0,
      LargeLossLimit: 0,
      SmallLossLimitKrw: 0,
      LargeLossLimitKrw: 0,
    };
  },
  watch: {
    activeId(v) {
      this.modelInfo.RequestDailyLossLimit = v === 1 ? this.LossLimitOptionSmall : this.LossLimitOptionLarge;
    }
  },
  computed: {
    isSmall() {
      return this.modelInfo.RequestDailyLossLimit === this.LossLimitOptionSmall;
    },
    isLarge() {
      return this.modelInfo.RequestDailyLossLimit === this.LossLimitOptionLarge;
    }
  },
  methods: {
    displayCoin(coin) {
      const numList = [];
      const units = [
        { label: this.$t('ko.100Quintillion'), value: 1e20 },
        { label: this.$t('ko.10Quadrillion'), value: 1e16 },
        { label: this.$t('ko.Trillion'), value: 1e12 },
        { label: this.$t('ko.100M'), value: 1e8 },
        { label: this.$t('ko.10K'), value: 1e4 },
      ];
      units.forEach(unit => {
        const quot = bi_divide(coin, unit.value);
        const remainder = bi_mod(coin, unit.value);

        if (quot > 0)
        {
          numList.push(quot + unit.label);
          coin = remainder;
        }
      });

      if (Number(coin) > 0) numList.push(coin);
      if (numList.length > 2) return numList.slice(0, 2).join(' ');
      return numList.length > 0 ? numList.join(' ') : coin;
    },
    close() {
      close(this);
    },
    async initialize() {
      const { CurrentMonthlyLossLimit, ExchangeRateKRWToGCO } = await this.$services.account.getLossLimit();
      const smallLimitExchangeRateCalc = bi_multiply(this.LossLimitOptionSmall, ExchangeRateKRWToGCO);
      const largeLimitExchangeRateCalc = bi_multiply(this.LossLimitOptionLarge, ExchangeRateKRWToGCO);
      this.SmallLossLimit = this.displayCoin(Number(smallLimitExchangeRateCalc));
      this.LargeLossLimit = this.displayCoin(Number(largeLimitExchangeRateCalc));
      this.SmallLossLimitKrw = this.displayCoin(Number(this.LossLimitOptionSmall));
      this.LargeLossLimitKrw = this.displayCoin(Number(this.LossLimitOptionLarge));

      this.modelInfo.RequestDailyLossLimit = isNaN(CurrentMonthlyLossLimit) ? this.LossLimitOptionLarge : CurrentMonthlyLossLimit;
      this.activeId = this.modelInfo.RequestDailyLossLimit === this.LossLimitOptionLarge ? 2 : 1;
    },
    async set() {
      await this.$services.onboarding.updateLossLimit(this.structure.model);
      this.isReady = false;
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-rg-loss-kr-setting] { .tc();
  > [font-icon] { .fs(30); }
  > .desc.xlg { .mv(8); }
  .my-information { .c(#fff); }
  > .result { .mt(40); .mh(30); .p(12); .br(4); .-a(#6f6f6f, 1);
    .desc.mlg { .mb(8); .c(#fff); }
    .desc.lg { .c(#fff); }
  }
  > p.error-msg { .mt(15); }
  .holder { .flex-center(); .space-around(); .gap(8); .w(90%); .mh-c(); .mt(40); .p(8); .-a(@c-w03); .br(4);
    >a { .w(50%); .br(4); .bgc(@c-w007); .pv(20);
      &.active { .c(white); .bgc(@c-w03);}
    }
  }
}
</style>
