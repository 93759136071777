<template>
  <div place-of-birth>
    <ValidationComponent name="_placeOfBirth" :label="$t('personalDetails.placeOfBirth')" :component="TrimTextInput" :rules="`requiredPlaceOfBirth:0,0,${modelInfo.CountryWritingSystem}`" v-model="modelInfo.PlaceOfBirth" :preset="preset"/>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'PlaceOfBirth',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  data() {
    return {
      TrimTextInput,
    };
  },
  computed: {
    preset() {
      return { maxLength: 50, disable: this.disabledPlaceOfBirth };
    },
    disabledPlaceOfBirth() {
      return this.modelInfo.blockFields?.includes('PlaceOfBirth') && !!this.modelInfo.PlaceOfBirth;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[place-of-birth] {  }
</style>