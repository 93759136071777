import { siteIds as sideIds, siteIds } from '@/constants/base/siteMap.mjs';
import { routeNames } from '@/constants/model/my-page';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export const rootRoute = 'MyInfo';
export const STEP = {
  DEFAULT: 'DEFAULT',
  INPUT: 'INPUT',
  VERIFY: 'VERIFY',
  CHECK: 'CHECK',
};

export const STATUS = {
  'VIEW': 'VIEW',
  'SUCCESS': 'SUCCESS',
  'ERROR': 'ERROR',
};

export const mfaLevel = {
  OFF: 'Off',
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High'
};

export const mfaMethod = {
  EMAIL: 'Email',
  MOBILE: 'Mobile',
  GOOGLE: 'GoogleAuthentication'
};

export const ggpassLevel = {
  'Off': 'OFF',
  'Low': 'LOW',
  'Medium': 'MEDIUM',
  'High': 'HIGH',
};

export const ggpassMethod = {
  GoogleAuthentication: 'GOOGLE',
  Mobile: 'MOBILE',
  Email: 'EMAIL'
};

export const socialType = {
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  APPLE: 'APPLE',
};

export const transferType = {
  CASH_OUT: 'CASH_OUT',
  ADD_CASH: 'ADD_CASH'
};

export const mfaSecurityType = { Off: 'Off', Notification: 'Notification', Verification: 'Verification' };

export const FUND_MODE = { FUND: 'fund', CHANGE: 'change', DISABLE: 'disable' };

export const KYC_STATUS = { SIGNED_UP: 'SignedUp', EMAIL_VERIFIED: 'EmailVerified', DETAILS_FILLED: 'DetailsFilled', AGE_VERIFIED: 'AgeVerified', POI_VERIFIED: 'PoiVerified', POA_VERIFIED: 'PoaVerified', EDD_VERIFIED: 'EddVerified' };
export const KYC_REQUEST_TYPE = { NONE: 'None', PERSONAL_DETAIL: 'PersonalDetail', POI: 'Poi', SELFIE_POI: 'SelfiePoi', POA: 'Poa', POI_POA: 'PoiPoa', EDD: 'EDD' };
export const kycMorePoiArray = [KYC_STATUS.POI_VERIFIED, KYC_STATUS.POA_VERIFIED, KYC_STATUS.EDD_VERIFIED];
export const kycVerified = [KYC_STATUS.POA_VERIFIED, KYC_STATUS.EDD_VERIFIED];


const useStateDropdownCountries = [];
const useCityDropdownCountries = ['CZ'];
const useLugasSiteIds = [siteIds.GGPDE];
const useOasisSiteIds = [siteIds.GGPDE];

export const kycDocumentStatus = { 0: 'pending', 1: 'confirmed', 2: 'rejected', 3: 'expired' };

export const usedFields = {
  [siteIds.GGPUKE]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.EVPUKE]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.SEVENXL]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.NATURAL8]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPOK]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPBE]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPBR]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPCW]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPDK]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPEU]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPFI]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPHU]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPJP]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPNG]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPPL]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPRO]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPUA]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.DAVAOPOKER]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPNL]: ['Address', 'City', 'PostalCode', 'Country', 'Nationality'],
  [siteIds.N8IN]: ['Address', 'State', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPCOM]: ['Address', 'State', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPCZ]: ['Street', 'StreetNumber', 'State', 'City', 'PostalCode', 'Country', 'Nationality'],
  [siteIds.GGPDE]: ['PlaceOfBirth', 'Street', 'HouseNumber', 'AdditionalAddress', 'State', 'City', 'PostalCode', 'Country', 'Nationality'],
  [siteIds.GGPPH]: ['Address', 'State', 'City', 'PostalCode', 'Country', 'Nationality'],
  [siteIds.GGPUK]: ['Building', 'Street', 'City', 'PostalCode', 'EmploymentType', 'Country'],
  [siteIds.WSOPON]: ['MiddleName', 'Alias', 'State', 'Address', 'City', 'PostalCode', 'OccupationType', 'EmployerName', 'EmployerAddress', 'BusinessPhoneNumber', 'Country', 'Nationality'],
  [siteIds.OCNP]: ['Address', 'City', 'Street', 'PostalCode', 'Country'],

  // TEST ALL Property ->
  // [siteIds.GGPCOM]: ['Address', 'State', 'City', 'PostalCode', 'Country', 'BuildingAddress', 'Street', 'City', 'PostalCode', 'EmploymentType', 'MiddleName', 'Alias', 'State','City', 'PostalCode', 'Occupation', 'EmployerName', 'EmployerAddress', 'BusinessPhoneNumber', 'Country', 'Nationality', 'PlaceOfBirth', 'Street', 'HouseNumber', 'AdditionalAddress', 'State', 'City', 'PostalCode', 'Country', 'Nationality'],
};


export const personalInfoProperties = {
  Address: { key: 'Address', name: '_address', label: 'address', type: 'text' },
  State: { key: 'State', name: '_stateProvince', label: 'stateProvince', type: 'custom' },
  City: { key: 'City', name: '_city', label: 'city', type: 'custom', },
  PostalCode: { key: 'PostalCode', name: '_postalCode', label: 'postalCode', type: 'text' },
  Street: { key: 'StreetName', name: '_street', label: 'street', type: 'text' },

  StreetNumber: { key: 'StreetNumber', name: '_streetNumber', label: 'streetNumber', type: 'text' },
  MaidenName: { key: 'MaidenName', name: '_lastNameAtBirth', label: 'lastNameAtBirth', type: 'text' },

  PlaceOfBirth: { key: 'PlaceOfBirth', name: '_placeOfBirth', label: 'placeOfBirth', type: 'text' },
  HouseNumber: { key: 'HouseNumber', name: '_houseNumber', label: 'houseNumber', type: 'text', },
  AdditionalAddress: { key: 'AdditionalAddress', name: '_additionalAddress', label: 'additionalAddress', type: 'text' },
  BuildingAddress: { key: 'BuildingAddress', name: '_buildingAddress', label: 'buildingAddress', type: 'text' },
  Building: { key: 'Building', name: '_buildingAddress', label: 'buildingAddress', type: 'text' },
  EmploymentType: { key: 'EmploymentType', name: '_employmentStatus', label: 'employmentStatus', type: 'custom' },
  MiddleName: { key: 'MiddleName', name: '_middleName', label: 'middleName', type: 'text' },
  Alias: { key: 'Alias', name: '_aliasPreferredName', label: 'aliasPreferredName', type: 'text' },
  EmployerName: { key: 'EmployerName', name: '_employerName', label: 'employerName', type: 'text' },
  EmployerAddress: { key: 'EmployerAddress', name: '_employerAddress', label: 'employerAddress', type: 'text' },
  BusinessPhoneNumber: { key: 'BusinessPhoneNumber', name: '_businessPhoneNumber', label: 'businessPhoneNumber', type: 'custom', preset: { maxLength: 14, telephone: true } },
  Country: {
    key: 'Country', name: '_country', label: 'country', type: 'custom', noWrapper: true
  },
  Nationality: {
    key: 'Nationality', name: '_nationality', label: 'nationality', type: 'custom', noWrapper: true
  },
  OccupationType: { key: 'OccupationType', name: '_occupation', label: 'occupation', type: 'custom' },
};



// My Document
export const unUseVerifyFlowList = [siteIds.SEVENXL, siteIds.DAVAOPOKER, siteIds.GGPUKE, siteIds.EVPUKE, siteIds.NATURAL8, siteIds.GGPOK];

export const KYC_STEP = {
  None: 'None',
  Done: 'Done',
  PersonalName: 'PersonalName',
  PersonalBirth: 'PersonalBirth',
  PersonalAddress: 'PersonalAddress',
  PersonalOccupation: 'PersonalOccupation',
  PersonalPep: 'PersonalPep',
  VerifyMobileNumber: 'VerifyMobileNumber',
  VerifyEmail: 'VerifyEmail',
  EditAccountInfo: 'EditAccountInfo',
  EditPersonalInfo: 'EditPersonalInfo',
  ShowReviewingKycMessage: 'ShowReviewingKycMessage',
  ShowPersonalInfoInconsistencyError: 'ShowPersonalInfoInconsistencyError',
  ShowMultipleAccountVerificationError: 'ShowMultipleAccountVerificationError',
  ShowAgeVerificationError: 'ShowAgeVerificationError',
  LifeTimeDepositLimit: 'LifeTimeDepositLimit',
  NotificationLoginInfo: 'NotificationLoginInfo',
  VerifyAge: 'VerifyAge',
  ShowExclusionCheckFailureMessage: 'ShowExclusionCheckFailureMessage',
  VerifyFundProtectionAcceptance: 'VerifyFundProtectionAcceptance',
  VerifyRgDepositSetting: 'VerifyRgDepositSetting',
  VerifyRgLoginTimeSetting: 'VerifyRgLoginTimeSetting',
  VerifyRgBalanceSetting: 'VerifyRgBalanceSetting',
  VerifyRgTotalBetSetting: 'VerifyRgTotalBetSetting',
  VerifyRgLossSetting: 'VerifyRgLossSetting',
  VerifyRgLossKrSetting: 'VerifyRgLossKrSetting',
  VerifyRgMonthlyLoginFrequencySetting: 'VerifyRgMonthlyLoginFrequencySetting',
  VerifyRgReLoginTimeSetting: 'VerifyRgReLoginTimeSetting',
  Lugas: 'Lugas',
  EditHighRiskMandatory: 'EditHighRiskMandatory',
  Deposit: 'Deposit',
  Verification: 'Verification',
  VerifyCrucks: 'VerifyCrucks',
  VerifyBankInfo: 'VerifyBankInfo',
  VerifySurePay: 'VerifySurePay',
  Poi: 'Poi',
  Poa: 'Poa',
  Jumio: 'Jumio',
  Signicat: 'Signicat',
  Payletter: 'Payletter',
  Edd: 'Edd',
  TransUnion: 'TransUnion',
  KycDE: 'KycDE',
  ReCheckPersonalDetails: 'ReCheckPersonalDetails',
  StartOnboarding: 'StartOnboarding',
  ConfirmPersonalInfo: 'ConfirmPersonalInfo',
  TempAccountGuide: 'TempAccountGuide',
  PersonalHomeAddress: 'PersonalHomeAddress',
  PersonalMailingAddress: 'PersonalMailingAddress',
  PersonalDataBox: 'PersonalDataBox',
  VerifyPaymentAccount: 'VerifyPaymentAccount',
  PersonalDetailReaffirm: 'PersonalDetailReaffirm',
  Screening: 'Screening',
  PersonalSelfDeclaration: 'PersonalSelfDeclaration',
  PersonalExtraAddress: 'PersonalExtraAddress',
  Sumsub: 'Sumsub'
};

// 각 나라의 통화별 최소 단위
// scale이 2 일 경우 소숫점 2자리 까지 => 0.01 0일 경우 => 1
export const currencyScaleModel = {
  'AMD': 2,
  'ARS': 2,
  'AUD': 2,
  'BRL': 2,
  'CAD': 2,
  'CHF': 2,
  'CLP': 0,
  'CNY': 2,
  'COP': 2,
  'CZK': 2,
  'DKK': 2,
  'EUR': 2,
  'GBP': 2,
  'GEL': 2,
  'HKD': 2,
  'HUF': 2,
  'IDR': 2,
  'INR': 2,
  'JPY': 0,
  'KHR': 2,
  'KRW': 0,
  'KZT': 2,
  'MXN': 2,
  'MYR': 2,
  'NOK': 2,
  'PHP': 2,
  'PLN': 2,
  'RON': 2,
  'RSD': 2,
  'RUB': 2,
  'SEK': 2,
  'SGD': 2,
  'THB': 2,
  'TWD': 2,
  'UAH': 2,
  'USD': 2,
  'VND': 0,
};

// Balance Transfer에서 Debit / Credit 의 ErrorCode가 동일 할때 사용
export const bothErrorCodes = [apiErrorCode.ACCOUNT_UNVERIFIED, apiErrorCode.RESPONSIBLE_GAMING, apiErrorCode.ACCOUNT_SUSPENDED, apiErrorCode.ACCOUNT_LOCK_BALANCE, apiErrorCode.ACCOUNT_CASHIER_BLOCK];

