<template>
  <div np-display-withdrwawal>
    <div class="info-header">
      <GpTitle :name="$t('myInfo.personalInfo.withdrawal')" />
      <GpBadge :type="verifiedType">{{ verifiedText }}</GpBadge>
    </div>
    <div class="display-group">
      <LabelComponent :label="$t('_.panNumber')" :value="panNumber" />
      <LabelComponent :label="$t('_.bankAccountNumber')" :value="bankAccount" />
    </div>

  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import CountryNumberDropDown from '@/views/components/gg-pass/CountryNumberDropDown.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap.mjs';
import siteModel, { routeNames } from '@/constants/model/my-page';
import LabelComponent from '@/views/components/gg-pass/LabelComponent.vue';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import TextButton from '@/views/components/gg-pass/TextButton.vue';
import GpBadge from '@/views/components/gg-pass/GpBadge.vue';

export default {
  name: 'DisplayWithdrawal',
  lexicon: 'myInfo.withdrawal',
  components: { GpBadge, TextButton, GpTitle, LabelComponent },
  props: {
    model: Specific
  },
  data() {
    return {
      isVerified: false,
      bankAccount: '',
      panNumber: ''
    };
  },
  computed: {
    paymentAccountVerifyStatus() {
      return { None: 'None', Created: 'Created', Reviewing: 'Reviewing', Approved: 'Approved', Denied: 'Denied' };
    },
    verifiedText() {
      return this.isVerified ? this.$t('verified') : this.$t('unverified');
    },
    verifiedType() {
      return this.isVerified ? 'SUCCESS' : 'FAILED';
    },
  },
  methods: {
    initialize() {
      const bankAccounts = [];
      this.isVerified = this.model?.PaymentAccountVerifyStatus === this.paymentAccountVerifyStatus.Approved;
      this.panNumber = this.model?.PanNumber;

      if (this.model?.BankAccountNumber) bankAccounts.push(this.model.BankAccountNumber);
      if (this.model?.Ifsc) bankAccounts.push(this.model.Ifsc);
      this.bankAccount = bankAccounts.length > 0 ? bankAccounts.join('/') : '';
    }
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-display-withdrwawal] {
  .info-header {.flex(); justify-content: space-between; .items-center(); }
  .display-group {.bgc(#fff);.p(20); .br(16); .flex(); .flex-dc();.w(100%); .gap(10);
    .bank-item {.c(black); .flex(); .items-center(); border-bottom: 1px solid #D3D6D9; .pv(20);
      &:nth-last-child(1) { border-bottom: none;}
      .label {flex: 1; .c(black); .fs(14); font-weight: 500;}
      .content {flex: 2; .fs(14);}
    }
  }

}
</style>