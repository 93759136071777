<template>
  <i font-icon :class="classname"/>
</template>

<script>
export default {
  name: 'FontIcon',
  props: {
    name: { type: String, required: true },
  },
  computed: {
    classname() {
      return `icon-${this.name}`;
    }
  }
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
</style>