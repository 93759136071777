<template>
  <div jumio-container :class="type">
    <FontIcon :name="icons[type]" />
    <slot />
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'JumioContainer',
  components: { FontIcon },
  props: {
    type: { type: String, default: 'fail' }, // success | fail | processing,
  },
  data() {
    return {
      icons: {
        success: 'check-thin',
        fail: 'exclamation-circle-regular-sharp',
        processing: 'auto-refresh',
        reviewing: 'clipboard-clock-regular',
      }
    };
  },
  computed: {}
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[jumio-container] {
  a[href^='mailto'] { .c(#0172ff); .underline; }
}
</style>
<style lang="less" scoped>
@import '~@/less/proj.less';
[jumio-container] { .flex; .flex-dc; .justify-center; .f; .ph(20); .tc; line-height: normal;
  [font-icon] { .mb(8); .fs(48); }
  &.success [font-icon] { .c(@c-blurRed); }
  &.fail [font-icon] { .c(@c-blurRed); }
  &.processing [font-icon] { .c(#d8d8d8); animation: rotate-circle 2s linear infinite; transform-origin: center; }
  &.reviewing [font-icon] { .c(#717171); }
  h3 { .regular; .fs(28); .c(@c-black); }
  .desc { .regular; .fs(15); .c(@c-text-desc);
    p { .mt(16); }
  }
  .contents { .mt(40); .p(16, 20); .br(4); .bgc(#efefef);
    p + p { .mt(12); }
  }
  [progress-button] { .w; .m; .mt(48); }

  @media (@ml-up) {
    .desc { .fs(16); }
  }
}
</style>
