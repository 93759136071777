import moment from 'moment';
import { pad } from './commonUtils.mjs';

export const utc = val => moment(val).utc();

/**
 * 날짜값 millisecond 값으로 변경
 * @param {string | moment.Moment} val
 */
export const getTime = val => +(moment(val).toDate());
export const getUtcTime = val => +(moment(val).toDate());
const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const renderHms = (sec, maintainHour) => {
  let s = Math.floor(sec);
  const h = Math.floor(s / HOUR);
  s -= h * HOUR;
  const m = Math.floor(s / MINUTE);
  s -= m * MINUTE;
  const hh = maintainHour || h > 0 ? `${pad(h, '0', 2)}:` : '';
  return `${hh + pad(m, '0', 2)}:${pad(s, '0', 2)}`;
};

export const remainDate = (store, target, unitOfTime = 'day') => {
  const v = moment(target).diff(moment(+new Date() + store.getters.timeDiff), unitOfTime);
  if (v < 0) return 0;
  return v;
};

/**
 * 남은 시간 표현
 * 남은 시간이 없다면 null 을 반환한다.
 * @param {Store} store
 * @param {number} target
 * @param {boolean?} maintainHour false 로 명시하면 hour 가 없을시 시간 부분은 노출되지 않음
 * @returns {string | null}
 */
export const remainHms = (store, target, maintainHour = true) => {
  const v = remainDate(store, target, 'second');
  if (v < 0) return null;
  return renderHms(v, maintainHour);
};

/**
 * 날짜포함 남은 시간 표현
 * 남은 시간이 없다면 null 을 반환한다.
 * @param {Store} store
 * @param {number|string} target
 * @param {?boolean} maintainHour
 * @returns {{day: number, hms: string, h: string, m: string, s: string, days: number} | null}
 */
export const remainDayHms = (store, target, maintainHour = false) => {
  const now = moment(+new Date() + store.getters.timeDiff);
  const then = moment(+target);
  let s = then.diff(now, 'second');
  if (s < 0) return null;
  const day = Math.floor(s / DAY);
  s -= day * DAY;
  const hms = renderHms(s, maintainHour);
  const days = Math.floor(then.toDate() / DAY / 1000) - Math.floor(now.toDate() / DAY / 1000);
  return { day, days, hms, h: hms.slice(0, 2), m: hms.slice(3, 5), s: hms.slice(6, 8) };
};

/**
 * 이미 지났는지 여부
 * @param {Store} store
 * @param {number|string} target
 * @returns {boolean}
 */
export const isPassed = (store, target) => moment(+target).isBefore(moment(+new Date() + store.getters.timeDiff));

/**
 * D-day
 * @param {Store} store
 * @param {number|string} dueDate
 * @returns {string}
 */
export const dday = (store, dueDate) => {
  if (!dueDate) return 'D-?';
  const d = moment(+new Date() + store.getters.timeDiff).diff(moment(+dueDate), 'day');
  if (d === 0) return 'D-day';
  return d > 0 ? `D+${d}` : `D${d}`;
};

/**
 * 00:00 ~ hour 까지 시간 목록 배열로 반환
 * D-day
 * @param {int} hour
 * @returns {string[]}
 */
export const hours = hour => {
  let n = 1;
  const list = [];
  while (hour >= n) {
    list.push(`${n < 10 ? `0${n}` : n}:00`);
    n++;
  }
  return list;
};

/**
 * 시작 - 끝 날짜 목록 배열로 반환
 * @param {string} s 'YYYY-MM-DD'
 * @param {string} e 'YYYY-MM-DD'
 * @returns {string[]|string}
 */
export const startToLast = (s, e) => {
  const regex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
  if (!(regex.test(s) && regex.test(e))) return 'error : date format';
  const result = [];
  const start = new Date(s);
  while (start <= new Date(e)) {
    result.push(start.toISOString().split('T')[0]);
    start.setDate(start.getDate() + 1);
  }
  return result;
};

export const fullDateDetail = milliSec => moment(milliSec).format('YYYY/MM/DD a h:mm');

export const fullDate = (milliSec, secFlag = false) => {
  if (secFlag) return moment(milliSec).format('YYYY-MM-DD HH:mm:ss');
  return moment(milliSec).format('YYYY-MM-DD HH:mm');
};

export const getYM = milliSec => moment(milliSec).format('YYYY-MM');
export const getY = milliSec => moment(milliSec).format('YYYY');
export const getM = milliSec => moment(milliSec).format('MM');
export const getYMD = milliSec => moment(milliSec).format('YYYY-MM-DD');

export const getHM = milliSec => moment(milliSec).format('HH:mm');

export const getDetailHM = milliSec => moment(milliSec).format('HH:mm:ss.SSS');

export const getEnHM = milliSec => moment(milliSec).locale('en').format('HH:mm:00 A');

export const getShortYMD = (milliSec, format = 'YY.MM.DD') => moment(milliSec).format(format);

export const getYear = () => moment().format('YYYY');
export const getToday = () => moment().format('YYYY-MM-DD');
export const getTodayEnd = () => moment().endOf('day').format('YYYY-MM-DD');
export const getTomorrow = () => moment().add(1, 'days').format('YYYY-MM-DD');
export const getWeekStart = () => moment().startOf('isoWeek').format('YYYY-MM-DD');
export const getWeekBefore = weeks => moment().startOf('isoWeek').add(weeks, 'week').format('YYYY-MM-DD');
export const getWeekEnd = (day = 6) => moment().startOf('isoWeek').add(day, 'days').format('YYYY-MM-DD');
export const getMonthEnd = (m = 1) => moment().startOf('isoWeek').add(m, 'month').format('YYYY-MM-DD');
export const getPerWeekEnd = (d, day = 6) => moment(d).startOf('isoWeek').add(day, 'days').format('YYYY-MM-DD');
export const getPerMonthEnd = (d, m = 1) => moment(d).add(m, 'months').add(-1, 'day').format('YYYY-MM-DD');
export const customDateFormat = (milliSec, format = 'YYYY-MM-DD') => {
  const z = milliSec !== undefined && String(milliSec)?.indexOf('Z') >= 0 ? utc : moment;
  return z(new Date(milliSec)).format(format);
};

export const getYYYYMMDDTHHmmssZ = milliSec => moment(milliSec).format('YYYY-MM-DDTHH:mm:ss[Z]');

export const getMMDDTHHmmssZ = milliSec => moment(milliSec).format('MM-DDTHH:mm:ss[Z]');

export const postDateFormat = (date, end) => `${moment(date ? new Date(date) : '').format(`YYYY-MM-DDT${end ? '23:59:00.000' : '00:00:00.000'}`)}Z`;

export const getPastFromNow = duration => moment().subtract(duration).valueOf();

export const defaultRange = () => ({ start: getYMD(), end: getYMD(moment().add(1, 'day')) });

export const timeValue = value => {
  const format = 'YYYY-MM-DD HH:mm:ss';
  const b = moment(customDateFormat(value, format));
  const now = moment(utc().format(format));
  const diff = moment.duration(now.diff(b));
  const gapH = Math.floor(diff.asHours());
  const gapM = Math.floor(diff.asMinutes());
  const gapD = Math.floor(diff.asDays());
  if (gapM < 5) return 'Just now';
  if (gapH < 1) return `${gapM} minutes ago`;
  if (gapH < 2) return `An hour ago`;
  if (gapD < 1) return `A Day ago`;
  return customDateFormat(value, format);
};

/**
 * @param {moment} target
 * @param {Duration} duration
 * @returns {number}
 */
export function addDuration(target, duration) {
  const d = moment.duration(duration);
  return target.add(d).valueOf();
}
