<template>
  <Modal upload-error-modal theme="dark" @close="close">
    <template #title>Uploaded Documents</template>
    <ul class="list-holder">
      <li v-for="(item, id) in options.info" :key="id" :class="isError(item) ? 'failed' : 'successful'">
        <UploadItem :info="item" :use-icon="true" :status="isError(item) ? item.status.desc : item.type" :error="isError(item)" />
      </li>
    </ul>
    <div class="bts">
      <ColorButton class="try-again" @click="close">Try Again</ColorButton>
      <ColorButton v-if="hasPassed" class="confirm" @click="$emit('resolve', true)">Confirm</ColorButton>
    </div>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import UploadItem from '@/views/components/extra/UploadItem.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'UploadErrorModal',
  components: { UploadItem, Modal, ColorButton },
  props: {
    options: /** @types {info: [{ img, type, file }], type: poi | poa} */ Specific,
  },
  data() {
    return {
      hasPassed: false,
    };
  },
  methods: {
    isError(item) {
      return item.status.code === 400;
    },
    close() {
      this.$emit('resolve', false);
    }
  },
  mounted() {
    this.hasPassed = Boolean(this.options.info.find(o => !this.isError(o)));
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[upload-error-modal] {
  .list-holder {
    li {
      & + li  { .pt(12); .mt(12); .-t(@c-w02); }
      [upload-item] { .pl(12);
        label::before { .mr(4); .bold(); .vam(); font-family: 'icomoon'; }
      }
      &.successful {
        [upload-item] { .-l(#1db77d, 4);
          label::before { .cnt('\ee67'); .c(#1db77d); }
        }
      }
      &.failed {
        [upload-item] { .-l(#c64040, 4);
          label::before { .cnt('\eea2'); .c(#c64040); }
        }
      }
    }
  }
  [color-button].try-again { .bgc(#c64040); }
  [color-button].confirm { .bgc(#717171); }
}
</style>
