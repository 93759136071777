<template>
  <div lugas>
    <LugasDeposit v-if="isDepositView" :structure="structure" @input="update" @update-status="changeStatus" @next="close" />
    <LugasResult v-if="isResultView" :newLimit="newLimit" :currentLimit="currentLimit" :availableDate="availableDate" @next="next" />
  </div>
</template>

<script>
import { close } from '@/utils/clientUtil';
import LugasDeposit from '@/views/components/pages/on-boarding/template/kyc/lugas/LugasDeposit.vue';
import LugasResult from '@/views/components/pages/on-boarding/template/kyc/lugas/LugasResult.vue';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'Lugas',
  components: { LugasResult, LugasDeposit },
  mixins: [baseTemplate],
  data() {
    return {
      isDepositView: false,
      isResultView: false,
      newLimit: null,
      currentLimit: null, // [kate] 재 설정 시 에만 표시되는 데이터로 문서 확인 되면 작업 필요
      availableDate: null, // [kate] 재 설정 시 에만 표시되는 데이터로 문서 확인 되면 작업 필요
    };
  },
  methods: {
    update(value) {
      this.newLimit = value;
    },
    changeStatus(status) {
      if (status) {
        this.isResultView = true;
        this.isDepositView = false;
      }
    },
    close() {
      close(this);
    },
    next() {
      this.modelInfo.step = 1;
    }
  },
  mounted() {
    this.isDepositView = true;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[lugas] {
  .desc { .regular() !important; .c(#d8d8d8) !important; }
}
</style>
