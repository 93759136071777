export default class ToastProxy {
  #app;
  #toastQue = [];

  /**
   * @param {string|string[]} message
   * @param {{translate?: boolean, type?: 'success'|'fail', dura?: number}?} options
   * @returns {void}
   */
  toast(message, options) {
    if (this.#app) this.#app.$toast(message, options);
    else this.#toastQue.push({ message, options });
  }

  setApp(app) {
    this.#app = app;
    while (this.#toastQue.length) {
      const arg = this.#toastQue.shift();
      this.#app.$toast(arg.message, arg.options);
    }
  }
}
