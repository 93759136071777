/**
 * @param {object} raw
 * @returns {object}
 */
export const flattenGroup = raw => Object.keys(raw)
  .map(groupKey => Object.keys(raw[groupKey])
    .map(key => ({[(groupKey === 'common' ? '' : groupKey+'.')+key]: raw[groupKey][key]}))
  )
  .reduce((groupAcc, group) => ({
    ...groupAcc,
    ...group.reduce((nodeAcc, node) => ({ ...nodeAcc, ...node }), {})
  }), {});

/**
 * @param {object[]} arr
 * @param {string} key
 * @returns {object}
 */
export const groupBy = (arr, key) => {
  const acc = {};
  arr.forEach(o => (acc[o[key]] || (acc[o[key]] = [])).push(o));
  return acc;
};

/**
 * @param {object} raw
 * @returns {object}
 */
export const flattenObject = raw => {
  const acc = {}, keyStack = [];
  let obj = raw, keys = Object.keys(obj), i = 0, prev = null;
  while (i < keys.length) {
    const key = keys[i];
    const current = obj[key];
    if (typeof current === 'object') {
      keyStack.push(key);
      prev = [obj, keys, i + 1, prev];
      obj = current;
      keys = Object.keys(current);
      i = 0;
    } else {
      acc[[...keyStack, key].join('.')] = current;
      i += 1;
    }
    if (i >= keys.length) {
      keyStack.pop();
      if (prev) [obj, keys, i, prev] = prev;
    }
  }
  return acc;
}

/**
 * @param {object[]} arr
 * @param {string} key
 * @returns {array}
 */
export const groupByAsArray = (arr, key) => Object.values(groupBy(arr, key));

/**
 * @param {object} target
 * @param {object} from
 */
export const setValuesBy = (target, from) => Object.keys(target).forEach(k => target[k] = from[k]);


export const isObjectEmpty = obj => !obj || Object.keys(obj).length === 0 || Object.values(obj).every(v => v === null || v === undefined);

export const trimObject = obj => {
  if (!isObjectEmpty(obj)) return {};
  const result = {};
  Object.keys(obj).forEach(k => {
    const v = obj[k];
    if (v !== null && v !== undefined) result[k] = v;
  });
  return result;
};
