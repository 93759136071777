export default {
  methods: {
    close() {
      this.$emit('close');
    },
    setScrollHeight() {
      /** @type {HTMLElement} */
      const titleArea = this.$el.querySelector('.panel > .header');
      /** @type {HTMLElement} */
      const scrollArea = this.$el.querySelector('.panel > .scroll-area');
      scrollArea.style.maxHeight = `${window.innerHeight - titleArea.offsetHeight - (this.matchedMediaDevice === 'M' ? 40 : 80)}px`;
    }
  },
  mounted() {
    this.setScrollHeight();
    window.addEventListener('resize', this.setScrollHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setScrollHeight);
  }
}
