<template>
  <Modal ro-message-modal @close="close">
    <p class="msg" v-html="$t('signUp.roRelayMessage')" />
    <ProgressButton button-name="confirm" @click="close" />
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
export default {
  name: 'RoMessageModal',
  components: { ProgressButton, Modal },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[ro-message-modal] {
  .panel.large { .bgc(#333); .c(white); .tl(); .fs(14, 20);
    .header { .bgc(#333);}
    .scroll-area { .pv(20); }
    [progress-button] { .mt(28); }
    a { .c(@c-link);}
  }
}
</style>