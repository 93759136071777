import VueGtm from '@gtm-support/vue2-gtm';

export default {
  install(Vue, { id, router }) {
    Vue.use(
      VueGtm,
      {
        id,
        loadScript: true,
        vueRouter: router,
        trackOnNextTick: true
      }
    );
  }
};
