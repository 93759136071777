import directive from '@shared/plugins/popover/directive';
import { getBrowserLang, setSiteInfo } from '@/utils/baseUtil';
import app from '@/app';
import modal from '@shared/plugins/modal';
import toast from '@shared/plugins/toast';
import scrollAgency from '@shared/plugins/scrollAgency';
import browser from '@shared/modules/Browser';
import { NoToast } from '@shared/modules/ToastErrorHandler';
import forgotPassword from '@/plugins/applicationController/forgotPassword';
import onBoarding from '@/plugins/applicationController/onboarding';
import promotion from '@/plugins/applicationController/promotion';
import myInfo from '@/plugins/applicationController/myInfo';
import responsibleGaming from '@/plugins/applicationController/responsibleGaming';
import findId from '@/plugins/applicationController/findId';
import sign from '@/plugins/applicationController/sign';
import { parseQuery } from '@shared/utils/urlUtils.mjs';
import { getSiteId } from '@/constants/base/signup/signUpSiteMap';

export default app.getEntryClient({
  beforeCreate: async (Vue, store, router, services) => {
    Vue.config.productionTip = false;
    Vue.use(modal);
    Vue.use(toast);
    Vue.use(scrollAgency, { store });
    Vue.directive('popover', directive(Vue));

    let lang = store.state.query.selectedLang || getBrowserLang() || services.cookie.getLanguage() || store.state.env.siteInfo?.lang || 'en';
    if (!store.state.env.selectedLang && store.state.env.siteInfo && !store.state.env.siteInfo.langList?.find(o => o.value === lang)) lang = store.state.env.siteInfo.lang || 'en';
    services.cookie.setLanguage(lang);
    store.commit('env/setBaseLang', lang);

    if(store.state.query.app === 'onboarding') Vue.use(onBoarding);
    else if (store.state.query.app === 'signup') Vue.use(sign);
    else if(store.state.query.app === 'fp') Vue.use(forgotPassword, { services, store, router });
    else if(store.state.query.app === 'promotion') Vue.use(promotion, { store, router});
    else if(store.state.query.app === 'myinfo') Vue.use(myInfo, { services, store, router });
    else if(store.state.query.app === 'rg') Vue.use(responsibleGaming, { services, store, router });
    else if (store.state.query.app === 'fi') Vue.use(findId, {services, store, router});
  },
  makeRouteErrorHandler: (services, router) => async e => {
    const { code } = e;
    if (code === 301 || code === 302) {
      if (/^https?:\/\//.test(e.to)) window.location.href = e.to;
      else await router.replace(e.to);
    }
    if (code === 404) await router.push('/404');
    if (code === 401 || code === 403) {
      services.toast.toast(['httpErrors', code], { type: 'fail' });
      if (code === 401) {}
      throw NoToast;
    }
  },
  afterCreate: (app, router, store) => {
    browser.useScrollHistory({ router, store, scrollHandler: app.$scroll });
  },
  afterMount: (app, route, store) => {
    browser.sync(store);
  },
});
