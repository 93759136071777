import { getObjectIndexByProperty } from '@/utils/valueUtil';
import { apiErrorCode, apiErrorType } from '@/constants/base/apiErrorCode';
import { errorInfo } from '@/modules/ApiErrorInfo';

class ApiErrorController {
  #info;
  // 단일로 혼자 존재할 경우 바로 Error 객체 저장, 2개 이상의 그룹일 경우 자기 자신도 포함하여 그룹으로 저장
  // constructor() null;
  proxy(/** @type {{site: String, code:String, customCode: String|null|undefined}} */ r) {
    if (!r || !r.site || !r.code) return null;
    if (!r.customCode) r.customCode = r.code;
    if (!this.#info) this.#info = errorInfo();

    switch (r.code) {
      case apiErrorCode.ACCOUNT_SUSPENDED:
        return this.#getAccountSuspended(r.site, r.code, r.customCode);
      case apiErrorCode.ACCESS_DENIED:
        return this.#getAccessDenied(r.site, r.code, r.customCode);
      case apiErrorCode.ACCOUNT_LOCKED:
        return this.#getAccountLocked(r.site, r.code, r.customCode);
      default:
        return this.#getTemplate(r.site, r.code, r.customCode);
    }
  }

  #getAccountSuspended(site, code, customCode) {
    if (![apiErrorCode.ACCOUNT_LOGIN_ATTEMPS_FAILED_LOCKED, apiErrorCode.ACCOUNT_LOGIN_REMAIN_LIMIT, apiErrorCode.ACCOUNT_LOGIN_REMAIN_LIMIT_OVER_LOCKED, apiErrorCode.COMPLIANCE_CHECK_PERIOD, apiErrorCode.CLOSED, apiErrorCode.COMPLIANCE_CHECK, apiErrorCode.SECURITY, apiErrorCode.MULTIACC_CHECK, apiErrorCode.EXCLUSION_CHECK, apiErrorCode.BLOCKEDLISTACC_CHECK, apiErrorCode.ACCOUNT_SUSPENDED].includes(customCode)) customCode = apiErrorCode.ETC;
    return this.#getTemplate(site, code, customCode);
  }

  #getAccessDenied(site, code, customCode) {
    return this.#getTemplate(site, code, customCode);
  }

  #getAccountLocked(site, code, customCode) {
    if (![apiErrorCode.ACCOUNT_LOGIN_LIMIT_ACCESS, apiErrorCode.GAMSTOP_SELF_EXCLUDED, apiErrorCode.CRUKS_SELF_EXCLUDED, apiErrorCode.AISG_SELF_EXCLUDED, apiErrorCode.ACCOUNT_LOCKED, apiErrorCode.OASIS_BAN_EXIST].includes(customCode)) customCode = apiErrorCode.ETC;
    return this.#getTemplate(site, code, customCode);
  }

  #isErrorCodeOnly(code) {
    // code 만 있을 경우 (customCode 무관하게 공통으로 쓰일 경우
    return this.#info[code] && Object.keys(this.#info[code]).some(key => [apiErrorType.AUTH, apiErrorType.VERIFICATION].includes(key));
  }

  #hasCustomErrorCode(code, customCode) {
    // code, customCode 둘 다 있을 경우
    return this.#info[code]?.[customCode] && Object.keys(this.#info[code]?.[customCode]).some(key => [apiErrorType.AUTH, apiErrorType.VERIFICATION].includes(key));
  }

  #getAuthTemplate(site, code, customCode) {
    const codeItem = this.#info[code];
    return this.#isErrorCodeOnly(code) ? codeItem[apiErrorType.AUTH]?.[site] || codeItem[apiErrorType.AUTH]?.default :
      this.#hasCustomErrorCode(code, customCode) ? codeItem[customCode][apiErrorType.AUTH]?.[site] || codeItem[customCode][apiErrorType.AUTH]?.default : null;
  }

  #getVerificationTemplate(site, code, customCode) {
    const codeItem = this.#info[code];
    return this.#isErrorCodeOnly(code) ? codeItem[apiErrorType.VERIFICATION]?.[site] || codeItem[apiErrorType.VERIFICATION]?.default :
      this.#hasCustomErrorCode(code, customCode) ? codeItem[customCode][apiErrorType.VERIFICATION]?.[site] || codeItem[customCode][apiErrorType.VERIFICATION]?.default : null;
  }

  #getTemplate(site, code, customCode) {
    if (!site || !code) return;
    // 현재는 auth와 verification 자체가 복수(array)가 없다는 전제로 진행하긴 하였으나 복수일 경우라면 로직 변경 필요 (복수 일 경우 그중 하나를 찾는 구분자 처리 또한 추가로 필요하나 모호함)
    const authTemplate = this.#getAuthTemplate(site, code, customCode);
    const verificationTemplate = this.#getVerificationTemplate(site, code, customCode);
    const path = authTemplate ? 'AuthError' : verificationTemplate ? 'VerificationFailed' : null;
    return {
      path,
      queries: { code, customCode },
      [apiErrorType.AUTH]: authTemplate,
      [apiErrorType.VERIFICATION]: verificationTemplate,
      ci: getObjectIndexByProperty(apiErrorCode, code),
      cci: getObjectIndexByProperty(apiErrorCode, customCode)
    };
  }
}

const controller = new ApiErrorController();
export default controller;