<template>
  <div not-provide>
    <ErrorTitle title="notProvide.title" description="notFound.description" :options="errorOption"/>
  </div>
</template>

<script>
import { appendQuery } from '@shared/utils/urlUtils.mjs';
import { state } from '@shared/utils/storeUtils.mjs';
import { queries } from '@/utils/baseUtil';
import ErrorTitle from '@/views/components/extra/ErrorTitle.vue';

export default {
  name: 'NotProvide',
  components: { ErrorTitle },
  data() {
    return {
      isReady: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    ggpass: state('env', 'ggpass'),
    csEmail: state('env', 'csEmail'),
    errorOption() {
      return { name: this.csEmail || 'help@ggpoker.com' };
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[not-provide] { .rel(); .wh(100%, calc(var(--innerHeight) - 160px));
  [error-title] { .abs(); .lt(50%, 50%); .t-xyc();}
}
</style>