export default class KycService {
  #services;
  #proxyNpPublicApi;
  #proxyNpUserApi;
  constructor(services) {
    this.#services = services;
    this.#proxyNpPublicApi = services.proxyNpPublicApi;
    this.#proxyNpUserApi = services.proxyNpUserApi;
  }

  /**
   * @description Payletter 인증 결과 업데이트
   * @id UpdatePayletterVerificationResultRequest
   * @param CallbackBody
   * @returns {*}
   */
  updatePayletter({ CallbackBody }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/kyc/verification/twoace/payletter', { CallbackBody });
    } catch (e) {
      console.log('error : updatePayletter', e);
      throw e;
    }
  }

  /**
   * @description paytletter 인증방식 선택 시 popup url 반환
   * @id GetPayletterVerificationUrlRequest
   * @param {number} method - 0: Mobile, 1: CreditCard
   * @param {string} redirectUrl - 응답받을 수 있는 주소 전달
   * @param config
   * @returns {Promise<PayletterVerificationUrl>}
   */
  async verifyPayletter(method, redirectUrl, config) {
    try {
      return await this.#proxyNpUserApi.proxyGet(`/player/kyc/verification/twoace/payletter/url/${method}`, { RedirectUrl: redirectUrl }, config);
    } catch (e) {
      console.log('error : verifyPayletter', e);
      throw e;
    }
  }

  /**
   * paytletter 인증 결과 반환
   * @id GetPayletterVerificationResultRequest
   * @param {string} token - Payletter 인증 URL 받기 API에서 받은 PayletterToken 값
   * @returns {Promise<KycTwoAceVerificationStatus>}
   */
  async getPayletterStatus(token, config) {
    try {
      return await this.#proxyNpUserApi.proxyGet(`/player/kyc/verification/twoace/payletter/status/${token}`, null, config);
    } catch (e) {
      console.log('error : getPayletterStatus', e);
      throw e;
    }
  }

  /**
   * HPF Credit card 인증 상태 조회
   * @id GetPaymentAuthorizationStatusRequest
   * @param {string} transactionId - HPF Credit card 인증 요청 API에서 받은 TransactionId 값
   * @returns {Promise<{PaymentProcessStatus: string}>} - Completed / Failed / Cancelled / Refunded / Default
   */
  async getHPFStatus(transactionId) {
    try {
      return await this.#proxyNpUserApi.proxyGet(`/payments/authorization/${transactionId}/status`);
    } catch (e) {
      console.log('error : getHPFStatus', e);
      throw e;
    }
  }

  /**
   * Jumio 인증 URL 받기
   * @id PaymentPraxisCardGatewayAuthorizationJumioVerificationRequest
   * @param {string} TransactionId - HPF Credit card 인증 요청 API 또는 HPF Credit card 인증 상태 조회 API에서 받은 TransactionId 값
   * @param {string} Language- 현재 표기 언어 코드
   * @param {string} RequestHost- 결과 받을 callback URL
   * @returns {Promise<{Url : string}>}
   */
  async getHPFJumioAuthUrl({ TransactionId, Language, RequestHost }) {
    try {
      return await this.#proxyNpUserApi.proxyPost(`/payments/jumio/name-verification`, { TransactionId, Language, RequestHost });
    } catch (e) {
      console.log('error : getHPFJumioAuthUrl', e);
      throw e;
    }
  }

  /**
   * Jumio 인증 상태 조회
   * @id GetPaymentJumioVerificationStatusRequest
   * @param {string} TransactionId - Jumio 결과 callback url로 받은 jumioIdScanReference query string 값
   * @returns {Promise<*>}
   */
  async getHPFJumioAuthStatus(TransactionId) {
    try {
      /**
       * DEV/TEST 전용 추가 필요
       * IsCardNumberMatched: Card Number Matched 체크 시 true
       * IsNameMatched: Name Matched 체크 시 true
       * IsDiscarded: Discarded 체크 시 true
       * IsFailed: IsFailed 체크 시 true
       */
      return await this.#proxyNpUserApi.proxyGet(`/payments/jumio/name-verification`, { TransactionId });
    } catch (e) {
      console.log('error : getHPFJumioAuthStatus', e);
      throw e;
    }
  }

  /**
   * Reviewing 요청 API
   * @id GetReviewingRequest
   * @param {string} TransactionId - Jumio 결과 callback url로 받은 jumioIdScanReference query string 값
   * @returns {Promise<void>}
   */
  async getHPFJumioReviewingUrl({ TransactionId }) {
    try {
      return await this.#proxyNpUserApi.proxyGet(`/payments/jumio/reviewing`, { TransactionId });
    } catch (e) {
      console.log('error : getHPFJumioReviewingUrl', e);
      throw e;
    }
  }

  /**
   * @id GetPlayerKycUploadStatusInfoRequest
   * @param {boolean} IsConsiderKycStatusWhenKycNotRequested
   * @returns {Promise<PlayerKycStatus>}
   * */
  async getPlayerKycUploadStatus(IsConsiderKycStatusWhenKycNotRequested = false, silent = false) {
    try {
      return await this.#proxyNpUserApi.proxyGet('/player/kyc/upload-statusinfo', { IsConsiderKycStatusWhenKycNotRequested }, { silent: silent });
    } catch (e) {
      console.log('error : getPlayerKycUploadStatus', e);
      throw e;
    }
  }

  /**
   * @id GetPlayerRecentKycDocumentsRequest
   * @returns {Promise<PlayerKycDocument>}
   * */
  async getPlayerKycDocuments() {
    try {
      return await this.#proxyNpUserApi.proxyGet('/player/kyc/documents/recent', null , { silent: true});
    } catch (e) {
      console.log('error : getPlayerKycDocuments', e);
      throw e;
    }
  }

  /**
   * @id AddPlayerKycDocumentRequest
   * @param {number} DocumentType
   * @param {string} FileData
   * @param {string} FileName
   * @param {boolean?} IsProofOfAffordability
   * @param {boolean?} IsPreRegister
   * */
  async setAddKycDocument({ DocumentType, FileData, FileName, IsProofOfAffordability = false, IsPreRegister }) {
    try {
      return await this.#proxyNpUserApi.proxyPost('/player/kyc/documents', { DocumentType, FileData, FileName, IsProofOfAffordability, IsPreRegister });
    } catch (e) {
      console.log('error : setAddKycDocument', e);
      return e; // 에러 발생 시 응답결과를 받아 핸들링하는 부분이 있으므로 throw가 아닌 return처리
    }
  }

  /**
   * @id SubmitPlayerKycRequisitionRequest
   * @param {string} DocumentTypes
   * */
  async setKycRequisition(DocumentTypes) {
    try {
      return this.#proxyNpUserApi.proxyPost('/player/kyc/requisitions/submit', { DocumentTypes });
    } catch (e) {
      console.log('error : setKycRequisition', e);
      throw e;
    }
  }

  /**
   * @id DeclarateAffordabilityRequest
   * @description EDD 입력 폼 내용 업데이트
   * @param EmploymentType - Employment Status 선택값
   * @param OccupationType - Occupation 선택값
   * @param EmployerName - 고용주 이름
   * @param DisposableIncome - 소득 선택
   * @param DesiredLimitAmountDaily - daily limit 사용자 입력 값
   * @param DesiredLimitAmountWeekly - weekly limit 사용자 입력 값
   * @param DesiredLimitAmountMonthly - monthly limit 사용자 입력 값
   * @returns {Promise<*>}
   */
  async setDeclarateAffordability({ EmploymentType, OccupationType, EmployerName, DisposableIncome, DesiredLimitAmountDaily, DesiredLimitAmountWeekly, DesiredLimitAmountMonthly }) {
    try {
      return this.#proxyNpUserApi.proxyPost('/player/kyc/affordability', { EmploymentType, OccupationType, EmployerName, DisposableIncome, DesiredLimitAmountDaily, DesiredLimitAmountWeekly, DesiredLimitAmountMonthly });
    } catch (e) {
      console.log('error : setDeclarateAffordability', e);
      throw e;
    }
  }

  /**
   * @id PaymentPraxisHPFSessionTokenRequest
   * */
  async requestHpfToken({ Language, PSPName, ReturnUrl }) {
    try {
      await this.#proxyNpUserApi.proxyPost('/payments/deposit/praxishpf/token', { Language, PSPName, ReturnUrl });
    } catch (e) {
      console.log('error : requestHpfToken', e);
      throw e;
    }
  }

  /**
   * @id GetPlayerKycStatusInfoRequest
   * KYC 레벨과 트리거 정보를 반환
   * @returns {Promise<PlayerKycStatus>}
   * */
  async getPlayerKycStatus() {
    try {
      return await this.#proxyNpUserApi.proxyGet('/player/kyc/statusinfo');
    } catch (e) {
      console.log('error : getPlayerKycStatus', e);
      throw e;
    }
  }

  /**
   * @description Jumio Identity 인증 결과 조회
   * @id VerifyKycOutsideIdentityRequest
   * @param {string} PlayerStamp
   * @param {string} TransactionId
   * @returns { Promise<KycIdentityStatus>}
   * */
  async patchKycIdentity({ PlayerStamp, TransactionId }) {
    try {
      return await this.#proxyNpUserApi.proxyPatch('/player/kyc/outside-verification/identity', { PlayerStamp, TransactionId }, { silent: true });
    } catch (e) {
      console.log('error : patchKycIdentity', e);
      throw e;
    }
  }

  /**
   * @description Jumio Document 인증 결과 확인
   * @id VerifyKycOutsideDocumentRequest
   * @param {string} PlayerStamp
   * @param {string} TransactionId
   * @returns { Promise<KycDocumentStatus>}
   * */
  async patchKycDocument({ PlayerStamp, TransactionId }) {
    try {
      return await this.#proxyNpUserApi.proxyPatch('/player/kyc/outside-verification/document', { PlayerStamp, TransactionId }, { silent: true });
    } catch (e) {
      console.log('error : patchKycDocument', e);
      throw e;
    }
  }

  /**
   * @id InitializeSumsubRequest
   * 초기 설정 처리 후 AccessToken을 반환
   * @returns {Promise<SumsubAccessToken>}
   */
  async initializeSumsub() {
    try {
      return await this.#proxyNpUserApi.proxyGet('/player/sumsub/init');
    } catch (e) {
      console.log('error : initSumsub', e);
      throw e;
    }
  }

  /**
   * @id GetSumsubStatusRequest
   * 현재 진행중인 Sumsub 상태를 반환
   * @returns {Promise<SumsubStatus>}
   * */
  async verifySumsubStatus() {
    try {
      return await this.#proxyNpUserApi.proxyGet('/player/sumsub/status', null, { silent: true });
    } catch (e) {
      console.log('error : getSumsubStatus', e);
      throw e;
    }
  }

  /**
   * @id GetSumsubAccessTokenRequest
   * 갱신된 AccessToken을 반환
   * @returns {Promise<SumsubAccessToken>}
   */
  async getSumsubAccessToken() {
    try {
      return await this.#proxyNpUserApi.proxyGet('/player/sumsub/access-token');
    } catch (e) {
      console.log('error : getSumsubAccessToken', e);
      throw e;
    }
  }

  /**
   * @id UpdateSumsubStatusReadyRequest
   * update Sumsub status to Ready (플로우 변경으로 현재 사용 안함)
   * @returns {Promise<*>}
   */
  async updateSumsubStatusToReady() {
    try {
      return await this.#proxyNpUserApi.proxyPatch('/player/sumsub/status/ready');
    } catch (e) {
      console.log('error : updateSumsubStatusToReady', e);
      throw e;
    }
  }

  /**
   * @id UpdateSumsubStatusPendingRequest
   * update Sumsub status to Pending  (플로우 변경으로 현재 사용 안함)
   * @returns {Promise<*>}
   */
  async updateSumsubStatusToPending() {
    try {
      return await this.#proxyNpUserApi.proxyPatch('/player/sumsub/status/pending');
    } catch (e) {
      console.log('error : updateSumsubStatusToPending', e);
      throw e;
    }
  }

  /**
   * @description Sofort URL 조회
   * @id GetSofortUrlRequest
   * @param RequestHost
   * @param IsFromExternalUrl
   * @returns <Promise<{Url: string}>>
   */
  async getSofortUrl({ RequestHost, IsFromExternalUrl = false }) {
    try {
      return await this.#proxyNpUserApi.proxyGet('/player/verification/sofort/url', { RequestHost, IsFromExternalUrl });
    } catch (e) {
      console.log('error : getSofortUrl', e);
      throw e;
    }
  }

  /**
   * @description Sofort 결과 업데이트
   * @id UpdateSofortResultRequest
   * @param QueryString
   * @returns {Promise<*>}
   */
  async verifySofort({ QueryString }) {
    try {
      return await this.#proxyNpUserApi.proxyPut('/player/verification/sofort/result', QueryString);
    } catch (e) {
      console.log('error : verifySofort', e);
      throw e;
    }
  }

  /**
   * @description Signicat 신원 인증 상태 조회
   * @id VerifyKycSignicatIdentityRequest
   * @param {string} RedirectUrl
   * @param {string} Language
   * @returns {Promise<VerifySignicatIdentity>}
   * */
  async verifySignicatIdentity({ RedirectUrl, Language = 'en' }) {
    try {
      return await this.#proxyNpUserApi.proxyPost('/player/kyc/verification/signicat/identity', { RedirectUrl, Language });
    } catch (e) {
      console.log('error : verifySignicatIdentity', e);
      throw e;
    }
  }

  /**
   * @description Cancel Signicat
   * @id VerifyKycSignicatIdentityRequest
   * @returns {Promise<*>}
   * */
  async verifySignicatCancelIdentity() {
    try {
      return await this.#proxyNpUserApi.proxyPost('/player/kyc/verification/signicat/identity/cancel');
    } catch (e) {
      console.log('error : verifySignicatCancelIdentity', e);
      throw e;
    }
  }

  /**
   * @description Signicat 뱅크 인증 상태 조회
   * @id VerifyKycSignicatBankRequest
   * @param {string} RedirectUrl
   * @param {string} Language
   * @returns {Promise<VerifySignicatBank>}
   */
  async verifySignicatBank({ RedirectUrl, Language }, config) {
    try {
      return await this.#proxyNpUserApi.proxyPost('/player/kyc/verification/signicat/bank', { RedirectUrl, Language }, config);
    } catch (e) {
      console.log('error : verifySignicatBank', e);
      throw e;
    }
  }

  /**
   * @description IBAN 업데이트
   * @id VerifyKycSignicatSurePayRequest
   * @param {string} IbanNumber
   * @returns {Promise<*>}
   */
  async verifySignicatSurePay({ IbanNumber }) {
    try {
      return await this.#proxyNpUserApi.proxyPost('/player/kyc/verification/signicat/surepay', { IbanNumber });
    } catch (e) {
      console.log('error : verifySignicatSurePay', e);
      throw e;
    }
  }

  /**
   * @id GetKycSignicatVerificationStepRequest
   * @returns {Promise<SignicatStep>}
   * */
  async getSignicatStep() {
    try {
      return await this.#proxyNpUserApi.proxyGet('/player/kyc/verification/signicat/step');
    } catch (e) {
      console.log('error : getSignicatStep', e);
      throw e;
    }
  }

  /**
   * @id VerifyKycSignicatScreeningRequest
   * @returns {Promise<*>}
   */
  async signicatScreening() {
    try {
      return await this.#proxyNpUserApi.proxyPost('/player/kyc/verification/signicat/screening', null, {silent: true});
    } catch (e) {
      console.log('error : signicatScreening');
      throw e;
    }
  }

  /**
   * @id VerifyTransUnionRequest
   * @returns {Promise<*>}
   */
  async transUnion() {
    try {
      return await this.#proxyNpUserApi.proxyPost('/player/kyc/verification/transunion/verify');
    } catch (e) {
      console.log('error : transUnion');
      throw e;
    }
  }

  /**
   * @description Jumio step 조회
   * @id GetKycOutsideVerificationStepRequest
   * @param {string} Language
   * @param {string} DocumentType
   * @param {string} RequestHost
   * @returns {Promise<JumioDocs>}
   * */
  getJumioStep({ Language = 'en', DocumentType = 'BS', RedirectUri } = {}) {
    try {
      return this.#proxyNpUserApi.proxyGet('/player/kyc/outside-verification/step', { Language, DocumentType, RedirectUri });
    } catch (e) {
      console.log('error : getJumioStep', e);
      throw e;
    }
  }

  /**
   * @description 인증 갱신 대상의 인증 스텝을 조회
   * @id GetAccountVerificationTypeOrderListForKycExpireRequest
   * @returns {Promise<string[]>}
   */
  async getOrderForKycExpire() {
    try {
      return (await this.#proxyNpUserApi.proxyGet('/player/login/verification/expire', null, { silent: true })).VerificationOrder;
    } catch (e) {
      console.log('error : getOrderForKycExpire');
      throw e;
    }
  }

  /**
   * @id GetBankidAuthUrlRequest
   * @param {string} RedirectUri - Bank ID 인증 결과 값을 받을 callback URi
   * @returns {<{Url: string}>}
   */
  verifyBankId(RedirectUri) {
    try {
      return this.#proxyNpUserApi.proxyGet('/player/kyc/verification/bankid/url', { RedirectUri });
    } catch (e) {
      console.log('error : verifyBankId', e);
      throw e;
    }
  }

  /**
   * @id UpdatePlayerInfoByBankidRequest
   * @param {string} Code -callback URL로 받은 query string의 code 변수 값
   * @param {string} StateValue - callback URL로 받은 query string의 state 변수 값
   * @param {string} Error - callback URL로 받은 query string의 error 변수 값
   * @param {string} ErrorDescription - callback URL로 받은 query string의 error_description 변수 값
   * @param {string} RequestHost - 접속 중인 페이지의 hostname
   * @returns {*}
   */
  updateBankId({ Code, StateValue, Error, ErrorDescription, RequestHost }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/kyc/verification/bankid/info', { Code, StateValue, Error, ErrorDescription, RequestHost });
    } catch (e) {
      console.log('error : updateBankId', e);
      throw e;
    }
  }

  /**
   * @id GetBankidResultRequest
   * @returns <Promise<{Status: string}>>
   * */
  verifyBankIdStatus() {
    try {
      return this.#proxyNpUserApi.proxyGet('/player/kyc/verification/bankid/status');
    } catch (e) {
      console.log('error : verifyBankIdStatus', e);
      throw e;
    }
  }

  /**
   * @id VerifyKycEnrollCRUKSRequest
   * @returns {Promise<*>}
   */
  async verifyCruks() {
    try {
      return await this.#proxyNpUserApi.proxyPost('/player/kyc/verification/cruks');
    } catch (e) {
      console.log('error : verifyCruks', e);
      throw e;
    }
  }

  /**
   * @description 온보딩 간소화 처리
   * @id VerificationAutomationRequest
   * @returns <Promise<{VerificationOrder: string[]}>>
   */
  async verificationAutomation() {
    try {
      return await this.#proxyNpUserApi.proxyPost('/v1/verification/automation', null, {silent: true});
    } catch (e) {
      console.log('error : verificationAutomation', e);
      throw e;
    }
  }
}