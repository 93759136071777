import { render, staticRenderFns } from "./SuccessMfa.vue?vue&type=template&id=1b517c3b"
import script from "./SuccessMfa.vue?vue&type=script&lang=js"
export * from "./SuccessMfa.vue?vue&type=script&lang=js"
import style0 from "./SuccessMfa.vue?vue&type=style&index=0&id=1b517c3b&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports