<template>
  <div download v-if="isReady">
    <div class="title">
      <h1>{{ $t('_.title') }}</h1>
      <ColorButton type="light-red" @click="downloadClient">{{ $t('_.downloadButton') }}</ColorButton>
    </div>
    <div class="contents">
      <div class="content" v-for="item in contentsList" :key="item.title">
        <img :src="item.image" />
        <h2>{{ $t(`_.${item.title}.title`) }}</h2>
        <p v-html="$t(`_.${item.title}.description`)" />
        <p>{{ $t(`_.${item.title}.subDescription`) }}</p>
      </div>
    </div>
    <div class="require-contents">
      <h2>{{ $t('_.requirements') }}</h2>
      <div class="require-content">
        <section class="os" v-for="item in osList" :key="item.title">
          <h4>{{ $t(`_.${item.title}.title`) }}</h4>
          <ul>
            <li v-for="index in item.length" :key="index">{{ $t(`_.${item.title}.listItem${index}`) }}</li>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { getDownloadUrl } from '@/utils/baseUtil';
import { parseQuery } from '@shared/utils/urlUtils.mjs';
import ColorButton from '@shared/components/common/ColorButton.vue';
import { cdnImgPath } from '@shared/utils/cdnUtils.mjs';

export default {
  name: 'Download',
  components: { ColorButton },
  lexicon: 'signUp.channelingDownload',
  computed: {
    site: state('env', 'site'),
    isMobile: state('browser', 'mobile'),
    brand: state('env', 'brand'),
    env: state('env', 'env'),
    ggpass: state('env', 'ggpass'),
    baseLang: state('env', 'baseLang'),
  },
  data() {
    return {
      channelingName: null,
      isReady: false,
      contentsList: [
        { image: `${cdnImgPath}/download-app-01.png`, title: 'downloadAndInstall' },
        { image: `${cdnImgPath}/download-app-02.png`, title: 'freeAccount' },
        { image: `${cdnImgPath}/download-app-03.png`, title: 'playNow' },
      ],
      osList: [
        { title: 'windows', length: 4 },
        { title: 'mac', length: 4 },
        { title: 'android', length: 3 },
        { title: 'ios', length: 3 },
      ],
    };
  },
  methods: {
    async setFailed(isLocal) {
      try {
        await this.replaceRouteName('NotSupportRegion', null, { channelingUrl: isLocal ? 'https://ocean-ggpcom-test.ggcore.net' : location.href, checkedUrl: true });
      } catch {
        console.log('navigate duplicate');
      }
      this.isReady = true;
    },
    async checkDomain() {
      const isLocal = this.env === 'local';
      const host = location.host;
      if (host.includes('eapt')) this.channelingName = 'eapt';
      if (host.includes('roadtovegas')) this.channelingName = 'roadtovegas';
      if (host.includes('ocean')) this.channelingName = 'ocean';
      if (!this.channelingName && !isLocal) await this.replaceRouteName('NotFound');
      const r = await this.$services.sign.checkChannelingDownload({ domain: isLocal ? 'https://ocean-ggpcom-test.ggcore.net' : location.href });
      if (r.channelingRegions?.length) await this.setFailed(isLocal);
    },
    async downloadClient() {
      const queries = parseQuery(location.search);
      const r = await this.$services.sign.getChannelingDownloadUrl({ domain: this.env === 'local' ? 'https://ocean-ggpcom-test.ggcore.net' : location.origin });
      const url = getDownloadUrl({ brand: r.clientBrandName, site: r.clientSiteName, isMobile: this.isMobile, baseLang: this.baseLang, queries, baseUrl: r.clientDownloadBaseUrl, channelingName: this.channelingName });
      window.open(url, '_blank');
    }
  },
  async mounted() {
    await this.checkDomain();
    this.isReady = true;
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[download] { .flex(); .flex-dc(); .c(white); .t(0); .p(0, 16, 60); .max-w(1024); .mh-c();
  .title { .flex(); .flex-dc(); .align-center(); .justify-center(); .h(auto); .lh(40); .tc(); .mb(38);
    > h1 { .fs(32); .p(0, 15, 40); .c(white); .mt(0); }
    [color-button] { .wh(100%, 56); .br(28); .fs(18); .tr-t(.3s); .rel(); .max-w(300); .mh-c(); .block(); .medium(); .mt(0); }
  }
  .contents {
    .content { .tc();
      > img { .h(140); .mh-c(); }
      > h2 { .fs(18); .bold(); .mb(8); .mt(24); }
      > p { .ph(20); .fs(16, 20); .c(#aeaeae); word-break: keep-all;
        strong { .c(white); .medium(); }
        &:nth-of-type(1) { .mb(8); }
      }
      &:not(:nth-of-type(1)) { .mt(32); }
    }
  }
  .require-contents { .-a(#707070); .p(24); .mt(80);
    > h2 { .fs(22); .medium(); .tc(); .mb(20); }
    .require-content {
      .os { .m(28, 0, 20);
        h4 { .fs(16); .mb(12); }
        ul {
          > li { list-style: disc; .block(); .c(#bfbfbf); .fs(14); .rel(); .pl(16);
            &:before { .cnt(); .wh(4); .bgc(#bfbfbf); .abs(); .lt(0, 10); .br(50%); }
          }
        }
      }
    }
  }
  @media (@tp-up) {
    .ph(60);
  }
  @media (@tl-up) {
    .max-w(1280); .ph(28);
    .title { .mb(84);}
    .contents {
      .content { .tl(); .pl(190); .rel();
        > img { .abs(); .lt(0, 50%); .t-yc(); .mt(8);}
        > p { .p(0); }
      }
    }
    .require-contents { .mt(130);
      > h2 { .mb(40); }
      .require-content { .flex-list();
        .os { .m(0);
          &:not(:nth-of-type(1)) { .ml(48); }
          ul {
            >li { .mb(12); .fs(14,20); }
          }
        }
      }
    }
  }
}
</style>