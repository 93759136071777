<template>
  <div display-step>
    <div v-for="item in tempList" class="item">
      <!--      <div v-if="item > first" :class="['bar', {'wait' : current < item}]" />-->
      <div :class="['circle', {'wait' : current < item}]">
        <!--        <SvgCheck v-if="current > item" />-->
      </div>

    </div>
  </div>

</template>

<script>
import SvgCheck from '@shared/svg/SvgCheck.vue';
export default {
  name: 'DisplayStep',
  components: { SvgCheck },
  props: {
    last: {
      type: Number,
      default: 5,
    },
    first: {
      type: Number,
      default: 1,
    },
    current: {
      type: Number,
      default: 1,
    }
  },
  data() {
    return { tempList: [] };
  },
  mounted() {
    this.tempList = Array.from({ length: this.last }, (v, i) => i + 1);
  }

};
</script>

<style lang="less">
@import '~@/less/proj.less';
[display-step] { .flex(); .flex-dr(); .justify-center(); .items-center(); .gap(5);
  .item { .flex(); .flex-dr(); .justify-center(); .items-center(); .gap(5);
    .circle { .bgc(@gp-green); .w(16); .h(2); .flex(); .justify-center(); .items-center(); .br(16);
      &.wait { .bgc(#D4D4D8); }
      > p { .c(#fff); .fs(12); font-weight: bold;}
      > svg {.fill(white); .wh(10);}
    }
    .bar { .h(1); .w(16); .bgc(@gp-green);
      &.wait { .bgc(#CCC) }
    }
  }

}
</style>