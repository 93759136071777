<template>
  <div national-register-number >
    <ValidationProvider v-slot="{errors}">
      <ValidationComponent :label="$t('personalDetails.nationalRegisterNumber')" name="_nationalRegisterNumber" :component="TextInput" :rules="rule" :value="modelInfo.NationalId" :preset="preset" @input="updateValue" />
      <ValidationErrors v-if="errors&&errors.length" class="error-msg" :errors="errors" />
    </ValidationProvider>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import Specific from '@shared/types/Specific';
import baseTemplate from '@/mixins/baseTemplate';
import { customDateFormat } from '@shared/utils/timeUtils.mjs';

export default {
  name: 'NationalRegisterNumber',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  watch: {
    'modelInfo.DateOfBirth': function () {
      this.initAge();
    },
  },
  computed: {
    rule() {
      return `requiredNationalRegisterNumber:${this.age.year},${this.age.month},${this.age.day},${this.modelInfo.Gender}`;
    },
    preset() {
      return {phone: true, maxLength: 15};
    }
  },
  data() {
    return {
      age: { year: null, month: null, day: null },
      TextInput,
    };
  },
  methods: {
    formatting(v) {
      let formattedNumber = v.replace(/\D/g, '');
      const threeDigitsRegex = /^(\d{2})(\d)$/;
      const fourDigitsRegex = /^(\d{2})(\d{2})$/;
      const fiveDigitsRegex = /^(\d{2})(\d{2})(\d)$/;
      const sixDigitsRegex = /^(\d{2})(\d{2})(\d{2})$/;
      const sevenDigitsRegex = /^(\d{2})(\d{2})(\d{2})(\d)$/;
      const eightDigitsRegex = /^(\d{2})(\d{2})(\d{2})(\d{2})$/;
      const nineDigitsRegex = /^(\d{2})(\d{2})(\d{2})(\d{3})$/;
      const tenDigitsRegex = /^(\d{2})(\d{2})(\d{2})(\d{3})(\d)$/;
      const elevenDigitsRegex = /^(\d{2})(\d{2})(\d{2})(\d{3})(\d{2})$/;
      const twelveDigitsRegex = /^(\d{2})(\d{2})(\d{2})(\d{3})(\d{2})(\d)$/;
      const thirteenDigitsRegex = /^(\d{2})(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})$/;
      const fourteenDigitsRegex = /^(\d{2})(\d{2})(\d{2})(\d{3})(\d{2})(\d{3})$/;
      const fifteenDigitsRegex = /^(\d{2})(\d{2})(\d{2})(\d{3})(\d{2})(\d{4})$/;

      formattedNumber = formattedNumber.replace(threeDigitsRegex, "$1.$2");
      formattedNumber = formattedNumber.replace(fourDigitsRegex, "$1.$2");
      formattedNumber = formattedNumber.replace(fiveDigitsRegex, "$1.$2.$3");
      formattedNumber = formattedNumber.replace(sixDigitsRegex, "$1.$2.$3");
      formattedNumber = formattedNumber.replace(sevenDigitsRegex, "$1.$2.$3-$4");
      formattedNumber = formattedNumber.replace(eightDigitsRegex, "$1.$2.$3-$4");
      formattedNumber = formattedNumber.replace(nineDigitsRegex, "$1.$2.$3-$4");
      formattedNumber = formattedNumber.replace(tenDigitsRegex, "$1.$2.$3-$4.$5");
      formattedNumber = formattedNumber.replace(elevenDigitsRegex, "$1.$2.$3-$4.$5");
      formattedNumber = formattedNumber.replace(twelveDigitsRegex, "$1.$2.$3-$4.$5");
      formattedNumber = formattedNumber.replace(thirteenDigitsRegex, "$1.$2.$3-$4.$5");
      formattedNumber = formattedNumber.replace(fourteenDigitsRegex, "$1.$2.$3-$4.$5");
      formattedNumber = formattedNumber.replace(fifteenDigitsRegex, "$1.$2.$3-$4.$5");

      return formattedNumber;
    },
    updateValue(v) {
      if(!v) return;
      this.modelInfo.NationalId = this.formatting(v);
    },
    initAge() {
      const {DateOfBirth: date} = this.modelInfo;
      const year = customDateFormat(date, 'YYYY');
      const month = customDateFormat(date, 'MM');
      const day = customDateFormat(date, 'DD');

      this.age = { year, month, day };
    },
  },
  mounted() {
    if(!this.modelInfo.NationalId || !this.modelInfo.DateOfBirth) return;
    this.modelInfo.NationalId = this.formatting(this.modelInfo.NationalId);
    this.initAge();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[national-register-number] { .mt(8); }
</style>