<template>
  <div bonus-section>
    <h4 v-if="title">
      <p>{{ $t(`_.${title}`) }}</p>
      {{ tooltip }}
    </h4>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BonusSection',
  lexicon: 'promotion',
  props : {
    title: { type: String },
    tooltip: { type: String },
  }
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[bonus-section] {
  + [bonus-section] { .mt(20);
    h4 { .pt(20); .-t(@c-gray2); }
  }
  h4 { .mb(20); .c(@c-gray2); .regular(); }
  //h5 { .mb(16); .fs(20); .semi-bold(); }
  //.bonus-info { .bgc(@c-bgBlack); }
}
</style>
