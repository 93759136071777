import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getDetailHM } from '@shared/utils/timeUtils.mjs';
import { appendQuery } from '@shared/utils/urlUtils';
import axios from 'axios';
import qs from 'qs';
import ApiErrorController from '@/modules/ApiErrorController';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

/**
 * @typedef { store: StoreProxy, router: RouterProxy, modal: ModalProxy } ProxyManager
 */

export default class ApiConnection {
  /** @type{AxiosInstance} */
  #axios;
  #getSync = {};
  /** @type {ServiceManager} */
  #services;
  #configDecorator;
  #postDecorator;
  baseUrl;

  /**
   * @param {string} baseURL
   * @param {ServiceManager?} services
   * @param {function(config: {}): {}} configDecorator
   * @param {function(config: {}): {}} postDecorator
   * @param {boolean} withCredentials
   */
  constructor(baseURL, services, configDecorator = v => v, postDecorator = v => v, withCredentials = false) {
    this.baseUrl = baseURL;
    this.#services = services;
    this.#axios = axios.create({ baseURL, withCredentials });
    this.#configDecorator = configDecorator;
    this.#postDecorator = postDecorator;
    this.#axios.interceptors.request.use(config => {
      /*
      * sign-up 시 국가 인도 선택 시 RestrictLocation 을 확인하기 위해 특정 IP header 추가
      * https://ggnetwork.atlassian.net/wiki/spaces/AG/pages/116555796/2.+Sign+up#Restrict-Location-Modal
      * // config.headers['X-Forwarded-For-Custom'] = '223.27.122.111'; // 223.196.169.4, 15.230.246.235
      * */

      if (process.env.VUE_APP_ENV !== 'production') console.log(`call api : ${config.baseURL ? new URL(`${config.baseURL}${config.url}`)?.pathname : ''}`, getDetailHM());

      config.headers['Content-Type'] = 'application/json';
      config.headers.Pragma = 'no-cache';
      config.headers.Expires = -1;
      config.paramsSerializer = qs.stringify;

      return config;
    });
  }

  get base() {
    return this.baseUrl;
  }

  #errorHandler = (code, customCode) => {
    if (!code) return;
    /*
    * https://ggnetwork.atlassian.net/wiki/spaces/NPF/pages/1791131773/04.+error+code
    * 전역 에러로 발생 시 에러 처리되야하며 로그아웃까지 진행되야 할 코드 목록
    * ACCOUNT_LOCKED : ACCOUNT_LOGIN_LIMIT_ACCESS, GAMSTOP_SELF_EXCLUDED, CRUKS_SELF_EXCLUDED, AISG_SELF_EXCLUDED, ACCOUNT_LOCKED, OASIS_BAN_EXIST
    * ACCESS_DENIED :
    * ACCOUNT_SUSPENDED : ACCOUNT_LOGIN_ATTEMPS_FAILED_LOCKED, ACCOUNT_LOGIN_REMAIN_LIMIT, ACCOUNT_LOGIN_REMAIN_LIMIT_OVER_LOCKED, COMPLIANCE_CHECK_PERIOD, CLOSED, COMPLIANCE_CHECK, SECURITY, MULTIACC_CHECK, EXCLUSION_CHECK, BLOCKEDLISTACC_CHECK
    * */
    return ApiErrorController.proxy({ site: this.#services.store.state.env.site, code, customCode });
  };

  #responseHandler = (promise, silent) => new Promise((resolve, reject) => {
    if (!silent) this.#services?.modal.block();
    promise.then(response => {
      if (!silent) this.#services?.modal.unblock();
      const /** @type {{ statusCode, data: { path, responseStatus, responseHeader, responseBody }, error: { errorCode, message, stack }, body }} */ data = response?.data;
      if (data?.statusCode >= 300) throw { status: 401, data: { code: apiErrorCode.ACCESS_DENIED } }; // TODO : [ean] proxy error. 차후 errorCode 정리 후 그에 맞는 에러화면 처리 필요. 임시로 전역 에러 코드 반환
      else {
        // TODO : [ean] body는 이제 안내려올 것 같은데 내려오는 데이터 경과 확인 후 제거 예정
        const /** @type {{ body, comment, error, CustomerCode, CustomerErrorCode }} */ body = (data?.body && data?.error ? data : data?.body) || data?.data?.responseBody || data || response;
        /**
         * TODO : BFGW 와 실제 api error를 분기하여 관리해야 함
         * Gateway : { timestamp, path, requestId, status, error, message, code }
         * Domain : { Description, description, message, error, Code, code, CustomerErrorCode, customerErrorCode, customer_error_code, CustomerErrorParameters }
         */
        if (data?.data?.responseStatus >= 300) throw { response: { status: data?.data?.responseStatus, data: body } }; // proxy ok, domain api error
        else if (body.error) throw { status: 401, data: { Code: body?.body, CustomerErrorCode: body?.CustomerCode || body?.CustomerErrorCode || body?.body, Description: body?.comment } }; // proxy ok, domain ok 지만 에러가 내려올 경우
        else {
          if (process.env.VUE_APP_ENV !== 'production') console.log(`[${data?.data?.path}]`, body);
          resolve(body);
        }

        if (process.env.VUE_APP_ENV !== 'production') console.log('success api call : ', response.request.responseURL ? new URL(response.request.responseURL)?.pathname : '', getDetailHM());
      }
    }).catch(e => {
      const {/** @type {{ status: number, data: { Description: string, description: string, message: string, error, Code: string, code: string, CustomerErrorCode : string, customerErrorCode : string, customer_error_code : string, CustomerErrorParameters: string[] }}} */ response: r } = e;
      const status = r?.status;
      const data = r?.data;
      const code = data?.Code || data?.code;
      const customerErrorCode = data?.CustomerErrorCode || data?.customerErrorCode || data?.customer_error_code;
      const errorTemplate = this.#errorHandler(code, customerErrorCode);

      if (code === apiErrorCode.ACCESS_DENIED || data?.error === 'Unauthorized' || (status === 401 && typeof data === 'string')) reject({ error: true, code: 401 });
      else if (status === 403) {
        if (data?.error?.errorCode === apiErrorCode.AUTHORIZATION_DENY) reject({ error: true, code: 403 }); // [ean] : Auth api 에서 반환하는 403이 아닐 경우는 정상 응답이 되야 함
        else resolve({ error: true, code: status, desc: data?.Description || data?.message, desc2: data?.description, key: customerErrorCode || code, CustomerErrorParameters: data?.CustomerErrorParameters, errorTemplate });
      } else if (status === 401 || status === 404 || status === 500) resolve({ error: true, code: status, desc: data?.Description || data?.message, desc2: data?.description, key: customerErrorCode || code, errorTemplate });
      else resolve({ error: true, code: status, desc: data?.Description || data?.message, desc2: data?.description, key: customerErrorCode || code, CustomerErrorParameters: data?.CustomerErrorParameters, errorTemplate });

      if (!silent) this.#services?.modal.unblock();
      if (process.env.VUE_APP_ENV !== 'production') console.log('exception api call : ', e, getDetailHM());
    });
  });

  // noinspection JSCheckFunctionSignatures
  /**
   * @param {string} path
   * @param {object?} params
   * @param {AxiosRequestConfig & { silent?: boolean }?} config
   * @returns {Promise<*>}
   */
  get(path, params, config) {
    if (!config?.silent) this.#services?.modal.block();
    return this.#responseHandler(this._get(path, params, this.#configDecorator(config)), config?.silent);
  }

  _get(path, params, config) {
    const uri = `${path}?${qs.stringify(params)}`;
    // noinspection JSUnresolvedVariable
    return this.#getSync[uri] || (this.#getSync[uri] = this.#axios.get(path, { ...config, params, timeout: 100000 }).then(response => {
      delete this.#getSync[uri];
      return response;
    }).catch(e => {
      delete this.#getSync[uri];
      throw e;
    }));
  }

  /**
   * @param {string} path
   * @param {any?} data
   * @param {AxiosRequestConfig?} config
   * @returns {Promise<*>}
   */
  post(path, data, config) {
    return this.#responseHandler(this.#axios.post(path, data, this.#configDecorator({ ...config })), config?.silent);
  }

  proxyGet(path, data, config) {
    const queryPath = Object.keys((data || {})).length ? appendQuery(path, data) : path;
    return this.#proxyMixin(queryPath, 'GET', {}, config);
  }

  proxyPost(path, data, config) {
    return this.#proxyMixin(path, 'POST', data, config);
  }

  proxyPut(path, data, config) {
    return this.#proxyMixin(path, 'PUT', data, config);
  }

  proxyPatch(path, data, config) {
    return this.#proxyMixin(path, 'PATCH', data, config);
  }

  proxyDelete(path, data, config) {
    return this.#proxyMixin(path, 'DELETE', data, config);
  }

  #proxyMixin(path, method, data, config) {
    const decorator = this.#postDecorator() || {};
    decorator.headers = config?.headers || {};
    decorator.httpMethod = method;
    decorator.body = data || {};

    const p = `${decorator?.path || ''}${path}`;
    delete decorator.path;

    return this.#responseHandler(this.#axios.post(p, decorator, this.#configDecorator(config)), config?.silent);
  }

  /**
   * @param {string} path
   * @param {any?} data
   * @param {AxiosRequestConfig?} config
   * @returns {Promise<*>}
   */
  put(path, data, config) {
    return this.#responseHandler(this.#axios.put(path, data, this.#configDecorator(config)), config?.silent);
  }

  /**
   * @param {string} path
   * @param {AxiosRequestConfig?} config
   * @returns {Promise<*>}
   */
  delete(path, config) {
    return this.#responseHandler(this.#axios.delete(path, this.#configDecorator(config)), config?.silent);
  }

  /**
   * @param {string} path
   * @param {any?} data
   * @param {AxiosRequestConfig?} config
   * @returns {Promise<*>}
   */
  patch(path, data, config) {
    return this.#responseHandler(this.#axios.patch(path, data, this.#configDecorator(config)), config?.silent);
  }
}
