<template>
  <div temporary-account-accepted-ph>
    <FontIcon name="exclamation-circle" />
    <p v-html="$t('_.temporaryAccountAcceptedPh.description1', { webSiteUrl, webSiteName })" />
    <p v-html="$t('_.temporaryAccountAcceptedPh.description2', { expiredDate })" />
    <p v-html="$t('_.temporaryAccountAcceptedPh.description3')" />
    <ProgressButton button-name="completeVerification" @click="complete" />
    <ProgressButton button-name="playNow" @click="playNow" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { autoLogin, close, isClient } from '@/utils/clientUtil';
import { redirectCompleteUrl } from '@/utils/baseUtil';
import { webSiteInfo } from '@/utils/webSiteUtil';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { restrictType } from '@/constants/base/onboarding/kyc';
import { fullDate } from '@shared/utils/timeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'TemporaryAccountAcceptedPh',
  lexicon: 'onBoarding',
  components: { FontIcon, ProgressButton },
  mixins: [baseTemplate],
  data() {
    return {
      webSiteUrl: null,
      webSiteName: null,
      expiredDate: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
  },
  methods: {
    async complete() {
      await this.replaceRouteName('Poi');
    },
    async playNow() {
      if (!await redirectCompleteUrl(this)) {
        if (isClient()) {
          const kycStatusInfo = await this.$services.kyc.getPlayerKycStatus();
          const existRestrictLogin = kycStatusInfo?.KycRequisition?.Restrics?.includes(restrictType.Login);
          if (existRestrictLogin) close(this);
          else autoLogin(this);
        } else close(this);
      }
    },
    initialize() {
      const { name, url } = webSiteInfo(this.site);
      this.webSiteName = name;
      this.webSiteUrl = url;
      this.expiredDate = this.userInfo.TemporaryVerificationExpireAt ? fullDate(this.userInfo.TemporaryVerificationExpireAt) : null;
      // this.expiredDate = fullDate('2024-10-30');
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[temporary-account-accepted-ph] { .tc();
  > [font-icon] { .block(); .mb(24); .fs(80); }
  > p { .tl(); .fs(18, 22, normal);
    .red { .semi-bold(); .c(red); }
    > a { .c(#2680EB); }
  }
  > p + p { .mt(22); }
  > [color-button] + [color-button] { .mt(16); }
}
</style>
