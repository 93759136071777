<template>
  <MfaModalTemplate download-market>
    <template #title>
      {{ $t('_.googleAuthenticationStep1') }}
    </template>
    <template #content>
      <div class="img-group">
        <div class="img-area" @click="downloadAppstoreLink">
          <img src="/img/apple_icon.png" />
          <p>{{ $t('_.downloadFrom') }}</p>
          <p>{{ $t('_.appStore') }}</p>
        </div>
        <div class="img-area" @click="downloadGoogleLink">
          <img src="/img/google_icon.png" />
          <p>{{ $t('_.downloadFrom') }}</p>
          <p>{{ $t('_.googlePlay') }}</p>
        </div>
      </div>
    </template>
    <template #description>
      {{ $t('_.googleStep1Desc') }}
    </template>
    <!--    <template #footer>-->
    <!--      <GpButton type="submit-green" @click="next" :progress="progress">{{ $t('next') }}</GpButton>-->
    <!--    </template>-->
  </MfaModalTemplate>
</template>

<script>
import MfaModalTemplate from '@/views/components/pages/my-page/modal/components/MfaModalTemplate.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
export default {
  name: 'DownloadMarket',
  lexicon: 'myInfo',
  components: { MfaModalTemplate, GpButton },
  props: {
    progress: { type: Boolean, default: false, }
  },
  methods: {
    next() {
      this.$emit('next');
    },
    downloadAppstoreLink() {
      window.open('https://apps.apple.com/us/app/google-authenticator/id388497605', 'blank');
    },
    downloadGoogleLink() {
      window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2', 'blank');
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[download-market] {
  .img-group { .flex(); .flex-dr(); .justify-center(); .gap(20);
    .img-area { .bgc(#fff); .flex(); .flex-dc(); .justify-center(); .items-center(); .p(20); .wh(136);
      > img { .h(36); }
      > p {text-align: center; .fs(12); .pt(10);
        &:nth-of-type(2) {.c(black); text-align: center; .fs(14); .pt(0);}
      }
      @media (@ms-down) {
        .wh(120);
        > img { .h(26); }
        > p {text-align: center; .fs(12); .pt(10); line-height: 16px;
          &:nth-of-type(2) {.c(black); text-align: center; .fs(14); .pt(0);}
        }
      }
    }
  }
}
</style>