<template>
  <ValidationProvider ref="refObserver" :mode="mode" gp-input-component :rules="rules" :name="name" v-slot="{errors, dirty, touched, valid, invalid}" :slim="true">
    <label :class="{'mandatory': isMandatory, 'label-color': labelColorGray}">{{ label }}</label>
    <div :class="['holder','input-wrapper',{'error': hasErrorMsg, },{'focus': isFocus, 'disabled': preset.disable }]">
      <component ref="component" class="component" :theme="theme" :is="component"
                 :props="() => preset" :list="preset.list" :telephone="preset.telephone" :phone="preset.phone" :digit="preset.digit" :max-length="preset.maxLength" :wordonly="preset.wordonly" :country="preset.country" :hidden-icon="preset.hiddenIcon"
                 :type="preset.type" :inputMode="preset.inputMode" :pattern="preset.pattern" :disabled="preset.disable" :placeholder="preset.placeholder" :auto="preset.auto" :translate="preset.translate" :my-info="preset.myinfo"
                 :size="preset.size" :select-only="preset.selectOnly" :input-placeholder="preset.inputPlaceholder" :search-value="preset.searchValue" :search-handler="preset.searchHandler" :show-no-result="preset.showNoResult" :min="preset.min" :max="preset.max"
                 v-model="model" @input="updateValue" @update="v => $emit('update',v)" @updateSearch="v => $emit('updateSearch',v)" @focus="setFocus" @blur="focusOut" @delete="$emit('delete')"  />
    </div>
    <ValidationErrors class="error-msg" v-if="isValid && (errorMsg || (rules && !['requiredNone', 'minMaxNone', 'requiredSelectNone'].some(rule => rules.includes(rule))) && errors && errors.length)" :errors="errors" :errorMsg="errorMsg" />
  </ValidationProvider>
</template>

<script>
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'InputComponent',
  components: { FontIcon },
  props: {
    value: Specific,
    component: { type: Specific, required: true },
    name: { type: String, required: true },
    mode: {type: String, default: 'aggressive'},
    rules: { type: String, default: null },
    preset: { type: Specific, default: () => ({}) },
    label: { type: String, default: null },
    errorMsg: { type: String, default: null },
    labelColorGray: { type: Boolean, default: true },
    autoFocus: { type: Boolean, default: true },
    theme: { type: String, default: 'white' },
  },
  data() {
    return {
      model: null,
      isFocus: false,
      inside: false,
    };
  },
  computed: {
    isValid() {
      if (this.model === null || this.model === undefined) return false;
      if (Array.isArray(this.model) && this.model.length === 0) return false;
      if (this.model === false) return false;
      return !!String(this.model).trim().length;
    },
    isMandatory() {
      return this.label && this.rules && (this.rules.includes('required') || this.rules.includes('length:8,20'));
    },
    hasErrorMsg() {
      return Boolean(this.errorMsg?.length);
    },
  },
  watch: {
    value: 'updateModel',
    errorMsg() {
      if (this.autoFocus && this.hasErrorMsg) this.$el.querySelector('input').focus();
    },
  },
  methods: {
    clear() {
      this.$refs.refObserver.reset();
    },
    updateModel() {
      this.model = this.value;
    },
    updateValue(v) {
      this.model = v;
      this.$emit('input', v);
    },
    click() {
      this.setFocus();
    },
    setFocus() {
      this.isFocus = true;
      this.$emit('focus');
    },
    focusOut() {
      this.isFocus = false;
      this.inside = false;
      this.$emit('blur');
      this.$emit('input', this.model);
    },
  },
  mounted() {
    this.updateModel();
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[gp-input-component] { cursor: text;
  .disabled {
    > [search-drop-select] {.bgc(@gp-disabled-bgc);
      > a {.c(#a1a1aa);}
    }
  }
  &.disabled {cursor: default;
    [search-drop-select] {
      > a {cursor: default !important;}
    }
  }

  > label { .c(@gp-component-label); .fs(14); .pl(3); display: block; .pb(5);
    &.mandatory:after { content: '*'; .c(@gp-green); .ml(1);}
    > span {.c(green)}
  }
  .holder { .wh(100%, 40); .rel();
    > .verify-icon {.c(#448F69); .abs(); .t(50%); .r(2%); transform: translateY(-50%); .fs(20);}
    > span::after {content: none}
    > label { pointer-events: none; .c(black)}
    &.error { //> span {.-a(@c-red, 1)}

      > label { .c(@c-red); }
    }
    > label { .pl(12); .fs(13); .pt(4); .tl(); .ib(); .rel(); .mb(2); .h(24); .vat();
      &.mandatory:after { content: '*'; .c(@c-red); .ml(4);}
      &.label-color { .c(#AFAFAF); }
    }

    //&.disabled { .bgc(@c-w01); box-shadow: none;}
    &.disabled { .bgc(#EEEEEE); .c(#a1a1aa); cursor: default;
      > span > input {.c(#a1a1aa);}
    }
    .currency-symbol {.abs(); .t(50%); transform: translateY(-50%); .l(10); .c(#A1A1AA); .z(2); .fs(14);}
  }
  .error-msg {.pl(2); .m(0); .c(@c-red); .fs(14); .pt(5);}
  .error-message { .c(@c-red); .fs(14); }

  // 드롭 다운 커스텀
  [drop-select] { .wh(100%); border: none !important; .br(8);
    &.open {border-bottom-left-radius: 0; border-bottom-right-radius: 0;
      > input {}
    }
    > a {.p(0);}
    &.disabled {.bgc(@gp-disabled-bgc); cursor: default; border: none;
      > a > input {background-color: transparent; .c(#a1a1aa);
        &::placeholder {.c(#a1a1aa); .fs(14);}
      }
    }
  }

  [password-input] { .h(40);
    > span {.h(100%); .bgc(white); .br(8); border: none;
      >input{
        &::placeholder{.c(#A1A1AA) !important;}
      }
    }
    > span::after {content: none}

  }
  [gp-text-input-box] {
    > span::after {content: none}
  }

  [search-drop-select] { .rel(); .bgc(#FFF); .br(8);
    > a { .br(8); .c(#333); .bgc(#1e1e1e); box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .block(); .fs(14); .flex(); .items-center(); .h(64); .p(6, 20, 6, 12); .-box(); .w(100%); .rel();
      &.placeholder {.c(#a1a1aa);}
      > label { .medium(); font-weight: 400; white-space: nowrap; text-overflow: ellipsis; overflow: hidden !important; box-sizing: border-box; .block();.pr(10);
        > em { .fs(14); }
        img { .mb(4); }
      }
      [font-icon] { .ib(); .fs(16); .abs(); .rt(10, 45%); .t-yc(); .c(#333);
        &.on { .t-r(180deg); }
      }
    }
    //.list-container { box-shadow: 2px 0 3px rgba(0, 0, 0, 0.5); .bgc(white); .br(8); .br-t(0); .abs(); .o(0); .crop(); .w(100%); .max-h(500); .h(0);
    .list-container { .bgc(white); .br(8); .br-t(0); .abs(); .o(0); .crop(); .w(100%); .max-h(500); .h(0);box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1); .br(8);
      // &.fill { .w(var(--innerWidth)); }
      &.fill { .w(100%); }
      &.on { .o(1); .z(10); }
      .list-inner { .pb(6); .pt(3); .hf();
        .input-holder { .p(5, 12, 10, 12); .bgc(#fff);}
        [text-input] {.bgc(#F7F7F8); .w(100%); box-shadow: none; border: none; .h(40);
          > input { .c(black); .fs(14); .pb(0);}
          > .input-btn-x {.bgc(#fff);
            > i {.c(black);}
          }
        }
        .scroll-holder { overflow-y: auto; .h(100%);
          label { .block(); .p(10, 16); .w(100%); .c(black); .tl();}
          button { .block(); .p(10, 16); .w(100%); .c(black); .tl(); white-space: nowrap; text-overflow: ellipsis; .w(100%); overflow: hidden !important; box-sizing: border-box;
            &:hover, &:focus, &.focus { .bgc(#F3FCF5); }
            &.active { .bgc(#E6F3E8); }
            em { .fs(14); .w(100%); }
            .icn-chevron-right { margin: 0 0 0 auto; }
          }
        }
        .empty { .fs(14); .p(10, 12); .c(black);}
      }
    }
    &.md {
      .list-container {
        .list-inner {
          .scroll-holder { .h(100%); }
        }
      }
    }
    &.sm, &.md {
      > a { .bgc(transparent) !important; box-shadow: none; .-a(); .p(0, 12); .h(40); .br(4);
        > [text-input] {.-a(); box-shadow: none; .w(100%);
          input { .pl(0); }
        }
      }
      .list-container {
        .list-inner { .fs(14);
        }
      }
    }
    [text-input] {
      input { .fs(14, 20); }
    }

    .placeholder { .c(@gp-placeholder-color); .regular(); }
    &.disabled {.bgc(@gp-disabled-bgc);
      > a {.c(#a1a1aa) !important;}
    }
  }

}
</style>
