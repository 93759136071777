<template>
  <div modal-container :data-mode="type" v-show="modals.length || blocked" :class="{global, clear}">
    <component v-for="modal in modals" :is="modal.component" :options="modal.options" :key="modal.id"
               @close="close(modal.id)"
               @resolve="(result) => resolve(modal.id, result)"
               @reject="(result) => reject(modal.id, result)"/>
    <transition name="fade">
      <div v-if="blocked" class="blocker">
        <div class="seq-preloader">
          <em class="c1" /><em class="c2" /><em class="c3" /><em class="c4" /><em class="c5" />
        </div>
        <div class="dim"/>
      </div>
    </transition>
  </div>
</template>

<script>
import Lottie from '@shared/components/general/Lottie.vue';

export default {
  name: 'ModalContainer',
  components: { Lottie },
  props: {
    global: { type: Boolean },
    type: String
  },
  data() {
    return {
      seq: 0,
      modals: [],
      blocked: false,
      blockTimeout: -1
    };
  },
  computed: {
    clear() {
      return !this.blocked && (!this.modals?.length || this.modals.every(m => m.clear));
    }
  },
  methods: {
    checkFreeze() {
      if (!this.global) return;
      if (!this.clear) this.$scroll.freeze();
    },
    checkRelease() {
      if (!this.global) return;
      if (this.blocked) return;
      if (this.modals.filter(m => !m.clear).length === 0) this.$scroll.release();
    },
    checkComponentFlag(component) {
      if (component.singleModal) {
        this.modals = this.modals.filter(m => m.component.name !== component.name);
      }
    },
    add(component, options, clear) {
      this.checkComponentFlag(component);
      return new Promise((resolve, reject) => {
        this.modals.push({ id: this.seq++, component, resolve, reject, options, clear });
        this.checkFreeze();
      });
    },
    closeModal(component) {
      this.modals = this.modals.filter(m => m.component !== component);
      this.checkRelease();
    },
    getModal(id) {
      return this.modals.find(m => m.id === id);
    },
    block() {
      clearTimeout(this.blockTimeout);
      this.blocked = true;
      this.checkFreeze();
    },
    unblock() {
      this.blockTimeout = setTimeout(() => {
        this.blocked = false;
        this.checkRelease();
      }, 300);
    },
    resolve(id, result) {
      this.getModal(id).resolve(result);
      this.close(id);
    },
    reject(id, result) {
      this.getModal(id).reject(result);
      this.close(id);
    },
    close(id) {
      this.modals = this.modals.filter(m => m.id !== id);
      this.checkRelease();
    },
    checkModal(component) {
      return this.modals.findIndex(m => {
        return (m.component.name === component.name);
      }) !== -1;
    },
    clearAll() {
      this.modals.forEach(o => this.close(o.id));
      this.modals = [];
      this.blocked = false;
    },
  },
  mounted() {
  }
};
</script>

<style lang="less">
@import '~@shared/less/asset.less';

[modal-container] { .fix; .wh(100vw, var(--innerHeight)); .lt; .z(5000);
  @keyframes c1 {
    0% {.o(1); .t-s(1);}
    50% {.o(0); .t-s(.5);}
    100% {.o(1); .t-s(1);}
  }
  > div { .z(1); }
  .blocker { .abs; .f; .flex-center; .z(1000);
    > .dim { .abs; .f; .bgc(rgba(0, 0, 0, 0.7)); .lt; }
    [lottie]:not(.lottie-coaching) { .wh(250); }
    .lottie-coaching {.wh(200)}
    .seq-preloader { .ib-list; .abs; .lt(50%, 50%); .t-xyc; .z(2);
      em {.wh(10); .m(0, 8); .bgc(white); .br(30); animation-name: c1; animation-duration: 2s; animation-iteration-count: infinite;
        &:nth-of-type(2) {.wh(12); .mt(-1); animation-delay: .2s;}
        &:nth-of-type(3) {.wh(14); .mt(-2); animation-delay: .4s;}
        &:nth-of-type(4) {.wh(16); .mt(-3); animation-delay: .6s;}
        &:nth-of-type(5) {.wh(14); .mt(-2); animation-delay: .8s;}
      }
    }
  }
  .fade-enter-active { .tr-o(0.5s, 0.3s); }
  .fade-leave-active { .tr-o(0.5s, 0.3s); }
  .fade-enter, .fade-leave-to { .o(0) }
  &.clear { .wh(0, 0); }
}
</style>
