<template>
  <div fp-radio-item :class="[{'active' : this.isActive}]">
    <!--    <span :class="['radio-button', {'active': isActive}]"></span>-->
    <div class="radio-box">
      <!--      <div>-->
      <!--        <RadioButton :active="isActive" />-->
      <!--      </div>-->
      <div class="title-group">
        <div>
          <h3>{{ label }}</h3>
        </div>
        <div>
          <h3>{{ maskedValue }}</h3>
        </div>
      </div>
    </div>
    <!--    <div class="mobile-button-group" v-if="isMobileActive">-->
    <!--      <button :class="[{'active' : this.mobileType === 0}]" @click.stop="click(0)">-->
    <!--        <FontIcon name="envelope-regular" />-->
    <!--        SMS-->
    <!--      </button>-->
    <!--      <button :class="[{'active' : this.mobileType === 1}]" @click.stop="click(1)">-->
    <!--        <FontIcon name="envelope-regular" />-->
    <!--        CALL-->
    <!--      </button>-->
    <!--    </div>-->
  </div>

</template>

<script>
import { isAuthenticated } from '@shared/utils/routerUtils.mjs';
import RadioButton from '@/views/components/gg-pass/RadioButton.vue';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import DropSelect from '@shared/components/common/input/DropSelect.vue';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'FpRadioItem',
  components: { InputComponent, RadioButton, FontIcon },
  props: {
    value: { type: String },
    label: { type: String },
    maskedValue: { type: String, default: null, },
    rules: { type: String, default: null, },
    active: { type: Boolean, default: false, },
    preset: { type: Specific, default: null },
    renderingComponent: { type: Specific, default: null },
    mobileType: { type: Number, default: 0, }
  },
  data() {
    return {
      selectValue: null,
      DropSelect,
    };
  },
  computed: {
    isRender() {
      return this.renderingComponent;
    },
    isActive() {
      return this.active;
    },
    isMobileActive() {
      return this.active && this.value === 'Mobile';
    }
  },
  methods: {
    update(v) {
      this.$emit('update', v);
    },
    click(v) {
      console.log(v);
      this.$emit('changeMobile', v);
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-radio-item ] {.p(20); .mt(-1); .pointer(); .rel(); .min-h(60); .br(5); .w(100%); box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .c(#aeaeae); .gap(10); .flex(); .flex-dc(); .bgc(#262626); border: 2px solid #333;
  [gp-radio-button] { .flex(); .flex-dr(); .items-center(); .gap(10); .pt(0);
    > i { .wh(16); .-a(#646464); .br(16); .bgc(#1f1f1f); .rel();
      &.on { .-a(#fff); border: 2px solid #fff;
        &:after { content: ''; .abs(); .wh(8); .bgc(#fff); .br(8); .t(50%); .l(50%); .m(-4, 0, 0, -4); }
      }
    }
  }
  &.active { border: 2px solid #fff; .c(#fff);
    //&::before { .br(5); content: ""; .w(100%); .h(100%); .abs(); .t(-1); .l(-1); .z(1); pointer-events: none; }
  }
  .radio-box { .flex(); .flex-dr(); .p(0); .gap(5); .items-center();
    .title-group { .flex(); .flex-dc(); .space-between(); .gap(5); .items-start();
      @media (@ml-up) {
        .gap(20); .flex-dr(); .items-center(); .w(100%);
        justify-content: space-between;
        > h3 {.fs(16); font-weight: 500;}
      }
      > div { .flex(); .flex-dr(); .items-center(); .gap(10);
        > img {.wh(18); object-fit: cover;}
        > h3 {.fs(14); font-weight: 500;}
      }
      > p { .fs(12); .c(red);}
    }
    .content-group { .flex(); .flex-dr(); .gap(10); .items-center();
      > p {.c(#999999); .fs(12); line-height: 16px; }
    }
  }
  .toggle-wrapper {.pt(20); .pb(10;)}
  .mobile-button-group {.flex(); .gap(10); .justify-end();
    > button {.c(#717171); .-a(#717171); .p(10); .min-w(100); .flex(); .justify-center(); .gap(5); .items-center();
      &.active {.-a(#fff); .c(#fff);}
    }
  }
}
</style>