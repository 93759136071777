import _keyBy from 'lodash/keyBy';
import qs from 'qs';
import { getHashValueFromRoute, historyMove, makeHashValues } from '@shared/utils/routerUtils';
import { NoToast } from '@shared/modules/ToastErrorHandler';
import { cdnSvgPath, cdnImgPath } from '@shared/utils/cdnUtils';

export default {
  computed: {
    matchedMedia() {
      return this.$store?.state?.browser?.matchedMedia || '';
    },
    matchedMediaDevice() {
      return this.matchedMedia?.[0];
    },
    lang() {
      return this.$i18n('lang');
    },
    locale() {
      return this.$store.state.route.params.locale;
    },
    routeMeta() {
      return this.$route.matched.reduce((acc, route) => ({ ...acc, ...route.meta }), {});
    },
    currency() {
      return this.$store.state.user.currency || 'USD';
    },
    cdnSvgPath() {
      return cdnSvgPath;
    },
    cdnImgPath() {
      return cdnImgPath;
    },
    isRtl() {
      return ['fa', 'ar'].includes(this.lang);
    }
  },
  methods: {
    isEmpty(v) {
      if (!v) return true;
      if (Array.isArray(v) && v.length === 0) return true;
      return typeof v === 'object' && Object.keys(v).length === 0;
    },
    values(...args) {
      return _keyBy(args);
    },
    /**
     * @typedef {{[key: string]: string}} RouteQuery
     */
    /**
     * @param {string} name
     * @param {object?} params
     * @param {object?} query
     * @returns {Promise<Route>}
     */
    routeName(name, params = null, query = {}) {
      try {
        return this.$router.push({ name, params: { locale: this.$route.params.locale, ...params }, query });
      } catch (e) {
        console.log('routeName : error ', e);
      }
    },
    replaceRouteName(name, params = {}, query = {}) {
      try {
        return this.$router.replace({ name, params: { locale: this.$route.params.locale, ...params }, query });
      } catch (e) {
        console.log('replaceRouteName : error ', e);
      }
    },
    routePath(path) {
      try {
        return this.$router.push(path[0] === '/' ? `${this.$i18n('path')}${path}` : path);
      } catch (e) {
        console.log('routePath : error ', e);
      }
    },
    /**
     * @param {string?} name
     * @param {object?} params
     * @param {object?} query
     * @param {string?} path
     */
    routeMainPath({ name, params, query = {}, path }) {
      const mainUrl = process.env.VUE_APP_HOME_URL;
      const { protocol, host } = location;

      if (mainUrl.includes(`${protocol}//${host}`)) {
        if (name) {
          this.routeName({ name, params, query });
        } else {
          this.routePath(path);
        }
      } else {
        if (path) historyMove(mainUrl + path);
      }
    },
    /**
     * @param {{[string]: string}} obj
     */
    pushHasValues(obj) {
      const orgValues = (!this.$route.hash) ? {} : qs.parse(this.$route.hash.substr(1));
      const newValues = { ...orgValues, ...obj };
      this.$router.push({ hash: makeHashValues(newValues) });
    },
    getHashValue(key) {
      return getHashValueFromRoute(this.$route, key);
    },
    replaceRoutePath(path, params, query) {
      return this.$router.replace({ path: path[0] === '/' ? `${this.$i18n('path')}${path}` : path, params, query });
    },
    /**
     * @param {RouteQuery} query
     * @returns {RouteQuery}
     */
    getMergedQuery(query) {
      return { ...this?.$route?.query || {}, ...query };
    },
    /**
     * @param {string[]} queryKeys
     * @returns {RouteQuery}
     * */
    getRemovedQuery(queryKeys) {
      const query = { ...this?.$route?.query || {} };
      queryKeys.forEach(qk => delete query[qk]);
      return query;
    },
    /**
     * 정해진 시간 간격 안에 여러번 발생하는 액션은 맨 첫 액션만 동작되도록 함. ( debounce 의 경우 마지막 액션을 사용하여 답답한 느낌을 줄 수 있음 )
     * @param {Number} duration 초단위
     * @returns {Promise<*>}
     */
    timeoutShield(duration = 0.5) {
      return new Promise((resolve, reject) => {
        const now = +new Date();
        if (this.__shieldTime__ && this.__shieldTime__ + (duration * 1000) > now) {
          reject(NoToast);
        } else {
          resolve();
        }
        this.__shieldTime__ = now;
      });
    },
  },
};
