<template>
  <div reaffirm-wsop-complete>
    <FontIcon name="check-over-circle" />
    <h2>{{ $t('thankYou') }}</h2>
    <ProgressButton button-name="complete" @click="complete" />
  </div>
</template>

<script>
import { webSiteInfo } from '@/utils/webSiteUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { autoLogin, close, isClient } from '@/utils/clientUtil';
import { redirectCompleteUrl } from '@/utils/baseUtil';
import { isUrl } from '@shared/utils/urlUtils.mjs';
import { getCookie } from '@shared/utils/cookieUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { restrictType } from '@/constants/base/onboarding/kyc';

export default {
  name: 'ReaffirmWsopComplete',
  lexicon: 'onBoarding.reaffirm.wsop',
  components: { FontIcon, ProgressButton },
  mixins: [baseTemplate],
  data() {
    return {
      webSiteUrl: null,
      webSiteName: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    language() {
      return this.$services.cookie.getLanguage();
    },
  },
  methods: {
    async initialize() {
      const { name, url } = webSiteInfo(this.site);
      this.webSiteName = name;
      this.webSiteUrl = url;

      const description = !this.structure.customSteps ? 'SameAsBefore' : `Changed: ${this.structure.customSteps.join(', ')}`;
      try { await this.$services.onboarding.updatePersonalReaffirm({ Description: description }); } catch (e) { /* empty */ }
    },
    async complete() {
      this.structure.customSteps = [];
      if (!await redirectCompleteUrl(this)) {
        if (isClient()) {
          const kycStatusInfo = await this.$services.kyc.getPlayerKycStatus();
          const existRestrictLogin = kycStatusInfo?.KycRequisition?.Restrics?.includes(restrictType.Login);
          if (existRestrictLogin) await close(this);
          else await autoLogin(this);
        } else await close(this);
      }
    }
  },
  mounted() {
    this.item.title = '';
    this.initialize();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[reaffirm-wsop-complete] { .tc(); .pt(24);
  [font-icon] { .p(16); .fs(50); .c(#1db77d); }
  h2 { .fs(32); .mt(12); .regular(); }
  > div { .fs(18, 20); .mt(40); .c(@c-text-desc); .regular(); .mb(55); .tc();
    p { line-height: 1.7;
      span { .fs(20, 20); .c(#ccc); }
      a { .block(); .mt(30); .c(@c-link); .medium(); }
    }
  }
  [progress-button] { .fs(20); }
}
</style>
