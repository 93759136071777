<template>
  <div tpid-label>
    <label>TPID</label>
    <TooltipItem :text="tpidText" direction="right" align="bottom">
      <FontIcon name="question-circle" />
    </TooltipItem>
  </div>
</template>

<script>
import TooltipItem from '@/views/components/gg-pass/TooltipItem.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'TpidLabel',
  components: { FontIcon, TooltipItem },
  computed: {
    tpidText() {
      return 'TPID is a unique key assigned to each player upon account creation';
    },
  }

};
</script>

<style lang="less">
@import '~@/less/proj.less';
[tpid-label] {.c(@gp-component-label); .flex(); .items-center(); .gap(3);
  > label {.fs(13);}
  [font-icon] {.fs(14);}
}
</style>