<template>
  <ValidationProvider rg-limit-input :mode="mode" :name="name" v-slot="{errors}" :rules="rules">
    <label v-if="label && label.length"> {{ label }}</label>
    <div class="limit-field">
      <s>{{ currencySymbol }}</s>
      <div>
        <TextInputBox v-model="model" digit input-mode="numeric" pattern="[0-9]*" :max-length="preset.maxLength" :disabled="preset.disable" :placeholder="preset.placeholder" :text-align="preset.textAlign" />
      </div>
    </div>
    <ValidationErrors v-if="!['requiredNone', 'minMaxNone', 'requiredSelectNone'].some(rule => getErrorRules(errors).includes(rule)) && errors && errors.length" :errors="errors"  />
  </ValidationProvider>

</template>

<script>
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import Specific from '@shared/types/Specific';
export default {
  name: 'RgLimitInput',
  components: { TextInputBox },
  props: {
    currencySymbol: { type: String, default: null, },
    label: { type: String, default: null },
    name: { type: String, default: null, },
    value: { type: [String, Number], default: null },
    limit: { type: Number, default: 0 },
    rules: { type: String, default: '' },
    maxLength: { type: Number, default: 999 },
    preset: { type: Specific, default: null, },
    mode: {type: String, default: 'aggressive'}
  },
  watch: {
    'model': 'updateModel',
    'value': 'updateValue'
  },
  data() {
    return {
      model: '',
    };
  },
  methods: {
    updateModel(v) {
      this.$emit('input', v);
    },
    updateValue() {
      this.model = this.value;
    },
    getErrorRules(errors) {
      return errors && Array.isArray(errors) ? errors.map(v => v._rule_) : [];
    },
  },
  mounted() {
    this.model = this.value;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-limit-input] {
  > label { .c(@gp-component-label); .fs(14); .pl(3); display: block; .pb(5); }
  .limit-field { .rel();
    > s {.abs(); .c(#a1a1aa); .t(50%); .l(10); .fs(14); z-index: 2; transform: translateY(-50%);}
  }
  [validation-errors] {
    > li > a {.c(#2d74f6);}
  }
}
</style>