import { baseList } from '@/constants/base/siteMap.mjs';
import { toFullDateTS } from '@/utils/dateTimeUtil';
import { isObjectEmpty } from '@shared/utils/objectUtils.mjs';
import { symbols } from '@/utils/promotionCurrency';
import { setGTM } from '@/plugins/tracking';
import { isEmpty } from "lodash";
import siteModel from '@/constants/model/forgot-password';

class PromotionService {
  #services;
  #store;
  #router;
  #token;
  #lang;
  #baseLang;
  #site;
  #siteInfo;
  #isMobile;
  #promotion;
  #queries;
  #locale;
  #isClient;

  async setScope(scope) {
    this.#services = scope.$services;
    this.#router = scope.$router;
    this.#store = scope.$store;
    this.#token = this.#store.state.user?.token;
    this.#lang = this.#store.state.query?.selectedLang || this.#store.state.env?.baseLang;
    this.#site = this.#store.state.env?.site;
    this.#siteInfo = this.#store.state.env?.siteInfo;
    this.#locale = this.#store.state.query?.selectedLang;
    this.#isClient = this.#store.state.promotion?.isClient;

    setGTM(this.#site, this.#siteInfo, this.#router);
  }

  #getOnboardingUrl() {
    const baseUrl = process.env[`VUE_APP_ON_BOARDING_URL_${this.#site}`];
    const queries = { ...this.#queries };
    queries.verificationType = 'verification';
    queries.redirectUri = this.#getRedirectUri();
    const queryStr = this.#getQueryString(queries);
    return `${baseUrl}?${queryStr}`;
  }

  #getPaymentUrl() {
    const baseUrl = process.env[`VUE_APP_WIDGET_URL_${this.#site}`];
    const queryStr = this.#getQueryString(this.#queries);
    return `${baseUrl}/Cashier?${queryStr}`;
  }

  #getRedirectUri() {
    if(typeof window === 'undefined') return '';
    const path = window.location.origin + window.location.pathname;
    const queries = { ...this.#queries };
    if(this.#isClient) queries.isclient = true;
    const queryStr = this.#getQueryString(queries);
    return encodeURIComponent(`${path}?${queryStr}`);
  }

  #getQueries() {
    this.#queries = {
      token: this.#token,
      lang: this.#lang || this.#baseLang,
    };
    if(this.#isMobile) this.#queries.ViewType = 'mobileType';
  }

  #getQueryString(queries) {
    return Object.keys(queries).map(key => `${key}=${queries[key]}`).join('&');
  }

  #initialize() {
    const state = this.#store.state;
    this.#isMobile = state?.browser?.mobile;
    this.#promotion = state?.promotion;
    this.#isClient = state?.promotion?.isClient;
    this.#lang = state?.query?.selectedLang;
    this.#baseLang = state?.env?.baseLang;
  }

  #notificationsFilter(obj) {
    return Object.keys(obj).filter(key => obj[key].show === true).map(key => ({ name: key, ...obj[key] }));
  }

  #depositNotifications({ info }) {
    const url = this.#getPaymentUrl();
    const { remainBonus, bonus, promotionRestriction, siteId } = info;
    const notifications = {
      claimBonus: {
        show: remainBonus > 0,
        message: { key: 'notification.claimBonus', options: { bonus, url } }
      }
    };

    return this.#notificationsFilter({ ...notifications, ...this.restriction(promotionRestriction, siteId) });
  }

  #dailyNotifications({ info, customerErrorCode, claimedRewarded }) {
    const { isRequiredKyc, siteId, promotionRestriction, suspendPromotionData } = info;
    const suspended = !isObjectEmpty(suspendPromotionData);
    const url = this.#getOnboardingUrl();
    const notifications = {
      alreadyClaimed: {
        show: !suspended && ['KYC_UNVERIFIED', 'KYC_DOCUMENT_NOT_UPLOADED', 'KYC_TRIGGER_NOT_NONE', 'MAC_ADDRESS_DUPLICATE'].includes(customerErrorCode),
        icon: 'info-circle-line',
        message: { key: 'notification.alreadyClaimed' },
      },
      rewardReleased: {
        show: !suspended && claimedRewarded,
        icon: 'check-thin',
        message: { key: 'notification.rewardReleased' },
      },
      accountVerifyClickHere: {
        show: !suspended && isRequiredKyc,
        icon: 'info-circle-line',
        message: { key: 'notification.accountVerifyClickHere', options: { url } },
      },
      winterPromotion2023: {
        show: suspended,
        icon: 'info-circle-line',
        message: { key: 'notification.winterPromotion2023' },
      },
    };

    return this.#notificationsFilter({ ...notifications, ...this.restriction(promotionRestriction, siteId) });
  }

  #signUpNotifications ({ info, customerErrorCode, claimedRewarded }) {
    const { currency, maxBonus, isOnBoardingCompleted, siteId, promotionRestriction } = info;
    const bonus = this.getCurrencyString(currency, maxBonus);
    const url = this.#getOnboardingUrl();
    const notifications = {
      alreadyClaimed: {
        show: ['KYC_UNVERIFIED', 'KYC_DOCUMENT_NOT_UPLOADED', 'KYC_TRIGGER_NOT_NONE', 'MAC_ADDRESS_DUPLICATE'].includes(customerErrorCode),
        icon: 'info-circle-line',
        message: { key: 'notification.alreadyClaimed' },
      },
      accountVerifyNow: {
        show: (['KYC_UNVERIFIED', 'KYC_DOCUMENT_NOT_UPLOADED', 'KYC_TRIGGER_NOT_NONE', 'MAC_ADDRESS_DUPLICATE'].includes(customerErrorCode)) && !claimedRewarded,
        icon: 'info-circle-line',
        message: { key: 'notification.accountVerifyNow', options: { bonus, url } },
      },
      claimSignupBonus: {
        show: !isOnBoardingCompleted,
        message: { key: 'notification.claimSignupBonus', options: { bonus, url } },
      },
    };

    return this.#notificationsFilter({ ...notifications, ...this.restriction(promotionRestriction, siteId) });
  }

  #referralNotifications({ info }) {
    const { isOnBoardingCompleted, siteId, promotionRestriction } = info;
    const url = this.#getOnboardingUrl();
    const notifications = {
      kycVerifyRequired: {
        show: !isOnBoardingCompleted,
        message: { key: 'notification.kycVerifyRequired', options: { url } },
      },
    };

    return this.#notificationsFilter({ ...notifications, ...this.restriction(promotionRestriction, siteId) });
  }

  restriction(promotionRestriction, siteId) {
    if (!promotionRestriction) return {};

    const { status, restrictionEndDate: endDate } = promotionRestriction;
    if (status !== 'Restriction' && status !== 'Edit') return {};
    const email = baseList.find(o => o.site === siteId).csEmail;
    const date = endDate && toFullDateTS(endDate, this.#locale);
    const notifications = {
      restrictionDue: {
        show: !endDate,
        message: { key: 'notification.restriction.due', options: { email } },
      },
      restrictionUntil: {
        show: !!endDate,
        message: { key: 'notification.restriction.until', options: { date, email } },
      }
    };

    return notifications;
  }

  getNotifications(name, option = {}) {
    if (this.#store) {
      this.#initialize();
      this.#getQueries();

      if (isEmpty(option?.info)) return {};
      switch (name) {
        case 'deposit':
          return this.#depositNotifications(option);
        case 'daily':
          return this.#dailyNotifications(option);
        case 'signUp':
          return this.#signUpNotifications(option);
        case 'referral':
          return this.#referralNotifications(option);
        default:
          break;
      }
    } else return null;
  }

  getCurrencyString(currency, value) {
    const site = this.#store.state.env.site;
    const country = this.#store.state.promotion.country;
    const isInt = ['JPY', 'KRW', 'VND', 'GCO'].includes(currency);
    const symbol = symbols[currency] ?? '$';

    if (!value) return isInt ? `${symbol}0` : `${symbol}0.00`;
    const v = isInt ? value : (Math.floor(value * 100) / 100).toFixed(2);

    if (currency === 'GCO') return `${v}Million`;
    if (['GCD', 'GTD'].includes(currency)) {
      if (country === 'DE') return `${symbol}${v}`.replace('$', '€');
      if (site === 'N8IN') return `${symbol}${v}`.replace('$', '');
    }

    return `${symbol}${v}`;
  }
}

export default {
  install(Vue, { store, router }) {
    Vue.prototype.$promotion = new PromotionService(store, router);
  }
};
