export default class TokenService {
  #services;
  #npDevApi;
  #proxyApi;
  #store;

  constructor(services) {
    this.#services = services;
    this.#npDevApi = services.npDevApi;
    this.#proxyApi = services.proxyApi;
    this.#store = services.store;
  }

  /**
   * @description 인증서버 정상 동작 확인
   * @returns {Promise<{message:string}>}
   */
  async ping() {
    try {
      return await this.#proxyApi.get('/ping');
    } catch (e) {
      console.log('error : ping', e);
      throw e;
    }
  }

  /**
   * @description np token을 기준으로 op, np token 생성 요청
   * @param token
   * @returns {Promise<ResponseAuthInfo>}
   */
  async generateToken(token, config) {
    console.log('generateToken', token);
    try {
      return await this.#proxyApi.post('/auth/sign-in', { npToken: token, npGwPath: this.#services.brandPath }, config);
    } catch (e) {
      console.log('error : generateToken', e);
      throw e;
    }
  }

  /**
   * @description 현재 접속 user의 token 정보 가져오기
   * @returns {Promise<ResponseAuthInfo>}
   */
  async getToken() {
    try {
      return await this.#proxyApi.get('/auth/session/me');
    } catch (e) {
      console.log('error : getToken', e);
      throw e;
    }
  }

  /**
   * @description token 자동 연장 요청(1시간)
   * @returns {Promise<ResponseAuthInfo>}
   */
  async autoRenewToken() {
    try {
      return await this.#proxyApi.post('/auth/session/token/regist-refresh');
    } catch (e) {
      console.log('error : autoRenewToken', e);
      throw e;
    }
  }

  /**
   * @description np token 발급 요청(np 개발 전용 api)
   * @param token
   * @returns {Promise<*>}
   */
  async npAccessTokenForDev({ token }) {
    try {
      return await this.#npDevApi.get('/player/access-tokens', { token });
    } catch (e) {
      console.log('error : npAccessTokenForDev', e);
      throw e;
    }
  }

  async validateNpToken() {
    const token = this.#store.state.query.token;
    try {
      const r = token ? await this.#services.token.generateToken(token) : await this.#services.token.getToken();
      // if (r.error) throw r;
      this.#store.commit('user/setToken', token || r?.data?.npToken?.accessToken);
      return true;
    } catch (e) {
      if (e?.code === 502) {
        return true;
      } else if (e?.code === 403) {
        this.#store.commit('user/setToken', null);
        return false;
      } else {
        return false;
      }
      // if (e?.code === 403) {
      //   try {
      //     if (!token) return false;
      //
      //     const rr = await this.#services.token.generateToken(token);
      //     if (!rr?.error || rr?.code === 502) {
      //       this.#store.commit('user/setToken', token);
      //       return true;
      //     }
      //
      //     return false;
      //   } catch (ee) {
      //     return false;
      //   }
      // }
      // else if (e?.code === 502) {
      //   this.#store.commit('user/setToken', token);
      //   return true;
      // }

      return false;
    }
  }
}
