<template>
  <div not-support-country>
    <ErrorTitle title="accessDeny.title" description="accessDeny.countryDescription" :options="{name: displayBrandName}"/>
  </div>
</template>

<script>
import ErrorTitle from "@/views/components/extra/ErrorTitle.vue";
import { brandIds, displayBrandNames } from '@/constants/base/siteMap';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'NotSupportCountry',
  components: { ErrorTitle },
  computed: {
    brand: state('env', 'brand'),
    displayBrandName() {
      const key = Object.keys(brandIds).find(key => brandIds[key] === this.brand);
      return this.brandNames[key];
    },
    brandNames() {
      return displayBrandNames;
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[not-support-country] { .pb(30); }
</style>