import FiMobile from '@/views/components/pages/find-id/template/FiMobile.vue';
import FiEmail from '@/views/components/pages/find-id/template/FiEmail.vue';
import FiVerify from '@/views/components/pages/find-id/template/FiVerify.vue';
import FiFind from '@/views/components/pages/find-id/template/FiCredential.vue';
import FiCreate from '@/views/components/pages/find-id/template/FiCreate.vue';
import FiConfirmPassword from '@/views/components/pages/find-id/template/FiConfirmPassword.vue';
import FiComplete from '@/views/pages/find-id/FiComplete.vue';
import FiEmailGuide from '@/views/components/pages/find-id/template/FiEmailGuide.vue';
import FiPasswordGuide from '@/views/components/pages/find-id/template/FiPasswordGuide.vue';
export const routeNames = {
  FiSelect: 'FiSelect',
  FiEmail: 'FiEmail',
  FiMobile: 'FiMobile',
  FiVerify: 'FiVerify',
  FiEmailGuide: 'FiEmailGuide',
  FiPasswordGuide: 'FiPasswordGuide',
  FiCreate: 'FiCreate',
  FiCredential: 'FiCredential',
  FiComplete: 'FiComplete',
  FiConfirmPw: 'FiConfirmPw',
};

const apiType = {
  Mobile: 'Mobile',
  Email: 'Email'
};

const siteModel = isCreate => ({
  model: {
    CountryCode: null,
    MobileNumber: null,
    Email: null,
    Code: null,
    Token: null,
    Type: 'RESET_PASSWORD',
    Method: 'CODE',
    Password: null,
    ConfirmPassword: null,
    AccessToken: null,
    VerificationMethods: null,
    LoginMethod: null,
    NpApiType: 'Email',
    UserName: null,
    MessageType: 0,

    PlayerEmailVerificationType: 'ForgotPassword',
    VerificationType: 'ForgotPassword',
  },
  [routeNames.FiMobile]: {
    title: 'findId.title.findGGPassId',
    component: FiMobile,
    btnName: 'next',
    captchaAction: 'FindIdSendMobileCode',
    desc: 'findId.description.inputMethod',
    api: 'requestMobileCert',
    next: routeNames.FiVerify,
    back: true,
  },
  [routeNames.FiEmail]: {
    title: 'findId.title.findGGPassId',
    component: FiEmail,
    btnName: 'next',
    desc: 'findId.description.inputMethod',
    captchaAction: 'FindIdSendEmailCode',
    api: 'requestEmailCert',
    next: routeNames.FiVerify,
    back: true,
  },
  [routeNames.FiVerify]: {
    title: 'findId.title.verificationCode',
    component: FiVerify,
    btnName: 'verify',
    captchaAction: 'FindIdVerify',
    api: isCreate ? 'ggpassVerify': 'verify',
    back: true,
  },
  [routeNames.FiCredential]: {
    title: 'findId.title.findGGPassId',
    component: FiFind,
    btnName: 'next',
    captchaAction: 'FindIdVerify',
    api: isCreate ? 'ggpassVerify' : 'verify',
    back: true,
  },
  [routeNames.FiCreate]: {
    title: 'emailAddress',
    desc: 'findId.description.createGgpassDesc',
    component: FiCreate,
    captchaAction : 'FindIdSendOnepassEmailCode',
    btnName: 'next',
    next: 'FiVerify',
    api: 'requestGgpassEmailCert',
  },
  [routeNames.FiConfirmPw]: {
    title: 'findId.title.ggpassIdPassword',
    component: FiConfirmPassword,
    btnName: 'next',
    api: 'confirmPassword',
  },
  [routeNames.FiPasswordGuide]: {
    title: null,
    component: FiPasswordGuide,
    btnName: 'next',
    api: null,
    back: false,
  },
  [routeNames.FiEmailGuide]: {
    title: null,
    component: FiEmailGuide,
    btnName: 'next',
    api: null,
    back: false,
  },
  [routeNames.FiComplete]: {
    component: FiComplete,
  }
});



export default siteModel;