import SignupModel from '@/constants/model/sign-up';
import OnboardingModel from '@/constants/model/on-boarding';
import SignRouteStepAdaptor from '@/modules/SignRouteStepAdaptor';
import OnboardingRouteStepAdaptor from '@/modules/OnboardingRouteStepAdaptor';

const modelMap = new Map();

class ModelController {
  #routeAdaptor;
  #services;
  #router;
  #store;
  constructor(services, router, store) {
    this.#services = services;
    this.#router = router;
    this.#store = store;
  }

  /**
   * siteMap 기준으로 model에 설정된 템플릿 정보를 조합하여 반환 (structure)
   * @param scope -
   * @param {'onboarding'|'signup'} application - application 명. 차후 추가 가능성 있음(ggpass, myinfo, fp 등등)
   * @param {string} siteId - 현재 접속 site의 id
   * @param {string[]} orderRouteNames - api를 호출하여 가져온 step 정보
   * @param replaceModel - replace 경우 model injection 정보
   * @param params - route param
   * @returns {Promise<ModelInfo>}
   */
  async getModel(scope, {application, orderRouteNames, siteId, replaceModel, params}) {
    let /** @type {ModelInfo} */ modelInfo = modelMap.get(siteId);
    if (!modelInfo || replaceModel) {
      if(modelInfo) modelMap.delete(siteId);

      const isOnboarding = application === 'onboarding';
      switch (application) {
        case 'onboarding':
          modelInfo = OnboardingModel();
          break;
        case 'signup':
          modelInfo = SignupModel({ site: siteId, npOnly: this.#store.state.env.npOnly });
          break;
        default:
          break;
      }

      const list = isOnboarding ? await this.setOnboardingModel(scope, modelInfo, orderRouteNames, Boolean(replaceModel), params) : await this.setSignupModel(scope, modelInfo, orderRouteNames);
      if (list?.error) return list;

      modelInfo.list = list;
      if(replaceModel) modelInfo.model = { ...replaceModel };

      modelMap.set(siteId, modelInfo);
    } else {
      await this.#updateStep(modelInfo);
    }

    return modelInfo;
  }

  /**
   * onBoardingSiteMap 기준으로 model에 설정된 템플릿 정보를 조합하여 반환 (structure)
   * @param scope
   * @param store
   * @param model - model/on-boarding/index.mjs 에 설정된 템플릿 정보
   * @param replace
   * @param params
   * @param {String[]} orderRouteNames - api를 호출하여 가져온 onboarding step 정보
   * @returns {RouteItem[]}
   */
  async setOnboardingModel(scope, model, orderRouteNames, replace, params) {
    if(!this.#routeAdaptor) this.#routeAdaptor = new OnboardingRouteStepAdaptor(scope, { store: this.#store, services: this.#services });
    if(params?.route) return this.#routeAdaptor.getRouteItem(model, params.route);
    return await this.#routeAdaptor.resetOrder(model, orderRouteNames, replace);
  }

  /**
   * signUpSiteMap 기준으로 model에 설정된 템플릿 정보를 조합하여 반환 (structure)
   * @param scope
   * @param model - model/sign-up/index.mjs 에 설정된 템플릿 정보
   * @param orderRouteNames - constants/base/signUpSiteMap.js 안에 해당 Site의 steps 배열 정보(front에서 핸들링하는 signup step 정보)
   * @param params
   * @returns {RouteItem[]}
   */
  async setSignupModel(scope, model, orderRouteNames, params) {
    if(!this.#routeAdaptor) this.#routeAdaptor = new SignRouteStepAdaptor(scope, { store: this.#store, services: this.#services });
    if(params?.route) return this.#routeAdaptor.getRouteItem(model, params.route);
    return await this.#routeAdaptor.resetOrder(model, orderRouteNames);
  }

  remove(siteId) {
    modelMap.delete(siteId);
  }

  async #updateStep(model) {
    const path = this.#router.app.$route.name;
    const id = model.list.findIndex(o => o.route === path);
    const gap = id - model.step;
    model.progress += gap;
    model.step = id < 0 ? 0 : id;
  }
}

export default {
  install(Vue, { services, router, store }) {
    Vue.prototype.$model = new ModelController(services, router, store);
  }
};
