<template>
  <div gp-toggle-switch @click="$emit('click')">
    <input type="checkbox">
    <span :class="['slider', {'active': active}]"></span>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    active: {type: Boolean,default: false},
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-toggle-switch] { .rel(); display: inline-block; .w(48); .h(24);
  >input{opacity: 0; .wh(0);}
  .slider{.abs(); cursor:pointer; .t(0); .l(0); .r(0); .b(0); .bgc(#D4D4D8); transition: .4s; .br(24);
    &.active{.bgc(#249135);
      &::before{transform: translateX(24px);}
    }
    &::before{.abs(); content:""; .wh(16); .l(4); .b(4); .bgc(white); transition: .4s; .br(50%);}
  }
}
</style>