<template>
  <div progress-bar>
    <p v-if="start" class="start">{{ start }}</p>
    <div class="bar">
      <span :style="`width: ${width * 100}%`" />
      <i v-if="currentValue && maxValue">{{ currentValue }} / {{ maxValue }}</i>
    </div>
    <p v-if="end" class="end">{{ end }}</p>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    width: { type: String | Number, require: true },
    current: { type: String | Number },
    max: { type: String | Number },
    start: { type: String | Number },
    end: { type: String | Number },
  },
  data() {
      return {
        noText: true,
      };
  },
  computed: {
    currentValue() {
      if (this.current !== undefined && this.current !== null) return String(this.current);
    },
    maxValue() {
      if (this.max !== undefined || this.max !== null) return String(this.max);
    }
  }
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[progress-bar] { .flex(); .items-center(); .gap(8); .c(@c-white);
  .bar { .flex(); .flex-dc(); .justify-center(); .items-center(); .rel(); .wf(); .h(20); .br(2em); .bgc(@c-bgProgress); .tc();
    span { .abs(); .lt(0, 0); .hf(); .br(2em); background: linear-gradient(to left, #c64040, #8e1919); }
    i { .rel(); .lt(0, 0); .wf(); .z(1); .fs(12); .lh(20); }
  }

  @media(@mm-up) { .gap(16); }
}
</style>
