<template>
  <div fp-resend-button>
    <p><span v-if="isRunning" class="timer">{{ intervalTime }}s</span><a :class="['resend', {'end' : !isRunning}]" @click="resend">{{ $t('resendButton', { method: isEmail ? $t('email') : $t('sms') }) }}</a></p>
  </div>
</template>

<script>
export default {
  name: 'FpResendButton',
  props: {
    isEmail: { type: Boolean, default: false, },
    resendTime: { type: Number, default: 59 },
  },
  data() {
    return {
      intervalTime: 0,
      timer: () => {}
    };
  },
  computed: {
    isRunning() {
      return this.intervalTime > 0;
    },
    buttonText(){
      return this.$t('resendButton', { method: this.isEmail ? this.$t('email') : this.$t('sms') })
    }
  },
  watch: {
    resendTime: function () {
      this.intervalTime = this.resendTime;
      if (this.timer) clearInterval(this.timer);
      this.startTimer();
    },
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        --this.intervalTime;
        if (this.intervalTime <= 0) {
          this.$emit('clearTime');
          clearInterval(this.timer);
        }
      }, 1000);
    },
    async resend() {
      if (this.intervalTime > 0) return;
      await this.$emit('resend');
    },
  },
  mounted() {
    this.intervalTime = this.resendTime;
    this.startTimer();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-resend-button] {.flex(); .justify-end();
  > p {.flex();.gap(10);}
  .resend {text-decoration-line: underline; .c(#434343);
    &.end {.c(#CCCCCC);}
  }
  .timer {.c(#737373);}
}
</style>