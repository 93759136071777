<template>
  <div reaffirm-wsop-names :name="formTag" :class="formClass">
    <p v-html="$t('personalDetails.nameGuide')"/>
    <div class="field-holder" >
      <ValidationComponent :label="$t('firstName')" name="_firstName" :component="TrimTextInput" :rules="`requiredPersonalName:0,50,${countryWritingSystem}`" v-model="modelInfo.FirstName" :error-msg="firstErrorMsg" :preset="preset" />
      <ValidationComponent :label="$t('lastName')" name="_lastName" :component="TrimTextInput" :rules="`requiredPersonalName:0,50,${countryWritingSystem}`" v-model="modelInfo.LastName" :error-msg="lastErrorMsg" :preset="preset" />
    </div>
    <OptionalName :model-info="modelInfo" />
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import OptionalName from '@/views/components/pages/on-boarding/template/extra/OptionalName.vue';

export default {
  name: 'ReaffirmWsopNames',
  components: { OptionalName, ValidationComponent },
  mixins: [baseTemplate],
  props: {
    formTag: { type: String, default: null },
    formClass: { type: String, default: null },
  },
  data() {
    return {
      firstErrorMsg: null,
      lastErrorMsg: null,
      isCheckSameLastName: false,
      TrimTextInput,
    };
  },
  computed: {
    countryWritingSystem() {
      return this.modelInfo.CountryWritingSystem;
    },
    preset() {
      return { maxLength: 50, disable: true };
    },
    formName() {
      return this.site === siteIds.WSOPON ? 'WSOPca_Step11_Personal_Details_Name_View' : 'Personal_Details_Name_View';
    },
    site: state('env', 'site'),
  },
  watch: {
    errorMsg() {
      const { errorMsg } = this;
      this.firstErrorMsg = null;
      this.lastErrorMsg = null;

      if (errorMsg) {
        if (errorMsg === apiErrorCode.INVALID_FIRST_NAME) this.firstErrorMsg = this.$tv({ _rule_: 'required', _field_: '_firstName' });
        else if (errorMsg === apiErrorCode.INVALID_LAST_NAME) this.lastErrorMsg = this.$tv({ _rule_: 'required', _field_: '_lastName' });
      }
    },
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[reaffirm-wsop-names] {
  > p { .c(@c-text-desc); .mb(24); }
  > div {
    > input { .wh(0); .abs();}
  }
  .field-holder { .flex(); .space-between();
    > * {.w(calc(50% - 5px)); }
  }
  > *:nth-of-type(2) { .mt(8); }
}
</style>