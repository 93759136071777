<template>
  <BaseModal transfer-reseult-modal :title="$t('myInfo.confirmTransfer')">
    <template #content>
      <div v-if="debit && credit" class="body">
        <p class="description">{{ $t('myInfo.balanceTransferResultDesc') }}</p>
        <div class="content">
          <GpTitle :name="$t('myInfo.sendFrom')" />
          <LinkAmountItem :label="debit.siteId" :country-code="debit.countryCode" :amount="debit.amount" :logo-url="debit.logoUrl" />
          <GpTitle :name="$t('myInfo.sendTo')" />
          <LinkAmountItem :label="credit.siteId" :country-code="credit.countryCode" :amount="credit.amount" :logo-url="credit.logoUrl" />
          <GpTitle :name="$t('myInfo.transferAmount')" />
          <div class="amount-group">
            <p class="amount">{{ displayAmount(debit.currency, fromAmount) }}</p>
            <p class="exchange">{{ displayAmount(credit.currency, exchangeAmount) }}</p>
          </div>
        </div>
      </div>
    </template>
    <template #floating>
      <div class="floating">
        <GpButton type="submit-green" :progress="progress" :disabled="progress" @click="submit">{{ $t('confirm') }}</GpButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>

import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import specific from '@shared/types/Specific';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import LinkAmountItem from '@/views/components/pages/my-page/template/link-account/LinkAmountItem.vue';
import { getDisplayAmount } from 'client-user-ssr/src/utils/amountUtil';

export default {
  name: 'TransferResultModal',
  components: { LinkAmountItem, GpTitle, GpButton, BaseModal },
  props: {
    options: specific
  },
  data() {
    return {
      debit: null,
      credit: null,
      fromAmount: null,
      exchangeAmount: null,

      delay: 2000,
      timeout: null,
      progress: false,
    };
  },
  computed: {},
  methods: {
    displayAmount(currency, amount) {
      return getDisplayAmount(currency, amount);
    },

    async submit() {
      if (this.progress) return;
      const { request } = this.options;
      this.progress = true;
      this.timeout = setTimeout(() => {
        this.progress = false;
        if (this.timeout) clearTimeout(this.timeout);
      }, this.delay);
      await request();
    },


  },
  mounted() {
    this.debit = this.options.debit;
    this.credit = this.options.credit;
    this.fromAmount = this.options.fromAmount;
    this.exchangeAmount = this.options.exchangeAmount;
    this.model = this.options.model;
    this.progress = false;
    if (this.timeout) clearTimeout(this.timeout);
  },
  beforeDestroy() {
    this.progress = false;
    if (this.timeout) clearTimeout(this.timeout);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[transfer-reseult-modal] {
  > .panel { .h(85%); .rel();
    .scroll-area {
      .h(calc(100% - 59px)); .flex(); .flex-dc();
      > .body { flex: 1; .flex(); .flex-dc(); .pb(20);
        > .description {.ph(40); text-align: center; line-height: 16px; .c(#737373); .fs(12);}
        > .content {flex: 1;}
      }
    }
    .floating {.abs(); .b(20); .w(100%); .ph(20);}
    .amount-group { .p(20); .bgc(#fff); .br(16); .flex(); .flex-dc(); text-align: right; .mb(20);
      .amount {.c(#000); .fs(14); font-weight: 600; border-bottom: 1px solid #D9D9E5; .pb(10);}
      .exchange {.c(#737373);.fs(12); .pt(10);}
    }

  }

}
</style>
