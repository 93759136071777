<template>
  <SearchDropSelect v-if="list" v-model="selectedItem" :placeholder="placeholder" :list="list" @focus="$emit('focus')" @input="update" @blur="blur" :search-value="searchValue" :search-handler="search" :hidden-icon="hiddenIcon" />
</template>

<script>
import _ from 'lodash';
import Specific from '@shared/types/Specific';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { state } from '@shared/utils/storeUtils.mjs';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';

export default {
  name: 'StateSearchSelect',
  components: { SearchDropSelect },
  props: {
    value: { type: Specific },
    placeholder:{type:String, default : null},
    hiddenIcon:{ type: Boolean, default : false},
  },
  data() {
    return {
      searchValue: null,
      selectedItem: null,
      originList: null,
      filterList: [],
      delayId: false,
      list: null
    };
  },
  computed: {
    site: state('env', 'site'),
    myInfo: state('user', 'userInfo'),
  },
  watch: {
    value: 'updateValue',
    searchValue: 'search',
  },
  methods: {
    async blur() {
      await sleep(60);
      if (this.selectedItem && this.searchValue !== this.selectedItem.label) this.searchValue = this.selectedItem.label;
      this.$emit('blur');
    },
    updateValue() {
      if (this.value) {
        this.searchValue = this.value.label;
        this.selectedItem = this.value;
      }
    },
    async update(v) {
      if (!v) return;
      this.selectedItem = v;
      this.$emit('input', this.selectedItem);
    },
    search(list, searchValue) {
      if (!_.isArray(list)) return;
      if (searchValue === this.selectedItem?.label) return;
      if (searchValue && searchValue.length) {
        return _.filter(list, o => /** @type { BaseInfo } */ o.label.toLowerCase().includes(searchValue.toLowerCase()));
      }
    }
  },
  async mounted() {
    this.list = (await this.$services.base.getStateList({ SiteId: this.site })).map(o => ({ label: o.StateName, value: o.StateCode }));
    this.filterList = this.list;

    this.updateValue();
  }
};
</script>

<style scoped>

</style>