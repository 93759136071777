<template>
  <div calendar-list v-if="current">
    <div class="calendar-holder">
      <h4>
        <button @click="goPrev" class="btn-l">
          <FontIcon name="angle-left" />
        </button>
        {{ currentTitle }}
        <button @click="goNext" v-if="isFitType && !limitNext" class="btn-r">
          <FontIcon name="angle-right" />
        </button>
      </h4>
      <Calendar :month="currentMonth" :range-start="selectedStart" :range-end="selectedEnd" :selected="selectedStart" @select="e => selectedDate(e, false)" />
    </div>
    <div class="calendar-holder" v-if="!isFitType">
      <h4>{{ nextTitle }}
        <button @click="goNext" class="btn-r" v-if="!limitNext">
          <FontIcon name="angle-right" />
        </button>
      </h4>
      <Calendar :month="nextMonth" :range-start="selectedStart" :range-end="selectedEnd" :selected="selectedEnd || selectedStart" @select="e => selectedDate(e, true)" />
    </div>
  </div>
</template>

<script>
import Calendar from '@shared/components/common/date/Calendar.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { getYMD, getYM, getM, utc, getTime } from '@shared/utils/timeUtils.mjs';
import moment from 'moment';

export default {
  name: 'CalendarList',
  components: { FontIcon, Calendar },
  props: {
    value: Object,
  },
  data() {
    return {
      monthlyList: [],
      current: null,
      next: null,
      selectedStart: null,
      selectedEnd: null,
    };
  },
  computed: {
    isFitType() {
      return this.matchedMediaDevice === 'M';
    },
    currentTitle() {
      return this.current.format('MMMM YYYY');
    },
    nextTitle() {
      return this.next.format('MMMM YYYY');
    },
    currentMonth() {
      return getYM(this.current);
    },
    nextMonth() {
      return getYM(this.next);
    },
    currentDate() {
      return getYMD(this.current);
    },
    nextDate() {
      return getYMD(this.next);
    },
    limitNext() {
      return getTime(moment(this.currentMonth).add(this.isFitType ? 1 : 2, 'month')) > getTime(getYM());
    },
    getNext() {
      return moment(this.current).add(1, 'month');
    },
  },
  watch: {
    value: 'update',
  },
  methods: {
    update() {
      this.updateMonth(this.value?.sortEnd ? this.value?.end : this.value?.start, false, this.value.sortEnd);
      this.selectedStart = this.value?.start;
      this.selectedEnd = this.value?.end;
    },

    updateMonth(m, pass, sortEnd) {
      if (!pass && m && (getYM(m) === this.currentMonth || getYM(m) === this.nextMonth)) return;
      this.current = moment(m).add(0, 'month');
      if (sortEnd) this.current = this.current.add(-1, 'month');
      this.next = this.getNext;
    },
    goPrev() {
      this.updateMonth(this.current.add(-1, 'month'), true);
    },
    goNext() {
      this.updateMonth(this.current.add(2, 'month'), true);
    },
    selectedDate(date) {
      const nowM = getM(date);

      if (this.selectedStart && this.selectedEnd) {
        this.selectedEnd = null;
        this.selectedStart = null;
      }

      if (!this.selectedStart) {
        this.selectedStart = date;
      } else if (date < this.selectedStart) {
        this.selectedEnd = this.selectedStart;
        this.selectedStart = date;
      } else {
        this.selectedEnd = date;
      }

      if (this.selectedStart && getM(this.current) > nowM) this.goPrev();
      else if (this.selectedEnd && getM(this.next) < nowM) this.goNext();
      this.$emit('input', { start: this.selectedStart, end: this.selectedEnd, sortEnd: Boolean(this.selectedEnd) });
    },
  },
  mounted() {
    this.update(this.value);
  },
};
</script>

<style lang="less">
@import "~@shared/less/proj.less";
[calendar-list] { .grid(); .grid-template-columns(1fr); .grid-gap(16); .c(black);
  .calendar-holder {
    h4 { .tc(); .mb(12); .fs(14); .rel();
      > button { .abs();
        svg { .wh(20); }
        &.btn-l {.lt(0, 60%); .t-yc();}
        &.btn-r {.rt(0, 60%); .t-yc();}
      }
    }
    [calendar] {}
  }
  @media (@tp-up) {
    .grid-template-columns(1fr 1fr);
  }
}
</style>
