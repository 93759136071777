<template>
  <div input-game-limit-table>
    <LimitTable>
      <template #header>
        <div class="left">{{ $t('_.category') }}</div>
        <div class="center">{{ $t('_.noRestrictions') }}</div>
        <div class="center">{{ $t('_.restricted') }}</div>
      </template>
      <template #body>
        <div class="input-group">
          <div class="row">
            <label class="label">{{ $t('_.poker') }}</label>
            <RadioList type="vertical" :list="radioValues" v-model="model.RequestPokerGameLimit" />
          </div>
          <div class="row">
            <label class="label">{{ $t('_.casino') }}</label>
            <RadioList type="vertical" :list="radioValues" v-model="model.RequestCasinoGameLimit" />
          </div>
          <div class="row">
            <label class="label">{{ $t('_.liveCasino') }}</label>
            <RadioList type="vertical" :list="radioValues" v-model="model.RequestLiveDealerGameLimit" />
          </div>
        </div>
      </template>
    </LimitTable>
  </div>
</template>

<script>
import LimitTableRow from '@/views/components/pages/responsible-gaming/template/common/LimitTableRow.vue';
import LimitTable from '@/views/components/pages/responsible-gaming/template/common/LimitTable.vue';
import Specific from '@shared/types/Specific';
import RadioList from '@/views/components/gg-pass/RadioList.vue';

export default {
  name: 'InputGameLimitTable',
  lexicon: 'responsibleGaming.label',
  components: { RadioList, LimitTable, LimitTableRow },
  props: {
    model: Specific,
    current: Specific,
  },
  data() {
    return {};
  },
  computed: {
    radioValues() {return [{ value: false, label: this.$t('_.noRestrictions') }, { value: true, label: this.$t('_.restricted') }];}
  },
  methods: {}
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-game-limit-table] {
  .center { text-align: center; }
  .left { text-align: left}
  .input-group {.flex(); .flex-dc(); .gap(25);}
  .row {.flex(); .flex-dc(); .gap(5);
    > label {flex: 1; .c(black); .fs(16); font-weight: 600;}
    > [gp-radio-group] {.bgc(#EBEBEF); .br(16); .p(20); }
  }
}
</style>