<template>
  <JumioContainer jumio-pep-match>
    <FontIcon name="exclmation-circle-regular" />
    <h3>{{ $t('_.title') }}</h3>
    <div class="desc">
      <p>{{ $t('_.accountFunctionalityLimited') }}</p>
      <p v-html="$t('_.contactForAssistance', { csEmail })" />
    </div>
    <ProgressButton button-name="close" @click="$emit('confirm')" />
  </JumioContainer>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import JumioContainer from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioContainer.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'JumioPepMatch',
  lexicon: 'errorPages.jumio.pepMatch',
  components: { ProgressButton, FontIcon, JumioContainer },
  props: {
    item: {type: Specific, default: null},
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  mounted() {
    if (this.item) this.item.title = 'onBoarding.title.pepMatch';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[jumio-pep-match] {}
</style>