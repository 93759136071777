import _ from 'lodash';

/**
 * @description 단순히 에러를 던지는 함수
 * @param {string} msg
 */
export const err = msg => {
  throw new Error(msg);
};

/**
 * @description
 * 에러에 메시지와 코드를 함께 심어서 생성
 * @example
 * try {
 *   throw new CustomError('ERROR_CODE', 'has an error');
 * } catch(err) {
 *   err.name === 'ERROR_CODE'
 *   err.message === 'has an error'
 * }
 */
export function CustomError(name = '', message = '') {
  this.name = name;
  this.message = message;
  const error = new Error(this.message);
  error.name = this.name;
  this.stack = error.stack;
}
CustomError.prototype = Object.create(Error.prototype);

/**
 * @param {number} time
 * @returns {Promise<null>}
 */
export const sleep = time => new Promise(resolve => setTimeout(resolve, time));

/**
 * start 부터 end 까지 step 간격의 수를 반환
 * @param {number} start
 * @param {number} end
 * @param {number} step
 * @returns {number[]}
 */
export const range = (start, end, step = 1) => {
  const delta = end - start;
  if (!delta) return [start];
  return [...(function* () {
    let i = start;
    while (true) {
      yield i;
      if (Math.abs(end - i) < Math.abs(step)) break;
      end - start > 0 ? i += step : i -= step;
    }
  }())];
};

/**
 * 특정 월의 일 목록
 * @param {number} year
 * @param {number} month
 * @returns {number[]}
 */
export const datesOfMonth = (year, month) => {
  if (!year || !month) return [];
  return [...(function* () {
    const date = new Date(year, month - 1);
    while (date.getMonth() === month - 1) {
      yield date.getDate();
      date.setDate(date.getDate() + 1);
    }
  }())];
};

/**
 * 직관적으로 in 연산자를 사용하기 위해 keyBy를 proxy
 * @param {string} values
 * @returns {*}
 */
export const values = (...values) => _.keyBy(values);

/**
 * @param {any} value
 * @param {any[]} allowed
 * @param {any} fallback
 */
export const regulation = (value, allowed, fallback) => (allowed.indexOf(value) === -1 ? fallback : value);

/**
 * 특정 길이만큼 앞에 특정값을 채움
 * @param {string|number} v 값
 * @param {string|number} p 갯수만큼 앞에 채울 값
 * @param {number} len 원하는 길이
 * @returns {string}
 */
export const pad = (v, p, len) => {
  let r = `${v}`;
  if (r.length >= len) return r;
  while (r.length < len) r = p + r;
  return r;
};

/**
 * 한글을 2자로 계산하는 방식의 문자열 길이
 * @CHECK 이전 소스상 한글 1자와 영문 2자를 같은 길이로 보는 커멘트가 보여 validation 시 이 방식으로 byte 체크를 진행하였으나 확실한지 확인 필요.
 * @param {string} str
 * @returns {number}
 */
export const getCharLength = str => {
  if (!str) return 0;
  let i = str.length;
  let result = 0;
  while (i--) result += str.charCodeAt(i) >> 7 ? 2 : 1;
  return result;
};

/**
 * 문자열 utf-8 byte 계산
 * @param {string} str
 * @returns {number}
 */
export const getByteLength = str => {
  if (!str) return 0;
  let i = str.length;
  let result = 0;
  let code;
  while (i--) {
    code = str.charCodeAt(i);
    result += code >> 11 ? 3 : code >> 7 ? 2 : 1;
  }
  return result;
};
/**
 * 클립보드에 문자열 카피
 * @param {string} str
 */
export const copyClipboard = str => {
  if (typeof str !== 'string') throw Error('it should be a string!');
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const focusSelector = selector => vnode => {
  const target = vnode.$el.querySelector(selector);
  if (target) vnode.$el.querySelector(selector).focus();
};

/**
 * @description
 * <br /> 같은 HTML 태그를 텍스트에서 제거해주는 기능
 * @function clearTag
 * @param {string} txt
 * @return {string}
 */
export const clearTag = txt => {
  if (!txt) return '';
  // eslint-disable-next-line
  const re = /(<[\d\w \/]*>)/gi;
  return `${txt}`.replace(re, ' ');
};

/**
 * @description
 * 현재 디버깅중인지 확인하는 기능
 * @returns {boolean}
 */
export const checkDebugging = () => ['development', 'stage', 'local'].findIndex(env => process.env.NODE_ENV === env || process.env.VUE_APP_ENV === env) !== -1;

/**
 * @description
 * list 목록에 width 가 있으면 해당 값으로 하고 아니면, defaultWidth 로 해서 필요한 넓이를 반환
 * */
export const getTableWidth = (list, defaultWidth = 140) => `${_.reduce(list, (o, v) => Number(o) + Number(v.width || defaultWidth), 0)}px`;