<template>
  <div gp-menu-link-button :class="[{arrow}, {'green' : greenBoarder}, {'no-pointer' : hiddenRight}]" @click="$emit('click')">
    <div class="menu-group">
      <div class="label">
        <div class="left">
          <FontIcon v-if="icon" :name="icon" />
          <span>{{ name }}</span>
        </div>
        <GpBadge v-if="isMfaBadge" :type="badgeType">{{ mfaBadgeText }}</GpBadge>
      </div>
      <div class="right-menu" v-if="!hiddenRight">
        <FontIcon v-if="register" class="green" name="plus" />
        <FontIcon v-else name="angle-right" />
      </div>
      <div class="rignt-component" v-if="hiddenRight">
        <ToggleSwitch v-if="isSwitch" :active="switchValue" @click="$emit('switch')" />
      </div>
    </div>
    <GpBadge v-if="isBadge" :type="badgeType" outline>{{ badgeText }}</GpBadge>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import GpBadge from '@/views/components/gg-pass/GpBadge.vue';
import ToggleSwitch from '@/views/components/gg-pass/ToggleSwitch.vue';
export default {
  name: 'MenuLinkButton',
  components: { ToggleSwitch, FontIcon, GpBadge },
  props: {
    icon: { type: String, default: null },
    name: { type: String, required: true },
    arrow: Boolean,
    isPending: { type: Boolean, default: false, },
    register: { type: Boolean, default: false },
    greenBoarder: { type: Boolean, default: false, },
    hiddenRight: { type: Boolean, default: false },
    isBadge: { type: Boolean, default: false, },
    isMfaBadge: { type: Boolean, default: false, },
    badgeType: { type: String, default: null, },
    isSwitch: {type: Boolean, default: false,},
    switchValue: {type: Boolean, default: false}
  },
  computed: {
    badgeText() {
      if (!this.isBadge) return null;

      switch(this.badgeType) {
        // Responsible Gaming
        case 'REQUEST': return this.$t('responsibleGaming.requested');
        case 'APPROVAL': return this.$t('responsibleGaming.approval');
      }
    },
    mfaBadgeText() {
      if (!this.isMfaBadge) return null;

      switch(this.badgeType) {
        case 'OFF': return this.$t('myInfo.off');
        case 'LOW': return this.$t('myInfo.low');
        case 'MEDIUM': return this.$t('myInfo.medium');
        case 'HIGH': return this.$t('myInfo.high');
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-menu-link-button] {.p(16, 20, 16, 16); .c(@c-b08); font-weight: 500; .bgc(#FFF); .min-h(64); .flex(); .w(100%); .flex-dc(); .br(16); justify-content: center; cursor: pointer; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; .min-w(240);
  &.no-pointer {cursor: default}
  //&.green {.-a(@gp-green);}
  .menu-group {.flex(); .w(100%); .flex-dr(); .items-center(); .space-between(); .p(0); .m(0); .gap(10);
    > .label {.flex(); flex :1; .space-between();
      .left{.flex(); .gap(10); .items-center();.fs(14); font-weight:400; }
      > .badge {.fs(10); font-weight: normal; .bgc(#DCDCDC); .p(0, 10, 0, 10); .h(16); line-height: 16px;}
      [gp-badge] {justify-content: flex-start; .fs(12); font-weight: 400;}
    }
    .right-menu{ .flex();  .align-center();}
    //&:before { .cnt(); .wh(4, 16); .mt(2); .lt(0, 50%); .t-yc(); .bgc(@c-b08); .abs();}
    [font-icon] { .c(#737373); .fs(16);
      //&.green {.c(@gp-green)}
    }
    &.arrow { .pointer(); .-b(@c-b01); }
  }
  [gp-badge] {justify-content: flex-start;}
}
</style>