import { render, staticRenderFns } from "./DisplayCurrentTransferAccount.vue?vue&type=template&id=17a7dab8"
import script from "./DisplayCurrentTransferAccount.vue?vue&type=script&lang=js"
export * from "./DisplayCurrentTransferAccount.vue?vue&type=script&lang=js"
import style0 from "./DisplayCurrentTransferAccount.vue?vue&type=style&index=0&id=17a7dab8&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports