import _isEmpty from 'lodash/isEmpty';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import qs from 'qs';
import { getSessionStorage } from '@shared/modules/ObjectStorage';

/**
 * @typedef {import('vue-router').Route} VueRoute
 */

/**
 * 라우트 Serialize
 * @param {Route} route
 * @returns {string}
 */
export const routeString = (route = null) => (route
  ? route.path + (_isEmpty(route.query) ? '' : `?${qs.stringify(route.query)}`)
  : window.location.pathname + window.location.search);

/**
 * 매치된 컴포넌트 인스턴스로부터 asyncData 를 가진 컴포넌트 목록을 추출
 * @param {{asyncData?: Function, components: *[]}[]} matchedComponents
 * @returns {{name: string, asyncData?: function({store: Store, route: Route, sharedScope?: Object}) : Promise, mixins: []}[]}
 */
export const getHasAsyncDataComponents = matchedComponents => {
  const acc = [];
  let prev = null;
  let i = 0;
  let list = matchedComponents;
  while (i < list.length) {
    const item = list[i];
    if (item?.asyncData) acc.push(item);
    if (item?.components) {
      prev = [i + 1, list, prev];
      list = Object.keys(item.components).map(k => item.components[k]);
      i = 0;
    } else {
      i += 1;
      while (i >= list.length && prev) [i, list, prev] = prev;
    }
  }
  return acc;
};

/**
 * 매치된 컴포넌트 인스턴스로부터 asyncData 를 가진 컴포넌트 목록을 asyncDataPriority 로 정렬하여 추출
 * @param {*[]} matchedComponents
 * @returns {{name: string, asyncData: function({store: Store, route: Route, sharedScope: Object}) : Promise, mixins: []}[][]}
 */
export const getHasAsyncDataComponentsSorted = matchedComponents => _sortBy(_groupBy(getHasAsyncDataComponents(matchedComponents), (/** @type {{ asyncDataPriority?: number }} */c) => c.asyncDataPriority || 0), (_, priority) => priority).reverse();

export const getPathAppliedParam = (matchedPath, params) => matchedPath.replace(/\/:\w+[^/]+/g, m => {
  const p = params[m.match(/:(\w+)/)[1]];
  return p ? `/${p}` : '';
});

/**
 * 히스토리 복귀 동작을 위한 외부링크 이동
 * @param {string} url
 */
export const historyMove = url => {
  getSessionStorage('browser').set('lastRoute', routeString());
  window.location.assign(url);
};

export const permitAll = () => permitAll;
export const isAuthenticated = () => isAuthenticated;
export const hasRole = (...requires) => roles => requires.every(require => roles.indexOf(require) > -1);
export const hasAnyRole = (...requires) => roles => requires.some(require => roles.indexOf(require) > -1);
export const expiredEvent = () => expiredEvent;

export const accessControl = (context, store) => (to, from, next) => {
  const localePath = to.params.locale ? `/${to.params.locale}` : '';
  for (let i = 0; i < context.length; ++i) {
    const condition = context[i];
    const exp = new RegExp(`^${localePath}${condition.path.replace(/\**/g, '++').replace(/\*/g, '[^/]*').replace(/\++/g, '.*')}$`);
    if (exp.test(to.path)) { // 매칭되는 경로가 있다면
      if (condition.redirect) {
        // role 이 없다면 무조건 to 경로로 role 이 있다면 해당 조건에 부합하는 경우 redirect 가 동작
        if (!condition.redirect.role || condition.redirect.role(store.getters['auth/roles'])) {
          const error = new Error('Redirect');
          // 리디렉션을 하는 도중 해시가 유실되지 않도록,
          // 해시가 비어있지 않으면 해시는 유지
          // 이벤트 페이지 등을 권한에 관계 없이 사용해야할 경우가 있을 수 있음
          const redirectTo = [condition.redirect.to];
          if (to.query) redirectTo.push(`?${qs.stringify(to.query)}`);
          if (to.hash) redirectTo.push(to.hash);

          error.to = localePath + redirectTo.join('');
          error.code = 302;
          next(error);
          return;
        }
        continue;
      }

      if (condition.access === permitAll) { // 권한없이 접근 가능한 경로면
        next(); // 통과
        return;
      }

      if (condition.access === expiredEvent) { // 만료된 이벤트일 경우
        const error = new Error('TemporaryRedirect');
        error.code = 307;
        next(error);
        return;
      }

      if (!store.getters['auth/isLogin']) { // 로그인 되어있지 않으면
        const error = new Error('Unauthorized');
        error.to = to.path;
        error.code = 401;
        next(error); // 인증필요 (로그인 페이지로 이동)
        return;
      }

      if (condition.access === isAuthenticated) { // 로그인 상태만 요구되면
        next(); // 통과
        return;
      }

      if (condition.access(store.getters['auth/roles'])) { // 권한에 부합하면
        next(); // 통과
        return;
      }

      const error = new Error('Forbidden');
      error.code = 403;
      next(error); // 매칭되었지만 권한이 부합되지 않았다면 권한없음
      return;
    }
  }
  next(); // 어떤 제한도 없었다면 이동
};

/**
 * @typedef {{ [key:string ]: string }} HashObject
 */

/**
 * @param {VueRoute} route
 * @returns {HashObject | *}
 */
const getHashObj = route => ((!route?.hash) ? {} : qs.parse(route.hash.substr(1)));

/**
 * @description
 * 라우터의 해시 문자열을 가져와서 key-value로 변경한 뒤 필요한 값만 가져오는 기능
 * @param {VueRoute} route
 * @param {string | string[]}key
 * @returns {string}
 */
export const getHashValueFromRoute = (route, key) => {
  // hash가 들어올 경우 hashbang(#)이 같이 들어오기 때문에 제거해주고 분석
  const values = getHashObj(route);
  return values[key] ?? '';
};

/**
 * @description
 * key-value로 해시 값을 사용할 수 있도록 도와준다
 * @param {HashObject} hashObj
 * @return {string}
 * @example
 * this.$router.push({ hash: makeHashValues({ 'my-key': '1234' }) });
 */
export const makeHashValues = hashObj => `#${qs.stringify(hashObj)}`;

/**
 * @description
 * @param {VueRoute} route
 * @param {HashObject} hashObj
 */
export const mergeHashValues = (route, hashObj) => makeHashValues({ ...getHashObj(route), ...hashObj });

/**
 * @description
 * @param {VueRoute} route
 * @param {string[]} keys
 */
export const removeHashValues = (route, keys) => {
  const hashObj = getHashObj(route);
  keys.forEach(k => delete hashObj?.[k]);
  return hashObj;
};
