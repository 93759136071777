<template>
  <div address-cz>
    <div class="field-holder">
      <ValidationComponent :label="$t('_.streetName')" name="_streetName" :component="TrimTextInput" :rules="streetNameRules" :preset="streetNamePreset" v-model="modelInfo.StreetName" :error-msg="streetErrorMsg" />
      <ValidationComponent :label="$t('_.streetNumber')" name="_streetNumber" :component="TrimTextInput" :rules="streetNumberRules" :preset="streetNumberPreset" v-model="modelInfo.StreetNumber" />
    </div>
    <CzechCountrySelector permanent :model-info="modelInfo"/>
    <ValidationComponent :label="$t('_.postalCode')" name="_postalCode" :component="TrimTextInput" :rules="postalCodeRules" :preset="postalCodePreset" v-model="modelInfo.PostalCode" :error-msg="postalCodeErrorMsg" />
    <ProgressButton button-name="next" @click="confirm" :disabled="!!structure.limitTime || structure.error || invalid"/>
  </div>
</template>

<script>
import { getObjectIndexByProperty } from '@/utils/valueUtil';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import CzechCountrySelector from '@/views/components/pages/on-boarding/template/CzechCountrySelector.vue'
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Specific from '@shared/types/Specific';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'AddressCz',
  lexicon: 'personalDetails',
  mixins: [baseTemplate],
  components: { ProgressButton, ValidationComponent, CzechCountrySelector},
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      streetNamePreset: Specific,
      streetNumberPreset: Specific,
      postalCodePreset: Specific,
      streetNameRules: Specific,
      streetNumberRules: Specific,
      postalCodeRules: Specific,
      districtErrorMsg: null,
      streetErrorMsg: null,
      houseNumberErrorMsg: null,
      addressErrorMsg: null,
      stateErrorMsg: null,
      buildingErrorMsg: null,
      cityErrorMsg: null,
      postalCodeErrorMsg: null,
      TrimTextInput,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    step() {
      return this.structure.step;
    },
    lastStep() {
      return this.step >= this.structure.list.length - 1;
    },
    currentItem() {
      return this.structure?.list[this.step];
    },
  },
  methods: {
    /**
     * 결과 노출 시 초기 설정
     * @returns {Promise<void>}
     */
    initialize() {
      this.initRules();
      this.initPreset();
      this.initErrorMsg();
    },
    /**
     * ModelInfo 유무에 따른 Rules 설정
     */
    initRules() {
      this.streetNameRules = `requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`;
      this.streetNumberRules = `requiredAddress:0,20,${this.modelInfo.CountryWritingSystem}`;
      this.postalCodeRules = `requiredNumber:0,5`;
    },
    /**
     * ModelInfo 유무에 따른 Preset 설정
     */
    initPreset() {
      this.streetNamePreset = {maxLength: 50};
      this.streetNumberPreset = {maxLength: 20};
      this.postalCodePreset = {digit: true, maxLength: 5};
    },
    /**
     * 에러 메세지 초기화
     */
    initErrorMsg() {
      this.districtErrorMsg = null;
      this.streetErrorMsg = null;
      this.houseNumberErrorMsg = null;
      this.addressErrorMsg = null;
      this.stateErrorMsg = null;
      this.buildingErrorMsg = null;
      this.cityErrorMsg = null;
      this.postalCodeErrorMsg = null;
    },
    /**
     * 입력 정보 저장
     * @returns {Promise<void>}
     */
    async confirm() {
      await this.apiRequest(this.currentItem?.api, this.structure.model);
      if (!this.invalid && !this.structure.error) this.modelInfo.step = 1;
    },

    /**
     * 컴포넌트 내에서의 api request 처리
     * @param {Specific} params - 호출 파라미터
     * @param {string} api - api 함수 명
     * @param {object} params - params
     * @param {object} config - config
     * @returns {Promise<*|null>}
     */
    async apiRequest(api, params, config = null) {
      const r = await this.getApiResponse(api, params, config);
      if (r?.error) {
        if(!(await this.apiErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.${api}`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }

        return;
      }

      return r;
    },
    /**
     * api 결과 반환 처리
     * @param {string} api - api 호출 함수 명
     * @param {object} params - 호출 함수 전달 정보
     * @param {object} config - 설정
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse(api, params, config = null) {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding[api]({ ...params }, { ...config }); }
      catch (e) { return e; }
    },
    /**
     * api Error Handler
     * @param {object} r - error 정보
     * @returns {Promise<boolean>}
     */
    async apiErrorHandler(r) {
      const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
      const i = getObjectIndexByProperty(apiErrorCode, key);
      /*
      * INVALID_STREET_NAME
        INVALID_STREET_NUMBER
        INVALID_HOUSE_NUMBER
        MISSING_EXTRA_ADDRESS
        INVALID_BARANGAY
        INVALID_ADDRESS
        INVALID_ADDRESS_LENGTH
        INVALID_ADDRESS_CHARACTER
        INVALID_STATE
        INVALID_BUILDING
        INVALID_CITY
        INVALID_CITY_LENGTH
        INVALID_CITY_CHARACTER
        INVALID_POSTAL_CODE
        INVALID_POSTAL_CODE_LENGTH
        INVALID_POSTAL_CODE_CHARACTER
      */
      switch (key) {
        case apiErrorCode.USER_INFO_ALREADY_EXIST:
        case apiErrorCode.FAILED_SELF_EXCLUSION_CHECK:
        case apiErrorCode.REJECT_FROM_BGC:
          this.replaceRouteName('VerificationFailed', {desc, errorTemplate}, { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined });
          break;
        case apiErrorCode.INVALID_STREET_NAME:
        case apiErrorCode.INVALID_STREET_NUMBER:
          this.streetErrorMsg = this.$t(key);
          break;
        case apiErrorCode.INVALID_BARANGAY:
          this.districtErrorMsg = this.$t(key);
          break;
        case apiErrorCode.INVALID_HOUSE_NUMBER:
          this.houseNumberErrorMsg = this.$t(key, {fieldName: this.$t('houseNumber')});
          break;
        case apiErrorCode.INVALID_ADDRESS:
        case apiErrorCode.INVALID_ADDRESS_LENGTH:
        case apiErrorCode.INVALID_ADDRESS_CHARACTER:
          this.addressErrorMsg = this.$t(key, {fieldName: this.$t('address'), length: this.addressPreset.maxLength});
          break;
        case apiErrorCode.INVALID_STATE:
          this.stateErrorMsg = this.$t(key, {fieldName: this.$t('stateProvince')});
          break;
        case apiErrorCode.INVALID_CITY:
        case apiErrorCode.INVALID_CITY_LENGTH:
        case apiErrorCode.INVALID_CITY_CHARACTER:
          this.cityErrorMsg = this.$t(key, {fieldName: this.$t('city'), length: this.cityPreset.maxLength});
          break;
        case apiErrorCode.INVALID_POSTAL_CODE:
        case apiErrorCode.INVALID_POSTAL_CODE_LENGTH:
        case apiErrorCode.INVALID_POSTAL_CODE_CHARACTER:
          // TODO : PH는 length 4, CZ는 length 5
          this.postalCodeErrorMsg = this.$t(key, {fieldName: this.$t('postalCode'), length: this.postalCodePreset.maxLength});
          break;
      }

      return true;
    },
  },
  created() {
    if (this.item) this.item.title = 'onBoarding.title.permanentAddress';
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[address-cz] {
  > :not(:nth-of-type(1)) { .mt(8); }
  .field-holder { .flex(); .gap(8);
    >:nth-of-type(1) { .flex-grow(2); }
  }
}
</style>
