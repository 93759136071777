<template>
  <div cnp>
    <ValidationComponent name="_cnp" v-model="modelInfo.NationalId" :component="TextInput" :label="$t('personalDetails.cnp')" :preset="{maxLength: 13, digit: true}" :error-msg="error" rules="required"/>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import { getCnpInfo } from '@/utils/userUtil';
import baseTemplate from '@/mixins/baseTemplate';
import { makeRules } from '@/plugins/validate';
import { getMMDDTHHmmssZ, getUtcTime, getYYYYMMDDTHHmmssZ, utc } from '@shared/utils/timeUtils.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'Cnp',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  data() {
    return {
      TextInput,
      error: null,
    };
  },
  computed: {
    nationalId() {
      return this.modelInfo.NationalId;
    }
  },
  watch: {
    errorMsg() {
      if(this.errorMsg === apiErrorCode.INVALID_NATIONAL_ID) this.error = this.$t('validation.rules.requiredCnp')
    },
    nationalId() {
      const v = this.nationalId;
      const { gender, date, age, foreign } = getCnpInfo(v);
      if(!makeRules().requiredCnp.validate(v)) {
        this.structure.error = true;
        this.error = this.$t('validation.rules.requiredCnp');
        return;
      } else if (+age < 18 || (+age === 18 && getMMDDTHHmmssZ() < getMMDDTHHmmssZ(date))) {
        this.structure.error = true;
        this.error = this.$t('validation.rules.confirmAge', {age: '18'});
        return;
      }

      this.error = null;
      if(v?.length !== 13) return;
      this.modelInfo.Gender = gender;
      this.modelInfo.DateOfBirth = date;
    }

  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[cnp] {}
</style>