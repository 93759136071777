<template>
  <div verify-mga />
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'VerifyMga',
  mixins: [baseTemplate],
  data() {
    return {
      error: false,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  methods: {
    /**
     * 컴포넌트 내에서의 api request 처리
     * @returns {Promise<*|null>}
     */
    async apiRequest() {
      const r = await this.getApiResponse();
      if (r.error) {
        if(!(await this.apiErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.verifyAge`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }
      }
    },
    /**
     *
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding.verifyAge(); }
      catch (e) { return e; }
    },
    async apiErrorHandler(r) {
      // const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
      // if (key === apiErrorCode.AISG_FAILED_SELF_EXCLUSION_CHECK) this.replaceRouteName('VerificationFailed', {desc, errorTemplate}, { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined });
      // else if (key === apiErrorCode.USER_INFO_NOT_VERIFIED_AND_PASS) return false;
      // else return true;
      switch (r?.key) {
        case apiErrorCode.USER_INFO_NOT_VERIFIED:
          break;
        case apiErrorCode.USER_INFO_NOT_VERIFIED_AND_RETRY:
          break;
        case apiErrorCode.GAMSTOP_SELF_EXCLUDED:
        case apiErrorCode.ACCOUNT_SUSPENDED:
        case apiErrorCode.OASIS_BAN_EXIST:
        case apiErrorCode.FAILED_SELF_EXCLUSION_CHECK:
        case apiErrorCode.AISG_FAILED_SELF_EXCLUSION_CHECK:
        case apiErrorCode.PEP_WATCH_MATCHED:
          this.$onboarding.errorHandler(r, true);
          break;
        case apiErrorCode.KYC_UPLOAD_REQUIRED:
          break;
        case apiErrorCode.INTERNAL_ERROR:
          break;
        case apiErrorCode.USER_INFO_NOT_VERIFIED_AND_PASS:
        default:
          return false;
      }

      return true;
    },
  },
  async mounted() {
    await this.apiRequest();
    if (!this.structure.error) this.modelInfo.replace = true;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-mga] {}
</style>
