<template>
  <SearchDropSelect gp-country-number-drop-select :class="{'disabled':disabled}" v-model="model" @input="selected" :placeholder="placeholder" :innerWidth="innerWidth" :template="getView" size="sm" :list="list" :isFromLanding="isFromLanding" :parent-fill="parentNode" @focus="$emit('focus')" @blur="$emit('blur')" :disabled="disabled" />
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import Specific from '@shared/types/Specific';
import { routeNames } from '@/constants/model/my-page';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';

export default {
  name: 'CountryNumberDropDown',
  components: { SearchDropSelect },
  props: {
    value: Specific,
    theme: { type: String, default: '' },
    isFromLanding: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    innerWidth: { type: Number, default: null, }
  },
  data() {
    return {
      // list: null,
      originList: null,
      model: null,
      userCountryInfo: null,
      parentNode: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    blockCountries: state('env', 'blockCountries'),
    isContactInfo() {
      return this.$route.name === routeNames.NpContactMobileNumber;
    },
    isGpApi() {
      return !isExcludeGgpass(this.site) && this.isContactInfo === false;
    },
    list() {
      if (!this.originList) return [];
      return this.originList.map(v => ({ ...v, CountryName: this.$t(`country.${v.value}`) }));
    },
  },
  watch: {
    value: 'updateModel',
  },
  methods: {
    getView(item, selected) {
      return !selected ? `<em class="space-between"><span>${this.$t(`country.${item.CountryCode}`)}</span> <span>+${item.PhoneCountryCode}</span></em>` : `<em>${item.label}</em>`;
    },
    selected(v) {
      if(!v) return;
      this.$emit('input', v);
    },
    updateModel() {
      if (this.value) this.model = this.value;
    },
    async getList() {
      if (this.isGpApi) {
        this.originList = (await this.$services.ggpass.getConfig()).allowCountryConfigs.map(o => {
            const mobileCountry = o.countryCode.replace('+', '');
            return { label: `${o.countryId} ${o.countryCode}`, value: o.countryId, ...o, CountryCode: o.countryId, PhoneCountryCode: mobileCountry, CountryName: this.$t(`country.${o.countryId}`) };
          }
        );
      } else {
        this.originList = (await this.$services.base.getMobileCountries()).List.map(o => ({ label: `${o.CountryCode} +${o.PhoneCountryCode}`, value: o.CountryCode, ...o }));
      }
    }
  },
  async mounted() {
    await this.getList();
    this.updateModel();
    this.parentNode = this.$el?.parentNode;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-country-number-drop-select] {.rel(); .bgc(#FFF); .br(8);
  > a { .br(8); .c(#333); .bgc(#1e1e1e); box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .block(); .fs(14); .flex(); .items-center(); .h(64); .p(6, 20, 6, 12); .-box(); .w(100%); .rel();
    &.placeholder {.c(#a1a1aa);}
    > label { .medium(); font-weight: 400;
      > em { .fs(14); }
      img { .mb(4); }
    }
    [font-icon] { .ib(); .fs(16); .abs(); .rt(10, 45%); .t-yc(); .c(#333);
      &.on { .t-r(180deg); }
    }
  }
  //.list-container { box-shadow: 2px 0 3px rgba(0, 0, 0, 0.5); .bgc(white); .br(8); .br-t(0); .abs(); .o(0); .crop(); .w(100%); .max-h(500); .h(0);
  .list-container { .bgc(white); .br(8); .br-t(0); .abs(); .o(0); .crop(); .w(100%); .max-h(500); .h(0);box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1); .br(8);
    //&.fill { .w(var(--innerWidth)); }
    &.fill { .w(100%); }
    &.on { .o(1); .z(10); }
    .list-inner { .pb(6); .pt(3); .hf();
      .input-holder { .p(5, 12, 10, 12); .bgc(#fff);}
      [text-input] {.bgc(#F7F7F8); .w(100%); box-shadow: none; border: none; .h(40);
        > input { .c(black); .fs(14); .pb(0);}
        > .input-btn-x {.bgc(#fff);
          > i {.c(black);}
        }
      }
      .scroll-holder { overflow-y: auto; .h(calc(100% - 50px));
        label { .block(); .p(10, 16); .w(100%); .c(black); .tl(); }
        button { .flex(); .p(10, 16); .w(100%); .c(black); .tl();
          &:hover, &:focus, &.focus { .bgc(#F3FCF5); }
          &.active { .bgc(#E6F3E8); }
          em { .fs(14); .w(100%); }
          .icn-chevron-right { margin: 0 0 0 auto; }
        }
      }
      .empty { .fs(14); .p(10, 12); .c(black);}
    }
  }
  &.md {
    .list-container {
      .list-inner {
        .scroll-holder { .h(100%); }
      }
    }
  }
  &.sm, &.md {
    > a { .bgc(transparent) !important; box-shadow: none; .-a(); .p(0, 12); .h(40); .br(4);
      > [text-input] {.-a(); box-shadow: none; .w(100%);
        input { .pl(0); }
      }
    }
    .list-container {
      .list-inner { .fs(14);
      }
    }
  }
  [text-input] {
    input { .fs(14, 20);
    }
  }
  .placeholder { .c(@gp-placeholder-color); .regular(); }
  &.disabled {.bgc(@gp-disabled-bgc);
    > a {.c(#a1a1aa);}
  }

  &.single { .h(48);
    [search-drop-select] {
      > a { .h(48); .br(6); box-shadow: 0 1px 0 0 rgba(200, 200, 200, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .bgc(rgba(255, 255, 255, 0.07)) !important;;
        > label { .lh(50); .h(100%);}
      }
    }
  }
  .img-country { .w(18); .ib(); .mr(8);}
  .link { .abs(); .rb(0, -28); .c(@c-red); .semi-bold();
    a { .regular(); .-b(@c-red);}
  }

}
</style>