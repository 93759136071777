<template>
  <ValidationObserver tag="div" ref="validator" gp-template-form v-slot="{ invalid, handleSubmit, errors }">
    <div v-if="hasHeader" :class="{'template-header' :hasHeader}">
      <slot name="header" />
    </div>
    <div class="template-content" :invalid="invalid">
      <slot name="content" :errors="errors" />
    </div>
    <div class="template-footer">
      <slot name="footer" :validate="checkValidate" :invalid="invalid" :handleSubmit="handleSubmit" />
    </div>
  </ValidationObserver>
</template>

<script>
import { focusSelector } from '@shared/utils/commonUtils.mjs';
import SubHeader from '@/views/components/gg-pass/SubHeader.vue';

export default {
  name: 'GpFormTemplate',
  components: { SubHeader },
  props: {
    title: { type: String, default: '' }
  },
  computed: {
    hasHeader() {
      return this.$slots.header !== undefined;
    }
  },
  methods: {
    async checkValidate(callback) {

      // Toast Message Hidden
      return this.$refs.validator.validate();
      // return await this.$validate(this.$refs.validator), focusSelector('.invalid input');
    },
  },
  beforeMount() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-template-form] {flex: 1; .flex();.flex-dc();.ph(20); .w(100%);
  > .template-content { flex: 1;}
  > .template-footer { .pt(20); .gap(5); }
  > .template-header {.pt(32); .pb(40);
    > [gp-description] {.p(0);}
  }
}
</style>