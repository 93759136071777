<template>
  <div input-automatic-reserve-win>
    <div class="label">{{ $t('_.automaticReserveSetting') }}</div>
    <div class="input-group">
      <RadioList type="vertical" :custom-radio="ResponsibleRadioItem" :list="itemList" v-model="group" />
      <RgLimitInput v-if="isReserve" class="limit-input" name="_amount" rules="rgMin:1" v-model="model.RequestReserveWinLimit" :preset="inputPreset" :currency-symbol="currencySymbol" />
    </div>

  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import ResponsibleRadioItem from '@/views/components/pages/responsible-gaming/template/common/ResponsibleRadioItem.vue';
import RadioList from '@/views/components/gg-pass/RadioList.vue';
import RgLimitInput from '@/views/components/pages/responsible-gaming/template/common/RgLimitInput.vue';

export default {
  name: 'InputAutomaticReserveWin',
  lexicon: 'responsibleGaming.label',
  components: { RgLimitInput, RadioList },
  props: {
    model: Specific,
    current: Specific,
    currencySymbol: { type: String, default: '$' }
  },
  data() {
    return {
      group: null,
      ResponsibleRadioItem
    };
  },
  computed: {
    isReserve() {
      return this.group === 'Reserve';
    },
    itemList() {
      return [
        { value: 'None', label: this.$t('_.notInUse'), },
        // { value: 'Reserve', label: this.$t('_.reserveOver'), preset: this.inputPreset, renderingComponent: TextInputBox },
        { value: 'Reserve', label: this.$t('_.reserveOver') },
      ];
    },
    inputPreset() {
      return { placeholder: this.$t('amount'), textAlign: 'right', currencySymbol: this.currencySymbol || '$', maxLength: 9, type: 'digit', rules: 'rgMin:1' };
    }
  },
  mounted() {
    if (this.model.RequestReserveWinLimit !== null) this.group = 'Reserve';
    else this.group = this.current.CurrentReserveWinLimit;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-automatic-reserve-win] {.flex(); .flex-dc(); .gap(5);
  .label {.c(black); font-weight: 600; .fs(16);}
  > [gp-radio-group] {.bgc(#EBEBEF); .br(16); .p(20); }
  .input-group {.bgc(#EBEBEF); .br(16); .p(20);
    .limit-input {.pt(10); .pl(25);}
  }
}
</style>