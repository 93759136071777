<template>
  <div input-login-time-limit>
    <TimeLimitRow :mode="mode" :label="monthlyLabel" :current-value="current.CurrentMonthlyLoginTimeLimit" current-name="CurrentMonthlyLoginTimeLimit" :rules="monthlyRules" :list="monthlyList" v-model="model.RequestMonthlyLoginTimeLimit" new-name="monthlyLimit" :preset="monthlyPreset" />
    <TimeLimitRow :mode="mode" :label="weeklyLabel" :current-value="current.CurrentWeeklyLoginTimeLimit" current-name="CurrentWeeklyLoginTimeLimit" :rules="weeklyRules" :list="weeklyList" v-model="model.RequestWeeklyLoginTimeLimit" new-name="weeklyLimit" :preset="weeklyPreset" />
    <TimeLimitRow :mode="mode" :label="dailyLabel" :current-value="current.CurrentDailyLoginTimeLimit" current-name="currentDailyLimit" :rules="dailyRules" :list="dailyList" v-model="model.RequestDailyLoginTimeLimit" new-name="dailyLimit" :preset="dailyPreset" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import TimeLimitRow from '@/views/components/pages/responsible-gaming/template/common/TimeLimitRow.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import LimitHourField from '@/views/components/pages/on-boarding/template/extra/LimitHourField.vue';

export default {
  name: 'InputLoginTimeLimit',
  lexicon: 'onBoarding.setting',
  components: { TimeLimitRow },
  props: {
    mode: {type: String, default: 'aggressive'},
    model: Specific,
    current: Specific,
    rolling: { type: String, default: false },
    currencySymbol: { type: String, default: null }
  },
  data() {
    return {
      loginTimeType: null,
      monthlyMinMax: { min: 1, max: 720 },
      weeklyMinMax: { min: 1, max: 168 },
      dailyMinMax: { min: 1, max: 24 },
      notNullType: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    isRolling() {
      return this.rolling === 'Rolling';
    },
    monthlyLabel() {
      return this.isRolling ? this.$t('_.30Days') : this.$t('monthly');
    },
    weeklyLabel() {
      return this.isRolling ? this.$t('_.7Days') : this.$t('weekly');
    },
    dailyLabel() {
      return this.isRolling ? this.$t('_.24hours') : this.$t('daily');
    },
    timeList() {
      return {
        monthly: [
          { label: 'custom', value: 'custom' },
          { label: '120', value: '120' },
          { label: '240', value: '240' },
          { label: '360', value: '360' },
          { label: '720', value: '720' },
        ],
        weekly: [
          { label: 'custom', value: 'custom' },
          { label: '24', value: '24' },
          { label: '72', value: '72' },
          { label: '120', value: '120' },
          { label: '168', value: '168' },
        ],
        daily: [
          { label: 'custom', value: 'custom' },
          { label: '24', value: '24' },
          { label: '12', value: '12' },
          { label: '6', value: '6' },
          { label: '3', value: '3' },
        ],
      };
    },
    monthlyList() {
      return this.timeList.monthly.map(item => ({ label: item.label === 'custom' ? this.$t(item.label) : `${item.label} ${this.$t('responsibleGaming.label.hr')}`, value: item.value }));
    },
    weeklyList() {
      return this.timeList.weekly.map(item => ({ label: item.label === 'custom' ? this.$t(item.label) : `${item.label} ${this.$t('responsibleGaming.label.hr')}`, value: item.value }));
    },
    dailyList() {
      return this.timeList.daily.map(item => ({ label: item.label === 'custom' ? this.$t(item.label) : `${item.label} ${this.$t('responsibleGaming.label.hr')}`, value: item.value }));
    },

    monthlyPreset() {
      return {
        type: 'digit',
        required: true,
        placeholder: this.isGGPNL ? this.$t('responsibleGaming.placeHolder.onlyNumber') : `${this.monthlyMinMax.min}~${this.monthlyMinMax.max}`,
        maxLength: 3,
        digit: true,
        inputMode: 'numeric',
        pattern: '[0-9]*'
      };
    },
    weeklyPreset() {
      return {
        type: 'digit',
        placeholder: this.isGGPNL ? this.$t('responsibleGaming.placeHolder.onlyNumber') : `${this.weeklyMinMax.min}~${this.weeklyMinMax.max}`,
        maxLength: 3,
        digit: true,
        inputMode: 'numeric',
        pattern: '[0-9]*'
      };
    },
    dailyPreset() {
      return {
        type: 'digit',
        placeholder: this.isGGPNL ? this.$t('responsibleGaming.placeHolder.onlyNumber') : `${this.dailyMinMax.min}~${this.dailyMinMax.max}`,
        maxLength: 2,
        digit: true,
        inputMode: 'numeric',
        pattern: '[0-9]*'
      };
    },
    monthlyRules() {
      if (this.isGGPNL) return this.getRule('monthly');
      return `limitMinMax:${this.monthlyMinMax.min},${this.monthlyMinMax.max}`;
    },
    weeklyRules() {
      if (this.isGGPNL) return this.getRule('weekly');
      return `limitMinMax:${this.weeklyMinMax.min},${this.weeklyMinMax.max}`;
    },
    dailyRules() {
      if (this.isGGPNL) return this.getRule('daily');
      return `limitMinMax:${this.dailyMinMax.min},${this.dailyMinMax.max}`;
    },
  },
  methods: {
    getRule(type) {
      const { RequestMonthlyLoginTimeLimit: monthlyLimit, RequestWeeklyLoginTimeLimit: weeklyLimit, RequestDailyLoginTimeLimit: dailyLimit } = this.model;
      const isRequired = !monthlyLimit && !weeklyLimit && !dailyLimit;
      const limit = this[`${type}MinMax`];

      if (this.isGGPNL) {
        const nlRule = `minimumLoginTimeLimit:${limit.min}|maximumLoginTimeLimit:${limit.min},${limit.max}`;
        // const getNLExceedRule = () => {
        //   switch (type) {
        //     case 'weekly':
        //       return `|exceedMonthlyLimit:${monthlyLimit || this.current.CurrentMonthlyLoginTimeLimit || 0}`;
        //     case 'daily':
        //       return `|exceedWeeklyLimit:${weeklyLimit || this.current.CurrentWeeklyLoginTimeLimit}`;
        //     case 'monthly':
        //       return `|rgMoreLimitComparison:${weeklyLimit ||  this.current.CurrentWeeklyLoginTimeLimit },${monthlyLimit || this.current.CurrentMonthlyLoginTimeLimit },${this.$t('monthly')},${this.$t('weekly')}`
        //     default:
        //       return '';
        //   }
        // };
        // const nlExceedRule = getNLExceedRule();
        return nlRule
      }
      return (isRequired ? 'required|' : '') + `loginTimeMinMax:${limit.min},${limit.max}`;
    },
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-login-time-limit] {.flex(); .flex-dc(); .gap(30);}
</style>