<template>
  <ColorButton my-info-button :type="type" :class="[hoverType, disabledType,size, {'hover-disable disabled': disabled || progress}]" :disabled="disabled || progress" @click.stop="$emit('click',$event)">
    <span><slot /></span>
    <i v-if="progress" class="sync">
      <FontIcon name="rotate-arrows" />
    </i>
  </ColorButton>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgIcon from '@shared/components/common/SvgIcon.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'GpButton',
  components: { FontIcon, SvgIcon, ColorButton },
  props: {
    type: { type: String, default: 'light-red' },
    hoverStyle: { type: String, default: 'dark' },
    disabled: { type: Boolean, default: false },
    disabledStyle: { type: String, },
    size: { type: String, default: 'md' },
    progress: { type: Boolean, default: false },
  },
  computed: {
    hoverType() {
      const style = this.hoverStyle;
      return style ? `hover-${style}` : '';
    },
    disabledType() {
      const style = this.disabledStyle;
      return style ? `disabled-${style}` : '';
    },
  },
};
</script>

<style lang="less" scoped>
@import '~@/less/proj.less';

//
[my-info-button] { .w(100%); .br(0); .min-h(40px); .br(8); .rel();
  .sync { .wh(24); .fill(white); .abs(); .rt(16, 50%); .mt(-12); .flex(); .justify-center(); .items-center(); animation: rolling 1s infinite; animation-timing-function: linear;
    [font-icon] {.fs(16);}
  }
  &.sm { .w(80); .min-h(30); font-weight: 400; .fs(12); }
  &.submit-green { .bgc(@gp-green); .c(#FFFFFF);
    &:hover {.bgc(#2EB843);}
    @{hover-press} {.bgc(#346D50); }
    &.disabled { .bgc(#CCC); }
  }
  &.cancel-gray { .bgc(#8A8E90); .c(#FFFFFF);
    @{hover-press} {.bgc(#6C7072); }
  }
  &.line-green {.-a(@gp-green); .c(@gp-green); .bgc(#fff);
    &.disabled { .-a(#ccc); .c(#ccc) }
    &:hover {.-a(#2EB843);.c(#2EB843)}
    @{hover-press} {.-a(#346D50); .c(#346D50) }
  }
  &.disable-gray {.bgc(#4a555a); .c(#FFFFFF);
    &.disabled { .bgc(#CCC); }
  }
  &.hover-disable { pointer-events: none; }
}
;
</style>