<template>
  <GpFormTemplate v-if="isLoad" fund-password>
    <template #header>
      <GpDescription v-if="showInputComponent" :message="$t('_.fundPassword.noFundPasswordDesc')" />
    </template>
    <template #content>
      <InputFundPassword v-if="showInputComponent" :model="model" :is-enable-fund-password="isEnableFundPassword" :mode="mode" :error="error" />
      <div v-if="isEnableFundPassword && mode==='fund'" class="desc-group">
        <div class="desc-bgc">
          <FontIcon name="lock-regular" />
        </div>
        <p>{{ $t('myInfo.fundPasswordEnabled') }}</p>
      </div>
    </template>
    <template #footer="{validate, invalid}">
      <div v-if="isEnableFundPassword && mode==='fund'" class="enable-fund-password">
        <GpButton type="line-green" @click="changeMode(fundMode.DISABLE)">{{ $t('_.disable') }}</GpButton>
        <GpButton type="submit-green" @click="changeMode(fundMode.CHANGE)">{{ $t('_.change') }}</GpButton>
      </div>
      <div v-if="showButton" class="enable-fund-password">
        <GpButton type="submit-green" @click="submit(validate)" :disabled="!!invalid || isApiError">{{ $t('_.enable') }}</GpButton>
      </div>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import InputFundPassword from '@/views/components/pages/my-page/template/inputs/InputFundPassword.vue';
import { FUND_MODE } from '@/constants/base/my-page';
import npErrorHandler from '@/mixins/my-info/npErrorHandler';
import FontIcon from '@shared/components/common/FontIcon.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import npStepInfo from '@/mixins/my-info/npStepInfo';
import Specific from '@shared/types/Specific';

export default {
  name: 'NpFundPassword',
  lexicon: 'myInfo',
  components: { InputFundPassword, FontIcon, GpButton, GpFormTemplate, GpDescription },
  mixins: [npErrorHandler, npStepInfo],
  props: {
    model: Specific,
    structure: { type: Specific, default: null },
  },
  data() {
    return {
      mode: 'fund',
      isLoad: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    STEP() {
      return this.stepInfo;
    },
    showButton() {
      return !(this.isEnableFundPassword && this.isDefaultStep);

    },
    showInputComponent() {
      return !(this.isEnableFundPassword && this.isDefaultStep);
    },
    fundMode() { return FUND_MODE;},
    isFundMode() {return this.mode === FUND_MODE.FUND;},
    isChangeMode() {return this.mode === FUND_MODE.CHANGE;},
    isDisableMode() {return this.mode === FUND_MODE.DISABLE;},
    isEnableFundPassword() {return this.model.IsEnableFundPassword;},
    successMessageModel() {
      return {
        [FUND_MODE.FUND]: 'myInfo.fundPasswordEnabled',
        [FUND_MODE.CHANGE]: 'myInfo.fundPassword.changeSuccess',
        [FUND_MODE.DISABLE]: 'myInfo.fundPassword.disabledSuccess',
      };
    }
  },
  watch: {
    step: 'updateStep',
  },
  methods: {
    async initialize() {
      const { IsEnableFundPassword } = await this.$myinfo.fetch({payload : null, block : true});
      this.model.IsEnableFundPassword = IsEnableFundPassword;
      this.isLoad = true;
    },

    updateStep() {
      if (this.isDefaultStep) this.mode = FUND_MODE.FUND;

      this.model.CurrentFundPassword = null;
      this.model.FundPassword = null;
    },

    changeMode(mode) {
      this.mode = mode;
      this.$emit('changeStep', this.STEP.INPUT);
    },

    createSubmitEnableFundPassword() {
      let enableFundPassword = false;

      if (this.isFundMode) enableFundPassword = true;
      if (this.isChangeMode) enableFundPassword = true;
      if (this.isDisableMode) enableFundPassword = false;

      return {
        ...this.model,
        IsEnableFundPassword: enableFundPassword
      };
    },

    async submit(validate) {
      if (validate) await validate();
      const submitData = this.createSubmitEnableFundPassword();
      const result = await this.$myinfo.request(submitData);

      if (result.error) {
        this.apiErrorHandler(result);
        return;
      }
      let successMessage = this.successMessageModel[this.mode];

      this.$emit('success', successMessage);
    },

  },
  async mounted() {
    this.structure.title = this.$myinfo.title;
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fund-password] {
  .enable-icon {.c(#68AD6F); .fs(18); }
  .password-input-group { .bgc(#f8f8f8);.p(20, 15, 20, 15); .br(4); .flex(); .flex-dc(); .gap(15); .w(100%);}
  .footer {.flex(); .flex-dc(); .gap(10)}
  .enable-fund-password { .bgc(#f8f8f8); .flex(); .flex;.gap(10); .items-center(); .p(20, 10, 20, 10);
    > h1 {.mb(30);}
  }
  .desc-group { .flex();.flex-dc();.justify-center(); .items-center();.gap(20); .pt(100);
    .desc-bgc {.bgc(@gp-green); .wh(36); .br(100); .flex(); .items-center(); .justify-center();
      [font-icon] {.fs(16); .c(#fff)}
    }

    > p { .c(#1B1B1B); .fs(16) }
  }

}

</style>


