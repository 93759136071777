<template>
  <div v-if="currentItem" transfer-dropdown>
    <LinkAmountItem :disable="disabled" :label="currentItem.SiteName" :amount="currentItem.DisplayAmount" :country-name="currentItem.CountryCode" @click="click" :active="toggle" :show-icon="!current" :logo-url="currentItem.LogoUrl" :value="currentItem.AccountId" />
    <div v-if="toggle" class="list-container">
      <div class="list-wrapper">
        <LinkAmountItem v-for="(item, index) in itemList" :is-drop-down="true" :is-radio="true" :disable="item.Disable" :active="currentValue === item.AccountId" :current-value="currentValue" :show-icon="index===0" :label="item.SiteName" :amount="item.DisplayAmount" :country-name="item.CountryCode" :logo-url="item.LogoUrl" :key="item.AccountId" :tool-tip-message="item.ToolTipMessage" :toggle="toggle" :value="item.AccountId" @click="itemClick" />
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@shared/components/common/SvgIcon.vue';
import LinkAmountItem from '@/views/components/pages/my-page/template/link-account/LinkAmountItem.vue';
import Specific from '@shared/types/Specific';
import { getDisplayAmount } from '@/utils/amountUtil';

export default {
  name: 'TransferDropDown',
  components: { LinkAmountItem, SvgIcon },
  props: {
    disabled: { type: Boolean, default: false, },
    current: { type: Boolean, default: false, },
    isFrom: { type: Boolean, default: false, },
    info: Specific,
    selected: { type: String, default: null, }
  },
  data() {
    return {
      value: '',
      toggle: false,
      currentValue: null,
      currentItem: null,
      list: null,
      itemList: []
    };
  },
  computed: {
    isActive(value) {
      return this.currentValue === value;
    },
    currentList() {
      let list = null;
      if ((this.isFrom && this.current) || (!this.isFrom && this.current)) {
        const array = [];
        const { CurrentAccount } = this.info;
        if (!CurrentAccount) return array;
        array.push(this.createAmountItem(CurrentAccount));
        list = array;
      } else {
        const key = this.isFrom ? 'Debit' : 'Credit';
        list = this.info.Accounts.map(account => {
          const item = this.createAmountItem(account);
          const available = account?.[key]?.transferableCode === 'OK';
          let toolTipMessage = available ? null : account.ToolTipMessage;
          if (!available) {
            toolTipMessage = toolTipMessage ? toolTipMessage : this.$t(account?.[key]?.description, { type: this.isFrom ? this.$t('sender') : this.$t('recipient') });
          }
          const result = {
            ...item,
            disabled: typeof account?.[key] !== 'undefined' ? !available : true,
            Disable: typeof account?.[key] !== 'undefined' ? !available : true,
            ToolTipMessage: toolTipMessage
          };

          return result;
        });
      }
      return list;
    },
  },
  watch: {
    current: 'updateType',
  },
  methods: {
    createAmountItem(account) {
      const item = {
        DisplayAmount: getDisplayAmount(account.Currency, account.Amount),
        value: account.AccountId,
        ...account,
      };

      return item;
    },
    updateType() {
      this.itemList = this.currentList;
      if (this.selected) {
        const accountItem = this.itemList.find(item => item.AccountId === this.selected);
        this.currentValue = accountItem ? accountItem.value : this.itemList[0].value;
        this.currentItem = accountItem ? accountItem : this.itemList[0];
      } else {
        this.currentValue = this.itemList[0].value;
        this.currentItem = this.itemList[0];
      }
      this.$emit('input', this.currentItem);
      this.toggle = false;
      this.$emit('input', this.currentItem);
    },
    itemClick(v, a) {
      this.currentValue = v;
      this.currentItem = this.itemList.find(item => item.value === v);
      this.$emit('input', this.currentItem);
      this.toggle = !this.toggle;
    },
    click() {
      if (this.disabled || this.current) return;
      this.toggle = !this.toggle;
    },
  },
  mounted() {
    this.updateType();
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[transfer-dropdown] { .min-h(75); .br(16); .rel(); .w(100%);
  .list-container { .abs(); .w(100%); .z(2); .t(10); .-a(@gp-green); .br(16); .max-h(400); overflow: auto;
    > .list-wrapper > {.bgc(#F8FBF9);
      [link-amount-item] {.bgc(#F8FBF9); .p(10, 20, 10, 20);}
    }
    > .list-wrapper { border-top: 1px solid #ddd;
      &:nth-child(1) { border-top: none; }
    }
  }
}

</style>
