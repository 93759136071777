<template>
  <div middle-name v-if="modelInfo">
    <ValidationComponent :label="middleNameLabel" name="_middleName" :component="TrimTextInput" :rules="`personalName:0,50,${countryWritingSystem}`" v-model="modelInfo.MiddleName" :preset="preset" />
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'MiddleName',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  lexicon: 'personalDetails',
  data() {
    return {
      middleNameLabel: `${this.$t('_.middleName')} ${this.$t('optional')}`,
      TrimTextInput,
    };
  },
  computed: {
    countryWritingSystem() {
      return this.modelInfo.CountryWritingSystem;
    },
    preset() {
      return { maxLength: 50 };
    },
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[middle-name] {}
</style>