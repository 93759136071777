<template>
  <div sofort-failed>
    <div class="progress-title">
      <h1>{{ $t('_.sofortFailedTitle') }}</h1>
      <p class="progress"><span :style="{width:'100%'}" /></p>
    </div>
    <template v-if="result === 'user_abort'">
      <FontIcon name="exclamation-triangle"/>
      <p class="desc">{{ $t('_.sofortUserAbortDescription1') }}</p>
      <br/>
      <p class="desc">{{ $t('_.sofortUserAbortDescription2') }}</p>
      <ProgressButton button-name="sofortOnlineBanking" @click="$emit('click', 'tryAgain')" />
      <ProgressButton button-name="sofortDocumentUpload" @click="$emit('click', 'replaceJumio')" />
    </template>
    <template v-else-if="result === 'invalid'">
      <template v-if="tryCount < 3">
        <FontIcon name="exclamation-triangle"/>
        <p class="desc">{{ $t('_.sofortInvalidDescription1') }}</p>
        <br/>
        <p class="desc">{{ $t('_.sofortInvalidDescription2') }}</p>
        <ProgressButton button-name="sofortOnlineBanking" @click="$emit('click', 'personalDetail')" />
        <ProgressButton button-name="sofortDocumentUpload" @click="$emit('click', 'replaceJumio')" />
      </template>
      <template v-else>
        <FontIcon name="exclamation-triangle"/>
        <p class="desc">{{ $t('_.sofortInvalidThreeTimeDescription1') }}</p>
        <br/>
        <p class="desc">{{ $t('_.sofortInvalidThreeTimeDescription2') }}</p>
        <ProgressButton button-name="sofortDocumentUpload" @click="$emit('click', 'replaceJumio')" />
        <p class="btn-link">
          <a @click="$emit('click', 'personalDetail')">{{ $t('sofortRetryButton') }}</a>
        </p>
      </template>
    </template>
    <template v-else>
      <FontIcon name="exclamation-triangle"/>
      <p class="desc">{{ $t('_.sofortFailedDescription') }}</p>
      <ProgressButton button-name="sofortDocumentUpload" @click="$emit('click', 'replaceJumio')" />
      <p class="btn-link">
        <a @click="$emit('click', 'tryAgain')">{{ $t('sofortRetryButton') }}</a>
      </p>
    </template>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';

export default {
  name: 'SofortFailed',
  components: { ProgressButton, FontIcon },
  lexicon: 'kyc.deGiude',
  props: {
    result: String,
    tryCount: { type: Number, default: 0 },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sofort-failed] {
  .progress-title {
    h1 { .tl(); .medium(); .fs(28); .c(white); .flex(); .items-center(); .wf(); .regular(); line-height: 1.2; }
    .progress { .wh(100%, 2); .rel(); .bgc(rgba(255, 255, 255, 0.07)); .block(); .mt(16);
      span { .block(); .wh(0, 2); .bgc(@c-red); }
    }
  }
  [font-icon] { .fs(60); .flex(); .justify-center(); .m(40,0,28); }
  [color-button] {
    &:nth-of-type(1) { .mt(55); }
    &:nth-of-type(2) { .mt(12); .bgc(#515151);
      @{hover-press} { .bgc(#515151); }
    }
  }
  .btn-link { .c(@c-link); .fs(20); .tc(); .mt(24);
    > a { .-b(@c-link, 2); }
  }
}
</style>
