<template>
  <GpFormTemplate login-time-limit>
    <template #header>
      <GpDescription v-if="description" :message="pageDescription" />
      <GpUlDescriptionList v-if="subDescription" :message="subDescription" />
      <CautionMessage :message="cautionMessage" />
      <GpDescription v-if="structure.config.verifyFreeze" :message="$t('_.verifyFreeze.message')" />
    </template>
    <template v-if="isLoaded" #content>
      <InputLoginTimeLimit :mode="validationMode" :rolling="rolling" :model="model" :current="current" />
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click="submit(validate)" :disabled="invalid || disabled">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import InputLoginTimeLimit from '@/views/components/pages/responsible-gaming/template/inputs/InputLoginTimeLimit.vue';
import ResponsibleProgressModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleProgressModal.vue';
import Specific from '@shared/types/Specific';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpUlDescriptionList from '@/views/components/gg-pass/GpUlDescriptionList.vue';
import CautionMessage from '@/views/components/pages/responsible-gaming/template/common/CautionMessage.vue';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'RgLoginTimeLimit',
  lexicon: 'responsibleGaming',
  components: { GpUlDescriptionList, InputLoginTimeLimit, GpFormTemplate, GpDescription, GpButton, CautionMessage },
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      countryCode: null,
      errorMsg: null,
      rolling: null,
      current: {
        CurrentMonthlyLoginTimeLimit: null,
        CurrentWeeklyLoginTimeLimit: null,
        CurrentDailyLoginTimeLimit: null,
        MonthlyLoginTimeLimitApplyAt: null,
        WeeklyLoginTimeLimitApplyAt: null,
        DailyLoginTimeLimitApplyAt: null,
      },
      model: {
        RequestMonthlyLoginTimeLimit: null,
        RequestWeeklyLoginTimeLimit: null,
        RequestDailyLoginTimeLimit: null,
        RequestType: 1,
      },
      isLoaded: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    calculateList() {
      return [
        { type: 'monthly', current: this.current.CurrentMonthlyLoginTimeLimit, request: this.model.RequestMonthlyLoginTimeLimit, noLimit: false, valueType: 'hr', rolling: this.rolling },
        { type: 'weekly', current: this.current.CurrentWeeklyLoginTimeLimit, request: this.model.RequestWeeklyLoginTimeLimit, noLimit: false, valueType: 'hr', rolling: this.rolling },
        { type: 'daily', current: this.current.CurrentDailyLoginTimeLimit, request: this.model.RequestDailyLoginTimeLimit, noLimit: false, valueType: 'hr', rolling: this.rolling },
      ];
    },
    pageDescription() {
      let desc = null;
      if (!['CZ', 'NL'].includes(this.countryCode)) {
        if (this.rolling === 'Rolling') {
          desc = this.$t('_.description.dailyPlayLimit');
        }
        if (this.rolling === 'Calendar' && this.structure.config.verifyFreeze) {
          desc = this.$t('_.description.dailyPlayLimit');
        } else if (this.rolling === 'Calendar' && !this.structure.config.verifyFreeze) {
          desc = this.$t('_.subDescription.hangtimePlayLimit');
        }
      } else {
        desc = this.$t('_.description.dailyLoginLimit');
      }
      return desc;
    },
    subDescription() {
      // dailyPlayLimit
      let desc = null;
      if (!['CZ', 'NL'].includes(this.countryCode)) {
        if (this.rolling === 'Rolling') {
          desc = this.$t('_.subDescription.dailyPlayLimit', { utcInfo: this.structure.config.timeZoneType });
        }
        if (this.rolling === 'Calendar') {
          desc = this.$t('_.subDescription.calendar', { utcInfo: this.structure.config.timeZoneType });
        }
      } else {
        if (this.rolling === 'Rolling') {
          desc = this.$t('_.subDescription.dailyLoginLimit', { utcInfo: this.structure.config.timeZoneType });
        }
        if (this.rolling === 'Calendar') {
          desc = this.$t('_.subDescription.calendar', { utcInfo: this.structure.config.timeZoneType });
        }
      }
      return desc;
    },
    cautionMessage() {
      return this.$t('_.caution.dailyLoginLimit');
    },
    disabled() {
      const {
        CurrentMonthlyLoginTimeLimit,
        CurrentWeeklyLoginTimeLimit,
        CurrentDailyLoginTimeLimit,
      } = this.current;
      const {
        RequestMonthlyLoginTimeLimit,
        RequestWeeklyLoginTimeLimit,
        RequestDailyLoginTimeLimit,
      } = this.model;

      let requestMonthly = (RequestMonthlyLoginTimeLimit || parseInt(RequestMonthlyLoginTimeLimit) === 0) ? parseInt(RequestMonthlyLoginTimeLimit) : 'custom';
      let requestWeekly = (RequestWeeklyLoginTimeLimit || parseInt(RequestWeeklyLoginTimeLimit) === 0) ? parseInt(RequestWeeklyLoginTimeLimit) : 'custom';
      let requestDaily = (RequestDailyLoginTimeLimit || parseInt(RequestDailyLoginTimeLimit) === 0) ? parseInt(RequestDailyLoginTimeLimit) : 'custom';

      if(this.site === siteIds.GGPNL){
        requestMonthly = requestMonthly !== 'custom' ? requestMonthly : CurrentMonthlyLoginTimeLimit;
        requestWeekly = requestWeekly !== 'custom' ? requestWeekly : CurrentWeeklyLoginTimeLimit;
        requestDaily = requestDaily !== 'custom' ? requestDaily : CurrentDailyLoginTimeLimit;
      }

      const sameValue = CurrentDailyLoginTimeLimit === requestDaily && CurrentWeeklyLoginTimeLimit === requestWeekly && CurrentMonthlyLoginTimeLimit === requestMonthly;
      const nullCheck = (!requestDaily && requestDaily !== 0 && requestDaily !== "") && (!requestWeekly && requestWeekly !== 0 && requestWeekly !== "") && (!requestMonthly && requestMonthly !== 0 && requestMonthly !== "");

      return sameValue || nullCheck;
    },
    validationMode() {
      return this.site === siteIds.GGPNL ? 'lazy' : 'aggressive';
    },
  },
  methods: {
    async initialize() {
      this.current = {
        ...this.current,
        CurrentMonthlyLoginTimeLimit: this.structure.current.CurrentMonthlyLoginTimeLimit || null,
        CurrentWeeklyLoginTimeLimit: this.structure.current.CurrentWeeklyLoginTimeLimit || null,
        CurrentDailyLoginTimeLimit: this.structure.current.CurrentDailyLoginTimeLimit || null,
      };
      this.model = {
        ...this.model,
        RequestMonthlyLoginTimeLimit: this.current.CurrentMonthlyLoginTimeLimit ? this.current.CurrentMonthlyLoginTimeLimit.toString() : null,
        RequestWeeklyLoginTimeLimit: this.current.CurrentWeeklyLoginTimeLimit ? this.current.CurrentWeeklyLoginTimeLimit.toString() : null,
        RequestDailyLoginTimeLimit: this.current.CurrentDailyLoginTimeLimit ? this.current.CurrentDailyLoginTimeLimit.toString() : null,
      };

      this.rolling = this.structure.config.LoginTimeLimitPeriodType;
      this.countryCode = this.structure.config.CountryCode;

      this.isLoaded = true;
    },
    createModalOptions() {
      const desc = this.structure.config.verifyFreeze ? 'responsibleGaming.modal.description.verifyFreeze' : 'responsibleGaming.modal.description.selfLimit';
      const descModel = {
        desc,
        hangTime: 'responsibleGaming.modal.description.hangTimeLimit',
      };

      let payload = { ...this.model };

      if(this.site === siteIds.GGPNL){
        payload.RequestMonthlyLoginTimeLimit = payload.RequestMonthlyLoginTimeLimit ? payload.RequestMonthlyLoginTimeLimit : this.current.CurrentMonthlyLoginTimeLimit;
        payload.RequestWeeklyLoginTimeLimit = payload.RequestWeeklyLoginTimeLimit ? payload.RequestWeeklyLoginTimeLimit : this.current.CurrentWeeklyLoginTimeLimit;
        payload.RequestDailyLoginTimeLimit = payload.RequestDailyLoginTimeLimit ? payload.RequestDailyLoginTimeLimit : this.current.CurrentDailyLoginTimeLimit;
      }

      return {
        title: { default: this.$t('_.modal.title') },
        subTitle: { default: this.$t(`_.modal.subTitle.${this.structure.config.title}`)},
        modalType: 'HangTime',
        request: () => this.$rg.request(payload),
        calculateList: this.calculateList,
        descModel,
        isVerifyFreeze: this.structure.config.verifyFreeze,
        config: this.structure.config
      };
    },

    openModal() {
      this.$modal(ResponsibleProgressModal, this.createModalOptions());
    },

    async submit(validate) {
      if (validate) {
        const invalid = await validate();
        if (!invalid) return;
      }
      this.openModal();
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';

</style>