<template>
  <div reaffirm-wsop-pep :name="formTag" :class="formClass">
    <ValidationProvider personal-pep-checkbox-item v-for="(pep, idx) in peps" :key="pep.propName" :rules="pep.required ? 'requiredCheck' : ''">
      <CheckboxItem v-model="pep.checked" theme="dark" @change="updateValue(pep.checked, idx)">
      <span class="exp">
        <template v-for="(obj) in pep.labelObjs">
          <template v-if="obj.key.toLowerCase() === '#text'">
            {{ obj.label }}
          </template>
          <template v-if="obj.key.toLowerCase() === 'a'">
            <a v-popover.bottom="{text: obj.popover, useClick: true}">{{ obj.label }}</a>
          </template>
        </template>
      </span>
      </CheckboxItem>
    </ValidationProvider>
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';

export default {
  name: 'ReaffirmWsopPep',
  lexicon: 'onBoarding.personalPep',
  components: { CheckboxItem },
  mixins: [baseTemplate],
  props: {
    formTag: { type: String, default: null },
    formClass: { type: String, default: null },
  },
  data() {
    return {
      peps: [{
        id: 1,
        required: false,
        propName: 'DeclarationIsPep',
        translateKey: 'declaration',
        checked: false,
        labelObjs: []
      }, {
        id: 2,
        required: true,
        propName: 'IsOnBehalfOf3rdParty',
        translateKey: 'isOnBehalfOf3rdParty',
        checked: true,
        labelObjs: []
      }, {
        id: 3,
        required: true,
        propName: 'IsAgreeAccurateInfo',
        translateKey: 'isAgreeAccurateInfo',
        checked: true,
        labelObjs: []
      }],
    };
  },
  methods: {
    updateValue(v, idx) {
      // DeclarationIsPep 의 경우 반대의 값이 들어가야하므로 반대 값 처리 진행
      if (idx === 0) v = !v;
      this.modelInfo[this.peps[idx].propName] = v;
    },
    convertLabelObject(label) {
      if (!label) return;

      const arrLabelObj = [];
      const parser = new DOMParser();
      const nodeList = parser.parseFromString(label, "text/html")?.body?.childNodes;

      nodeList.forEach((node, i) => {
        const { nodeName, nodeValue, id, innerHTML} = node;
        // 다국어의 a tag의 id값을 다국어 키로 하여 번역 처리 진행
        if (nodeName.toLowerCase() === 'a') arrLabelObj.push({key: nodeName, label: innerHTML || nodeValue, popover: `_.${id}` });
        else arrLabelObj.push({key: nodeName, label: innerHTML || nodeValue, });
      });

      return arrLabelObj;
    },
    async initialize() {
      this.peps[0].checked = this.modelInfo?.DeclarationIsPep === 'NonPEP';
      this.peps.forEach((o, idx) => { this.updateValue(o.checked, idx); o.labelObjs = this.convertLabelObject(this.$t(`_.${o.translateKey}`)); });
    },
  },
  async created() {
    await this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reaffirm-wsop-pep] {
  [checkbox-item] { .w(100%);
    &:not(:nth-of-type(1)) { .mt(20); }
    .exp { .max-w(580); white-space: normal; .fs(16); .ib(); .c(@c-placeholder); .regular();
      a { .c(@c-blurRed); .underline(); }
    }
  }
}
</style>