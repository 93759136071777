import _get from 'lodash/get';
import {ObjectLiteral} from '@shared/types/utilType';

const koreanWordFinalSoundCheck = <T extends string>(word: T) => {
  let lastCode = word.trim().charCodeAt(word.trim().length - 1);
  if (lastCode < 58) return [1, 1, 0, 1, 0, 0, 1, 1, 1, 0][lastCode - 48];
  if (lastCode < 91) lastCode += 32;
  if (lastCode < 123) return [0, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 0, 1, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0][lastCode - 97];
  return (lastCode - 0xAC00) % 28 > 0;
};

/**
 * @example
 *
 *  const localeLanguageMap = {
 *    kr: 'ko',
 *    us: 'en',
 *    jp: 'ja',
 *    cn: 'zh',
 *    gb: 'en',
 *    ca: 'en',
 *    fr: 'fr',
 *    fr_ca: 'fr',
 *  };
 *
 *  const localeCountryMap = {
 *    kr: 'kr',
 *    us: 'us',
 *    jp: 'jp',
 *    cn: 'cn',
 *    gb: 'gb',
 *    ca: 'ca',
 *    fr: 'fr',
 *    fr_ca: 'ca',
 *  };
 */

type BundleData<Language extends string> = { [P in Language]: ObjectLiteral };

type BI18nConstructor<Locale extends string, Language extends string, Country extends string> = {
  data: BundleData<Language>;
  localeLanguageMap: Record<Locale, Language>;
  localeCountryMap: Record<Locale, Country>;
  defaultLocale: Locale;
  fallbackLang?: Language;
};

export default class BI18n<Locale extends string, Language extends string, Country extends string> {
  private readonly data: BundleData<Language>;
  private readonly localeLanguageMap: Record<Locale, Language>;
  private readonly localeCountryMap: Record<Locale, Country>;
  protected readonly translates: Locale[];
  protected defaultLocale: Locale;
  private readonly fallbackLang: Language;

  constructor({data, localeLanguageMap, localeCountryMap, defaultLocale, fallbackLang}: BI18nConstructor<Locale, Language, Country>) {
    this.data = data;
    this.localeLanguageMap = localeLanguageMap;
    this.localeCountryMap = localeCountryMap;
    this.translates = Object.keys(localeLanguageMap) as Locale[];
    this.defaultLocale = defaultLocale;
    this.fallbackLang = fallbackLang || this.localeLanguageMap[defaultLocale];
  }

  localeByRouteLocale(routeLocale?: Locale) {
    return routeLocale || this.defaultLocale;
  }

  pathByRouteLocale(routeLocale?: Locale) {
    return (!routeLocale || routeLocale === this.defaultLocale ? '' : `/${routeLocale}`);
  }

  languageByRouteLocale(routeLocale?: Locale) {
    return this.localeLanguageMap[routeLocale || this.defaultLocale];
  }

  countryByRouteLocale(routeLocale?: Locale) {
    return this.localeCountryMap[routeLocale || this.defaultLocale];
  }

  routeMatches() {
    return this.translates.filter(locale => locale !== this.defaultLocale).join('|');
  }

  translate(key: string, lang: Language, plural: boolean, noMark: boolean, options?: number | ObjectLiteral) {
    if (!key) throw new Error('[BI18n] empty key');
    let curr: undefined | string | ObjectLiteral = _get(this.data[lang], key);
    curr = curr ?? _get(this.data[this.fallbackLang], key);
    let t: string | undefined = curr
      ? (typeof curr === 'string')
        ? curr
        : (curr[lang] || curr[this.fallbackLang])
      : undefined;
    if (t === undefined) return noMark ? null : `@@${key}@@`;

    let params: ObjectLiteral | undefined;
    if (plural) {
      const pl = t.split('|').map(s => s.trim());
      if (typeof options !== 'number') throw new Error('[BI18n] only number option is allowed for pluralization');
      if (options < 1) [t] = pl; // count 가 0 혹은 음수라면 첫번째
      else if (options > 1) t = pl[pl.length - 1]; // count 가 1 보다 크다면 마지막 항목
      else if (pl.length > 2) [, t] = pl; // count 가 1 이고 복수항목이 2개 이상이면 2번째
      else [t] = pl; // 아니면 첫번째
      params = {n: options, count: options};
    } else {
      if (typeof options === 'number') throw new Error('[BI18n] only Object option is allowed');
      params = options; // || paramInKey;
    }
    if (params) {
      return t.replace(/{([\w.]+)(?:\|([^,}]+),?([^,}]+)?)?}/g, (_, key, v1, v2) => {
        const word = _get(params, key);
        if (word === undefined) return `!!${key}!!`;
        if (v1 && v2) return word + (koreanWordFinalSoundCheck(word) ? v1 : v2);
        if (v1) return word + v1;
        return word;
      });
    }
    return t;
  }
}
