<template>
  <div screening-nl v-if="showFailedView">
    <template>
      <FontIcon name="info-circle-line" />
      <p v-html="$t('_.description1')" />
      <p v-html="$t('_.description2', {csEmail})" />
      <ProgressButton button-name="close" @click="close" />
    </template>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { close } from '@/utils/clientUtil';
import baseTemplate from '@/mixins/baseTemplate';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';

export default {
  name: 'ScreeningNl',
  lexicon: 'onBoarding.screening',
  components: { ProgressButton, FontIcon },
  mixins: [baseTemplate],
  data() {
    return {
      showFailedView: false,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  methods: {
    close() {
      close(this);
    },
    /**
     * 컴포넌트 내에서의 api request 처리
     * @returns {Promise<*|null>}
     */
    async apiRequest() {
      const r = await this.getApiResponse();
      if (r.error) {
        this.showFailedView = true;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.signicatScreening`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }
      }
    },
    /**
     *
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.kyc.signicatScreening(); }
      catch (e) { return e; }
    },
  },
  async mounted() {
    await this.apiRequest();
    if (!this.structure.error) this.modelInfo.step = 1;
    else this.item.title = 'onBoarding.title.accountVerification';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[screening-nl] {
  > [font-icon] { .block(); .tc(); .fs(60); .mb(32); }
  > p { .tl(); .fs(18, 22, normal); .c(#FFF);
    > a[href^='mailto'] { .c(#2680EB); .underline(); }
  }
  > p + p { .mt(22); }
}
</style>
