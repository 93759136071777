<template>
  <ul validation-errors :class="{error}" v-if="errorMsg || (errors && errors.length)">
    <li v-if="errorMsg" v-html="errorMsg"/>
    <li v-else v-for="error in errors" v-tv="error" :key="`${error._rule_}${error._field_}`" />
    <li v-if="placeholder && !error">{{ placeholder }}</li>
  </ul>
</template>
<script>
export default {
  name: 'ValidationErrors',
  props: {
    errors: { type: Array },
    errorMsg: { type: String },
    placeholder: { type: String },
  },
  computed: {
    error() {
      return this.errors?.length > 0;
    },
  },
};
</script>
<style lang="less">
@import "~@shared/less/proj.less";
[validation-errors] { .c(@c-red); .mt(4); .tl();
  > li { .fs(14);
    a[href^="mailto:"] { .c(@c-link); }
  }
}
</style>
