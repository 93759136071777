<template>
  <div v-if="linkedUrl" open-email-app>
    <FontIcon name="envelope-open-light" />
    <button @click="openEmailApp">{{ $t('openEmailApp') }}</button>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import emailList from '@/constants/base/email';
import { getCookie } from '@shared/utils/cookieUtils.mjs';
import { openEmailApp } from '@/utils/clientUtil';

export default {
  name: 'OpenEmailApp',
  components: { FontIcon },
  props: {
    email: { type: [String, /** @type { label: string, value: string } */Object], default: null },
  },
  data() {
    return {
      isFromMobileApp: getCookie('isFromMobileApp') || this.$route.query.isFromMobileApp,
      isFromClientApp: getCookie('isFromClientApp') || this.$route.query.isFromClientApp,
      emailValue: null,
    };
  },
  computed: {
    linkedUrl() {
      if (!this.emailValue) return;
      const [, domain] = this.emailValue.split('@');
      return emailList.find(email => email.label === domain)?.value;
    }
  },
  methods: {
    openEmailApp() {
      openEmailApp(this, this.emailValue);
    },
  },
  mounted() {
    this.emailValue = this.email?.label || this.email;
  }
};
</script>

<style lang="less" scoped>
@import '~@/less/proj.less';

[open-email-app] {
  > button { .ml(4); .c(#CCCCCC); .fs(16); }
  > [font-icon] { .fs(16); .c(#CCCCCC); }
}
</style>
