<template>
  <div np-display-uploaded-kyc-documents>
    <div class="display-wrapper">
      <div v-if="model.length > 0" class="uploaded-item" v-for="item in displayList">
        <div class="content-wrapper">
          <div class="header">
            <div class="label-wrapper">
              <label>{{ item.FileName }}</label>
            </div>
            <div class="button-wrapper">
              <div class="badge-group">
                <!--          v-if="item.DocumentStatus === 2"-->
                <TooltipItem v-if="item.DocumentStatus === 2" :text="item.Description || '-'">
                  <FontIcon class="tool-tip-icon" name="exclamation-circle-regular-sharp" />
                </TooltipItem>
                <GpBadge :type="getStatusType(item.DocumentStatus)" outline>{{ getStatus(item.DocumentStatus) }}</GpBadge>
              </div>
            </div>
          </div>
          <!--          <p class="content">{{ item.FileName }}</p>-->
          <p class="kyc-date">{{ getUploadDate(item.UploadDate) }}</p>
        </div>

      </div>
      <div v-if="model.length <= 0 || !model" class="no-data">
        <FontIcon name="exclamation-circle-regular-sharp" />
        <p>{{ $t('_.noDocument') }}</p>
      </div>
    </div>
    <GpPagination :current-page="currentPage" :page-limit="pageLimit" :page-index="pageIndex" @update="pagination" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import moment from 'moment/moment';
import TooltipItem from '@/views/components/gg-pass/TooltipItem.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import GpPagination from '@/views/components/gg-pass/GpPagination.vue';
import { kycDocumentStatus } from '@/constants/base/my-page';
import GpBadge from '@/views/components/gg-pass/GpBadge.vue';

export default {
  name: 'DisplayUploadedKycDocuments',
  components: { GpPagination, FontIcon, TooltipItem, GpBadge },
  lexicon: 'myInfo',
  props: {
    model: Specific
  },
  data() {
    return {
      list: [],
      currentPage: 1,
      cursor: 1,
      pageLimit: 4,
      // pageIndex: 0,
    };
  },
  computed: {
    displayList() {
      return this.model.slice(this.startIndex, this.endIndex);
    },
    startIndex() {
      return (this.currentPage - 1) * this.pageLimit;
    },
    endIndex() {
      return this.currentPage * this.pageLimit;
    },
    pageIndex() {
      return this.model.length > 0 ? Math.ceil(this.model.length / this.pageLimit) : 1;
    }
  },
  methods: {
    getUploadDate(date) {
      const locale = this.$i18n('locale');
      return moment(date).locale(locale).format('ll');
    },
    getStatus(status) {
      return this.$t(`_.${kycDocumentStatus[status]}`);
    },
    getStatusType(status) {
      const type = kycDocumentStatus[status];
      switch (type) {
        case 'pending' :
          return 'PENDING';
        case 'confirmed' :
          return 'SUCCESS';
        case 'expired' :
          return 'EXPIRED';
        default :
          return 'FAILED';
      }
    },
    pagination(index) {
      this.currentPage = index;
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-display-uploaded-kyc-documents] { .flex(); .flex-dc();.w(100%); .gap(20);
  .display-wrapper {.bgc(#f8f8f8); .br(4); .flex(); .flex-dc();.w(100%); .gap(15);
    .uploaded-item {.c(black); .flex(); .p(20); .bgc(#fff); .br(16); .w(100%);
      .content-wrapper { flex: 1; .w(100%);
        .header { .flex(); .space-between(); .items-center();
          .label-wrapper {overflow: hidden; text-overflow: ellipsis; white-space: nowrap; .w(75%);
            > label {.fs(14); font-weight: 400; .c(#737373);}
          }
          .kyc-status {.fs(14); font-weight: 500;}
        }
        .content {.fs(14); .c(#717171); overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
        .kyc-date {.c(#737373); .fs(12); .pt(4);}
      }
      .button-wrapper {.rel(); .w(25%);
        .badge-group {.flex(); .justify-end();
          [tooltip-item] {.fs(14); .pt(3); }
          [gp-badge] {.ph(5) !important;}
        }
      }
      @media (@ms-down) {
        .label-wrapper {.w(55%) !important;}
        .button-wrapper {.rel(); .w(45%); }
      }
    }
    .no-data { .c(black); .min-h(150); .flex(); .justify-center(); .items-center(); .flex-dc(); .gap(20); .bgc(white); .br(16); .min-h(200);
      [font-icon] {.fs(30); .c(#737373);}
    }
  }
  [popover-position] {
    .max-w(280) !important;;
  }
  @media (@ml-up) {
    .label-wrapper { .w(55%) !important; }
    .display-wrapper { .flex-dr(); flex-wrap: wrap;
      .uploaded-item { .w(48%); .flex-dr();
        .contents-wrapper {
          .header {

          }
        }
        .button-wrapper { .flex(); .justify-end(); }
      }
      .no-data {.w(100%);}
    }
  }
  .footer { .flex(); .justify-center(); .p(10); .items-center();
    .pagination {.flex();.gap(0); .items-center();
      > button { .c(black); line-height: 12px; .p(10, 12, 10, 12); .fs(14);
        &.on { .c(#1010104D) }
        &.active { .-a(#b5b5b5); .bgc(#e8e8e8); .c(#717171) }
      }
    }
  }

}
</style>
