const view = path => () => import(/* webpackChunkName: "ForgotPassword" */ `../views/pages/forgot-password/${path}.vue`);

export default ({ local }) => {
  const path = local ? 'forgot-password/' : 'app/';
  const list = [
    /** redirect */
    {
      path: `requestresetpassword`,
      redirect: `${path}find-pw/request-reset`,
    },
    /** root */
    {
      path: `${path}`,
      redirect: `${path}find-pw`,
      component: view('ForgotPassword'),
      children: [
        {
          path: `find-pw`,
          name: 'ForgotPassword',
          redirect: `find-pw/info`,
        },
        {
          path: 'find-pw/info',
          name: 'FpInfo',
          component: view('FpProgress'),
        },
        {
          path: 'find-pw/select',
          name: 'FpSelect',
          component: view('FpProgress'),
        },
        {
          path: 'find-pw/verify',
          name: 'FpVerify',
          component: view('FpProgress'),
        },
        {
          path: 'find-pw/reset',
          name: 'FpReset',
          component: view('FpProgress'),
        },
        {
          path: 'find-pw/complete',
          name: 'FpComplete',
          component: view('FpComplete'),
        },
        {
          path: 'find-pw/error',
          name: 'FpError',
          component: view('FpProgress'),
        },
        {
          path: `find-pw/request-reset`,
          name: 'FpRequestReset',
          component: view('FpRequestReset')
        },
        {
          path: `temp/request-reset`,
          name: 'FpRequestResetPassword',
          component: view('FpRequestResetPassword')
        },
        { path: '*', redirect: '/not-found' },
      ],
    },
  ];
  return list;
};