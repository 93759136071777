<template>
  <div reaffirm-wsop-address :name="formTag" :class="formClass">
    <SearchLoqateAddress v-if="isSearch" @reset="reset" @selected="selected" />
    <template v-else>
      <div class="fields-holder" v-if="isReady">
        <a class="btn-update-field" @click="reset(true)">
          <FontIcon name="chevron-left" />
          {{$t('_.personalAddressAddressReset')}}
        </a>
        <p v-html="$t('_.personalAddressManualDescription')" />
        <div class="between">
          <ValidationComponent :label="$t('_.country')" name="_country" :component="TrimTextInput" rules="required" :preset="{disable: true}" v-model="modelInfo.CountryName" />
          <ValidationComponent :label="$t('_.postalCode')" name="_postalCode" :component="TrimTextInput" :rules="postalRules" :preset="postalPreset" v-model="modelInfo.PostalCode" :error-msg="postalCodeErrorMsg" />
        </div>
        <div :class="{between: showState}">
          <ValidationComponent v-if="showState" :label="$t('_.stateProvince')" name="_stateProvince" :component="TrimTextInput" :rules="stateRules" :preset="statePreset" v-model="modelInfo.State" :error-msg="stateErrorMsg" />
          <ValidationComponent :label="$t('_.city')" name="_city" :component="TrimTextInput" :rules="cityRules" :preset="cityPreset" v-model="modelInfo.City" :error-msg="cityErrorMsg" />
        </div>
        <ValidationComponent :label="$t('_.loqateAddress')" name="_loqateAddress" :component="TrimTextInput" :rules="addressRules" :preset="addressPreset" v-model="modelInfo.Address" :error-msg="addressErrorMsg" />
      </div>
      <ProgressButton v-if="isReady" :button-name="buttonName" :progress="progress" :name="buttonTag" :class="buttonClass" :disabled="!!structure.limitTime || structure.error || invalid" @click="confirm" />
    </template>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import baseTemplate from '@/mixins/baseTemplate';
import FontIcon from '@shared/components/common/FontIcon.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import SearchLoqateAddress from '@/views/components/pages/on-boarding/template/address/SearchLoqateAddress.vue';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'ReaffirmWsopAddress',
  lexicon: 'personalDetails',
  components: { FontIcon, ProgressButton, ValidationComponent, SearchLoqateAddress },
  mixins: [baseTemplate],
  props: {
    template: { type: Specific },
    invalid: { type: Specific, default: null },
    formTag: { type: String, default: null },
    formClass: { type: String, default: null },
    buttonTag: { type: String, default: null },
    buttonClass: { type: String, default: null },
  },
  data() {
    return {
      progress: false,
      isSearch: true,
      isReady: false,
      selectedInfo: null,
      postalPreset: Specific,
      statePreset: Specific,
      cityPreset: Specific,
      addressPreset: Specific,
      postalRules: Specific,
      stateRules: Specific,
      cityRules: Specific,
      addressRules: Specific,
      districtErrorMsg: null,
      streetErrorMsg: null,
      houseNumberErrorMsg: null,
      addressErrorMsg: null,
      stateErrorMsg: null,
      buildingErrorMsg: null,
      cityErrorMsg: null,
      postalCodeErrorMsg: null,
      TrimTextInput,
    };
  },
  watch: {
    isSearch(v) {
      if (!v) this.initData();
      else this.isReady = false;
    }
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    csEmail: state('env', 'csEmail'),
    showState() {
      return ['CA', 'DE', 'CZ', 'MX', 'US'].includes(this.userInfo.Country);
    },
    step() {
      return this.structure.step;
    },
    customStep() {
      return this.structure.customStep;
    },
    customSteps() {
      return this.structure.customSteps;
    },
    lastStep() {
      return this.customStep >= this.customSteps?.length - 1;
    },
    api() {
      return this.template?.api;
    },
    buttonName() {
      return this.item?.buttonName || (this.lastStep ? 'submit' : 'next');
    },
    isProd() {
      return this.env === 'production';
    },
  },
  methods: {
    /**
     * SearchLoqateAddress 에서 선택 결과 반환
     * @param {object} v - 선택 결과
     */
    selected(v) {
      this.selectedInfo = v;
    },
    /**
     * 검색 초기화
     * @param {Boolean} v - 검색 초기화 여부
     */
    reset(v) {
      this.isSearch = v;
    },

    /**
     * modelInfo.PostalCode 유무에 따른 검색이나 결과 노출 처리
     */
    initialize() {
      this.isSearch = !this.modelInfo.CountryName || !this.modelInfo.PostalCode || !this.modelInfo.State || !this.modelInfo.City || !this.modelInfo.Address;
    },
    /**
     * 결과 노출 시 초기 설정
     * @returns {Promise<void>}
     */
    initData() {
      this.setDetailAddress().then(() => {
        this.initRules();
        this.initPreset();
        this.initErrorMsg();
        this.isReady = true;
      });
    },
    /**
     * ModelInfo 유무에 따른 Rules 설정
     */
    initRules() {
      this.postalRules = this.modelInfo.PostalCode ? 'required' : `requiredAddress:0,20,${this.modelInfo.CountryWritingSystem}`;
      this.stateRules = this.modelInfo.State ? 'required' : `requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`;
      this.cityRules = this.modelInfo.City ? 'required' : `requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`;
      this.addressRules = (this.modelInfo.StreetName || this.modelInfo.Building || this.modelInfo.HouseNumber) ? 'required' : `address:0,120,${this.modelInfo.CountryWritingSystem}`;
    },
    /**
     * ModelInfo 유무에 따른 Preset 설정
     */
    initPreset() {
      this.postalPreset = {disable: !!this.modelInfo.PostalCode, maxLength: 20};
      this.statePreset = {disable: !!this.modelInfo.State, maxLength: 50};
      this.cityPreset = {disable: !!this.modelInfo.City, maxLength: 50};
      this.addressPreset = {disable: !!(this.modelInfo.StreetName || this.modelInfo.Building || this.modelInfo.HouseNumber), maxLength: 120};
    },
    /**
     * 에러 메세지 초기화
     */
    initErrorMsg() {
      this.districtErrorMsg = null;
      this.streetErrorMsg = null;
      this.houseNumberErrorMsg = null;
      this.addressErrorMsg = null;
      this.stateErrorMsg = null;
      this.buildingErrorMsg = null;
      this.cityErrorMsg = null;
      this.postalCodeErrorMsg = null;
    },
    /**
     * Address property 에 저장 할 loqateAddress 정보 설정
     * @param street
     * @param buildingName
     * @param buildingNumber
     * @returns {*}
     */
    setLoqateAddress(street, buildingName, buildingNumber) {
      const arrLoqateAddress = [street, buildingName, buildingNumber];
      return arrLoqateAddress.reduce((a, b) => (a && b ? `${a}, ${b}` : a || b));
    },
    /**
     * selectedInfo 값 유무에 따라 상세 주소 조회 또는 ModelInfo를 기반으로 정보 설정
     * @returns {Promise<void>}
     */
    async setDetailAddress() {
      /* WSOP 관련 기획 및 지라
       * https://ggnetwork.atlassian.net/browse/GGBB-3043
       * https://ggnetwork.atlassian.net/wiki/spaces/AG/pages/840826893/Personal+Details+Input
       * https://ggnetwork.atlassian.net/wiki/spaces/P2/pages/1682440636/NP+Frontend+-+User+API#1.4-EditPersonalAddressRequest
       */
      if (!this.selectedInfo) {
        const loqateAddress = this.setLoqateAddress(this.modelInfo.StreetName, this.modelInfo.Building, this.modelInfo.HouseNumber);
        this.modelInfo.Address = loqateAddress;
      } else {
        const { selectedItem, sessionToken } = this.selectedInfo;
        const params = { SiteId: this.site, Country: this.userInfo.Country, AddressId: selectedItem.Id, SessionToken: sessionToken };
        const r = await this.apiRequest('searchDetailAddress', params);

        const loqateAddress = this.setLoqateAddress(r.Street, r.BuildingName, r.BuildingNumber);
        const setProperties = [{name: 'Address', value: loqateAddress}, {name: 'StreetName', value: r.Street}, {name: 'Building', value: r.BuildingName}, {name: 'HouseNumber', value: r.BuildingNumber}];

        Object.keys(r).forEach(o => { this.modelInfo[o] = r[o]; });
        setProperties.forEach(p => { this.modelInfo[p.name] = p.value; });
      }
    },

    nextStep() {
      this.structure.customStep++;
    },
    /**
     * 입력 정보 저장
     * @returns {Promise<void>}
     */
    async confirm() {
      await this.apiRequest(this.currentItem?.api, this.structure.model);
      if (!this.invalid && !this.structure.error) this.nextStep();
    },

    /**
     * 컴포넌트 내에서의 api request 처리
     * @param {Specific} params - 호출 파라미터
     * @param {string} api - api 함수 명
     * @param {object} params - params
     * @param {object} config - config
     * @returns {Promise<*|null>}
     */
    async apiRequest(api, params, config = null) {
      const r = await this.getApiResponse(api, params, config);
      if (r?.error) {
        if(!(await this.apiErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.${api}`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }

        return;
      }

      return r;
    },
    /**
     * api 결과 반환 처리
     * @param {string} api - api 호출 함수 명
     * @param {object} params - 호출 함수 전달 정보
     * @param {object} config - 설정
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse(api, params, config = null) {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding[api]({ ...params }, { ...config }); }
      catch (e) { return e; }
    },
    /**
     * api Error Handler
     * @param {object} r - error 정보
     * @returns {Promise<boolean>}
     */
    async apiErrorHandler(r) {
      const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
      /*
      * INVALID_STREET_NAME
        INVALID_STREET_NUMBER
        INVALID_HOUSE_NUMBER
        MISSING_EXTRA_ADDRESS
        INVALID_BARANGAY
        INVALID_ADDRESS
        INVALID_ADDRESS_LENGTH
        INVALID_ADDRESS_CHARACTER
        INVALID_STATE
        INVALID_BUILDING
        INVALID_CITY
        INVALID_CITY_LENGTH
        INVALID_CITY_CHARACTER
        INVALID_POSTAL_CODE
        INVALID_POSTAL_CODE_LENGTH
        INVALID_POSTAL_CODE_CHARACTER
      */
      switch (key) {
        case apiErrorCode.USER_INFO_ALREADY_EXIST:
        case apiErrorCode.REJECT_FROM_BGC:
          this.replaceRouteName('VerificationFailed', {desc, errorTemplate}, { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined });
          return;
        case apiErrorCode.INVALID_STREET_NAME:
        case apiErrorCode.INVALID_STREET_NUMBER:
          this.streetErrorMsg = this.$t(key);
          break;
        case apiErrorCode.INVALID_HOUSE_NUMBER:
          this.houseNumberErrorMsg = this.$t(key, {fieldName: this.$t('houseNumber')});
          break;
        case apiErrorCode.INVALID_BARANGAY:
          this.districtErrorMsg = this.$t(key);
          break;
        case apiErrorCode.INVALID_ADDRESS:
        case apiErrorCode.INVALID_ADDRESS_LENGTH:
        case apiErrorCode.INVALID_ADDRESS_CHARACTER:
          this.addressErrorMsg = this.$t(key, {fieldName: this.$t('address'), length: this.addressPreset.maxLength});
          break;
        case apiErrorCode.INVALID_STATE:
          this.stateErrorMsg = this.$t(key, {fieldName: this.$t('stateProvince')});
          break;
        case apiErrorCode.INVALID_CITY:
        case apiErrorCode.INVALID_CITY_LENGTH:
        case apiErrorCode.INVALID_CITY_CHARACTER:
          this.cityErrorMsg = this.$t(key, {fieldName: this.$t('city'), length: this.cityPreset.maxLength});
          break;
        case apiErrorCode.INVALID_POSTAL_CODE:
        case apiErrorCode.INVALID_POSTAL_CODE_LENGTH:
        case apiErrorCode.INVALID_POSTAL_CODE_CHARACTER:
          // TODO : PH는 length 4, CZ는 length 5
          this.postalCodeErrorMsg = this.$t(key, {fieldName: this.$t('postalCode'), length: this.postalPreset.maxLength});
          break;
      }

      return true;
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reaffirm-wsop-address] {
  .fields-holder {
    > p { .mv(24); }
    > .btn-update-field { .mb(12); .inline-flex(); .items-center(); .fs(16); .c(#05A0CE); .-b(#05A0CE);
      &:hover { .c(#05A0CE); .-b(@black-bg); .tr-d(.3); }
      > [font-icon] { .fs(8); .bold(); .mr(4); }
    }
    .between > div:nth-of-type(2) { .mt(8); }
  }
  @media (@tp-up) {
    .fields-holder {
      .between { .flex(); .space-between();
        > div:nth-of-type(1) {.w(calc(50% - 4px)); }
        > div:nth-of-type(2) { .mt(0); .w(calc(50% - 4px)); }
      }
    }
  }
}
</style>