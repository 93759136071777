<template>
  <div np-display-bank-info>
    <div class="info-header">
      <GpTitle :name="$t('myInfo.bankInfo')" />
      <TextButton v-if="!isSelfExclusion && showEditButton" @click="redirectKycUrl">{{ $t('edit') }}</TextButton>
    </div>
    <div class="display-group">
      <LabelComponent :label="$t('_.bankCountry')" :value="bankCountry" />
      <LabelComponent :label="$t('_.bankName')" :value="model.BankName" />
      <LabelComponent :label="$t('_.IBAN')" :value="model.IBAN" />
      <LabelComponent :label="$t('_.swiftBic')" :value="model.BankSwiftBic || '-'" />
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import LabelComponent from '@/views/components/gg-pass/LabelComponent.vue';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import TextButton from '@/views/components/gg-pass/TextButton.vue';
import { isString, isNumber } from 'lodash';
import { kycVerified } from '@/constants/base/my-page';
import BankInfoModal from '@/views/components/pages/my-page/modal/BankInfoModal.vue';

export default {
  name: 'DisplayBankInfo',
  components: { TextButton, GpTitle, LabelComponent },
  lexicon: 'myInfo',
  props: {
    model: Specific,
    kycStatus: { type: String, default: null, },
    isSelfExclusion: { type: Boolean, default: false }
  },
  computed: {
    bankCountry() {
      if (this.model.BankCountry) return this.model.BankCountry;
      if (isString(this.model.IBAN) && this.model.IBAN.length > 2) {
        const ibanCountryCode = this.model.IBAN.substring(0, 2);
        if (!isNaN(ibanCountryCode)) return '-';

        const countryCode = ibanCountryCode.toUpperCase();
        const countryLabel = this.$t(`country.${countryCode}`);
        if (countryLabel.includes('@')) return '-';

        return countryLabel;
      }
      return '-';
    },
    showEditButton() {
      return kycVerified.includes(this.kycStatus);
    }
  },
  methods: {
    redirectKycUrl() {
      this.$modal(BankInfoModal);
      // this.$myinfo.redirectKycUrl({ redirectPath: 'np-personal-info', lang: this.$i18n('lang'), customStep: 'VerifySurePay' });
    },
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-display-bank-info] {
  .display-group { .bgc(#fff);.p(20); .br(16); .flex(); .flex-dc();.w(100%); .gap(10);
    .bank-item {.c(black); .flex(); .items-center(); border-bottom: 1px solid #D3D6D9; .pv(20);
      &:nth-last-child(1) { border-bottom: none;}
      .label {flex: 1; .c(black); .fs(14); font-weight: 500;}
      .content {flex: 2; .fs(14);}
    }
  }

}
</style>