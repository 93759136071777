export default () => ({
  namespaced: true,
  state: {
    userInfo: null,
    currency: null,
    recaptchaKey: null,
    geecaptchaKey: null,
    countryCode: null,
    token: null,
    balance: null,
    currencySymbol: null,
    onePassInfo: null,
    gwToken: null,
    gwExpired: null,
    gpToken: null,
    signedAccessToken: null,
    signedEmail: null,
    signedMobileNumber: null,
    signedPw: null,
    reCheckTarget: null,
  },
  getters: {
    isLogin(state) {
      return !!state.userInfo;
    },
  },
  mutations: {
    setUserInfo(state, info) {
      state.userInfo = info;
    },
    setCurrency(state, info) {
      state.currency = info;
    },
    setRecaptchaKey(state, key) {
      state.recaptchaKey = key;
    },
    setGeecaptchaKey(state, key) {
      state.geecaptchaKey = key;
    },
    setCountryCode(state, code) {
      state.countryCode = code;
    },
    setToken(state, value) {
      state.token = value;
    },
    setBalance(state, value) {
      state.balance = value;
    },
    setCurrencySymbol(state, value) {
      state.currencySymbol = value;
    },
    setOnePassInfo(state, info) {
      state.onePassInfo = info;
    },
    setGwToken(state, value) {
      state.gwToken = value;
    },
    setGwExpired(state, value) {
      state.gwExpired = value;
    },
    setGpToken(state, value) {
      state.gpToken = value;
    },
    removeToken(state) {
      state.gpToken = null;
      state.gwExpired = null;
      state.gwToken = null;
      state.token = null;
    },
    setSignedAccessToken(state, value) {
      state.signedAccessToken = value;
    },
    setSignedEmail(state, value) {
      state.signedEmail = value;
    },
    setSignedMobileNumber(state, value) {
      state.signedMobileNumber = value;
    },
    setSignedPw(state, value) {
      state.signedPw = value;
    },
    setReCheckTarget(state, value) {
      state.reCheckTarget = value;
    },
  },
});
