<template>
  <div extra-address-common v-if="modelInfo">
    <div class="fields-holder">
      <CheckboxItem theme="dark" v-model="isSameAddress" @change="toggleSameAddress">{{ $t('_.extraAddress.samePermanentAddressNotice') }}</CheckboxItem>
      <ValidationComponent :label="$t('_.address')" name="_address" :component="TrimTextInput" :rules="`requiredAddress:0,120,${this.modelInfo.CountryWritingSystem}`" v-model="modelInfo.Address" :preset="addressPreset" @input="updateModelInfo" :error-msg="addressErrorMsg" />
      <ValidationComponent :label="$t('_.street')" name="_street" :component="TrimTextInput" :rules="`requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`" v-model="modelInfo.StreetName" :preset="streetNamePreset" @input="updateModelInfo" :error-msg="streetErrorMsg" />
      <ValidationComponent :label="$t('_.barangay')" name="_barangay" :component="TrimTextInput" rules="required" v-model="modelInfo.District" :preset="districtPreset" @input="updateModelInfo" :error-msg="districtErrorMsg" />
      <ValidationComponent :label="$t('_.stateProvince')" name="_stateProvince" :component="TrimTextInput" :rules="`requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`" v-model="modelInfo.State" :preset="statePreset" @input="updateModelInfo" :error-msg="stateErrorMsg" />
      <ValidationComponent :label="$t('_.city')" name="_city" :component="TrimTextInput" :rules="`requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`" v-model="modelInfo.City" :preset="cityPreset" @input="updateModelInfo" :error-msg="cityErrorMsg" />
      <ValidationComponent :label="$t('_.postalCode')" name="_postalCode" :component="TrimTextInput" :rules="postalRule" v-model="modelInfo.PostalCode" :preset="postalPreset" @input="updateModelInfo" :error-msg="postalCodeErrorMsg" />
    </div>
    <ProgressButton button-name="next" @click="confirm" :progress="isProgress" :name="buttonTag" :class="currentItem.buttonClass" :disabled="!!structure.limitTime || structure.error || invalid" />
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import Specific from '@shared/types/Specific';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'ExtraAddressCommon',
  lexicon: 'personalDetails',
  components: { ProgressButton, CheckboxItem, ValidationComponent },
  mixins: [baseTemplate],
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      originExtraAddressInfo: {
        Address: null,
        StreetName: null,
        District: null,
        StateProvince: null,
        City: null,
        PostalCode: null
      },
      extraAddressInfo: {
        Address: null,
        StreetName: null,
        District: null,
        StateProvince: null,
        City: null,
        PostalCode: null
      },
      isSameAddress: true,
      isProgress: false,

      districtErrorMsg: null,
      streetErrorMsg: null,
      houseNumberErrorMsg: null,
      addressErrorMsg: null,
      stateErrorMsg: null,
      buildingErrorMsg: null,
      cityErrorMsg: null,
      postalCodeErrorMsg: null,

      TrimTextInput
    };
  },
  computed: {
    siteId: state('user', 'siteId'),
    addressPreset() {
      return { maxLength: 120, disable: this.isSameAddress };
    },
    streetNamePreset() {
      return { maxLength: 50, disable: this.isSameAddress };
    },
    districtPreset() {
      return { maxLength: 120, disable: this.isSameAddress };
    },
    statePreset() {
      return { maxLength: 50, disable: this.isSameAddress };
    },
    cityPreset() {
      return { maxLength: 50, disable: this.isSameAddress };
    },
    postalPreset() {
      return ['GGPCZ'].includes(this.siteId) ? { digit: true, maxLength: 5, disable: this.isSameAddress } : { maxLength: 20, disable: this.isSameAddress };
    },
    postalRule() {
      return ['GGPCZ'].includes(this.siteId) ? 'required' : `requiredAddress:0,20,${this.modelInfo.CountryWritingSystem}`;
    },
    step() {
      return this.structure.step;
    },
    buttonTag() {
      const t = this.currentItem?.buttonTag;
      if (!t) return '';
      return t?.[this.site] || t.default || t;
    },
    currentItem() {
      return this.structure?.list[this.step];
    },
  },
  methods: {
    /**
     * checkbox click 시 정보 갱신
     */
    toggleSameAddress() {
      if (this.isSameAddress) {
        this.extraAddressInfo = { ...this.originExtraAddressInfo };
      } else {
        this.extraAddressInfo.Address = null;
        this.extraAddressInfo.StreetName = null;
        this.extraAddressInfo.District = null;
        this.extraAddressInfo.State = null;
        this.extraAddressInfo.City = null;
        this.extraAddressInfo.PostalCode = null;

        this.$parent.reset(); // validator reset
      }

      this.updateModelInfo();
    },
    /**
     * modelInfo 정보 갱신
     */
    updateModelInfo() {
      this.modelInfo.Address = this.extraAddressInfo.Address;
      this.modelInfo.StreetName = this.extraAddressInfo.StreetName;
      this.modelInfo.District = this.extraAddressInfo.District;
      this.modelInfo.State = this.extraAddressInfo.State;
      this.modelInfo.City = this.extraAddressInfo.City;
      this.modelInfo.PostalCode = this.extraAddressInfo.PostalCode;
    },
    /**
     * 입력 정보 저장
     * @returns {Promise<void>}
     */
    async confirm() {
      this.isProgress = true;
      const payload = { ExtraAddresses: [{ ...this.extraAddressInfo }] };
      await this.apiRequest(this.currentItem?.api, payload);
      this.isProgress = false;
      if (!this.invalid && !this.structure.error) {
        // 정상적으로 저장 되었을 경우, modelInfo 를 갱신함.
        this.modelInfo.ExtraAddresses = [{ ...this.extraAddressInfo }];
        this.modelInfo.step = 1;
      }
    },

    /**
     * 컴포넌트 내에서의 api request 처리
     * @param {Specific} params - 호출 파라미터
     * @param {string} api - api 함수 명
     * @param {object} params - params
     * @param {object} config - config
     * @returns {Promise<*|null>}
     */
    async apiRequest(api, params, config = null) {
      const r = await this.getApiResponse(api, params, config);
      if (r?.error) {
        if (!(await this.apiErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail });
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.${api}`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }

        return;
      }

      return r;
    },
    /**
     * api 결과 반환 처리
     * @param {string} api - api 호출 함수 명
     * @param {object} params - 호출 함수 전달 정보
     * @param {object} config - 설정
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse(api, params, config = null) {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding[api]({ ...params }, { ...config }); } catch (e) { return e; }
    },
    /**
     * api Error Handler
     * @param {object} r - error 정보
     * @returns {Promise<boolean>}
     */
    async apiErrorHandler(r) {
      const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
      /*
      * INVALID_STREET_NAME
        INVALID_STREET_NUMBER
        INVALID_HOUSE_NUMBER
        MISSING_EXTRA_ADDRESS
        INVALID_BARANGAY
        INVALID_ADDRESS
        INVALID_ADDRESS_LENGTH
        INVALID_ADDRESS_CHARACTER
        INVALID_STATE
        INVALID_BUILDING
        INVALID_CITY
        INVALID_CITY_LENGTH
        INVALID_CITY_CHARACTER
        INVALID_POSTAL_CODE
        INVALID_POSTAL_CODE_LENGTH
        INVALID_POSTAL_CODE_CHARACTER
      */
      switch (key) {
        case apiErrorCode.USER_INFO_ALREADY_EXIST:
        case apiErrorCode.FAILED_SELF_EXCLUSION_CHECK:
        case apiErrorCode.REJECT_FROM_BGC:
          this.replaceRouteName('VerificationFailed', { desc, errorTemplate }, { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined });
          break;
        case apiErrorCode.INVALID_STREET_NAME:
        case apiErrorCode.INVALID_STREET_NUMBER:
          this.streetErrorMsg = this.$t(key);
          break;
        case apiErrorCode.INVALID_BARANGAY:
          this.districtErrorMsg = this.$t(key);
          break;
        case apiErrorCode.INVALID_HOUSE_NUMBER:
          this.houseNumberErrorMsg = this.$t(key, { fieldName: this.$t('houseNumber') });
          break;
        case apiErrorCode.INVALID_ADDRESS:
        case apiErrorCode.INVALID_ADDRESS_LENGTH:
        case apiErrorCode.INVALID_ADDRESS_CHARACTER:
          this.addressErrorMsg = this.$t(key, { fieldName: this.$t('address'), length: this.addressPreset.maxLength });
          break;
        case apiErrorCode.INVALID_STATE:
          this.stateErrorMsg = this.$t(key, { fieldName: this.$t('stateProvince') });
          break;
        case apiErrorCode.INVALID_CITY:
        case apiErrorCode.INVALID_CITY_LENGTH:
        case apiErrorCode.INVALID_CITY_CHARACTER:
          this.cityErrorMsg = this.$t(key, { fieldName: this.$t('city'), length: this.cityPreset.maxLength });
          break;
        case apiErrorCode.INVALID_POSTAL_CODE:
        case apiErrorCode.INVALID_POSTAL_CODE_LENGTH:
        case apiErrorCode.INVALID_POSTAL_CODE_CHARACTER:
          this.postalCodeErrorMsg = this.$t(key, { fieldName: this.$t('postalCode'), length: this.postalPreset.maxLength });
          break;
      }

      return true;
    },
  },
  created() {
    this.originExtraAddressInfo = { ...this.modelInfo };
    this.extraAddressInfo = { ...this.modelInfo };
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[extra-address-common] { .rel();
  > div {
    > * {
      &:not(:nth-of-type(1)) { .mt(8); }
    }
  }
}
</style>
