<template>
  <div gender-selector v-if="modelInfo">
    <ValidationComponent name="_gender" :label="$t('gender')" :component="RadioButtonList" v-model="modelInfo.Gender" rules="requiredCheck" :preset="{list:genderList, disable: disabledGender}" tabindex="0"/>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import RadioButtonList from '@shared/components/common/RadioButtonList.vue';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'GenderSelector',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  data() {
    return {
      genderList: [{ label: 'male', value: 'Male' }, { label: 'female', value: 'Female' }],
      RadioButtonList,
    };
  },
  computed: {
    disabledGender() {
      return this.modelInfo.blockFields?.includes('Gender') && !!this.modelInfo.Gender;
    },
  },
  methods: {},
  mounted() {
    this.modelInfo.Gender = this.modelInfo.Gender ?? this.genderList[0].value;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gender-selector] { }
</style>