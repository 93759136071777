<template>
  <div v-if="config" fp-reset class="field-wrapper">
    <ValidationPassword :config="config" :label="`${$t('forgotPassword.label.newPassword')}(${min}-${max})`" :preset="passwordPreset" name="newPassword" :rules="pwRules" v-model="modelInfo.Password" />
    <ValidationComponent name="confirmPassword" v-model="modelInfo.ConfirmPassword" :rules="confirmPwRules" :preset="confirmPasswordPreset" :component="PasswordInput" :label="$t('forgotPassword.label.confirmPassword')" />
    <ValidationErrors class="error-msg" :errorMsg="structure.errorMsg" />
  </div>
</template>

<script>
import { isExcludeGgpass } from '@/utils/baseUtil';
import { emailPasswordValidation, mobilePasswordValidation, npPasswordConfigs } from '@/utils/pwUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import ValidationPassword from '@/views/components/common/ValidationPassword.vue';

export default {
  name: 'FpReset',
  components: { ValidationPassword, ValidationComponent },
  props: {
    modelInfo: { type: Specific, default: null },
    structure: Specific,
  },
  data() {
    return {
      captcha: null,
      config: null,
      PasswordInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    min() {
      return this.config?.passwordMinLength || 8;
    },
    max() {
      return this.config?.passwordMaxLength || 20;
    },
    emailValue() {
      return this.modelInfo.Email || '';
    },
    mobileValue() {
      return this.modelInfo.MobileNumber || '';
    },
    emailMode() {
      return this.modelInfo?.LoginMethod === 'Email';
    },
    passwordPreset(){
      return {placeholder : ''}
    },
    confirmPasswordPreset(){
      return {placeholder : ''}
    },
    pwRules() {
      if (!this.config) return '';
      return this.emailMode ? emailPasswordValidation(this.config, this.emailValue) : mobilePasswordValidation(this.config, this.mobileValue);
    },
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    confirmPwRules(){
      const password = encodeURIComponent(this.modelInfo.Password);
      return `required|confirmPassword:${password}`
    }
  },
  watch: {
    'modelInfo.Password': function () {this.$emit('clear-error'); },
    'modelInfo.ConfirmPassword' : function () {this.$emit('clear-error'); },
    // 'modelInfo.Password': function () {this.$emit('clear-error'); },
  },
  methods: {
    async initialize() {
      let pwConfig = null;
      if (this.isGgpass) {
        pwConfig = await this.$services.ggpass.getHighestConfig();
      } else {
        pwConfig = npPasswordConfigs(this.site);
      }

      this.config = pwConfig;
    },
  },
  async mounted() {
    this.structure.title = 'forgotPassword.title.resetPassword';
    this.structure.desc = 'forgotPassword.description.resetPasswordDescription';
    this.structure.back = false;
    this.structure.showButton = true;
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-reset] {
  > div + div { .mt(12); }
}
</style>