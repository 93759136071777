<template>
  <th table-head @click="sort" :rowspan="rowspan" :colspan="colspan">
    {{ label }}
    <i v-if="(useSort && field) && this.field !== this.sortField"></i>
    <FontIcon class="sort" v-if="(this.field && this.sortField) && this.field === this.sortField" :name="this.sortType === 'desc' ? 'arrow-down-wide-short' : 'arrow-down-short-wide'" />
    <span class="tooltip" v-if="tooltip" v-popover.left.center="{ text:tooltip }">
      <FontIcon name="circle-exclamation" />
    </span>
  </th>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'GridTableTH',
  components: { FontIcon },
  props: {
    field: { type: String },
    label: { type: String, default: '' },
    sortField: { type: String },
    sortType: { type: String },
    useSort: { type: Boolean },
    tooltip: { type: String, default: null },
    rowspan: { type: String, default: '2' },
    colspan: { type: String, default: '1' },
  },
  methods: {
    sort() {
      if (this.useSort) this.$emit('sort', this.field);
    },
  },
};
</script>

<style lang="less">
@import '@shared/less/proj.less';
[table-head] {
  .sort { .fs(14); .pointer(); .vam(); .c(@c-mint); }
  .tooltip { .ml(4); .pointer(); .inline-flex(); .vam();
    [font-icon] { .fs(14); .c(@c-link); }
  }
}
</style>
