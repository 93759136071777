<template>
  <div gp-pagination>
    <div class="pagination">
      <button @click="prev" :class="[{'on' : isFirst}]">
        <FontIcon name="chevron-left" />
      </button>
      <button v-for="item in pageArray" @click="click(item)" :class="[{'active' : currentPage === item}]">{{ item }}</button>
      <button @click="next" :class="[{'on' : isLast}]">
        <FontIcon name="chevron-right" />
      </button>
    </div>
  </div>
</template>

<script>
import RadioButton from '@/views/components/gg-pass/RadioButton.vue';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
export default {
  name: 'GpPagination',
  components: { FontIcon },
  props: {
    currentPage: { type: Number, default: 1 },
    cursor: { type: Number, default: 1 },
    pageLimit: { type: Number, default: 4 },
    pageIndex: { type: Number, default: 0 },
  },
  computed: {
    pageArray() {
      return Array.from({ length: this.pageIndex }, (v, i) => i + 1);
    },
    isFirst() {
      return this.currentPage === 1;
    },
    isLast() {
      return this.currentPage >= this.pageIndex;
    }
  },
  methods: {
    prev() {
      if (this.isFirst) return;
      this.$emit('update', this.currentPage - 1);
    },
    next() {
      if (this.isLast) return;
      this.$emit('update', this.currentPage + 1);
    },
    click(index) {
      this.$emit('update', index);
    },
  },
  mounted() {
    this.currentValue = this.value;
  }

};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-pagination] { .flex(); .justify-center(); .p(10); .items-center();
  .pagination {.flex();.gap(0); .items-center();
    > button { .c(#737373); line-height: 12px; .p(10, 12, 10, 12); .fs(14); font-weight: 400;
      &.on { .c(#1010104D) }
      &.active { .bgc(#333); .c(#fff); .br(100); opacity: 0.8; }
    }
  }
}
</style>