<template>
  <div check-affordability>
    <ValidationProvider affordability-check rules="requiredAffordabilityCheck">
      <CheckboxItem theme="dark" v-model="model" @change="change">{{ $t('kyc.enhancedDueDiligence.affordabilityCheck') }}</CheckboxItem>
    </ValidationProvider>
    <p v-if="isShow" v-html="$t('kyc.enhancedDueDiligence.confirmCheck')" />
  </div>
</template>
<script>
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';

export default {
  name: 'CheckAffordability',
  components: { CheckboxItem },
  props: {
    value: Boolean,
  },
  data() {
    return {
      model: false,
      confirm: null,
    };
  },
  computed: {
    isShow() {
      return this.confirm === false ? true : this.confirm ? false : false;
    }
  },
  methods: {
    change(v) {
      this.confirm = v;
      this.model = v;
      this.$emit('change', v);
    },
  },
  async mounted() {
    this.model = this.value;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[check-affordability] { .mv(16);
  > p { .fs(14); .mt(20); box-shadow: 0 6px 12px rgba(0,0,0,0.175); .p(6); .br(4); .bgc(#262626); .-a(#333); .c(#d8d8d8); }
  [checkbox-item] em { white-space: normal; }
}
</style>
