import { brandIds, gpBrandIds, siteIds } from '@/constants/base/siteMap.mjs';
import { ggpassLevel, ggpassMethod } from '@/constants/base/my-page';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { isExcludeTnc } from '@/utils/baseUtil';
import _ from 'lodash';

export default class GgPassService {
  #services;
  #store;
  #config;
  #proxyNpPublicApi;
  #proxyOpApi;
  #proxyAuthOpApi;
  #onePassInfo;

  constructor(services) {
    this.#services = services;
    this.#proxyNpPublicApi = services.proxyNpPublicApi;
    this.#proxyOpApi = services.proxyOpApi;
    this.#proxyAuthOpApi = services.proxyAuthOpApi;
    this.#store = services.store;
  }

  /**
   * @param oldPassword { String }
   * @param newPassword { String }
   * */
  changePassword({ OldPassword, Password }) {
    try {
      const newPassword = Password;
      const oldPassword = OldPassword;

      return this.#proxyAuthOpApi.proxyPut('/op/v1/onepass/passwords', { oldPassword, newPassword });
    } catch (e) {
      console.log('error : changePassword', e);
    }
  }

  /**
   *
   *
   */
  async getOnePassMfa() {
    try {
      const r = await this.#proxyAuthOpApi.proxyPut('/op/v1/onepass/mfas');
      if (r.error) {
        return r;
      }
      return r;
    } catch (e) {
      console.log('error : getConfig', e);
    }
  }

  /**
   * @param methodType { String }
   * @param level { String }
   * @param email { String }
   * @param countryCode { String }
   * @param mobileNumber { String }
   * @param verificationCode { String }
   * @param VerificationMethod { String }
   * */
  async setMfa(mfa) {
    const isEmail = ggpassMethod[mfa.VerificationMethod] === ggpassMethod.Email;
    const methodType = ggpassMethod[mfa.VerificationMethod];
    const level = ggpassLevel[mfa.SecurityLevel];
    const email = isEmail ? mfa?.Email?.trim() : null;
    const countryCode = isEmail ? null : mfa.CountryNumber ? `+${mfa.CountryNumber}` : null;
    const mobileNumber = isEmail ? null : mfa.MobileNumber;
    const verificationCode = mfa.VerificationCode ? mfa.VerificationCode : null;
    const verificationToken = mfa.Token ? mfa.Token : null;
    const brandId = this.#store.state.env.gpBrand;

    try {
      const result = await this.#proxyAuthOpApi.proxyPut('/op/v1/onepass/mfa', { methodType, level, email, countryCode, mobileNumber, verificationCode, verificationToken, brandId });
      if (result.error) {
        if (result.key === apiErrorCode.REQUIRED_VERIFICATION_CODE) {
          const json = JSON.parse(result.desc2);
          return json;
        } else if (result.key === apiErrorCode.RESEND_TERM_LIMITED) {
          const json = JSON.parse(result.desc2);
          const value = parseInt(json.remainingTime);
          return { key: apiErrorCode.RESEND_TERM_LIMITED, value: value };
        }
      }
      return result;
    } catch (e) {
      console.log('error : getMfa', e);
    }
  }

  /**
   * @param totpCode { String }
   * */
  setGoogleMfa(totpCode) {
    try {
      return this.#proxyAuthOpApi.proxyPut('/op/v1/onepass/mfa/google', { totpCode });
    } catch (e) {
      console.log('error : setGoogleMfa', e);
    }
  }

  /**
   * @param avatarId { String }
   * */
  setAvatar(avatarId) {
    try {
      return this.#proxyOpApi.proxyPut('/op/v1/onepass/accounts/avatars', { avatarId });
    } catch (e) {
      console.log('error : setAvatar', e);
    }
  }

  /**
   * @param nickname { String }
   * */
  setNickname(nickname) {
    try {
      return this.#proxyOpApi.proxyPut('/op/v1/onepass/accounts/nicknames', { nickname });
    } catch (e) {
      console.log('error : setNickname', e);
    }
  }

  /**
   * @param brandId { String }
   * @param siteId { String }
   * @returns { Promise<ggPassConfig> }
   * */
  getConfig() {
    try {
      const brandId = this.#store.state.env.gpBrand;
      const siteId = this.#store.state.env.gpSite;
      if (!this.#config) this.#config = this.#proxyOpApi.proxyGet('/op/v1/configs/onepass-creation', { brandId, siteId });
      return this.#config;
    } catch (e) {
      console.log('error : getConfig', e);
    }
  }

  /**
   * @param brandId { String }
   * @param siteId { String }
   * @returns { Promise<ggPassHighestConfig> }
   * */
  getHighestConfig() {
    try {
      const brandId = this.#store.state.env.gpBrand;
      const isMbp = brandId === gpBrandIds.GGPUKE || brandId === gpBrandIds.EVPUKE;
      const siteId = isMbp ? this.#store.state.env.gpSite : null;
      const gpToken = this.#store.state.user.gpToken;

      const config = { headers: {} };
      if (gpToken) config.headers['Z-Authorization'] = `Bearer ${gpToken}`;
      return this.#proxyOpApi.proxyGet('/op/v1/onepass/configs/onepass-creation', { brandId, siteId }, config);
    } catch (e) {
      console.log('error : getHighestConfig', e);
    }
  }

  /**
   * @param info { { PlatformType, StateCode:String, MobileCountryCode:String, Token:String, CountryCode, MobileNumber, VerificationCode, Email,Password, BonusCode, ChannelingAgentCode, BTag, QTag, Invite, SignupType, EmailVerificationCode,IsFromExternalUrl , IsTrackingUrl, SiteId, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, IsOffline , Challenge , SecCode ,Validate , FirstName , LastName , Gender , DateOfBirth , Address , City , PostalCode ,IsMarketingAgreement , IsAgreeTnC , IsTypedBonusCode, QueryParams } }
   * @param final { Boolean } 사인업 마지막 단계에서 NP signup을 진행하기 위한 값
   * @param npOnly { Boolean } tnc만 진행 되는 경우
   * */
  async signup(info) {
    try {
      if (this.#store.state.env.npOnly) {
        return await this.#services.sign.signup(info);
      } else {
        const siteId = this.#store.state.env.gpSite;
        const brandId = this.#store.state.env.gpBrand;
        const agree = isExcludeTnc(info.SiteId);

        const email = info.Email?.trim();
        const password = info.Password;
        const mobileNumber = info.MobileNumber;
        const countryCode = mobileNumber ? `+${info.PhoneCountryCode}` : null;
        const verificationCode = info.VerificationCode;
        const oAuthType = null;
        const oAuthToken = null;
        const residenceCountryId = info.CountryCode;
        const invitor = info.Invite;
        const isMarketingAgreement = agree || info.IsMarketingAgreement;
        const isTncAgreement = agree || info.IsAgreeTnC;
        const token = info.Token;
        const recaptchaToken = info.RecaptchaToken;
        const challenge = info.Challenge;
        const secCode = info.SecCode;
        const bonusCode = info.BonusCode;
        const referenceKey = this.#store.state.query.refKey;

        const query = this.#store.state.query;
        const REFERRAL_CODE_REGEX = /ca[0-9]{4,}/gi;
        let marketingTag = null;
        let referralCode = query.qtag || query.qtag1 || query.btag || query.btag1 || this.#services.cookie.getBonusTag();
        if(_.isArray(referralCode) && referralCode.length) referralCode = referralCode[0];
        if (referralCode && referralCode.includes('|')) {
          marketingTag = referralCode;
          referralCode = null;
        }
        if(referralCode === 'ocean' && bonusCode) {
          referralCode = null;
        }
        if (siteId === siteIds.WSOPON && REFERRAL_CODE_REGEX.test(referralCode)) referralCode = null;
        let r = await this.#proxyOpApi.proxyPost('/op-auth/v1/onepass/auth/sign-up', { token, email, countryCode, mobileNumber, password, verificationCode, oAuthType, oAuthToken, brandId, siteId, marketingTag, residenceCountryId, referralCode, bonusCode, invitor, isMarketingAgreement, isTncAgreement, recaptchaToken, challenge, secCode });
        if (r?.key === apiErrorCode.RESEND_TERM_LIMITED) r.value = r?.desc2.replace(/[^0-9]/g, '');
        if (r?.accessToken) try { r = await this.#services.sign.signup(info, r.accessToken); } catch (ee) { /* empty */ }

        return r;
      }
    } catch (e) {
      console.log('error : signup', e);
    }
  }

  /**
   * @param info { { PlatformType, StateCode:String, MobileCountryCode:String, CountryCode, MobileNumber, VerificationCode, Email,Password, BonusCode, ChannelingAgentCode, BTag, QTag, Invite, SignupType, EmailVerificationCode,IsFromExternalUrl , IsTrackingUrl, SiteId, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, IsOffline , Challenge , SecCode ,Validate , FirstName , LastName , Gender , DateOfBirth , Address , City , PostalCode ,IsMarketingAgreement , IsAgreeTnC , IsTypedBonusCode, QueryParams } }
   * @param final { Boolean } 사인업 마지막 단계에서 NP signup을 진행하기 위한 값
   * @param npOnly { Boolean } tnc만 진행 되는 경우
   * */
  async checkCredentials(info) {
    try {
      const email = info.Email?.trim();
      const mobileNumber = info.MobileNumber;
      const countryCode = mobileNumber ? `+${info.PhoneCountryCode}` : null;
      const password = info.Password;
      const brandId = this.#store.state.env.gpBrand;
      const siteId = this.#store.state.env.gpSite;
      const action = info.RecaptchaAction;
      const recaptchaToken = info.RecaptchaToken;
      const challenge = info.Challenge;
      const secCode = info.SecCode;

      const r = await this.#proxyOpApi.proxyPost('/op-auth/v1/onepass/auth/check-credentials', { email, countryCode, mobileNumber, password, brandId, siteId, action, recaptchaToken, challenge, secCode });
      if (r?.key === apiErrorCode.RESEND_TERM_LIMITED) r.value = r?.desc2.replace(/[^0-9]/g, '');

      return r;
    } catch (e) {
      console.log('error : checkCredentials', e);
    }
  }

  /**
   * @param brandId {String}
   * @param hasSignupInfo { Boolean }
   * @param hasMultiFactors { Boolean }
   * @param hasAccounts { Boolean }
   * @param hasAccountDetail { Boolean }
   * @param hasAccountAddresses { Boolean }
   * @param hasAccountVerifications { Boolean }
   * @param hasOauth { Boolean }
   * @param hasBalances { Boolean }
   * @param hasMfas { Boolean }
   * @param hasLoginLock { Boolean }
   * @returns { Promise<GgPassInfo> }
   * */

  getInfo({ hasSignupInfo, hasMultiFactors, hasAccounts, hasAccountDetail, hasAccountAddresses, hasAccountVerifications, hasTransferInfo, hasBalances, hasOauth, hasLoginLock, hasMfas }, reset = false) {
    try {
      if (!this.#onePassInfo || reset) this.#onePassInfo = this.#proxyAuthOpApi.proxyGet('/op/v1/onepass', { hasSignupInfo, hasMultiFactors, hasAccounts, hasAccountDetail, hasAccountAddresses, hasAccountVerifications, hasTransferInfo, hasBalances, hasOauth, hasLoginLock, hasMfas }, { silent: true });
      return this.#onePassInfo;
    } catch (e) {
      console.log('error : getInfo', e);
    }
  }

  /**
   * @returns { Promise<SignupResponse> }
   * */
  async verification(info, type = 'SIGN_UP') {
    try {
      const isMobile = info.LoginMethod === 'Mobile';
      const email = isMobile ? null : info.Email?.trim();
      const countryCode = isMobile ? info.CountryCode : null;
      const mobileNumber = isMobile ? info.MobileNumber : null;
      const recaptchaToken = info.RecaptchaToken;
      const brandId = this.#store.state.env.gpBrand;
      const siteId = this.#store.state.env.gpSite;
      const challenge = info.Challenge;
      const secCode = info.SecCode;
      const result = await this.#proxyOpApi.proxyPost('/op/v1/onepass/verification-codes/send', { email, countryCode, mobileNumber, type, recaptchaToken, brandId, siteId, challenge, secCode }, { silent: true });
      if (result.error && result.key === apiErrorCode.RESEND_TERM_LIMITED) return { ...result, value: result.desc2.replace(/[^0-9]/g, '') };
      return result;
    } catch (e) {
      console.log('error : verification', e);
    }
  }

  /**
   * @returns { Promise<SignupResponse> }
   * */
  mobileVerification(info, type = 'LINK_ACCOUNT') {
    try {
      const countryCode = info.CountryNumber ? `+${info.CountryNumber}` : '';
      const mobileNumber = info.MobileNumber;
      const brandId = this.#store.state.env.gpBrand;
      const siteId = this.#store.state.env.gpSite;

      return this.#proxyOpApi.proxyPost('/op/v1/onepass/verification-codes/send', { countryCode, mobileNumber, type, brandId, siteId });
    } catch (e) {
      console.log('error : verification', e);
    }
  }

  /**
   * @param email { String }
   * @param countryCode { String }
   * @param mobileNumber { String }
   * @param type { String }
   * @param code { String }
   * @param token { String }
   * @param method { String }
   * @returns { Promise<null> }
   * */
  verifyCode(info) {
    try {
      const isMobile = info.LoginMethod === 'Mobile';
      const email = isMobile ? null : info.Email?.trim();
      const countryCode = isMobile ? info.CountryCode : null;
      const mobileNumber = isMobile ? info.MobileNumber : null;
      const type = info.Type;
      const code = info.Code;
      const method = info.Method;
      const token = info.Token;
      const username = info.UserName;
      const challenge = info?.Challenge || null;
      const secCode = info?.SecCode || null;

      const brandId = this.#store.state.env.gpBrand;
      const siteId = this.#store.state.env.gpSite;

      return this.#proxyOpApi.proxyPost('/op/v1/onepass/verification-codes/verify', { email, countryCode, mobileNumber, type, token, code, method, username, challenge, secCode, brandId, siteId }, { silent: true });
    } catch (e) {
      console.log('error : verification', e);
    }
  }

  /**
   * @param type { String }
   * @param token { String }
   * @returns { Promise<GgpassVerification> }
   * */
  getVerification(token, type = 'RESET_PASSWORD') {
    try {
      return this.#proxyOpApi.proxyGet('/op/v1/onepass/verification-codes', { type, token }, { silent: true });
    } catch (e) {
      console.log('error : verification', e);
    }
  }

  /**
   * @param refreshToken { String }
   * @param countryCode { String }
   * @param mobileNumber { String }
   * @param verificationCode { String }
   * @param verificationToken { String }
   * @param password { String }
   * @returns { Promise<null> }
   * */
  resetPassword(info) {
    const isEmail = info.LoginMethod === 'Email';
    try {
      // 24-07-10 수정 - OP API Spec 변경으로 인하여 B/S 추가
      const brandId = this.#store.state.env.gpBrand;
      const isMbp = brandId === gpBrandIds.GGPUKE || brandId === gpBrandIds.EVPUKE;
      const siteId = isMbp ? this.#store.state.env.gpSite : null;

      const email = isEmail ? info.Email?.trim() : null;
      const countryCode = isEmail ? null : info.CountryCode;
      const mobileNumber = isEmail ? null : info.MobileNumber;
      const verificationCode = info.Code;
      const verificationToken = info.Token;
      const password = info.Password;
      const username = info.UserName;
      const verificationCodeType = info?.VerificationCodeType || null;

      const headers = {};
      if (this.#store.state.user.gpToken) headers['Z-Authorization'] = `Bearer ${this.#store.state.user.gpToken}`;

      return this.#proxyOpApi.proxyPut('/op/v1/onepass/passwords/reset', { email, countryCode, mobileNumber, verificationCode, verificationToken, password, username, verificationCodeType, brandId, siteId }, { silent: true, headers:headers });
    } catch (e) {
      console.log('error : resetPassword', e);
    }
  }

  /**
   * @param targetGgpassIds { String }
   * @param email { String }
   * @param countryCode { String }
   * @param mobileNumber { String }
   * @param verificationCode { String }
   * @returns { Promise<{ brandId: String, siteId: String }> }
   * */

  linkAccount({ targetGgpassIds, email, countryCode, mobileNumber, verificationCode }) {
    try {
      /** onepass/accounts/link ? */
      return this.#proxyAuthOpApi.proxyPut('/op/v1/onepass/link-accounts', { targetGgpassIds, email: (email ? email.trim() : undefined), countryCode, mobileNumber, verificationCode });
    } catch (e) {
      console.log('error : linkAccount', e);
    }
  }
  /**
   * @returns { Promise<any> }
   * */
  async setLoginId({ Email, CountryCode, Token, Code, MobileNumber, IsContactUpdate, LoginMethod }) {
    const isEmail = LoginMethod === 'Email';
    const email = isEmail ? Email?.trim() : null;
    const countryCode = isEmail ? null : CountryCode;
    const mobileNumber = isEmail ? null : MobileNumber;
    const verificationCode = Code;
    const verificationToken = Token;
    const brandId = this.#store.state.env.gpBrand;
    const IdType = isEmail ? 'Email' : 'MobileNumber';

    try {
      const result = this.#proxyAuthOpApi.proxyPut('/op/v1/onepass/login-id', { email, countryCode, mobileNumber, verificationCode, verificationToken, brandId });
      return result;
    } catch (e) {
      console.log('error : setLoginId', e);
    }
  }

  /**
   * @param refreshToken { String }
   * @returns { Promise<{accessToken: String}> }
   * */
  renewToken(refreshToken) {
    try {
      return this.#proxyOpApi.proxyPut('/op/v1/onepass/auth/tokens', { refreshToken });
    } catch (e) {
      console.log('error : renewToken', e);
    }
  }
  /**
   * @param statys {String}
   * @param fromDateTime {String}
   * @param toDateTime {String}
   * @param offset {String}
   * @param limit {String}
   * @param sortDirection {String}
   * @param sortProperty {String}
   @returns { Promise<GgpassTransferHistories> }
   * */
  getTransferHistories(searchModel) {
    try {
      return this.#proxyAuthOpApi.proxyGet('/op/v1/transfer/histories', { ...searchModel });
    } catch (e) {
      console.log('error : getTransferHistories', e);
    }
  }

  /**
   * @param FromOnepassAccountId { String }
   * @param FromCurrencyId { String }
   * @param FromAmount { String }
   * @param TransferType { String }
   * @param TransferMethod { String }
   * @param ToOnepassAccountId { String }
   * @param ToCurrencyId {String}
   * @returns { Promise<null> }
   * */
  setTransfer({ FromOnepassAccountId, FromCurrencyId, FromAmount, TransferMethod, TransferType, ToOnepassAccountId, ToCurrencyId }) {
    try {
      const fromOnepassAccountId = FromOnepassAccountId;
      const fromCurrencyId = FromCurrencyId;
      const fromAmount = FromAmount;
      const transferMethod = TransferMethod;
      const transferType = TransferType;
      const toOnepassAccountId = ToOnepassAccountId;
      const toCurrencyId = ToCurrencyId;

      return this.#proxyAuthOpApi.proxyPost('/op/v1/transfer', { fromOnepassAccountId, fromCurrencyId, fromAmount, transferMethod, transferType, toOnepassAccountId, toCurrencyId }, { silent: true });
    } catch (e) {
      console.log('error : setTransfer', e);
    }
  }

  updateSsoApple(code) {
    try {
      return this.#proxyOpApi.proxyPost('/op/v1/onepass/auth/login/apple', { code }, { silent: true });
    } catch (e) {
      console.log('error : updateSsoApple', e);
    }
  }

  updateNewPassword(info) {
    try {
      const newPassword = info.Password;
      const brandId = this.#store.state.env.gpBrand;
      const siteId = this.#store.state.env.gpSite;

      return this.#proxyOpApi.proxyPut('/op/v1/onepass/passwords/required-change', { newPassword, brandId, siteId, });
    } catch (e) {
      console.log('error : updateNewPassword', e);
    }
  }
}