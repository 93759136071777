<template>
  <SectionContainer match-bonus :class="{cancelled: isCancelled}">
    <template #title>
      <h3>
        <span>{{ $t('_.title') }}</span>
      </h3>
    </template>
    <template #contents>
      <div class="summary box">
        <h4>{{ $t('_.summary') }}</h4>
        <div class="progress-circle">
          <svg viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
            <circle stroke="#262626" stroke-width="2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" stroke-dasharray="100"></circle>
            <circle :stroke-dasharray="`${progress}, 100`" stroke="#C64040" stroke-width="2" stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
          </svg>
          <p class="progress-text">
            <span :class="{current: !isCancelled}">{{ matchBonus.currentIncrementCount }}</span>
            <span class="slash">/</span>
            <span>{{ matchBonus.maxIncrementCount }}</span>
          </p>
          <div class="chart-info">
            <div class="released">
              <p>
                <span :class="['value', {current: !isCancelled}]">{{ releasedAmount }}</span>
                <span class="text">{{ $t('_.released') }}</span>
              </p>
            </div>
            <div class="max">
              <p>
                <span class="value">{{ maxAmount }}</span>
                <span class="text">{{ $t('_.max') }}</span>
              </p>
            </div>
          </div>
        </div>
        <p class="description">{{ $t('_.totalIncrement') }}</p>
      </div>
      <div class="next box">
        <p>{{ $t('_.nextRelease') }}</p>
        <ProgressBar :status-info="statusInfo" />
      </div>
    </template>
  </SectionContainer>
</template>

<script>
import SectionContainer from '@/views/components/pages/promotion/v1/SectionContainer.vue';
import Specific from '@shared/types/Specific';
import ProgressBar from '@/views/components/pages/promotion/v1/ProgressBar.vue';

export default {
  name: 'MatchBonus',
  components: { ProgressBar, SectionContainer },
  lexicon: 'promotion.v1.signUp.matchBonus',
  props: {
    statusInfo: { /** @type {PromotionStatus} */ type: Specific, required: true },
  },
  computed: {
    matchBonus() {
      return /** @type {MatchBonusStatus} */this.statusInfo?.item?.matchBonuses[0] || {};
    },
    progress() {
      return ((this.matchBonus.currentIncrementCount / this.matchBonus.maxIncrementCount) * 100).toFixed(2);
    },
    currency() {
      return this.statusInfo?.promotionCurrency;
    },
    releasedAmount() {
      return this.$promotion.getCurrencyString(this.currency, this.matchBonus.releasedAmount);
    },
    maxAmount() {
      return this.$promotion.getCurrencyString(this.currency, this.matchBonus.maxBonus);
    },
    isCancelled() {
      return /^Cancel/.test(this.statusInfo?.item?.playerPromotionStatus);
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[match-bonus] { .wf(); .-t(@c-dimGrey);
  &.cancelled {
    [progress-bar],
    .summary .progress-circle { .o(0.8); filter: grayscale(1) brightness(2); }
  }
  .box { .p(20, 16, 16); .bgc(@c-tintBlack9); }
  .summary { .tc();
    h4 { .mb(20); .tl(); .semi-bold(); .fs(22); .lh(1.36em); }
    .current { .c(@c-blurRed); .semi-bold(); }
    .progress-circle { .rel(); .medium();
      svg { .w(100); transform: rotate(90deg) rotateY(180deg);
        circle:last-of-type { animation: circle-fill 2s reverse; }
      }
      > p { .abs(); .t(50%); .l(50%); .t-xyc(); }
      .chart-info { .flex(); .gap(80); .space-between(); .abs(); .lt(50%, 50%); .t-xyc();
        .released { .flex(); .justify-end(); }
        .max { .flex(); .justify-start(); }
        > div { .w(0);
          span { .block(); }
          .value { .mb(4); .fs(18); }
          .text { .regular(); .fs(15); }
        }
      }
      .slash { .ph(2); }
    }
    .description { .mt(16); }
  }
  .next { .-t(@c-w02); .tc();
    > p { .c(@c-lightGrey); }
  }


  @media (@mm-up) {
    .summary {
      .progress-circle .chart-info { .gap(120); }
    }
  }
  @media (@tp-up) {
    .summary {
      .progress-circle svg { .w(120); }
      .progress-circle .chart-info { .gap(180); }
    }
  }

  @keyframes circle-fill {
    to { stroke-dasharray: 0 100; }
  }
}
</style>
