<template>
  <div currency-input :class="['amount-text', size, { right }]">
    <span class="symbol" ref="symbol">{{ currencySymbol }}</span>
    <div class="text">
      <TextInputBox v-model="model" @input="updateModel" :isDecimalLimit="true" :decimal-limit="2" input-mode="number" :max-length="maxLength" :class="{'right': right}" @blur="blur" />
    </div>
    <em class="clone" ref="clone">{{ model }}</em>
  </div>
</template>

<script>
import { addResize, removeResize } from '@shared/utils/resizeUtil';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { currencyValue } from '@shared/utils/currencyUtils.mjs';
import { state } from '@shared/utils/storeUtils.mjs';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';

export default {
  name: 'CurrencyInput',
  components: { TextInputBox },
  props: {
    value: { type: [String, Number], default: null },
    maxLength: { type: Number, default: 9 },
    right: { type: Boolean, default: true },
    gap: { type: Number, default: 0 },
    size: { type: String, default: 'lg' }, // md | lg
    min: { type: Number, default: null },
    currencySymbol: { type: String, default: null },
    cashType: { type: String, default: null, },
  },
  data() {
    return {
      model: 0,
    };
  },
  watch: {
    value: 'updateValue',
    cashType: 'updateCashType',
  },
  methods: {
    updateCashType() {
      this.model = 0;
    },
    updateValue() {
      if (this.value) this.model = this.value;
      this.updateModel();
    },
    async updateModel() {
      this.model = String(isNaN(+this.model) ? 0 : this.model);
      if (+this.model >= 1) this.model = this.model.replace(/(^0+)/, '');

      // console.log(this.model, 'this.model');
      //
      // const isValidInput = /^\d*(\.\d{0,2})?$/.test(this.model);
      // console.log(isValidInput, 'isValidInput');
      //
      // if (!isValidInput) {
      //   // console.log('소수점이 2자리 이상');
      //   this.model = this.model.slice(0, -1);
      // }
      // console.log(this.model, 'this.model2');

      // this.model = currencyValue(this.model);
      this.$emit('input', this.model);
      await sleep(30);
      const inputTextWidth = this.$refs.clone?.offsetWidth;
      const symbolWidth = this.$refs.symbol?.offsetWidth;
      if (!inputTextWidth || !symbolWidth) return;
      if (this.right) {
        this.$refs.symbol.style.left = `${this.$el.clientWidth - (((inputTextWidth - this.model?.length) + symbolWidth + this.gap))}px`;
      } else {
        this.$refs.symbol.style.paddingRight = `${this.gap}px`;
      }
    },
    blur() {
      if (this.min !== null && this.model && +this.model === 0) {
        this.model = this.min;
        this.$emit('input', this.model);
      }
    },
  },
  mounted() {
    this.blur();
    this.updateValue();
    addResize(this.$el, this.updateModel);
  },
  beforeDestroy() {
    removeResize(this.$el, this.updateModel);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[currency-input] {.mb(5); .rel();
  [gp-text-input-box] > input {padding-left: 0 !important; padding-right: 0 !important; border-bottom: 1px solid #DDD;}
  > span { .c(black); font-weight: 500; .fs(18); .pr(4); .pb(4); line-height: 20px; }
  > p { .fs(24); font-weight: 500;.c(black); }

  // .rel; .flex; .items-start;
  //> .input-amount { .w(100%); .flex(); .justify-end(); .mb(5);
  //  [gp-text-input-box] > input {padding-left: 0 !important; padding-right: 0 !important; border-bottom: 1px solid #DDD;}
  //  > span { .c(black); font-weight: 500; .fs(18); .pr(4); .pb(4); line-height: 20px; }
  //  > p { .fs(24); font-weight: 500;.c(black); }
  //}
  //.text { .rel; .wf; .fs(inherit); .vam;
  //  [payment-input] { .hf; .fs(inherit); }
  //}
  .symbol { .fs(16); .z(1); pointer-events: none;}
  .text { .wf;
    [payment-input][text-input] { .h(inherit); .lh(inherit); }
    input { .hf; .lh(inherit); }
  }
  .clone { .o(0); .abs; .lt(0, 0); .fs(inherit); pointer-events: none; }

  // size
  &.lg {
    .symbol { .fs(14); .mt(2); }
    .text { .h(24); .lh(24);
      [payment-input][text-input] input { .fs(24); }
    }
  }
  &.md {
    .symbol { .fs(12); .mt(2); }
    .text { .h(18); .lh(18);
      [payment-input][text-input] input { .fs(18); }
    }
  }

  // text align
  &.right { .tr;
    .symbol { .abs; .lt(0, 10); }
    [payment-input] { .tr; }
  }

  @media (@ml-up) {
    &.divider { .pb(8); }

    &.lg {
      .symbol { .fs(12); .mt(1); }
      .text { .h(30); .lh(30);
        [payment-input][text-input] input { .fs(30); }
      }
    }
    &.md {
      .symbol { .fs(14); .mt(4); }
      .text { .h(24); .lh(24);
        [payment-input][text-input] input { .fs(24); }
      }
    }
  }
}
</style>
