<template>
  <div kyc-de>
    <template v-if="template">
      <ProgressTitle v-if="showProgressTitle" :default-label="title" is-final />
      <component :is="template" :model-info="modelInfo" :item="item" :structure="structure" @step="stepTrigger" />
    </template>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { checkClientPermission } from '@/utils/clientUtil';
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue';
import VerifyReadyDe from '@/views/components/pages/on-boarding/template/kyc/de/VerifyReadyDe.vue';
import VerifyJumio from '@/views/components/pages/on-boarding/template/VerifyJumio.vue';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'KycDe',
  components: { ProgressTitle },
  mixins: [baseTemplate],
  data() {
    return {
      step: 'sofort',
      sofort: null,
      info: null,
      components: {
        sofort: VerifyReadyDe,
        jumio: VerifyJumio,
      },
    };
  },
  computed: {
    site: state('env', 'site'),
    title() {
      return this.step === 'sofort' ? 'onBoarding.title.identityAndAddress' : 'onBoarding.title.identityVerification';
    },
    template() {
      return this.components[this.step];
    },
    showProgressTitle() {
      return !this.item.title;
    },
  },
  methods: {
    stepTrigger(step) {
      this.step = step;
    },
  },
  mounted() {
    checkClientPermission(this);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[kyc-de] {
  > p { .fs(16, 24); .mb(8); .medium(); }
  > a { .-a(@c-w01, 2px); .br(8); .block(); .rel(); .p(28, 24); .flex(); .items-center();
    > img { .h(40); }
    > div { .pl(24); .ml(24); .rel();
      &:before { .cnt(); .wh(1, 110%); .bgc(@c-w01); .abs(); .lt(0, 50%); .t-yc(); }
      h4 { .fs(20); .c(#FFC64D); .mb(8); }
      p { .fs(14, 20); }
    }
    &:nth-of-type(2) { .mt(24); }
  }
  @media (@ms-down) {
    [progress-title] {
      > h1 {.fs(21);}
    }
  }
}
</style>
