<template>
  <div rg-my-radio-item :class="[{'active' : this.isActive}]">
    <!--    <span :class="['radio-button', {'active': isActive}]"></span>-->
    <div class="radio-box">
      <div>
        <RadioButton :active="isActive" />
      </div>
      <div class="title-group">
        <div>
          <h3>{{ label }}</h3>
        </div>
      </div>
    </div>
    <div v-if="isActive && isRender" class="toggle-wrapper">
      <InputComponent v-if="isRender" name="custom-component" :rules="preset.rules" :component="renderingComponent" :value="selectValue" :preset="preset" @input="update" />
    </div>
  </div>

</template>

<script>
import { isAuthenticated } from '@shared/utils/routerUtils.mjs';
import RadioButton from '@/views/components/gg-pass/RadioButton.vue';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import DropSelect from '@shared/components/common/input/DropSelect.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'ResponsibleRadioItem',
  components: { InputComponent, RadioButton },
  props: {
    value: { type: String },
    label: { type: String },
    rules: { type: String, default: null, },
    active: { type: Boolean, default: false, },
    preset: { type: Specific, default: null },
    renderingComponent: { type: Specific, default: null },
    type: { type: String, default: null, }
  },
  data() {
    return {
      selectValue: null,
      DropSelect,
    };
  },
  computed: {
    isRender() {
      return this.renderingComponent;
    },
    isActive() {
      return this.active;
    },
  },
  methods: {
    update(v) {
      if (this.type === 'text') {
        this.$emit('update', v);
        return;
      } else {
        if (!v) return;
        this.$emit('update', v?.value);
        return;
      }
    }
  },
  mounted() {
    if (this.type === 'select') this.selectValue = this.value;
    else this.selectValue = null;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-my-radio-item ] { .c(black); .mt(-1); .pointer(); .rel();
  &.active {
    &::before { .br(5); content: ""; .w(100%); .h(100%); .abs(); .t(-1); .l(-1); .z(1); pointer-events: none; }
  }
  .radio-box { .flex(); .flex-dr(); .p(0); .gap(0);
    .title-group { .flex(); .flex-dr(); .space-between(); .items-center();
      @media (@ml-up) {
        .justify-start(); .gap(10);
      }
      > div { .flex(); .flex-dr(); .items-center(); .gap(10);
        > img {.wh(18); object-fit: cover;}
        > h3 {.fs(14); font-weight: 400;}
      }
      > p { .fs(12); .c(red);}
    }
    .content-group { .flex(); .flex-dr(); .gap(10); .items-center();
      > p {.c(#999999); .fs(12); line-height: 16px; }
    }
  }
  .toggle-wrapper {.pt(5); .pb(5); .pl(30);}
}
</style>