<template>
  <div drop-list :class="[convertDirection, className, {mounted, 'on': showList, 'active': isOn},theme]" :style="styleValue" v-if="showList" tabindex="0">
    <div ref="listBox" class="list-holder">
      <div class="list-box" :style="{maxHeight}">
        <a class="item" v-for="(item, index) in (showList || [])" :key="`list-${index}${uid}`" :class="{'active': activeId === index}" @click="e => clickListHandler(e,item)" tabindex="1">
          <i>{{ label(item) }}</i>
        </a>
        <li v-if="!showList.length" class="none-data">{{ translate ? 'No List' : $t('noList') }}</li>
      </div>
    </div>
  </div>
</template>

<script>
import dropSelect from '@shared/mixins/dropListBox';

export default {
  name: 'DropListBox',
  components: {},
  mixins: [dropSelect],
  methods: {},
  async mounted() {
    this.ready = true;
    this.selectedItem = this.value;
    if (this.selectedItem) this.activeId = this.list.findIndex(o => (o.value || o) === this.selectedItem);
    await this.updateSize();
  },
};
</script>

<style lang="less">
@import "~@shared/less/proj.less";
[drop-list] {
  .list-box {
    > a.item { .block(); .w(100%); .tl();
      &:focus { .bgc(@c-b02); }
    }
  }

  &.white {
    .list-box {
      > a.item { .block(); .w(100%); .tl(); .c(#000); border-top: none !important;
        &:focus { .bgc(@c-b02); }
      }
    }
  }
}
</style>