<template>
  <div class="link" terms-and-conditions-link v-if="url">
    <a :href="url" target="_blank">{{ $t(`_.${routerName}`) }}</a>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';

export default {
  name: 'TermsAndConditionsLink',
  components: {},
  lexicon: 'promotion.v1.linkText',
  props: {
    statusInfo: { /** @type {PromotionStatus} */ type: Specific, default: null },
  },
  computed: {
    url() {
      const info = this.statusInfo;
      return info?.depositBonusTermsAndConditionUrl || info?.dailyFreebieTermsAndConditionUrl;
    },
    routerName() {
      console.log(this.$route.name);
      return this.$route.name;
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[terms-and-conditions-link] { .flex(); .justify-end(); .wf(); .mb(10); .c(@c-blue); .fs(14);
  a { .-b(@c-blue); }
}
</style>
