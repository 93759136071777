<template>
  <div limit-minute-field>
    <label v-if="label"> {{ label }}</label>
    <div>
      <SearchDropSelect theme="dark" class="input-field" :list="selectList" :translate="false" v-model="selected" :rules="rules" auto select-only />
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import SearchDropSelect from "@/views/components/common/SearchDropSelect.vue";

export default {
  name: 'LimitMinuteField',
  components: { SearchDropSelect },
  props: {
    value: Specific,
    label: String,
    name: { type: String },
    rules: { type: String, default: '' },
    isMonthlyLoginFrequency: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: null,
      selectList: [],
    };
  },
  watch: {
    selected: 'updateSelected',
  },
  methods: {
    updateSelected() {
      this.$emit('input', this.selected.value);
    },
  },
  created() {
    const list = [{ label: `${this.$t('noLimit')}`, value: 0 }];
    if (this.isMonthlyLoginFrequency) [100, 50, 30, 10, 5, 1].forEach(i => list.push({ label: `${i} ${this.$t('onBoarding.setting.times')}`, value: i }));
    else [5, 10, 15, 30, 60].forEach(i => list.push({ label: `${i} ${this.$t('minute')}`, value: i }));
    list.unshift({ label: this.$t('noLimit'), value: 'noLimit' });
    this.selectList = list;
    this.selected = this.value || list?.[0];
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[limit-minute-field] { .flex(); .items-start();
  &:not(:nth-of-type(1)) { .mt(16); }
  > label { .fs(16); .c(#ccc); .mt(14); .min-w(120); }
  > div { .flex-grow(2);
    [drop-select] a input { .fs(16) !important; }
    .hour-holder { .flex(); .items-center(); .mt(8);
      [text-input] { .flex-grow(2); }
      >em { .ml(12); .ib(); .fs(14); .c(@c-w05); }
    }
  }
}
</style>
