<template>
  <div bank-account>
    <ValidationComponent name="_iban" :component="TextInput" rules="required" label="IBAN" :preset="{ maxLength: 50 }" />
    <div class="list-holder" v-if="fileList.length">
      <label>{{ $t('kyc.documentType.BankStatement') }}</label>
      <UploadItem v-for="(item, id) in fileList" :key="id" :info="item" @remove="remove(id)" />
    </div>
    <FileSelector @update="fileUpdate" ref="selector" :max-length="1">
      <p v-html="$t('kyc.dropFile', { fileLength: 1, fileSize: 10 })" />
    </FileSelector>
    <ProgressButton button-name="upload" @click="confirm" :disabled="!fileList.length"/>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import FileSelector from '@/views/components/pages/on-boarding/FileSelector.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import UploadItem from '@/views/components/extra/UploadItem.vue';
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import baseTemplate from '@/mixins/baseTemplate';
export default {
  name: 'BankAccount',
  mixins: [baseTemplate],
  components: { ProgressButton, UploadItem, FileSelector, ValidationComponent },
  data() {
    return {
      fileList: [],
      TextInput
    };
  },
  methods: {
    fileUpdate(list) {
      this.fileList = list;
      if( this.fileList.length ) {
        const f = this.fileList[0];
        // this.$emit('input', { FileData: f.img, FileName: f.name, PaymentAccountVerifyDocumentType: 'BankAccountNumber' });
      }
    },
    remove(id) {
      this.$refs.selector.remove(id);
    },
    confirm() {
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[bank-account] {
  .list-holder {
    >label { .block(); .c(@c-w06); .mv(24); }
  }
  [file-selector].disabled { .wh(0); .abs(); .lt(-9999,-9999);}
}
</style>
