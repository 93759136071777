<template>
  <div trans-union />
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'TransUnion',
  mixins: [baseTemplate],
  async mounted() {
    // transUnion 처리 시 오류가 발생하더라도 무시
    await this.$services.kyc.transUnion();
    this.modelInfo.replace = true;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[trans-union] {}
</style>