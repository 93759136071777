<template>
  <div signicat-eidv-processing>
    <FontIcon name="hourglass-empty-regular" />
    <h3 v-html="$t('_.eidvProcessing')" />
    <p v-html="$t('_.eidvDescription')" />
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'SignicatEidvProcessing',
  lexicon: 'onBoarding.signicat.processing',
  components: { FontIcon },
  methods: {}
};
</script>

<style lang="less">
@import '~@/less/proj.less';
@keyframes rotate-timer {
  0% { .t-r(0deg); }
  50% { .t-r(180deg); animation-delay: 2s}
  100% { .t-r(360deg);}
}
[signicat-eidv-processing] { .abs(); .lt(50%, 50%); .t-xyc(); .tc();
  > [font-icon] { .c(#777); .fs(36); .tc(); .block(); animation: rotate-circle 2.5s linear infinite; transform-origin: center; }
  > h3 { .mt(8); .c(#000); .fs(28); .mt(24);}
  > p { .mt(12); .c(#717171); .fs(16, 20, normal); }
}
</style>
