<template>
  <component grid-column :is="comp" :field="field" :sort-field="sortField" :sort-type="sortType" :label="label" :use-sort="useSort" @sort="sort" :tooltip="tooltip">
    <slot />
  </component>
</template>

<script>
import GridTableTH from '@shared/components/common/table/grid/GridTableTH.vue';
import GridTableTD from '@shared/components/common/table/grid/GridTableTD.vue';

export default {
  name: 'GridColumn',
  props: {
    label: { type: String, default: '' },
    tooltip: { type: String, default: null },
    weight: { type: Number, default: 10 },
    parentLabel: { type: String, default: null },
    field: { type: String },
  },
  computed: {
    comp() { return this.$parent.th ? GridTableTH : GridTableTD; },
    sortField() { return this.$parent.sortField; },
    sortType() { return this.$parent.sortType; },
    useSort() { return this.$parent.useSort; },
    rowSpan() {}
  },
  methods: {
    sort(field) {
      this.$parent.sort(field);
    },
  },
};
</script>
