<template>
  <div mobile-number v-if="modelInfo">
    <div class="field-holder">
      <ValidationComponent :label="$t('mobileNumber')" :labelColorGray="labelColorGray" name="_countryNumber" :component="CountryNumberDropSelect" rules="requiredSelect" :preset="countryCodePreset" v-model="countryCode" @input="updateCountry" @focus="$emit('focus')" @blur="$emit('blur')" />
      <ValidationComponent class="number-field" name="_mobileNumber" :component="TrimTextInput" :rules="`mobileNumber:${countryLength}`" v-model="modelInfo.MobileNumber" :error-msg="myErrorMsg" :preset="mobileNumberPreset" />
    </div>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import CountryNumberDropSelect from '@/views/components/common/CountryNumberDropSelect.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'MobileNumber',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  props: {
    labelColorGray: { type: Boolean, default: true },
  },
  data() {
    return {
      myErrorMsg: null,
      countryCode: null,
      countryLength: 0,
      CountryNumberDropSelect,
      TrimTextInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    extra() {
      return this.item?.extra && this.item.extra[this.site] ? this.item.extra[this.site] : {};
    },
    extraComponent() {
      return this.extra.template;
    },
    countryCodePreset() {
      return { disable: this.disabled, placeholder: this.placeholder };
    },
    mobileNumberPreset() {
      return { phone: true, type: 'tel', maxLength: 12, placeholder: this.$t('mobileNumber'), disable: this.disabled };
    },
    placeholder() {
      return this.$t('nothingSelected');
    }
  },
  watch: {
    'modelInfo.MobileNumber': function () {
      this.structure.errorMsg = null;
    },
    errorMsg() {
      if (this.errorMsg === apiErrorCode.INVALID_MOBILE_NUMBER) this.myErrorMsg = this.$tv({ _rule_: 'required', _field_: '_mobileNumber' });
      else if (this.errorMsg === apiErrorCode.PHONE_RESEND_LIMITED) this.myErrorMsg = this.$t('signUp.tnc.resendTimer', { seconds: this.structure.errorDesc });
      else if (this.errorMsg === apiErrorCode.EXISTING_MOBILE_NUMBER) this.myErrorMsg = this.$t(this.errorMsg);
      else if (this.errorMsg === apiErrorCode.EXIST_MOBILE) this.myErrorMsg = this.$t(this.errorMsg);
      else this.myErrorMsg = null;
    }
  },
  methods: {
    updateCountry(item) {
      if (!item) return;

      this.modelInfo.MobileCountryCode = item.value;
      this.modelInfo.CountryCode = item.value;
      this.modelInfo.PhoneCountryCode = item.PhoneCountryCode;
      this.countryLength = String(item.PhoneCountryCode)?.length;
    },
    async initialize() {
      this.modelInfo.SendVerificationType = 'MOBILE';
      this.countryCode = this.modelInfo.MobileCountry;
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[mobile-number] { .rel();
  .flex(); .flex-dc();
  .field-holder { .block();
    > div + div { .mt(4); }
    @media (@ml-up) {
      .flex(); .space-between();
      > div + div { .mt(0); }
      > * {
        &:nth-of-type(1) { .w(35%); }
        &:nth-of-type(2) { .w(calc(65% - 8px)); }
      }
    }
  }
}
</style>
