import { isExcludeGgpass } from '@/utils/baseUtil';
import { npPasswordConfigs } from '@/utils/pwUtil';
import { autoLogin } from '@/utils/clientUtil';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { getCookie } from '@shared/utils/cookieUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import { routeNames } from '@/constants/base/signup/signUpSiteMap';

export class SignServiceController {
  #services;
  #scope;
  #router;
  #store;
  #model;
  #isMBP;
  #isGGPBE;
  #isMobile;
  #isLocal;
  #site;
  #ggpass;
  #npOnly;
  #countryCode;

  #structure;
  #routeNames;

  setScope(scope) {
    this.#services = scope.$services;
    this.#router = scope.$router;
    this.#store = scope.$store;
    this.#model = scope.$model;
    this.#countryCode = scope.$route.query.country || this.#store.state.user.countryCode;

    this.#site = this.#store.state.env.site;
    this.#ggpass = this.#store.state.env.ggpass;
    this.#npOnly = this.#store.state.env.npOnly;
    this.#routeNames = this.#store.state.env.routeNames;
    this.#isMBP = [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.#site);
    this.#isGGPBE = this.#site === siteIds.GGPBE;
    this.#isMobile = this.#store.state.browser?.mobile;
    this.#isLocal = this.#store.state.env?.env === 'local';

    this.#scope = scope;
  }

  async initialize() {
    if (!this.#site && !this.#ggpass && !this.#npOnly) {
      await this.#scope.replaceRouteName('SignUpCountry', null, this.#scope.$route.query);
      return;
    }

    if (!this.#isMBP) await this.#services.captcha.reCaptchaInitialize();
    const pwConfig = !isExcludeGgpass(this.#site) ? await this.#services.ggpass.getConfig() : npPasswordConfigs(this.#site);
    const displayTnc = !isExcludeGgpass(this.#site) ? pwConfig?.displayTnc : (await this.#services.sign.getSiteConfig(this.#site))?.RequireTcAgreement;
    this.#store.commit('config/setInfo', pwConfig);
    this.#store.commit('config/setDisplayTnc', displayTnc);
  }

  async updateStructure(structure, requireTnc) {
    if(!requireTnc) {
      this.#structure = structure?.list.filter(s => s.route !== routeNames.SignUpTnc);
    }
  }

  async getModel() {
    const model = await this.#model.getModel(this.#scope, {application: 'signup', orderRouteNames: this.#routeNames, siteId: this.#site});
    if (model) model.model.CountryCode = this.#countryCode;
    return model;
  }

  async setRecaptcha(action) {
    return this.#isMBP ? await this.#services.captcha.setGeeCaptcha() : await this.#services.captcha.setRecaptcha(action);
  }

  async redirectOnboarding(token, { email, password, mobileNumber } = {}) {
    const isFromMobileApp = getCookie('isFromMobileApp') || this.#router.app.$route.query.isFromMobileApp;
    const isFromClientApp = getCookie('isFromClientApp') || this.#router.app.$route.query.isFromClientApp;

    let kycUrl = process.env[`VUE_APP_KYC_URL_${this.#site}`];
    if (kycUrl && this.#isLocal) kycUrl = `${location?.origin}/onboarding`;
    if ((isFromMobileApp || isFromClientApp) && kycUrl && token) {
      await autoLogin(this.#scope, { doLogin: false, email, password, mobileNumber });
      if (isFromMobileApp) await sleep(100); // mobile client 일 경우 postMessage 처리 시 mobile client 연동 방식에 따라 0.5초 딜레이를 줌

      const deviceType = this.#isMobile ? 'mobileType' : 'pcType';
      const isFromApp = isFromMobileApp ? 'isFromMobileApp=true' : 'isFromClientApp=true';
      location.href = `${kycUrl}?verificationType=verification&ViewType=${deviceType}&language=${this.lang}&${isFromApp}&token=${token}`;
      return false;
    }

    return true;
  }
}

export default {
  install(Vue) {
    Vue.prototype.$sign = new SignServiceController();
  }
};