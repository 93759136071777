<template>
  <div temporary-account-expired-ph v-if="modelInfo">
    <FontIcon name="exclamation-triangle" />
    <p v-html="$t('onBoarding.temporaryAccountExpiredPh.description1')" />
    <p v-html="$t('onBoarding.temporaryAccountExpiredPh.description2')" />
    <p v-html="$t('onBoarding.temporaryAccountExpiredPh.description3')" />
    <ProgressButton button-name="completeVerification" @click="complete" />
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'TemporaryAccountExpiredPh',
  components: { FontIcon, ProgressButton },
  mixins: [baseTemplate],
  methods: {
    async complete() {
      this.modelInfo.step = 1;
    },
  },
  mounted() {
    if (this.structure?.list?.[this.structure?.step]) this.structure.list[this.structure.step].background = true;
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[temporary-account-expired-ph] { .tc();
  > [font-icon] { .block(); .mb(24); .fs(80); }
  > p { .tl(); .fs(18, 22, normal);
    .red { .semi-bold(); .c(red); }
  }
  > p + p { .mt(22); }
  > [color-button] + [color-button] { .mt(16); }
}
</style>