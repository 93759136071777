<template>
  <div reaffirm-wsop-birth :name="formTag" :class="formClass">
    <div class="birth-holder">
      <DateOfBirth :modelInfo="modelInfo" :structure="structure" :item="item" :disabled="true" />
    </div>
    <div class="extra-holder">
      <ContactMobileNumber :modelInfo="modelInfo" :structure="structure" />
      <Citizenship :modelInfo="modelInfo" :structure="structure" :disabled="true" />
    </div>
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import DateOfBirth from '@/views/components/extra/DateOfBirth.vue';
import Citizenship from '@/views/components/pages/on-boarding/template/extra/Citizenship.vue';
import ContactMobileNumber from '@/views/components/pages/on-boarding/template/ContactMobileNumber.vue';

export default {
  name: 'ReaffirmWsopBirth',
  components: { ContactMobileNumber, Citizenship, DateOfBirth },
  mixins: [baseTemplate],
  props: {
    formTag: { type: String, default: null },
    formClass: { type: String, default: null },
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[reaffirm-wsop-birth] {
  .birth-holder {
    > [date-of-birth] { pointer-events: none; cursor: not-allowed; }
  }
  .extra-holder {
    > * { .mt(8);}
    > [citizenship] { pointer-events: none; cursor: not-allowed; }
  }
}
</style>