import { render, staticRenderFns } from "./VerifyCitizenship.vue?vue&type=template&id=45381e78"
import script from "./VerifyCitizenship.vue?vue&type=script&lang=js"
export * from "./VerifyCitizenship.vue?vue&type=script&lang=js"
import style0 from "./VerifyCitizenship.vue?vue&type=style&index=0&id=45381e78&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports