<template>
  <div limit-table>
    <!--    <div class="limit-table-header">-->
    <!--      <slot name="header" />-->
    <!--    </div>-->
    <div class="limit-table-body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'LimitTable',

};
</script>

<style lang="less">
@import '~@/less/proj.less';
[limit-table] { .flex(); .flex-dc();
  .limit-table-header {.flex();.items-center();.gap(10); .ph(20);
    > div { .w(40%); .c(#999); .fs(12); font-weight: 500;
      &:nth-of-type(1) {.w(20%);}
    }
  }
  .limit-table-body {flex: 1; .flex(); .flex-dc(); .gap(20); }

}
</style>