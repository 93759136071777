<template>
  <div input-personal-birth>
    <div v-if="step === STEP.DEFAULT">
      <LabelComponent :label="$t('dateOfBirth')" :value="displayLabel" />
    </div>
    <ValidationProvider v-if="step === STEP.INPUT" ref="refObserver" rules="required" name="_dateOfBirth" v-slot="{errors, dirty, touched, valid}" :slim="true">
      <input type="hidden" v-model="value">
      <label class="label">{{ $t('dateOfBirth') }}<span>*</span></label>
      <div class="content">
        <!--        <DropSelect class="month-drop-select" name="_month" auto v-model="date.month" :list="monthList" @input="updateDate" :translate="false" :placeholder="$t('month')" :disabled="disabled" />-->
        <SearchDropSelect :class="['month-drop-select',{'disabled':disabled}]" name="_month" auto :value="date.month" :list="monthList" @input="updateMonth" :translate="false" :placeholder="$t('month')" :disabled="disabled" select-only :hidden-icon="disabled" />
        <TextInputBox class="text-box" :digit="true" :max-length="2" :input-mode="'numeric'" :pattern="'[0-9]*'" v-model="date.day" @input="updateDate" :placeholder="$t('day')" :disabled="disabled" />
        <TextInputBox class="text-box" :digit="true" :max-length="4" :input-mode="'numeric'" :pattern="'[0-9]*'" v-model="date.year" @input="updateDate" :placeholder="$t('year')" :disabled="disabled" />
      </div>
      <ValidationErrors class="error-msg" :errors="errors" :errorMsg="errMsg" />
    </ValidationProvider>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import DropSelect from '@shared/components/common/input/DropSelect.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import { customDateFormat, getYYYYMMDDTHHmmssZ } from '@shared/utils/timeUtils.mjs';
import { ValidationProvider } from 'vee-validate';
import { makeRules } from '@/plugins/validate';
import { tncInfo } from '@/utils/webSiteUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { sleep } from '@shared/utils/commonUtils.mjs';
import LabelComponent from '@/views/components/gg-pass/LabelComponent.vue';
import { STEP } from '@/constants/base/my-page';
import moment from 'moment/moment';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import { toFullDate } from '@/utils/dateTimeUtil';

const BIRTH_LIST = [
  { label: 'jan', value: '01' }, { label: 'feb', value: '02' }, { label: 'mar', value: '03' }, { label: 'apr', value: '04' }, { label: 'may', value: '05' }, { label: 'jun', value: '06' },
  { label: 'jul', value: '07' }, { label: 'aug', value: '08' }, { label: 'sep', value: '09' }, { label: 'oct', value: '10' }, { label: 'nov', value: '11' }, { label: 'dec', value: '12' }
];
export default {
  name: 'InputPersonalBirth',
  components: { SearchDropSelect, LabelComponent, InputComponent, ValidationProvider, DropSelect, TextInputBox },
  props: {
    value: { type: String, default: null },
    step: { type: String, default: null, },
    editableModel: Specific,
    error: Specific,
  },
  data() {
    return {
      date: {
        year: null,
        day: null,
        month: null,
      },
      rules: null,
      errorMessage: null,
    };
  },
  computed: {
    moment() {
      return moment;
    },
    STEP() {
      return STEP;
    },
    site: state('env', 'site'),
    monthList() {
      return BIRTH_LIST.map(o => ({ ...o, label: this.$t(o.label) }));
    },
    disabled() {
      return !this.editableModel['DateOfBirth'];
    },
    errMsg() {
      return this.error.DateOfBirth || this.errorMessage || null;
    },
    birthError() {
      return !this.rules.validateDateOfBirth.validate(this.date) ? this.$tv({ _rule_: 'dateOfBirth' }) : null;
    },
    ageError() {
      const { age } = tncInfo(this.site);
      return !this.rules.validateConfirmAge.validate(this.date, { age }) ? this.$tv({ _rule_: 'confirmAge' }).replace('!!age!!', age.toString()) : null;
    },
    displayLabel() {
      if (!this.value) return null;
      const locale = this.$i18n('locale');
      return toFullDate(this.value, locale);
    }
  },

  methods: {
    initialize() {
      if (!this.value) return;
      const { year, month, day } = this.splitDateOfBirth();
      this.date.year = year;
      this.date.month = month;
      this.date.day = day;
    },

    splitDateOfBirth() {
      const year = customDateFormat(this.value, 'YYYY');
      const month = customDateFormat(this.value, 'MM');
      const day = customDateFormat(this.value, 'DD');
      return { year, month, day, };
    },
    updateMonth(v) {
      if (!v) return;
      this.date.month = v?.value;
      this.updateDate();
    },
    async updateDate() {
      const { year, month, day } = this.date;

      await sleep(60);
      this.myErrorMsg = this.birthError || this.ageError;
      let validate = false;
      if (!this.validate()) {
        validate = true;
      }

      this.errorMessage = this.birthError || this.ageError;

      if (this.errorMessage || validate) {
        this.$emit('update', 'DateOfBirth', null);
        return;
      }

      // const formatDate = new Date(year, month - 1, day);
      // const postDate = postDateFormat(formatDate);
      const postDate = getYYYYMMDDTHHmmssZ(`${year}-${month}-${day}`).toString();
      // this.$emit('input', postDate);
      this.$emit('update', 'DateOfBirth', postDate);
    },
    validate() {
      const { age } = tncInfo(this.site);
      // required
      if (Object.keys(this.date).some(key => this.date[key] === null)) {
        this.errorMessage = '';
        return false;
      }
      const { year, month, day } = this.date;
      return true;
    }
  },
  mounted() {
    this.rules = makeRules();
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-personal-birth] { .flex(); .flex-dc(); .gap(1);
  > div > label {.w(100%); .c(@gp-component-label); .fs(13);
    > span {.c(green); .ml(3);}
  }
  .content {.flex(); .gap(10); .items-start();
    > div {flex: 1;}
    > span {flex: 1;}
    .text-box { .wh(100%, 40); .rel();
      &::after {content: none}
      &.disabled {
        > input {.c(#a1a1aa);}
      }
    }
    .month-drop-select { .w(100%); .h(40); .br(8); border: none !important;
      &.open {border-bottom-left-radius: 0; border-bottom-right-radius: 0; border-bottom: none;
        > input {}
      }
      > a {.p(0);}
      &.disabled {.bgc(#d4d4d8); .-a(); .c(#a1a1aa); cursor: default;
        > a > input {background-color: transparent; .c(#a1a1aa);
          &::placeholder {.c(@c-b05); .fs(14);}
        }
      }
    }
  }
  .error-msg {.pl(2); .m(0); .c(@c-red); .fs(14); .pt(5);}

  [search-drop-select] { .rel(); .bgc(#FFF); .br(8);
    > a { .br(8); .c(#333); .bgc(#1e1e1e); box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .block(); .fs(14); .flex(); .items-center(); .h(64); .p(6, 20, 6, 12); .-box(); .w(100%); .rel();
      &.placeholder {.c(#ccc);}
      > label { .medium(); font-weight: 400;
        > em { .fs(14); }
        img { .mb(4); }
      }
      [font-icon] { .ib(); .fs(16); .abs(); .rt(10, 45%); .t-yc(); .c(#333);
        &.on { .t-r(180deg); }
      }
    }
    //.list-container { box-shadow: 2px 0 3px rgba(0, 0, 0, 0.5); .bgc(white); .br(8); .br-t(0); .abs(); .o(0); .crop(); .w(100%); .max-h(500); .h(0);
    .list-container { .bgc(white); .br(8); .br-t(0); .abs(); .o(0); .crop(); .w(100%); .max-h(500); .h(0);box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1); .br(8);
      // &.fill { .w(var(--innerWidth)); }
      &.fill { .w(100%); }
      &.on { .o(1); .z(10); }
      .list-inner { .pb(12); .pt(6); .hf();
        .input-holder { .p(10, 12); .bgc(#fff);}
        [text-input] {.bgc(#F7F7F8); .w(100%); box-shadow: none; border: none; .h(40);
          > input { .c(black); .fs(14); .pb(0);}
          > .input-btn-x {.bgc(#fff);
            > i {.c(black);}
          }
        }
        .scroll-holder { overflow-y: auto; .h(calc(100%));
          label { .block(); .p(10, 16); .w(100%); .c(black); .tl(); }
          button { .flex(); .p(10, 16); .w(100%); .c(black); .tl();
            &:hover, &:focus, &.focus { .bgc(#F3FCF5); }
            &.active { .bgc(#E6F3E8); }
            em { .fs(14); .w(100%); }
            .icn-chevron-right { margin: 0 0 0 auto; }
          }
        }
        .empty { .fs(14); .p(10, 12); .c(black);}
      }
    }
    &.md {
      .list-container {
        .list-inner {
          .scroll-holder { .h(100%); }
        }
      }
    }
    &.sm, &.md {
      > a { .bgc(transparent) !important; box-shadow: none; .-a(); .p(0, 12); .h(40); .br(4);
        > [text-input] {.-a(); box-shadow: none; .w(100%);
          input { .pl(0); }
        }
      }
      .list-container {
        .list-inner { .fs(14);
        }
      }
    }
    [text-input] {
      input { .fs(14, 20);
      }
    }
    .placeholder { .c(@gp-placeholder-color); .regular(); }
    &.disabled {.bgc(@gp-disabled-bgc);
      > a {.c(#a1a1aa);}
    }
  }
}
</style>
