<template>
  <div verify-uk v-if="showFailedView">
    <ProgressTitle :default-label="title" is-final />
    <div>
      <p class="desc">
        {{ $t('onBoarding.failed.ageVerificationFailedDescription1') }}<br />
        {{ $t('onBoarding.failed.ageVerificationFailedDescription2') }}
      </p>
      <div class="info-list">
        <div v-for="item in infoList" :key="item.label">
          <label>{{ $t(item.label) }}</label>
          <span>{{ item.value }}</span>
        </div>
      </div>
      <ProgressButton button-name="upload" @click="replace" />
    </div>
  </div>
</template>

<script>
import { close } from '@/utils/clientUtil';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { state } from '@shared/utils/storeUtils.mjs';
import { toFullDate } from '@/utils/dateTimeUtil';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import baseTemplate from '@/mixins/baseTemplate';
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'VerifyUk',
  components: { ProgressButton, ProgressTitle },
  mixins: [baseTemplate],
  props: {
    structure: { type: Specific, default: null },
  },
  data() {
    return {
      error: false,
      retry: false,
      infoList: null,
      showFailedView: false,
      title: 'onBoarding.title.sorryVerificationFailed',
    };
  },
  computed: {
    env: state('env', 'env'),
    csEmail: state('env', 'csEmail'),
    isLocal() { return this.env === 'local'; },
    locale() { return this.$i18n('locale'); },
  },
  methods: {
    /**
     * 컴포넌트 내에서의 api request 처리
     * @returns {Promise<*|null>}
     */
    async apiRequest() {
      const r = await this.getApiResponse();
      if (r.error) {
        if (!(await this.apiErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.verifyAge`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }
      }
    },
    /**
     *
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding.verifyAge(); } catch (e) { return e; }
    },
    async apiErrorHandler(r) {
      switch (r?.key) {
        case apiErrorCode.USER_INFO_NOT_VERIFIED:
          this.showFailedView = true;
          await this.$onboarding.injectionMyInfo();
          this.getInfoList();
          break;
        case apiErrorCode.USER_INFO_NOT_VERIFIED_AND_RETRY:
          this.retry = true;
          if (this.item) this.item.title = 'onBoarding.title.personalDetailCheck';
          break;
        case apiErrorCode.GAMSTOP_SELF_EXCLUDED:
        case apiErrorCode.ACCOUNT_SUSPENDED:
        case apiErrorCode.OASIS_BAN_EXIST:
        case apiErrorCode.FAILED_SELF_EXCLUSION_CHECK:
          this.$onboarding.errorHandler(r, true);
          break;
        case apiErrorCode.AISG_FAILED_SELF_EXCLUSION_CHECK:
          close(this);
          break;
        case apiErrorCode.KYC_UPLOAD_REQUIRED:
          this.replace();
          break;
        case apiErrorCode.INTERNAL_ERROR:
          break;
        case apiErrorCode.USER_INFO_NOT_VERIFIED_AND_PASS:
        default:
          return false;
      }
      return true;
    },
    getInfoList() {
      this.infoList = [
        { label: 'firstName', value: this.modelInfo.FirstName || '' },
        { label: 'lastName', value: this.modelInfo.LastName || '' },
        { label: 'dateOfBirth', value: toFullDate(this.modelInfo.DateOfBirth, this.locale) || '' },
        { label: 'gender', value: this.modelInfo.Gender || '' },
        // Lakemba 9
        { label: 'buildingAddress', value: this.modelInfo.Building || '' },
        { label: 'street', value: `${this.modelInfo.StreetName || ''} ${this.modelInfo.Street || this.modelInfo.Address || ''}` },
        { label: 'city', value: this.modelInfo.City || '' },
        { label: 'postalCode', value: this.modelInfo.PostalCode || '' },
        { label: 'employmentStatus', value: this.modelInfo.EmploymentType ? this.$t(`occupation.${this.modelInfo.EmploymentType}`) : '' },
      ];
    },
    replace() {
      this.modelInfo.replace = true;
    }
  },
  async mounted() {
    // if(this.structure.list[1] === 'ShowReviewingKycMessage') {
    //   this.modelInfo.next = 1;
    // } else {
    //   await sleep(500);
    //   await this.apiRequest();
    //   if (!this.structure.error) this.modelInfo.replace = true;
    // }
    await sleep(500);
    await this.apiRequest();
    if (!this.structure.error) this.modelInfo.replace = true;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-uk] {
  p.desc { .c(#d8d8d8); .regular(); }
  .info-list { .-t(rgba(255, 255, 255, 0.3)); .-b(rgba(255, 255, 255, 0.3)); .m(30, 0); .p(30, 0);
    > div { .flex(); .space-between(); .mb(15);
      &:last-child { .mb(0); }
      > label { .c(#717171); }
    }
  }
}
</style>
