<template>
  <div kyc-ready-uk>
    <div v-if="message" class="message-holder">
      <FontIcon name="info-circle-line" />
      <div>{{ message }}</div>
    </div>
    <p v-html="$t('_.verificationRequest.descriptionPh')" />
    <template v-if="poiList.length">
      <div class="holder" v-if="poiDefaultKycDocumentList.length">
        <h5>
          <FontIcon name="check" />
          {{ $t('_.proofOfIdentity.label') }}
        </h5>
        <ul>
          <li v-for="poi in poiDefaultKycDocumentList" :key="poi.value">
            <div>
              {{ $t(poi.label) }}
              <span v-html="$t(poi.description)" />
            </div>
          </li>
        </ul>
      </div>
      <div class="holder" v-if="poiMandatoryKycDocumentList.length">
        <h5>
          <FontIcon name="check" />
          {{ $t('_.proofOfIdentity.label') }}
        </h5>
        <ul>
          <li v-for="poi in poiMandatoryKycDocumentList" :key="poi.value">
            <div>
              {{ $t(poi.label) }}
              <span v-html="$t(poi.description)" />
            </div>
          </li>
        </ul>
      </div>
    </template>
    <template v-if="poaList.length">
      <div class="holder" v-if="poaDefaultKycDocumentList.length">
        <h5>
          <FontIcon name="check" />
          {{ $t('_.proofOfAddress.label') }}
        </h5>
        <ul>
          <li v-for="poa in poaDefaultKycDocumentList" :key="poa.value">
            <div>
              {{ $t(poa.label) }}
              <span v-html="$t(poa.description)" />
            </div>
          </li>
        </ul>
      </div>
      <div class="holder" v-if="poaMandatoryKycDocumentList.length">
        <h5>
          <FontIcon name="check" />
          {{ $t('_.proofOfAddress.label') }}
        </h5>
        <ul>
          <li v-for="poa in poaMandatoryKycDocumentList" :key="poa.value">
            <div>
              {{ $t(poa.label) }}
              <span v-html="$t(poa.description)" />
            </div>
          </li>
        </ul>
      </div>
    </template>
    <template v-if="eddList.length">
      <div class="holder">
        <h5>
          <FontIcon name="check" />
          {{ $t('_.declarationAffordability.label') }}
        </h5>
      </div>
      <div class="holder" v-if="eddDefaultKycDocumentList.length">
        <h5>
          <FontIcon name="check" />
          {{ $t('_.proofAffordability.label') }}
        </h5>
        <ul>
          <li v-for="edd in eddDefaultKycDocumentList" :key="edd.value">
            <div>
              {{ $t(edd.label) }}
              <span v-html="$t(edd.description)" />
            </div>
          </li>
        </ul>
      </div>
    </template>
    <ProgressButton button-name="proceed" @click="next(step)" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { getKycDocuments, kycStatus } from '@/constants/base/onboarding/kyc';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';

export default {
  name: 'KycReadyUk',
  lexicon: 'kyc',
  components: { ProgressButton, FontIcon },
  props: {
    kycDocumentGroups: /** @type {Array<KycDocumentGroup>} */ Specific,
    message: { type: String, default: '' },
  },
  watch: {
    kycDocumentGroups () {
      this.hasPoiStep = this.kycDocumentGroups.some(doc => doc.KycDocumentGroupType === kycStatus.Poi);
      this.hasPoaStep = this.kycDocumentGroups.some(doc => doc.KycDocumentGroupType === kycStatus.Poa);
      this.hasEddStep = this.kycDocumentGroups.some(doc => doc.KycDocumentGroupType === kycStatus.Edd);
      this.step = this.hasPoiStep ? kycStatus.Poi : this.hasPoaStep ? kycStatus.Poa : this.hasEddStep ? kycStatus.EddForm : null;
    }
  },
  data() {
    return {
      hasPoiStep: false,
      hasPoaStep: false,
      hasEddStep: false,
      step: kycStatus.Poi,
    };
  },
  computed: {
    site: state('env', 'site'),
    poiDefaultKycDocumentList() {
      if (!this.kycDocumentGroups) return [];
      const poiKycDocumentGroup = this.findKycDocumentGroup(this.kycDocumentGroups, kycStatus.Poi);
      return this.convertKeyValue(poiKycDocumentGroup, 'Default');
    },
    poiMandatoryKycDocumentList() {
      if (!this.kycDocumentGroups) return [];
      const poiKycDocumentGroup = this.findKycDocumentGroup(this.kycDocumentGroups, kycStatus.Poi);
      return this.convertKeyValue(poiKycDocumentGroup, 'Mandatory');
    },
    poaDefaultKycDocumentList() {
      if (!this.kycDocumentGroups) return [];
      const poaKycDocumentGroup = this.findKycDocumentGroup(this.kycDocumentGroups, kycStatus.Poa);
      return this.convertKeyValue(poaKycDocumentGroup, 'Default');
    },
    poaMandatoryKycDocumentList() {
      if (!this.kycDocumentGroups) return [];
      const poaKycDocumentGroup = this.findKycDocumentGroup(this.kycDocumentGroups, kycStatus.Poa);
      return this.convertKeyValue(poaKycDocumentGroup, 'Mandatory');
    },
    eddDefaultKycDocumentList() {
      if (!this.kycDocumentGroups) return [];
      const eddKycDocumentGroup = this.findKycDocumentGroup(this.kycDocumentGroups, kycStatus.Edd);
      return this.convertKeyValue(eddKycDocumentGroup, 'Default');
    },
    poiList() {
      return [...(this.poiDefaultKycDocumentList ?? []), ...(this.poiMandatoryKycDocumentList ?? [])];
    },
    poaList() {
      return [...(this.poaDefaultKycDocumentList ?? []), ...(this.poaMandatoryKycDocumentList ?? [])];
    },
    eddList() {
      return this.eddDefaultKycDocumentList ?? [];
    },
  },
  methods: {
    /**
     * Kyc Document 정보를 Label, Value 형태로 변환
     * @param {KycDocumentGroup} kycDocumentGroup - Kyc Document
     * @param type - Default, Mandatory
     * @returns {{label: *, value: *}[]|*[]}
     */
    convertKeyValue(kycDocumentGroup, type) {
      if (!kycDocumentGroup) return [];
      let documents;
      if (this.hasPoiStep) documents = type === 'Default' ? kycDocumentGroup.DefaultKycDocuments : kycDocumentGroup.MandatoryKycDocuments;
      if (this.hasPoaStep) documents = type === 'Default' ? kycDocumentGroup.DefaultKycDocuments : kycDocumentGroup.MandatoryKycDocuments;
      if (this.hasEddStep) documents = type === 'Default' ? ['BankStatements', 'EwalletStatements'] : kycDocumentGroup.MandatoryKycDocuments;

      if (!documents) return [];
      const kycDocuments = getKycDocuments(this.site);
      return documents.map(document => {
        const kycDocument = kycDocuments.find(type => type.value === document);
        return kycDocument ? { label: kycDocument?.label, value: kycDocument?.value, description: kycDocument?.description } : null;
      });
    },
    /**
     * 올려야 할 Kyc document 중 Type(Poi, Poa) 별 정보 가져오기
     * @param {KycDocumentGroup[]} kycDocumentGroups - 업로드 필요한 Kyc document group
     * @param {string} type - Kyc document Type(Poi, Poa)
     * @returns {*}
     */
    findKycDocumentGroup(kycDocumentGroups, type) {
      return kycDocumentGroups?.find(group => group.KycDocumentGroupType === type);
    },
    next(type) {
      this.$emit('update', type);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[kyc-ready-uk] {
  > p { .c(@c-w07); }
  .holder { .p(16); .mt(15); .bgc(@c-w01);
    h5 { .mb(8); .medium();
      [font-icon] { .fs(10, 16); .br(50); .bgc(@c-mint); .wh(16); .ib(); .tc(); .mr(4);}
    }
    > ul { .pl(20); .fs(14, 20);
      li { .flex(); .c(@c-w07);
        &:not(:nth-of-type(1)) { .mt(4); }
        &:before { content: '-'; .pr(10); }
        // span { .block(); .pl(16); } TODO : [ean] 원래 줄바꿈이였는데 우선 주석처리로 대응
      }
    }
    > span { .mt(12); .ml(20); .block(); .c(@c-w07); .fs(14, 20); }
  }
  .holder + .holder { .-t(#717171); .mt(0); }
  .message-holder { .flex(); .flex-ai(); .mb(24); .p(24); .br(8); .bgc(#333);
    > [font-icon] { .fs(24); .mr(8); }
    > div { word-break: break-word; .pre-line(); }
  }
}
</style>