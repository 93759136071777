<template>
  <div verify-citizenship>
    <VerifyCitizenshipInformation v-if="!customSteps" :model-info="modelInfo" :structure="structure" :item="citizenshipItem" />
    <template v-else>
      <component :model-info="modelInfo" :structure="structure" :is="component" :template="template" :item="citizenshipItem" :title="title" :button-name="buttonName" @upload="confirm" />
      <template v-if="!background">
        <p v-if="errorMsg" class="error" v-html="errorMsg" />
        <ProgressButton v-if="!isComplete" :button-name="buttonName" :progress="progress" :disabled="structure.error || Boolean(errorMsg) || invalid" @click="confirm" />
      </template>
    </template>
  </div>
</template>

<script>

import { state } from '@shared/utils/storeUtils.mjs';
import { checkClientPermission } from '@/utils/clientUtil';
import Specific from '@shared/types/Specific';
import baseTemplate from '@/mixins/baseTemplate';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import VerifyCitizenshipNames from '@/views/components/pages/on-boarding/template/verify-citizenship/VerifyCitizenshipNames.vue';
import VerifyCitizenshipBirth from '@/views/components/pages/on-boarding/template/verify-citizenship/VerifyCitizenshipBirth.vue';
import VerifyCitizenshipProofOfCitizenship from '@/views/components/pages/on-boarding/template/verify-citizenship/VerifyCitizenshipProofOfCitizenship.vue';
import VerifyCitizenshipInformation from '@/views/components/pages/on-boarding/template/verify-citizenship/VerifyCitizenshipInformation.vue';
import VerifyCitizenshipReviewingDocuments from '@/views/components/pages/on-boarding/template/verify-citizenship/VerifyCitizenshipReviewingDocuments.vue';

export default {
  name: 'VerifyCitizenship',
  lexicon: 'onBoarding.citizenship',
  components: { ProgressButton, VerifyCitizenshipInformation },
  mixins: [baseTemplate],
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      init: true,
      progress: false,
      templates: [
        { component: VerifyCitizenshipNames, route: routeNames.personalName, api: 'editPersonalName', title: 'onBoarding.title.personalDetails'},
        { component: VerifyCitizenshipBirth, route: routeNames.personalBirth, api: 'editPersonalBirth', title: 'onBoarding.title.personalDetails' },
        { component: VerifyCitizenshipProofOfCitizenship, route: routeNames.poc, api: '', title: 'onBoarding.title.proofCitizenship', background: true },
        { component: VerifyCitizenshipReviewingDocuments, route: routeNames.reviewingDocuments, api: '', title: 'onBoarding.title.proofCitizenship' },
      ],
    };
  },
  watch: {
    template() {
      this.item.title = this.title;
    }
  },
  computed: {
    env: state('env', 'env'),
    csEmail: state('env', 'csEmail'),
    step() {
      return this.structure.step;
    },
    citizenshipItem() {
      return this.structure?.list[this.step];
    },
    template() {
      return this.templates.find(o => o.route === this.customSteps?.[this.customStep]);
    },
    customStep() {
      return this.structure.customStep;
    },
    customSteps() {
      return this.structure.customSteps;
    },
    lastStep() {
      return this.customStep >= this.customSteps?.length - 1;
    },
    component() {
      return this.template?.component;
    },
    api() {
      return this.template?.api;
    },
    title() {
      return this.template?.title;
    },
    background() {
      return this.template?.background;
    },
    buttonName() {
      return this.lastStep ? 'submit' : 'next';
    },
    isProd() {
      return this.env === 'production';
    },
    isComplete() {
      return this.component === VerifyCitizenshipReviewingDocuments;
    },
  },
  methods: {
    nextStep() {
      this.processing = false;
      this.structure.customStep++;
    },
    async confirm() {
      this.processing = true;
      await this.apiRequest();
    },
    async apiRequest() {
      if (!this.api) {
        this.nextStep();
      } else {
        this.progress = true;
        const r = await this.getApiResponse();
        this.progress = false;
        if (r?.error) {
          if(this.$onboarding.errorHandler(r, true)) return;
          this.structure.error = true;
          this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});

          if (!this.isProd) {
            console.log(`Api Request Error : onboarding.${this.api}`, r);
            this.$toast(r.desc, { type: 'fail', translate: false });
          }
        } else {
          await this.nextStep();
        }
      }
    },
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding[this.api]({ ...this.structure.model }); }
      catch (e) { return e; }
    },
  },
  async mounted() {
    await checkClientPermission(this);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-citizenship] {
  p { .fs(16); }
  > .error { .mt(20); .c(@c-red;) }
  a[href^='mailto'] { .c(#2680EB); .underline(); }
}
</style>
