<template>
  <div date-selector :class="theme">
    <div v-if="m" class="date-selector-header">
      <button class="prev-button" v-if="step < 2 && isPrevGo" @click="move(-1)">
        <FontIcon name="angle-double-left" />
      </button>
      <span @click="click" :class="['yyyy-mm', {'click' : step < 2}]">{{ displayDate }}</span>
      <button class="next-button" v-if="step < 2 && isNextGo" @click="move(1)">
        <FontIcon name="angle-double-right" />
      </button>
    </div>
    <Calendar v-if="isCalendarStep" :month="month" :selectedLabel="selectedLabel" :min="minDate" :max="maxDate" :emphasis="emphasis" :selected="value" @select="onSelect" :theme="theme" />
    <div v-if="step === 1" class="month-list">
      <div :class="['month-item', {'active' : item.active, 'disabled' : item.disabled}]" v-for="item in monthData()" :key="item.value" @click.prevent="monthClick(item)">{{ $t(item.label) }}</div>
    </div>
    <div v-if="step >= 2" class="month-list">
      <div :class="['month-item', {'active' : item.active, 'disabled' : item.disabled}]" v-for="item in yearData()" :key="item.value" @click.prevent="yearClick(item)">{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
import { getM, getY, utc } from '@shared/utils/timeUtils.mjs';
import Calendar from '@shared/components/common/date/Calendar.vue';
import ComponentModel from '@shared/mixins/ComponentModel';
import moment from 'moment/moment';
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'DateSelector',
  components: { Calendar, FontIcon },
  mixins: [ComponentModel({
    event: 'select',
    update: 'updateModel',
  })],
  props: {
    value: {},
    rangeStart: String,
    rangeEnd: String,
    theme: String,
    emphasis: String,
    selectedLabel: String,
    min: Specific,
    max: Specific,
  },
  data() {
    return {
      selected: null,
      m: null,
      minM: null,
      maxM: null,
      month: null,
      year: null,
      step: 0,
      origin: null,
      originMonth: null,
      yearTitle: null,

      minMonth: null,
      maxMonth: null,

      today: null,
      todayMonth: null,
    };
  },
  computed: {
    displayDate() {
      const locale = this.$i18n('locale');
      const current = this.month;
      if (this.step === 0) {
        return moment(current).locale(locale).format('YYYY MMM');
      }
      if (this.step === 1) return moment(current).locale(locale).format('YYYY');
      if (this.step >= 2) return this.yearTitle;

    },
    isCalendarStep() {
      return this.step === 0;
    },
    currentDateMoment() {return this.m;},
    minDate() {return this.min;},
    maxDate() {return this.max;},
    birthList() {
      return [
        { label: 'jan', value: '01' }, { label: 'feb', value: '02' }, { label: 'mar', value: '03' }, { label: 'apr', value: '04' }, { label: 'may', value: '05' }, { label: 'jun', value: '06' },
        { label: 'jul', value: '07' }, { label: 'aug', value: '08' }, { label: 'sep', value: '09' }, { label: 'oct', value: '10' }, { label: 'nov', value: '11' }, { label: 'dec', value: '12' }
      ];
    },
    isPrevGo() {
      if (this.step === 0) {
        if (!this.month || !this.minMonth) return true;
        return this.month > this.minMonth;
      }
      if (this.step === 1) {
        if (!this.minM) return true;
        const minDateYear = getY(this.minM);
        return this.year > minDateYear;
      }
    },
    isNextGo() {
      if (this.step === 0) {
        if (!this.month || !this.maxMonth) return true;
        return this.month < this.maxMonth;
      }
      if (this.step === 1) {
        if (!this.maxM) return true;
        const maxDateYear = getY(this.maxM);
        return this.year < maxDateYear;
      }
    }
  },
  watch: {
    'step': function () {
      if (this.step < 2) this.yearTitle = null;
    }
  },
  methods: {
    updateModel() {
      this.m = moment(this.value || undefined);
      if (this.value) this.selected = this.m.format('YYYY-MM-DD');
      this.m.set({ date: 1 });
      this.month = this.m.format('YYYY-MM');
      this.year = this.m.format('YYYY');
    },
    move(d) {
      let unit = 'month';
      if (this.step === 1) unit = 'year';
      this.m = this.m.add(d, unit);
      this.month = this.m.format('YYYY-MM');
      this.year = this.m.format('YYYY');
    },
    onSelect(v) {
      this.$emit('select', v);
    },
    checkMove(d, unit) {
      let date = moment(this.m || undefined);
      date = date.add(d, unit);

      const dateMonth = +getM(date);
      const dateYear = +getY(date);
      if (this.min && (d < 0)) {
        const minDate = moment(this.min);
        const minMonth = +getM(minDate);
        const minYear = +getY(minDate);
      }
      // if (this.max && (d > 0)) {
      //   const maxDate = moment(this.max);
      //   if (maxDate < date) return false;
      // }
      return true;
    },
    monthData() {
      return this.birthList.map(v => {
        const month = getM(this.m);
        const year = getY(this.m);
        const active = `${year}-${v.value}` === this.originMonth;
        let disabled = false;
        const birthDate = `${year}-${v.value}`;
        if (this.minMonth && birthDate < this.minMonth) disabled = true;
        if (this.maxMonth && birthDate > this.maxMonth) disabled = true;
        return {
          ...v,
          active,
          disabled,
        };
      });
    },
    yearData() {
      const currentYear = getY(this.m);
      const minDateYear = this.minM ? getY(this.minM) : null;
      const maxDateYear = this.maxM ? getY(this.maxM) : null;

      const minYear = +currentYear - 5;
      const maxYear = +currentYear + 6;

      this.yearTitle = `${minYear} ~ ${maxYear}`;

      const arry = [];
      for (let i = minYear; i <= maxYear; i++) {
        const year = i.toString();
        const active = year === currentYear;
        let disabled = false;
        if (minDateYear && minDateYear > year) disabled = true;
        if (maxDateYear && maxDateYear < year) disabled = true;

        arry.push({ label: year, value: year, active, disabled });
      }
      return arry;
    },

    click() {
      this.step++;
      if (this.step >= 2) return;
    },
    monthClick(month) {
      if (!month) return;
      if (isNaN(month.value)) return;
      if (month.disabled) return;
      this.m.set({ month: +month.value - 1 });
      this.month = this.m.format('YYYY-MM');
      this.step = 0;

    },
    yearClick(year) {
      if (!year) return;
      if (isNaN(year.value)) return;
      if (year.disabled) return;
      this.m.set({ year: +year.value });
      this.month = this.m.format('YYYY-MM');
      this.step = 1;
    }
  },
  mounted() {
    this.minM = this.min ? moment(this.min || undefined) : null;
    this.maxM = this.max ? moment(this.max || undefined) : null;
    this.origin = moment(this.value || undefined);
    this.originMonth = this.origin.format('YYYY-MM');
    const today = moment();

    this.todayMonth = today.format('YYYY-MM');
    this.minMonth = this.minM ? this.minM.format('YYYY-MM') : null;
    this.maxMonth = this.maxM ? this.maxM.format('YYYY-MM') : null;
  }
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[date-selector] { .c(#333); .p(0, 5, 5, 5); cursor: default !important;
  .date-selector-header { .tc; .h(40); .fs(14, 36px); .mb(10); .rel; .pt(5);
    .yyyy-mm { .p(10);
      &.click {
        &:hover {.bgc(#F3FCF5);.pointer();}
      }
    }
    > * {.vam;}
    button {.abs; .pointer; .wh(24, 24); .t(14);
      &:hover {.bgc(#F3FCF5)}
      svg { .w(24);
        .stroke-target { stroke: #191919; }
      }
      &.prev-button {.l(14); }
      //&:nth-of-type(2) {.l(34); }
      //&:nth-of-type(3) {.r(34); }
      &.next-button {.r(14); }
    }
  }
  &.dark {
    .date-selector-header {
      > span { .c(white); }
      button {
        svg { .w(24);
          .stroke-target { stroke: white; }
        }
      }
    }
  }
  .month-list {.flex(); flex-wrap: wrap; .p(10); .w(100%); .space-between();
    .month-item {.h(50); .w(23%); .flex(); .justify-center(); .items-center();
      &:hover {.bgc(#F3FCF5)}
      &.disabled {background-color: transparent; color: #A1A1AA; cursor: default;}

      &.active {.bgc(#E6F3E8) !important;}
    }
  }
}
</style>

