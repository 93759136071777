import { makeRules } from '@/plugins/validate';
import { getYYYYMMDDTHHmmssZ, utc } from '@shared/utils/timeUtils.mjs';
import moment from 'moment';

const cnpCountries = { 1: '19', 2: '19', 3: '18', 4: '18', 5: '20', 6: '20' };
const cnpDateParser = (gender, date) => {
  const year = date.substr(0, 2);
  const month = date.substr(2, 2);
  const day = date.substr(4, 2);
  const isResident = +gender === 7 || +gender === 8;
  const fullYear = `${isResident ? year < new Date().getFullYear().toString().substr(2, 2) ? 20 : 19 : cnpCountries[gender]}${year}`; //isResident ? (year < new Date().getFullYear().toString().substr(2, 2) ? 20 : 19) + String(year) : cnpCountries[gender] +  year;
  const d = new Date(+fullYear, +month - 1, +day, 0, 0, 0, 0);

  return getYYYYMMDDTHHmmssZ(!makeRules(null).date(date) ? d : null);
};

export const getCnpInfo = value => {
  const genderType = +value.charAt(0) % 2;
  const gender = genderType === 1 ? 'Male' : genderType === 0 ? 'Female' : null;
  const foreign = +value.charAt(0) > 6;
  const date = cnpDateParser(value.charAt(0), value.substr(1, 6));
  return { gender, date, age: getAge(date), foreign };
};

const getAge = g => {
  const today = utc();
  const age = +today.year() - (+moment(g).year());
  const month = +today.month() - (+moment(g).month);

  return month < 0 || (month === 0 && utc(today) < utc(g)) ? age - 1 : age
}


export const getBirth = n => {
  const LENGTH = n.length;

  if (LENGTH < 9) return false;
  if (LENGTH === 10 && parseInt(n) % 11 !== 0) return false;

  let maxDay = 31;
  let year = parseInt(n.slice(0,2));
  let month = parseInt(n.slice(2, 4));
  let day = parseInt(n.slice(4,6));
  let gender = 'Male'

  if (month > 70)
  {
    month -= 70;
    gender = 'Female'
  }
  else if (month > 50)
  {
    month -= 50;
    gender = 'Female';
  }
  else if (month > 20)
  {
    month -= 20;
  }

  if (month < 1 || month > 12 ) return false;

  switch (month)
  {
    case 2:
      if (year % 4 === 0)
        maxDay = 29;
      else
        maxDay = 28;
      break;
    case 4:
    case 6:
    case 9:
    case 11:
      maxDay = 30;
      break;
    default:
      break;
  }

  if (day < 1 || day > maxDay) return false;

  let fullYear;
  if (LENGTH === 9) fullYear = 1900 + year;
  else if (LENGTH === 10)
  {
    if (year >= 54)
      fullYear = 1900 + year;
    else
      fullYear = 2000 + year;
  }

  const currentCzDate = getCzDate();

  if (fullYear > currentCzDate.getFullYear() - 18) return false;
  else if (fullYear === currentCzDate.getFullYear() - 18) {
    if (month > currentCzDate.getMonth() + 1)
      return false;
    else if (month === currentCzDate.getMonth() + 1) {
      if (day > currentCzDate.getDate())
        return false;
    }
  }
  return {year: String(fullYear), month: String(month).padStart(2,"0"), day: String(day).padStart(2,"0"), gender};
}

export const getCzDate = (v) => {
  const currentDate = new Date(v);
  const utcDate = currentDate.getTime() + (currentDate.getTimezoneOffset() * 60 * 1000);
  const CZECH_TIME_DIFF = 60 * 60 * 1000;
  return new Date(utcDate + CZECH_TIME_DIFF);
}
