<template>
  <ul description-holder v-if="list" :class="{transparent}">
    <li v-for="(item,id) in list" :key="id" v-html="item"/>
  </ul>
</template>

<script>
export default {
  name: 'DescriptionHolder',
  props: {
    list: /** @type string[]*/{type: Array, required: true },
    transparent: Boolean,
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[description-holder] { .bgc(#333); .-a(@c-w007); .flex-column(); .items-start(); .p(20); .gap(4); .mv(24);
  >li { .keep-all(); .pl(12); .rel(); .fs(14); .medium();
    &:before { .cnt(); .wh(4); .br(50%); .bgc(white); .abs(); .lt(0,10); }
  }
  &.transparent { .bgc(transparent); .-a(); .p(0); .mv(0);
    >li { .c(#7f7f7f);
      &:before { .bgc(#7f7f7f); }
    }
  }
}
</style>