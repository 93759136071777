<template>
  <div textarea-box :class="{ required, disabled, error }">
    <label>
      <span>{{label}}</span>
      <i v-if="required">*</i>
    </label>
    <div class="textarea-container">
      <textarea :placeholder="placeholder" :disabled="disabled" :maxlength="maxLength" :minlength="minLength" @change="$emit('change')" @input="input" />
      <p>{{length}} / {{maxLength}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextareaBox',
  props: {
    label:  { type: String, default: '' },
    required: { type: Boolean, default: false },
    value: { default: '' },
    maxLength: { type: Number, default: 100 },
    minLength: { type: Number, default: 0 },
    hideLength: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
  },
  data() {
    return {
      length: 0,
    };
  },
  methods: {
    input(e) {
      this.$emit('input', e.target.value);
      this.length = e.target.value.length;
    },
  }
}
</script>

<style lang="less">
@import '@/less/proj.less';
[textarea-box] { .rel(); .c(#000); .fs(14);
  &.required {
    label i { .c(#249135); .fs(12); .ml(4); }
  }
  label { .block(); .mb(4); .c(#737373); }
  .textarea-container { .crop(); .flex(); .flex-dc(); .pt(10); .bgc(#fff); .br(8); .min-h(80); }
  textarea { flex: 1; .block(); .f(); .ph(12); font: inherit;
    &::placeholder { .c(@c-placeholder); }
  }
  p { .b(0); .wf(); .c(#A1A1AA); .fs(12); .ph(12); .tr(); line-height: 1.2; .mb(4); }
}
</style>
