<template>
  <div v-if="searchConfig" rg-input-search-bar>
    <div class="select-group">
      <InputComponent name="_limitType" :value="limitType" :component="SearchDropSelect" :preset="limitTypePreset" @input="updateLimitType" />
      <InputComponent name="_selectDate" :value="dateType" :component="SearchDropSelect" :preset="datePreset" @input="changeDateType" />
      <div class="button-group">
        <a :class="['refresh-button', {'disable' : searchButtonDisabled}]" @click="searchClick">
          <FontIcon name="search" />
        </a>
      </div>
    </div>
    <div v-if="isCustomDate" class="custom-date-group">
      <InputComponent name="_customStartDate" v-model="startDate" @input="changeStartDate" :component="DatePicker" :preset="customDatePreset" />
      <InputComponent name="_customEndDate" v-model="endDate" @input="changeEndDate" :component="DatePicker" :preset="customEndDatePreset" />
    </div>
  </div>
</template>

<script>
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import Specific from '@shared/types/Specific';
import { limitTypes } from '@/constants/base/responsible-gaming';
import { getYYYYMMDDTHHmmssZ } from '@shared/utils/timeUtils.mjs';
import moment from 'moment/moment';
import FontIcon from '@shared/components/common/FontIcon.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import DatePicker from '@/views/components/gg-pass/DatePicker.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'InputSearchBar',
  components: { FontIcon, InputComponent },
  props: {
    model: Specific,
    // searchConfig: Specific,
  },
  data() {
    return {
      limitType: 'All',
      dateType: '7',
      searchConfig: null,
      startDate: null,
      endDate: null,

      TextInputBox,
      DatePicker,
      SearchDropSelect,
    };
  },
  computed: {
    site: state('env', 'site'),
    limitDefaultType() {
      return { label: this.$t('responsibleGaming.label.allType'), value: 'All' };
    },
    limitTypeList() {
      const extraLimitTypes = limitTypes.filter(type => {
        if (!this.searchConfig) return false;
        if (!this.searchConfig.hasOwnProperty(type.enable)) return false;
        return this.searchConfig[type.enable];
      }).map(type => {
        const label = type.label[this.site] || type.label.default;
        return { label: this.$t(`responsibleGaming.title.${label}`), value: type.value };
      });

      return [this.limitDefaultType, ...extraLimitTypes];
    },

    dateTypeList() {
      return [
        { label: this.$t('responsibleGaming.label.last7Days'), value: '7' },
        { label: this.$t('responsibleGaming.label.last30Days'), value: '30' },
        { label: this.$t('responsibleGaming.label.last90Days'), value: '90' },
        { label: this.$t('responsibleGaming.label.last180Days'), value: '180' },
        { label: this.$t('responsibleGaming.label.customRange'), value: 'custom' },
      ];
    },
    searchButtonDisabled() {
      if (!this.isCustomDate) return false;
      else {
        if (!this.startDate || !this.endDate) return true;
        if (this.startDate > this.endDate) return true;

        return false;
      }
    },
    limitTypePreset() {
      return { placeholder: this.$t('responsibleGaming.label.allType'), list: this.limitTypeList, translate: false, auto: true, selectOnly: true, parentFill: true, };
    },
    datePreset() {
      return { placeholder: this.$t('responsibleGaming.label.last7Days'), list: this.dateTypeList, translate: false, auto: true, selectOnly: true, };
    },
    isCustomDate() {
      return this.dateType === 'custom';
    },
    customDatePreset() {
      const minDate = moment().subtract(180, 'days').format('YYYY-MM-DD');
      const maxDate = this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      return { type: 'date', placeholder: this.$t('startDate'), min: minDate, max: maxDate };
    },
    customEndDatePreset() {
      const minDate = this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : moment().subtract(30, 'days').format('YYYY-MM-DD');
      const maxDate = moment().format('YYYY-MM-DD');
      return { type: 'date', min: minDate, max: maxDate, placeholder: this.$t('endDate') };
      // return { type: 'date', placeholder: this.$t('endDate') };
    }
  },
  methods: {
    async initialize() {
      const r = await this.$services.account.getRgConfig();
      this.searchConfig = r;
    },
    changeDateType(v) {
      if (!v) return;
      const beginDate = getYYYYMMDDTHHmmssZ(moment().subtract(v?.value, 'day').format('YYYY-MM-DD'));
      const endDate = getYYYYMMDDTHHmmssZ(moment().format('YYYY-MM-DD'));
      this.dateType = v?.value;
      if (v?.value !== 'custom') {
        this.model.DateBegin = beginDate;
        this.model.DateEnd = endDate;
        this.startDate = null;
        this.endDate = null;
      } else {
        this.model.DateBegin = this.startDate ? getYYYYMMDDTHHmmssZ(this.startDate) : null;
        this.model.DateEnd = this.endDate ? getYYYYMMDDTHHmmssZ(this.endDate) : null;
      }
    },
    updateLimitType(v) {
      if (!v) return;
      this.limitType = v?.value;
      if (v?.value === 'All') {
        this.model.LimitType = null;
      } else {
        this.model.LimitType = v?.value;
      }
    },
    changeStartDate(v) {
      if (!v) return;
      this.startDate = v;
      this.model.DateBegin = getYYYYMMDDTHHmmssZ(v);
    },
    changeEndDate(v) {
      if (!v) return;
      this.endDate = v;
      this.model.DateEnd = getYYYYMMDDTHHmmssZ(v);
    },
    searchClick() {
      if (this.searchButtonDisabled) return;
      this.$emit('click');
    }
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-input-search-bar] { .bgc(#f8f8f8); .mt(10); .flex(); .flex-dc(); .gap(10);
  //.select-group {.flex(); .flex-dc();.gap(10);
  //  > div {flex: 1;}
  //}
  .button-group {.pt(5);}
  .select-group {.flex(); .gap(10); .items-center(); .w(100%);
    > [gp-input-component] {.w(calc(50% - 25px));}
    .refresh-button {.wh(40); .fs(16); .c(@gp-green); .flex(); .justify-center(); .items-center(); .-a(@gp-green); .br(8); .bgc(#fff);
      &.disable { .-a(#A3A3A3); cursor: not-allowed;
        [font-icon] {.fs(16); .c(#A3A3A3);}
      }
      [font-icon] {.fs(16); .c(@gp-green);}
    }
  }
  .custom-date-group {.flex(); .flex-dr(); .gap(10);
    > div {flex: 1;}
  }
  @media (@ml-up) {
    .select-group {.flex-dr();.gap(10); flex: 1;}
    .custom-date-group {.flex-dr(); .gap(10);}
  }
}
</style>
