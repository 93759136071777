import Specific from '@shared/types/Specific';
export default {
  props: {
    disabled: { type: Boolean, default: null },
    itemInfo: {type: Specific, default: null},
    modelInfo: { type: Specific, default: null },
    structure: { type: Specific, default: null },
    item: {type: Specific, default: null},
  },
  computed: {
    errorMsg() {
      return /** @type {string} */this.structure?.errorMsg;
    },
  },
}