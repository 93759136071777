import { err } from '@shared/utils/commonUtils.mjs';

class ClickOutside {
  /** @type {Document} */
  #document;
  /** @type {Map<HTMLElement, {click: function, mousedown: function, mouseup: function}>} */
  #listeners = new Map();
  /** @type {Map<HTMLElement, {firstClick: boolean, moved: boolean, startX?: number, startY?: number, dragged: boolean}>} */
  #stateMap = new Map();
  /** @type {Map<HTMLElement, any>} */
  #bindingMap = new Map();

  constructor() {
    return this;
  }

  /** @description binding 의 유효성 검사 */
  get bindingValidator() {
    return {
      data: [
        value => typeof value !== 'function' ? err(`ClickOutSide -> inValid binding: ${value}`) : undefined,
      ],
      validate(binding) {
        const { value } = binding;
        this.data.forEach(fn => fn(value));
      }
    };
  }

  setBinding(el, binding) {
    this.bindingValidator.validate(binding);
    this.#bindingMap.set(el, binding);
    return this;
  }

  /**
   * @param {Document} document
   * @returns {ClickOutside}
   */
  setDocument(document) {
    this.#document = document;
    return this;
  }

  /**
   * @description 해당 엘리먼트 안을 클릭했는지 검사
   * @param {HTMLElement} el
   * @param {MouseEvent} e
   * @returns {boolean}
   */
  checkClickedInside(el, e) {
    const { target } = e;
    return el.contains(target);
  }

  /**
   * @param {HTMLElement} el
   * @param {MouseEvent} e
   */
  clickEvent(el, e) {
    switch (true) {
      case !this.#stateMap.get(el).firstClick:
        /** @description 첫번째 클릭은 무시해야함 */
        this.#stateMap.get(el).firstClick = true;
        break;
      case this.#stateMap.get(el).dragged: /** @description 만약 내부에서 드레그를 후 최종 mouseUp이 target 밖에 있을때 */
        break;
      case this.checkClickedInside(el, e): /** @description 내부를 클릭했을때 */
        break;
      default:
        /** @description 위의 케이스가 아니라면 binding function 실행 */
        this.#bindingMap.get(el).value();
        break;
    }
  }

  /**
   * @description 클릭 시작하는 점의 좌표를 계산
   * @param {HTMLElement} el
   * @param {MouseEvent} e
   */
  mouseDownEvent(el, e) {
    this.#stateMap.get(el).dragged = false;
    this.#stateMap.get(el).startX = e.pageX;
    this.#stateMap.get(el).startY = e.pageY;
    this.clickEvent(el,e);
  }

  /**
   * @description 마우스 업과 마우스 다운의 좌표를 계산하여 drag 인지 판별
   * @param {HTMLElement} el
   * @param {MouseEvent} e
   */
  mouseUpEvent(el, e) {
    if (!this.#stateMap.get(el).startX && !this.#stateMap.get(el).startY) return;
    /** @description 조정 값 */
    const delta = 4;
    const diffX = Math.abs(e.pageX - this.#stateMap.get(el).startX);
    const diffY = Math.abs(e.pageY - this.#stateMap.get(el).startY);
    if (diffX > delta && diffY > delta) this.#stateMap.get(el).dragged = true;
  }

  /**
   * @param {HTMLElement} el
   * @returns {ClickOutside}
   */
  setEvent(el) {
    this.#listeners.set(el, {
      // click: this.clickEvent.bind(this, el),
      mousedown: this.mouseDownEvent.bind(this, el),
      mouseup: this.mouseUpEvent.bind(this, el)
    });
    this.#stateMap.set(el, { firstClick: false, moved: false, startX: undefined, startY: undefined, dragged: false });
    Object.entries(this.#listeners.get(el)).forEach(([key, value]) => {
      this.#document.querySelector('body').addEventListener(key, value);
    });
    return this;
  }

  /**
   * @param {HTMLElement} el
   * @return {ClickOutside}
   */
  destroy(el) {
    Object.entries(this.#listeners.get(el)).forEach(([key, value]) => {
      this.#document.querySelector('body').removeEventListener(key, value);
    });
    this.#listeners.delete(el);
    this.#stateMap.delete(el);
    this.#bindingMap.delete(el);
    return this;
  }
}

const clickOutside = new ClickOutside();

export default {
  bind(el, binding) {
    clickOutside
      .setBinding(el, binding)
      .setDocument(document)
      .setEvent(el);
  },
  unbind(el) {
    clickOutside.destroy(el);
  }
};
