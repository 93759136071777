<template>
  <div bonus-item :class="['bonusType', { active }, bonusType]">
    <div class="container">
      <div class="info">
        <img :src="`/img/promotion/${bonusType}${active ? '-active' : ''}.png`" :alt="info.type">
        <p v-if="info.quantity" class="quantity">{{ info.quantity }} x</p>
        <p v-if="info.value" class="value">{{ getCurrencyString(info.currencyId, info.value) }}</p>
      </div>
      <p class="item-name">{{ itemName }}</p>
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';

export default {
  name: 'BonusItem',
  props: {
    info: Specific,
    active: { type: Boolean, default: false },
  },
  computed: {
    bonusType() {
      const type = this.info?.type;
      const images = {
        TICKET: 'tickets',
        GCD: 'gcd',
        GTD: 'gtd',
        GPD: 'gpd',
        CASH: 'cash',
        CASINO_BONUS: 'casino-bonus',
        FREE_SPIN: 'spin',
      };
      return images[type] || 'tickets';
    },
    itemName() {
      return this.info?.ticketName;
    },
  },
  methods: {
    getCurrencyString(currency, value) {
      return this.$promotion.getCurrencyString(currency, value);
    },
  },
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[bonus-item] { .flex(); .flex-dc(); .gap(20); .c(#888); .fs(12); .tc(); .lh(1.5em);
  &.active {
    &.tickets { .c(#a84823); }
    &.gcd { .c(#d1c350); }
    &.gtd { .c(#d47527); }
    &.gpd { } // [celina] 디자인 필요
    &.cash { .c(#efb840); }
    &.casino, &.spin { .c(#c1ac80); }
    .info { .bgc(#242424); }
    .quantity { .c(@c-white); }
    .item-name { .c(@c-gray2); }
  }
  .info { .rel(); .ib(); .min-w(7.4em); .ml(1.7em); .-a(@c-gray6); .br(4); .bgc(#1a1a1a); .semi-bold();
    img { .abs(); .lt(0, 50%); .w(3.5em); .h(); .t-xyc(); }
    p { .pl(20%); }
    p + p { .-t(@c-gray6); }
  }
  &.tickets, &.spin {
    .info { .ml(1.9em);
      img { .w(3.8em); }
    }
  }
  &.casino-bonus .info { .ml(1.8em);
    img { .w(4.1em); }
  }
  .item-name { .min-h(16); .mt(0.8em); .fs(1em); .lh(1.3em); .c(@c-gray3); }
}
</style>
