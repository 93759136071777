<template>
  <div toast-container>
    <div v-for="toast in toastStack" :key="toast.id" :style="{transform: `translateY(${toast.bottom}) translateX(-50%)`}" :class="[toast.type, toast.out ? 'out' : null]" @click="remove(toast.id)">
      <FontIcon name="circle-check" v-if="toast.type === 'success'" />
      <FontIcon name="circle-xmark" v-else />
      <div v-html="toast.message" />
    </div>
  </div>
</template>

<script>

import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'ToastContainer',
  components: { FontIcon },
  data() {
    return {
      seq: 0,
      toastStack: [],
      intervalId: -1,
      fullscreen: false,
      fullscreenStyle: {},
      toastHolder: null,
    };
  },
  methods: {
    toast(message, { type = 'success', dura = process.env.VUE_APP_TOAST_DURATION } = {}) {
      this.toastStack.unshift({ message, type, life: dura * 10 + 5, id: this.seq++, out: false });
      if (this.intervalId === -1) this.intervalId = setInterval(this.tick, 100);
    },
    remove(id) {
      this.toastStack = this.toastStack.filter(t => t.id !== id);
      this.emptyCheck();
    },
    tick() {
      this.toastStack.forEach((t, i) => {
        t.life -= 1;
        t.bottom = `${i * 150}%`;
        if (t.life < 5) t.out = true;
      });
      this.toastStack = this.toastStack.filter(t => t.life > 0);
      this.emptyCheck();
    },
    emptyCheck() {
      if (this.toastStack.length === 0) {
        clearInterval(this.intervalId);
        this.intervalId = -1;
      }
    },
    fullscreenChanged() {
      const fullscreen = !!document.fullscreenElement;
      if (this.fullscreen === fullscreen) return;
      /** @type {HTMLElement} */
      const el = this.$el;
      if (fullscreen) {
        document.fullscreenElement.appendChild(el);
      } else {
        this.toastHolder.$el.appendChild(el);
      }
      this.fullscreen = fullscreen;
    },
    init(holder) {
      this.toastHolder = holder;
      this.fullscreenChanged();
      document.addEventListener('fullscreenchange', this.fullscreenChanged);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

.GOLD {
  [toast-container] {
    > div {
      &.success { .bgc(@c-gold); }
    }
  }
}
[toast-container] { .fix; .wf; .lb(0, 20); .z(10000); .fs(14); .medium();
  > div { .abs; .lb(50%, 0); .wh(90%, auto); .o(1); .min-h(30); .flex-center; .tr-to(0.5s); .t-xy(-50%, 150%); .p(18, 0); .c(white); .br(30);
    [font-icon] { .ib(); .mr(4);}
    &.success { .bgc(@c-secondary); }
    &.fail { .bgc(@c-red); .c(white);
      * { .ib() !important;}
    }
    &.out { .o(0); }
    [font-icon] { .ib; .vam; .fs(20); .mr(5); .c(white);}
    span { .ib; .vam; }
  }
}
</style>
