<template>
  <div rg-display-cancel-modal>
    <GpDescription :message="description" />
    <div class="body">
      <p class="sub-title">{{ subTitle }}</p>
      <div v-if="displayList" class="content">
        <div class="item-body" v-for="(item, idx) in displayList" :key="idx">
          <div class="label">{{ item.label }}</div>
          <div class="row-content">
            <section class="row">
              <label class="row-title">{{ $t('_.currentLimit') }}</label>
              <label class="amount">{{ item.currentValue }}</label>
            </section>
            <section class="row">
              <label class="row-title">{{ $t('_.requestLimit') }}</label>
              <label class="amount">{{ item.requestValue }}</label>
            </section>
            <section class="row">
              <label class="row-title">{{ $t('_.effectiveDate') }}</label>
              <label class="amount">{{ `${item.effectiveDate}${item.showUtc ? `(${config.timeZoneType})` : ''}` }}</label>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import Specific from '@shared/types/Specific';
import moment from 'moment/moment';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'DisplayCancelModal',
  lexicon: 'responsibleGaming.modal.label',
  components: { GpDescription },
  props: {
    title: { type: String, default: null },
    subTitle: { type: String, default: null },
    calculateList: { type: Specific, default: null },
    descModel: { type: Specific, default: null },
    isVerifyFreeze: { type: Boolean, default: false },
    config: { type: Specific, default: null },
  },
  data() {
    return {
      displayList: null,
      descType: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    description() {
      return this.$t(this.descModel.cancelPreviewDescription);
    }
  },
  methods: {
    initialize() {
      if (!this.calculateList) {
        this.displayList = [];
      }
      const calculateResults = this.calculateList.map(item => this.createDisplayItem(item));
      const list = calculateResults.filter(x => x !== null && x.currentValue !== x.requestValue);

      this.displayList = list;
    },

    createDisplayItem(item) {
      const { type } = item;
      let resultItem = null;
      if (type === 'poker' || type === 'casino' || type === 'liveCasino') {
        resultItem = this.$rg.calculateGameHangTime(item.type, item.current, item.request);
      } else {
        resultItem = this.$rg.calculateLimitHangTime(item.type, item.valueType, item.noLimit, item.current, item.request, this.isVerifyFreeze);
      }

      return {
        ...resultItem,
        label: this.$t(this.getLabelKey(item)),
        currentValue: this.createDisplayValue(item?.valueType, resultItem?.currentValue, item?.currencySymbol),
        requestValue: this.createDisplayValue(item?.valueType, resultItem?.requestValue, item?.currencySymbol),
        ...this.getDateInfo(item.limitApplyAt)
      };
    },

    getDateInfo(time) {
      if (!time || (this.isVerifyFreeze && time === 'verifyFreeze')) return { effectiveDate: '-', showUtc: null }; // TODO : [ean] 향후 showUtc: false 로 변경
      return { effectiveDate: moment(time).locale(this.$i18n('locale')).format('MMM DD, YYYY HH:mm:ss'), showUtc: null }; // TODO : [ean] 향후 showUtc: true 로 변경
    },

    getLabelKey(item) {
      const { rolling, type } = item;
      const isRolling = rolling === 'Rolling';
      const prefix = 'onBoarding.setting';

      switch (type) {
        case 'daily':
          return isRolling ? `${prefix}.24hours` : 'daily';
        case 'weekly':
          return isRolling ? `${prefix}.7Days` : 'weekly';
        case 'monthly':
          return isRolling ? `${prefix}.30Days` : 'monthly';
        case 'poker':
          return 'responsibleGaming.label.poker';
        case 'casino':
          return 'responsibleGaming.label.casino';
        case 'liveCasino':
          return 'responsibleGaming.label.liveCasino';
        default:
          return null;
      }
    },

    createDisplayValue(type, value, currencySymbol = '') {
      if (!value && type !== 'game') return this.$t('noLimit');
      if (value === 'None' && type !== 'game') return this.$t('noLimit');
      let v = value;
      if (!isNaN(value)) v = +value;

      switch (type) {
        case 'amount': {
          return `${currencySymbol} ${v}`;
        }
        case 'hr': {
          return `${v} ${this.$t('hr')}`;
        }
        case 'min':
          return `${v} ${this.$t('minute')}`;
        case 'times': {
          return `${v} times`;
        }
        case 'game': {
          return !v ? this.$t('responsibleGaming.label.noRestrictions') : this.$t('responsibleGaming.label.restricted');
        }
        default: {
          return null;
        }
      }
    },
  },
  mounted() {
    this.initialize();
  }
};

</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-display-cancel-modal] {overflow-y: auto;.h(100%);
  [gp-description] {
    > p {line-height: 20px; .tc(); .ph(40);}
  }
  .sub-title {.c(#000); .fs(14); font-weight: 500; text-align: center; .p(0, 20, 16, 20);}
  .body {
    .content {.flex(); .flex-dc(); .gap(20); }
    .item-body {.flex(); .flex-dc(); .gap(5);
      .label {.fs(14); font-weight: 500; .c(#000);}
      .row-content {.bgc(#fff); .p(10, 20, 10, 20); .br(8); .flex(); .flex-dc(); .gap(10);
        .row {.flex(); .space-between(); font-weight: 400; .fs(14);
          .row-title {.block(); .c(#737373);}
          .amount {.block(); .c(#000); }
        }
      }
    }
  }
}
</style>