<template>
  <div input-monthly-login-frequency>
    <InputComponent :label="$t('setLimit')" name="_monthlyLoginFrequency" :component="SearchDropSelect" :value="model.RequestMonthlyLoginFrequency" :preset="preset" @input="input" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import DropSelect from '@shared/components/common/input/DropSelect.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';

export default {
  name: 'InputMonthlyLoginFrequency',
  lexicon: 'onBoarding.setting',
  components: { InputComponent },
  props: {
    model: Specific,
    current: Specific,
    currencySymbol: { type: String, default: null }
  },
  data() {
    return {
      SearchDropSelect
    };
  },
  computed: {
    preset() { return { list: this.timeList, auto: true, translate: false, selectOnly: true, }; },
    timeList() {
      return [
        { label: this.$t('noLimit'), value: 'None' },
        { label: '100', value: '100' },
        { label: '50', value: '50' },
        { label: '30', value: '30' },
        { label: '10', value: '10' },
        { label: '5', value: '5' },
        { label: '1', value: '1' },
      ];
    }
  },
  methods: {
    input(v) {
      if (!v) return;
      this.model.RequestMonthlyLoginFrequency = v?.value === 'None' ? null : v?.value;
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-monthly-login-frequency] {.flex(); .flex-dc(); .br(16); .bgc(#EBEBEF); .p(20); }
</style>