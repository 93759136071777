<template>
  <div search-loqate-address>
    <template>
      <div class="desc lg" v-if="useGuide1 || useGuide2">
        <FontIcon name="exclamation-circle-regular" />
        <div>
          <span v-if="useGuide1" v-html="$t('_.personalAddressSearchDescription1', {csEmail})" />
          <template v-if="useGuide2">
            <span v-html="$t('_.personalAddressSearchDescription2', {csEmail})"></span>
            <span>(e.g., "10-123 Main Street")</span>
          </template>
        </div>
      </div>
    </template>
    <template v-if="!searchInfo">
      <ValidationComponent :label="$t('_.searchAddress')" name="_address" :component="AddressSearchSelect" rules="required" v-model="searchInfo" :preset="addressPreset" />
    </template>
    <template v-if="useSub">
      <a class="btn-update-field" @click="reset">
        <FontIcon name="chevron-left" />
        {{$t('_.personalAddressAddressReset')}}
      </a>
      <div class="desc lg">{{ searchInfo.selectedItem.label }}</div>
      <div>
        <ValidationComponent :label="$t('_.subSearchAddress')" name="_address" :component="AddressSearchSelect" rules="required" v-model="searchSubInfo" :preset="subAddressPreset" />
      </div>
    </template>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import AddressSearchSelect from '@/views/components/pages/on-boarding/AddressSearchSelect.vue';
import SvgIcon from '@shared/components/common/SvgIcon.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'SearchLoqateAddress',
  lexicon: 'personalDetails',
  components: { FontIcon, ValidationComponent },
  props: {
    useGuide1: { type: Boolean, default: true },
    useGuide2: { type: Boolean, default: true },
    value: { type: Boolean },
  },
  data() {
    return {
      placeholder: '',
      subPlaceholder: '',
      searchInfo: null,
      searchSubInfo: null,
      AddressSearchSelect,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    addressPreset() {
      return {placeholder: this.placeholder, maxLength: 100, type: 'loqate-address'};
    },
    subAddressPreset() {
      return {placeholder: this.subPlaceholder, maxLength: 100, type: 'loqate-sub-address'};
    },
    useSub() {
      const type = this.searchInfo?.selectedItem?.Type;
      return type && type !== 'Address';
    }
  },
  watch: {
    searchInfo: 'updateValue',
    searchSubInfo: 'updateSubValue',
  },
  methods: {
    /**
     * 초기 진입 시 placeHolder 설정
     */
    initialize() {
      this.placeholder = this.$t('_.personalAddressSearchPlaceHolder');
      this.subPlaceholder = this.$t('_.personalAddressSearchPlaceHolder');
    },
    /**
     * 검색 초기화
     */
    reset() {
      this.$emit('reset', true);
      this.$emit('selected', null);

      this.resetInfo();
    },
    /**
     * 검색 정보 초기화
     */
    resetInfo() {
      this.searchInfo = null;
      this.searchSubInfo = null;
    },
    /**
     * 검색된 메인 주소 DropListBox에서 결과 선택 시 호출
     */
    updateValue() {
      if (!this.searchInfo?.selectedItem) return;

      const { selectedItem, sessionToken } = this.searchInfo;
      const { Id, Text, Description } = selectedItem;
      this.placeholder = `${Text} ${Description}`;

      this.$emit('reset', this.useSub);
      this.$emit('selected', this.searchInfo);

      if (this.useSub) {
        this.$block();
        this.searchSubInfo = { AddressId: Id, SessionToken: sessionToken };
      }
      else this.resetInfo();
    },
    /**
     * 검색된 서브 주소 DropListBox에서 결과 선택 시 호출
     */
    updateSubValue() {
      if (!this.searchSubInfo?.selectedItem) return;

      this.$emit('reset', false);
      this.$emit('selected', this.searchSubInfo);

      this.resetInfo();
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[search-loqate-address] {
  > .desc.lg { .flex(); .mb(24); .fs(16, 20) !important; .c(#d8d8d8) !important;
    [font-icon] { .mr(12); .fs(24); .c(#919597); }
    a { .c(#2D74F6); }
    span { .block(); }
    span + span { .mt(8); }
  }
  > .btn-update-field { .mb(12); .inline-flex(); .items-center(); .fs(16); .c(#05A0CE); .-b(#05A0CE);
    &:hover { .c(#05A0CE); .-b(@black-bg); .tr-d(.3); }
    > [font-icon] { .fs(8); .bold(); .mr(4); }
  }
}
</style>