<template>
  <div floating-debug-button>
    <ColorButton type="dark-line" @click="debug">{{ `${this.service} : v${version}` }}</ColorButton>
  </div>
</template>
<script>

import ColorButton from '@shared/components/common/ColorButton.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { webviewDebugger } from '@/utils/clientUtil';

export default {
  name: 'FloatingDebugButton',
  components: { ColorButton },
  computed: {
    env: state('env', 'env'),
    app: state('query', 'app'),
    service() {
      switch (this.app) {
        case 'onboarding':
          return 'onboarding';
        case 'myinfo':
          return 'my info';
        case 'fi':
          return 'find ggpass id';
        case 'fp':
          return 'forgot password';
        case 'rg':
          return 'responsible gaming';
        case 'promotion':
          return 'promotion';
        case '':
          return 'error';
        default:
          return 'signup';
      }
    },
    version() {
      return process.env.VUE_APP_VERSION;
    }
  },
  methods: {
    debug() {
      webviewDebugger(this);
    },
  }
};
</script>

<style lang="less">
@import "@/less/proj.less";
[floating-debug-button] { .z(10); .flex(); .fix(); .w(auto) !important; .lb(30, 30);
  > [color-button] + [color-button] { .ml(10); }
}
</style>
