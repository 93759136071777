<template>
  <div my-info>
    <div v-if="isFetched && config" v-for="(item, idx) in list" class="info-router-group" :key="idx">
      <h3>{{ $t(item.title) }}</h3>
      <MenuLinkButton v-for="child in item.children" @click="move(child)" :name="getItemLabel(child.label)" :is-badge="hasItemBadge(child)" :badge-type="badgeType(child)" :hidden-right="(structure.isSelfExclusion || hasRightButton(child)) && !isPointer(child)" />
    </div>
  </div>
</template>

<script>
import { getSiteList, routeNames } from '@/constants/model/responsible-gaming';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import MenuLinkButton from '@/views/components/gg-pass/MenuLinkButton.vue';
import { kycVerified, useLugasSiteIds } from '@/constants/base/responsible-gaming';
import ResponsibleProgressModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleProgressModal.vue';
import { siteIds } from '@/constants/base/siteMap';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';

export default {
  name: 'RgInfo',
  lexicon: 'responsibleGaming',
  components: { MenuLinkButton },
  props: {
    config: Specific,
    structure: Specific,
  },
  data() {
    return {
      list: [],
      rolling: null,
      currencySymbol: null,
      isFetched: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    isEnabledLugas() {
      return useLugasSiteIds.indexOf(this.site) >= 0;
    },
    isKycVerified() {
      const { KycStatus } = this.userInfo;
      return kycVerified.indexOf(KycStatus) >= 0;
    },
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
  },
  methods: {
    async initialize() {
      this.$rg.updateRouteName('RgInfo');
      this.list = getSiteList(this.config, (this.isEnabledLugas && !this.isKycVerified));
      this.isFetched = true;
    },
    move(item) {
      if (this.structure.isSelfExclusion) return;
      const requested = item.requestKey ? this.config[item.requestKey] : false;
      const verifyFreeze = item.verifyFreezeKey ? this.config[item.verifyFreezeKey] : false;
      if (requested && verifyFreeze) {
        if (this.isGGPNL) this.openModal(item);
      } else this.replaceRouteName(item.name);
    },
    getItemLabel(item) {
      let defaultString = '_.title.';
      if (item.hasOwnProperty(this.site)) {
        defaultString += item[this.site];
      } else defaultString += item.default;

      return this.$t(defaultString);
    },
    hasItemBadge(item) {
      const requested = item.requestKey !== '' ? this.config[item.requestKey] : false;
      return requested;
    },
    hasRightButton(item) {
      const requested = item.requestKey ? this.config[item.requestKey] : false;
      const verifyFreeze = item.verifyFreezeKey ? this.config[item.verifyFreezeKey] : false;
      if (requested && verifyFreeze) return true;
      return false;
    },
    badgeType(item) {
      const requested = item.requestKey !== '' ? this.config[item.requestKey] : false;
      const verifyFreeze = item.verifyFreezeKey !== '' ? this.config[item.verifyFreezeKey] : false;

      if (!verifyFreeze && requested) return 'REQUEST';
      else if (verifyFreeze && requested) return 'APPROVAL';
    },
    isPointer(item) {
      return this.isGGPNL && this.badgeType(item) === 'APPROVAL' && this.isHangtimeRouter(item);
    },
    isHangtimeRouter(item) {
      return [routeNames.RgMyDepositLimit, routeNames.RgMyTotalBetAmountLimit, routeNames.RgMySingleBetAmountLimit, routeNames.RgMyLossLimit, routeNames.RgMyBalanceLimit, routeNames.RgMyLoginTimeLimit, routeNames.RgMyReLoginTimeLimit, routeNames.RgMyMonthlyLoginFrequency, routeNames.RgMyGameLimit].includes(item.name);
    },
    async createModalOptions(item) {
      const pageModel = this.$rg.getPageModel(item.name);
      const limitType = pageModel.type || '';
      const rolling = this.config[pageModel.rolling] || '';
      let isVerifyFreeze = this.config[pageModel.verifyFreezeKey] || false;
      // const isVerifyFreeze = this.isGGPNL ? false : (this.config[pageModel.verifyFreezeKey] || false);

      let r;
      let calculateList;
      let currencySymbol;
      switch (item.name) {
        case routeNames.RgMyDepositLimit:
          r = await this.$services.account.getDepositLimit();
          if (await this.$rg.errorRouteReplace(r)) return;

          isVerifyFreeze = this.isGGPNL ? false : (this.config[pageModel.verifyFreezeKey] || false);
          currencySymbol = getCurrencySymbol(r.Currency);
          calculateList = [
            { type: 'monthly', current: r.CurrentMonthlyDepositLimit, request: r.RequestMonthlyDepositLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.MonthlyDepositLimitApplyAt, valueType: 'amount', currencySymbol, rolling },
            { type: 'weekly', current: r.CurrentWeeklyDepositLimit, request: r.RequestWeeklyDepositLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.WeeklyDepositLimitApplyAt, valueType: 'amount', currencySymbol, rolling },
            { type: 'daily', current: r.CurrentDailyDepositLimit, request: r.RequestDailyDepositLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.DailyDepositLimitApplyAt, valueType: 'amount', currencySymbol, rolling },
          ];

          break;
        case routeNames.RgMyTotalBetAmountLimit:
          r = await this.$services.account.getTotalBetLimit();
          if (await this.$rg.errorRouteReplace(r)) return;

          currencySymbol = getCurrencySymbol(r.Currency);
          calculateList = [
            { type: 'monthly', current: r.CurrentMonthlyTotalBetLimit, request: r.RequestMonthlyTotalBetLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.MonthlyTotalBetLimitApplyAt, valueType: 'amount', currencySymbol, rolling },
            { type: 'weekly', current: r.CurrentWeeklyTotalBetLimit, request: r.RequestWeeklyTotalBetLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.WeeklyTotalBetLimitApplyAt, valueType: 'amount', currencySymbol, rolling },
            { type: 'daily', current: r.CurrentDailyTotalBetLimit, request: r.RequestDailyTotalBetLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.DailyTotalBetLimitApplyAt, valueType: 'amount', currencySymbol, rolling },
          ];
          break;
        case routeNames.RgMySingleBetAmountLimit:
          r = await this.$services.account.getSingleBetLimit();
          if (await this.$rg.errorRouteReplace(r)) return;

          currencySymbol = getCurrencySymbol(r.Currency);
          calculateList = [
            { type: 'default', current: r.CurrentSingleBetLimit, request: r.RequestSingleBetLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.SingleBetLimitApplyAt, noLimit: !r.RequestSingleBetLimit, valueType: 'amount', currencySymbol },
          ];
          break;
        case routeNames.RgMyLossLimit:
          r = await this.$services.account.getLossLimit();
          if (await this.$rg.errorRouteReplace(r)) return;

          currencySymbol = getCurrencySymbol(r.Currency);
          calculateList = [
            { type: 'monthly', current: r.CurrentMonthlyLossLimit, request: r.RequestMonthlyLossLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.MonthlyLossLimitApplyAt, valueType: 'amount', currencySymbol, rolling },
            { type: 'weekly', current: r.CurrentWeeklyLossLimit, request: r.RequestWeeklyLossLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.WeeklyLossLimitApplyAt, valueType: 'amount', currencySymbol, rolling },
            { type: 'daily', current: r.CurrentDailyLossLimit, request: r.RequestDailyLossLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.DailyLossLimitApplyAt, valueType: 'amount', currencySymbol, rolling },
          ];
          break;
        case routeNames.RgMyBalanceLimit:
          r = await this.$services.account.getBalanceLimit();
          if (await this.$rg.errorRouteReplace(r)) return;

          currencySymbol = getCurrencySymbol(r.Currency);
          calculateList = [
            { type: 'default', current: r.CurrentBalanceLimit, request: r.RequestBalanceLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.BalanceLimitApplyAt, noLimit: !r.RequestBalanceLimit, valueType: 'amount', currencySymbol },
          ];
          break;
        case routeNames.RgMyLoginTimeLimit:
          r = await this.$services.account.getLoginTimeLimit();
          calculateList = [
            { type: 'monthly', current: r.CurrentMonthlyLoginTimeLimit, request: r.RequestMonthlyLoginTimeLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.MonthlyLoginTimeLimitApplyAt, noLimit: false, valueType: 'hr', currencySymbol, rolling },
            { type: 'weekly', current: r.CurrentWeeklyLoginTimeLimit, request: r.RequestWeeklyLoginTimeLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.WeeklyLoginTimeLimitApplyAt, noLimit: false, valueType: 'hr', currencySymbol, rolling },
            { type: 'daily', current: r.CurrentDailyLoginTimeLimit, request: r.RequestDailyLoginTimeLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.DailyLoginTimeLimitApplyAt, noLimit: false, valueType: 'hr', currencySymbol, rolling },
          ];
          break;
        case routeNames.RgMyReLoginTimeLimit:
          r = await this.$services.account.getRelogintimelimit();
          calculateList = [
            { type: 'default', current: r.CurrentReLoginTimeLimit, request: r.RequestReLoginTimeLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.ReLoginTimeLimitApplyAt, noLimit: !r.RequestReLoginTimeLimit, valueType: 'min' },
          ];
          break;
        case routeNames.RgMyMonthlyLoginFrequency:
          r = await this.$services.account.getRgMonthlyLoginFrequency();
          calculateList = [
            { type: 'default', current: r.CurrentMonthlyLoginFrequency, request: r.RequestMonthlyLoginFrequency, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.MonthlyLoginTimeLimitApplyAt, noLimit: !r.RequestMonthlyLoginFrequency, valueType: 'times' },
          ];
          break;
        case routeNames.RgMyGameLimit:
          r = await this.$services.account.getGameLimit();
          calculateList = [
            { type: 'poker', current: r.CurrentPokerGameLimit, request: r.RequestPokerGameLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.PokerGameLimitApplyAt, valueType: 'game' },
            { type: 'casino', current: r.CurrentCasinoGameLimit, request: r.RequestCasinoGameLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.CasinoGameLimitApplyAt, valueType: 'game' },
            { type: 'liveCasino', current: r.CurrentLiveDealerGameLimit, request: r.RequestLiveDealerGameLimit, limitApplyAt: isVerifyFreeze ? 'verifyFreeze' : r.LiveDealerGameLimitApplyAt, valueType: 'game' },
          ];
          break;
      }

      const descModel = {
        cancelPreviewDescription: 'responsibleGaming.modal.cancelPreview.description',
        cancelConfirmDescription: 'responsibleGaming.modal.cancelConfirm.description',
      };

      return {
        title: { default: this.$t('_.modal.cancelPreview.title'), 2: this.$t('_.modal.cancelConfirm.title') },
        subTitle: { default: this.$t(`_.modal.cancelPreview.subTitle`, {rgType: this.$t(`_.title.${item.label.default}`)}) },
        modalType: 'Cancel',
        calculateList,
        isVerifyFreeze,
        limitType,
        descModel,
        first: 1,
        last: 2,
        config: this.structure.config,
        request: () => this.$services.account.setCancelTransaction(limitType),
      };
    },
    async openModal(item) {
      this.structure.config = this.$rg.createConfig(this.config);
      this.$services?.modal.block();
      const options = await this.createModalOptions(item);
      this.$services?.modal.unblock();
      if (options.calculateList) this.$modal(ResponsibleProgressModal, options);
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-info] {
  .info-router-group { .pt(10); .flex(); .flex-dc(); .gap(10); .ph(20);
    > h3 {.c(@c-b08); .fs(16)}
    > [gp-menu-link-button] .pointer { .pointer(); }
  }
  .list-holder {
    > a { .block(); .p(16, 24, 12, 16); .-b(@c-b01); .c(@c-b08); .bold(); .rel();
      [font-icon] { .bold(); .c(@c-b05); .rt(0, 50%); .abs(); .t-yc(); }
    }
  }
}
</style>
