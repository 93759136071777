<template>
  <GpFormTemplate external-monthly-limit>
    <template #header>
      <GpDescription v-if="description" :message="description" />
      <GpDescription v-if="structure.config.verifyFreeze" :message="$t('responsibleGaming.verifyFreeze.message')" />
    </template>
    <template #content>
      <InputExternalMonthlyLimit :model="model" :current="current" :currency-symbol="currencySymbol" />
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click="submit(validate)" :disabled="!!invalid || disabledButton">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';
import ResponsibleConfirmModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleConfirmModal.vue';
import InputExternalMonthlyLimit from '@/views/components/pages/responsible-gaming/template/inputs/InputExternalMonthlyLimit.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
import rgErrorHandler from '@/mixins/responsible-gaming/rgErrorHandler';
import { getDisplayAmount } from '@/utils/amountUtil';
import { toYMDT } from '@/utils/dateTimeUtil';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'RgExternalMonthlyLimit',
  lexicon: 'responsibleGaming',
  components: { InputExternalMonthlyLimit, GpFormTemplate, GpDescription, GpButton },
  mixins: [rgErrorHandler],
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      errorMsg: null,
      currencySymbol: null,
      current: {
        Currency: null,
        CurrentExternalMonthlyDepositLimit: null,
        ExternalMonthlyDepositLimitApplyAt: null,
      },
      model: {
        CrossProviderLimitAmount: null,
      },
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    disabledButton() {
      return !this.model.CrossProviderLimitAmount;
    }
  },
  methods: {
    async initialize() {
      this.current = {
        ...this.current,
        ...this.structure.current,
      };
      this.currencySymbol = getCurrencySymbol(this.current.Currency);
    },
    async submit(validate) {
      const result = await this.$rg.request(this.model);
      let modalOption = null;
      if (result.error) {
        // const { message, subMessage } = this.apiErrorHandler(result);
        const message = this.externalLimitError(result);
        modalOption = {
          title: { default: this.$t(`_.title.${this.title}`) },
          desc: message,
          modalType: 'Message',
          showFloating: false,
        };
      } else {
        modalOption = {
          title: { default: this.$t(`_.title.${this.title}`) },
          desc: this.$t('_.modal.description.externalLimit'),
          data: this.createModalDate(result),
          modalType: 'External',
          showFloating: false,
          closeReturn: true,
        };
      }
      this.$modal(ResponsibleConfirmModal, modalOption);
    },

    createModalDate: function (result) {
      if (result?.NewLimit) {
        return {
          current: this.displayAmount(result.CurrentLimit),
          limit: this.displayAmount(result.NewLimit),
          date: toYMDT(result.NewLimitAvailableDate)
        };
      } else {
        return {
          limit: this.displayAmount(result.CurrentLimit)
        };
      }
    },

    externalLimitError(error) {
      const lugasCode = error.CustomerErrorParameters[0] ? error.CustomerErrorParameters[0].trim() : null;
      const lugasErrorPrefix = 'onBoarding.lugasStep.';
      if (!lugasCode) {
        const { message, subMessage } = this.apiErrorHandler(error);
        return message;
      }
      if (error?.key === apiErrorCode.ALREADY_HAVE_RG_PENDING_LIMIT)
        return this.$t(error?.key);
      else {
        return this.$t(lugasErrorPrefix + lugasCode, { email: this.csEmail });
      }
    },

    displayAmount(amount) {
      return getDisplayAmount(this.currency, amount);
    }
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[external-monthly-limit] {
  [gp-description] {
    > p > ul { .rel(); .pl(15); line-height: 1.4; .pt(10);}
    > p > ul > li:before { content: "•"; .abs(); .l(0); }
  }
}
</style>
