<template>
  <SectionContainer bonus-history-v1 v-if="ready">
    <template #title>
      <h3>{{ $t('_.bonusHistory') }}</h3>
    </template>
    <template #contents>
      <div class="table-holder" :class="isEmpty(items) ? 'no-list-table' : ''">
        <GridTable :list="items" :min-width="size.tableMin">
          <template v-slot="{/** @type {PromotionHistoryItem} */row}">
            <GridColumn :label="$t('_.date')" :weight="size.cols.weight" class="date"> {{ toFullDateT(row.date, locale) }}</GridColumn>
            <GridColumn :label="$t('_.description')" :weight="size.cols.description" class="desc">
              <button @click="detailShow" :class="['extension-btn', {'disabled': !row.itemDetails}]">
                <span>{{ row.description }}</span>
                <FontIcon name="angle-down" v-if="row.itemDetails" />
              </button>
              <div class="details">
                <p v-for="(detail, id) in row.itemDetails" :key="id">
                  <bdi>{{ detail.ticketCount }} x {{ currencyStr(detail.ticketName) }}</bdi>
                </p>
              </div>
            </GridColumn>
            <GridColumn :label="$t('amount')" :weight="size.cols.amount" class="amount">
              <p>{{ amount(row) }}</p>
              <div class="details" v-if="row.itemDetails">
                <p v-for="(detail, id) in row.itemDetails" :key="id">
                  {{ detailAmount(row.currency, detail) }}
                </p>
              </div>
            </GridColumn>
          </template>
          <template #no-list>
            <p>{{ $t('_.noBonusHistory') }}</p>
          </template>
        </GridTable>
      </div>
      <Pagination :chunk-size="pageChunk" :info="history" :showRow="false" @change="update" />
    </template>
  </SectionContainer>
</template>

<script>
import SectionContainer from '@/views/components/pages/promotion/v1/SectionContainer.vue';
import Pagination from '@shared/components/common/Pagination.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import GridTable from '@shared/components/common/table/GridTable.vue';
import GridColumn from '@shared/components/common/table/GridColumn.vue';
import pagination from '@shared/mixins/pagination';
import { toFullDateT } from '@/utils/dateTimeUtil';

export default {
  name: 'BonusHistoryV1',
  lexicon: 'promotion',
  components: { SectionContainer, Pagination, FontIcon, GridColumn, GridTable },
  mixins: [pagination],
  props: {
    playerPromotionId: { type: Number },
  },
  data() {
    return {
      tableSize: 20,
      extraInfo: null,
      ready: false,
    };
  },
  computed: {
    siteId: state('user', 'siteId'),
    locale() { return this.$i18n('locale'); },
    history() {
      return this.info;
    },
    size() {
      if (this.$route.name === 'Referral') return ({ tableMin: '800px', cols: { weight: 2.5, description: 5.5, amount: 2 } });
      return ({ tableMin: '630px', cols: { weight: 3, description: 4, amount: 3 } });
    },
    items() {
      return /** @type {PromotionHistoryItem[]} */this.history?.items || [];
    },
  },
  methods: {
    toFullDateT,
    detailShow(e) {
      const tr = e.currentTarget.closest('tr');
      const isOpen = tr.classList.value.includes('open');
      return isOpen ? tr.classList.remove('open') : tr.classList.add('open');
    },
    exchanged(item) {
      const hasDetails = item.itemDetails;
      const exchangeRate = hasDetails ? this.history.exchangeRateTicketCurrencyToLocalCurrency : this.history.exchangeRateTemplateCurrencyToLocalCurrency;
      return (Math.floor(item.amount * exchangeRate * 100) / 100).toFixed(2);
    },
    amount(item) {
      if (Object.keys(item).length === 0) return;
      const { currency, amount } = item;
      const { site, localCurrency } = this.history;
      const isN8in = site === 'N8IN';
      const sameCurrency = currency === localCurrency;
      if (amount <= 0) return '-';
      const itemAmount = this.$promotion.getCurrencyString(currency, amount);
      const localAmount = this.$promotion.getCurrencyString(localCurrency, this.exchanged(item));

      if (isN8in) return localAmount;
      if (currency === 'GCD' || currency === 'GTD' || sameCurrency) return itemAmount;
      return `${itemAmount} (${localAmount})`;
    },
    detailAmount(itemCurrency, detail) {
      const { site, localCurrency, exchangeRateTicketCurrencyToLocalCurrency } = this.history;
      const { ticketCount, ticketValueInPromotionCurrency, ticketValueInUSD } = detail;
      const isN8in = site === 'N8IN';
      const sameCurrency = itemCurrency === localCurrency;
      const exchangeRate = sameCurrency ? 1 : exchangeRateTicketCurrencyToLocalCurrency;
      const ticketAmount = this.$promotion.getCurrencyString(itemCurrency, (ticketCount * ticketValueInPromotionCurrency));
      const localAmount = this.$promotion.getCurrencyString(localCurrency, (ticketCount * ticketValueInUSD * exchangeRate));

      if (isN8in) return localAmount;
      if (sameCurrency) return ticketAmount;
      return `${ticketAmount} (${localAmount})`;
    },
    currencyStr(name) {
      name = name || '';
      switch (this.siteId) {
        case 'WSOPON':
          return name.replace('CA$', '$');
        case 'N8IN':
          return name.replace('$', '');
        default:
          return name;
      }
    },
    async update(cursor, extra) {
      this.ready = false;
      if (extra) this.extraInfo = extra;
      /** @type {PromotionHistory} */ await this.pageReset({ service: this.$services.promotion, api: 'history', cursor, extra: this.extraInfo });
      this.ready = true;
    },
    change(cursor) {
      return cursor++;
    },
  },
  async mounted() {
    this.setPageChunk(this.tableSize);
    await this.update(this.currentCursor, { PlayerPromotionId: this.playerPromotionId });
  },
};
</script>

<style lang="less">
@import "~@/less/promotion.less";
[bonus-history-v1] {
  .no-list-table [grid-table] .table-wrapper { .min-w(auto) !important;
    table { table-layout: auto; }
    .scroll-holder { .wf(); }
  }
  [grid-table] {
    .scroll-holder { .-b(); }
    .no-list { .pv(40); .-b(@c-dimGrey); .fs(16); .c(@c-dimGrey); }
    table.header { .wf() !important; }
    table.header, table tbody tr, table tr td:last-child {
      .bgc();
    }
    table { .fs(16); .c(@c-blurWhite); border-collapse: collapse;
      thead { .-t(@c-dimGrey);
        th { .-a(); .fs(14); .text-start() !important; }
        th:not(:nth-of-type(1)) { .-l(); }
      }
      tbody {
        td { .-a(); .fs(inherit); }
      }
      tr { .-b(@c-dimGrey);
        td, th { .p(14, 20); .text-start(); .vat(); line-height: 1.4; }
        .amount { .text-end() !important; }
        th, .date { .c(@c-grey67); }
      }
      .details { .hide(); .c(@c-grey67);
        p { .mt(10); }
      }
      .open {
        .details { .block(); }
        [font-icon] { .t-r(180deg); }
      }
      .extension-btn { .flex(); .items-center(); .gap(4); .bgc(); .fs(inherit); .c(inherit);
        &.disabled { .default();
          span { cursor: text; }
        }
        [font-icon] { .ib(); .fs(0.875em); .c(@c-grey67);
          &::before { .vam(); }
        }
      }
    }
  }
  [pagination] { .flex(); .justify-end(); text-align: initial;
    .left button:first-child, .right button:last-child { .hide(); }
    a { .o(0.7); .ph(10); .mh(2); .lh(30); .fs(16);
      &:first-of-type { .ml(10); }
      &:last-of-type { .mr(10); }
      &.active { .o(1); .c(@c-grey67); .semi-bold(); }
      &.active:after { .hide(); }
    }
    button {
      .w(30); .h(30); .bgc(#202020); .c(@c-grey67);
    }
  }
}
</style>
<style lang="less" scoped>
@import "~@/less/promotion.less";
[bonus-history-v1] { .mt(16);
  h3 { .pv(20); }
  .table-holder { .mh(-20); }
  .amount { .nowrap(); }
}
</style>
