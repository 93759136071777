import { render, staticRenderFns } from "./FreeBonusComponent.vue?vue&type=template&id=583b3ba5&scoped=true"
import script from "./FreeBonusComponent.vue?vue&type=script&lang=js"
export * from "./FreeBonusComponent.vue?vue&type=script&lang=js"
import style0 from "./FreeBonusComponent.vue?vue&type=style&index=0&id=583b3ba5&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583b3ba5",
  null
  
)

export default component.exports