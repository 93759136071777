<template>
  <div ph-poa-floating v-html="poaPopover">
  </div>
</template>

<script>
export default {
  name: 'PhPoaFloating',
  data() {
    return {
      poaPopover: null,
      popoverList: [
        'kyc.documentType.UtilityBill',
        'kyc.documentType.SignedLease',
        'kyc.documentType.TaxAssessment',
        'kyc.documentType.BankStatement',
        'kyc.documentType.MortgageStatement',
      ],
    };
  },
  async mounted() {
    this.poaPopover = `${this.popoverList.map(i => `• ${this.$t(i)}`).join('<br/>')}<br/>${this.$t('kyc.proofOfAddress.descriptionPh')}`;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[ph-poa-floating] { box-shadow: 0 6px 12px rgba(0,0,0,0.175); .p(12); .br(4); .bgc(#0e1415); .fs(13); .c(white);
  >span { .c(@c-white); .fs(14); }
  >a { .fs(14); .c(#4A8CFF); .-b(#4A8CFF); .medium(); }
}
</style>