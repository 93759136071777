<template>
  <div input-balance-transfer>
    <div class="transfer-select">
      <GpTitle :name="$t('myInfo.sendFrom')" />
      <TransferDropDown :current="!isAddCash" is-from @input="debitSelect" :info="model" :selected="selectedAccountId" />
      <div :class="['change-button', {'disable': changeButtonDisable}]" @click="change">
        <div class="btn">
          <FontIcon name="exchange-v" />
        </div>
        <span>{{ $t('change') }}</span>
      </div>
      <GpTitle :name="$t('myInfo.sendTo')" />
      <TransferDropDown :current="isAddCash" @input="creditSelect" :info="model" :selected="selectedAccountId" />
    </div>
    <div class="transfer-amount">
      <GpTitle :name="$t('myInfo.transferAmount')" />
      <div v-if="debitConfig && creditConfig" class="amount">
        <ValidationProvider class="amount-field" name="_amount" :rules="rules" v-slot="{errors, touch}">
          <CurrencyInput :placeholder="$t('myInfo.enterTransfer')" textAlign="right" :cashType="model.Type" v-model="model.FromAmount" :currencySymbol="getCurrencySymbol(model.FromCurrencyId)" type="number" :maxLength="9" />
          <ValidationErrors class="error-field" :errors="errors" v-if=" errors && isValid" />
        </ValidationProvider>
        <p class="transfer-up"><span>{{ $t('myInfo.transferUpTo') }}</span><span>{{ displayFromCurrencyAmount }}</span></p>
        <p class="exchange-amount" v-if="model.FromAmount && (model.FromCurrencyId !== model.ToCurrencyId)"><span>{{ $t('myInfo.exchangeAmount') }}</span><span>{{ displayExchangeAmount }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import TransferDropDown from '@/views/components/pages/my-page/template/transfer/TransferDropDown.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';
import _ from 'lodash';
import { getDisplayAmount } from '@/utils/amountUtil';
import CurrencyInput from '@/views/components/pages/my-page/template/transfer/CurrencyInput.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { transferType, currencyScaleModel } from '@/constants/base/my-page';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { fixedRound, fixedRoundUp } from '@shared/utils/numberUtils.mjs';

export default {
  name: 'InputBalanceTransfer',
  components: { CurrencyInput, TransferDropDown, GpTitle, FontIcon },
  props: {
    model: Specific,
  },
  data() {
    return {
      fromTransferList: null,
      toTransferList: null,
      displayFromAmount: null,
      displayToAmount: null,
      debitConfig: null,
      creditConfig: null,
      selectedAccountId: null,
      selectLinkedAccount : null,
      error: {},
    };
  },
  computed: {
    site: state('env', 'site'),
    isValid() {
      if (!this.model.FromAmount || this.model.FromAmount === '0') return false;
      return !!String(this.model.FromAmount).trim().length;
    },
    isAddCash() {
      return this.model.Type === transferType.ADD_CASH;
    },
    transferMin() {
      const debitMin = this.debitConfig?.minAmount ? this.debitConfig.minAmount : 0;
      const creditMin = this.creditConfig?.minAmount ? this.creditConfig.minAmount : 0;
      const min = Math.max(debitMin, creditMin);
      if (this.model.FromCurrencyId !== this.model.ToCurrencyId) {
        if (currencyScaleModel.hasOwnProperty(this.model.ToCurrencyId)) {
          const currencyScale = currencyScaleModel[this.model.ToCurrencyId];
          const toMin = this.findMinimumUnit(currencyScale);
          const toExchangeFromMin = fixedRoundUp((toMin / this.model.ExchangeLate), 2);
          return Math.max(+toExchangeFromMin, min);
        } else {
          return min;
        }
      } else {
        if (currencyScaleModel.hasOwnProperty(this.model.FromCurrencyId)) {
          const currencyScale = currencyScaleModel[this.model.FromCurrencyId];
          const fromMin = this.findMinimumUnit(currencyScale);
          return Math.max(fromMin, min);
        } else {
          return min;
        }
      }
    },
    transferMax() {
      let debitMax = typeof this.debitConfig?.maxAmount === 'number' ? this.debitConfig.maxAmount : Number.MAX_SAFE_INTEGER;
      let creditMax = typeof this.creditConfig?.maxAmount === 'number' ? this.creditConfig.maxAmount : Number.MAX_SAFE_INTEGER;
      if (this.model.FromCurrencyId !== this.model.ToCurrencyId) {
        debitMax = fixedRound((debitMax * this.model.ExchangeLate), 2);

        const max = Math.min(debitMax, creditMax);
        if (max === +debitMax) {
          return fixedRound((max / this.model.ExchangeLate), 2);
        } else if (max === +creditMax) {
          return fixedRound((max / this.model.ExchangeLate), 2);
        }
        return max;
      } else {
        const max = Math.min(debitMax, creditMax);
        return max;
      }
    },
    displayFromCurrencyAmount() {
      if (!this.transferMax && typeof this.transferMax !== 'number') return null;
      return getDisplayAmount(this.model.FromCurrencyId, this.transferMax);
    },
    displayExchangeAmount() {
      if (!this.model.ExchangeAmount) return null;
      const exchangeAmount = this.truncateDecimals(+this.model.ExchangeAmount, 2);
      return getDisplayAmount(this.model.ToCurrencyId, exchangeAmount);
    },
    rules() {
      return `required|balanceTransferMin:${parseFloat(this.transferMin.toString()).toFixed(2)},${getCurrencySymbol(this.model.FromCurrencyId)}|balanceTransferMax:${parseFloat(this.transferMax.toString()).toFixed(2)},${getCurrencySymbol(this.model.FromCurrencyId)}`;
    },
    changeButtonDisable(){
      if(this.isAddCash){
        if(!this.model.CurrentAccount?.DebitAvailable) return true;
        if(!this.selectLinkedAccount?.CreditAvailable) return true;
        return false;

      }else {
        if(!this.model.CurrentAccount?.CreditAvailable) return true;
        if(!this.selectLinkedAccount?.DebitAvailable) return true;
        return false;
      }
    }
  },
  watch: {
    'model.FromAmount': _.debounce(function (value) {
      if (!value) return;
      if (this.model.FromCurrencyId === this.model.ToCurrencyId) {
        this.model.ExchangeAmount = value;
      } else {
        this.model.ExchangeAmount = value * this.model.ExchangeLate;
      }
    }, 200),
  },
  methods: {
    /**
     * @주요 정보
     * model.CurrentAccount : 현재 계정 정보
     * model.Accounts : 현재 계정을 제외한 연결된 계정들 정보
     * @ 각 어카운트별 내부 정보
     * DebitAvailable: 어카운트의 Debit 가능 여부 ( 보내기 가능 여부 )
     * CreditAvailable : 어카운트의 Credit 가능 여부 ( 받기 가능 여부 )
     *
     * **/
    getCurrencySymbol,
    async change() {
      // Current Account 또는 현재 선택된 계정 의 credit / debit 가능 여부에 따라 switch 기능을 막음.
      if(this.isAddCash){
        if(!this.model.CurrentAccount.DebitAvailable) return;
        if(!this.selectLinkedAccount.CreditAvailable) return;

      }else {
        if(!this.model.CurrentAccount.CreditAvailable) return;
        if(!this.selectLinkedAccount.DebitAvailable) return;
      }

      this.model.Type = this.isAddCash ? transferType.CASH_OUT : transferType.ADD_CASH;
      this.selectedAccountId = this.isAddCash ? this.model.ToOnepassAccountId : this.model.FromOnepassAccountId;
      this.toTransferList = null;
      this.fromTransferList = null;

      await sleep(60);
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    debitSelect(item) {
      if (!item) return;
      this.model.FromOnepassAccountId = item.AccountId;
      this.model.FromCurrencyId = item.Currency;
      this.model.DebitCountryCode = item.CountryCode ? item.CountryCode.toLowerCase() : "";
      this.model.DebitAmount = item.DisplayAmount;
      this.model.DebitSiteId = item.SiteId;
      this.model.DebitLogoUrl = item.LogoUrl;
      this.displayFromAmount = item.Amount;
      this.model.DebitErrorCode = item.Debit.transferableCode;
      this.debitConfig = item.Debit;
      if(item.AccountId !== this.model.CurrentAccount.AccountId){
        this.selectLinkedAccount = item;
      }

      this.amountExchange();
      this.$emit('currency');
    },

    creditSelect(item) {
      if (!item) return;
      this.model.ToOnepassAccountId = item.AccountId;
      this.model.ToCurrencyId = item.Currency;
      this.displayToAmount = item.Amount;
      this.model.CreditCountryCode = item.CountryCode ? item.CountryCode.toLowerCase() : "";
      this.model.CreditAmount = item.DisplayAmount;
      this.model.CreditSiteId = item.SiteId;
      this.model.CreditLogoUrl = item.LogoUrl;
      this.model.CreditErrorCode = item.Credit.transferableCode;
      this.creditConfig = item.Credit;

      if(item.AccountId !== this.model.CurrentAccount.AccountId){
        this.selectLinkedAccount = item;
      }
      this.amountExchange();
      this.$emit('currency');
    },

    amountExchange() {
      if (this.model.FromCurrencyId === this.model.ToCurrencyId) {
        this.model.ExchangeAmount = this.model.FromAmount;
      } else {
        this.model.ExchangeAmount = this.model.FromAmount * this.model.ExchangeLate;
      }
    },
    findMinimumUnit(decimalPlaces) {
      return Math.pow(10, -decimalPlaces);
    },
    truncateDecimals(number, decimalPlaces) {
      const factor = Math.pow(10, decimalPlaces);
      return Math.floor(number * factor) / factor;
    }
  },
  beforeDestroy() {
    this.model.FromAmount = null;
    this.model.ExchangeAmount = null;
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[input-balance-transfer] { .flex();.flex-dc();
  .mask {.p(0); .m(0);}
  .transfer-select { flex: 1;
    > div { .pv(10); }
    [my-title-label] {.pv(0);}
  }
  .amount-field { .wf();
    [validation-errors] { text-align: left; .mt(10); .tl(); .pt(10); }
  }
  .input-comp {visibility: hidden;}
  .change-button {.flex();.justify-center(); .items-center(); .gap(10); .m(20); cursor:pointer;
    &.disable { .c(#737373); cursor:default;
      > span {.c(#737373); .fs(14); cursor:default;}
      > .btn {border: 1px solid #737373; .br(100); .wh(36); .flex(); .justify-center(); .items-center(); .bgc(white); cursor:default;
        > [font-icon] {.c(#737373); .fs(18);}
      }
    }
    > span {.c(@gp-green); .fs(14); cursor:pointer;}
    > .btn {border: 1px solid @gp-green; .br(100); .wh(36); .flex(); .justify-center(); .items-center(); .bgc(white); cursor: pointer;
      > [font-icon] {.c(@gp-green); .fs(18);}
    }
  }
  .transfer-amount { .flex(); .flex-dc(); .pv(20); .gap(10);
    [my-title-label] {.pb(0);}
    .error-field {.mb(-13);}
    .amount { .flex(); .items-center(); .p(20); .justify-end(); .br(16); .bgc(#fff); .flex-dc(); .gap(5);
      > .transfer-up {.c(#737373); .fs(12); text-align: left; .w(100%); .pv(0); .m(0); line-height: 16px; .flex(); .space-between();.mt(13);}
      > .exchange-amount {.c(#737373); .fs(12); text-align: left; .w(100%); .pv(0); .m(0); line-height: 16px; .flex(); .space-between();}
    }
  }
}
</style>
