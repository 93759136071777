<template>
  <div fp-select class="field-wrapper">
    <div class="field-holder">
      <RadioList type="vertical" :list="radioList" :custom-radio="FpRadioItem" v-model="modelInfo.NpApiType" :mobileType="modelInfo.MessageType" @input="input" @changeMobile="changeMobile" />
      <ValidationErrors v-if="structure.errorMsg && structure.limitTime <= 0" :error-msg="structure.errorMsg" />
      <!--      <LimitTimer class="error-msg" v-if="structure.limitTime > 0" :time="structure.limitTime" @complete="timerComplete" />-->
    </div>
    <div class="support-email">
      <span>{{ $t('forgotPassword.label.needHelp') }}</span><a :href="mailTo" target="_blank">{{ $t('forgotPassword.label.contactSupportTeam') }}</a>
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { state } from '@shared/utils/storeUtils.mjs';
import RadioList from '@/views/components/gg-pass/RadioList.vue';
import FpRadioItem from '@/views/components/pages/forgot-password/FpRadioItem.vue';
import LimitTimer from '@/views/components/pages/on-boarding/LimitTimer.vue';

export default {
  name: 'FpSelect',
  components: { LimitTimer, RadioList },
  props: {
    modelInfo: { type: Specific, default: null },
    structure: Specific,
    step: { type: Number, default: 0 },
    resendTime: 0,
  },
  data() {
    return {
      captcha: null,
      selectedValue: 'Email',
      mobileType: 0,
      PasswordInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    config: state('config', 'info'),
    channelingName: state('query', 'channelingName'),
    csEmail: state('env', 'csEmail'),
    currentLocale() {
      return this.$i18n('locale');
    },
    isTwoAce() {
      return this.site === siteIds.TWOACE;
    },
    mailTo() {
      return `mailto:${this.csEmail}`;
    },
    emailContactLabel() {
      return `<span>${this.$t('forgotPassword.label.needHelp')}</span><a href='mailto:${this.csEmail}' target='_blank'>${this.$t('forgotPassword.label.contactSupportTeam')}</a>`;
    },
    FpRadioItem() {
      return FpRadioItem;
    },
    radioList() {
      const list = this.isTwoAce ? [{ value: 'Email', label: this.$t('emailAddress') }] : [{ value: 'Email', label: this.$t('emailAddress') }, { value: 'Mobile', label: this.$t('mobileNumber') }];
      if (!this.modelInfo.VerificationMethods) return list;
      return list.map(item => {
        const method = this.modelInfo.VerificationMethods.find(method => item.value === method.IdentityVerificationMethod);
        if (method) {
          return {
            ...item,
            maskedValue: method.ValueMasked
          };
        } else {
          return {
            ...item,
            maskedValue: null
          };
        }
      }).filter(x => x.maskedValue);
    },
  },
  watch: {
    'modelInfo.NpApiType': function () {this.$emit('clear-error'); },
  },
  methods: {
    input(v) {
      if (v === 'Email') {
        this.modelInfo.SendVerificationType = 'EMAIL';
      } else if (v === 'Mobile') {
        this.modelInfo.SendVerificationType = 'MOBILE';
      }
      this.modelInfo.ValueMasked = this.getMaskedValue(v);
    },
    changeMobile(v) {
      this.modelInfo.MessageType = v;
      this.modelInfo.SendVerificationType = 'MOBILE';
    },
    timerComplete() {
      this.$emit('timer-complete');
    },
    getMaskedValue(type) {
      return this.modelInfo.VerificationMethods.find(v => v.IdentityVerificationMethod === type)?.ValueMasked || '';
    },

    removeElement(element) {
      element.parentElement.removeChild(element);
    }
  },
  mounted() {
    this.modelInfo.SendVerificationType = 'EMAIL';
    this.modelInfo.NpApiType = this.modelInfo.SendVerificationType === 'EMAIL' ? 'Email' : 'Mobile';
    this.modelInfo.ValueMasked = this.getMaskedValue(this.modelInfo.NpApiType);
    this.modelInfo.Language = this.currentLocale;
    this.structure.title = 'fpTitle';
    this.structure.desc = 'forgotPassword.description.selectDesc';
    this.structure.back = true;
    this.structure.showButton = true;
    this.structure.errorCode = null;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-select] {.flex();.flex-dc();
  .or { .fs(16, 20); .c(#7F7F7F); .mv(20); .rel();
    span { .ph(12); .bgc(#171717); .rel(); .z(1);}
    em { .wh(100%, 1); .bgc(#333); .block(); .abs(); .lt(50%, 50%); .t-xyc(); .max-w(440); }
  }
  .support-email {.flex();.justify-center(); .gap(5);
    > span {.c(#7F7F7F);}
    > a {.c(#c64040); .underline();}
  }
}
</style>