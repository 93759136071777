<template>
  <div fi-create class="field-wrapper">
    <div class="header">
      <p class="desc" v-html="$t('findId.description.createDesc')" />
    </div>
    <div class="field-holder">
      <!--      <ValidationComponent name="email" v-model="modelInfo.Email" :label="$t('emailAddress')" rules="required|requiredSignEmail" :component="EmailInputBox" :preset="{placeHolder : $t('emailOrUser')}" />-->
      <EmailSearchSelect :model-info="modelInfo" :structure="structure" rules="required|requiredSignEmail" />
<!--      <ValidationErrors class="error-msg" :errorMsg="structure.errorMsg" />-->
    </div>
  </div>
</template>

<script>
import { isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import EmailSearchSelect from '@/views/components/common/EmailSearchSelect.vue';

export default {
  name: 'FiCreate',
  components: { EmailSearchSelect },
  props: {
    modelInfo: { type: Specific, default: null },
    structure: Specific,
  },
  data() {
    return {
      captcha: null,
      config: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    PasswordInput() {
      return PasswordInput;
    },
    TrimTextInput() {
      return TrimTextInput;
    },
    min() {
      return isExcludeGgpass(this.site) ? 8 : (this.config?.passwordMinLength || 8);
    },
    max() {
      return isExcludeGgpass(this.site) ? 20 : (this.config?.passwordMaxLength || 20);
    },
    emailValue() {
      return this.modelInfo.Email || '';
    },
    pwRule() {
      const emailName = this.emailValue.split('@')[0];
      const password = this.modelInfo.Password || '';
      const contain = emailName.length && emailName.length <= password.length ? `containEmail:${this.emailValue}` : '';
      return this.isGGPDE ? `length:8,20|password|${contain}` : `length:8,20|includeNumberAndAlphabet|${contain}`;
    },
  },
  watch: {
    // 'modelInfo.Email': function () {
    //   this.structure.error = false;
    //   this.structure.errorMsg = null;
    // },
    'modelInfo.Email': function () {this.$emit('clear-error'); },
  },
  methods: {},
  async mounted() {
    this.structure.desc = null;
    this.structure.back = false;
    this.modelInfo.RequestType = '';
    this.structure.methodType = '';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fi-create] { .flex(); .flex-dc();
  .field-holder {.flex(); .flex-dc(); .gap(10);}
  > div + div { .mt(12); }
}
</style>