<template>
  <div reaffirm-wsop-information>
    <div class="guide-holder">
      <p v-html="$t('_.guide')" />
      <p v-html="$t('_.guideConfirm')" />
    </div>
    <div class="contents-holder">
      <ul>
        <li v-for="(item, id) in list" :key="id">
          <div :class="['contents', item.title]">
            <p>{{ `${id + 1}. ${$t(item.title)}` }}</p>
            <div>
              <dl>
                <div v-for="(sItem, sId) in item.contents" :key="`${id}-${sId}`">
                  <dt :class="{ checked: !sItem.disabled && item.value }">
                    <p v-if="item.title === 'pephio'" :class="{ desc: sItem.type === 'desc' }">{{ $t(sItem.label) }}</p>
                    <p v-else>{{ $t(sItem.label) }} : </p>
                  </dt>
                  <dd :class="{ checked: !sItem.disabled && item.value }">{{ sItem.value }}</dd>
                </div>
              </dl>
            </div>
          </div>
          <div class="checkbox">
            <p>
              <span :class="{hide: id !== 0}">{{ $t('changed') }}</span>
            </p>
            <div>
              <CheckboxItem theme="dark" v-model="item.value" />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="button-holder">
      <ProgressButton button-name="onBoarding.reaffirm.wsop.nothingUpdate" :disabled="isUpdate" @click="nothingUpdate" />
      <ProgressButton button-name="onBoarding.reaffirm.wsop.update" :disabled="!isUpdate" @click="update" />
    </div>
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';
import { toDMY } from '@/utils/dateTimeUtil';

export default {
  name: 'ReaffirmWsopInformation',
  lexicon: 'onBoarding.reaffirm.wsop',
  components: { CheckboxItem, ProgressButton },
  mixins: [baseTemplate],
  data() {
    return {
      list: [],
    };
  },
  computed: {
    isUpdate() {
      return this.list.some(item => item.value);
    }
  },
  methods: {
    nothingUpdate() {
      this.$emit('complete');
    },
    update() {
      const customSteps = [];
      this.list.forEach((item, id) => {
        if (item.value) {
          switch (id) {
            case 0:
              customSteps.push(routeNames.personalName);
              break;
            case 1:
              customSteps.push(routeNames.personalBirth);
              break;
            case 2:
              customSteps.push(routeNames.personalAddress);
              break;
            case 3:
              customSteps.push(routeNames.personalOccupation);
              break;
            case 4:
              customSteps.push(routeNames.personalPep);
              break;
          }
        }
      });
      this.structure.customSteps = customSteps;
      this.$emit('start');
    },
    stepInitialize() {
      // Personal Name
      this.list.push({
        title: 'onBoarding.name',
        value: false,
        contents: [
          { label: 'firstName', value: this.modelInfo.FirstName ?? '-', disabled: true },
          { label: 'lastName', value: this.modelInfo.LastName ?? '-', disabled: true },
          { label: 'personalDetails.middleName', value: this.modelInfo.MiddleName ?? '-' },
          { label: 'personalDetails.aliasPreferredName', value: this.modelInfo.Alias ?? '-' },
        ]
      });
      // Mobile Number
      this.list.push({
        title: 'mobileNumber',
        value: false,
        contents: [
          { label: 'dateOfBirth', value: this.modelInfo.DateOfBirth ? toDMY(this.modelInfo.DateOfBirth, '/') : '', disabled: true },
          { label: 'countryCode', value: this.modelInfo.MobileCountryCode ? `+${this.modelInfo.MobileCountryCode}` : '' },
          { label: 'mobileNumber', value: this.modelInfo.ContactMobileNumber ?? '' },
          { label: 'personalDetails.citizenship', value: this.modelInfo.Nationality ? this.$t(`country.${this.modelInfo.Nationality}`) : '', disabled: true },
        ]
      });
      // Address
      this.list.push({
        title: 'address',
        value: false,
        contents: [
          { label: 'address', value: this.modelInfo.Address ?? '' },
          { label: 'personalDetails.stateProvince', value: this.modelInfo.State ?? '' },
          { label: 'personalDetails.city', value: this.modelInfo.City ?? '' },
          { label: 'personalDetails.postalCode', value: this.modelInfo.PostalCode ?? '' },
        ]
      });
      // Occupation
      this.list.push({
        title: 'onBoarding.occupation',
        value: false,
        contents: [
          { label: 'onBoarding.occupation', value: (this.modelInfo.OccupationType && this.modelInfo.OccupationType !== 'None') ? this.$t(`occupation.${this.modelInfo.OccupationType}`) : '' },
          { label: 'employerName', value: this.modelInfo.EmployerName ?? '' },
          { label: 'employerAddress', value: this.modelInfo.EmployerAddress ?? '' },
          { label: 'businessTelephoneNumber', value: this.modelInfo.BusinessPhoneNumber ?? '' },
        ]
      });
      // PEP/HIO
      this.list.push({
        title: 'pephio',
        value: false,
        contents: [
          { label: 'pepGuide', type: 'desc' },
          { label: 'notPep' },
        ]
      });
    }
  },
  mounted() {
    this.item.title = 'onBoarding.reaffirm.wsop.title';
    this.stepInitialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reaffirm-wsop-information] {
  p { .fs(16); }
  > .button-holder {
    > [progress-button] + [progress-button] { .mt(12); }
  }
  > .contents-holder { .ph(0) !important; }
  > .contents-holder ul { .-t(#707070);
    li { .flex(); .pv(12px); .-b(#707070);
      > .contents { flex: 1; }
      > .checkbox { flex: 0; }
      .hide { .o(0); }
      .checkbox { .flex(); .flex-dc(); .justify-center(); .items-center();
        > p { flex: 0; }
        > div { .flex(); .items-center(); flex: 1;}
        [checkbox-item], [checkbox-item].dark i, [checkbox-item] .rtx { .m(0); }
      }
      dl div { .flex(); .column-gap(8px); .pv(4px); .pl(16px); .c(#7f7f7f); .crop();
        dt { flex: 1; }
        dd { flex: 1.5 1; word-break: break-word; }
        .checked { .c(#fff); }
        .desc { .pb(12px); .c(inherit); }
      }
      .pephio dl div {
        dt { flex: 1; }
        dd { flex: 0; }
      }
    }
  }
  .button-holder button[color-button][progress-button] { line-height: 1; }
}
</style>
