<template>
  <div input-external-monthly-limit>
    <RgLimitInput :label="$t('responsibleGaming.label.externalMonthly')" name="_amount" :rules="rules" v-model="model.CrossProviderLimitAmount" :preset="{...preset}" :currency-symbol="preset.currencySymbol" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import RgLimitInput from '@/views/components/pages/responsible-gaming/template/common/RgLimitInput.vue';
import { makePrice } from '@shared/utils/currencyUtils.mjs';
export default {
  name: 'InputExternalMonthlyLimit',
  lexicon: 'onBoarding.setting',
  components: { RgLimitInput, CheckboxItem, InputComponent },
  props: {
    model: Specific,
    current: Specific,
    currencySymbol: { type: String, default: null }

  },
  data() {
    return {
      maximumLimit: 1000,
      TextInputBox
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    preset() {
      return { placeholder: this.$t('amount'), textAlign: 'right', currencySymbol: this.currencySymbol, type: 'number', maxLength: 9 };
    },
    rules() {
      return `depositLimitDE:${this.currencySymbol},${this.csEmail},${this.maximumLimit},${makePrice(this.maximumLimit)}`;
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-external-monthly-limit] {.flex(); .flex-dc(); .gap(20); .p(20); .bgc(#EBEBEF); .br(16);
  [validation-errors] {
    > li > a {.c(#2d74f6);}
  }
}
</style>