<template>
  <ValidationObserver tag="div" ref="validator" gp-template-form v-slot="{ invalid, handleSubmit }">
    <MfaModalTemplate verify-google-code>
      <template #title>
        {{ $t('_.googleAuthenticationStep4') }}
      </template>
      <template #content>
        <div class="verify-content">
          <InputComponent :label="$t('_.authenticatorCode')" name="_authenticator_code" rules="required" :component="TextInputBox" v-model="googleModel.authenticatorCode" :preset="preset" :error-msg="errorMsg" />
        </div>
      </template>
      <!--      <template #footer>-->
      <!--        <GpButton type="line-green" @click="prev">{{ $t('_.prev') }}</GpButton>-->
      <!--        <GpButton type="submit-green" @click="submit" :disabled="!!invalid || authenticatorCode.length !== 6">{{ $t('_.next') }}</GpButton>-->
      <!--      </template>-->
    </MfaModalTemplate>
  </ValidationObserver>
</template>

<script>
import { isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import MfaModalTemplate from '@/views/components/pages/my-page/modal/components/MfaModalTemplate.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'DisplayQRCode',
  lexicon: 'myInfo',
  components: { InputComponent, MfaModalTemplate },
  props: {
    img: { type: String, default: '' },
    code: { type: String, default: '' },
    googleModel: Specific,
    googleError: Specific,
  },
  data() {
    return { TextInputBox, authenticatorCode: '', error: null, };
  },
  computed: {
    site: state('env', 'site'),
    errorMsg() {
      return this.googleError?.errorMsg;
    },
    preset() {
      return { digit: true, maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*', placeholder: this.$t('_.googleStep4PlaceHolder') };
    },
    isGGPass() {
      return !isExcludeGgpass(this.site);
    }
  },
  watch: {
    authenticatorCode: function () {this.error = null;}
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    next() {
      this.$emit('next');
    },
    async submit() {
      const result = await this.$services.mfa.sendGoogleAuthentication(this.authenticatorCode);


      if (result.error) {
        this.apiErrorHandler(result);
        return;
      } else {
        this.next();
      }
      // this.$emit('verify');

    },

    apiErrorHandler(error) {
      const { key, desc } = error;
      switch (key) {
        case 'INVALID_PLAYER' :
          return this.error = this.$t(key);
        case 'INVALID_VERIFICATIONCODE' :
          return this.error = this.$t(key);
        case 'VERIFICATIONCODE_NOT_FOUND' :
          return this.error = this.$t(key);
        case 'INTERNAL_ERROR' :
          return this.error = this.$t(key);
        case 'UNMATCHED_VERIFICATIONCODE' :
          return this.error = this.$t(key);
        default :
          return this.error = this.$t('INVALID_VERIFICATION_CODE');
      }
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-google-code] { .flex(); .justify-center(); .flex-dc();
  .verify-content { .bgc(#f8f8f8); .p(20, 20, 20, 20); .w(100%); }
  .head-message {.c(black); text-align: center; .pb(20); .fs(14);}
}
</style>