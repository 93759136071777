/**
 * 로컬 언어 체크를 위한 정규식 패턴
 */
export const countryWritingPatterns = [
  { key: 1, value: '\\u00c0-\\u024f\\u1e00-\\u1eff' }, // Latin
  { key: 2, value: '\\u0400-\\u04ff' }, // Cyrillic
  { key: 4, value: '\\u0600-\\u06ff' }, // Arabic
  { key: 8, value: '\\u4e00-\\u9fd5' }, // Chinese
  { key: 16, value: '\\u0370-\\u03ff' }, // Greek
  { key: 32, value: '\\u00c0-\\u00ff\\u0100-\\u017f\\u1e9e\\.\\-' }, // German
];
/**
 * 아포스트로피 정규식 패턴
 * @type {string}
 */
export const apostrophePattern = '\\u0027\\u2019\\u02bc';
/**
 * 하이픈 체크를 위한 정규식 패턴
 */
export const hyphenPattern = '\\-';
/**
 * 스페이스 체크를 위한 정규식 패턴
 */
export const spacePattern = '\\s';
/**
 * 특수문자 정규식 패턴
 * @type {string}
 */
export const specialCharactersPattern = '\!\""\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?@\[\\\]\^_\`\{\|\}\~';