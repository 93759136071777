<template>
  <ul gp-radio-group :class="[{'vertical' : this.isVertical}, { 'horizontal' : !this.isVertical }]" @click="click($event)">
    <li v-for="(item, idx) in list" :data-value="item.value.toString()" :key="idx">
      <component :is="getComponent" :type="item.type" :active="isActive(item.value)" :rules="rules" :label="item.label" v-bind="item" :is-authenticated="item.isAuthenticated" :maskedValue="item.maskedValue" :mobileType="mobileType" @update="update" @changeMobile="changeMobile" />
    </li>
  </ul>
</template>

<script>
import RadioButton from '@/views/components/gg-pass/RadioButton.vue';
import Specific from '@shared/types/Specific';
export default {
  name: 'RadioList',
  // components: { RadioButton },
  props: {
    list: { type: Array, default: null },
    value: Specific,
    rules: { type: String, default: null, },
    type: { type: String, default: 'horizontal' },
    customRadio: { type: Specific, default: null },
    mobileType: { type: Number, default: 0 },
  },
  data() {
    return {
      currentValue: null,
    };
  },
  computed: {
    isVertical() {
      return this.type === 'vertical';
    },
    getComponent() {
      return this.customRadio ? this.customRadio : RadioButton;
    },
  },
  watch: {
    'value': 'updateCurrentValue'
  },
  methods: {
    updateCurrentValue() {
      this.currentValue = this.value;
    },
    click(e) {
      const element = e.target.closest('li');
      let value = this.getDataValue(element);
      if (!value) return;
      if (value === 'true' || value === 'false') {
        value = value === 'true';
      }
      this.currentValue = value;
      this.$emit('input', value);
    },
    getDataValue(element) {
      if (!element) return null;
      const { dataset } = element;
      if (!dataset) return null;
      const { value } = element.dataset;
      return value;
    },
    update(v) {
      this.$emit('update', v);
    },
    changeMobile(v) {
      this.$emit('changeMobile', v);
    },
    isActive(value) {
      return value === this.currentValue;
    },
  },
  mounted() {
    this.currentValue = this.value;
  }

};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-radio-group] { .flex(); .gap(10);
  &.vertical { .flex-dc();}
  &.horizontal {.flex-dr();}
}
</style>