<template>
  <div fp-error>
    <ProgressButton button-name="tryAgain" @click="click" />
  </div>
</template>

<script>

import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';


export default {
  name: 'FpError',
  components: { ProgressButton },
  props: {
    structure: Specific,
    modelInfo: { type: Specific, default: null },
  },
  data() {
    return {};
  },
  computed: {
    site: state('env', 'site'),
  },
  methods: {
    click() {
      this.replaceRouteName('FpInfo');
    },
    initialize() {
      this.structure.error = true;
      this.structure.title = 'forgotPassword.title.somethingWentWrong';
      this.structure.desc = this.structure.errorCode || 'VERIFICATIONTOKEN_NOT_FOUND';
      this.structure.back = false;
      this.structure.showButton = false;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-verify] { .flex(); .flex-dc(); flex: 1; justify-content: space-between;
  .email-app {.flex(); .items-end();.justify-center();
    .btn-holder {.flex();.gap(10); .items-center();}
  }
  [verify-resend] {.flex(); .justify-end();}
}
</style>