import _ from 'lodash';

export const checkPermissionList = (list, permission) => {
  checkList(list);
  function checkList(items) {
    _.forEachRight(items, o => {
      if (o.permission) {
        let included = false;
        if (_.isArray(o.permission)) included = o.permission.includes(permission);
        else included = o.permission === permission;

        if (!included) _.remove(items, v => v === o);
      } else if (o.excluded) {
        if (_.isArray(o.excluded) && o.excluded.includes(permission)) _.remove(items, v => v === o);
        else if (o.excluded === permission) _.remove(items, v => v === o);
      }
      if (o.children && o.children.length) checkList(o.children);
    });
  }
  return list;
};
