<template>
  <div ph-poi-floating v-html="poiPopover">
  </div>
</template>

<script>
export default {
  name: 'PhPoiFloating',
  data() {
    return {
      poiPopover: null,
      popoverList: [
        'kyc.documentType.IdentityCard',
        'kyc.documentType.Passport',
        'kyc.documentType.DrivingLicence',
        'kyc.documentType.UnifiedMultiPurpose',
        'kyc.documentType.ProfessionalRegulationCommission',
        'kyc.documentType.PostalIdentityCard',
        'kyc.documentType.VoterCard'
      ],
    };
  },
  async mounted() {
    this.poiPopover = this.popoverList.map(i => `• ${this.$t(i)}`).join('<br/>');
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[ph-poi-floating] { box-shadow: 0 6px 12px rgba(0,0,0,0.175); .p(12); .br(4); .bgc(#0e1415); .fs(13); .c(white);
  >span { .c(@c-white); .fs(14); }
  >a { .fs(14); .c(#4A8CFF); .-b(#4A8CFF); .medium(); }
}
</style>