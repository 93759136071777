<template>
  <div range-selector>
    <Slider :info="types" slides-per-view="auto" :space-between="8">
      <template v-slot:slide="{info, idx}">
        <button :class="{'active': activeId === idx}" @click="reset(idx)">{{ $t(info.label) }}</button>
      </template>
    </Slider>
    <CalendarList v-if="model" @input="updateActive" v-model="model" />
  </div>
</template>

<script>
import CalendarList from '@shared/components/common/date/CalendarList.vue';
import Slider from '@shared/components/general/Slider.vue';
import { getYMD, getWeekStart, getWeekEnd, getWeekBefore } from '@shared/utils/timeUtils.mjs';
import moment from 'moment';

export default {
  name: 'RangeSelector',
  components: { CalendarList, Slider },
  props: {
    value: Object,
  },
  watch: {
    value: 'update',
  },
  data() {
    return {
      types: [
        { label: 'thisWeek' }, { label: 'lastTwoWeeks' }, { label: 'lastTreeWeeks' }, { label: 'lastFourWeeks' },
      ],
      activeId: -1,
      model: null,
    };
  },
  computed: {
    today() {
      return getYMD(moment());
    },
    week() {
      return getWeekStart();
    },
    twoWeeks() {
      return getWeekEnd(-7);
    },
    threeWeeks() {
      return getWeekBefore(-2);
    },
    fourWeeks() {
      return getWeekBefore(-3);
    },
    isWeek() {
      return this.model.start === this.week;
    },
    isTwoWeeks() {
      return this.model.start === this.twoWeeks;
    },
    isThreeWeeks() {
      return this.model.start === this.threeWeeks;
    },
    isFourWeeks() {
      return this.model.start === this.fourWeeks;
    },
  },
  methods: {
    getWeeks(v) {
      return getYMD(moment().add(v, 'week'));
    },
    reset(id) {
      switch (id) {
        case 0:
          this.model = { start: this.week, end: getWeekEnd() };
          break;
        case 1:
          this.model = { start: this.twoWeeks, end: getWeekEnd() };
          break;
        case 2:
          this.model = { start: this.threeWeeks, end: getWeekEnd() };
          break;
        case 3:
          this.model = { start: this.fourWeeks, end: getWeekEnd() };
          break;
        default:
          this.activeId = -1;
          break;
      }
      this.updateActive();
    },
    update() {
      this.model = this.value || {};
      this.updateActive();
    },
    getActiveId() {
      return [this.isWeek, this.isTwoWeeks, this.isThreeWeeks, this.isFourWeeks].findIndex(v => v);
    },
    updateActive() {
      this.activeId = this.getActiveId();
      this.$emit('input', this.model);
    },
  },
  mounted() {
    this.update();
  },
};
</script>

<style lang="less">
@import "~@shared/less/proj.less";
[range-selector] { .max-w(300); .p(12); .br(8); .bgc(white); .bs(1, 2, 5, 1, .1); .-box();
  .swiper-container { .pb(12); .mb(12); .-b(@c-b02);
    .swiper-slide { .w(auto);
      button { .p(12, 12); }
    }
  }
  button { .p(8, 4); .br(8); .bgc(transparent);
    &.active { .bgc(@c-mint); .c(white); .bold(); }
  }
  @media (@tp-up) {
    .min-w(500);
  }
}
</style>
