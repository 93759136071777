export default () => ({
  namespaced: true,
  state: {
    info: null,
    displayTnc: null,
    currencies: null,
  },
  mutations: {
    setInfo(state, info) {
      state.info = info;
    },
    setDisplayTnc(state, displayTnc) {
      state.displayTnc = displayTnc;
    },
    setCurrencies(state, currencies) {
      state.currencies = currencies;
    },
  },
});
