import { isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  data() {
    return {
      error: {
        error: true,
        errorMsg: null,
      },
    };
  },
  computed: {
    brand: state('env', 'brand'),
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    mobileMode() {
      return this.modelInfo.LoginMethod === 'Mobile';
    }
  },
  methods: {
    apiErrorHandler(error) {
      this.error.error = true;
      if (this.isGgpass) return this.opErrorHandler(error);
      else return this.npErrorHandler(error);
    },
    opErrorHandler(error) {
      const { key, desc } = error;
      switch (key) {
        case 'BRAND_SITE_TEMPLATE_CONFIG_NOT_FOUND' : {
          this.error.errorMsg = this.$t(apiErrorCode.BRAND_SITE_TEMPLATE_CONFIG_NOT_FOUND);
          return;
        }
        case apiErrorCode.ONEPASS_NOT_FOUND: {
          this.error.errorMsg = this.$t('FORGOT_ONEPASS_NOT_FOUND', { method: this.mobileMode ? this.$t('mobileNumber') : this.$t('emailAddress') });
          return;
        }
        case 'FORGOT_ONEPASS_NOT_FOUND': {
          this.error.errorMsg = this.$t('FORGOT_ONEPASS_NOT_FOUND', { method: this.mobileMode ? this.$t('mobileNumber') : this.$t('emailAddress') });
          return;
        }
        case 'RESEND_TERM_LIMITED' : {
          return null;
        }
        case apiErrorCode.RESEND_LIMITED : {
          this.error.errorMsg = this.$t(key, { method: this.mobileMode ? this.$t('mobileNumber') : this.$t('emailAddress') });
          break;
        }
        default : {
          this.error.errorMsg = this.$t(key, {csEmail : this.csEmail});
        }
      }
    },
    npErrorHandler(error) {
      const { key, desc } = error;
      switch (key) {
        case 'MOBILE_NOT_FOUND':
        case 'EMAIL_NOT_FOUND' : {
          this.error.errorMsg = this.$t(apiErrorCode.ONEPASS_NOT_FOUND, { fieldName: this.mobileMode ? this.$t('mobileNumber') : this.$t('emailAddress') });
          break;
        }
        case apiErrorCode.INVALID_EMAIL :
        case 'INVALID_EMAIL_LENGTH' : {
          this.error.errorMsg = this.$t(apiErrorCode.INVALID_EMAIL);
          break;
        }
        case 'EMAIL_RESEND_TERM_LIMITED' :
        case 'MOBILE_RESEND_TERM_LIMITED' : {
          return null;
        }
        case 'EXCEED_VERIFICATION_CODE_SEND_COUNT' : {
          this.error.errorMsg = this.$t(key);
          break;
        }
        case 'VERIFICATIONTOKEN_NOT_FOUND' :
        case 'INTERNAL_ERROR' : {
          this.error.errorMsg = this.$t('VERIFICATIONTOKEN_NOT_FOUND');
          break;
        }
        case apiErrorCode.ACCOUNT_NOT_FOUND:
          this.error.errorMsg = this.$t('ACCOUNT_NOT_FOUND', { method : this.mobileMode ? this.$t('mobileNumber') : this.$t('emailAddress'),brand: this.brand });
          break;
        case apiErrorCode.INVALID_REQUEST:
          this.error.errorMsg = this.$t(apiErrorCode.INVALID_REQUEST, { csEmail: this.csEmail });
          break;
        default : {
          this.error.errorMsg = this.$t(key, {csEmail : this.csEmail});
          break;
        }
      }
    },
    async statusHandler(status) {
      let error = false;
      let key = '';

      switch (status) {
        case 'USED' :
          key = 'RESET_PASSWORD_USED';
          break;
        case 'EXPIRED' :
          key = 'RESET_PASSWORD_EXPIRED';
          break;
      }

      if (error) {
        return { key, error: true };
      }
      return { key: null, error: false };
    },
    resetError() {
      this.error.error = false;
      this.error.errorMsg = null;
    }
  },
};