<template>
  <label price-unit :class="getPriceClass(value)">{{ getPrice(value, currency, option, fixedCurrency) }}</label>
</template>

<script>
import { makeCurrency, roundUp } from '@shared/utils/currencyUtils.mjs';
import _ from 'lodash';

export default {
  name: 'PriceUnit',
  props: {
    value: { type: [String, Number], default: null },
    option: { type: String, default: null },
    fixedCurrency: { type: String, default: null },
  },
  methods: {
    getPrice(value, currency, option, fixedCurrency) {
      if (value !== null) {
        if (!_.isNumber(value)) return '';
        value = roundUp(value);
        return makeCurrency(value, currency, option, fixedCurrency);
      }
      return '-';
    },
    getPriceClass(value) {
      if (!value) return;
      return value < 0 ? 'minus' : '';
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[price-unit] { .fs(14);
  &.minus { .c(@c-red); }
}
</style>
