<template>
  <JumioContainer jumio-oasis-failed>
    <div class="desc">
      <p v-if="customerCode == 'OASIS_BAN_EXIST'" v-html="$t('_.desc.yourAccountWasSuspended', { csEmail })" />
      <p v-if="customerCode == 'FAILED_SELF_EXCLUSION_CHECK'" v-html="$t('_.desc.verificationOnOASISFailed', { code: responseCode, csEmail })" />
    </div>
    <ProgressButton button-name="close" @click="$emit('confirm')" />
  </JumioContainer>
</template>

<script>
import JumioContainer from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioContainer.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'JumioOasisFailed',
  lexicon: 'errorPages.jumio.oasis',
  components: { ProgressButton, JumioContainer },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    customerCode() {
      // TODO : [celina] GetKycOutsideVerificationStepRequest, VerifyKycOutsideIdentityRequest 에서 data.CustomerCode로 체크
      // return 'FAILED_SELF_EXCLUSION_CHECK';
      return 'OASIS_BAN_EXIST';
    },
    responseCode() {
      // TODO : [celina] RESPONSE_CODE: Oasis Error Code(CustomerErrorParameters[0])
      return '12345';
    },
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[jumio-oasis-failed] {}
</style>