import { getHasAsyncDataComponentsSorted } from '@shared/utils/routerUtils.mjs';

export default createApp => ({ beforeCreate }) => context => {
  return new Promise((resolve, reject) => {
    (async () => {
      const { app, router, store, services } = await createApp(context.cookies, async (services, store) => {
        beforeCreate(context, store);
        await services.init(context.url, context.host, context.queries, store, context.cookies, context.header);
      });

      try {
        router.onError(reject);
        await router.push(context.url);
        const matchedComponents = router.getMatchedComponents();
        if (!matchedComponents.length) return reject({ code: 404 });

        const COMPONENTS_STATE = {};
        const route = router.currentRoute;
        const internalError = [];
        const sharedScope = {};
        const sortedByPriority = getHasAsyncDataComponentsSorted(matchedComponents);
        for (const components of sortedByPriority) {
          await Promise.all(components.map(component => {
            return component.asyncData({ store, route, services, sharedScope, mixinFetcher: component.mixins?.find(m => m.fetch) }).catch(e => {
              if (e.org?.response) {
                internalError.push({ request: e.org.request.path, response: e.org.response.data });
              } else {
                internalError.push(e);
              }
            }).then(result => {
              if (!component.name) throw new Error(`[asyncData] 'name' property is required`);
              if (COMPONENTS_STATE[component.name]) throw new Error(`[asyncData] duplicated name(${component.name}) property`);
              COMPONENTS_STATE[component.name] = component.__INITIAL_STATE__ = result;
            });
          }));
        }
        for (const component of matchedComponents) {
          if (!component.afterAsyncData) continue;
          await component.afterAsyncData({ store, route, services, sharedScope }).catch(reject);
        }

        context.rendered = () => {
          if (app.$i18n) {
            context.lang = app.$i18n('lang');
            context.country = app.$i18n('country');
          }
          context.gtmId = process.env.VUE_APP_GTM_ID || '';

          context.state = store.state;
          context.state.__COMPONENTS_STATE__ = COMPONENTS_STATE;
          context.meta = app.$meta();
          context.internalError = internalError.length ? internalError : null;
        };

        resolve(app);
      } catch (e) {
        if (e.code === 'NOT_FOUND') reject({ code: 404 });
        else reject({ code: 500, stack: e.org || e });
      }
    })();
  });
};
