<template>
  <div input-two-ace-loss-limit>
    <RadioList type="vertical" :custom-radio="ResponsibleRadioItem" :list="itemList" v-model="model.RequestDailyLossLimit" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import ResponsibleRadioItem from '@/views/components/pages/responsible-gaming/template/common/ResponsibleRadioItem.vue';
import RadioList from '@/views/components/gg-pass/RadioList.vue';
export default {
  name: 'InputAutomaticReserveWin',
  lexicon: 'onBoarding.setting',
  components: { RadioList },
  props: {
    model: Specific,
    current: Specific,
    currencySymbol: { type: String, default: '$' }
  },
  data() {
    return {
      group: null,
      TextInputBox,
      ResponsibleRadioItem
    };
  },
  computed: {
    itemList() {
      return [
        { value: '100000', label: '8억 코인 (10만원)', },
        { value: '700000', label: '56억 코인 (70만원)', },
      ];
    },
  },
  methods: {},
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-two-ace-loss-limit] {.flex(); .flex-dc(); .gap(15); .br(4); .pv(20); }
</style>