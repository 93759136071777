<template>
  <div input-email-address>
    <EmailSearchSelect :model-info="model" :structure="errorObj" rules="requiredSignEmail|required" />
<!--    <InputComponent name="_email" :label="$t('emailAddress')" :component="TextInputBox" v-model="model.Email" rules="requiredSignEmail|required" :preset="{type: 'email'}" />-->
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import CountryNumberDropDown from '@/views/components/gg-pass/CountryNumberDropDown.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import LimitTimer from '@/views/components/pages/on-boarding/LimitTimer.vue';
import EmailSearchSelect from '@/views/components/common/EmailSearchSelect.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';

export default {
  name: 'InputEmailAddress',
  lexicon: 'myInfo',
  components: { ValidationComponent, EmailSearchSelect, LimitTimer, InputComponent },
  props: {
    model: Specific,
    error: Specific,
    resendTime: 0,
    disabled: { type: Boolean, default: false, },
    isVerified: { type: Boolean, default: false, }
  },
  data() {
    return {
      errorObj: {
        errorMsg: null,
      },
      TextInputBox,
    };
  },
  watch: {
    'error.Email': function () {
      this.errorObj.errorMsg = this.error.Email;
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-email-address] { .flex(); .flex-dc(); .w(100%); .bgc(@gp-bgc-gray);.p(20, 15, 20, 15); .br(16); .rel();
  >div {.flex(); .flex-dc(); .gap(5); }
  >[email-search-select] {
    [validation-component] {
      .holder {
        &.focus {background-color: transparent !important;}
      }
      .input-field {background-color: transparent; border: none; box-shadow: none;
        > .label {.c(#737373); .t(0);
          >label{.pl(3); .mb(5); .fs(14); .pt(0);}
          >.mandatory::after {.c(@gp-green);}
        }
        .field{.flex(); .flex-dc();
          > [search-drop-select] { .c(black); .br(8);
            > a {.p(0) !important; .h(40);
              > span { .h(40);
                > input {.c(black); .fs(14); font-weight: 400; .bgc(#fff); .h(40); .p(10, 12, 10, 12);}
              }
            }
            .list-container {box-shadow: none; .bgc(#fff); .br(8); pointer-events: none;
              &.on {pointer-events: auto;}
              > .list-inner > .scroll-holder > .size-holder button {.c(black);
                &.active {.bgc(#f8fbf9);}
                &.focus {.bgc(#f8fbf9);}
              }
            }
          }
        }
      }
    }
    > div > [validation-errors] {.pl(0); .ml(5); .mt(0);}
  }
  [validation-errors] {.pl(5);}
}
</style>