<template>
  <SumsubContainer sumsub-start>
    <div v-if="message" class="message">
      <FontIcon name="info-circle-line" />
      <div>{{ message }}</div>
    </div>
    <h3>{{ $t('_.description') }}</h3>
    <div class="document-holder">
      <div class="step-holder">
        <div class="icon-holder">
          <FontIcon class="identity" name="identity-document-upload" />
        </div>
        <div>
          <span class="step">{{`${$t('step')}1`}}</span>
          <span class="step-title">{{$t('_.identityTitle')}}</span>
          <span class="step-description1">{{$t('_.identityDescription1')}}</span>
          <span class="step-description2">{{$t('_.identityDescription2')}}</span>
          <div>
            <span class="doc-title">
              <FontIcon class="identity" name="hourglass-round-regular" />
              {{$t('kyc.documentType.DrivingLicence')}}
            </span>
          </div>
          <div>
            <span class="doc-title">
              <FontIcon class="identity" name="hourglass-round-regular" />
              {{$t('kyc.documentType.Passport')}}
            </span>
          </div>
          <div>
            <span class="doc-title">
              <FontIcon class="identity" name="bolt-round-regular" />
              {{$t('kyc.documentType.IdentityCard')}}
            </span>
            <ul>
              <li>{{$t('kyc.documentType.NationalId')}}</li>
              <li>{{$t('kyc.documentType.CitizenshipCard')}}</li>
              <li>{{$t('kyc.documentType.PhotoCard')}}</li>
              <li>{{$t('kyc.documentType.ProvincialOrTerritorialIdentityCard')}}</li>
              <li>{{$t('kyc.documentType.ProvincialServicesCard')}}</li>
              <li>{{$t('kyc.documentType.SecureCertificateOfIndianStatus')}}</li>
            </ul>
          </div>
          <div>
            <span class="doc-title">
              <FontIcon class="identity" name="bolt-round-regular" />
              {{$t('kyc.documentType.ResidencePermit')}}
            </span>
            <ul>
              <li>{{$t('kyc.documentType.NexusCard')}}</li>
              <li>{{$t('kyc.documentType.GlobalEntryCard')}}</li>
              <li>{{$t('kyc.documentType.PermanentResidentCard')}}</li>
              <li>{{$t('kyc.documentType.GreenCard')}}</li>
              <li>{{$t('kyc.documentType.BorderCrossingCard')}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="document-holder">
      <div class="step-holder">
        <div class="icon-holder">
          <FontIcon class="selfie" name="liveness-check" />
        </div>
        <div>
          <span class="step">{{`${$t('step')}2`}}</span>
          <span class="step-title">{{$t('_.selfieTitle')}}</span>
        </div>
      </div>
    </div>
    <ProgressButton button-name="continue" @click="$emit('confirm')" />
  </SumsubContainer>
</template>

<script>
import SumsubContainer from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubContainer.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import SvgIcon from '@shared/components/common/SvgIcon.vue';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'SumsubStart',
  lexicon: 'onBoarding.sumsub.start',
  components: { FontIcon, SumsubContainer, ProgressButton },
  props: {
    data: Specific
  },
  computed: {
    message() {
      return this.data?.KycRequestMessage;
    },
  }
};
</script>

<style lang="less" scoped>
@import '~@/less/proj.less';
[sumsub-start] {
  .message { .flex(); .flex-ai(); .mb(24); .p(24); .br(8); .bgc(#333);
    > [font-icon] { .fs(24); .mr(8); }
    > div { word-break: break-word; .pre-line(); }
  }
  h3 { .fs(18, 22, normal); .c(#ccc); .regular(); .mb(24); }
  span { .block(); .regular();
    &.step { .c(#7F7F7F); .fs(16, 20, normal); .mt(20); }
    &.step-title { .c(#fff); .fs(22, 27, normal); .mt(4); }
    &.step-description1 {.c(#7F7F7F); .fs(16, 20, normal); .mt(8); }
    &.step-description2 {.c(#FFC64D); .fs(16, 20, normal); .mt(4); }
    &.doc-title { .flex(); .flex-ai(); .c(#ccc); .fs(18, 22, normal); .mt(8);
      [font-icon] { .fs(16); .mr(4); }
    }
  }
  ul { .mt(4); .ml(16);
    li { .pl(4); .rel(); .c(#7F7F7F); .fs(14, 16, normal);
      &:before { .cnt(); .wh(3); .br(50%); .bgc(#7F7F7F); .abs(); .lt(-8,50%); .t-yc(); }
    }
  }
  .document-holder + .document-holder {
    .mt(20);
  }
  .step-holder { .flex();
    .icon-holder { .flex(); flex: 0; flex-basis: 72px; .flex-center(); .wh(72); .mr(16); .br(50%); .bgc(#3e3e3e);
      & + div { flex: 1; }
      [font-icon].identity { .fs(32); }
      [font-icon].selfie { .fs(36); }
    }
  }

  @media (@ml-up) {
    .step-holder .icon-holder { flex-basis: 96px; .wh(96);
      [font-icon].identity { .fs(36); }
      [font-icon].selfie { .fs(40); }
    }
  }
}
</style>
