<template>
  <p footer-ph>{{ $t('personalDetails.footer.ph') }}</p>
</template>

<script>
export default {
  name: 'FooterPh'
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[footer-ph] { .tc(); .fs(14); .mt(24);
  &:before { content: '*'; .ib(); .vam(); .mr(4); .mt(2); }
}
</style>