<template>
  <JumioContainer jumio-manual-information>
    <h3>{{ $t('onBoarding.title.verificationRequest') }}</h3>
    <div class="desc">
      <p>{{ $t('onBoarding.jumio.description.uploadRequestedDocuments') }}</p>
      <p v-html="$t('onBoarding.jumio.description.forFurtherAssistance', { csEmail })" />
    </div>
    <div class="contents" v-if="kycRequestMessage">
      <p>{{ kycRequestMessage }}</p>
    </div>
    <ProgressButton button-name="upload" @click="$emit('confirm')" />
  </JumioContainer>
</template>

<script>
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import JumioContainer from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioContainer.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'JumioManualInformation',
  lexicon: 'onBoarding.jumio.manualInformation',
  props: {
    kycRequestMessage: { type: String },
  },
  components: { JumioContainer, ProgressButton },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
  },
};
</script>

<style lang="less" scoped>
@import '~@/less/proj.less';
[jumio-manual-information] {}
</style>