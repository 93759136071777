<template>
  <div popover-item>
    <slot />
  </div>
</template>

<script>
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'PopoverItem',
  props: {
    el: { type: HTMLElement, default: null },
    useClick: { type: Boolean, default: false },
  },
  methods: {
    checkClickOutside(e) {
      const term = 10;
      const { pageX, pageY } = e;
      const rect = this.$el.getBoundingClientRect();
      const sy = window.scrollY;
      if (pageX < rect.left - term || pageX > rect.left + (rect.width + term) || pageY < (rect.top - sy) - term || pageY > sy + rect.top + (rect.height + term)) this.$emit('remove');
    },
  },
  async mounted() {
    /** @todo popover left 위치가 - 인 경우만 임시 처리.. 개선포인트가 있음 */
    if (this.$el.getBoundingClientRect().left < 0) {
      this.$el.parentElement.style.left = '10px';
      this.$el.parentElement.style.right = 'auto';
      this.$el.parentElement.style.maxWidth = `${window.innerWidth - 20}px`;

      const arrow = /** @type { HTMLElement } */this.$el.querySelector('.arrow');
      const rect = this.el.getBoundingClientRect();
      arrow.style.left = `${rect.left - 4}px`;
    }
    if (this.useClick) {
      await sleep(100);
      document.addEventListener('mousedown', this.checkClickOutside);
    }
  },
  beforeDestroy() {
    if (this.useClick) document.removeEventListener('mousedown', this.checkClickOutside);
  },
};
</script>
