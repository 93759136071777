<template>
  <GpFormTemplate np-mobile-number>
    <template v-if="!isContactInfo" #header>
      <GpDescription :message="description" />
    </template>
    <template #content="{invalid}">
      <InputMobileNumber :model="model" :is-verified="model.IsMobileVerified && isDefaultStep" :current-mobile-number="currentMobileNumber" :error="error" :invalid="invalid" />
      <CheckboxItem v-if="isChangeId && !isTwoAce" :class="['via-contact']" theme="light" :selected="model.IsContactUpdate" @change="updateWithContactInfo">{{ $t('_.mobileNumber.updateContactInfo') }}</CheckboxItem>
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click.prevent="confirm(validate)" :disabled="!!invalid || disableButton">{{ $t('_.send') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import Specific from '@shared/types/Specific';
import { isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { routeNames } from '@/constants/model/my-page';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import InputMobileNumber from '@/views/components/pages/my-page/template/inputs/InputMobileNumber.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import npErrorHandler from '@/mixins/my-info/npErrorHandler';
import npStepInfo from '@/mixins/my-info/npStepInfo';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';

export default {
  name: 'NpMobileNumber',
  components: { GpDescription, InputMobileNumber, GpButton, GpFormTemplate, CheckboxItem },
  lexicon: 'myInfo',
  mixins: [npErrorHandler, npStepInfo],
  props: {
    isModal: { type: Boolean, default: false, },
    structure: { type: Specific, default: null },
    model: Specific,
  },
  data() {
    return {
      currentMobileNumber: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    onePassInfo: state('user', 'onePassInfo'),
    brand: state('env', 'brand'),
    disableButton() {
      if(this.model.MobileNumber === null) return true;
      if(this.model.CountryCode === null) return true;
      return this.error.MobileNumber !== null
    },
    isChangeId() {
      return this.$route.name === routeNames.NpMobileNumber;
    },
    isContactInfo() {
      return this.$route.name === routeNames.NpContactMobileNumber;
    },
    isMfaSetting() {
      return this.$route.name === routeNames.NpMfaMobileNumber;
    },
    verificationType() {
      return this.isContactInfo ? 'ContactInfo' : 'LoginId';
    },
    kind() {
      return this.isContactInfo ? 'ContactInfo' : 'LoginId';
    },
    isGGpass() {
      return !isExcludeGgpass(this.site);
    },
    isTwoAce() {
      return this.site === siteIds.TWOACE;
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    isWSOP() {
      return this.site === siteIds.WSOPON;
    },
    description() {
      const nonGgpassBrand = this.isWSOP ? 'GGPoker.ca' : this.brand;
      const brand = this.isGGpass ? 'GGPass' : nonGgpassBrand;
      if (this.isChangeId) return this.$t('_.mobileNumber.description', { brand: brand });
      else if (this.isContactInfo) return null;
      else if (this.isMfaSetting) return this.$t('_.mfa.setMfaDescription', { method: this.$t('mobileNumber') });
      else return '';
    },

  },
  methods: {
    async initialize() {
      const result = await this.$myinfo.fetch({payload : null, block : true});

      this.model.LoginMethod = 'Mobile';
      this.model.SendVerificationType = 'MOBILE';

      this.setType();
      this.ggpassInitialize();
      this.npInitialize(result);
      this.changeIdSetting(result);
      this.mfaInitialize();
      this.contactInfoSetting(result);
    },

    ggpassInitialize() {
      if (!this.isGGpass) return;
      this.model.IsMobileVerified = this.onePassInfo.isMobileVerified;
    },

    npInitialize(result) {
      if (this.isGGpass) return;
      this.model.IsMobileVerified = result?.IsMobileVerified;
    },

    changeIdSetting(result) {
      if (!this.isChangeId) return;
      this.currentMobileNumber = (result.MobileCountryCode && result.MobileNumber) ? `+${result.MobileCountryCode} ${result.MobileNumber}` : null;
      this.structure.title = this.currentMobileNumber ? 'myInfo.mobileNumber.changeLoginId' : 'myInfo.mobileNumber.registerLoginID';
      this.model.IsUpdateContactInfo = true;
    },

    contactInfoSetting(result) {
      if (!this.isContactInfo) return;
      const { ContactInfo } = result;
      this.currentMobileNumber = ContactInfo.ContactMobileCountryCode && ContactInfo.ContactMobileNumber ? `+${ContactInfo.ContactMobileCountryCode} ${ContactInfo.ContactMobileNumber}` : null;
      this.model.IsUpdateContactInfo = false;
    },

    mfaInitialize() {
      if (!this.isMfaSetting) return;
      this.model.IsUpdateContactInfo = false;
      const { VerificationMethod, SecurityLevel, UsualLoginSecurity, UnusualLoginSecurity } = this.model;
      if (this.isGGpass) {
        if (!VerificationMethod || !SecurityLevel) {
          this.replaceRouteName('NpLoginSecurity');
          return;
        }
      } else {
        if (!VerificationMethod || !SecurityLevel || !UsualLoginSecurity || !UnusualLoginSecurity) {
          this.replaceRouteName('NpLoginSecurity');
          return;
        }
      }

    },

    setType() {
      const route = this.$route.name;

      switch (route) {
        case routeNames.NpContactMobileNumber:
          this.model.Type = 'CONTACT_INFO';
          this.model.ApiType = 'CONTACT_INFO';
          break;
        case routeNames.NpMfaMobileNumber:
          this.model.Type = 'MFA_TYPE_ADD';
          this.model.ApiType = 'MFA_TYPE_ADD';
          break;
        default:
          this.model.Type = 'CHANGE_LOGIN_ID';
          this.model.ApiType = 'CHANGE_LOGIN_ID';
          break;
      }
    },

    updateWithContactInfo(v) {
      this.model.IsContactUpdate = v;
    },

    createPayload() {
      if (this.isGGpass && !this.isContactInfo) {
        if (this.isMfaSetting) return this.model;
        else return { ...this.model, CountryCode: `+${this.model.CountryNumber}`, VerificationCode : null, };
      } else {
        return { ...this.model, VerificationType: this.verificationType, VerificationCode : null, };
      }
    },

    async confirm(validate) {
      const payload = this.createPayload();
      this.structure.resendInitTime = 0;
      const result = await this.$myinfo.request(payload, this.model.Type);
      if (result?.error) {
        const errorResult = this.apiErrorHandler(result);
        if (errorResult?.key === 'limit') {
          this.structure.limitTime = +errorResult.value;
          return await this.routeName('NpVerifyCode');
        }
        return;
      }

      // if (this.isGGpass && !this.isContactInfo) this.model.Token = result.token;
      return await this.routeName('NpVerifyCode');
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-mobile-number] {
  .mobile-holder { .flex(); .flex-dc(); .w(100%); .gap(10); .bgc(#f8f8f8);.p(20, 15, 20, 15); .br(4);
    > div {.flex(); .flex-dc(); .gap(5);}
  }
  .timer {.c(red)}
  .via-contact { .mt(10); .c(black); .fs(14); line-height: 16px;
    &.disable { .c(#757575);
      > i { .-a(#EEEEEE); .bgc(#757575);
        > svg {fill: white;}
      }
    }
  }
}

</style>