<template>
  <div promotion-title>
    <h2>
      <span>{{ $t(`promotion.type.${type}`) }}</span>
      <button @click="$emit('reset')"><FontIcon name="rotate-right" /></button>
    </h2>
    <PromotionTncLink v-if="!hideTcn" />
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';
import PromotionTncLink from '@/views/components/pages/promotion/PromotionTncLink.vue';
import { state } from '@shared/utils/storeUtils';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'PromotionTitle',
  components: { PromotionTncLink, FontIcon },
  props: {
    info: Specific,
    type: { type: String, require: true },
  },
  computed: {
    site: state('env', 'site'),
    hideTcn() {
      if(!this.site) return true;
      return [siteIds.GGPUKE, siteIds.EVPUKE, siteIds.DAVAOPOKER, siteIds.SEVENXL, siteIds.NATURAL8, siteIds.N8IN, siteIds.TWOACE].includes(this.site);
    },
  },
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[promotion-title] { .flex(); .flex-dc(); .gap(4px 24px); .p(20); .c(@c-white);
  h2 { .flex(); .justify-start(); .items-center(); .gap(12); .fs(22); .semi-bold();
    [font-icon] { .fs(16); .c(@c-gray4); }
  }

  @media(@mm-up) { .flex-dr(); .space-between(); }
  @media(@tp-up) { .ph(0); }
}
</style>
