export const symbols = {
  EUR: '€',
  BRL: 'R$',
  CAD: '$',
  CNY: '¥',
  HUF: 'Ft',
  GBP: '£',
  JPY: 'JP¥',
  MXN: 'MEX$',
  NOK: 'kr',
  PLN: 'zł',
  RON: 'lei',
  RUB: '₽',
  KRW: '₩',
  UAH: '₴',
  GCD: 'C$',
  GTD: 'T$',
  INR: '₹',
  CZK: 'Kč',
  XXX: ''
};

export const getPromotionCurrencySymbol = v => {
  return symbols[v] || '$';
};
