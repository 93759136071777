<template>
  <div input-re-login-limit>
    <InputComponent :label="$t('responsibleGaming.label.setTime')" name="_reLoginTimeLimit" :component="SearchDropSelect" :value="model.RequestReLoginTimeLimit" @input="input" :preset="preset" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';

export default {
  name: 'InputReLoginTimeLimit',
  lexicon: 'onBoarding.setting',
  components: { InputComponent },
  props: {
    model: Specific,
    current: Specific,
    currencySymbol: { type: String, default: null }
  },
  data() {
    return {
      SearchDropSelect
    };
  },
  computed: {
    preset() { return { list: this.timeList, auto: true, translate: false, placeholder: this.$t('noLimit'), selectOnly: true }; },
    timeList() {
      return [
        { label: this.$t('noLimit'), value: 'None' },
        { label: `60 ${this.$t('minute')}`, value: '60' },
        { label: `30 ${this.$t('minute')}`, value: '30' },
        { label: `15 ${this.$t('minute')}`, value: '15' },
        { label: `10 ${this.$t('minute')}`, value: '10' },
        { label: `5 ${this.$t('minute')}`, value: '5' },
      ];
    }
  },
  methods: {
    input(v) {
      if (!v) return;
      this.model.RequestReLoginTimeLimit = v?.value === 'None' ? null : v?.value;
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-re-login-limit] {.flex(); .flex-dc(); .br(16); .bgc(#EBEBEF); .p(20); }
</style>