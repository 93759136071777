import Vue from 'vue';
import Vuex from 'vuex';
import { store as browser } from '@shared/modules/Browser';
import { sleep } from '@shared/utils/commonUtils.mjs';
import user from './user.store';
import query from './query.store';
import env from './env.store';
import config from './config.store';
import promotion from './promotion.store';

Vue.use(Vuex);
export default () => new Vuex.Store({
  state: {
    hideAction: false,
    resendCloseSecond: 0,
    resendSumSecond: 0,
    showId: 1,
  },
  mutations: {
    async setResendCloseSecond(state, sec) {
      state.resendCloseSecond = sec;
    },
    async setResendSumSecond(state, sec) {
      state.resendSumSecond = sec;
    }
  },
  modules: {
    user: user(),
    browser: browser(),
    query: query(),
    env: env(),
    config: config(),
    promotion: promotion(),
  },
});