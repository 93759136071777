<template>
  <div optional-name v-if="modelInfo">
    <MiddleName :model-info="modelInfo" :structure="structure" :item="item" />
    <AliasName :model-info="modelInfo" :structure="structure" :item="item" />
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import AliasName from '@/views/components/pages/on-boarding/template/extra/AliasName.vue';
import MiddleName from '@/views/components/pages/on-boarding/template/extra/MiddleName.vue';

export default {
  name: 'OptionalName',
  components: { MiddleName, AliasName },
  mixins: [baseTemplate],
  lexicon: 'personalDetails',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[optional-name] {
  >*:nth-of-type(2) { .mt(8); }
}
</style>