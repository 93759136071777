<template>
  <div search-google-address>
    <ValidationComponent :label="$t('_.searchAddress')" name="_address" :component="AddressSearchSelect" rules="required" v-model="searchInfo" :preset="addressPreset" />
    <a class="btn-update-field" @click.stop="reset">{{ $t('_.enterManually') }}</a>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import AddressSearchSelect from '@/views/components/pages/on-boarding/AddressSearchSelect.vue';
import Specific from '@shared/types/Specific';
import baseTemplate from '@/mixins/baseTemplate';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'SearchGoogleAddress',
  lexicon: 'personalDetails',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      placeholder: '',
      searchInfo: null,
      AddressSearchSelect,
    };
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    addressPreset() {
      return {placeholder: this.placeholder, maxLength: 100, type: 'google-address', autoOpen: true};
    },
  },
  watch: {
    searchInfo: 'updateValue',
  },
  methods: {
    /**
     * 초기 진입 시 placeHolder 설정
     */
    initialize() {
      this.placeholder = this.$t('_.personalAddressSearchPlaceHolder');
    },
    /**
     * 직접 입력 이동
     */
    reset() {
      this.$emit('reset', false);
      this.$emit('selected', null);

      this.resetInfo();
    },
    /**
     * 검색 정보 초기화
     */
    resetInfo() {
      this.searchInfo = null;
    },
    /**
     * 검색된 메인 주소 DropListBox에서 결과 선택 시 호출
     */
    updateValue() {
      if (!this.searchInfo?.selectedItem) return;

      const { selectedItem } = this.searchInfo;
      const { Text, Description } = selectedItem;
      this.placeholder = `${Text} ${Description}`;

      this.$emit('reset', this.useSub);
      this.$emit('selected', this.searchInfo);

      this.resetInfo();
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[search-google-address] {
  > .btn-update-field { .mt(16); .inline-flex(); .items-center(); .fs(16); .c(#aeaeae); .-b(#aeaeae);
    &:hover { .c(#aeaeae); .-b(@black-bg); .tr-d(.3); }
    > [font-icon] { .fs(8); .bold(); .mr(4); }
  }
}
</style>