import domainList from '@/constants/domainList.json';
import { cdnImgPath } from '@shared/utils/cdnUtils.mjs';

export const envDomainList = domainList[process.env.VUE_APP_ENV];

export const baseInfo = { ...process.env };

export const siteIds = {
  GGPUKE: 'GGPUKE',
  NATURAL8: 'NATURAL8',
  N8IN: 'N8IN',
  GGPOK: 'GGPOK',
  GGPCOM: 'GGPCOM',
  GGPUK: 'GGPUK',
  GGPEU: 'GGPEU',
  GGPFI: 'GGPFI',
  GGPPL: 'GGPPL',
  GGPDE: 'GGPDE',
  GGPBE: 'GGPBE',
  GGPHU: 'GGPHU',
  GGPNL: 'GGPNL',
  GGPRO: 'GGPRO',
  GGPBR: 'GGPBR',
  GGPUA: 'GGPUA',
  GGPCW: 'GGPCW',
  GGPJP: 'GGPJP',
  GGPNG: 'GGPNG',
  DAVAOPOKER: 'DAVAOPOKER',
  EVPUKE: 'EVPUKE',
  TWOACE: 'TWOACE',
  WSOPON: 'WSOPON',
  SEVENXL: 'SEVENXL',
  GGPCZ: 'GGPCZ',
  GGPPH: 'GGPPH',
  GGPDK: 'GGPDK',
  GGPCOMCASINO: 'GGPCOMCASINO',
  OCNP: 'OCNP',
  POKERARABIA: 'PokerArabia',
  GGVEGAS: 'GGVEGAS',
};

export const brandIds = {
  GGPCOM: 'GGPCOM',
  SEVENXL: '7XL',
  N8: 'NATURAL8',
  POKEROK: 'POKEROK',
  GGPOKERUK: 'GGPOKERUK',
  DAVAOPOKER: 'DAVAOPOKER',
  TWOACE: '2ACE',
  WSOP: 'WSOP',
  GGPUKE: 'GGPuke',
  EVPUKE: 'EVPuke',
  WSOPCASINO: 'WSOPCASINO',
  GGPCOMCASINO: 'GGPCOMCASINO',
  OCNP: 'OCNP',
  POKERARABIA: 'POKERARABIA',
  GGVEGAS: 'GGVEGAS',
};

export const sitesByBrand = {
  GGPCOM: [siteIds.GGPCOM, siteIds.GGPPH, siteIds.GGPBE, siteIds.GGPBR, siteIds.GGPCW, siteIds.GGPCZ, siteIds.GGPDE, siteIds.GGPEU, siteIds.GGPFI, siteIds.GGPHU, siteIds.GGPJP, siteIds.GGPNG, siteIds.GGPNL, siteIds.GGPPL, siteIds.GGPRO, siteIds.GGPUA],
  SEVENXL: [siteIds.SEVENXL],
  N8: [siteIds.NATURAL8, siteIds.N8IN],
  POKEROK: [siteIds.GGPOK],
  GGPOKERUK: [siteIds.GGPUK],
  DAVAOPOKER: [siteIds.DAVAOPOKER],
  TWOACE: [siteIds.TWOACE],
  WSOP: [siteIds.WSOPON],
  GGPUKE: [siteIds.GGPUKE],
  EVPUKE: [siteIds.EVPUKE],
  WSOPCASINO: [siteIds.WSOPON],
  GGPCOMCASINO: [siteIds.GGPCOM, siteIds.GGPPH, siteIds.GGPBE, siteIds.GGPBR, siteIds.GGPCW, siteIds.GGPCZ, siteIds.GGPDE, siteIds.GGPEU, siteIds.GGPFI, siteIds.GGPHU, siteIds.GGPJP, siteIds.GGPNG, siteIds.GGPNL, siteIds.GGPPL, siteIds.GGPRO, siteIds.GGPUA],
  OCNP: [siteIds.OCNP],
  POKERARABIA: [siteIds.POKERARABIA],
  GGVEGAS: [siteIds.GGVEGAS],
};

const brandNames = {
  GGPCOM: 'GGPoker',
  SEVENXL: '7XL',
  N8: 'NATURAL8',
  POKEROK: 'POKEROK',
  GGPOKERUK: 'GGPokerUK',
  DAVAOPOKER: 'DAVAOPOKER',
  TWOACE: '2ACE',
  WSOP: 'GGPoker.ca',
  GGPUKE: 'GGPuke',
  EVPUKE: 'EVPuke',
  OCNP: 'OceanBet',
  POKERARABIA: 'PokerArabia powered by GGPoker',
  GGVEGAS: 'GGVEGAS',
};

export const displayBrandNames = {
  GGPCOM: 'GGPoker',
  SEVENXL: '7XL',
  N8: 'Natural8',
  POKEROK: 'ПокерOK',
  GGPOKERUK: 'GGPoker UK',
  DAVAOPOKER: 'Davao Poker',
  TWOACE: '2ACE',
  WSOP: 'GGPoker.ca',
  GGPUKE: 'GG扑克',
  EVPUKE: 'EV扑克',
  OCNP: 'OceanBet',
  POKERARABIA: 'PokerArabia powered by GGPoker',
  GGVEGAS: 'GGVEGAS',
};

export const gpSiteIds = {
  GGPUKE: 'GGPUKE',
  NATURAL8: 'Natural8',
  N8IN: 'N8IN',
  GGPOK: 'GGPOK',
  GGPCOM: 'GGPCOM',
  GGPUK: 'GGPUK',
  GGPEU: 'GGPEU',
  GGPFI: 'GGPFI',
  GGPPL: 'GGPPL',
  GGPDE: 'GGPDE',
  GGPBE: 'GGPBE',
  GGPHU: 'GGPHU',
  GGPNL: 'GGPNL',
  GGPRO: 'GGPRO',
  GGPBR: 'GGPBR',
  GGPUA: 'GGPUA',
  GGPCW: 'GGPCW',
  GGPJP: 'GGPJP',
  GGPNG: 'GGPNG',
  DAVAOPOKER: 'DavaoPoker',
  EVPUKE: 'EVPUKE',
  TWOACE: '2ACE',
  WSOPON: 'WSOPON',
  SEVENXL: 'SEVENXL',
  GGPCZ: 'GGPCZ',
  GGPPH: 'GGPPH',
  GGPDK: 'GGPDK',
  GGPCOMCASINO: 'GGPCOMCASINO',
  OCNP: 'OCNP',
  POKERARABIA: 'PokerArabia',
  GGVEGAS: 'GGVEGAS',
};

export const gpBrandIds = {
  GGPUKE: 'MBP',
  NATURAL8: 'Natural8',
  N8IN: 'Natural8',
  GGPOK: 'POKEROK',
  GGPUK: 'GGPOKER',
  DAVAOPOKER: 'DAVAOPOKER',
  EVPUKE: 'MBP',
  TWOACE: '2ACE',
  SEVENXL: 'SEVENXL',
  GGPCOM: 'GGPCOM',
  WSOPON: 'WSOP',
  GGPCOMCASINO: 'GGPCOMCASINO',
  WSOPCASINO: 'WSOPCASINO',
  OCNP: 'OCNP',
  POKERARABIA: 'POKERARABIA',
  GGVEGAS: 'GGVEGAS',
};

const langTypes = {
  EN: { label: 'English', value: 'en', code: 'en-US' },
  ZH_CN: { label: '中文', value: 'zh-cn', code: 'zh-CN' },
  RU: { label: 'русский', value: 'ru', code: 'ru-RU' },
  ES: { label: 'Español', value: 'es', code: 'es-ES' },
  PT: { label: 'Português', value: 'pt', code: 'pt-PT' },
  VI: { label: 'Tiếng Việt', value: 'vi', code: 'vi-VI' },
  ID: { label: 'Bahasa', value: 'id', code: 'id-ID' },
  DE: { label: 'Deutsch', value: 'de', code: 'de-DE' },
  FR: { label: 'Français', value: 'fr', code: 'fr-FR' },
  TH: { label: 'ภาษาไทย', value: 'th', code: 'th-TH' },
  TR: { label: 'Türkçe', value: 'tr', code: 'tr-TR' },
  JA: { label: '日本語', value: 'ja', code: 'ja-JP' },
  ET: { label: 'Eesti', value: 'et', code: 'et-ET' },
  LV: { label: 'Latviešu', value: 'lv', code: 'lv-LV' },
  FI: { label: 'Suomi', value: 'fi', code: 'fi-FI' },
  PL: { label: 'Polski', value: 'pl', code: 'pl-PL' },
  RO: { label: 'Română', value: 'ro', code: 'ro-RO' },
  HU: { label: 'Magyar', value: 'hu', code: 'hu-HU' },
  NL: { label: 'Nederlands', value: 'nl', code: 'nl-NL' },
  UK_UA: { label: 'Ukrainian', value: 'uk-ua', code: 'uk-UA' },
  CS: { label: 'čeština', value: 'cs', code: 'cs-CZ' },
  KO: { label: '한국어', value: 'ko', code: 'ko-KR' },
  AR: { label: 'Arabic', value: 'ar', code: 'ar-AR' },
  FA: { label: 'Persian/Farsi', value: 'fa', code: 'fa-FA' },
};

const {
  VUE_APP_GTM_PUBLIC,
  VUE_APP_GTM_GGPNL,
  VUE_APP_GTM_GGPHU,
  VUE_APP_GTM_GGPPL,
  VUE_APP_GTM_GGPBE,
  VUE_APP_GTM_GGPBR,
  VUE_APP_GTM_GGPUA,
  VUE_APP_GTM_GGPDE,
  VUE_APP_GTM_GGPEU,
  VUE_APP_GTM_GGPFI,
  VUE_APP_GTM_GGPOK,
  VUE_APP_GTM_GGPUK,
  VUE_APP_GTM_WSOPON,
  VUE_APP_GTM_OCNP,
  VUE_APP_GTM_GGPRO,
  VUE_APP_GTM_GGPPH
} = process.env;

export const langList = [
  { ...langTypes.EN, excluded: [siteIds.TWOACE] },
  { ...langTypes.ZH_CN, excluded: [siteIds.TWOACE, siteIds.OCNP, siteIds.POKERARABIA] },
  { ...langTypes.RU, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.POKERARABIA] },
  { ...langTypes.ES, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.PT, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.POKERARABIA] },
  { ...langTypes.VI, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.ID, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.DE, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.FR, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.TH, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.TR, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.JA, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.POKERARABIA] },
  { ...langTypes.ET, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.LV, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.FI, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.PL, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.RO, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.HU, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.NATURAL8, siteIds.N8IN, siteIds.GGPOK, siteIds.GGPUK, siteIds.DAVAOPOKER, siteIds.SEVENXL, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.NL, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.NATURAL8, siteIds.N8IN, siteIds.GGPOK, siteIds.GGPUK, siteIds.DAVAOPOKER, siteIds.SEVENXL, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.UK_UA, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.NATURAL8, siteIds.N8IN, siteIds.GGPOK, siteIds.GGPUK, siteIds.DAVAOPOKER, siteIds.SEVENXL, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.CS, excluded: [siteIds.EVPUKE, siteIds.GGPUKE, siteIds.TWOACE, siteIds.NATURAL8, siteIds.N8IN, siteIds.GGPOK, siteIds.GGPUK, siteIds.DAVAOPOKER, siteIds.SEVENXL, siteIds.WSOPON, siteIds.OCNP, siteIds.GGVEGAS, siteIds.POKERARABIA] },
  { ...langTypes.KO, permission: siteIds.TWOACE },
  { ...langTypes.AR, permission: siteIds.POKERARABIA },
  { ...langTypes.FA, permission: siteIds.POKERARABIA },
];

export const baseList = [
  {
    site: siteIds.GGPUKE,
    countries: baseInfo.VUE_APP_COUNTRY_GGPUKE,
    domain: envDomainList.GGPUKE,
    api: baseInfo.VUE_APP_API_GGPUKE,
    logo: `${cdnImgPath}/ggpuke_white_240809.png`,
    lang: langTypes.ZH_CN.value,
    favicon: `${cdnImgPath}/ggpuke_favicon_240809.ico`,
    title: 'GG扑克',
    brandAlias: brandIds.GGPUKE,
    brandName: brandNames.GGPUKE,
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPUKE,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPUKE,
    gpSite: gpSiteIds.GGPUKE,
    gpBrand: gpBrandIds.GGPUKE,
    webUrl: baseInfo.VUE_APP_WEB_GGPUKE,
  },
  {
    site: siteIds.EVPUKE,
    countries: baseInfo.VUE_APP_COUNTRY_EVPUKE,
    domain: envDomainList.EVPUKE,
    api: baseInfo.VUE_APP_API_EVPUKE,
    logo: `${cdnImgPath}/evpuke_white_240809.png`,
    lang: langTypes.ZH_CN.value,
    favicon: `${cdnImgPath}/evpuke_favicon_240809.ico`,
    title: 'EV扑克',
    brandAlias: brandIds.EVPUKE,
    brandName: brandNames.EVPUKE,
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_EVPUKE,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_EVPUKE,
    gpSite: gpSiteIds.EVPUKE,
    gpBrand: gpBrandIds.EVPUKE,
    webUrl: baseInfo.VUE_APP_WEB_EVPUKE,
  },
  {
    site: siteIds.GGPUK,
    gtm: VUE_APP_GTM_GGPUK,
    countries: baseInfo.VUE_APP_COUNTRY_GGPUK,
    domain: envDomainList.GGPUK,
    api: baseInfo.VUE_APP_API_GGPUK,
    logo: `${cdnImgPath}/ggpuk_white_240809.png`,
    favicon: `${cdnImgPath}/ggpuk_favicon_240809.ico`,
    title: 'GGPokerUK',
    brandAlias: brandIds.GGPOKERUK,
    brandName: brandNames.GGPOKERUK,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPUK,
    webSiteUrl: '//ggpoker.co.uk',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPUK,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPUK,
    gpSite: gpSiteIds.GGPUK,
    gpBrand: gpBrandIds.GGPUK,
    webUrl: baseInfo.VUE_APP_WEB_GGPUK,
  },
  {
    site: siteIds.GGPOK,
    gtm: VUE_APP_GTM_GGPOK,
    countries: baseInfo.VUE_APP_COUNTRY_GGPOK,
    domain: envDomainList.GGPOK,
    api: baseInfo.VUE_APP_API_GGPOK,
    logo: `${cdnImgPath}/pokerok_white_240809.png`,
    favicon: `${cdnImgPath}/pokerok_favicon_240809.ico`,
    title: 'POKEROK',
    brandAlias: brandIds.POKEROK,
    brandName: brandNames.POKEROK,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPOK,
    includeNumeric: ['.pok', '.ggpokerok', '.pokerok', 'pokerok-'],
    webSiteUrl: '//pokerok152.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPOK,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPOK,
    gpSite: gpSiteIds.GGPOK,
    gpBrand: gpBrandIds.GGPOK,
    webUrl: baseInfo.VUE_APP_WEB_GGPOK,
  },
  {
    site: siteIds.TWOACE,
    countries: baseInfo.VUE_APP_COUNTRY_TWOACE,
    domain: envDomainList.TWOACE,
    api: baseInfo.VUE_APP_API_TWOACE,
    logo: `${cdnImgPath}/twoace_white_240809.png`,
    lang: langTypes.KO.value,
    favicon: `${cdnImgPath}/twoace_favicon_240809.ico`,
    title: '2ACE',
    brandAlias: brandIds.TWOACE,
    brandName: brandNames.TWOACE,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_TWOACE,
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_TWOACE,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_TWOACE,
    gpSite: gpSiteIds.TWOACE,
    gpBrand: gpBrandIds.TWOACE,
    webUrl: baseInfo.VUE_APP_WEB_TWOACE,
  },
  {
    site: siteIds.SEVENXL,
    countries: baseInfo.VUE_APP_COUNTRY_SEVENXL,
    domain: envDomainList.SEVENXL,
    api: baseInfo.VUE_APP_API_SEVENXL,
    logo: `${cdnImgPath}/7xl_white_240809.png`,
    favicon: `${cdnImgPath}/7xl_favicon_240809.ico`,
    title: '7XL',
    brandAlias: brandIds.SEVENXL,
    brandName: brandNames.SEVENXL,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_7XL,
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_7XL,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_7XL,
    gpSite: gpSiteIds.SEVENXL,
    gpBrand: gpBrandIds.SEVENXL,
    webUrl: baseInfo.VUE_APP_WEB_SEVENXL,
  },
  {
    site: siteIds.NATURAL8,
    countries: baseInfo.VUE_APP_COUNTRY_NATURAL8,
    domain: envDomainList.NATURAL8,
    api: baseInfo.VUE_APP_API_NATURAL8,
    logo: `${cdnImgPath}/natural8_white_240809.png`,
    favicon: `${cdnImgPath}/natural8_favicon_240809.ico`,
    title: 'NATURAL8',
    brandAlias: brandIds.N8,
    brandName: brandNames.N8,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_NATURAL8,
    webSiteUrl: '//natural8.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_NATURAL8,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_NATURAL8,
    gpSite: gpSiteIds.NATURAL8,
    gpBrand: gpBrandIds.NATURAL8,
    webUrl: baseInfo.VUE_APP_WEB_NATURAL8,
  },
  {
    site: siteIds.N8IN,
    countries: baseInfo.VUE_APP_COUNTRY_N8IN,
    domain: envDomainList.N8IN,
    api: baseInfo.VUE_APP_API_N8IN,
    logo: `${cdnImgPath}/natural8_white_240809.png`,
    favicon: `${cdnImgPath}/natural8_favicon_240809.ico`,
    title: 'NATURAL8',
    brandAlias: brandIds.N8,
    brandName: brandNames.N8,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_N8IN,
    webSiteUrl: '//natural8in.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_N8IN,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_N8IN,
    gpSite: gpSiteIds.N8IN,
    gpBrand: gpBrandIds.N8IN,
    webUrl: baseInfo.VUE_APP_WEB_N8IN,
  },
  {
    site: siteIds.DAVAOPOKER,
    countries: baseInfo.VUE_APP_COUNTRY_DAVAOPOKER,
    domain: envDomainList.DAVAOPOKER,
    api: baseInfo.VUE_APP_API_DAVAOPOKER,
    logo: `${cdnImgPath}/davaopoker_white_240809.png`,
    favicon: `${cdnImgPath}/davaopoker_favicon_240809.ico`,
    title: 'DAVAOPOKER',
    brandAlias: brandIds.DAVAOPOKER,
    brandName: brandNames.DAVAOPOKER,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_DAVAOPOKER,
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_DAVAOPOKER,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_DAVAOPOKER,
    gpSite: gpSiteIds.DAVAOPOKER,
    gpBrand: gpBrandIds.DAVAOPOKER,
    webUrl: baseInfo.VUE_APP_WEB_DAVAOPOKER,
  },
  {
    site: siteIds.GGPJP,
    gtm: VUE_APP_GTM_PUBLIC,
    countries: baseInfo.VUE_APP_COUNTRY_GGPJP,
    domain: {
      default: envDomainList.GGPJP,
      casino: envDomainList.GGPJPCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPJP,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ggpoker.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPJP,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPJP,
    gpSite: gpSiteIds.GGPJP,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPJP,
  },
  {
    site: siteIds.GGPNG,
    countries: baseInfo.VUE_APP_COUNTRY_GGPNG,
    domain: {
      default: envDomainList.GGPNG,
      casino: envDomainList.GGPNGCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPNG,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ggpoker.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPNG,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPNG,
    gpSite: gpSiteIds.GGPNG,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPNG,
  },
  {
    site: siteIds.GGPCW,
    countries: baseInfo.VUE_APP_COUNTRY_GGPCW,
    domain: {
      default: envDomainList.GGPCW,
      casino: envDomainList.GGPCWCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPCW,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ggpoker.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPCW,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPCW,
    gpSite: gpSiteIds.GGPCW,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPCW,
  },
  {
    site: siteIds.GGPBR,
    gtm: VUE_APP_GTM_GGPBR,
    countries: baseInfo.VUE_APP_COUNTRY_GGPBR,
    domain: {
      default: envDomainList.GGPBR,
      casino: envDomainList.GGPBRCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPBR,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//br.ggpoker.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPBR,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPBR,
    gpSite: gpSiteIds.GGPBR,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPBR,
  },
  {
    site: siteIds.GGPEU,
    gtm: VUE_APP_GTM_GGPEU,
    countries: baseInfo.VUE_APP_COUNTRY_GGPEU,
    domain: {
      default: envDomainList.GGPEU,
      casino: envDomainList.GGPEUCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPEU,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ggpoker.eu',
    weSiteName: 'GGPoker.eu',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPEU,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPEU,
    gpSite: gpSiteIds.GGPEU,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPEU,
  },
  {
    site: siteIds.GGPHU,
    gtm: VUE_APP_GTM_GGPHU,
    countries: baseInfo.VUE_APP_COUNTRY_GGPHU,
    domain: {
      default: envDomainList.GGPHU,
      casino: envDomainList.GGPHUCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPHU,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//hun.ggpoker.eu',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPHU,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPHU,
    gpSite: gpSiteIds.GGPHU,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPHU,
  },
  {
    site: siteIds.GGPFI,
    gtm: VUE_APP_GTM_GGPFI,
    countries: baseInfo.VUE_APP_COUNTRY_GGPFI,
    domain: {
      default: envDomainList.GGPFI,
      casino: envDomainList.GGPFICASINO,
    },
    api: baseInfo.VUE_APP_API_GGPFI,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//fi.ggpoker.eu',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPFI,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPFI,
    gpSite: gpSiteIds.GGPFI,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPFI,
  },
  {
    site: siteIds.GGPPL,
    gtm: VUE_APP_GTM_GGPPL,
    countries: baseInfo.VUE_APP_COUNTRY_GGPPL,
    domain: {
      default: envDomainList.GGPPL,
      casino: envDomainList.GGPPLCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPPL,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//pl2.ggpoker.com/',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPPL,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPPL,
    gpSite: gpSiteIds.GGPPL,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPPL,
  },
  {
    site: siteIds.GGPDE,
    gtm: VUE_APP_GTM_GGPDE,
    countries: baseInfo.VUE_APP_COUNTRY_GGPDE,
    domain: {
      default: envDomainList.GGPDE,
      casino: envDomainList.GGPDECASINO,
    },
    api: baseInfo.VUE_APP_API_GGPDE,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ggpoker.de',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPDE,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPDE,
    gpSite: gpSiteIds.GGPDE,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPDE,
  },
  {
    site: siteIds.GGPNL,
    gtm: VUE_APP_GTM_GGPNL,
    countries: baseInfo.VUE_APP_COUNTRY_GGPNL,
    domain: {
      default: envDomainList.GGPNL,
      casino: envDomainList.GGPNLCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPNL,
    logo: `${cdnImgPath}/ggpnl_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ggpoker.nl',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPNL,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPNL,
    gpSite: gpSiteIds.GGPNL,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPNL,
  },
  {
    site: siteIds.GGPBE,
    gtm: VUE_APP_GTM_GGPBE,
    countries: baseInfo.VUE_APP_COUNTRY_GGPBE,
    domain: {
      default: envDomainList.GGPBE,
      casino: envDomainList.GGPBECASINO,
    },
    api: baseInfo.VUE_APP_API_GGPBE,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ggpoker.be',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPBE,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPBE,
    gpSite: gpSiteIds.GGPBE,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPBE,
  },
  {
    site: siteIds.GGPUA,
    gtm: VUE_APP_GTM_GGPUA,
    countries: baseInfo.VUE_APP_COUNTRY_GGPUA,
    domain: {
      default: envDomainList.GGPUA,
      casino: envDomainList.GGPUACASINO,
    },
    api: baseInfo.VUE_APP_API_GGPUA,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ua.ggpoker.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPUA,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPUA,
    gpSite: gpSiteIds.GGPUA,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPUA,
  },
  {
    site: siteIds.GGPRO,
    gtm: VUE_APP_GTM_GGPRO,
    countries: baseInfo.VUE_APP_COUNTRY_GGPRO,
    domain: {
      default: envDomainList.GGPRO,
      casino: envDomainList.GGPROCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPRO,
    logo: `${cdnImgPath}/ggpro_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//playgg.ro',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPRO,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPRO,
    gpSite: gpSiteIds.GGPRO,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPRO,
  },
  {
    site: siteIds.GGPCZ,
    gtm: VUE_APP_GTM_PUBLIC,
    countries: baseInfo.VUE_APP_COUNTRY_GGPCZ,
    domain: {
      default: envDomainList.GGPCZ,
      casino: envDomainList.GGPCZCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPCZ,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ggpoker.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPCZ,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPCZ,
    gpSite: gpSiteIds.GGPCZ,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPCZ,
  },
  {
    site: siteIds.GGPDK,
    gtm: VUE_APP_GTM_PUBLIC,
    countries: baseInfo.VUE_APP_COUNTRY_GGPDK,
    domain: {
      default: envDomainList.GGPDK,
      casino: envDomainList.GGPDKCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPDK,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ggpoker.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPDK,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPDK,
    gpSite: gpSiteIds.GGPDK,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPDK,
  },
  {
    site: siteIds.GGPPH,
    gtm: VUE_APP_GTM_GGPPH,
    countries: baseInfo.VUE_APP_COUNTRY_GGPPH,
    domain: {
      default: envDomainList.GGPPH,
      casino: envDomainList.GGPPHCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPPH,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ggpoker.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPPH,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPPH,
    gpSite: gpSiteIds.GGPPH,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPPH,
  },
  {
    site: siteIds.GGPCOM,
    gtm: VUE_APP_GTM_PUBLIC,
    countries: baseInfo.VUE_APP_COUNTRY_GGPCOM,
    domain: {
      default: envDomainList.GGPCOM,
      casino: envDomainList.GGPCOMCASINO,
    },
    api: baseInfo.VUE_APP_API_GGPCOM,
    logo: `${cdnImgPath}/ggpoker_white_240809.png`,
    favicon: `${cdnImgPath}/ggpoker_favicon_240809.ico`,
    title: 'GGPoker',
    brandAlias: {
      default: brandIds.GGPCOM,
      casino: brandIds.GGPCOMCASINO,
    },
    brandName: brandNames.GGPCOM,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGPCOM,
    webSiteUrl: '//ggpoker.com',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGPCOM,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGPCOM,
    gpSite: gpSiteIds.GGPCOM,
    gpBrand: {
      default: gpBrandIds.GGPCOM,
      casino: gpBrandIds.GGPCOMCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGPCOM,
  },
  {
    site: siteIds.WSOPON,
    gtm: VUE_APP_GTM_WSOPON,
    countries: baseInfo.VUE_APP_COUNTRY_WSOPON,
    domain: {
      default: envDomainList.WSOPON,
      casino: envDomainList.WSOPCASINO,
    },
    api: baseInfo.VUE_APP_API_WSOPON,
    logo: `${cdnImgPath}/ggpca_white_240809.png`,
    favicon: `${cdnImgPath}/ggpca_favicon_240809.ico`,
    title: 'GGPoker.ca',
    brandAlias: {
      default: brandIds.WSOP,
      casino: brandIds.WSOPCASINO,
    },
    brandName: brandNames.WSOP,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_WSOPON,
    webSiteUrl: '//ggpoker.ca',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_WSOPON,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_WSOPON,
    gpSite: gpSiteIds.WSOPON,
    gpBrand: {
      default: gpBrandIds.WSOPON,
      casino: gpBrandIds.WSOPCASINO,
    },
    webUrl: baseInfo.VUE_APP_WEB_WSOPON,
  },
  {
    site: siteIds.OCNP,
    gtm: VUE_APP_GTM_OCNP,
    countries: baseInfo.VUE_APP_COUNTRY_OCNP,
    domain: {
      default: envDomainList.OCNP,
    },
    api: baseInfo.VUE_APP_API_OCNP,
    logo: `${cdnImgPath}/ocnp_white_240809.png`,
    favicon: `${cdnImgPath}/ocnp_favicon_240809.ico`,
    title: 'OceanBet',
    brandAlias: {
      default: brandIds.OCNP,
    },
    brandName: brandNames.OCNP,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_OCNP,
    webSiteUrl: '//ocnp',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_OCNP,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_OCNP,
    gpSite: gpSiteIds.OCNP,
    gpBrand: {
      default: gpBrandIds.OCNP,
    },
    webUrl: baseInfo.VUE_APP_WEB_OCNP,
  },
  {
    site: siteIds.POKERARABIA,
    gtm: VUE_APP_GTM_PUBLIC,
    countries: baseInfo.VUE_APP_COUNTRY_POKERARABIA,
    domain: {
      default: envDomainList.POKERARABIA,
    },
    api: baseInfo.VUE_APP_API_POKERARABIA,
    logo: `${cdnImgPath}/pokerarabia_white_240809.png`,
    favicon: `${cdnImgPath}/pokerarabia_favicon_240809.ico`,
    title: 'POKERARABIA',
    brandAlias: {
      default: brandIds.POKERARABIA,
    },
    brandName: brandNames.POKERARABIA,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_POKERARABIA,
    webSiteUrl: '//pokerarabia',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_POKERARABIA,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_POKERARABIA,
    gpSite: gpSiteIds.POKERARABIA,
    gpBrand: {
      default: gpBrandIds.POKERARABIA,
    },
    webUrl: baseInfo.VUE_APP_WEB_POKERARABIA,
  },
  {
    site: siteIds.GGVEGAS,
    gtm: VUE_APP_GTM_PUBLIC,
    countries: baseInfo.VUE_APP_COUNTRY_GGVEGAS,
    domain: {
      default: envDomainList.GGVEGAS,
    },
    api: baseInfo.VUE_APP_API_GGVEGAS,
    logo: `${cdnImgPath}/ggvegas_white_240809.png`,
    favicon: `${cdnImgPath}/ggvegas_favicon_240809.ico`,
    title: 'GGVEGAS',
    brandAlias: {
      default: brandIds.GGVEGAS,
    },
    brandName: brandNames.GGVEGAS,
    recaptchaKey: baseInfo.VUE_APP_RECAPTCHA_KEY_GGVEGAS,
    webSiteUrl: '//ggvegas',
    widgetUrl: baseInfo.VUE_APP_WIDGET_URL_GGVEGAS,
    csEmail: baseInfo.VUE_APP_CS_EMAIL_GGVEGAS,
    gpSite: gpSiteIds.GGVEGAS,
    gpBrand: {
      default: gpBrandIds.GGVEGAS,
    },
    webUrl: baseInfo.VUE_APP_WEB_GGVEGAS,
  },
];
