<template>
  <div gp-country-drop-down>
    <SearchDropSelect :class="{'disabled':disabled}" v-model="model" :hidden-icon="hiddenIcon" @input="selected" :template="getView" :placeholder="placeholder" :list="list" :size="size" @focus="$emit('focus')" @blur="$emit('blur')" :disabled="disabled" />
  </div>
</template>

<script>

import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { sleep } from '@shared/utils/commonUtils.mjs';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';

export default {
  name: 'CountryDropDown',
  components: { SearchDropSelect },
  props: {
    country: { type: Specific, default: null },
    value: { type: Specific, default: null, },
    size: { type: String, default: 'sm' },
    disabled: { type: Boolean, default: false, },
    placeholder: { type: String, default: null },
    hiddenIcon:{ type: Boolean, default : false},
  },
  data() {
    return {
      list: null,
      model: null,
      blockItem: null,
    };
  },
  computed: {
    isExternal() {
      return (this.site !== siteIds.GGPCW && this.landingMode === 'warp') || this.channelingName;
    },
    isNullSite() {
      return [siteIds.TWOACE, siteIds.GGPOK, siteIds.DAVAOPOKER, siteIds.SEVENXL].includes(this.site);
    },
    isNoneSite() {
      return !this.$route.query?.siteId || this.$route.query?.siteId?.toLowerCase() === 'none' || (/** GGPPH GGPDK GGPCZ Stage 까지만 배포 */ this.env === 'production' && [siteIds.GGPPH, siteIds.GGPDK, siteIds.GGPCZ].includes(this.$route.query?.siteId));
    },
    channelingName: state('query', 'channelingName'),
    blockCountries: state('env', 'blockCountries'),
    userInfo: state('user', 'userInfo'),
    defaultSite: state('env', 'defaultSite'),
    site: state('env', 'site'),
  },
  methods: {
    getView(item) {
      const code = item.value.toLowerCase();
      const img = this.size === 'lg' ? `<img class="img-country" src="https://ssl.gg-global-cdn.com/game/platform-front/img/${code}.svg" alt="country">` : '';
      return `${img}<em>${item.label}</em>`;
    },
    selected(v) {
      if (!v) return;
      this.blockItem = this.blockCountries?.find(o => o.country === v.CountryCode);
      if (this.blockItem) {
        this.$emit('block', { ...v, ...this.blockItem });
      } else {
        this.$emit('input', v.value);
      }
    },
    async getList() {
      const request = this.isNullSite ? null : this.isNoneSite ? 'None' : (this.defaultSite || this.site);
      this.list = (await this.$services.base.getCountryList(request)).map(o => ({ label: this.$t(`country.${o.CountryCode}`), value: o.CountryCode, ...o }));
      if (this.isExternal && this.blockCountries.length) this.originList = this.originList.filter(country => !this.blockCountries.some(b => b.country === country.CountryCode));
      await sleep(60);
      this.model = this.list.find(o => o.value === (this.value)) || null;
      // this.list[0];
      if (this.model) {
        this.$emit('input', this.model.CountryCode);
      }
    }
  },
  async mounted() {
    await this.getList();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-country-drop-down] { .h(40); .rel();
  &.disabled {.h(20);
    >a {cursor : default}
  }
  &.single { .h(48);
    [search-drop-select] {
      > a { .h(48); .br(6); box-shadow: 0 1px 0 0 rgba(200, 200, 200, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .bgc(rgba(255, 255, 255, 0.07)) !important;;
        > label { .lh(50); .h(100%);}
      }


    }
  }
  .img-country { .w(18); .ib(); .mr(8);}
  .link { .abs(); .rb(0, -28); .c(@c-red); .semi-bold();
    a { .-b(@c-red);}
  }
  [search-drop-select] { .rel(); .bgc(#FFF); .br(8);
    > a { .br(8); .c(#333); .bgc(#1e1e1e); box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .block(); .fs(14); .flex(); .items-center(); .h(64); .p(6, 20, 6, 12); .-box(); .w(100%); .rel();
      &.placeholder {.c(#ccc);}
      > label { .medium(); font-weight: 400;
        > em { .fs(14); }
        img { .mb(4); }
      }
      [font-icon] { .ib(); .fs(16); .abs(); .rt(10, 45%); .t-yc(); .c(#333);
        &.on { .t-r(180deg); }
      }
    }
    //.list-container { box-shadow: 2px 0 3px rgba(0, 0, 0, 0.5); .bgc(white); .br(8); .br-t(0); .abs(); .o(0); .crop(); .w(100%); .max-h(500); .h(0);
    .list-container { .bgc(white); .br(8); .br-t(0); .abs(); .o(0); .crop(); .w(100%); .max-h(500); .h(0);box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1); .br(8);
      // &.fill { .w(var(--innerWidth)); }
      &.fill { .w(100%); }
      &.on { .o(1); .z(10); }
      .list-inner { .pb(6); .pt(3); .hf();
        .input-holder { .p(5, 12, 10, 12); .bgc(#fff);}
        [text-input] {.bgc(#F7F7F8); .w(100%); box-shadow: none; border: none; .h(40);
          > input { .c(black); .fs(14); .pb(0);}
          > .input-btn-x {.bgc(#fff);
            > i {.c(black);}
          }
        }
        .scroll-holder { overflow-y: auto; .h(calc(100% - 50px));
          label { .block(); .p(10, 16); .w(100%); .c(black); .tl(); }
          button { .flex(); .p(10, 16); .w(100%); .c(black); .tl();
            &:hover, &:focus, &.focus { .bgc(#F3FCF5); }
            &.active { .bgc(#E6F3E8); }
            em { .fs(14); .w(100%); }
            .icn-chevron-right { margin: 0 0 0 auto; }
          }
        }
        .empty { .fs(14); .p(10, 12); .c(black);}
      }
    }
    &.md {
      .list-container {
        .list-inner {
          .scroll-holder { .h(100%); }
        }
      }
    }
    &.sm, &.md {
      > a { .bgc(transparent) !important; box-shadow: none; .-a(); .p(0, 12); .h(40); .br(4);
        > [text-input] {.-a(); box-shadow: none; .w(100%);
          input { .pl(0); }
        }
      }
      .list-container {
        .list-inner { .fs(14);
        }
      }
    }
    [text-input] {
      input { .fs(14, 20);
      }
    }
    .placeholder { .c(@gp-placeholder-color); .regular(); }
    &.disabled {.bgc(@gp-disabled-bgc);
      > a {.c(#717171);}
    }
  }
}
</style>
