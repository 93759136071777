<template>
  <div lottie>
    <img class="fallback" v-if="fallback && !loaded" :src="fallback" alt="">
  </div>
</template>

<script>
import axios from 'axios';
import lottie from 'lottie-web';

export default {
  name: 'Lottie',
  props: {
    src: { type: String, default: null },
    fallback: { type: String, default: null },
    renderer: { type: String, default: null },
    autoplay: { type: Boolean, default: false },
    loop: { type: Boolean, default: false },
    yoyo: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: false,
      failed: false,
      playing: false,
      player: null,
    };
  },
  methods: {
    tick() {
      if (!this.player) return;
      const rect = this.$el.getBoundingClientRect();
      if (rect.bottom <= 0 || rect.top >= window.innerHeight) {
        if (this.playing) {
          this.playing = false;
          this.player.pause();
        }
      } else {
        if (!this.playing) {
          this.playing = true;
          this.player.play();
        }
      }
    },
    stop() {
      this.player.stop();
    },
    play() {
      if (this.player) this.player.goToAndPlay(0);
    },
    pause() {
      this.player.pause();
    },
    resume() {
      this.player.play();
    },
    // 모든 자원을 불러오고 DOM에 관련 svg가 생성되어 재생을 기다리는 시점에 이벤트 트리거
    onLoad() {
      this.$emit('load');
    }
  },
  async mounted() {
    const [renderer] = /(svg|html|canvas)/.exec(this.renderer) || ['svg'];
    try {
      const loop = this.loop && !this.yoyo;
      const autoplay = this.autoplay && !this.yoyo;
      const { data: animationData } = await axios.get(this.src);
      this.player = lottie.loadAnimation({ container: this.$el, renderer, loop, autoplay, animationData });
      this.player.addEventListener('DOMLoaded', this.onLoad);
      if (this.yoyo) {
        this.player.addEventListener('complete', () => {
          this.player.setDirection(this.player.playDirection * -1);
          if (this.autoplay || this.player.playDirection === -1) this.player.play();
        });
        if (this.autoplay) this.player.play();
      }
      if (this.$scroll) this.$scroll.addListener(this.tick);
      this.loaded = true;
    } catch (ignored) {

      this.failed = true;
    }
  },
  beforeDestroy() {
    if (this.player) this.player.destroy();
    if (this.$scroll) this.$scroll.removeListener(this.tick);
  },
};
</script>
<style>
[lottie] { position: relative; }
[lottie] .fallback { width: 100%; height: 100%; display: block; position: absolute; left: 0; top: 0; }
</style>
