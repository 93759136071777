<template>
  <div signicat-idin-guide>
    <p class="desc" v-html="$t('_.description')"/>
    <ProgressButton button-name="continue" @click="$emit('click')" />
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { state } from '@shared/utils/storeUtils.mjs';
export default {
  name: 'SignicatIdinGuide',
  lexicon: 'onBoarding.signicat.idinGuide',
  components: { ProgressButton },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[signicat-idin-guide] {
  > p { .fs(16); .c(@c-w07); }
}
</style>