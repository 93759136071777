import RouterProxy from '@shared/modules/RouterProxy.js';
import { Service } from '@/services/Service';
import { getLocalApp, getTitle, isExcludeGgpass, setSiteInfo, updateSignEnv } from '@/utils/baseUtil';
import { defaultSiteMap, ggpassSiteMap, getSiteId } from '@/constants/base/signup/signUpSiteMap';
import { log } from '@shared/utils/logUtils.mjs';
import { apiErrorType } from '@/constants/base/apiErrorCode';

/**
 * @implements {ProxyManager}
 */
class ServiceManager extends Service {
  constructor({ store = null, cookies = null } = {}) {
    super({ store, cookies });
  }

  async mounted(app, route) {
    this.toast.setApp(app);
    this.modal.setApp(app);
  }

  async init(url, host, queries, store) {
    try {
      queries.ReferenceKey = queries.ReferenceKey || queries.referenceKey;
      queries.FindKey = queries.FindKey || queries.findKey;
      queries.lang = queries.lang || queries.language;
      queries.token = queries.token || queries.Token;

      if (queries.isFromClientApp) this.cookie.setIsFromClientApp(true);
      if (queries.isFromMobileApp) this.cookie.setIsFromMobileApp(true);
      if (queries.redirectUri) this.cookie.setRedirectUri(queries.redirectUri);

      await setSiteInfo(url, host, queries, store, null);

      if (queries.token) {
        this.cookie.setToken(queries.token); // TODO : [ean] 차후 cookie token 관련 제거 필요
        this.store.commit('user/setToken', queries.token); // TODO : [ean] 해당 처리는 session/me 에서 조회하거나 sign-in 시 활용할 토큰을 적재하는걸로 로직 변경 필요
        this.store.commit('query/setToken', queries.token); // TODO : [ean] query로 온 토큰과 auth api로 받은 token을 구분하여 사용할 예정
      }
    } catch (e) {
      log({ logLevel: 'ERROR', message: 'error: init service'}, {host, url, queries, e});
      if (e.errorTemplate?.[apiErrorType.AUTH]) location.href = `/auth-error/${e.errorTemplate?.ci}/${e.errorTemplate?.cci}`;
    }

    return true;
  }

  setRouter(router) {
    this.router = new RouterProxy(router);
    this.router.setRouter(router);
  }
}

export const servicePlugin = {
  install(Vue, { services }) {
    Vue.prototype.$services = services;
  }
};

const createServiceManager = ({ store, cookies, router }) => new ServiceManager({ store, cookies });

export default createServiceManager;
