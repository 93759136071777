<template>
  <td :class="rowClass"><slot /></td>
</template>

<script>
export default {
  name: 'GridTableTD',
  props: {
    rowClass: { type: String },
  },
};
</script>
