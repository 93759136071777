<template>
  <div input-personal-name>
    <div :class="['content', 'view']" v-if="isDefaultStep">
      <LabelComponent :label="$t('firstName')" :value="model.FirstName" />
      <LabelComponent :label="$t('lastName')" :value="model.LastName" />
      <LabelComponent :label="$t('gender')" :value="genderLabel" />
      <LabelComponent v-if="isGGPDE" :label="$t('myInfo.personalDetails.lastNameAtBirth')" :value="model.MaidenName" />
    </div>
    <div :class="['content', 'edit']" v-if="isInputStep">
      <InputComponent :label="$t('firstName')" name="_firstName" :component="TextInputBox" :rules="firstNameRules" v-model="model.FirstName" :preset="firstNamePreset" :disabled="!editableModel.FirstName" :error-msg="error.FirstName" @input="value => input('FirstName', value)" />
      <InputComponent :label="$t('lastName')" name="_lastName" :component="TextInputBox" :rules="lastNameRules" v-model="model.LastName" :preset="lastNamePreset" :disabled="!editableModel.LastName" :error-msg="error.LastName" @input="value => input('LastName', value)" />
      <InputComponent :label="$t('gender')" name="_gender" :component="SearchDropSelect" :rules="genderRules" :value="model.Gender" :preset="titlePreset" :disabled="titleDisabled" :error-msg="error.Gender" @input="updateGender" />
      <InputComponent v-if="isGGPDE" :label="$t('myInfo.personalDetails.lastNameAtBirth')" name="_lastName" :component="TextInputBox" :rules="firstNameRules" v-model="model.MaidenName" :preset="{...firstNamePreset, disable :!editableModel.MaidenName }" :disabled="!editableModel.MaidenName" :error-msg="error.MaidenName" @input="value => input('MaidenName', value)" />
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import DropSelect from '@shared/components/common/input/DropSelect.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap.mjs';
import LabelComponent from '@/views/components/gg-pass/LabelComponent.vue';
import npStepInfo from '@/mixins/my-info/npStepInfo';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';


export default {
  name: 'InputPersonalName',
  components: { LabelComponent, InputComponent },
  mixins: [npStepInfo],
  props: {
    model: Specific,
    error: Specific,
    editableModel: Specific,
    structure: Specific,
    countryWritingCode: null,
  },
  data() {
    return {
      TextInputBox,
      DropSelect
    };
  },
  computed: {
    SearchDropSelect() {
      return SearchDropSelect;
    },
    site: state('env', 'site'),
    firstNamePreset() {
      return { placeholder: this.$t('firstName'), disable: !this.editableModel.FirstName };
    },
    titleDisabled() {
      return !this.editableModel.Gender;
    },
    lastNamePreset() {
      return { placeholder: this.$t('lastName'), disable: !this.editableModel.LastName };
    },
    titlePreset() {
      return { placeholder: '-', list: this.genderList, translate: false, auto: true, disable: this.titleDisabled, disabled: this.titleDisabled, selectOnly: true, hiddenIcon: this.titleDisabled };
    },
    firstNameRules() {
      return `required|validateName:1,50,${this.countryWritingCode},${this.$t('firstName')}`;
    },
    lastNameRules() {
      return `required|validateName:1,50,${this.countryWritingCode},${this.$t('lastName')}`;
    },
    genderRules() {
      if (this.isN8IN) return '';
      else return 'required';
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    isN8IN() {
      return this.site === siteIds.N8IN;
    },
    genderList() { // [celina] mr, ms 번역 Mr.{name}, Ms.{name} 으로 되어야할 것으로 보임
      return [{ label: this.$t('mr'), value: 'Male' }, { label: this.$t('ms'), value: 'Female' }];
    },
    genderLabel() {
      if (!this.model.Gender || this.model.Gender === 'Undefined') return null;
      const gender = this.genderList.find(gender => gender.value === this.model.Gender);
      if (!gender) return null;
      return gender.label;
    },

  },
  methods: {
    input(key, value) {
      this.$emit('update', key, value);
    },
    updateGender(v) {
      if (!v) return;
      this.$emit('update', 'Gender', v?.value);
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-personal-name] { .flex(); .flex-dc(); .gap(10);
  .content {.flex(); .flex-dc(); .gap(10); }
}
</style>
