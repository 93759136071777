<template>
  <BaseModal np-resend-modal :show-title="false">
    <template #content>
      <div class="modal-body">
        <FontIcon :name="icon" :class="['icon',{'success' : isSuccess}]" />
        <div class="content">
          <p v-html="message" />
        </div>
      </div>
    </template>
    <template #footer>
      <GpButton type="submit-green" @click="close">{{ $t('close') }}</GpButton>
    </template>
  </BaseModal>
</template>

<script>

import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
export default {
  name: 'GpMessageModal',
  components: { GpButton, GpDescription, BaseModal, FontIcon },
  props: {
    options: {
      success: { type: Boolean, default: true, },
      message: { type: String, default: null },
    }
  },
  data() {
    return {
      title: null,
      message: null,
    };
  },
  computed: {
    isSuccess() {
      return this.options.success;
    },
    icon() {
      return this.isSuccess ? 'check-over-circle-regular' : 'exclamation-circle-regular';
    },
  },
  methods: {
    close() {
      this.$modalCloseAll();
    },
  },
  mounted() {
    this.title = this.options.title;
    this.message = this.options.message;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-resend-modal] {
  > .panel { .rel(); overflow: visible;
    .scroll-area {.h(calc(100% - 59px)); .flex(); .flex-dc(); overflow-y: visible;
      > .body { flex: 1; }
    }
  }
  .modal-body {.flex(); .flex-dc(); .gap(10); .justify-center(); .items-center();
    .icon {.fs(30); .c(@c-red);
      &.success {.c(@gp-green);}
    }
    .content { .ph(20); .w(80%);
      > p {.pt(0); .pb(20); text-align: center; .fs(14); .c(#000);}
    }
  }
  .footer {.flex(); .gap(10);}
}
</style>
