<template>
  <ColorButton promotion-button :type="color" :class="[hoverType, disabledType, {'hover-disable': disabled}]" :disabled="disabled" @click.stop="$emit('click',$event)">
    <span><slot /></span>
  </ColorButton>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';

export default {
  name: 'PromotionButton',
  components: { ColorButton },
  props: {
    color: { type: String, default: 'light-red' },
    hoverStyle: { type: String, default: 'dark' },
    disabled: { type: Boolean, default: false },
    disabledStyle: { type: String, },
  },
  computed: {
    hoverType() {
      const style = this.hoverStyle;
      return style ? `hover-${style}` : '';
    },
    disabledType() {
      const style = this.disabledStyle;
      return style ? `disabled-${style}` : '';
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotionButton.less";
</style>
