import { brandIds, siteIds } from '@/constants/base/siteMap';
import _ from 'lodash';
import { getCookie, removeCookie } from '@shared/utils/cookieUtils';
import { appendQuery, parseQuery } from '@shared/utils/urlUtils';
import { defaultSiteMap, ggpassSiteMap } from '@/constants/base/signup/signUpSiteMap';

const cookieKey = {
  token: process.env.VUE_APP_COOKIE_TOKEN_KEY,
  isFromClientApp: 'isFromClientApp',
  isFromMobileApp: 'isFromMobileApp',
  redirectUri: 'redirectUri',
};

export const getTitle = ({ host, queries, siteInfo }) => {
  const warpMode = queries.isFromExternalUrl && queries.siteId;
  const channelingBrand = { ocean: 'OCEAN' };

  let siteTitle = queries.channelingName && channelingBrand[queries.channelingName] ? `${channelingBrand[queries.channelingName]} X ${siteInfo.title}` : warpMode ? `Signup | ${siteInfo.brandName}` : siteInfo.title;
  if (host.includes('ocean')) siteTitle = `OCEAN X ${siteInfo.title}`;
  return siteTitle;
};

export const getLocalApp = url => {
  const appList = {
    'find-id': 'fi', 'myinfo': 'myinfo', 'responsible-gaming': 'rg',
    'forgot-password': 'fp', 'promotion': 'promotion', 'onboarding': 'onboarding', 'signup': 'signup',
  };
  const key = Object.keys(appList).find(o => url.includes(o));
  return key?.length ? appList[key] : '';
};

export const isTest = () => ['local', 'development', 'test'].includes(process.env.VUE_APP_ENV);
export const isLocal = () => process.env.VUE_APP_ENV === 'local';
export const isExcludedProduction = () => process.env.VUE_APP_ENV !== 'production';

const getDownloadPath = (clientBrandName, isMobile, baseLang) => {
  const agent = window.navigator.userAgent;
  const os = agent.includes('Mac') ? 'osx' : 'windows';
  const deviceAndOs = isMobile ? 'mobile-page' : `desktop/installer/${os}`;
  return `${deviceAndOs}/${clientBrandName}/${baseLang}`;
};
const getDownLoadQueries = (queries, channelingName) => {
  const { qtag, qtag1, btag, btag1 } = /** @type { UrlParameters } */ queries;
  const agent = window.navigator.userAgent;
  const windowBit = agent.includes('Win64') ? `arcg=x64` : '';
  const bonusCode = channelingName || qtag || qtag1 || btag || btag1;
  const tag = bonusCode ? `${windowBit && '&'}btag1=${bonusCode}` : '';
  return `${(windowBit || tag) && '?'}${windowBit}${tag}`;
};

export const setSiteInfo = async (url, host, queries, store, userSite) => {
  const { VUE_APP_ENV, VUE_APP_SITE } = process.env;
  const npOnly = Boolean(queries.ReferenceKey);
  const site = (userSite || queries.siteId || VUE_APP_SITE);
  const siteInfo = defaultSiteMap({ domain: host, site, useBlock: site?.length && site.toLowerCase() !== 'none', npOnly });
  const app = queries.app ? queries.app : store.state.query.app ? store.state.query.app : getLocalApp(url);

  if (queries.ggpass) updateSignEnv({ host, store, route: this.$route, siteInfo: ggpassSiteMap(), queries });
  else updateSignEnv({ host, store, route: null, siteInfo, queries, site });

  store.commit('query/setApp', app);
  store.commit('query/setRefKey', queries.ReferenceKey || queries.referenceKey);
  store.commit('query/setFindKey', queries.FindKey || queries.findKey);
  store.commit('query/setSelectedLang', queries.lang);
  store.commit('query/setAtag', queries.atag || queries.aTag || queries.ATAG);
  store.commit('query/setMobileAnonymousId', queries.mobile_anonymous_id);
  store.commit('env/setEnv', VUE_APP_ENV);
  store.commit('env/setHost', host);
  store.commit('env/setQueries', queries);
  store.commit('env/setNpOnly', npOnly);
  store.commit('env/setCustomIp', queries.customIp);
  store.commit('env/setTitle', getTitle({ host, queries, siteInfo }));
  store.commit('env/setGgPass', isExcludeGgpass(siteInfo.site) && queries.ggpass);
  store.commit('env/setCasino', host.includes('casino') || process.env.VUE_APP_CASINO);

  return true;
};

export const updateSignEnv = ({ host, store, route, siteInfo, queries }) => {
  const { tt, isFromLanding, isFromExternalUrl, invite, tracking, closeUrl, originId, siteId, channelingName, additional, restrict, customStep, CustomStep, citizenshipCode, stripLayout, theme } = /** @type { UrlParameters } */ queries;
  const isCasino = host.includes('casino');
  const lowerChannelingName = channelingName ? channelingName.toLowerCase() : channelingName;

  store.commit('env/setSiteInfo', siteInfo);
  store.commit('env/setSite', siteInfo.site);
  store.commit('user/setRecaptchaKey', siteInfo.recaptchaKey);
  store.commit('env/setBrand', isCasino ? siteInfo.brandAlias.casino : siteInfo.brandAlias.default || siteInfo.brandAlias);
  store.commit('env/setBrandName', siteInfo.brandName);
  store.commit('env/setLogo', siteInfo.logo);
  store.commit('env/setFavicon', siteInfo.favicon);
  store.commit('env/setTheme', siteInfo.theme || theme || 'BASE');
  store.commit('env/setCaptcha', siteInfo.captcha || 'recaptcha');
  store.commit('env/setApi', siteInfo.api);
  store.commit('env/setDefaultSite', siteInfo.site);
  store.commit('env/setRouteNames', siteInfo.routeNames);
  store.commit('env/setWidgetUrl', siteInfo.widgetUrl);
  store.commit('env/setCsEmail', siteInfo.csEmail);
  store.commit('env/setGpBrand', isCasino ? siteInfo.gpBrand.casino : siteInfo.gpBrand.default || siteInfo.gpBrand);
  store.commit('env/setGpSite', siteInfo.gpSite);
  store.commit('env/setLangList', siteInfo.langList);
  store.commit('env/setBrandSignup', !!siteInfo.site && !!originId && !!theme && String(stripLayout) === 'true');
  store.commit('env/setBlockCountries', _.map(siteInfo.blockList, o => ({ country: o.country, link: `https://${o.link[process.env.VUE_APP_ENV]}/?ggManualLink=1`, name: o.link.name, label: o.link.label })));
  if (route) store.commit('env/setIsTracking', route.path.indexOf('/tracking') >= 0 || tracking);

  /* queries */
  store.commit('query/setCustomStep', customStep || CustomStep);
  store.commit('query/setRestrict', restrict);
  store.commit('query/setAdditional', additional);
  store.commit('query/setLandingMode', (isFromExternalUrl && siteId) ? 'warp' : tt === '1' ? 'landing' : '');
  store.commit('query/setIsFromLanding', isFromLanding);
  store.commit('query/setInvite', [siteIds.GGPUKE, siteIds.EVPUKE].includes(siteInfo.site) ? invite : null);
  store.commit('query/setIsFromExternalUrl', String(isFromExternalUrl) === 'true');
  store.commit('query/setCitizenshipCode', citizenshipCode);
  store.commit('query/setChannelingName', lowerChannelingName);
  store.commit('query/setCloseUrl', closeUrl);
  store.commit('query/setOriginId', originId);
  store.commit('query/setStripLayout', String(stripLayout) === 'true');
};

export const getDownloadUrl = ({ brand, site, isMobile, baseLang = 'en', queries, baseUrl, channelingName }) => {
  const clientBrandName = brand || siteIds[site];
  if ([brandIds.GGPUKE.toUpperCase(), brandIds.EVPUKE.toUpperCase()].includes(brand)) {
    baseUrl = 'download.bjhav.com/';
  }
  return `//${baseUrl}${getDownloadPath(clientBrandName, isMobile, baseLang)}${getDownLoadQueries(queries, channelingName)}`;
};

export const getBrowserLang = () => {
  let r = navigator.language.toLowerCase();
  if (r === 'ko-kr') r = 'ko';
  if (r === 'en-us') r = 'en';
  return r;
};

export const getDomain = host => {
  if (typeof window === 'undefined') return null;
  const hosts = new URL(host);
  const domain_array = hosts.hostname.replace('www', '').split('.');
  const cnt = domain_array.length;
  let domain = '';

  if (cnt >= 3 && domain_array[cnt - 2].length === 2) {
    domain = domain_array.splice(cnt - 3, 3).join('.');
  } else if (cnt >= 2) {
    domain = domain_array.splice(cnt - 2, 2).join('.');
  }

  return domain;
};

export const checkPokerOk = (domain, target) => {
  if (domain.includes('poker-arabia.com')) return false;
  if (_.some(target.includeNumeric, o => domain.includes(o))) return target;
  return false;
};

/**
 * Sign-up, On-boarding 여부 반환
 * structure 정보가 있을 경우는 target으로 확인이 가능하나 없을 경우는 url정보로 구분하여 처리
 * @returns {String}
 */
export const getServiceName = () => (!window?.location?.pathname.split('/').includes('Deposit') ? 'signUp' : 'onBoarding');

export const queries = ggpass => {
  const queryItems = parseQuery(location.search);
  return ggpassNullQueries(queryItems, ggpass);
};

export const ggpassNullQueries = (queries, ggpass) => {
  if (ggpass) {
    queries.channelingUrl = null;
    queries.redirectUri = null;
    queries.isFromMobileApp = null;
    queries.isFromClientApp = null;
    queries.isFromLanding = null;
    queries.targeturl = null;
  }
  return queries;
};

export const completeRemoveCookie = () => {
  const MAIN_DOMAIN = process.env.VUE_APP_ENV === 'local' ? 'localHost' : `.${getDomain(window.location.origin)}`;
  removeCookie(cookieKey.token);
  removeCookie(cookieKey.isFromClientApp, { domain: `.${MAIN_DOMAIN}` });
  removeCookie(cookieKey.isFromMobileApp, { domain: `.${MAIN_DOMAIN}` });
  removeCookie(cookieKey.redirectUri, { domain: `.${MAIN_DOMAIN}` });
};

export const initRemoveCookie = () => {
  const MAIN_DOMAIN = process.env.VUE_APP_ENV === 'local' ? 'localHost' : `.${getDomain(window.location.origin)}`;
  const queries = parseQuery(location.search);

  removeCookie(cookieKey.token);
  !queries.isFromClientApp && removeCookie(cookieKey.isFromClientApp, { domain: `.${MAIN_DOMAIN}` });
  !queries.isFromMobileApp && removeCookie(cookieKey.isFromMobileApp, { domain: `.${MAIN_DOMAIN}` });
  !queries.redirectUri && removeCookie(cookieKey.redirectUri, { domain: `.${MAIN_DOMAIN}` });
};

export const redirectCompleteUrl = async scope => {
  const redirectUri = getCookie(cookieKey.redirectUri);
  if (redirectUri) {
    const tokenResponse = await scope?.$services.token.getToken();
    const token = tokenResponse?.data?.npToken?.accessToken;

    if (token) {
      completeRemoveCookie();
      location.href = appendQuery(redirectUri, { token });
      return true;
    }
  }

  return false;
};

export const isInfoCaptcha = site => isExcludeTnc(site) || [siteIds.GGPUKE, siteIds.EVPUKE].includes(site);

export const isExcludeGgpass = site => [siteIds.POKERARABIA, siteIds.NATURAL8, siteIds.N8IN, siteIds.SEVENXL, siteIds.TWOACE].includes(site);

export const isExcludeTnc = site => [siteIds.GGPUKE, siteIds.EVPUKE, siteIds.SEVENXL, siteIds.NATURAL8, siteIds.DAVAOPOKER].includes(site);

/**
 * GGPFI, GGPEU, GGPHU 인 사이트 인 경우 MGA라고 명시
 * @param site
 * @returns {boolean}
 */
export const isMga = site => [siteIds.GGPFI, siteIds.GGPEU, siteIds.GGPHU].includes(site);