<template>
  <div progress-title>
    <h1>
      <button @click="goBack" v-if="structure && step > 0 && !final && !disabledBack">
        <FontIcon name="arrow-left" :default-path="cdnSvgPath" />
      </button>
      {{ $t(title) }}
    </h1>
    <p class="progress"><span :style="{width}" /></p>
    <FloatingAdminButton v-if="isLocal" />
  </div>
</template>

<script>
import { isLocal } from '@/utils/baseUtil';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import FloatingAdminButton from '@/views/components/common/FloatingAdminButton.vue';
import { routeNames, STEP_TYPES } from '@/constants/base/onboarding/onBoardingSiteMap';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'ProgressTitle',
  components: { FloatingAdminButton, FontIcon },
  props: {
    structure: { /** @type {ModelInfo} */type: Specific, default: null },
    defaultLabel: String,
    isFinal: Boolean,
  },
  data() {
    return {
      width: 0,
    };
  },
  computed: {
    site: state('env', 'site'),
    isLocal() {
      return isLocal();
    },
    title() {
      return this.defaultLabel || (this.isFinal ? 'onBoarding.title.complete' : this.structureTitle);
    },
    structureTitle(){
      const t = this.structure.list[this.step].title
      if (!t) return '';
      return t?.[this.site] || t.default || t;
    },
    final() {
      return this.isFinal || this.structure.list[this.step].final || this.structure.list.length === 1;
    },
    disabledBack() {
      return this.structure.list[this.step].disabledBack;
    },
    step() {
      if (this.isFinal) return;
      return Number(this.structure?.step ?? 0);
    },
    progress() {
      if (this.isFinal) return;
      return Number(this.structure?.progress ?? 0);
    },
    customStep() {
      return Number(this.structure?.customStep ?? 0);
    },
  },
  watch: {
    isFinal: 'update',
    progress: 'update',
    customStep: 'update',
  },
  methods: {
    update() {
      /*
      * 현재 진행중인 Step의 index와 (this.progress)
      * 진행해야 할 Step의 count를 (this.structure.originCount)
      * 비교하여 같거나 더 커질 경우 현재 진행중인 Step을 저장하고
      * 아닐 경우는 index는 0부터 시작하므로 1을 추가하여 저장
      */
      if (this.final || !this.structure || !this.structure.progressGroup) {
        this.width = '100%';
      } else {
        const arrDestructionStep = [];
        for (let i = 0; i < this.structure.progressGroup.length; i++) {
          if (Array.isArray(this.structure.progressGroup[i])) {
            arrDestructionStep.push(...this.structure.progressGroup[i]);
          } else {
            arrDestructionStep.push(this.structure.progressGroup[i]);
          }
        }

        if (arrDestructionStep[this.progress]?.route === routeNames.personalDetailReaffirm) {
          if (!this.structure.customSteps) {
            this.width = '100%';
          } else {
            let t;
            let n = this.customStep;
            for (let i = 0; i < this.structure.customSteps.length; i++) {
              t = Array.isArray(this.structure.customSteps[i]) ? this.structure.customSteps[i].length : 1;
              if (n >= t) n -= t;
              else break;
            }
            this.width = `${100 * ((n + 1) / t)}%`;
          }
        } else {
          if (arrDestructionStep[this.progress]?.stepType === STEP_TYPES.ETC) {
            this.width = '100%';
          } else {
            let t;
            let n = this.progress;
            for (let i = 0; i < this.structure.progressGroup.length; i++) {
              t = Array.isArray(this.structure.progressGroup[i]) ? this.structure.progressGroup[i].length : 1;
              if (n >= t) n -= t;
              else break;
            }
            this.width = `${100 * ((n + 1) / t)}%`;
          }
        }
      }
    },
    goBack() {
      this.replaceRouteName(this.structure.list[this.structure.step - 1].route, null, this.$route.query);
    },
  },
  mounted() {
    this.update();
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[progress-title] { .mb(24); .rel();
  h1 { .tl(); .medium(); .fs(28); .c(white); .flex(); .items-center(); .wf(); .regular(); line-height: 1.2;
    [font-icon] { .fs(20); .mr(16); .mt(4); .c(white); }
  }
  .progress { .wh(100%, 2); .rel(); .bgc(rgba(255, 255, 255, 0.07)); .block(); .mt(16);
    span { .block(); .wh(0, 2); .bgc(@c-red); }
  }
  .btn-logout { .abs(); .rt(0, 0); }
}
</style>
