<template>
  <div questionnaire-limit-amount-modal>
    <div class="content" :class="{gradient}">
      <p v-html="$t('responsibleGaming.modal.description.affordabilityQuestionnaire')" />
      <div class="body">
        <div v-if="displayList" class="contents">
          <div class="item-body" v-for="(item, idx) in displayList" :key="idx">
            <div class="label">{{ item.label }}</div>
            <div class="row-content">
              <section class="row">
                <label class="row-title">{{ $t('_.currentLimit') }}</label>
                <label class="amount">{{ item.currentValue }}</label>
              </section>
              <section class="row">
                <label class="row-title">{{ $t('_.requestLimit') }}</label>
                <label class="amount">{{ item.requestValue }}</label>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
export default {
  name: 'QuestionnaireLimitAmountModal',
  lexicon: 'responsibleGaming.modal.label',
  props: {
    calculateList: { type: Specific, default: null },
    gradient: { type: Boolean, default: null }
  },
  data() {
    return {
      displayList: [],
    };
  },
  computed: {
    TextInputBox() {
      return TextInputBox;
    }
  },
  methods: {
    initialize() {
      if (!this.calculateList) this.displayList = [];
      const calculateResults = this.calculateList.map(item => this.createDisplayItem(item));
      const list = calculateResults.filter(x => x !== null && x.requestValue != null && x.requestValue !== '');
      this.displayList = list.map(x => ({label: x.label, currentValue: x.currentValue != null ? `${x.currencySymbol}${x.currentValue}` : '', requestValue: x.requestValue != null ? `${x.currencySymbol}${x.requestValue}` : ''}));
    },
    getLabelKey(item) {
      const { rolling, type } = item;
      const isRolling = rolling === 'Rolling';
      const prefix = 'onBoarding.setting';

      switch (type) {
        case 'daily':
          return isRolling ? `${prefix}.24hours` : 'daily';
        case 'weekly':
          return isRolling ? `${prefix}.7Days` : 'weekly';
        case 'monthly':
          return isRolling ? `${prefix}.30Days` : 'monthly';
        default:
          return null;
      }
    },
    createDisplayItem(item) {
      return {
        label: this.$t(this.getLabelKey(item)),
        currentValue: item.current,
        requestValue: item.request,
        regulationValue: item.regulation,
        dmiValue: item.dmi,
        currencySymbol: item.currencySymbol
      };
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[questionnaire-limit-amount-modal] {
  .body { .pt(24);
    .item-body {.flex(); .flex-dc(); .gap(5);
      .label {.fs(14); font-weight: 500; .c(#000);}
      .row-content {.bgc(#fff); .p(10, 20, 10, 20); .br(8); .flex(); .flex-dc(); .gap(10);
        .row {.flex(); .space-between(); font-weight: 400; .fs(14);
          .row-title {.block(); .c(#737373);}
          .amount {.block(); .c(#000); }
        }
      }
    }
    .item-body + .item-body { .pt(20); }
  }
}
</style>
