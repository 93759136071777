export default {
  data() {
    return {
      pageChunk: 10,
      currentCursor: 0,
      info: {},
    };
  },
  methods: {
    async pageReset({ service, api, cursor, extra = {} }) {
      if (this.info && (cursor * this.pageChunk) > this.info.totalCount) cursor = Math.floor(this.info.totalCount / this.pageChunk);
      this.info = await service[api]({ CurrentPage: cursor, PageSize: this.pageChunk, ...extra });
      await this.updatePaging(cursor);
    },
    async updatePaging(cursor) {
      await new Promise(resolve => {
        this.currentCursor = cursor;

        const nextCursor = this.currentCursor + 1;
        const hasNext = nextCursor * this.pageChunk < this.info.TotalCount;
        this.info = { items: this.info.List || this.info.Changes || this.info.Banners, currentCursor: this.currentCursor, nextCursor, totalCount: this.info.TotalCount, hasNext, ...this.info };
        resolve();
      });
    },
    setPageChunk(num) {
      this.pageChunk = num;
    },
    setCursor(cursor) {
      this.currentCursor = cursor;
    }
  }
};