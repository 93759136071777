<template>
  <ColorButton progress-button :type="color" :disabled="disabled || progress || pause" :class="buttonClass" @click="click">
    {{ $t(buttonName, options) }}
    <i class="sync"><FontIcon name="rotate-arrows" v-if="progress"/></i>
  </ColorButton>
</template>

<script>

import ColorButton from '@shared/components/common/ColorButton.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from "@shared/types/Specific";
import FontIcon from '@shared/components/common/FontIcon.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'ProgressButton',
  components: { FontIcon, ColorButton },
  props: {
    buttonName: { type: String, default: null, required: true },
    disabled: { type: Boolean, default: false },
    progress: { type: Boolean, default: false },
    options: { type: Specific, default: null },
    size: { type: String, default: 'medium' },
  },
  data() {
    return {
      pause: false,
    }
  },
  computed: {
    color() {
      return this.theme === 'GOLD' ? 'gold' : 'light-red';
    },
    buttonClass() {
      return { ...this.progress, [this.size]: !!this.size };
    },
    theme: state('env', 'theme'),
  },
  methods: {
    async click() {
      this.pause = true;
      this.$emit('click');
      await sleep(1000);
      this.pause = false;
    }
  }
};
</script>

<style lang="less">
@import "@/less/proj.less";
.GOLD {
  [progress-button] {
    &.progress {
      .sync { .fill(white); }
    }
  }
}
[progress-button][color-button] { .wh(100%, 56); .br(28); .fs(18); .tr-t(.3s); .rel(); .max-w(320); .mh-c(); .block(); .medium();
  .sync { animation: rolling 1s infinite; .wh(32); animation-timing-function: linear; .fill(white); .abs(); .rt(16, 50%); .mt(-16);
    [font-icon] { .tc();}
  }
  &.large { .max-w(400); }
  &.small { .max-w(260); }
  &.progress {
    .sync { .fill(#c64040); }
  }
  @keyframes rolling {
    50% { .t-r(180deg); }
    100% { .t-r(360deg); }
  }
  @media(@ml-up) { .max-w(440);
    &.large { .max-w(500); }
    &.small { .max-w(360); }
  }
}
</style>
