<template>
  <div fp-info class="field-wrapper">
    <div class="field-holder">
      <div class="mobile-holder" v-if="mobileMode">
        <ValidationComponent :label="$t('mobileNumber')" name="_countryNumber" :component="FpCountryNumberDropSelect" rules="requiredSelect" v-model="countryInfo" @input="updateCountry" @focus="$emit('focus')" @blue="$emit('blue')" />
        <ValidationComponent class="number-field" name="_mobileNumber" :component="TrimTextInput" rules="required" v-model="modelInfo.MobileNumber" :preset="{phone: true, type: 'tel'}" />
      </div>
      <EmailSearchSelect :model-info="modelInfo" :structure="structure" rules="requiredSignEmail|required" v-else />
      <!--      <ValidationComponent v-else :label="$t('emailAddress')" name="_email" :component="TrimTextInput" rules="requiredSignEmail|required" v-model="modelInfo.Email" :error-msg="structure.errorMsg" :preset="{type: 'email'}" />-->
      <ValidationErrors v-if="mobileMode" class="error-msg" :errorMsg="structure.errorMsg" />
    </div>
    <div v-if="showChangeMode">
      <p class="btn-holder"><a @click="toggleType" v-html="otherType"></a>
        <FontIcon class="icon" name="angle-right" />
      </p>
    </div>
  </div>
</template>

<script>
import { isExcludeGgpass, } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';

import { siteIds } from '@/constants/base/siteMap.mjs';
import FontIcon from '@shared/components/common/FontIcon.vue';
import EmailSearchSelect from '@/views/components/common/EmailSearchSelect.vue';
import FpCountryNumberDropSelect from '@/views/components/pages/forgot-password/FpCountryNumberDropSelect.vue';

export default {
  name: 'FpInfo',
  components: { FontIcon, ValidationComponent, EmailSearchSelect },
  props: {
    structure: Specific,
    modelInfo: Specific,
  },
  data() {
    return {
      queries: null,
      captcha: null,
      testCaptcha: true,
      countryInfo: Specific,
      action: 'RESET_PASSWORD',
      TextInput,
      FpCountryNumberDropSelect
    };
  },

  computed: {
    site: state('env', 'site'),
    addressPreset() {
      return { placeholder: this.placeholder, maxLength: 100, type: 'loqate-address' };
    },
    TrimTextInput() {
      return TrimTextInput;
    },
    mobileMode() {
      return this.modelInfo.LoginMethod === 'Mobile';
    },
    isMBP() { return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site); },
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    showChangeMode() {
      return this.$route.name === 'FpInfo' && ![siteIds.TWOACE, siteIds.OCNP].includes(this.site);
    },
    isTwoAce() {
      return this.site === siteIds.TWOACE;
    },
    otherType() {
      return this.mobileMode ? this.$t('forgotPassword.label.changeEmail') : this.$t('forgotPassword.label.changeMobile');
    },
  },
  watch: {
    'modelInfo.Email': function () {this.$emit('clear-error'); },
    'modelInfo.MobileNumber': function () {this.$emit('clear-error'); },
    'modelInfo.CountryCode': function () {this.$emit('clear-error'); }
  },
  methods: {
    updateCountry(v) {
      if (!v) return;
      this.countryInfo = v;
      if (this.countryInfo) {
        if (!this.isGgpass) this.modelInfo.CountryCode = this.countryInfo.CountryCode;
        else this.modelInfo.CountryCode = `+${this.countryInfo.PhoneCountryCode}`;
      }
    },
    timerComplete() {
      this.$emit('timer-complete');
    },
    toggleType() {
      this.structure.errorMsg = null;
      this.modelInfo.LoginMethod = this.modelInfo.LoginMethod === 'Email' ? 'Mobile' : 'Email';
      this.modelInfo.SendVerificationType = this.modelInfo.SendVerificationType === 'EMAIL' ? 'MOBILE' : 'EMAIL';
    },
  },
  async mounted() {
    this.structure.title = 'fpTitle';
    this.structure.desc = 'forgotPassword.description.enterMethod';
    this.structure.back = false;
    this.modelInfo.LoginMethod = 'Email';
    this.modelInfo.SendVerificationType = 'EMAIL';
    this.structure.showButton = true;
    this.structure.errorCode = null;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-info] {.flex();.flex-dc();
  .field-holder {flex: 1;}
  .or { .fs(16, 20); .c(#7F7F7F); .mv(20); .rel();
    span { .ph(12); .bgc(#171717); .rel(); .z(1);}
    em { .wh(100%, 1); .bgc(#333); .block(); .abs(); .lt(50%, 50%); .t-xyc(); .max-w(440); }
  }
  .error-msg {.pl(2); .m(0); .c(@c-red); .fs(14); .pt(5);}
  .mobile-holder { .flex(); .flex-dc();
    > div + div { .mt(4); }
    @media (@ml-up) {
      .flex(); .flex-dr(); .space-between();
      > div + div { .mt(0); }
      > * {
        &:nth-of-type(1) { .w(35%); }
        &:nth-of-type(2) { .w(calc(65% - 8px)); }
      }
    }
  }
  .btn-holder {margin-bottom: 0px !important; .flex(); .justify-center(); .items-center(); .gap(5); .fs(16);
    > a {text-decoration-line: underline;}
    .icon {.c(#C64040)}
  }
}
</style>