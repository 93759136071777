<template>
  <div ph-pos-floating v-html="posPopover">
  </div>
</template>

<script>
import { cdnImgPath } from '@shared/utils/cdnUtils.mjs';
export default {
  name: 'PhPosFloating',
  data() {
    return {
      posPopover: null,
      popoverList: [
        'kyc.proofOfSelfie.popoverDescription1',
        'kyc.proofOfSelfie.popoverDescription2',
        'kyc.proofOfSelfie.popoverDescription3',
      ],
    };
  },
  async mounted() {
    this.posPopover = `<img src='${cdnImgPath}/kyc_poi_selfie.png' alt="selfie sample"><br/>${this.popoverList.map(i => `• ${this.$t(i)}`).join('<br/>')}`;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[ph-pos-floating] { box-shadow: 0 6px 12px rgba(0,0,0,0.175); .p(12); .br(4); .bgc(#0e1415); .fs(13); .c(white);
  >img { .w(100%); .mb(10); }
  >span { .c(@c-white); .fs(14); }
  >a { .fs(14); .c(#4A8CFF); .-b(#4A8CFF); .medium(); }
}
</style>