<template>
  <div date-of-birth v-if="age">
    <ValidationProvider v-slot="{errors}" rules="required">
      <input type="hidden" v-model="modelInfo.DateOfBirth">
      <div class="form-holder">
        <ValidationComponent :label="$t('dateOfBirth')" name="_month" :component="SearchDropSelect" rules="requiredNone" v-model="age.month" :preset="birthPreset" @input="updateAge" :error-msg="myErrorMsg" :auto-focus="false" />
        <ValidationComponent name="_day" :component="TrimTextInput" rules="required|minMaxNone:1,31" v-model="age.day" :preset="dayPreset" @input="updateAge" :error-msg="myErrorMsg" :auto-focus="false" />
        <ValidationComponent name="_year" :component="TrimTextInput" rules="required|lengthMin:3" v-model="age.year" :preset="yearPreset" @input="updateAge" :error-msg="myErrorMsg" :auto-focus="false" />
      </div>
      <ValidationErrors v-if="myErrorMsg" class="error-msg" :errors="errors" :error-msg="myErrorMsg" />
    </ValidationProvider>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { customDateFormat, getYYYYMMDDTHHmmssZ } from '@shared/utils/timeUtils.mjs';
import { makeRules } from '@/plugins/validate';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { state } from '@shared/utils/storeUtils.mjs';
import { tncInfo } from '@/utils/webSiteUtil';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';

const BIRTH_LIST = [
  { label: 'jan', value: '01' }, { label: 'feb', value: '02' }, { label: 'mar', value: '03' }, { label: 'apr', value: '04' }, { label: 'may', value: '05' }, { label: 'jun', value: '06' },
  { label: 'jul', value: '07' }, { label: 'aug', value: '08' }, { label: 'sep', value: '09' }, { label: 'oct', value: '10' }, { label: 'nov', value: '11' }, { label: 'dec', value: '12' }
];

export default {
  name: 'DateOfBirth',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  data() {
    return {
      birthList: BIRTH_LIST,
      age: null,
      observerValue: null,
      myErrorMsg: null,
      currentInput: null,
      rules: null,
      SearchDropSelect,
      TrimTextInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    birthPreset() {
      const list = BIRTH_LIST.map(o => ({ ...o, label: this.$t(o.label) }));
      return { list, size: 'md', selectOnly: true, placeholder: this.$t('month'), auto: true, disable: this.disabled || this.disabledDateOfBirth };
    },
    dayPreset() {
      return { digit: true, maxLength: 2, inputMode: 'numeric', pattern: '[0-9]*', placeholder: this.$t('day'), disable: this.disabled || this.disabledDateOfBirth };
    },
    yearPreset() {
      return { digit: true, maxLength: 4, inputMode: 'numeric', pattern: '[0-9]*', placeholder: this.$t('year'), disable: this.disabled || this.disabledDateOfBirth };
    },
    birthError() {
      return !this.rules.dateOfBirth.validate(this.age) ? this.$tv({ _rule_: 'dateOfBirth' }) : null;
    },
    ageError() {
      const { age } = tncInfo(this.site);
      return !this.rules.confirmAge.validate(this.age, { age }) ? this.$tv({ _rule_: 'confirmAge' }).replace('!!age!!', age) : null;
    },
    isReCheckCustomStep() {
      if (!this.structure.customSteps) return false;
      return this.structure.customSteps.indexOf(routeNames.personalDetailsRecheck) >= 0;
    },
    disabledDateOfBirth() {
      return this.modelInfo.blockFields?.includes('DateOfBirth') && !!this.modelInfo.DateOfBirth;
    },
    hasValue() {
      return this.age.month || this.age.day || this.age.year;
    },
  },
  watch: {
    errorMsg() {
      if (this.errorMsg === apiErrorCode.INVALID_DOB || this.errorMsg === apiErrorCode.INVALID_UNDERAGE) {
        const { age } = tncInfo(this.site);
        this.myErrorMsg = this.$t(this.errorMsg, { age });
      }
    },
  },
  methods: {
    async updateAge() {
      try {
        const inputFields = this.$el.querySelectorAll('.form-holder .input-field');
        const m = this.age?.month?.value;
        this.observerValue = `${this.age.year}${m}${this.age.day}`;

        const pass = Boolean(this.age.day?.length && m?.length && this.age.year?.length);
        const allNull = !this.age.day && !m && !this.age.year;

        if (pass && !this.$el.querySelector('.error-msg.error')) {
        } else {
          if (allNull) inputFields.forEach(field => field.classList.remove('error'));
        }

        await sleep(60);
        this.myErrorMsg = this.birthError || this.ageError;
        if (this.modelInfo.DateOfBirth?.includes('Invalid')) this.myErrorMsg = this.$tv({ _rule_: 'dateOfBirth' });
        const month = `${m}`.padStart(2, '0');
        const day = `${this.age.day}`.padStart(2, '0');
        this.modelInfo.DateOfBirth = !this.myErrorMsg && this.hasValue ? getYYYYMMDDTHHmmssZ(`${this.age.year}-${month}-${day}`).toString() : null;

        this.$el.querySelectorAll('.form-holder .holder').forEach(o => {
          this.myErrorMsg ? o.classList.add('error') : o.classList.remove('error');
        });
      } catch (e) { console.log('updateAge', e);/* empty */ }
    },
    initAge() {
      const { DateOfBirth: date } = this.modelInfo;
      const year = customDateFormat(date, 'YYYY');
      const month = customDateFormat(date, 'MM');
      const day = customDateFormat(date, 'DD');

      this.age = { year, month, day };
    },
  },
  async mounted() {
    this.rules = makeRules();
    if (!this.modelInfo.DateOfBirth) {
      this.age = {year: null, month: null, day: null};
      return;
    }

    await sleep(60);
    this.initAge();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[date-of-birth] {
  > div .form-holder { .block();
    > [validation-component] {
      > input { .wh(0); .abs();}
      [validation-errors] { .hide(); }
    }
    > [validation-component] + [validation-component] { .mt(4);}
  }
  @media (@ml-up) {
    > div .form-holder { .flex(); .space-between();
      > [validation-component] {
        &:not(:nth-of-type(1)) { .ml(10); }
        &:nth-of-type(1) { .w(60%); }
      }
      > [validation-component] + [validation-component] { .mt(0); }
    }
  }

}
</style>
