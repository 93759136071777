<template>
  <div password-input :class="[theme, {'clearable': clearable}]">
    <TextInput :placeholder="placeholder" :theme="theme" :digit="numberOnly" :disabled="disabled"
               v-model="model" @input="change" @blur="blur" @focus="$emit('focus')" :readonly="readonly" :max-length="maxLength" :type="showPassword ? 'text' : 'password'" :class="{'password': !showPassword}" :clearable="clearable" @enter="$emit('enter')"
    />
    <button :class="['btn-eye', {'my-info' : myInfo}]" @click.prevent="click" tabindex="-1">
      <FontIcon :default-path="cdnSvgPath" :name="iconName" />
    </button>
    <FontIcon class="icn-lock" :default-path="cdnSvgPath" name="lock" v-if="theme.indexOf('dark') >= 0 && model && model.length" />
  </div>
</template>

<script>

import TextInput from '@shared/components/common/input/TextInput.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'PasswordInput',
  components: { FontIcon, TextInput },
  props: {
    value: null,
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Password' },
    maxLength: { type: Number, default: 20 },
    theme: { type: String, default: 'white' },
    clearable: Boolean,
    numberOnly: Boolean,
    readonly: Boolean,
    confirmRule: { type: Function, default: null },
    myInfo: { type: Boolean, default: false, }
  },
  computed: {
    iconName() {
      if (this.myInfo) return this.showPassword ? 'eye-light' : 'eye-off-regular';
      return this.showPassword ? 'eye' : 'eye-slash';
    }
  },
  watch: {
    value: 'updateModel',
  },
  data() {
    return {
      model: '',
      showPassword: false,
    };
  },
  methods: {
    updateModel() {
      this.model = this.value;
    },
    change() {
      this.$emit('input', this.model);
    },
    blur() {
      this.$emit('input', this.model);
      this.$emit('blur');
    },
    click() {
      this.showPassword = !this.showPassword;
    }
  },
  mounted() {
    this.updateModel();
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
.error {
  [password-input] {
    &.dark {
      .icn-lock { .hide(); }
    }
  }
}
[password-input] { .rel; .-box();
  [text-input] {.block; .w(100%); .rel;
    .password { -webkit-text-security: disc;}
  }
  .btn-eye { .abs; .rb(20, 0); .wh(16); .t-yc;
    &.my-info {.fs(16); .t(50%);}
    [font-icon] { .c(@c-b05); }
  }
  &.clearable {
    .btn-eye { .r(44); }
    [text-input] {
      input { .pr(70); }
    }
  }
  &.dark {
    .btn-eye {
      [font-icon] { .c(white); }
    }
  }
  .icn-lock { .abs(); .wh(16); .rb(50, 10);
    path { .fill(@c-success);}
  }
}

</style>
