<template>
  <SumsubContainer sumsub-service-abort>
    <FontIcon name="sharp-triangle-warning" />
    <p v-html="$t('_.description1')" />
    <p v-html="$t('_.description2', {csEmail})" />
    <ProgressButton button-name="close" @click="$emit('confirm', 'close')" />
  </SumsubContainer>
</template>

<script>
import SumsubContainer from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubContainer.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'SumsubServiceAbort',
  lexicon: 'onBoarding.sumsub.serviceAbort',
  components: { FontIcon, ProgressButton, SumsubContainer },
  props: {
    item: {type: Specific, default: null},
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  mounted() {
    if (this.item) this.item.title = 'onBoarding.title.accountVerification';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sumsub-service-abort] { .tc();
  > [font-icon] { .block(); .mb(24); .fs(80); }
  > p { .tl(); .fs(18, 22, normal); .c(#7F7F7F);
    > a[href^='mailto'] { .c(#2680EB); .underline; }
  }
  > p + p { .mt(22); }
}
</style>
