<template>
  <div input-verification-code>
    <div class="input-group">
      <label class="label">{{ $t('myInfo.verificationCode') }}<span>*</span></label>
      <VerifyPinCode :class="['np-verify-code', {'six' : length > 4}]" v-model="model.VerificationCode" :structure="structure" :length="length" :type="inputType" @input="updateCode" />
      <ValidationErrors class="error-msg" :errorMsg="error.VerificationCode" />
    </div>

  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import CountryNumberDropDown from '@/views/components/gg-pass/CountryNumberDropDown.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import TextButton from '@/views/components/gg-pass/TextButton.vue';
import VerifyPinCode from '@/views/components/common/VerifyPinCode.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import ValidationErrors from '@shared/plugins/validate/components/ValidationErrors.vue';
import OpenEmailAppButton from '@/views/components/gg-pass/OpenEmailAppButton.vue';
export default {
  name: 'InputVerificationCode',
  lexicon: 'myInfo',
  components: { GpDescription, VerifyPinCode, TextButton, InputComponent, ValidationErrors },
  props: {
    model: Specific,
    error: Specific,
    disabled: { type: Boolean, default: false, },
    length: { type: Number, default: 4 },
    errMsg: { type: String, default: null },
  },
  data() {
    return {
      structure: {},
      TextInputBox,
      CountryNumberDropDown
    };
  },
  computed:{
    inputType(){
      return this.length > 4 ? 'nonSpecial' : 'number';
    }
  },
  methods: {
    updateCode(v) {
      this.model.VerificationCode = v;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-verification-code] { .flex(); .flex-dc(); .w(100%); .gap(10); flex: 1; height: 100%;

  .input-group { .bgc(#EBEBEF);.p(20, 15, 20, 15); .br(16); .items-center(); flex: 1;
    > label {.w(100%); .c(@gp-component-label); .fs(13); .pl(5);
      > span {.c(green); .ml(3);}
    }
  }
  .np-verify-code { .bgc(#EBEBEF); .pt(0); .mt(0); .mb(0); justify-content: center;
    &.six {
      > span {.w(calc((100vw - 140px) / 6)); .h(calc((100vw - 140px) / 6));}
    }
    > span {.bgc(#FFF); .-a(#DDD); box-shadow: none; .c(black); border: none; .max-w(74); .max-h(74); .w(calc((100vw - 140px) / 4)); .h(calc((100vw - 140px) / 4));
      &.focus {.-a(#DDD);}
      > input {.c(black);}
    }
  }
}
</style>