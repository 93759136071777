<template>
  <GpFormTemplate change-password>
    <template #header>
      <GpDescription :message="$t('myInfo.changeLoginPassword.description', {brand })" />
    </template>
    <template #content>
      <!--      <GpTitle :name="$t('myInfo.title.changePassword')" />-->
      <InputChangePassword :model="model" :error="error" :pwConfig="pwConfig" :isPasswordSet="isPasswordSet" />
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click="submit(validate)" :disabled="!!invalid || isApiError || !load">{{ $t('myInfo.change') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import { npPasswordConfigs } from '@/utils/pwUtil';
import InputChangePassword from '@/views/components/pages/my-page/template/inputs/InputPassword.vue';
import npErrorHandler from '@/mixins/my-info/npErrorHandler';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
import { siteIds } from '@/constants/base/siteMap.mjs';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';

export default {
  name: 'NpChangeLoginPassword',
  components: { GpDescription, InputChangePassword, GpFormTemplate, GpButton },
  mixins: [npErrorHandler],
  props: {
    structure: { type: Specific, default: null },
    model: Specific,
  },
  data() {
    return {
      errorMsg: null,
      pwConfig: null,
      isPasswordSet: true,
      load: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    onePassInfo: state('user', 'onePassInfo'),
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    }
  },
  methods: {
    async initialize() {
      if (this.isGgpass) {
        this.isPasswordSet = this.onePassInfo.isPasswordSet;
      } else {
        this.isPasswordSet = true;
      }

      this.structure.title = this.isPasswordSet ? 'myInfo.title.changePassword' : 'myInfo.title.registerPassword'

      let pwConfig = null;
      if (this.isGgpass) {
        pwConfig = await this.$services.user.getAuthHighestConfig();
      } else {
        pwConfig = npPasswordConfigs(this.site);
      }

      this.pwConfig = pwConfig;
      this.load = true;
    },
    async submit(validate) {
      if (validate) await validate();
      const payload = { ...this.model };
      if (!this.isPasswordSet) {
        payload.OldPassword = null;
      }
      const result = await this.$myinfo.request(payload, null, true);

      if (result.error) {
        this.apiErrorHandler(result);
        return;
      }

      this.$emit('success', 'myInfo.changePasswordSuccess');
    },
  },

  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[change-password] { .pt(0);
  .password-input-group { .bgc(#f8f8f8);.p(20, 15, 20, 15); .br(4); .flex(); .flex-dc(); .gap(15); .w(100%);}
}

</style>

