<template>
  <div verify-crucks />
</template>

<script>
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import baseTemplate from '@/mixins/baseTemplate';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'VerifyCrucks',
  mixins: [baseTemplate],
  data() {
    return {
      error: false,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  methods: {
    /**
     * 컴포넌트 내에서의 api request 처리
     * @returns {Promise<*|null>}
     */
    async apiRequest() {
      const r = await this.getApiResponse();
      if (r.error) {
        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.verifyCrucks`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }

        await this.apiErrorHandler(r);
      }
    },
    /**
     *
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.kyc.verifyCruks(); }
      catch (e) { return e; }
    },
    async apiErrorHandler(r) {
      const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
      this.replaceRouteName('VerificationFailed', {desc, errorTemplate}, { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined });
    },
  },
  async mounted() {
    await this.apiRequest();
    if (!this.structure.error) this.modelInfo.step = 1;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-crucks] {}
</style>
