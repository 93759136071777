<template>
  <div display-transfer-history>
    <div v-if="noAccounts" class="no-accounts">
      <FontIcon name="exclamation-circle-regular-sharp" />
      <p>{{ $t('_.noData') }}</p>
    </div>
    <div v-if="!noAccounts" class="history-table">
      <TransferHistoryItem :item="item" v-for="(item,id) in model" :key="id" />
      <div class="ob" v-infinite-scroll="loadData"></div>
    </div>
  </div>
</template>

<script>

import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import TransferHistoryItem from '@/views/components/pages/my-page/template/transfer/TransferHistoryItem.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import InfiniteScrollDirective from '@/directives/InfiniteScrollDirective';


export default {
  name: 'DisplayTransferHistory',
  lexicon: 'myInfo.transferHistory',
  components: { FontIcon, GpDescription, TransferHistoryItem },
  directives: {
    'infinite-scroll': InfiniteScrollDirective,
  },
  props: {
    model: Specific,
  },
  computed: {
    site: state('env', 'site'),
    noAccounts() {
      if (!this.model) return true;
      return this.model.length <= 0;
    }
  },
  methods: {
    loadData() {
      this.$emit('loadData');
    }
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[display-transfer-history] {.flex(); .flex-dc(); .gap(10); flex: 1;
  .no-accounts {.min-h(150); .flex(); .justify-center(); .items-center(); .bgc(#fff); .br(16); .c(black); .flex-dc(); .gap(10); .h(100%);
    [font-icon] {.fs(20); .c(#737373);}
  }
  .history-table {.flex(); .flex-dc(); .gap(10); overflow-y: auto; .max-h(500); }
  .ob {.p(5);}
}
</style>
