import { startTransition } from '@shared/modules/Transition';

/**
 * @extends {ScrollHandler}
 */
class ScrollAgency {
  #store;
  #scrollAnimation;
  #frozen = false;
  #frozenScroll = 0;
  #listeners = [];
  #lockFreeze = false;

  constructor(store) {
    if (typeof window === 'undefined') return;
    this.#store = store;
    window.addEventListener('scroll', this.#update);
    window.addEventListener('resize', this.#update);
  }


  #update = () => {
    const v = this.getScrollTop();
    this.#listeners.forEach(h => h(v));
  };

  /**
   * @returns {number}
   */
  getScrollTop() {
    return window.scrollY;
  }

  /**
   * @returns {number}
   */
  getScrollHeight() {
    return document.documentElement.scrollHeight;
  }

  getScrollPer() {
    return 100 * (this.getScrollTop() / (this.getScrollHeight() - window.innerHeight));
  }

  /**
   * @param {number} v
   */
  scrollTo(v) {
    const to = v || 0;
    this.#frozenScroll = to;
    window.scroll(0, to);
  }

  /**
   * @param {number} to
   * @param {number} dura
   */
  scrollMove(to, dura) {
    if (this.#scrollAnimation) return;
    const from = this.getScrollTop();
    this.#scrollAnimation = startTransition(from, to, dura, (v, end) => {
      this.scrollTo(v);
      if (end) this.#scrollAnimation = null;
    });
  }

  /**
   * @param {function(scrollTop: number)} listener
   */
  addListener = listener => this.#listeners.push(listener);
  /**
   * @param {function(scrollTop: number)} listener
   */
  removeListener = listener => {
    const idx = this.#listeners.indexOf(listener);
    if (idx > -1) this.#listeners.splice(idx, 1);
  };

  /**
   * @param {boolean} lockFreeze
   */
  freeze(lockFreeze) {
    if (lockFreeze) this.#lockFreeze = lockFreeze;
    if (this.#frozen) return;
    if (document.documentElement.scrollHeight <= window.innerHeight) return;
    document.documentElement.style.overflowY = 'scroll';
    this.#frozenScroll = this.getScrollTop();
    this.#frozen = true;
    const bodyStyle = document.body.style;
    bodyStyle.position = 'absolute';
    bodyStyle.width = '100%';
    bodyStyle.height = '100%';
    bodyStyle.overflow = 'hidden';
    const appStyle = document.querySelector('#app')['style'];
    appStyle.position = 'absolute';
    appStyle.width = '100%';
    appStyle.top = `-${this.#frozenScroll}px`;
    this.#store.commit('browser/freezeScroll');
  }

  /**
   * @param {boolean} forceRelease
   */
  release(forceRelease) {
    if (forceRelease) this.#lockFreeze = false;
    if (this.#lockFreeze) return;
    if (!this.#frozen) return;
    document.documentElement.style.overflowY = 'auto';
    this.#frozen = false;
    document.body.setAttribute('style', null);
    document.querySelector('#app').setAttribute('style', null);
    this.scrollTo(this.#frozenScroll);
    this.#store.commit('browser/releaseScroll');
  }

  frozenToZero() {
    this.#frozenScroll = 0;
  }

}

export default {
  install(Vue, { store }) {
    Vue.prototype.$scroll = new ScrollAgency(store);
  },
};
