import siteModel from '@/constants/model/find-id';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { setGTM } from '@/plugins/tracking';
import ResponsibleSiteMap from '@/constants/model/responsible-gaming';
import { STEP } from '@/constants/base/my-page';

class FindIdService {
  #services;
  #router;
  #store;
  #siteMap;
  #siteInfo;
  #site;
  #step;

  setScope(scope) {
    this.#services = scope.$services;
    this.#router = scope.$router;
    this.#store = scope.$store;
    this.#siteInfo = this.#store.state.env.siteInfo;
    this.#site = this.#store.state.env.site;

    this.updateSiteMap();
    this.#setRecaptcha();

    setGTM(this.#site, this.#siteInfo, this.#router);
  }

  #setRecaptcha() {
    if(![siteIds.GGPUKE, siteIds.EVPUKE].includes(this.#site)) this.#services.captcha.reCaptchaInitialize();
  }

  get currentPageModel() {
    return this.#siteMap[this.#step];
  }
  get step() {
    return this.#step;
  }

  get next() {
    return this.currentPageModel.next;
  }

  get root() {
    return this.#siteMap.root;
  }

  get captchaAction() {
    return this.currentPageModel.captchaAction;
  }

  get api() {
    return this.currentPageModel.api;
  }

  get stepList() {
    return this.currentPageModel.step || [];
  }
  get currentStepIndex() {
    if (this.stepList.length <= 0) return 0;
    return this.stepList.findIndex(step => this.step === step);
  }

  get currentFetchKey() {
    return this.currentPageModel.fetchKeys || null;
  }
  get isFirstStep() {
    return this.currentStepIndex === 0;
  }
  get isLastStep() {
    return this.currentStepIndex === this.stepList.length - 1;
  }

  get currentComponent() {
    return this.currentPageModel.component || null;
  }

  updateSiteMap(findKey) {
    if(findKey) this.#store.commit('query/setFindKey', findKey);
    this.#siteMap = siteModel(Boolean(this.#store.state.query.findKey));
  }

  changeStep(step) {
    this.#step = step;
    this.updateSiteMap();
  }

  async confirm(model) {
    return this.#services.findId[this.api](model);
  }
}

export default {
  install(Vue, { services, store, router }) {
    Vue.prototype.$findId = new FindIdService(services, store, router);
  }
};