<template>
  <div gp-ul-description-list v-html="message" />
</template>

<script>
export default {
  name: 'GpUlDescriptionList',
  props: {
    message: { type: String, default: null, }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-ul-description-list] {.c(#737373); .fs(14); .ph(10); .pv(15);
  > ul { .rel(); .pl(15); line-height: 1.4; }
  > ul > li:before { content: "•"; .abs(); .l(0); }
}

</style>