<template>
  <div ph-edd-floating v-html="eddPopover">
  </div>
</template>

<script>
export default {
  name: 'PhEddFloating',
  data() {
    return {
      eddPopover: null,
      popoverList: [
        'kyc.documentType.RecentBankStatements',
        'kyc.documentType.RecentEwalletStatements',
        'kyc.documentType.InvestmentTradingScreenshot',
        'kyc.documentType.WinningsScreenshot',
        'kyc.documentType.OtherProveDocuments',
      ],
    };
  },
  async mounted() {
    this.eddPopover = this.popoverList.map(i => `• ${this.$t(i)}`).join('<br/>');
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[ph-edd-floating] { box-shadow: 0 6px 12px rgba(0,0,0,0.175); .p(12); .br(4); .bgc(#0e1415); .fs(13); .c(white);
  >span { .c(@c-white); .fs(14); }
  >a { .fs(14); .c(#4A8CFF); .-b(#4A8CFF); .medium(); }
}
</style>