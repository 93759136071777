<template>
  <div verify-pin-code v-if="model">
    <TextInput v-for="(item, id) in model" :ref="`input${id}`" :value="item" :key="id" :max-length="1" :pattern="pattern" :input-mode="inputMode" :none-special="noneSpecial" :digit="digit" theme="dark" @input="v => update(v,id)" @focus="focusField(id)" @blur="focusId = null" />
  </div>
</template>

<script>
import _ from 'lodash';
import TextInput from '@shared/components/common/input/TextInput.vue';
import Specific from '@shared/types/Specific';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'VerifyCode',
  components: { TextInput },
  props: {
    length: { type: Number, default: 4 },
    structure: { type: Specific, required: true },
    type: { type: String, default: 'number' },
  },
  data() {
    return {
      model: [],
      savedCode: '',
      focusId: null,
      passed: false,
      isBackKey: false,

    };
  },
  computed: {
    isText() {
      return this.type === 'text';
    },
    pattern() {
      return this.isText ? '^[0-9a-zA-Z]+$' : '[0-9]*';
    },
    inputMode() {
      return this.type === 'number' ? 'numeric' : 'text';
    },
    digit() {
      return this.type === 'number';
    },
    noneSpecial() {
      return this.type === 'nonSpecial';
    }
  },
  methods: {
    update(v, id) {
      // nonSpecial 타입일때 알파벳 입력시 대문자 치환
      if(v && this.noneSpecial && isNaN(v)) v = v.toUpperCase();
      if (!this.isBackKey && !v?.length) return;
      const ll = this.model.length;
      let nextId = id !== 0 && !v?.length ? id - 1 : id + 1 !== this.model?.length && v?.length ? id + 1 : -1;
      if(nextId === ll - 1 && this.model[ll - 1]?.length && _.every(this.model , o => o?.length)) nextId = ll - 1;
      this.model[id] = v?.length ? v.slice(-1) : v;
      this.model = [...this.model];
      this.verifyPass();

      requestAnimationFrame(() => {
        if (nextId > -1) this.$refs[`input${nextId}`][0].$el.querySelector('input').focus();
      });
    },
    verifyPass() {
      const pass = _.every(this.model, o => o?.length);
      const code = this.model.join('');

      if (pass && code !== this.savedCode) this.structure.error = false;
      this.savedCode = code;
      this.$emit('input', pass ? this.savedCode : '');
    },
    focusField(id) {
      this.focusId = id;
    },
    pasteText(e) {
      if (this.focusId === null) return;
      e.preventDefault();
      const t = e.clipboardData.getData('text');
      t.split('').forEach((o, id) => {
        if (this.noneSpecial) {
          if (!this.checkSpecial(o) || id > this.model.length - 1)
            return;
        } else {
          if (!this.checkDigit(o) || id > this.model.length - 1)
            return;
        }
        this.model[id] = o;
      });
      this.model = [...this.model];
      this.verifyPass();
    },
    checkDigit(v) {
      return !/[^\d]/gi.test(v);
    },
    checkSpecial(v) {
      return !(/[^a-zA-Z\d]/g).test(v);
    },
    focusMode(e) {
      if (this.focusId === null) return;
      let id = -1;
      this.isBackKey = false;
      switch (e.key?.toLowerCase()) {
        case 'delete':
        case 'backspace':
          if (this.focusId > 0 && !this.model[this.focusId]) {
            this.focusId--;
            this.setFocus(this.focusId);
            this.model[this.focusId] = '';
            this.model = [...this.model];
          } else this.isBackKey = true;
          break;
        case 'arrowleft':
          if (!this.focusId) return;
          id = this.focusId - 1;
          break;
        case 'arrowright':
          if (this.focusId >= this.model.length - 1) return;
          id = this.focusId + 1;
          break;
        default:
      }

      if (id < 0) return;
      this.setFocus(id);
    },
    setFocus(id) {
      const target = this.$el.querySelectorAll('input')[id];
      target.focus();
      target.select();
    }
  },
  mounted() {
    this.model = new Array(this.length).fill('');
    window.addEventListener('paste', this.pasteText);
    window.addEventListener('keydown', this.focusMode);
    this.$nextTick(() => {
      this.$refs[`input${0}`][0].$el.querySelector('input').focus();
    });
  },
  beforeDestroy() {
    window.removeEventListener('paste', this.pasteText);
    window.removeEventListener('keydown', this.focusMode);
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[verify-pin-code] { .flex-center(); .mt(40); .w(100%);
  [text-input] { .wh(64); .mh(6); .fs(24); .bgc(@c-w004);
    input { .p(0) !important; .tc(); }
    &.focus { .-a(@c-yellow, 2); }
  }
}
</style>