<template>
  <div date-picker>
    <button class="remove-btn" @click="toggle">
      <TextInput readonly :placeholder="placeholder" :value="displayValue" @input="remove" />
      <!--      <FontIcon class="icon" name="calendar-days" />-->
    </button>
    <DateSelector v-if="isOn" :class="{ 'active':isShow }" ref="selector" v-model="model" :min="minDate" :max="maxDate" @select="selectedDate" :style="styleValue" />
  </div>
</template>

<script>
import RangeSelector from '@shared/components/common/date/RangeSelector.vue';
import DateSelector from '@shared/components/common/date/DateSelector.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import Specific from '@shared/types/Specific';
import { sleep } from '@shared/utils/commonUtils';
import FontIcon from '@shared/components/common/FontIcon.vue';
import moment from 'moment/moment';

export default {
  name: 'DatePicker',
  components: { TextInput, RangeSelector, DateSelector, FontIcon },
  props: {
    value: Specific,
    placeholder: String,
    clearable: { type: Boolean, default: true },
    min: { type: Specific, default: null },
    max: { type: Specific, default: null },
  },
  computed: {
    minDate() {return this.min;},
    maxDate() {return this.max;},
    displayValue() {
      if (!this.value) return null;
      const locale = this.$i18n('locale');
      return moment(this.value).locale(locale).format('MMM, DD YYYY');
    }
  },
  data() {
    return {
      model: null,
      isOn: false,
      isShow: false,
      styleValue: null,
    };
  },
  watch: {
    value: 'update',
  },
  methods: {
    selectedDate(info) {
      this.model = info;
      this.$emit('input', this.model);
      this.off();
    },
    remove() {
      this.selectedDate(null);
      this.off();
    },
    async toggle() {
      this.isOn = !this.isOn;
      if (this.isOn) {
        await sleep(60);
        const { selector } = this.$refs;
        const parentRect = this.$el.getBoundingClientRect();
        const rect = selector.$el.getBoundingClientRect();
        // const w = parentRect.width > 300 ? parentRect.width : 300;
        let w = parentRect.width;
        if (parentRect.width > 250) w = 250;
        else if (parentRect.width < 200) w = 250;

        let x = parentRect.left;
        // let y = parentRect.top + 36;

        x = parentRect.x + w > window.innerWidth ? ((parentRect.x + w) - window.innerWidth + 20) * -1 : 0;

        this.styleValue = { left: `${x}px`, top: `${50}px`, width: `${w}px` };
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    off() {
      this.isOn = false;
      this.isShow = false;
    },
    update() {
      this.model = this.value;
      this.$emit('input', this.model);
    },
    checkOutside(e) {
      const { target } = e;
      if (!(this.$el === target || this.$el.contains(target))) {
        this.off();
      }
    },
    eventUpdate(destroy) {
      const touch = 'ontouchstart' in window;
      const down = touch ? 'touchstart' : 'mousedown';
      if (destroy) {
        window.removeEventListener('scroll', this.off);
        window.removeEventListener('resize', this.off);
        window.removeEventListener(down, this.checkOutside);
      } else {
        this.update();
        window.addEventListener('scroll', this.off);
        window.addEventListener('resize', this.off);
        window.addEventListener(down, this.checkOutside);
      }
    }
  },
  mounted() {
    this.eventUpdate();
  },
  beforeDestroy() {
    this.eventUpdate(true);
  },
};
</script>

<style lang="less">
@import "~@shared/less/proj.less";
[date-picker] { .rel(); .bgc(#fff); .h(40); .c(black); .br(8); cursor: pointer;
  .remove-btn { .wf(); .rel(); cursor: pointer;
    [text-input] { .-a(); .h(40);
      > input {cursor: pointer;}
    }
    .icon {.abs(); .t(50%); .r(15); transform: translateY(-50%);}
  }
  [range-selector] { .hidden(); .hidden(); .fix(); .z(100); .tr-o(.2s); .o(0);
    &.active { .o(1); .visible(); }
  }
  [date-selector] { .hidden(); .abs();.z(5); .bgc(#fff); box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1); border-radius: 8px; .o(0);
    &.active { .o(1); .visible(); }
  }
}
</style>