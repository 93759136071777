<template>
  <div radio-button-list :class="theme">
    <RadioItem v-for="(item,id) in list" :key="id" :value="item.value" v-model="model" :type="theme" @change="selectedItem">{{ label(item) }}</RadioItem>
  </div>
</template>

<script>
import RadioItem from '@shared/components/common/input/RadioItem.vue';

export default {
  name: 'RadioButtonList',
  components: { RadioItem },
  props: {
    list: { type: /** @type { BaseInfo[] } */ Array, required: true, },
    theme: { type: String, default: 'box' },
    translate: { type: Boolean, default: true },
    value: { type: [Boolean,String,Number], default: null },
  },
  watch: {
    value: 'update',
  },
  data() {
    return {
      model: null,
    };
  },
  methods: {
    label(item) {
      return this.translate ? this.$t(item.label) : item.label;
    },
    update() {
      if (!Boolean(this.value)) this.model = this.list[0].value;
      else this.model = this.value;
      this.$emit('input', this.model);
    },
    selectedItem() {
      this.$emit('input', this.model);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.update();
    });

  }
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

[radio-button-list] { .flex-list();
  [radio-item] {.tc();
    &:nth-of-type(1).box {
      i { .br-l(8); }
    }
    &:nth-last-of-type(1).box {
      i { .br-r(8); }
    }
    &.box {
      i { .br(0); }
    }
  }
  &.dark { .block(); .pl(12);
    [radio-item] { .ib();
      &:not(:nth-of-type(1)) { .ml(20); }
      i { .wh(16); .br(50%); .-a(white, 1);
        &:after { .wh(8); .br(50%); .lt(50%, 50%); .t-xyc();}
        &.checked { .-a(white, 2);
        }
      }
      span { .medium(); }
    }
  }
}
</style>