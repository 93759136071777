<template>
  <div v-if="selfVisible" np-display-kyc-documents>
    <h3 class="kyc-title">{{ $t('_.documentTitle') }}</h3>
    <ul class="desc">
      <!--      <li v-if="poiDescription">{{ poiDescription }}</li>-->
      <li v-if="poiDefaultKycDocumentList" v-for="(item, index) in poiDefaultKycDocumentList" :key="item.value">
        <p>{{ $t(item.label) }}</p>
        <p v-if="item.description">{{ $t(item.description) }}</p>
      </li>
      <li v-if="poiMandatoryKycDocumentList" v-for="(item, index) in poiMandatoryKycDocumentList" :key="item.value">
        <p>{{ $t(item.label) }}</p>
        <p v-if="item.description">{{ $t(item.description) }}</p>
      </li>
      <li v-if="poaDefaultKycDocumentList" v-for="(item, index) in poaDefaultKycDocumentList" :key="item.value">
        <p>{{ $t(item.label) }}</p>
        <p v-if="item.description">{{ $t(item.description) }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import { getKycDocuments, kycStatus } from '@/constants/base/onboarding/kyc';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'DisplayKycDocument',
  lexicon: 'myInfo',
  props: {
    model: Specific
  },
  data() {
    return {
      poiDefaultKycDocumentList: null,
      poiMandatoryKycDocumentList: null,
      poaDefaultKycDocumentList: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    selfVisible() {
      return ![this.poiDefaultKycDocumentList, this.poiMandatoryKycDocumentList, this.poaDefaultKycDocumentList].every(arr => arr.length <= 0);
    },
  },
  methods: {
    convertKeyValue(kycDocumentGroup, type) {
      if (!kycDocumentGroup) return [];
      const documents = type === 'Default' ? kycDocumentGroup.DefaultKycDocuments : kycDocumentGroup.MandatoryKycDocuments;
      if (!documents) return [];
      const originArray = documents.map(document => {
        const kycDocument = getKycDocuments(this.site).find(type => type.value === document);
        return {
          label: kycDocument?.label,
          value: kycDocument?.value,
          description: kycDocument?.description
        };
      });
      return [...originArray.filter(x => !x.description), ...originArray.filter(x => x.description)];
    },
    findKycDocumentGroup(kycDocumentGroups, type) {
      return kycDocumentGroups?.find(group => group.KycDocumentGroupType === type);
    },
    updateProofDocuments() {
      const poiKycDocumentGroup = this.findKycDocumentGroup(this.model, kycStatus.Poi);
      const poaKycDocumentGroup = this.findKycDocumentGroup(this.model, kycStatus.Poa);

      this.poiDefaultKycDocumentList = this.convertKeyValue(poiKycDocumentGroup, 'Default');
      this.poiMandatoryKycDocumentList = this.convertKeyValue(poiKycDocumentGroup, 'Mandatory');
      this.poaDefaultKycDocumentList = this.convertKeyValue(poaKycDocumentGroup, 'Default');
    },
  },
  beforeMount() {
    this.updateProofDocuments();

    console.log('poiMandatoryKycDocumentList', this.poiMandatoryKycDocumentList);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-display-kyc-documents] { .bgc(#f8f8f8);.p(0, 0, 20, 0); .br(4); .flex(); .flex-dc(); .w(100%);
  .kyc-title {.c(#737373); .fs(14); font-weight: 400; .pb(0);}
  .desc { .rel(); .pl(15); line-height: 1.4; .ml(15);
    > li:before { content: "•"; .abs(); .l(0); }
    > li {.c(#737373); .fs(14); font-weight: 400; .flex(); .flex-column(); .justify-start(); .items-start(); }

  }
}
</style>