/**
 * @param {HTMLElement} el
 * @param {string} className
 */
export const toggleClass = (el, className) => {
  if (el.classList.contains(className)) {
    el.classList.remove(className);
  } else {
    el.classList.add(className);
  }
};

/**
 * @param {HTMLElement} el
 * @param {string} selector
 * @returns {HTMLElement|null}
 */
export const closest = (el, selector) => {
  if (el.closest) return el.closest(selector);
  let t = el;
  while (t) {
    if (t.matches(selector)) return t;
    if (t === document.body) return null;
    t = t.parentNode;
  }
  return null;
};

/**
 * @param {HTMLElement} el
 * @returns {number}
 */
export const elementIndex = el => {
  if (!el.parentNode) return -1;
  return Array.prototype.indexOf.call(el.parentNode.childNodes, el);
};

/**
 * @description 스크롤이 얼마나 되었는지 비율로 나타냄
 * @param {Event} event
 * @returns {number}
 */
export const getScrolledRatio = event => {
  const { scrollHeight, scrollTop, clientHeight } = event.target;
  return scrollTop / (scrollHeight - clientHeight);
};
