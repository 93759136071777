const emailList = [
    { label: '126.com', value: 'https://email.163.com' },
    { label: '139.com', value: 'https://mail.10086.cn' },
    { label: '163.com', value: 'https://email.163.com' },
    { label: 'aol.com', value: 'https://mail.aol.com' },
    { label: 'bk.ru', value: 'https://e.mail.ru' },
    { label: 'daum.net', value: 'https://mail.daum.net' },
    { label: 'gmail.com', value: 'https://mail.google.com' },
    { label: 'gmx.com', value: 'https://www.gmx.com' },
    { label: 'gmx.at', value: 'https://www.gmx.at' },
    { label: 'gmx.ch', value: 'https://www.gmx.ch' },
    { label: 'gmx.de', value: 'https://www.gmx.net' },
    { label: 'gmx.net', value: 'https://www.gmx.net' },
    { label: 'googlemail.com', value: 'https://mail.google.com' },
    { label: 'hotmail.com', value: 'https://outlook.live.com' },
    { label: 'hanmail.net', value: 'https://mail.daum.net' },
    { label: 'hotmail.be', value: 'https://outlook.live.com' },
    { label: 'hotmail.ca', value: 'https://outlook.live.com' },
    { label: 'hotmail.ch', value: 'https://outlook.live.com' },
    { label: 'hotmail.co.nz', value: 'https://outlook.live.com' },
    { label: 'hotmail.co.th', value: 'https://outlook.live.com' },
    { label: 'hotmail.co.uk', value: 'https://outlook.live.com' },
    { label: 'hotmail.com.ar', value: 'https://outlook.live.com' },
    { label: 'hotmail.com.tw', value: 'https://outlook.live.com' },
    { label: 'hotmail.de', value: 'https://outlook.live.com' },
    { label: 'hotmail.es', value: 'https://outlook.live.com' },
    { label: 'hotmail.fi', value: 'https://outlook.live.com' },
    { label: 'hotmail.fr', value: 'https://outlook.live.com' },
    { label: 'hotmail.gr', value: 'https://outlook.live.com' },
    { label: 'hotmail.it', value: 'https://outlook.live.com' },
    { label: 'hotmail.nl', value: 'https://outlook.live.com' },
    { label: 'hotmail.no', value: 'https://outlook.live.com' },
    { label: 'hotmail.se', value: 'https://outlook.live.com' },
    { label: 'hotmail.sg', value: 'https://outlook.live.com' },
    { label: 'icloud.com', value: 'https://www.icloud.com/mail' },
    { label: 'inbox.ru', value: 'https://e.mail.ru' },
    { label: 'list.ru', value: 'https://mail.ru' },
    { label: 'live.com', value: 'https://outlook.live.com' },
    { label: 'live.at', value: 'https://outlook.live.com' },
    { label: 'live.be', value: 'https://outlook.live.com' },
    { label: 'live.ca', value: 'https://outlook.live.com' },
    { label: 'live.cl', value: 'https://outlook.live.com' },
    { label: 'live.co.uk', value: 'https://outlook.live.com' },
    { label: 'live.co.za', value: 'https://outlook.live.com' },
    { label: 'live.com.ar', value: 'https://outlook.live.com' },
    { label: 'live.com.au', value: 'https://outlook.live.com' },
    { label: 'live.com.mx', value: 'https://outlook.live.com' },
    { label: 'live.de', value: 'https://outlook.live.com' },
    { label: 'live.fi', value: 'https://outlook.live.com' },
    { label: 'live.fr', value: 'https://outlook.live.com' },
    { label: 'live.hk', value: 'https://outlook.live.com' },
    { label: 'live.ie', value: 'https://outlook.live.com' },
    { label: 'live.it', value: 'https://outlook.live.com' },
    { label: 'live.nl', value: 'https://outlook.live.com' },
    { label: 'live.no', value: 'https://outlook.live.com' },
    { label: 'live.se', value: 'https://outlook.live.com' },
    { label: 'mail.ru', value: 'https://e.mail.ru' },
    { label: 'msn.com', value: 'https://outlook.live.com' },
    { label: 'mail.com', value: 'https://www.mail.com' },
    { label: 'naver.com', value: 'https://mail.naver.com' },
    { label: 'nate.com', value: 'https://home.mail.nate.com' },
    { label: 'outlook.com', value: 'https://outlook.live.com' },
    { label: 'o2.pl', value: 'https://poczta.o2.pl/' },
    { label: 'outlook.be', value: 'https://outlook.live.com' },
    { label: 'outlook.co.id', value: 'https://outlook.live.com' },
    { label: 'outlook.co.il', value: 'https://outlook.live.com' },
    { label: 'outlook.co.th', value: 'https://outlook.live.com' },
    { label: 'outlook.com.br', value: 'https://outlook.live.com' },
    { label: 'outlook.com.gr', value: 'https://outlook.live.com' },
    { label: 'outlook.com.vn', value: 'https://outlook.live.com' },
    { label: 'outlook.de', value: 'https://outlook.live.com' },
    { label: 'outlook.es', value: 'https://outlook.live.com' },
    { label: 'outlook.fr', value: 'https://outlook.live.com' },
    { label: 'outlook.hu', value: 'https://outlook.live.com' },
    { label: 'outlook.ie', value: 'https://outlook.live.com' },
    { label: 'outlook.it', value: 'https://outlook.live.com' },
    { label: 'outlook.jp', value: 'https://outlook.live.com' },
    { label: 'outlook.kr', value: 'https://outlook.live.com' },
    { label: 'outlook.sg', value: 'https://outlook.live.com' },
    { label: 'protonmail.com', value: 'https://mail.proton.me' },
    { label: 'qq.com', value: 'https://mail.qq.com' },
    { label: 'rambler.ru', value: 'https://mail.rambler.ru' },
    { label: 'sina.com', value: 'https://mail.sina.com.cn' },
    { label: 't-online.de', value: 'https://www.t-online.de' },
    { label: 'ukr.net', value: 'https://www.ukr.net' },
    { label: 'web.de', value: 'https://web.de' },
    { label: 'wp.pl', value: 'https://poczta.wp.pl' },
    { label: 'yahoo.com', value: 'https://mail.yahoo.com' },
    { label: 'yandex.com', value: 'https://360.yandex.com' },
    { label: 'yeah.net', value: 'https://email.163.com/' },
    { label: 'ya.ru', value: 'https://360.yandex.ru/mail' },
    { label: 'yahoo.ca', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.co.in', value: 'https://in.mail.yahoo.com' },
    { label: 'yahoo.co.jp', value: 'https://mail.yahoo.co.jp' },
    { label: 'yahoo.co.nz', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.co.uk', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.com.ar', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.com.au', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.com.br', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.com.hk', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.com.mx', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.com.sg', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.com.tw', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.de', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.es', value: 'https://es.mail.yahoo.com' },
    { label: 'yahoo.fr', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.gr', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.ie', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.in', value: 'https://in.mail.yahoo.com' },
    { label: 'yahoo.it', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.no', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.ro', value: 'https://mail.yahoo.com' },
    { label: 'yahoo.se', value: 'https://mail.yahoo.com' },
    { label: 'yandex.by', value: 'https://mail.yandex.by' },
    { label: 'yandex.kz', value: 'https://mail.yandex.kz' },
    { label: 'yandex.ru', value: 'https://360.yandex.ru/mail' },
    { label: 'yandex.ua', value: 'https://360.yandex.com/mail' },
  ];

export default emailList;
