<template>
  <div verify-mobile-number>
    <div class="field-holder">
      <ValidationComponent :label="$t('mobileNumber')" name="_countryNumber" :component="CountryNumberDropSelect" rules="requiredSelect" v-model="countryCode" :labelColorGray="labelColorGray" @input="updateCountry" @focus="$emit('focus')" @blur="$emit('blur')"/>
      <ValidationComponent class="number-field" name="_mobileNumber" :component="TrimTextInput" :rules="`mobileNumber:${countryLength}`" v-model="modelInfo.MobileNumber" :error-msg="myErrorMsg" :preset="mobileNumberPreset"/>
    </div>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import CountryNumberDropSelect from '@/views/components/common/CountryNumberDropSelect.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'VerifyMobileNumber',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  props: {
    labelColorGray: { type: Boolean, default: true },
  },
  data() {
    return {
      myErrorMsg: null,
      countryCode: null,
      countryLength: 0,
      CountryNumberDropSelect,
      TrimTextInput,
    };
  },
  computed: {
    extra() {
      return this.item?.extra && this.item.extra[this.site] ? this.item.extra[this.site] : {};
    },
    extraComponent() {
      return this.extra.template;
    },
    isVerifyMobileNumber() {
      return this.$route.name === 'VerifyMobileNumber';
    },
    mobileNumberPreset() {
      return {phone: true, type: 'tel', maxLength: 12, placeholder: this.$t('mobileNumber')};
    },
    site: state('env', 'site'),
    userCountryCode: state('user', 'countryCode'),
  },
  watch: {
    'modelInfo.MobileNumber': function() {
      this.structure.errorMsg = null;
    },
    errorMsg() {
      if(this.errorMsg === apiErrorCode.INVALID_MOBILE_NUMBER) this.myErrorMsg = this.$tv({ _rule_: 'required', _field_: '_mobileNumber' });
      else if(this.errorMsg === apiErrorCode.PHONE_RESEND_LIMITED) this.myErrorMsg = this.$t('signUp.tnc.resendTimer', { seconds: this.structure.errorDesc });
      else if(this.errorMsg === apiErrorCode.EXISTING_MOBILE_NUMBER) this.myErrorMsg = this.$t(this.errorMsg);
      else if(this.errorMsg === apiErrorCode.EXIST_MOBILE) this.myErrorMsg = this.$t(this.errorMsg);
      else this.myErrorMsg = null;
    }
  },
  methods: {
    updateCountry(item) {
      if (!item) return;

      this.modelInfo.MobileCountryCode = item.CountryCode;
      this.modelInfo.CountryCode = item.CountryCode;
      this.modelInfo.PhoneCountryCode = item.PhoneCountryCode;
      this.countryLength = String(this.modelInfo.PhoneCountryCode)?.length;
    },
    async initialize() {
      const isNanCountryCode = this.userCountryCode || (isNaN(this.modelInfo.MobileCountryCode) ? this.modelInfo.MobileCountryCode : this.modelInfo.MobileCountry);
      /** API sendMobileVerification, editPersonalBirth 이냐에 따라 model 이 다름 */
      this.countryCode = (this.isVerifyMobileNumber ? this.modelInfo.CountryCode : isNanCountryCode) || (await this.$services.base.getUserCountry()).CountryCode;
    },
  },
  async mounted() {
    this.$nextTick(this.initialize);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-mobile-number] { .rel();
  .flex(); .flex-dc();
  .field-holder { .block();
    > div + div { .mt(4); }
    @media (@ml-up) {
      .flex(); .space-between();
      > div + div { .mt(0); }
      > * {
        &:nth-of-type(1) { .w(35%); }
        &:nth-of-type(2) { .w(calc(65% - 8px)); }
      }
    }
  }
}
</style>
