<template>
  <div verify-rg-re-login-time-setting>
    <ValidationProvider rules="required">
      <LimitMinuteField :label="$t('setLimit')" v-model="modelInfo.RequestReLoginTimeLimit" />
    </ValidationProvider>
    <p class="list-star">{{ $t('_.reLoginTime.description') }}</p>
  </div>
</template>

<script>
import LimitMinuteField from '@/views/components/pages/on-boarding/template/extra/LimitMinuteField.vue';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'VerifyRgReLoginTimeSetting',
  lexicon: 'onBoarding.setting',
  components: { LimitMinuteField },
  mixins: [baseTemplate],
  data() {
    return {
      activeId: 1,
    };
  },
  methods: {
    async initialize() {
      this.modelInfo.RequestReLoginTimeLimit = (await this.$services.account.getRelogintimelimit()).CurrentTimeLimit ?? '';
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-rg-re-login-time-setting] {
  [limit-minute-Field] { .mt(16);
    label { flex-basis: 30%; .min-w(98); white-space: normal; }
    label + div { flex-basis: 70%; }
  }
  p { .mt(16); }
}
</style>
