export default class NoticeService {
  #services;
  #proxyNpUserApi;

  constructor(services) {
    this.#services = services;
    this.#proxyNpUserApi = services.proxyNpUserApi;
  }
  /**
   * @id GetNotificationBoardRequest
   * @returns {Promise<NoticeType>}
   * */
  async getNotification() {
    try {
      return await this.#proxyNpUserApi.proxyGet('/notification/status', {}, { silent: true });
    } catch (e) {
      console.log('error : getNotification', e);
      throw e;
    }
  }
}