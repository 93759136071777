import Vue from 'vue';
import ToastErrorHandler from '@shared/modules/ToastErrorHandler';
import { getHasAsyncDataComponents, getHasAsyncDataComponentsSorted, getPathAppliedParam, routeString } from '@shared/utils/routerUtils.mjs';
import { getSessionStorage } from '@shared/modules/ObjectStorage';
import _sortBy from 'lodash/sortBy';
import _groupBy from 'lodash/groupBy';
import _assign from 'lodash/assign';
import cookies from 'js-cookie';
import _forEach from 'lodash/forEach';
import { parseQuery } from '@shared/utils/urlUtils.mjs';

export default createApp => async ({ beforeCreate, afterCreate, afterMount, makeRouteErrorHandler }) => {
  let componentsState;
  let routeErrorHandler;

  // const cookies = require('js-cookie');
  const { app, router, store, services } = await createApp(cookies, async (services, store) => {
    if (window.__INITIAL_STATE__) { // SSR 통해 기본 정보 받아온 경우
      componentsState = window.__INITIAL_STATE__.__COMPONENTS_STATE__;
      window.__INITIAL_STATE__.__COMPONENTS_STATE__ = undefined;
      store.replaceState(window.__INITIAL_STATE__);
    } else { // SSR이 되지 않는 devServer 환경에서는 SSR에서 가져오는 정보를 이시점에 가져온다.
      await services.init(window.location.pathname, window.location.host, parseQuery(window.location.search), store, {}).catch(routeErrorHandler);
    }
  }, async (services, store, router) => {
    await beforeCreate(Vue, store, router, services);

    routeErrorHandler = makeRouteErrorHandler(services, router);

    const routerPush = router.constructor.prototype.push;
    router.constructor.prototype.push = function (r) {
      return routerPush.call(this, r).catch(e => {
        if (e.name !== 'NavigationDuplicated') routeErrorHandler(e);
      });
    };

    const routerReplace = router.constructor.prototype.replace;
    router.constructor.prototype.replace = function (r) {
      return routerReplace.call(this, r).catch(e => {
        if (e.name !== 'NavigationDuplicated') routeErrorHandler(e);
      });
    };

    router.onError(routeErrorHandler);
  });

  ToastErrorHandler(Vue, app, services);
  afterCreate(app, router, store);

  const getMatchedComponentsNextTick = () => new Promise(resolve => setTimeout(() => resolve(router.getMatchedComponents()), 100));

  const getMatchedComponentsOnLanding = async () => {
    let matched = router.getMatchedComponents();
    if (matched.length) return matched;
    do matched = await getMatchedComponentsNextTick(); while (!matched.length);
    return matched;
  };

  const prepComponentsInitial = async (matchedComponents, route, initialData, restoreData) => {
    if (initialData || restoreData) {
      getHasAsyncDataComponents(matchedComponents).forEach(component => {
        if (initialData) component.__INITIAL_STATE__ = initialData[component.name];
        if (restoreData) component.__RESTORE_STATE__ = restoreData[component.name];
      });
    } else {
      const sharedScope = {};
      const sortedByPriority = getHasAsyncDataComponentsSorted(matchedComponents);
      for (const components of sortedByPriority) {
        await Promise.all(components.map(component => component.asyncData({ store, route, services, sharedScope, mixinFetcher: component.mixins?.find(m => m.fetch) })
          .catch(e => {
            throw e;
          })
          .then(result => component.__INITIAL_STATE__ = result)));
      }

      for (const component of matchedComponents) {
        if (component.afterAsyncData) await component.afterAsyncData({ store, route, services, sharedScope }).catch(routeErrorHandler);
        if (component.routeMappers) {
          _forEach(component.routeMappers, (routeMapper, key) => {
            if (routeMapper.type !== 'param') return;
            const currentValue = route.params[key];
            if (currentValue && currentValue !== '_') return;
            let defaultValue = routeMapper.defaultParam;
            if (routeMapper.useLastValueAsDefault) {
              const savedValue = services.cookies.getCookie(`${component.name}_${key}`);
              if (savedValue) defaultValue = savedValue;
            }
            if (!defaultValue || defaultValue === '_') return;
            const matchedPath = route.matched[route.matched.length - 1].path;
            if (!matchedPath.includes(key)) return;
            routeErrorHandler({ code: 302, to: getPathAppliedParam(matchedPath, { ...route.params, [key]: defaultValue }) });
          });
        }
      }
    }
  };

  const localObject = getSessionStorage('componentState');

  const allThroughComponents = (root, hook) => {
    let prev = null; let i = 0; let
      list = root.$children;
    while (i < list.length) {
      const instance = list[i];
      const component = instance.constructor.extendOptions;
      if (hook(instance, component)) break;
      if (instance.$children) {
        prev = [i + 1, list, prev];
        list = instance.$children;
        i = 0;
      } else {
        i += 1;
      }
      while (i >= list.length && prev) [i, list, prev] = prev;
    }
  };

  const getFetchedData = root => {
    let acc = null;
    allThroughComponents(root, (instance, component) => {
      if (component.asyncData) {
        if (!instance.__fetched) {
          acc = null;
          return true;
        }
        if (!acc) acc = {};
        if (!component.name) throw new Error('[asyncData] \'name\' property is required');
        if (acc[component.name]) throw new Error(`[asyncData] duplicated name(${component.name}) property`);
        acc[component.name] = instance.$data;
      }
    });
    return acc;
  };

  window.addEventListener('beforeunload', () => localObject.set(routeString(), getFetchedData(app)));

  /**
   * hmr 개발시 asyncData 동작
   */

  /**
   * @return {{component: any, instance: any, priority: number }[][]}
   */
  const getLiveComponentsSortedByPriority = () => {
    const components = [];
    allThroughComponents(app, (instance, component) => {
      if (component.asyncData) components.push({ instance, component, priority: component.asyncDataPriority });
    });
    return _sortBy(_groupBy(components, c => c.priority || 0), (_, priority) => priority).reverse();
  };

  const loadAsyncDataInComponent = async () => {
    const sortedByPriority = getLiveComponentsSortedByPriority();
    const sharedScope = {};
    for (const components of sortedByPriority) {
      await Promise.all(components.map(({ instance, component }) => (async () => _assign(instance.$data, await component.asyncData({ store, route: router.currentRoute, services, sharedScope, mixinFetcher: component.mixins?.find(m => m.fetch) })))()));
    }
  };

  // for webpack hmr
  if (process.env.NODE_ENV === 'development' && module && module.hot) {
    /** @type {{ addStatusHandler: (callback) => void }} */
    const { hot } = module;
    hot.addStatusHandler(status => {
      if (status === 'idle') {
        Vue.nextTick(async () => {
          await loadAsyncDataInComponent();
        });
      }
    });
  }

  // for vite hmr
  if (process.env.VUE_APP_ENV === 'local') window.loadAsyncDataInComponent = loadAsyncDataInComponent;

  router.beforeEach(async (to, from, next) => {
    if (routeString(to) === routeString(from)) {
      next();
      return;
    }
    localObject.set(routeString(from), getFetchedData(app));
    const modules = await Promise.all(router.getMatchedComponents(to).map(lazyOrNot => (typeof lazyOrNot === 'function' ? lazyOrNot(null, null) : lazyOrNot)));
    if (modules.some(module => !module)) {
      next();
      return;
    }
    const matchedComponents = modules.map(module => module.default || module);
    let restoreData;
    if(services.router) services.router.nextRoute = to;
    if (store.state.browser.popState) restoreData = localObject.pick(routeString(to));
    await prepComponentsInitial(matchedComponents, to, restoreData);
    next();
  });

  router.onReady(async () => {
    const route = router.currentRoute;
    let restoreData;

    if (getSessionStorage('browser').get('lastRoute') === routeString()) {
      store.commit('browser/popState', true);
      if (componentsState) restoreData = localObject.pick(routeString());
      else componentsState = localObject.pick(routeString());
    }
    getSessionStorage('browser').remove('lastRoute');

    try {
      await prepComponentsInitial(await getMatchedComponentsOnLanding(), route, componentsState, restoreData);
    } catch (e) {
      if (e.code === 'NOT_FOUND') window.location.href = '/404';
      console.log(e);
    }

    app.$mount('#app');
    services.mounted(app, route);
    if (afterMount) afterMount(app, route, store);
  });
};
