<template>
  <TextInput gp-text-input-box :class="[textAlign, {'currency' : currencySymbol}]" :value="value" :placeholder="placeholder" :is-decimal-limit="isDecimalLimit" :decimal-limit="decimalLimit" :number="number" :digit="digit" :phone="phone" :telephone="telephone" :type="type" :sm="sm" :wordonly="wordonly" :readonly="readonly" :disabled="disabled" :error="error" :maxLength="maxLength"
             :inputMode="inputMode" :pattern="pattern" :theme="theme" :useReset="useReset" :center="center" :tabindex="tabindex" :translate="translate" :clearable="clearable" :max="max" :min="min"
             @input="v => $emit('input', returnValue(v))" @change="v => $emit('change', returnValue(v))" @focus="v => $emit('focus', returnValue(v))" @blur="v => $emit('blur', returnValue(v))" @keydown.enter="$emit('enter')" @delete="$emit('delete')" />
</template>
<script>

import TextInput from '@shared/components/common/input/TextInput.vue';

export default {
  name: 'TextInputBox',
  components: { TextInput },
  props: {
    value: { default: '' },
    placeholder: { type: String, default: '' },
    number: { type: Boolean, default: false },
    digit: { type: Boolean, default: false },
    phone: { type: Boolean, default: false },
    telephone: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    sm: { type: Boolean, default: false },
    wordonly: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: [Array, Object], default: null },
    maxLength: { type: Number, default: 999 },
    inputMode: { type: String, default: '' },
    pattern: { type: String, default: '' },
    currencySymbol: { type: String, default: null },
    textAlign: { type: String, default: null, },

    /** @type string{'white' | 'black' | 'dark'} */
    theme: { type: String, default: 'white' },
    useReset: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    tabindex: { type: String, default: '0' },
    translate: { type: Boolean, default: false },
    clearable: Boolean,
    isDecimalLimit: { type: Boolean, default: false, },
    decimalLimit: { type: Number, default: null },
    isTrimText: { type: Boolean, default: false },
    max: { type: String, default: null, },
    min: { type: String, default: null, },
  },
  methods: {
    returnValue(v) {
      if (!this.isTrimText) return v;
      return typeof v === 'string' ? v.trim() : v;
    },
  },
};
</script>

<style lang="less">
@import '@/less/proj.less';
[gp-text-input-box] { .br(8); .wh(100%, 40); .bgc(#FFF); border: none;
  &.currency {
    > input {padding-left: 30px !important;}
  }
  &.right {
    > input {text-align: right;}
  }
  &.disabled { .bgc(@gp-disabled-bgc); }
  > input { .wh(100%, 40); .p(10, 10, 10, 12); padding-right: 12px !important; .fs(14);
    &::placeholder {.c(@gp-placeholder-color);}
  }
  &.transparent { .bgc(transparent); }
  &.focus:after { background-color: transparent !important; }
}
</style>
