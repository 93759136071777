<template>
  <div verify-citizenship-proof-of-citizenship>
    <p class="desc">
      {{ $t('_.proofCitizenshipDescription') }}<br />
    </p>
    <DescriptionHolder :list="[$t('_.proofCitizenshipItem1'),$t('_.proofCitizenshipItem2')]" transparent />
    <FileSelector @update="fileUpdate" ref="selector" drag-input :maxLength="1">
      <p v-html="$t('kyc.dropFile', { fileLength: 1, fileSize: 10 })" />
    </FileSelector>
    <div class="list-holder" v-if="fileList && fileList.length">
      <UploadItem v-for="(item, id) in fileList" :key="id" :info="item" type="Etc" @remove="remove(id)" />
    </div>
    <p v-if="errorMsg" class="error" v-html="errorMsg" />
    <ProgressButton button-name="upload" :disabled="!verifyDocument" @click="upload" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import FileSelector from '@/views/components/pages/on-boarding/FileSelector.vue';
import UploadItem from '@/views/components/extra/UploadItem.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import DescriptionHolder from '@/views/components/common/DescriptionHolder.vue';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'VerifyCitizenshipProofOfCitizenship',
  lexicon: 'onBoarding.citizenship',
  components: { DescriptionHolder, ProgressButton, UploadItem, FileSelector },
  mixins: [baseTemplate],
  props: {
    item: { type: Specific, default: null },
    kycDocumentGroups: /** @type {Array<KycDocumentGroup>} */ Specific,
  },
  data() {
    return {
      fileList: null,
      uid: 0,
    };
  },
  watch: {
    fileList() {
      this.structure.error = false;
      this.structure.errorMsg = null;
      this.structure.errorDesc = null;
    }
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    citizenshipCode: state('query', 'citizenshipCode'),
    verifyDocument() {
      return !this.structure.error && this.fileList?.length === 1;
    },
  },
  methods: {
    fileUpdate(list) {
      this.fileList = list;
      this.uid++;
    },
    remove(id) {
      this.$refs.selector.remove(id);
    },
    /**
     * 컴포넌트 내에서의 api request 처리
     * @returns {Promise<*|null>}
     */
    async apiRequest() {
      const r = await this.getApiResponse();
      if (r.error) {
        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          this.$toast(r.desc, { type: 'fail', translate: false });
          console.log(`Api Request Error : onboarding.setAddCitizenshipDocument`, r);
        }
      }
    },
    /**
     *
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding.setAddCitizenshipDocument({ Citizenship: this.citizenshipCode, FileData: this.fileList[0].img, FileName: this.fileList[0].file.name }); }
      catch (e) { return e; }
    },
    async upload() {
      if (this.fileList?.length === 1) {
        await this.apiRequest();
        if (!this.structure.error) this.$emit('upload');
      }
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-citizenship-proof-of-citizenship] {
  [description-holder] { .mt(12); .pl(16);}
  .add-document { .c(#ccc); .m(20, 0); }
  .list-holder { .mt(20); }
  .error { .mt(20); .c(@c-red;) }
  a[href^='mailto'] { .c(#2680EB); .underline(); }
}
</style>
