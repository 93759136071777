<template>
  <div questionnaire-dmi-modal>
    <div class="content">
      <p class="desc1">
        {{ description1 }}<br />
      </p>
      <p class="desc2">
        {{ description2 }}<br />
      </p>
      <DescriptionHolder :list="example" transparent />
      <FileSelector @update="fileUpdate" ref="selector" drag-input>
        <p v-html="$t('responsibleGaming.modal.label.dropFiles', { fileLength: maxFileLength, fileSize: maxFileSize })" />
      </FileSelector>
      <div class="list-holder" v-if="fileList && fileList.length">
        <UploadItem v-for="(item, id) in fileList" :key="id" :info="item" type="edd" @remove="remove(id)" />
      </div>
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import UploadItem from '@/views/components/extra/UploadItem.vue';
import DescriptionHolder from '@/views/components/common/DescriptionHolder.vue';
import FileSelector from '@/views/components/pages/responsible-gaming/template/common/FileSelector.vue';

export default {
  name: 'QuestionnaireDmiModal',
  components: { DescriptionHolder, UploadItem, FileSelector },
  props: {
    message: { type: String, default: null },
    subMessage: { type: String, default: null },
    payload: Specific,
  },
  data() {
    return {
      fileList: null,
      uid: 0,
      maxFileLength: 5,
      maxFileSize: 10,
    };
  },
  computed: {
    description1() {
      return this.$t('responsibleGaming.modal.description.documentUploadGuide1');
    },
    description2() {
      return this.$t('responsibleGaming.modal.description.documentUploadGuide2');
    },
    example() {
      return [this.$t('kyc.documentType.RecentPayslips'), this.$t('kyc.documentType.RecentIncomeTaxReturn'), this.$t('kyc.documentType.RecentBankStatements')];
    },
  },
  methods: {
    fileUpdate(list) {
      this.fileList = list;
      this.uid++;
      this.payload.DmiFileList = list;
    },
    remove(id) {
      this.$refs.selector.remove(id);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[questionnaire-dmi-modal] {
  > p + p { .mt(8); }
  .desc1{ word-break: break-word; .c(#737373); .fs(14, 20, 0.42); }
  .desc2{ word-break: break-word; .mt(20); .c(#000); .fs(14, 20, 0.42); }
  [description-holder] { .mt(12); .pl(16); .gap(0);
    li { .c(#000); .regular(); }
  }
  [file-selector] { .mt(12); .-a(#249135); .dashed(); .tc(); .bgc(@c-w01); .br(16);
    [font-icon] { .fs(32); .c(#249135); }
    em { .c(#1570EF); .-b(); .medium(); }
    label { .p(20); }
    &:hover { .bgc(#F8FBF9); .-a(#249135); }
  }
  .list-holder { .mt(12);
    [upload-item] {
      img { .wh(40); }
      .type-holder { .ml(8); .w(100%); .flex(); .space-between(); .items-center();
        label { .mb(0); .c(#0B0B0B); }
        button { .c(#000); }
      }
    }
  }
}
</style>
