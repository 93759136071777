<template>
  <div verification-failed v-if="model">
    <div v-if="model.title" class="title">
      <h1>{{ $t(model.title) }}</h1>
      <p><span /></p>
    </div>
    <fieldset class="field-holder">
      <div v-html="$t(model.content, { csEmail: csEmail || 'help@ggpoker.com', multipleAccount, serviceName, ...(typeof firstCustomParam === 'object' ? firstCustomParam : {firstCustomParam}), ...(typeof secondCustomParam === 'object' ? secondCustomParam : {secondCustomParam}) })" />
    </fieldset>
    <div v-if="model.btnName" class="button-holder">
      <ColorButton type="light-red" @click="click">
        {{ $t(model.btnName) }}
      </ColorButton>
    </div>
    <FloatingAdminButton v-if="isLocal" />
  </div>
</template>
<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isLocal } from '@/utils/baseUtil';
import { close } from '@/utils/clientUtil';
import { getObjectPropertyByIndex } from '@/utils/valueUtil';
import { apiErrorCode, apiErrorType } from '@/constants/base/apiErrorCode';
import FloatingAdminButton from '@/views/components/common/FloatingAdminButton.vue';
import Specific from '@shared/types/Specific';
import ColorButton from '@shared/components/common/ColorButton.vue';
import ApiErrorController from '@/modules/ApiErrorController';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'VerificationFailed',
  components: { ColorButton, FloatingAdminButton },
  mixins: [baseTemplate],
  props: {
    desc: { type: String },
    errorTemplate: { type: Specific },
  },
  data() {
    return {
      model: null,
      code: null,
      customCode: null,
      customParams: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    defaultSite: state('env', 'defaultSite'),
    isLocal() {
      return isLocal();
    },
    firstCustomParam() {
      if (!this.customParams) return null;
      const param = Array.isArray(this.customParams) ? this.customParams[0] : this.customParams;
      try { return JSON.parse(decodeURIComponent(param)); } catch (e) { return decodeURIComponent(param); }
    },
    secondCustomParam() {
      if (!this.customParams) return null;
      if (!Array.isArray(this.customParams) || this.customParams.length < 2) return null;
      try { return JSON.parse(decodeURIComponent(this.customParams[1])); } catch (e) { return decodeURIComponent(this.customParams[1]); }
    },
    multipleAccount() {
      return this.firstCustomParam;
    },
    serviceName() {
      return this.firstCustomParam;
    },
  },
  methods: {
    click() {
      switch (this.model.btnAction) {
        case 'close':
          close(this);
          break;
        case 'upload':
          this.replaceRouteName('KycDocumentManual', { route: 'KycDocumentManual' });
          break;
        case 'replace':
          this.modelInfo.replace = true;
          break;
        default:
          break;
      }
    },
    async initialize() {
      try {
        const token = this.$services.cookie.getToken();
        if (token) {
          // query로 ci, cci를 전달 시 ci, cci로 errorTemplate를 가져와서 처리
          if (this.errorTemplate) {
            this.model = this.errorTemplate?.[apiErrorType.VERIFICATION];
            if(this.$route.query?.p) this.customParams = decodeURIComponent(this.$route.query.p);
          } else {
            if (this.code) {
              this.model = ApiErrorController.proxy({site: process.env.VUE_APP_SITE, code: this.code, customCode: this.customCode})?.[apiErrorType.VERIFICATION];
            } else {
              this.replaceRouteName('Replace');
            }
          }

          // 새로고침 시 화면이 유지가 되야하는 케이스가 존재하여 ci, cci를 params가 아닌 query로도 전달하게 됨.
          if (this.model?.ci) this.code = getObjectPropertyByIndex(apiErrorCode, Number(this.model.ci));
          else if (this.$route.query?.ci) this.code = getObjectPropertyByIndex(apiErrorCode, Number(this.$route.query.ci));

          if (this.model?.cci) this.customCode = getObjectPropertyByIndex(apiErrorCode, Number(this.model.cci));
          else if (this.$route.query?.cci) this.customCode = getObjectPropertyByIndex(apiErrorCode, Number(this.$route.query.cci));
        } else this.replaceRouteName('AuthError');
      } catch (e) { /** empty */ }
    },
  },
  async mounted() {
    await this.initialize();
    if (this.model?.logout) this.$services.sign.logout({ useRedirect: false });
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verification-failed] {
  .title { .m() !important; .mb(24) !important; .rel();
    h1 { .flex() !important; .space-between(); .items-center(); .w(100%); .tl(); .regular() !important; .fs(28) !important; .c(white) !important;
      > [color-button] { .h(24); .flex(); .flex-ai(); }
    }
    p { .wh(100%, 2); .rel(); .bgc(rgba(255, 255, 255, 0.07)); .block() !important; .mt(16) !important;
      span { .block(); .wh(100%, 2); .bgc(@c-red); }
    }
  }
  .field-holder {
    p { .c(#7f7f7f); // 기본 컬러 밝은톤으로 시안 확인해서 수정해야 함
      a { .underline();
        &.link { .c(#0064E5); }
      }
      b { .medium();
        &.white { .c(#fff); } // 굵게 처리할때 톤 시안 확인해서 수정해야 함
      }
    }
    p + p { .mt(12); }
  }
  .button-holder {
    [color-button] { .mt(55); .max-w(440); .wh(100%, 56); .br(28); .fs(18); .tr-t(.3s); .mh-c(); .block(); .medium(); }
  }
  a[href^="mailto:"] { .c(@c-link); }
}
</style>
