<template>
  <div footer-ro>
    <p>{{ $t('or') }}</p>
    <button @click="skip">{{ $t('_.skipForNow') }}</button>
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'FooterRo',
  lexicon: 'onBoarding',
  mixins: [baseTemplate],
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  methods: {
    /**
     * 다음 단계로 이동
     */
    nextStep() {
      this.modelInfo.step = 1;
    },
    /**
     * 컴포넌트 내에서의 api request 처리
     * @param {Specific} params - 호출 파라미터
     * @param {string} api - api 함수 명
     * @param {object} params - params
     * @param {object} config - config
     * @returns {Promise<*|null>}
     */
    async apiRequest(api, params, config = null) {
      const r = await this.getApiResponse(api, params, config);
      if (r.error) {
        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.${api}`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }
      }
    },
    /**
     *
     * @param api
     * @param params
     * @param config
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse(api, params, config = null) {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.verification[api]({ ...params }, { ...config }); } catch (e) { return e; }
    },
    async skip() {
      const { structure } = this;
      const api = structure?.list[structure.step]?.api;
      // Skip 실행 전, Structure에 에러가 있을 경우 초기화 하여야함.
      this.structure.error = false;
      this.structure.errorMsg = null;
      this.structure.errorDesc = null;

      await this.apiRequest(api, { ...this.modelInfo, IsSkipMobileVerification: true, VerificationCode: null, });

      if (!this.invalid && !this.structure.error) {
        this.nextStep();
      }
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[footer-ro] { .tc();
  p { .mv(20); .flex(); .items-center(); .c(#707070); }
  p:before, p:after { .w(50%); .h(1); .cnt(''); .bgc(#707070); }
  p:before { .mr(16); }
  p:after { .ml(16); }

  button { .c(#bfbfbf); .fs(16); }
}
</style>