<template>
  <div tooltip-item v-popover="{text, translate : false, useClick: isMobile, class: this.childClass, direction: direction, align: align}" v-if="text">
    <button>
      <slot></slot>
    </button>
  </div>
</template>

<script>

import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'TooltipItem',
  props: {
    childClass: { type: String, default: 'myinfo-tooltip' },
    text: { type: String, required: true },
    direction: { type: String, default: 'bottom' },
    align: { type: String, default: 'right' },
  },
  computed: {
    isMobile: state('browser', 'mobile'),
  },
};
</script>

<style lang="less">
@import '@/less/proj.less';
div[popover-container] {
  .popover-position {
    .popover.myinfo-tooltip {
      .component-crop { .bgc(white); .c(@c-black); box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1); .br(4);
        .popover-text { .c(inherit); }
      }
      em.arrow { .bgc(white); box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1); }
    }
  }
}
</style>
<style lang="less" scoped>
@import '@/less/proj.less';
[tooltip-item] { .fs(16);
  button {.fs(1em); .c(gray); }
}
</style>
