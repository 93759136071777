<template>
  <Modal verify-request-message-modal theme="dark" @close="close">
    <template #title>
      {{ title }}
    </template>
    <div class="body" v-html="body" />
    <div class="bts">
      <ProgressButton button-name="close" @click="close" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Specific from '@shared/types/Specific';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'VerifyRequestMessageModal',
  components: { ProgressButton, Modal },
  lexicon: 'signUp.resend',
  props: {
    // options: {
    //   type: { type: String, default: 'email' },
    //   isSend: Boolean,
    //   error: { key: ERROR_CODE }
    // }
    options: Specific,
  },
  computed: {
    title() { return this.$t('_.title', { method: this.$t(this.type) }); },
    body() {
      if(this.isSend){
        return this.$t('_.successDescription');
      }else{
        if(this.error){
          const {key} = this.error;
          if(key === apiErrorCode.RESEND_LIMITED || key === apiErrorCode.PHONE_RESEND_LIMITED || key === apiErrorCode.EMAIL_RESEND_LIMITED)
            return this.$t('RESEND_COUNT_LIMIT', { method: this.$t(this.type) });
        }
        return this.$t('_.failedDescription', { method: this.$t(this.type) });
      }

    },
    error(){ return this.options.error; },
    type() { return this.options?.type?.toLowerCase(); },
    isSend() { return this.options?.isSend; }

  },
  methods: {
    close() {
      this.$emit('close');
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[verify-request-message-modal] {
  .bts { .flex(); .flex-dc(); .items-center(); }
  > .panel.large { .max-w(460); .mh(16); }
}
</style>
