import { siteIds, brandIds } from '@/constants/base/siteMap.mjs';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';
/** KYC 란? Know Your Customer 의 약자 */
export const backgroundOnlyList = [siteIds.GGPCOM, siteIds.GGPNG, siteIds.GGPUA, siteIds.GGPJP, siteIds.GGPBR, siteIds.GGPPL, siteIds.GGPCW, siteIds.N8IN, siteIds.GGPRO, siteIds.GGPBE, siteIds.GGPFI, siteIds.GGPJU, siteIds.GGPEU];

export const restrictType = {
  None: 0,
  Login: 1,
  Deposit: 2,
  Withdrawal: 4,
  MoneyTransfer: 8,
  GamePlay: 16,
  FreeGame: 32,
};

export const kycLevel = {
  SignedUp: 'SignedUp',
  EmailVerified: 'EmailVerified',
  DetailsFilled: 'DetailsFilled',
  AgeVerified: 'AgeVerified',
  PoiVerified: 'PoiVerified',
  PoaVerified: 'PoaVerified',
  EddVerified: 'EddVerified',
};

// TODO : VerifyEmail 및 PersonalName Step 의 활용 여부 확인 후 주석 제거
export const kycLevelByBrand = {
  [kycLevel.SignedUp]: {
    [brandIds.POKEROK]: null,
    [brandIds.GGPCOM]: null,
    [brandIds.GGPOKERUK]: null,
    [brandIds.WSOP]: null,
  },
  [kycLevel.EmailVerified]: {
    [brandIds.POKEROK]: null,
    [brandIds.GGPCOM]: null,
    [brandIds.GGPOKERUK]: null,
    [brandIds.WSOP]: null,
  },
  [kycLevel.DetailsFilled]: {
    [brandIds.POKEROK]: [routeNames.poi],
    [brandIds.GGPCOM]: [routeNames.poi],
    [brandIds.GGPOKERUK]: [routeNames.poi],
    [brandIds.WSOP]: [routeNames.poi],
  },
  [kycLevel.AgeVerified]: {
    [brandIds.POKEROK]: [routeNames.poi],
    [brandIds.GGPCOM]: [routeNames.poi],
    [brandIds.GGPOKERUK]: [routeNames.poi],
    [brandIds.WSOP]: [routeNames.poi],
  },
  [kycLevel.PoiVerified]: {
    [brandIds.POKEROK]: [routeNames.poa],
    [brandIds.GGPCOM]: [routeNames.poa],
    [brandIds.GGPOKERUK]: [routeNames.poa],
    [brandIds.WSOP]: [routeNames.edd],
  },
  [kycLevel.PoaVerified]: {
    [brandIds.POKEROK]: null,
    [brandIds.GGPCOM]: [routeNames.edd],
    [brandIds.GGPOKERUK]: [routeNames.edd],
    [brandIds.WSOP]: null,
  },
  [kycLevel.EddVerified]: {
    [brandIds.POKEROK]: null,
    [brandIds.GGPCOM]: [routeNames.edd],
    [brandIds.GGPOKERUK]: [routeNames.edd],
    [brandIds.WSOP]: [routeNames.edd],
  },
};

/** ******************************* Status ********************************** */
/**
 * Kyc Status
 * @type {{Poa: string, Poi: string}}
 */
export const kycStatus = {
  Ready: 'Ready',
  Poi: 'Poi',
  Poa: 'Poa',
  EddForm: 'EddForm',
  Edd: 'Edd',
  Final: 'Final',
};

export const jumioType = { BS: 'BS', UB: 'UB', CCS: 'CCS', TR: 'TR', LAG: 'LAG', CB: 'CB' };
export const jumioStep = { None: 'None', Identity: 'Identity', Document: 'Document', Reviewing: 'Reviewing', Done: 'Done', OasisFailed: 'OasisFailed', SelfExclusionFailed: 'SelfExclusionFailed', PepFailed: 'PepFailed', Failed: 'Failed' };
export const jumioStatus = { None: 'None', Pending: 'Pending', Rejected: 'Rejected', Done: 'Done', Verified: 'Verified', NotApplicable: 'NotApplicable', DocumentInvalid: 'DocumentInvalid', DocumentExpired: 'DocumentExpired', Failed: 'Failed' };

export const sumsubStatus = { None: 'None', Ready: 'Ready', Pending: 'Pending', Reviewing: 'Reviewing', Verified: 'Verified', ReCheck: 'ReCheck', ServiceAbort: 'TimeOver', TryCountOver: 'TryCountOver', DocumentInvalid: 'DocumentInvalid', CertificationFailed: 'CertificationFailed', CameraFailed: 'CameraFailed', Failed: 'Failed' };

export const signicatStep = { IdinVerification: 'IdinVerification', EidvVerification: 'EidvVerification', Cruks: 'Cruks', SurePay: 'SurePay', KycDocumentManual: 'KycDocumentManual', Screening: 'Screening', Done: 'Done' };

export const signicatStatus = { Processing: 'Processing', Pending: 'Pending', Duplicated: 'Duplicated', Accepted: 'Accepted', CruksFailed: 'CruksFailed', Canceled: 'Canceled', Failed: 'Failed' };

export const sofortStatus = { None: 'None', Ready: 'Ready', Processing: 'Processing', Finished: 'Verified', UrlFailed: 'UrlFailed', OasisFailed: 'OasisFailed', PepFailed: 'PepFailed', Invalid: 'Invalid', UserAbort: 'UserAbort', Failed: 'Failed' };

export const bankIdStatus = { None: 'None', Ready: 'Ready', Pending: 'Pending', Verified: 'Verified', Default: 'Default' };

export const payletterStatus = { None: 'None', Ready: 'Ready', Waiting: 'Waiting', Success: 'Success', Failed: 'Failed', RejectedUnderAge: 'RejectedUnderAge', RejectedDupCi: 'RejectedDupCi', RejectedOtherCi: 'RejectedOtherCi', PopupBlocked: 'PopupBlocked' };

export const getKycDocuments = site => {
  const exceptionSite = [siteIds.DAVAOPOKER, siteIds.GGPOK, siteIds.TWOACE].includes(site);
  return [
    // POI selectable : true
    { label: 'kyc.documentType.IdentityCard', value: 'IdentityCard', selectable: true, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.DrivingLicence', value: 'DrivingLicence', selectable: !exceptionSite, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.Passport', value: 'Passport', selectable: true, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.ProofOfIdentity', value: 'ProofIdentity', selectable: true, description: `kyc.documentType.ProofOfIdentityDescription${exceptionSite ? site : ''}`, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.UnifiedMultiPurpose', value: 'UnifiedMultiPurpose', selectable: true, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.PostalIdentityCard', value: 'PostalIdentityCard', selectable: true, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.ProfessionalRegulationCommission', value: 'ProfessionalRegulationCommission', selectable: true, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.Selfie', value: 'Selfie', selectable: true, description: 'kyc.documentType.SelfieDescription', isPoi: true, isPoa: false },
    { label: 'kyc.documentType.VoterCard', value: 'VoterCard', selectable: true, description: null, isPoi: true, isPoa: false },
    // POI selectable : false
    { label: 'kyc.documentType.NexusCard', value: 'NexusCard', selectable: false, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.GlobalEntryCard', value: 'GlobalEntryCard', selectable: false, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.CitizenshipCard', value: 'CitizenshipCard', selectable: false, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.PermanentResidentCard', value: 'PermanentResidentCard', selectable: false, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.ProvincialOrTerritorialIdentityCard', value: 'ProvincialOrTerritorialIdentityCard', selectable: false, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.ProvincialServicesCard', value: 'ProvincialServicesCard', selectable: false, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.SecureCertificateOfIndianStatus', value: 'SecureCertificateOfIndianStatus', selectable: false, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.PoiPoaNationalID', value: 'PoiPoaNationalID', selectable: false, description: null, isPoi: true, isPoa: false },
    { label: 'kyc.documentType.AadharCard', value: 'AadharCard', selectable: false, description: null, isPoi: true, isPoa: false },
    // POA selectable : true
    { label: 'kyc.documentType.UtilityBill', value: 'UtilityBill', selectable: true, description: null, isPoi: false, isPoa: true, jumioType: jumioType.UB},
    { label: 'kyc.documentType.BankStatement', value: 'BankStatement', selectable: true, description: null, isPoi: false, isPoa: true },
    { label: 'kyc.documentType.ProofOfAddress', value: 'ProofAddress', selectable: true, description: 'kyc.documentType.ProofOfAddressDescription', isPoi: false, isPoa: true },
    { label: 'kyc.documentType.ProofOfAffordability', value: 'ProofOfAffordability', selectable: true, description: null, isPoi: false, isPoa: true },
    { label: 'kyc.documentType.CreditCardStatement', value: 'CreditCardStatement', selectable: true, description: null, jumioType: jumioType.CCS, isPoi: false, isPoa: true, period: site === siteIds.GGPDE ? 'onBoarding.jumio.documentsSelect.last3Months' : null },
    { label: 'kyc.documentType.SignedLease', value: 'SignedLease', selectable: true, description: null, jumioType: jumioType.LAG, isPoi: false, isPoa: true, period: site === siteIds.GGPDE ? 'onBoarding.jumio.documentsSelect.currentYear' : null },
    { label: 'kyc.documentType.TaxAssessment', value: 'TaxAssessment', selectable: true, description: null, jumioType: jumioType.TR, isPoi: false, isPoa: true, period: site === siteIds.GGPDE ? 'onBoarding.jumio.documentsSelect.last12Months' : null },
    { label: 'kyc.documentType.BankOpeningLetter', value: 'BankOpeningLetter', selectable: true, description: null, jumioType: jumioType.BS, isPoi: false, isPoa: true, period: site === siteIds.GGPDE ? 'onBoarding.jumio.documentsSelect.last3Months' : null },
    { label: 'kyc.documentType.ElectionNotification', value: 'ElectionNotification', selectable: true, description: null, isPoi: false, isPoa: true },
    { label: 'kyc.documentType.OfficialRegistrationConfirmation', value: 'OfficialRegistrationConfirmation', selectable: true, description: null, isPoi: false, isPoa: true },
    { label: 'kyc.documentType.CertificateOfEnrolmentOrStudy', value: 'CertificateOfEnrolment', selectable: true, description: null, isPoi: false, isPoa: true },
    { label: 'kyc.documentType.Signature', value: 'Signature', selectable: true, description: null, isPoi: false, isPoa: true },
    { label: 'kyc.documentType.MortgageStatement', value: 'MortgageStatement', selectable: true, description: null, isPoi: false, isPoa: true },
    // POA selectable : false
    { label: 'kyc.documentType.CreditCard', value: 'CreditCard', selectable: false, description: null, isPoi: false, isPoa: true },
    // EDD
    { label: 'kyc.documentType.BankStatements', value: 'BankStatements', selectable: false, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.RecentBankStatements', value: 'RecentBankStatements', selectable: false, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.EwalletStatements', value: 'EwalletStatements', selectable: false, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.RecentEwalletStatements', value: 'RecentEwalletStatements', selectable: false, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.InvestmentTradingScreenshot', value: 'InvestmentTradingScreenshot', selectable: false, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.WinningsScreenshot', value: 'WinningsScreenshot', selectable: false, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.OtherProveDocuments', value: 'OtherProveDocuments', selectable: false, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.RecentPayslips', value: 'RecentPayslips', selectable: false, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.RecentIncomeTaxReturn', value: 'RecentIncomeTaxReturn', selectable: false, description: null, isPoi: false, isPoa: false },
    // ETC
    { label: 'kyc.documentType.DeclarationOfSow', value: 'DeclarationOfSow', selectable: true, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.SowEvidence', value: 'SowEvidence', selectable: true, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.PaymentMethod', value: 'PaymentMethod', selectable: true, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.ProofOfNoDebt', value: 'ProofOfNoDebt', selectable: false, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.ProofOfNoDebit', value: 'ProofOfNoDebit', selectable: false, description: null, isPoi: false, isPoa: false },
    { label: 'kyc.documentType.Other', value: 'Others', selectable: false, description: null, isPoi: false, isPoa: false },
  ];
};
export const kycEmploymentDocuments = [
  { label: 'kyc.employmentType.Employed', value: 'Employed' },
  { label: 'kyc.employmentType.SelfEmployed', value: 'SelfEmployed' },
  { label: 'kyc.employmentType.Retired', value: 'Retired' },
  { label: 'kyc.employmentType.Student', value: 'Student' },
  { label: 'kyc.employmentType.Unemployed', value: 'Unemployed' },
];
export const kycIndustryDocuments = [
  { label: 'kyc.industryType.NotEmployed', value: 'NotEmployed' },
  { label: 'kyc.industryType.Accounting', value: 'Accounting' },
  { label: 'kyc.industryType.AdministrativeSupport', value: 'AdministrativeSupport' },
  { label: 'kyc.industryType.AdultEntertainment', value: 'AdultEntertainment' },
  { label: 'kyc.industryType.AdvertisingMarketing', value: 'AdvertisingMarketing' },
  { label: 'kyc.industryType.Agriculture', value: 'Agriculture' },
  { label: 'kyc.industryType.AirlinesAviation', value: 'AirlinesAviation' },
  { label: 'kyc.industryType.ArchitectureAndPlanning', value: 'ArchitectureAndPlanning' },
  { label: 'kyc.industryType.ArtCulturalProperty', value: 'ArtCulturalProperty' },
  { label: 'kyc.industryType.Automotive', value: 'Automotive' },
  { label: 'kyc.industryType.AutomotiveDealer', value: 'AutomotiveDealer' },
  { label: 'kyc.industryType.BankingFinancial', value: 'BankingFinancial' },
  { label: 'kyc.industryType.CannabisBusiness', value: 'CannabisBusiness' },
  { label: 'kyc.industryType.CannabisStoreOwner', value: 'CannabisStoreOwner' },
  { label: 'kyc.industryType.Construction', value: 'Construction' },
  { label: 'kyc.industryType.ConsultancyService', value: 'ConsultancyService' },
  { label: 'kyc.industryType.ConsumerProductsRetail', value: 'ConsumerProductsRetail' },
  { label: 'kyc.industryType.ConvenienceStoreOwner', value: 'ConvenienceStoreOwner' },
  { label: 'kyc.industryType.CryptocurrencyVirtualCurrenciesBusiness', value: 'CryptocurrencyVirtualCurrenciesBusiness' },
  { label: 'kyc.industryType.Diplomats', value: 'Diplomats' },
  { label: 'kyc.industryType.EducationalServices', value: 'EducationalServices' },
  { label: 'kyc.industryType.EnergyNaturalResource', value: 'EnergyNaturalResource' },
  { label: 'kyc.industryType.FinancialInvestment', value: 'FinancialInvestment' },
  { label: 'kyc.industryType.FineArtAuctionDealing', value: 'FineArtAuctionDealing' },
  { label: 'kyc.industryType.FoodHospitalityService', value: 'FoodHospitalityService' },
  { label: 'kyc.industryType.ForeignExchange', value: 'ForeignExchange' },
  { label: 'kyc.industryType.FoundationsCharitiesOtherNotForProfitEntities', value: 'FoundationsCharitiesOtherNotForProfitEntities' },
  { label: 'kyc.industryType.GamblingCasino', value: 'GamblingCasino' },
  { label: 'kyc.industryType.GovernmentAuthoritiesDiplomats', value: 'GovernmentAuthoritiesDiplomats' },
  { label: 'kyc.industryType.HealthcareHealthProfessionals', value: 'HealthcareHealthProfessionals' },
  { label: 'kyc.industryType.HighRiskOccupationIndustry', value: 'HighRiskOccupationIndustry' },
  { label: 'kyc.industryType.ITService', value: 'ITService' },
  { label: 'kyc.industryType.Insurance', value: 'Insurance' },
  { label: 'kyc.industryType.Jeweler', value: 'Jeweler' },
  { label: 'kyc.industryType.Law', value: 'Law' },
  { label: 'kyc.industryType.LeisureHospitality', value: 'LeisureHospitality' },
  { label: 'kyc.industryType.LiquorStoreOwner', value: 'LiquorStoreOwner' },
  { label: 'kyc.industryType.LogisticsSupplyChain', value: 'LogisticsSupplyChain' },
  { label: 'kyc.industryType.LuxuryGoods', value: 'LuxuryGoods' },
  { label: 'kyc.industryType.MaritimeShipping', value: 'MaritimeShipping' },
  { label: 'kyc.industryType.Media', value: 'Media' },
  { label: 'kyc.industryType.MoneyTransmitter', value: 'MoneyTransmitter' },
  { label: 'kyc.industryType.MusicEntertainment', value: 'MusicEntertainment' },
  { label: 'kyc.industryType.Pawnbroker', value: 'Pawnbroker' },
  { label: 'kyc.industryType.PaymentServiceProvider', value: 'PaymentServiceProvider' },
  { label: 'kyc.industryType.ATM', value: 'ATM' },
  { label: 'kyc.industryType.ProfessionalSports', value: 'ProfessionalSports' },
  { label: 'kyc.industryType.ProfessionalScientificTechnicalServices', value: 'ProfessionalScientificTechnicalServices' },
  { label: 'kyc.industryType.RealEstate', value: 'RealEstate' },
  { label: 'kyc.industryType.RealEstateBroker', value: 'RealEstateBroker' },
  { label: 'kyc.industryType.ReligiousInstitution', value: 'ReligiousInstitution' },
  { label: 'kyc.industryType.RestaurantOwner', value: 'RestaurantOwner' },
  { label: 'kyc.industryType.RetailStoreOwner', value: 'RetailStoreOwner' },
  { label: 'kyc.industryType.SocialMedia', value: 'SocialMedia' },
  { label: 'kyc.industryType.SoftwareDevelopment', value: 'SoftwareDevelopment' },
  { label: 'kyc.industryType.Telecommunication', value: 'Telecommunication' },
  { label: 'kyc.industryType.TobaccoDistributor', value: 'TobaccoDistributor' },
  { label: 'kyc.industryType.TradingInCommodities', value: 'TradingInCommodities' },
  { label: 'kyc.industryType.VendingMachineOperator', value: 'VendingMachineOperator' },
];
