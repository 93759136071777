<template>
  <div display-accounts-table>
    <div class="table-body">
<!--      6월 배포 제외-->
<!--      <LinkAmountItem v-if="itemList.length > 0" v-for="(item, index) in itemList" :disable="item.Disable" :show-button="item.ShowButton" :label="item.SiteName" :value="item.AccountId" :amount="displayAmount(item.Currency, item.Amount)" :country-code="item.CountryCode" :country-name="item.CountryCode" :logo-url="item.LogoUrl" :key="item.AccountId" :tool-tip-message="item.ToolTipMessage" @transferClick="transferClick" />-->
      <LinkAmountItem v-if="itemList.length > 0" v-for="(item, index) in itemList" :disable="false" :show-button="false" :label="item.SiteName" :value="item.AccountId" :amount="null" :country-code="item.CountryCode" :country-name="item.CountryCode" :logo-url="item.LogoUrl" :key="item.AccountId" :tool-tip-message="null" />
      <div v-if="itemList.length <= 0" class="no-accounts">
        <GpDescription :message="$t('myInfo.noAccounts')" />
      </div>
      <!--      There are no accounts connected to GGPass.-->
    </div>
  </div>
</template>

<script>
import SvgIcon from '@shared/components/common/SvgIcon.vue';
import LinkAmountItem from '@/views/components/pages/my-page/template/link-account/LinkAmountItem.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';
import Specific from '@shared/types/Specific';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';

export default {
  name: 'DisplayAccountTable',
  components: { GpDescription, CheckboxItem, LinkAmountItem, SvgIcon },
  props: {
    model: Specific,
    list: { type: Array, },
  },
  data() {
    return {
      itemList: []
    };
  },
  methods: {
    initialize() {
      this.sortItemList();
    },
    sortItemList() {

      const enableList = this.list.filter(x => !x.Disable).sort(this.sortBySiteId);
      const disableList = this.list.filter(x => x.Disable).sort(this.sortBySiteId);

      this.itemList = [...enableList, ...disableList];


      // this.itemList.sort(function (a, b) {
      //   if (a.Disable === false && b.Disable === true) {
      //     return -1;
      //   } else if (a.Disable === true && b.Disable === false) {
      //     return 1;
      //   }
      //   // return b.Amount - a.Amount;
      //   return b.SiteId - a.SiteId;
      // });
    },

    sortBySiteId(a, b) {
      if (a.SiteId < b.SiteId) return -1;
      if (a.SiteId > b.SiteId) return 1;
      return 0;
    },

    displayAmount(currency, amount) {
      return `${getCurrencySymbol(currency)} ${amount}`;
    },
    transferClick(v) {
      return;
      this.$emit('click', v);
    }
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[display-accounts-table] { .min-h(75); .br(4); .rel(); .w(100%); .max-h(400); overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .table-header { .ph(20);.pv(10); .bgc(#f8f8f8);}
  .table-body {.flex(); .flex-dc(); .gap(10); overflow-y: auto; .max-h(60%);
    .no-accounts {.min-h(150); .flex(); .justify-center(); .items-center(); .bgc(#fff); .br(16);}
  }
}

</style>
