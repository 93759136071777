<template>
  <SectionContainer referral-step>
    <template #contents>
      <h4>{{ $t('_.start.title') }}</h4>
      <p class="description">
        {{ $t('_.start.description') }}
      </p>
      <div class="step">
        <div class="step-card">
          <div class="icon">
            <FontIcon name="share-nodes" />
          </div>
          <div class="name">{{ $t('_.invitation.title') }}</div>
          <p>{{ $t('_.invitation.description') }}</p>
        </div>
        <div class="step-card">
          <div class="icon">
            <FontIcon name="user2" />
          </div>
          <div class="name">{{ $t('_.signUp.title') }}</div>
          <p>{{ $t('_.signUp.description') }}</p>
        </div>
        <div class="step-card">
          <div class="icon">
            <FontIcon name="file" />
          </div>
          <div class="name">{{ $t('_.verification.title') }}</div>
          <p>{{ $t('_.verification.description') }}</p>
        </div>
      </div>
    </template>
  </SectionContainer>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import SectionContainer from '@/views/components/pages/promotion/v1/SectionContainer.vue';

export default {
  name: 'ReferralStep',
  lexicon: 'promotion.v1.referral',
  components: { SectionContainer, FontIcon },
};

</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
.RTL {
  [referral-step] {
    .step .step-card + .step-card[data-v-531152dc]::before { .l(auto); .r(0); .t-x(50%); }
  }
}
[referral-step] { .pt(20); .pb(60);
  h4 { .c(@c-blurWhite); .fs(22); }
  .description { .mt(8); }
  .step { .flex(); .column-gap(10); .w(); .m(60, -10, 0); .tc();
    .icon { .w(60); .m(0, auto, 24); .br(50%); .bgc(@c-blurRed); .fs(16px); .c(@c-white);
      [font-icon] { .fs(1.375em); .lh(60); }
      [font-icon].icn-user { .fs(1.625em); }
    }
    .step-card { .rel(); .w(calc(100% / 3));
      & + .step-card {
        &::before {
          .cnt(); .abs(); .t(30); .l(0); .calc-w(100%, 80); .h(1); .m-start(-5); .-a(); .-t(@c-grey56, 2); .dashed(); .t-xc();
        }
      }
      .name { .c(@c-blurWhite); .bold(); .mb(6) }
    }
  }

  @media (@ml-up) {
    .step { .column-gap(20); .mh(0);
      .step-card + .step-card::before { .m-start(-10); }
    }
  }

  @media (@tp-up) {
    .step { .column-gap(60);
      .step-card + .step-card::before { .m-start(-30); }
    }
  }
}
</style>
