<template>
  <div questionnaire-survey-modal>
    <div class="content">
        <InputComponent
          v-model="model.SurveyAnswer1"
          name="_first"
          :component="TextareaBox"
          :preset="{ placeholder: $t('_.placeHolder.questionnaire') }"
          :label="$t('_.modal.label.affordabilityQuestion1')"
          rules="required"
          @input="v => payload.SurveyAnswer1 = v"
        />
        <InputComponent
          v-model="model.SurveyAnswer2"
          name="_second"
          :component="TextareaBox"
          :preset="{ placeholder: $t('_.placeHolder.questionnaire') }"
          :label="$t('_.modal.label.affordabilityQuestion2', { dateType: getDateType() })"
          rules="required"
          @input="v => payload.SurveyAnswer2 = v"
        />
        <InputComponent
          v-model="model.SurveyAnswer3"
          name="_third"
          :component="TextareaBox"
          :preset="{ placeholder: $t('_.placeHolder.questionnaire') }"
          :label="$t('_.modal.label.affordabilityQuestion3', { dateType: getDateType() })"
          rules="required"
          @input="v => payload.SurveyAnswer3 = v"
        />
    </div>
  </div>
</template>

<script>
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import Specific from '@shared/types/Specific';
import TextareaBox from '@/views/components/gg-pass/TextareaBox.vue';

export default {
  name: 'QuestionnaireSurveyModal',
  components: { InputComponent },
  lexicon: 'responsibleGaming',
  props: {
    message: { type: String, default: null },
    subMessage: { type: String, default: null },
    calculateList: Specific,
    payload: Specific,
  },
  data(){
    return {
      model: {
        SurveyAnswer1: null,
        SurveyAnswer2: null,
        SurveyAnswer3: null,
      }
    }
  },
  computed: {
    TextareaBox() { return TextareaBox; },
    TextInputBox() { return TextInputBox; }
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    getDateType() {
      const limitTypes = this.calculateList.filter(limit => +limit?.current < +limit?.request).map(limit => this.$t(limit.type));
      return limitTypes.join('/');
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[questionnaire-survey-modal] {
  [gp-input-component] {
    + [gp-input-component] { .mt(20); }
    .holder { .h(); }
  }
}
</style>
