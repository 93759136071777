<template>
  <div v-if="linkedUrl" gp-open-email-app>
    <p class="btn-holder">
      <FontIcon name="envelope-open-light" />
      <a class="open-email" @click="open">{{ $t('openEmailApp') }}</a>
    </p>
  </div>
</template>

<script>
import { getCookie } from '@shared/utils/cookieUtils.mjs';
import FontIcon from '@shared/components/common/FontIcon.vue';
import emailList from '@/constants/base/email';
import { openEmailApp } from '@/utils/clientUtil';

export default {
  name: 'OpenEmailAppButton',
  components: { FontIcon },
  props: {
    type: { type: String, default: 'light-red' },
    email: { type: String, default: null },
  },
  data() {
    return {
      isFromMobileApp: getCookie('isFromMobileApp') || this.$route.query.isFromMobileApp,
      isFromClientApp: getCookie('isFromClientApp') || this.$route.query.isFromClientApp,
    };
  },
  computed: {
    emailDomainList() {
      return emailList;
    },
    linkedUrl() {
      const userEmail = this.email;
      if (!userEmail) return;

      let url;
      const [id, domain] = userEmail.split('@');
      const findDomain = this.emailDomainList.find(email => email.label === domain);

      if (findDomain) url = findDomain.value;
      else url = null;

      return url;
    }
  },
  methods: {
    async open() {
      await openEmailApp(this, this.email);
    },
  }
};
</script>

<style lang="less" scoped>
@import '~@/less/proj.less';
[gp-open-email-app] {.flex(); .items-end();.justify-center();
  .btn-holder {.flex();.gap(10); .items-center(); margin-bottom: 0px !important; .fs(16);}
}
</style>
