<template>
  <JumioContainer jumio-processing type="processing">
    <h3>{{ $t('_.title') }}</h3>
    <div class="desc">
      <p>
        {{ title }}<br />
        {{ $t('_.desc.pleaseWaitForResult') }}
      </p>
    </div>
  </JumioContainer>
</template>

<script>
import JumioContainer from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioContainer.vue';

export default {
  name: 'JumioProcessing',
  lexicon: 'onBoarding.jumio.processing',
  components: { JumioContainer },
  props: {
    step: { type: String },
  },
  computed: {
    title() {
      return this.$t(`_.desc.verificationBeingProcessed.${this.step.toLowerCase()}`);
    }
  }
};
</script>

<style lang="less" scoped>
@import '~@/less/proj.less';
[jumio-processing] {}
</style>