<template>
  <button promotion-button :class="type" :disabled="disabled" @click="$emit('click', $event)">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'PromotionButton',
  props: {
    type: { type: String, default: 'red' },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[promotion-button] { .fs(16); .p(4, 12); .br(2em); .c(@c-white);
  &.red { .bgc(#c64040);
    &:not(:disabled) {
      &:hover, &:active, &:focus { .bgc(#b13232); }
    }
  }
  &.gray { .bgc(@c-gray3);
    &:not(:disabled) {
      &:hover, &:active, &:focus { .bgc(@c-gray4); }
    }
  }
  &:disabled { .o(0.35); cursor: default; }
}
</style>
