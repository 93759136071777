<template>
  <div verify-citizenship-information>
    <div class="icon-holder">
      <FontIcon name="shield-checker" />
    </div>
    <div class="title-holder">
      <p v-html="$t('_.informationTitle')" />
    </div>
    <p class="description" v-html="$t('_.informationDescription')" />
    <div class="contents-holder">
      <div class="sub-holder">
        <label class="label" v-html="$t('_.informationSubTitle')" />
        <label class="citizenship" v-html="citizenship" />
      </div>
      <div v-if="!existPersonalDetail" class="contents">
        <FontIcon name="check-circle" />
        <div>
          <label v-html="$t('_.informationPdTitle')" />
          <ul>
            <li v-html="$t('_.informationPdItem1')" />
            <li v-html="$t('_.informationPdItem2')" />
          </ul>
        </div>
      </div>
      <div class="contents">
        <FontIcon name="check-circle" />
        <div>
          <label v-html="$t('_.informationCdTitle')" />
          <ul>
            <li v-html="$t('_.informationCdItem1')" />
            <li v-html="$t('_.informationCdItem2')" />
          </ul>
        </div>
      </div>
    </div>
    <div class="button-holder">
      <ProgressButton class="proceed" button-name="proceed" @click="proceed" />
      <ProgressButton class="cancel" button-name="cancel" @click="cancel" />
    </div>
    <p class="footer" v-html="$t('_.informationFooter')" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { close } from '@/utils/clientUtil';
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';

export default {
  name: 'VerifyCitizenshipInformation',
  lexicon: 'onBoarding.citizenship',
  components: { FontIcon, ProgressButton },
  mixins: [baseTemplate],
  computed: {
    citizenshipCode: state('query', 'citizenshipCode'),
    citizenship() {
      return this.$t(`country.${this.citizenshipCode}`);
    },
    existPersonalDetail() {
      return (this.modelInfo.FirstName && this.modelInfo.LastName && this.modelInfo.DateOfBirth && this.modelInfo.Gender);
    },
  },
  methods: {
    proceed() {
      this.structure.customSteps = this.existPersonalDetail ? [routeNames.poc, routeNames.reviewingDocuments] : [routeNames.personalName, routeNames.personalBirth, routeNames.poc, routeNames.reviewingDocuments];
    },
    cancel() {
      close(this);
    },
  },
  mounted() {
    this.item.title = '';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-citizenship-information] {
  .icon-holder { .mt(28); .tc();
    [font-icon] { .fs(40); }
  }
  .title-holder { .mv(28); .tc();
    p {  .fs(32); .semi-bold(); }
  }
  .description { .mt(12); .c(#9d9e9e); }
  .contents-holder { .p(20, 0, 0, 0) !important;
    .sub-holder { .flex(); .space-between(); .fs(20); .bold(); .mb(20);
      .label { .c(#ffffff); }
      .citizenship { .c(#7a7a7a); }
    }
    .contents { .flex(); .p(12); .br(4); .bgc(#2a2a2a);
      [font-icon] { .c(#929292); .fs(20); }
      div { .ml(8); .fs(14, 20);
        label { .block(); .c(#f2f2f2); .semi-bold(); }
        ul { .c(#aaaaaa);
          >li { .keep-all(); .ml(4); .pl(12); .rel();
            &:before { .cnt(); .wh(4); .br(50%); .bgc(#aaaaaa); .abs(); .lt(0,8); }
          }
        }
      }
    }
    .contents + .contents { .mt(12); }
  }
  [progress-button].cancel { .mv(16); .bgc(#515151);
    @{hover-press} { .bgc(#515151); }
  }
  .footer { .tc(); .c(#9f9f9f); }
}
</style>