<template>
  <div reaffirm-de>
    <ReaffirmDeInformation v-if="!customSteps" :model-info="modelInfo" :structure="structure" :item="reaffirmItem" />
    <template v-else>
      <component :model-info="modelInfo" :structure="structure" :is="component" :template="template" :item="reaffirmItem" :title="title" :button-name="buttonName" />
      <template v-if="!background">
        <p v-if="errorMsg" class="error">{{ errorMsg }}</p>
        <ProgressButton v-if="!isComplete" :button-name="buttonName" :progress="progress" :disabled="structure.error || Boolean(errorMsg) || invalid" @click="confirm" />
      </template>
    </template>
  </div>
</template>

<script>

import { state } from '@shared/utils/storeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';
import Specific from '@shared/types/Specific';
import ReaffirmDeInformation from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/de/ReaffirmDeInformation.vue';
import ReaffirmDeAddress from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/de/ReaffirmDeAddress.vue';
import ReaffirmDeContactEmail from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/de/ReaffirmDeContactEmail.vue';
import ReaffirmDeVerifyForm from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/de/ReaffirmDeVerifyForm.vue';
import ReaffirmDeKycDe from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/de/ReaffirmDeKycDe.vue';
import ReaffirmDeComplete from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/de/ReaffirmDeComplete.vue';
import VerifyJumio from '@/views/components/pages/on-boarding/template/VerifyJumio.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
export default {
  name: 'ReaffirmDe',
  lexicon: 'onBoarding.reaffirm.de',
  components: { ProgressButton, ReaffirmDeInformation },
  mixins: [baseTemplate],
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      progress: false,
      templates: [
        { component: ReaffirmDeContactEmail, route: routeNames.verifyContactEmail, service: 'verification', api: 'sendVerification', title: 'onBoarding.reaffirm.de.title', action: 'EmailVerifySendCode' },
        { component: ReaffirmDeVerifyForm, route: routeNames.verifyCode, service: 'verification', api: 'verifyCode', title: 'onBoarding.reaffirm.de.title', background: true },
        { component: ReaffirmDeAddress, route: routeNames.personalAddress, service: 'onboarding', api: 'editPersonalAddress', title: 'onBoarding.title.address', background: true },
        { component: VerifyJumio, route: routeNames.jumio, api: '', title: 'onBoarding.reaffirm.de.title', background: true },
        { component: VerifyJumio, route: routeNames.showReviewingKycMessage, api: '', title: 'onBoarding.title.reviewingDocuments', background: true },
        { component: ReaffirmDeKycDe, route: routeNames.kycDE, api: '', background: true },
      ],
      captcha: null,
    };
  },
  watch: {
    template() {
      this.item.title = this.title;
    }
  },
  computed: {
    env: state('env', 'env'),
    csEmail: state('env', 'csEmail'),
    step() {
      return this.structure.step;
    },
    reaffirmItem() {
      return this.structure?.list[this.step];
    },
    template() {
      return this.templates.find(o => o.route === this.customSteps?.[this.customStep]);
    },
    customStep() {
      return this.structure.customStep;
    },
    customSteps() {
      return this.structure.customSteps;
    },
    lastStep() {
      return this.customStep >= this.customSteps?.length - 1;
    },
    component() {
      return this.template?.component ?? ReaffirmDeComplete;
    },
    api() {
      return this.template?.api;
    },
    service() {
      return this.template?.service;
    },
    title() {
      return this.template?.title;
    },
    background() {
      return this.template?.background;
    },
    isProd() {
      return this.env === 'production';
    },
    isComplete() {
      return this.component === ReaffirmDeComplete;
    },
    buttonName() {
      if (this.component === ReaffirmDeContactEmail) return 'send';
      return this.lastStep ? 'submit' : 'next';
    },
    action() {
      return this.template?.action || null;
    }
  },
  methods: {
    nextStep() {
      this.structure.customStep++;
    },
    async confirm() {
      await this.apiRequest();
    },
    async loadCaptcha() {
      this.captcha = {};
      this.captcha = this.isMBP ? await this.$services.captcha.setGeeCaptcha() : await this.$services.captcha.setRecaptcha(this.action);
    },
    async apiRequest() {
      if (!this.api) {
        this.nextStep();
      } else {
        this.progress = true;
        const r = await this.getApiResponse();
        this.progress = false;
        if (r?.error) {
          const replace = this.$onboarding.errorHandler(r);
          if (this.$onboarding.errorHandler(r, true)) return;
          if (r?.key === 'limit') {
            this.structure.limitTime = +r?.value;
            this.nextStep();
            return;
          }
          this.structure.error = true;
          this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail });

          if (!this.isProd) {
            console.log(`Api Request Error : onboarding.${this.api}`, r);
            this.$toast(r.desc, { type: 'fail', translate: false });
          }
        } else {
          this.captcha = null;
          await this.nextStep();
        }
      }
    },
    async getApiResponse() {
      try {
        if (this.action) await this.loadCaptcha();
        return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services[this.service][this.api]({ ...this.structure.model, ...this.captcha });
      } catch (e) { return e; }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reaffirm-de] {
  p { .fs(16); }
}
</style>
