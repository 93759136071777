<template>
  <div fi-complete :class="['field-wrapper']">
    <div class="wrapper">
      <FontIcon :name="icon" />
      <p class="desc" v-html="desc"></p>
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { brandIds, displayBrandNames } from '@/constants/base/siteMap.mjs';
export default {
  name: 'FiComplete',
  components: { FontIcon },
  props: {
    structure: Specific,
    modelInfo: Specific,
  },
  computed: {
    brand: state('env', 'brand'),
    icon() {
      return 'check-circle-regular';
    },
    desc() {
      return this.$t('findId.description.successDesc', { email: this.modelInfo.FoundLoginId, brand : this.displayBrandName });
    },
    btnName() {
      return 'playNow';
    },
    displayBrandName() {
      const key = Object.keys(brandIds).find(key => brandIds[key] === this.brand);
      return this.brandNames[key];
    },
    brandNames(){
      return displayBrandNames
    },
  },
  methods: {
    action() {
    }
  },
  mounted() {
    this.structure.title = null;
    this.structure.desc = null;
    this.structure.back = false;
    this.structure.complete = true;
  },
  beforeDestroy() {
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fi-complete] { .tc(); .rel();
  .wrapper { .abs(); .lt(50%,50%); .t-xyc(); .mt(-40); .w(100%);
    [font-icon] { .block(); .fs(60); .c(#1DB77D); }
    .desc { .mt(40); .w(100%);
      em { .c(white); }
    }
  }
}
</style>