<template>
  <span checkbox-item @click="click" :class="['no-drag', theme,  icon , {disabled}]">
    <em class="ltx" v-if="!left"><slot /></em>
    <i :class="{ checked: !checked }" v-if="reverse"><SvgCheck v-if="theme !== 'tree'" /><SvgMinus v-else /></i>
    <i :class="{ checked }" v-else><SvgCheck v-if="theme !== 'tree'" /><SvgPlus v-else /></i>
    <em class="rtx" v-if="left"><slot /></em>
  </span>
</template>

<script>

import SvgIcon from '@shared/components/common/SvgIcon.vue';
import SvgCheck from '@shared/svg/SvgCheck.vue';
import SvgMinus from '@shared/svg/SvgMinus.vue';
import SvgPlus from '@shared/svg/SvgPlus.vue';

export default {
  name: 'CheckboxItem',
  components: { SvgPlus, SvgMinus, SvgCheck },
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    selected: {},
    value: {},
    // /** @type {'big' | 'small' | 'tree'} */
    theme: { type: String, default: 'small' },
    reverse: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    left: { type: Boolean, default: true },
  },
  data() {
    return {};
  },
  computed: {
    checked() {
      return Array.isArray(this.selected) ? this.selected.indexOf(this.checkedValue) !== -1 : this.selected === this.checkedValue;
    },
    checkedValue() {
      return this.value ?? true;
    },
    uncheckedValue() {
      return this.value ? null : false;
    },
    icon() {
      if (this.theme === 'tree') return this.checked ? 'plus' : 'minus';
      return 'check';
    },
  },
  methods: {
    click(e) {
      e.stopImmediatePropagation();

      let result;
      if (this.disabled || e.target.tagName === 'A') return;
      if (Array.isArray(this.selected)) {
        result = this.selected;
        if (this.checked) {
          result.splice(this.selected.indexOf(this.checkedValue), 1);
        } else {
          this.selected.push(this.checkedValue);
        }
      } else {
        result = this.checked ? this.uncheckedValue : this.checkedValue;
      }
      this.$emit('change', result);
      if (this.$parent.validate) this.$parent.validate(result);
    },
  },
  mounted() {
  }
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[checkbox-item] { .block(); .rel(); .-box();.pointer; .mr(8); .max-h(100%); .flex();
  em { .ml(8); .ellipsis(); .max-w(calc(100% - 22px));
    &.ltx { .mr(8); }
  }
  &.small, &.tree { .items-center(); .flex();
    &.disabled { .o(0.5); cursor: default;
      i {
        svg { .o(.2); }
      }
    }
    i { .wh(14); .-a(rgba(0, 0, 0, 0.5)); .br(2);; .rel(); .mt(2); .block(); .bgc(white);
      svg { .abs(); .wh(10, 10); .lt(50%, 50%); .t-xyc();
        path { .fill(@c-b05); }
      }
      &.checked { .bgc(@c-mint);
        svg path { .fill(white); }
      }
    }
  }
  &.dark { .items-start();
    em { .max-w(calc(100% - 24px)); }
    i { .wh(16); .-a(#f2f2f2); .bgc(transparent); .mt(4);
      svg { .wh(14); .hide();
        path { .fill(trasparent); }
      }
      &.checked { .bgc(#f2f2f2);
        svg { .block();
          path { .fill(#222); }
        }
      }
    }
    em { .fs(14); }
  }
  &.big {
    i { .wh(20); .br(50%); .bgc(@c-b07); .ib(); .vam();
      svg { .mh-c; .block; .wh(10, 10);
        path { .fill(@c-placeholder)}
      }
      &.checked { .bgc(@c-mint);
        svg path { .fill(white); }
      }
    }
  }
  &.default { .h(16);
    i {.ib; .wh(16); .-a(rgba(255, 255, 255, 0.5)); .br(3); .-a(@c-title-black); .pt(2); .o(.5);
      svg { .wh(10); .block; .mh-c;
        .fill-target { fill: transparent; }
      }
      &.checked { .bgc(rgba(255, 255, 255, 0.8)); .o(1);}
      &.checked svg .fill-target { fill: #2c81ff; }
    }
  }

  &.light { .h(16); .mr(0);
    i {.ib; .wh(16); .-a(#ccc); .br(3); .pt(2); .o(.5); .bgc(white);
      svg { .wh(10); .block; .mh-c; visibility: hidden;
        .fill-target { fill: transparent; }
      }
      &.checked { .bgc(rgba(255, 255, 255, 0.8)); .o(1); .-a(#249135) }
      &.checked svg {
        visibility: visible;
        .fill(#249135);
        .fill-target { fill: #249135; }
      }
    }
  }
}
</style>
