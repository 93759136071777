<template>
  <GpFormTemplate edit-bank-info>
    <template #header>
      <GpDescription :message="description" />
    </template>
    <template #content>
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click="submit(validate)" :disabled="!!invalid || isApiError">{{ $t('save') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import npErrorHandler from '@/mixins/my-info/npErrorHandler';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';

export default {
  name: 'NpAutoLoginSetting',
  components: { GpDescription, GpTitle, GpFormTemplate, GpButton },
  mixins: [npErrorHandler],
  props: {
    structure: { type: Specific, default: null },
    model: Specific,
  },
  data() {
    return {
      bankInfo: {
        BankCountry: null,
        IBAN: null,
        BankName: null,
        BankSwiftBic: null,
        CountryCode: null,
        CountryWritingSystem: null,
      }
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    description() {
      return this.$t('onBoarding.surepayStepDescription', { csEmail: this.csEmail });
    },
  },
  methods: {
    async initialize() {
      const result = this.structure.current;
      const { CountryWritingSystem, Country } = result;

      this.bankInfo.CountryWritingSystem = CountryWritingSystem;
      this.structure.title = this.$myinfo.title;

      this.bankInfo.CountryCode = Country;
      this.bankInfo.IBAN = result?.PlayerBankInfo?.IBAN;

    },
    async submit(validate) {
      if (validate) await validate();
      const result = await this.$myinfo.request(this.bankInfo);

      if (result.error) {
        this.apiErrorHandler(result);
        return;
      }

      this.$emit('success', 'myInfo.commonSuccess');
    },
  },

  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[edit-bank-info] { .pt(30);
  .password-input-group { .bgc(#f8f8f8);.p(20, 15, 20, 15); .br(4); .flex(); .flex-dc(); .gap(15); .w(100%);}
}

</style>