<template>
  <SectionContainer complete-all-challenge>
    <template #contents>
      <h4>{{ $t('_.completeAllChallenges') }}</h4>
      <p>{{ rewardCash }} {{ $t('_.instantBonus') }}!</p>
      <ProgressBar :status-info="statusInfo" :complete-all="true" />
    </template>
  </SectionContainer>
</template>

<script>
import SectionContainer from '@/views/components/pages/promotion/v1/SectionContainer.vue';
import Specific from '@shared/types/Specific';
import ProgressBar from '@/views/components/pages/promotion/v1/ProgressBar.vue';

export default {
  name: 'CompleteAllChallenge',
  lexicon: 'promotion.v1',
  components: { ProgressBar, SectionContainer },
  props: {
    statusInfo: { /** @type {PromotionStatus} */ type: Specific, required: true },
  },
  computed: {
    challenge() {
      return this.statusInfo.item?.dailyChallenges?.[0] || {};
    },
    rewardCash() {
      return this.$promotion.getCurrencyString(this.challenge.completionRewardInstantCashAmountCurrency, this.challenge.completionRewardInstantCashAmount);
    }
  },
};
</script>

<style lang="less">
@import "~@/less/promotion.less";
[complete-all-challenges] {
  .bar-chart .bar { background: linear-gradient(to left, @c-darkSand, @c-chestnutBrown); }
}
</style>
<style lang="less" scoped>
@import "~@/less/promotion.less";
[complete-all-challenge] { .bgc(@c-black);
  h4 { .medium() }
  p { .mt(6); .c(@c-darkSand); }
}
</style>
