<template>
  <section section-container>
    <slot name="title" />
    <slot name="contents" />
  </section>
</template>

<script>
export default {
  name: 'SectionContainer',
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[section-container] { .wf(); .p(0, 20, 20); .bgc(@c-bg);
  h3 { .flex(); .items-center(); .gap(8); .regular(); .fs(16); .c(@c-veryLightGrey); }
  h3, .section-top { .pv(16); }
  .section-top h3 { .pv(0); }
}
</style>
