<template>
  <GpFormTemplate login-time-limit>
    <template #header>
      <GpDescription v-if="description" :message="description" />
      <CautionMessage :message="$t('_.caution.gameLimit')" />
      <GpDescription v-if="structure.config.verifyFreeze" :message="$t('responsibleGaming.verifyFreeze.message')" />
    </template>
    <template #content>
      <InputReLoginTimeLimit v-if="load" :model="model" />
    </template>
    <template #footer="{validate}">
      <GpButton type="submit-green" @click="openModal" :disabled="disabled">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import InputReLoginTimeLimit from '@/views/components/pages/responsible-gaming/template/inputs/InputReLoginTimeLimit.vue';
import CautionMessage from '@/views/components/pages/responsible-gaming/template/common/CautionMessage.vue';
import ResponsibleProgressModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleProgressModal.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
export default {
  name: 'RgReLoginTimeLimit',
  lexicon: 'responsibleGaming',
  components: { InputReLoginTimeLimit, CautionMessage, GpFormTemplate, GpDescription, GpButton },
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      errorMsg: null,
      current: {
        CurrentReLoginTimeLimit: null,
        ReLoginTimeLimitApplyAt: null,
      },
      model: {
        RequestReLoginTimeLimit: null,
      },
      load: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    calculateList() {
      return [
        { type: 'default', current: this.current.CurrentReLoginTimeLimit, request: this.model.RequestReLoginTimeLimit, noLimit: this.noLimit, valueType: 'min' },
      ];
    },
    noLimit() {
      return this.model.RequestReLoginTimeLimit === null;
    },
    disabled() {
      const request = this.model.RequestReLoginTimeLimit;
      const current = this.current.CurrentReLoginTimeLimit ? this.current.CurrentReLoginTimeLimit.toString() : this.current.CurrentReLoginTimeLimit;

      return current === request;
    }
  },
  methods: {
    initialize() {
      this.current = {
        ...this.current,
        ...this.structure.current,
      };
      this.model.RequestReLoginTimeLimit = this.current?.CurrentReLoginTimeLimit ? this.current?.CurrentReLoginTimeLimit.toString() : 'None';
      this.load = true;
    },
    createModalOptions() {
      const desc = this.structure.config.verifyFreeze ? 'responsibleGaming.modal.description.verifyFreeze' : 'responsibleGaming.modal.description.selfLimit';
      const descModel = {
        desc: desc,
        hangTime: 'responsibleGaming.modal.description.hangTimeLimit',
      };

      return {
        title: { default: this.$t('_.modal.title') },
        subTitle: { default: this.$t(`_.modal.subTitle.${this.structure.config.title}`) },
        modalType: 'HangTime',
        request: () => this.$rg.request(this.model),
        calculateList: this.calculateList,
        descModel,
        isVerifyFreeze: this.structure.config.verifyFreeze,
        config: this.structure.config
      };
    },

    openModal() {
      this.$modal(ResponsibleProgressModal, this.createModalOptions());
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[login-time-limit] {
  .limit-input-group {.flex(); .flex-dc(); .gap(15); .br(4); .bgc(#f8f8f8); .p(20)}
}

</style>