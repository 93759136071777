<template>
  <div rg-display-external-monthly-modal>
    <div class="content">
      <GpDescription :message="description" />
      <div v-if="current" class="item">
        <p class="label">{{ $t('responsibleGaming.modal.label.currentLimit') }}</p>
        <p class="amount">{{ current }}</p>
      </div>
      <div v-if="limit" class="item border">
        <p class="label">{{ $t('responsibleGaming.modal.label.newLimit') }}</p>
        <p class="amount">{{ limit }}</p>
      </div>
      <div v-if="date" class="item">
        <p class="label">{{ $t('responsibleGaming.modal.label.avaliableDate') }}</p>
        <p class="amount">{{ date }}</p>
      </div>
      <div class="footer">
        <GpButton type="cancel-gray" @click="close">{{ $t('close') }}</GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import Specific from '@shared/types/Specific';
import { routeNames } from '@/constants/model/responsible-gaming';
export default {
  name: 'DisplayExternalMonthlyModal',
  components: { GpDescription, GpButton },
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
    data: Specific
  },
  data() {
    return {
      current: null,
      limit: null,
      date: null,
    };
  },
  computed: {},
  methods: {
    close() {
      this.$emit('close');
      this.routeName('ResponsibleGaming');
    },
  },
  mounted() {
    if (this.data) {
      this.current = this.data?.current;
      this.limit = this.data?.limit;
      this.date = this.data?.date;
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-display-external-monthly-modal] {
  .content {
    [gp-description] {
      > p {.pt(0); .fs(14); text-align: left;}
    }
    .item {.p(10); .c(black);
      .label {.fs(14);}
      .amount {.fs(12); .c(#999);}
      &.border {border-bottom: 1px solid #DDDDDD; border-top: 1px solid #DDDDDD; }
    }
  }
  .body {
    > p {.c(black);}
  }
  .footer {.flex(); .gap(10);}
}
</style>