<template>
  <div free-bonus-challenge v-if="challenge">
    <div class="current">
      <div class="info">
        <p class="day">
          <b>{{ $t('_.day', { day: challenge.currentDayNumber }) }}</b>
          <span>{{ periodString }}</span>
        </p>
        <p>
          <span class="name">{{ $t('_.gameType') }}</span>
          <span class="text">{{ gameType }}</span>
        </p>
        <p>
          <span class="name">{{ $t(`_.${currentInfo.name}`) }}</span>
          <span class="text">{{ currentInfo.value }}</span>
        </p>
      </div>
      <div :class="['hands', challenge.gameType]">
        <p>
          <span class="name">{{ $t('_.dailyChallenge') }} : </span>
          <span class="text" v-html="$t(gameType === 'AoF' ? '_.playAofCount' : '_.playSpinAndGoldCount', {currentCount: Math.min(challenge.currentHandCount, challenge.handRequirements), handsCount: challenge.handRequirements})" />
        </p>
        <ProgressBar :status-info="statusInfo" />
      </div>
    </div>
    <div class="slider" v-if="itemList.length">
      <Slider ref="refSlider" class="challenges" :info="itemList" slides-per-view="auto" @initialized="goCurrencyIndex">
        <template v-slot:slide="{ info }">
          <FirstDepositChallengeCard :challenge-info="info" v-if="firstDepositBonus" />
          <DailyChallengeCard :challenge-info="info" v-else />
        </template>
      </Slider>
    </div>
  </div>
</template>

<script>
import Slider from '@shared/components/general/Slider.vue';
import Specific from '@shared/types/Specific';
import DailyChallengeCard from '@/views/components/pages/promotion/v1/DailyChallengeCard.vue';
import ProgressBar from '@/views/components/pages/promotion/v1/ProgressBar.vue';
import FirstDepositChallengeCard from '@/views/components/pages/promotion/v1/FirstDepositChallengeCard.vue';
import { toDateT, toDurationDays } from '@/utils/dateTimeUtil';

export default {
  name: 'FreeBonusChallenge',
  lexicon: 'promotion.v1',
  components: { FirstDepositChallengeCard, ProgressBar, DailyChallengeCard, Slider },
  props: {
    statusInfo: { /** @type {PromotionStatus} */ type: Specific, required: true },
  },
  computed: {
    challenge() {
      return this.statusInfo?.item?.dailyChallenges?.[0] || {};
    },
    currentIndex() {
      const index = (this.challenge.currentDayNumber ?? 0) - 1;
      return Math.max(index, 0);
    },
    firstDepositBonus() {
      return this.statusInfo.item?.name === 'First Deposit Bonus';
    },
    itemList() {
      return this.challenge?.items || [];
    },
    periodString() {
      const start = this.challenge.challengeBeginDate;
      const end = this.challenge.challengeEndDate;
      const locale = this.$i18n('locale');
      if (toDurationDays(start, end) < 1) return '-';

      return `( ${toDateT(start, locale)} - ${toDateT(end, locale)} )`;
    },
    gameType() {
      return (this.challenge?.gameType || '').replace(/And/, ' & ');
    },
    currentInfo() {
      /** desc: 해당 부분이 어디인지 몰라서 테스트 못해봄 */
      const { firstDepositBonus, statusInfo, challenge } = this;
      const name = firstDepositBonus ? 'ticketValue' : 'bonusAmount';
      const currency = firstDepositBonus ? statusInfo.promotionCurrency : challenge.bonusAmountCurrency;
      const value = firstDepositBonus ? statusInfo.exchangeRate * challenge.ticketValue : challenge.bonusAmount;

      return { name, value: this.$promotion.getCurrencyString(currency, value) };
    },
  },
  methods: {
    goCurrencyIndex() {
      this.$refs.refSlider.go(this.currentIndex);
    },
  },
};
</script>

<style lang="less">
@import "~@/less/promotion.less";
[free-bonus-challenge] {
  [slider] {
    .swiper-container { .wf(); }
    .swiper-wrapper { .min-w(890); .grid(); grid-template-columns: repeat(6, 1fr);}
    .swiper-slide { .w(); .min-w(140);
      &:not(:nth-of-type(1)) { .m-start(8); }
    }
  }
  [progress-bar] { .mt(30);
    .end { .fs(1em); }
  }

  @media (@tp-up) {
    [progress-bar] {
      .end { .fs(1.125em); }
    }
  }
}
</style>
<style lang="less" scoped>
@import "~@/less/promotion.less";
[free-bonus-challenge] { .bgc(@c-black);
  .current { .flex(); .flex-dc(); .-b(@c-w02);
    .info, .hands { .p(20);
      + div { .-t(@c-w02); }
      > p { .flex-wrap();
        span { .nowrap(); }
        .name { .c(@c-lightGrey); }
      }
    }
    .info { .min-w(300);
      .day { .flex-wrap(); .items-center(); .gap(8); .mb(16);
        span { .medium(); .fs(0.8em); .c(@c-dimGrey); }
      }
      p:not(.day) { .mb(8);
        .name { flex: 0 0 120px; }
      }
    }
    .hands { flex: 1; .flex(); .flex-dc(); .space-between(); .pt(16);
      &.AoF {
        .text {
          > ::v-deep em { .c(@c-blurRed); }
        }
      }
      > p { .lh(1.8em); .gap(16); }
    }
  }
  .slider { .crop(); .p(20); }

  @media (@tp-up) {
    .current { .flex-row();
      .info, .hands {
        + div { .-t(); .-start(@c-w02); }
      }
    }
  }
}
</style>
