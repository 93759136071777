<template>
  <GpFormTemplate login-security>
    <template #content>
      <div class="logout-device">
        <GpTitle :name="$t('myInfo.title.logoutAllDevices')" />
        <div class="item">
          <label>{{$t('myInfo.myDevices.deviceLabel')}}</label>
          <GpButton type="line-green" size="sm" @click="logoutDevices">{{ $t('logout') }}</GpButton>
        </div>

      </div>
      <div class="security-level-group">
        <GpTitle :name="securityLabel" />
        <InputSecurityLevel :model="model" :current-mfa="currentMfa" @initMethod="initMethod" />
      </div>
      <div v-if="isSecuritySelected" class="security-method-group">
        <GpTitle v-if="isSecuritySelected" :name="methodLabel" />
        <InputSecurityMethod :model="model" :authInfo="authInfo" :userInfo="userInfo" :current-mfa="currentMfa" />
      </div>
    </template>
    <template #footer="{validate}">
      <GpButton type="submit-green" @click="submit(validate)" :progress="!isLoad" :disabled="buttonDisabled">{{ $t('confirm') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { mfaLevel, mfaMethod, STEP } from '@/constants/base/my-page';
import MfaGoogleModal from '@/views/components/pages/my-page/modal/MfaGoogleModal.vue';
import SecurityLevelRadioItem from '@/views/components/pages/my-page/template/login-securty/SecurityLevelRadioItem.vue';
import SecurityMethodRadioItem from '@/views/components/pages/my-page/template/login-securty/SecurityMethodRadioItem.vue';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import Specific from '@shared/types/Specific';
import InputSecurityLevel from '@/views/components/pages/my-page/template/inputs/InputSecurityLevel.vue';
import InputSecurityMethod from '@/views/components/pages/my-page/template/inputs/InputSecurityMethod.vue';
import LogoutDevicesModal from '@/views/components/pages/my-page/modal/LogoutDevicesModal.vue';

export default {
  name: 'NpLoginSecurity',
  lexicon: 'myInfo',
  components: { InputSecurityMethod, InputSecurityLevel, GpTitle, GpButton, GpFormTemplate },
  props: {
    structure: { type: Specific, default: null },
    model: Specific,
  },
  data() {
    return {
      errorMsg: null,
      isVerifyGoogleAuthentication: false,
      userInfo: null,
      mfa: null,
      currentMfa: null,
      authInfo: {
        EmailAuthenticated: false,
        Email: '',
        MobileAuthenticated: false,
        MobileNumber: '',
        GoogleAuthenticated: false,
        Google: '',
      },
      isLoad: false,
      SecurityLevelRadioItem,
      SecurityMethodRadioItem
    };
  },
  computed: {
    site: state('env', 'site'),
    onePassInfo: state('user', 'onePassInfo'),
    myInfo: state('user', 'userInfo'),
    methodLabel() {
      let key = this.model.SecurityLevel === mfaLevel.LOW ? '_.mfa.notificationMethod' : '_.mfa.mfaAuthentication';
      return this.$t(key);
    },
    securityLabel() {
      return this.$t('_.mfa.securityLevel');
    },
    buttonDisabled() {
      if (!this.currentMfa) return true;
      if (this.model.SecurityLevel === this.currentMfa.SecurityLevel && this.model.VerificationMethod === this.currentMfa.VerificationMethod) {
        return true;
      }
      return false;
    },
    isSecuritySelected() {
      return this.model.SecurityLevel !== mfaLevel.OFF;
    },
    isAuthentication() {
      switch (this.model.VerificationMethod) {
        case mfaMethod.EMAIL :
          return this.authInfo.EmailAuthenticated;
        case mfaMethod.MOBILE :
          return this.authInfo.MobileAuthenticated;
        case mfaMethod.GOOGLE  :
          return this.isOpenGoogleModal;
        default :
          return true;
      }
    },
    isOpenGoogleModal() {
      if (this.isGGPass) return this.authInfo.GoogleAuthenticated;
      return (this.isVerifyGoogleAuthentication || this.mfa.VerificationMethod === mfaMethod.GOOGLE);
    },
    isGGPass() {
      return !isExcludeGgpass(this.site);
    }
  },
  methods: {
    async initialize() {
      this.$emit('changeStep', STEP.DEFAULT);
      const { myInfo, mfaConfig } = await this.$myinfo.fetch({ payload: null, block: true });
      this.userInfo = myInfo;
      this.mfa = mfaConfig;
      if(!this.isGGPass) this.currentMfa = mfaConfig;
      else {
        this.currentMfa = {}
      }
      this.ggpassInitialize();
      this.npInitialize();
    },

    ggpassInitialize() {
      if (!this.isGGPass) return;
      const mfa = this.mfa.find(m => m.isEnabled);

      if (!mfa) {
        this.model.SecurityLevel = mfaLevel.OFF;
        this.model.VerificationMethod = null;
      } else {
        this.model.SecurityLevel = mfaLevel[mfa.level];
        this.model.VerificationMethod = mfaMethod[mfa.methodType];
      }

      this.currentMfa.SecurityLevel =this.model.SecurityLevel;
      this.currentMfa.VerificationMethod =this.model.VerificationMethod;

      const email = this.mfa.find(m => m.methodType === 'EMAIL');
      const mobile = this.mfa.find(m => m.methodType === 'MOBILE');
      const google = this.mfa.find(m => m.methodType === 'GOOGLE');

      this.authInfo = {
        ...this.authInfo,
        EmailAuthenticated: email ? email.isVerified : false,
        Email: email ? email?.methodValue : null,
        MobileAuthenticated: mobile ? mobile.isVerified : false,
        MobileNumber: mobile ? mobile?.methodValue : null,
        GoogleAuthenticated: google ? google.isVerified : false,
        Google: google ? google?.methodValue : null,
      };
    },

    npInitialize() {
      if (this.isGGPass) return;
      this.model.SecurityLevel = this.mfa.SecurityLevel;
      this.model.VerificationMethod = this.mfa.VerificationMethod;
      this.model.UsualLoginSecurity = this.mfa.UsualLoginSecurity;
      this.model.UnusualLoginSecurity = this.mfa.UnusualLoginSecurity;

      this.authInfo = {
        ...this.authInfo,
        EmailAuthenticated: this.userInfo.IsEmailVerified,
        Email: this.userInfo.Email,
        MobileAuthenticated: this.userInfo.IsMobileVerified,
        MobileNumber: this.userInfo.MobileNumber,
        GoogleAuthenticated: false,
        Google: '',
      };
    },

    initMethod() {
      if(this.model.SecurityLevel === this.currentMfa?.SecurityLevel){
        this.model.VerificationMethod = this.currentMfa?.VerificationMethod;
      }else this.model.VerificationMethod = mfaMethod.EMAIL;
    },

    async setGoogleVerifyAuthentication() {
      this.isVerifyGoogleAuthentication = true;
      this.authInfo.GoogleAuthenticated = true;

      await this.submit();
      this.$modalCloseAll();
    },
    async mfaAuthenticatedAction() {
      switch (this.model.VerificationMethod) {
        case mfaMethod.EMAIL :
          await this.routeName('NpMfaEmailAddress');
          return;
        case mfaMethod.MOBILE : {
          await this.routeName('NpMfaMobileNumber');
          return;
        }
        case mfaMethod.GOOGLE : {
          this.$modal(MfaGoogleModal, { ...this.model, successGoogleVerify: this.setGoogleVerifyAuthentication, model: this.model });
          return;
        }
      }
    },

    async submit(validate) {
      if (!this.isAuthentication) {
        await this.mfaAuthenticatedAction();
        return;
      }
      const result = await this.$myinfo.request(this.model, null, true);
      if (result.error) {
        return;
      }

      await sleep(300);
      scrollTo(0, 0);

      this.$emit('success', 'myInfo.mfaSuccess');
    },

    logoutDevices() {
      this.$modal(LogoutDevicesModal);
    }
  },
  async mounted() {
    this.structure.title = this.$myinfo.title;
    await this.initialize();
    this.$nextTick(() => {
      this.isLoad = true;
    });
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[login-security] {
  .my-title-label { .w(100%); }
  .desc { .flex(); .items-center();
    > p {.c(black);}
  }
  .logout-device {.flex(); .flex-dc();
    > .item {.flex();.space-between(); .bgc(white); .br(16); .p(16, 20, 16, 20); .items-center();
      > label {flex: 1; .c(black); .fs(14); font-weight: 500;}
      [my-info-button] {.br(100); .w(auto); .h(30); line-height: 16px !important;
      }
    }
  }
  .security-level-group {
    > p {.c(black); .bold(); .pv(10);}
  }
  .security-method-group { .pt(5); .pb(20);
    > p {.c(black); .bold(); .pv(10);}
  }
  @media (@ml-up) {
    .flex(); .justify-start(); .gap(10);
    > div + div { .mt(0); }
  }
}
</style>
