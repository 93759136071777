<template>
  <SumsubContainer sumsub-certification-failed>
    <FontIcon name="sharp-triangle-warning" />
    <h3 v-html="$t('_.title')" />
    <p>
      {{ $t('_.description1') }} <br />
      {{ $t('_.description2') }}
    </p>
    <p v-html="$t('_.description3', {csEmail})" />
    <ProgressButton button-name="close" @click="$emit('confirm', 'close')" />
  </SumsubContainer>
</template>

<script>
import SumsubContainer from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubContainer.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';

// 차후 기획 추가 시 활용 예정
export default {
  name: 'SumsubCertificationFailed',
  lexicon: 'onBoarding.sumsub.certificationFailed',
  components: { FontIcon, ProgressButton, SumsubContainer },
  props: {
    item: {type: Specific, default: null},
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  mounted() {
    if (this.item) this.item.title = 'onBoarding.title.accountVerification';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sumsub-certification-failed] { .tc();
  > h3 { .fs(22, 27, normal); .regular(); .mb(24); }
  > [font-icon] { .block(); .mb(24); .fs(80); .c(#df3320); }
  > p { .fs(18, 22, normal); .c(#7F7F7F);
    > a[href^='mailto'] { .c(#2680EB); .underline; }
  }
  > p + p { .mt(22); }
}
</style>
