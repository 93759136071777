<template>
  <TextInput trim-text-input :value="value" :placeholder="placeholder" :number="number" :digit="digit" :phone="phone" :telephone="telephone" :type="type" :sm="sm" :wordonly="wordonly" :noneSpecial="noneSpecial" :readonly="readonly" :disabled="disabled" :error="error" :maxLength="maxLength"
             :inputMode="inputMode" :pattern="pattern" :theme="theme" :useReset="useReset" :center="center" :tabindex="tabindex" :translate="translate" :isDecimalLimit="isDecimalLimit" :decimalLimit="decimalLimit" :max="max" :min="min" :clearable="clearable"
             @input="v => $emit('input', trimValue(v))" @change="v => $emit('change', trimValue(v))" @focus="v => $emit('focus', trimValue(v))" @blur="v => $emit('blur', trimValue(v))" @keydown.enter="$emit('enter')" @delete="$emit('delete')" />
</template>
<script>

import TextInput from '@shared/components/common/input/TextInput.vue';

export default {
  name: 'TrimTextInput',
  components: { TextInput },
  props: {
    value: { default: '' },
    placeholder: { type: String, default: '' },
    number: { type: Boolean, default: false },
    digit: { type: Boolean, default: false },
    phone: { type: Boolean, default: false },
    telephone: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    sm: { type: Boolean, default: false },
    wordonly: { type: Boolean, default: false },
    noneSpecial: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: [Array, Object], default: null },
    maxLength: { type: Number, default: 999 },
    inputMode: { type: String, default: '' },
    pattern: { type: String, default: '' },

    /** @type string{'white' | 'black' | 'dark'} */
    theme: { type: String, default: 'white' },
    useReset: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    tabindex: { type: String, default: '0' },
    translate: { type: Boolean, default: false },
    isDecimalLimit: { type: Boolean, default: false, },
    decimalLimit: { type: Number, default: null },
    max: { type: String, default: null, },
    min: { type: String, default: null, },
    clearable: Boolean,
  },
  methods: {
    trimValue(v) {
      return typeof v === 'string' ? v.trim() : v;
    },
  }
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[trim-text-input] {}
</style>
