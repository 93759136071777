<template>
  <span status-badge :class="statusText" v-if="show">
    {{ $t(`_.${statusText}`) }}
  </span>
</template>

<script>
export default {
  name: 'StatusBadge',
  lexicon: 'promotion.v1.badge',
  props: {
    status: { type: String, required: true },
  },
  computed: {
    show() {
      return this.status !== 'ToDo';
    },
    statusText() {
      if (this.status === 'Created') return 'Pending';
      return this.isCancelled(this.status) ? 'Cancelled' : this.status;
    },
  },
  methods: {
    isCancelled(status) {
      return /^Cancel/.test(status);
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[status-badge] { .flex(); .justify-center(); .items-center(); .gap(6); .p(4, 12); .fs(0.675em); .br(2em); .nowrap();
  &::before { .cnt(); .w(0.5em); .h(0.5em); .br(50%); }
  &.InProgress { .bgc(#174c38); .c(#22cc8c);
    &::before { .bgc(#22cc8c); }
  }
  &.Completed { .bgc(#1a3d5a); .c(#5ab4ff);
    &::before { .bgc(#5ab4ff); }
  }
  &.Suspended { .bgc(#4d350c); .c(#d89d3a);
    &::before { .bgc(#d89d3a); }
  }
  &.Expired { .bgc(#462222); .c(#f56565);
    &::before { .bgc(#f56565); }
  }
  &.Cancelled { .bgc(#5a5a5a); .c(#ebebeb);
    &::before { .bgc(#ebebeb); }
  }
  &.Pending { .bgc(#5a5a5a); .c(#ebebeb);
    &::before { .bgc(#ebebeb); }
  }
}
</style>
