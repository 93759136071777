import cookies from 'js-cookie';

const { VUE_APP_COOKIE_DEFAULT_EXPIRES_DAY, VUE_APP_COOKIE_DOMAIN } = process.env;
const DEFAULT_EXPIRES_DAY = parseFloat(VUE_APP_COOKIE_DEFAULT_EXPIRES_DAY ?? 7);
const SESSION_ONLY = 0;

/**
 *
 * @param key
 * @param value
 * @param attributes
 */
export const setCookie = (key, value, attributes) => {
  const domain = attributes?.domain ? attributes.domain : VUE_APP_COOKIE_DOMAIN;
  const path = attributes?.path ? attributes.path : '/';
  const expires = attributes?.expires ? attributes.expires : SESSION_ONLY;
  cookies.set(key, value, { domain, path, expires: expires === SESSION_ONLY ? undefined : expires, secure: true, sameSite: 'None' });
};

/**
 *
 * @param key
 * @param attributes
 */
export const removeCookie = (key, attributes) => {
  const domain = attributes?.domain ? attributes.domain : VUE_APP_COOKIE_DOMAIN;
  const path = attributes?.path ? attributes.path : '/';
  cookies.remove(key, { domain, path });
};

/**
 *
 * @param key
 * @returns {*}
 */
export const getCookie = key => cookies.get(key);