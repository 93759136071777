<template>
  <div wsop-check-floating v-html="$t('signUp.tnc.wsop_tooltip')">
  </div>
</template>

<script>
export default {
  name: 'WsopCheckFloating'
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wsop-check-floating] { box-shadow: 0 6px 12px rgba(0,0,0,0.175); .p(12); .br(4); .bgc(#262626); .-a(#333); .fs(14);
  >span { .c(@c-white); .fs(14); }
  >a { .fs(14); .c(#4A8CFF); .-b(#4A8CFF); .medium(); }

}
</style>