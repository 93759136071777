<template>
  <JumioContainer jumio-document-expired>
    <h3>{{ $t('_.title') }}</h3>
    <div class="desc">
      <p>{{ $t('_.desc') }}</p>
    </div>
    <ProgressButton button-name="back" @click="$emit('confirm')" />
  </JumioContainer>
</template>

<script>
import JumioContainer from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioContainer.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';

export default {
  name: 'JumioDocumentExpired',
  lexicon: 'errorPages.jumio.documentExpired',
  components: { ProgressButton, JumioContainer }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[jumio-document-expired] {}
</style>