import moment from 'moment/moment';
import 'moment/locale/zh-cn';
import 'moment/locale/ru';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/vi';
import 'moment/locale/id';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/th';
import 'moment/locale/tr';
import 'moment/locale/ja';
import 'moment/locale/sv';
import 'moment/locale/et';
import 'moment/locale/lv';
import 'moment/locale/da';
import 'moment/locale/uk';
import 'moment/locale/fi';
import 'moment/locale/cs';
import 'moment/locale/pl';
import 'moment/locale/ro';
import 'moment/locale/nl';
import 'moment/locale/hu';
import 'moment/locale/ar';
import 'moment/locale/fa';
import 'moment/locale/ko';

export const to12hTime = date => moment(date).format('hh:mm');
export const toTime = date => moment(date).format('HH:mm');
export const toTimeS = date => moment(date).format('HH:mm:ss');
export const toDate = (date, locale = 'en') => moment(date).locale(locale).format('MMM DD');
export const toDateT = (date, locale = 'en') => moment(date).locale(locale).format('MMM DD, HH:mm');
export const toFullDate = (date, locale = 'en') => moment(date).locale(locale).format('ll');
export const toFullDateT = (date, locale = 'en') => moment(date).locale(locale).format('ll HH:mm');
export const toFullDateTS = (date, locale = 'en') => moment(date).locale(locale).format('ll HH:mm:ss');

export const toYM = (date, separator = '-') => moment(date).format(`YYYY${separator}MM`);
export const toYMD = (date, separator = '-') => moment(date).format(`YYYY${separator}MM${separator}DD`);
export const toYMDT = (date, separator = '-') => moment(date).format(`YYYY${separator}MM${separator}DD HH:mm`);
export const toYMDTS = (date, separator = '-') => moment(date).format(`YYYY${separator}MM${separator}DD HH:mm:ss`);
export const toDMY = (date, separator = '-') => moment(date).format(`DD${separator}MM${separator}YYYY`);

export const toMilliseconds = time => moment.duration(time).asMilliseconds();
export const toDurationDays = (start, end) => {
  const s = moment(start);
  const e = moment(end);
  return moment.duration(e.diff(s)).asDays();
};
