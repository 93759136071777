<template>
  <div restriction-notice>
    <div error-title :class="{'twoace': isTwoAce }">
      <img :src="img" alt="">
      <h2>{{titleText}}</h2>
      <p v-html="desc"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RestrictionNotice',
  computed: {
    isTwoAce() {
      return location.host.includes('2ace');
    },
    img() {
      return this.isTwoAce ? "/img/2ace_logo.png" : "/img/oops_daniel.png";
    },
    titleText() {
      return this.isTwoAce ? '게임물관리위원회 심사 기간동안 신규 회원 가입이 일시적으로 제한됩니다.' : this.$t(this.title);
    },
    desc() {
      return this.isTwoAce ? '자세한 내용은 2ACE 홈페이지의 공지 사항을 확인 부탁 드립니다.' : this.$t(this.description, this.options);
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[restriction-notice] {
  [error-title] { .tc(); .c(@c-w06); .mt(40);
    h2 { .mv(12); .fs(22); .c(#fff); }
    &.twoace { .w(100%);
      >img { .mt(-80); .max-w(400); .w(100%);}
      h2 { .medium(); .fs(16); .mt(-80);}
    }
  }
}
</style>