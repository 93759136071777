<template>
  <div verify-ready-de>
    <p>{{ $t('_.guide') }}</p>
    <a @click="goSofort" :class="{disabled: status === sofortStatus.UrlFailed}">
      <template v-if="status === sofortStatus.UrlFailed">
        <FontIcon name="exclamation-triangle-regular" />
        <div>
          <ColorButton type="blue" @click="getSofortUrl">
            <FontIcon name="rotate-right" />
            {{ $t('_.sofortRefrash') }}
          </ColorButton>
          <p class="desc">{{ $t('_.sofortUrlError') }}</p>
        </div>
      </template>
      <template v-else>
        <FontIcon name="bank-account-card-regular" />
        <div>
          <h4>{{ $t('_.sofortTitle') }}</h4>
          <p class="desc">{{ $t('_.sofortDescription') }}</p>
        </div>
      </template>
    </a>
    <a @click="$emit('step', 'jumio')">
      <FontIcon name="identity-card-regular" />
      <div>
        <h4>{{ $t('_.jumioTitle') }}</h4>
        <p class="desc">{{ $t('_.jumioDescription') }}</p>
      </div>
    </a>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import { sofortStatus } from '@/constants/base/onboarding/kyc';
import ColorButton from '@shared/components/common/ColorButton.vue';
import baseTemplate from '@/mixins/baseTemplate';
import SofortController from '@/plugins/sofortController';
export default {
  name: 'VerifyReadyDe',
  lexicon: 'kyc.deGiude',
  computed: {
    sofortStatus() {
      return sofortStatus;
    }
  },
  components: { ColorButton, FontIcon },
  mixins: [baseTemplate],
  data() {
    return {
      sofort: null,
      status: sofortStatus.Ready,
    };
  },
  methods: {
    /**
     * 컨트롤러에서 결과 콜백
     * @param info
     */
    callback(info) {
      console.log('%csofort callback', 'color:yellow', info);
      this.status = info.status;
      if (this.status === sofortStatus.Processing) this.sofort.redirectSofort();
    },
    goSofort() {
      this.$emit('step', 'sofort');
      this.sofort.updateStatus(sofortStatus.Ready);
    },
    getSofortUrl() {
      this.sofort.tryAgain();
    }
  },
  mounted() {
    const { $services: services, site, lang, callback } = this;
    this.sofort = new SofortController(this, { services, site, lang, callback });
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-ready-de] {
  > p { .fs(21, 24); .mb(16); .c(#ccc); }
  > a { .-a(@c-w01, 2px); .br(8); .block(); .rel(); .p(28, 24); .flex(); .items-center();
    > [font-icon] { .c(#fff); .fs(60); }
    > div { .pl(24); .ml(24); .rel();
      &:before { .cnt(); .wh(1, 110%); .bgc(@c-w01); .abs(); .lt(0, 50%); .t-yc(); }
      h4 { .fs(21); .tc(); .c(#FFC64D); .mb(8); }
      .desc { .fs(14, 20); .c(#ccc); .regular(); }
    }
    &:nth-of-type(2) { .mt(24);
      > [font-icon] { .fs(40); }
    }
    &.disabled { .bgc(#333333);
      > [font-icon] { .c(#c64040); .fs(56); }
      [color-button] { .mb(8); .fs(16); .c(#edf4fd); .bgc(#2680eb);
        > [font-icon] { .c(#93c0f5); .fs(16); }
      }
    }
  }
  @media (@ms-down) {
    > p {.fs(18);}
    > a {.flex();.flex-dc(); .p(20);
      > [font-icon] {display: none; }
      > div {.pl(0); .ml(0);
        &:before {content: none; }
      }
    }
  }
}
</style>
