<template>
  <SumsubContainer sumsub-recheck>
    <p v-html="$t('_.description1')" />
    <p v-html="$t('_.description2')" />
    <ProgressButton button-name="onBoarding.reCheckPersonal" @click="$emit('confirm', 'reset')" />
  </SumsubContainer>
</template>

<script>
import SumsubContainer from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubContainer.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Specific from '@shared/types/Specific';

// 차후 기획 추가 시 활용 예정
export default {
  name: 'SumsubRecheck',
  lexicon: 'onBoarding.sumsub.recheck',
  components: { ProgressButton, SumsubContainer },
  props: {
    item: {type: Specific, default: null},
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  mounted() {
    if (this.item) this.item.title = 'onBoarding.title.personalDetailsCheck';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sumsub-recheck] {
  > p { .fs(18, 22, normal); .c(#7F7F7F); }
  > p + p { .mt(22); }
}
</style>
