<template>
  <div lugas-result>
    <p class="desc lg">{{ $t('_.lugasStep.successDescription') }}</p>
    <div class="limit-holder" v-if="currentLimit">
      <label>{{ $t('currentLimit') }}</label>
      <p><PriceUnit :value="currentLimit" fixed-currency="EUR"/></p>
    </div>
    <div class="limit-holder">
      <label>{{ $t('newLimit') }}</label>
      <p><PriceUnit :value="newLimit" fixed-currency="EUR"/></p>
    </div>
    <div class="limit-holder" v-if="availableDate">
      <label>{{ $t('availableDate') }}</label>
      <p>{{ customDateFormat(availableDate) }}</p>
    </div>
    <ProgressButton button-name="next" @click="$emit('next')" />
  </div>
</template>

<script>
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { customDateFormat } from '@shared/utils/timeUtils.mjs';
import { makePrice } from '@shared/utils/currencyUtils.mjs';
import PriceUnit from '@shared/components/common/table/PriceUnit.vue';

export default {
  name: 'LugasResult',
  components: { ProgressButton, PriceUnit },
  lexicon: 'onBoarding',
  props: {
    newLimit: { type: Number, default: null },
    currentLimit: { type: Number, default: null }, // [kate] 현재 AS-IS에서도 재현이 되지 않아 작업 X
    availableDate: { type: String, default: '' }, // [kate] 현재 AS-IS에서도 재현이 되지 않아 작업 X
  },
  methods: {
    makePrice,
    customDateFormat,
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[lugas-result] {
  .desc { .m(24, 0); }
  .limit-holder { .fs(14); .bgc(@c-w02); .p(12); .mb(2);
    p { .c(@c-w07); }
  }
}
</style>
