<template>
  <div rg-display-message-modal>
    <div class="content">
      <GpDescription :message="description" />
    </div>
  </div>
</template>

<script>
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
export default {
  name: 'DisplayMessageModal',
  components: { GpDescription, GpButton },
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
  },
  computed: {},
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-display-message-modal] {
  .content {.min-h(120);
    [gp-description] {
      > p {.pt(0); .fs(14); text-align: center;}
    }
  }
  .body {
    > p {.c(black);}
  }
  .footer {.flex(); .gap(10);}
}
</style>