import { isExcludeGgpass } from '@/utils/baseUtil';
class SignRouteStepAdaptor {
  #scope;
  #store;
  #services;
  #site;
  constructor(scope, {store, services}) {
    this.#scope = scope;
    this.#site = store.state.env.site;
    this.#services = services;
    this.#store = store;
  }

  getRouteItem(model, route) {
    return [model.list.find(o => o.route === route)];
  }

  async resetOrder(model, orderRouteNames) {
    const sortList = [];
    orderRouteNames?.forEach(routeName => {
      // 에러가 날 경우 에러 객체가 들어갈 것이므로 === false 조건에 성립되지 않으며 약관 노출을 진행
      if (!(this.#store.state.config?.displayTnc === false) || this.#store.state.env.npOnly || (routeName !== 'SignUpTnc')) {
        const item = model.list.find(o => o.route === routeName);
        item && sortList.push(item);
      }
    });

    return sortList;
  }
}

export default SignRouteStepAdaptor;
