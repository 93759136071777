<template>
  <div v-if="info" :class="[{initialized}, `swiper-container-${direction || 'horizontal'}`]" slider @mouseenter="mouseEnter" @mouseleave="mouseLeave" @touchstart.passive="mouseEnter" @touchend.passive="mouseLeave">
    <div class="swiper-container">
      <div class="swiper-wrapper" @click="click">
        <div v-for="(item, idx) in info" :key="idx" :data-idx="idx" class="swiper-slide">
          <slot :idx="idx" :info="item" name="slide"/>
        </div>
      </div>
    </div>
    <div v-if="scrollbar" class="swiper-scrollbar"></div>
    <div v-if="navigation" :class="{ more: useMore }" class="swiper-navigation">
      <a class="swiper-button-prev" @dblclick.prevent>
        <svg-angle/>
      </a>
      <a class="swiper-button-next" @dblclick.prevent>
        <svg-angle/>
      </a>
      <a v-if="useMore" class="swiper-button-next swiper-button-more" @click="$emit('more')">
        <svg-angle/>
      </a>
    </div>
    <div v-if="pagination" :data-active="now + 1" :data-length="info.length" :data-max-position="paginationMaxPosition" :data-position="paginationPosition" class="swiper-pagination">
      <div v-if="paginationNav" :class="['pagination-nav', 'pagination-nav-prev', {'pagination-nav-disabled': now <= 0}]">
        <button @click="prev" @dblclick.prevent :disabled="now <= 0">
          <FontIcon name="chevron-left" />
        </button>
      </div>
      <div class="pagination-container">
        <div class="pagination-list">
          <div v-for="(item, idx) in info" :key="idx" v-show="onlyShowNowPage ? idx === now : true" :class="{active: idx === now}" class="pagination-item" @click="go(idx)">
            <slot :active="idx === now" :idx="idx" :info="item" :progress="idx === now ? autoProgress : 0" name="pagination"/>
          </div>
        </div>
      </div>
      <div v-if="paginationNav" :class="['pagination-nav', 'pagination-nav-next', {'pagination-nav-disabled': now >= info.length - 1}]">
        <button @click="next" @dblclick.prevent :disabled="now >= info.length - 1">
          <FontIcon name="chevron-right" />
        </button>
      </div>
    </div>
    <div v-if="usePlain" :data-stay="plainStay" class="plain" @click="click">
      <div v-for="(item, idx) in info" :key="idx" class="swiper-slide">
        <slot :idx="idx" :info="item" name="slide"/>
      </div>
    </div>
  </div>
</template>

<script>
import _every from 'lodash/every';
import Swiper from 'swiper/swiper-bundle.min';
import { closest, elementIndex } from '@shared/utils/domUtils.mjs';
import SvgAngle from '@shared/graphics/svg-angle.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'Slider',
  components: { FontIcon, SvgAngle },
  props: {
    info: { type: [Array, Object, Number] },
    pagination: { type: Boolean, default: false },
    paginationType: { type: String, default: null },
    navigation: { type: Boolean, default: false },
    loop: { type: Boolean, default: false },
    centeredSlides: { type: Boolean, default: false },
    scrollbar: { type: Boolean, default: false },
    thumbs: { type: Boolean, default: false },
    freeMode: { type: Boolean, default: false },
    useThumbs: { type: Boolean, default: false },
    usePlain: { type: Boolean, default: false },
    plainStay: { type: Boolean, default: false },
    preventTouchMove: { type: Boolean, default: false },
    spaceBetween: { type: Number, default: undefined },
    slidesPerView: { type: String, default: undefined },
    fade: { type: Boolean, default: false },
    breakpoints: { type: Object, default: undefined },
    delayed: { type: Boolean, default: false },
    speed: { type: Number, default: 700 },
    threshold: { type: Number, default: 5 },
    watchOverflow: { type: Boolean, default: false },
    clickActive: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: false },
    autoplayDelay: { type: Number, default: 5000 },
    useMore: { type: Boolean, default: false },
    paginationPosition: { type: Number, default: 0 },
    paginationMaxPosition: { type: Number, default: 0 },
    noResistance: { type: Boolean, default: false },
    direction: { type: String, default: undefined },
    option: Object,
    // custom (not swiper properties)
    onlyShowNowPage: { type: Boolean, default: false },
    paginationNav: { type: Boolean, default: false },
  },
  data() {
    return {
      /** @type {Swiper} */
      slider: null,
      opt: null,
      timer: null,
      changedListener: [],
      sliderMoveListener: [],
      initialized: false,

      autoStartTime: null,
      autoProgress: 0,
      autoStopFlag: true,
    };
  },
  computed: {
    now() {
      const real = this.slider?.realIndex;
      return real >= 0 ? real : (this.option?.initialSlide || 0);
    },
  },
  watch: {
    info() {
      this.$nextTick(() => {
        this.slider?.update();
      });
    },
  },
  methods: {
    imageReady() {
      if (!this.$el || !this.$el.querySelectorAll) return;
      const images = this.$el?.querySelectorAll('img');
      return new Promise(resolve => {
        if (_every(images, e => e.complete)) {
          resolve();
        }
        const intervalId = setInterval(() => {
          if (_every(images, e => e.complete)) {
            resolve();
            clearInterval(intervalId);
          }
        }, 100);
      });
    },
    async init(thumbsSwiper) {
      await this.imageReady();

      this.opt = { ...this.option, loop: this.loop };

      if (thumbsSwiper) this.opt.thumbs = { swiper: thumbsSwiper, autoScrollOffset: 1, multipleActiveThumbs: false };
      if (this.spaceBetween) this.opt.spaceBetween = this.spaceBetween;
      if (this.centeredSlides) this.opt.centeredSlides = this.centeredSlides;
      if (this.slidesPerView) this.opt.slidesPerView = this.slidesPerView;
      if (this.freeMode) this.opt.freeMode = true;
      if (this.breakpoints) this.opt.breakpoints = this.breakpoints;
      if (this.speed) this.opt.speed = this.speed;
      if (this.preventTouchMove) this.opt.allowTouchMove = false;
      if (this.watchOverflow) this.opt.watchOverflow = true;
      if (this.threshold) this.opt.threshold = this.threshold;
      if (this.noResistance) this.opt.resistanceRatio = 0;
      if (this.paginationType) this.opt.pagination = { el: this.$el.querySelector('.swiper-pagination'), clickable: true, type: this.paginationType };
      if (this.direction) this.opt.direction = this.direction;

      if (!this.opt.on) this.opt.on = {};
      this.opt.on.realIndexChange = this.realIndexChange;
      this.opt.on.transitionStart = this.transitionStart;
      this.opt.on.transitionEnd = this.transitionEnd;
      this.opt.on.touchStart = this.touchStart;
      this.opt.on.touchMove = this.touchMove;
      this.opt.on.touchEnd = this.touchEnd;
      if (this.opt.on.slideChange) this.changedListener.push(this.opt.on.slideChange);
      this.opt.on.slideChange = this.slideChanged;
      if (this.opt.on.sliderMove) this.sliderMoveListener.push(this.opt.on.sliderMove);
      this.opt.on.sliderMove = this.sliderMove;

      if (this.thumbs) {
        this.opt.watchSlidesVisibility = true;
        this.opt.watchSlidesProgress = true;
      }

      if (this.fade) {
        this.opt.effect = true;
        this.opt.fadeEffect = { crossFade: true };
      }

      if (this.scrollbar) {
        this.opt.scrollbar = {
          el: this.$el.querySelector('.swiper-scrollbar'),
          hide: false,
        };
      }

      if (this.$props.navigation) {
        this.opt.navigation = {
          nextEl: this.$el.querySelector('.swiper-button-next'),
          prevEl: this.$el.querySelector('.swiper-button-prev'),
        };
      }

      this.enable();

      this.$emit('initialized', this.slider);
      this.initialized = true;

      if (this.autoplay) this.startAuto();
    },
    prev() {
      this.slider?.slidePrev();
    },
    next() {
      this.slider?.slideNext();
    },
    go(index, duration = undefined) {
      if (this.loop) this.slider?.slideToLoop(index, duration);
      else this.slider?.slideTo(index, duration);
    },
    progress(num, speed = 100) {
      this.slider?.setProgress(num, speed);
    },
    disable() {
      if (this.slider) {
        this.slider.destroy(true, false);
        this.$el.querySelector('.swiper-wrapper')['style'].transform = 'none';
      }
    },
    enable() {
      if (!this.$el || !this.$el.querySelector) return;
      this.slider = new Swiper(
        this.$el.querySelector('.swiper-container'),
        this.opt,
      );
    },
    startAuto(resetTime = true) {
      if (this.autoplayDelay <= 0) throw new Error('autoplay delay should be greater than 0');
      if (resetTime) this.autoStartTime = +new Date();
      if (!this.autoStopFlag) return;
      this.autoStopFlag = false;
      requestAnimationFrame(this.autoTick);
    },
    pauseAuto() {
      this.autoStopFlag = true;
      this.autoProgress = 0;
    },
    autoTick() {
      if (this.autoStopFlag) return;
      const delta = +new Date() - this.autoStartTime;
      const progress = delta / this.autoplayDelay;
      if (progress >= 1) {
        this.next();
        this.autoProgress = 0;
        this.autoStartTime = +new Date();
      } else {
        this.autoProgress = progress;
      }
      requestAnimationFrame(this.autoTick);
    },
    async update() {
      await this.imageReady();
      this.slider?.update();
    },
    click(e) {
      const el = closest(e.target, '.swiper-slide');
      if (this.clickActive) {
        if (!el) return;
        const index = elementIndex(el);
        this.go(index);
      } else {
        if (el) this.$emit('click', this.info[el.dataset.idx], el.dataset.idx);
      }
    },
    slideChanged() {
      if (this.autoplay) this.startAuto();
      this.$emit('changed', this.now);
      this.changedListener.forEach(listener => listener(this.now));
    },
    sliderMove() {
      this.sliderMoveListener.forEach(listener => listener(this.now));
    },
    realIndexChange() {
      if (this.autoplay) this.autoStartTime = +new Date();
      this.$emit('indexChanged', this.now);
    },
    transitionStart() {
      this.$emit('transitionStart', this.now);
    },
    transitionEnd() {
      this.$emit('transitionEnd', this.now);
    },
    touchStart() {
      this.$emit('touchStart', this.now);
    },
    touchMove() {
      this.$emit('touchMove', this.now);
    },
    touchEnd() {
      this.$emit('touchEnd', this.now);
    },
    setTimer(timer) {
      this.changedListener.push(timer.changed);
      this.sliderMoveListener.push(timer.sliderMove);
    },
    tiedUp(other) {
      other.controller.control = this.slider;
      this.slider.controller.control = other;
    },
    mouseEnter() {
      if (this.autoplay) this.pauseAuto();
    },
    mouseLeave() {
      if (this.autoplay && this.autoStopFlag) this.startAuto(false);
    },
  },
  mounted() {
    if (!this.useThumbs) this.init();
  },
  beforeDestroy() {
    this.autoStopFlag = true;
    this.slider?.destroy();
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj';
[slider] { .rel;
  .swiper-button-prev, .swiper-button-next { .z(2);
    &:after { .hide; }
    .mt(0);
  }
  .swiper-button-prev {
    svg { .t-r(90deg) }
  }
  .swiper-button-next {
    svg { .t-r(-90deg) }
  }
  .swiper-button-more { .hide; }

  .swiper-navigation.more {
    .swiper-button-next.swiper-button-disabled { .hide;
      & + .swiper-button-more { .flex; }
    }
  }

  .swiper-pagination-custom, .pagination-list { .flex(); .justify-center(); .items-center(); .gap(4); }
  .swiper-pagination-custom {
    .pagination-container, .pagination-list, button, [font-icon] { .c(inherit); }
  }
  .pagination-nav-disabled { .o(0.35); cursor: auto; pointer-events: none; }
}
</style>
