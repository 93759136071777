import { getTitle, isExcludeGgpass, isLocal, updateSignEnv } from '@/utils/baseUtil';
import ResponsibleSiteMap, { routeNames } from '@/constants/model/responsible-gaming';
import { setGTM } from '@/plugins/tracking';
import MyPageSiteMap, { getCurrencySymbol } from '@/constants/model/my-page';
import { STEP } from '@/constants/base/my-page';
import { apiErrorType } from '@/constants/base/apiErrorCode';
import { hiddenNoLimitCountries } from '@/constants/base/responsible-gaming';
import { siteIds } from '@/constants/base/siteMap.mjs';

class ResponsibleGamingService {
  #services;
  #store;
  #router;
  #siteMap;
  #siteInfo;
  #step;
  #site;
  #structure;
  #routeName;

  setScope(scope) {
    this.#services = scope.$services;
    this.#router = scope.$router;
    this.#store = scope.$store;
    this.#siteInfo = this.#store.state.env.siteInfo;
    this.#step = STEP.DEFAULT;
    this.#site = this.#store.state.env.site;
    this.#structure = { action: null, config: null };

    this.#siteMap = ResponsibleSiteMap();

    setGTM(this.#site, this.#siteInfo, this.#router);
  }

  get title() {
    const item = this.currentPageModel.title;
    if (item.hasOwnProperty(this.#site)) {
      return item[this.#site];
    } else return item.default;
  }
  get currentPageModel() {
    return this.#siteMap[this.#routeName];
  }
  get step() {
    return this.#step;
  }
  get root() {
    return this.#siteMap.root;
  }
  get stepList() {
    return this.currentPageModel.step || [];
  }
  get currentStepIndex() {
    if (this.stepList.length <= 0) return 0;
    return this.stepList.findIndex(step => this.#step === step);
  }
  get currentFetchModels() {
    return this.currentPageModel.fetchModels || null;
  }
  get currentFetchKey() {
    return this.currentPageModel.fetchKeys || null;
  }
  get isFirstStep() {
    return this.currentStepIndex === 0;
  }
  get isLastStep() {
    return this.currentStepIndex === this.stepList.length - 1;
  }
  get currentComponent() {
    return this.currentPageModel.component || null;
  }

  async #setLocalCurrency() {
    const r = await this.#services.account.getBalance();
    if (r?.error) {
      const replace = this.errorHandler(r);
      if (replace) {
        await this.#router.replace({ name: replace.path, params: replace.params, query: replace.query });
        return false;
      }
    }
    const localCurrency = r?.LocalCurrency;
    const currencySymbol = getCurrencySymbol(localCurrency, '');

    this.#store.commit('user/setCurrencySymbol', currencySymbol);
    return true;
  }
  async #setCurrencyList() {
    const r = await this.#services.base.getCurrencyList();
    if (r?.error) {
      const replace = this.errorHandler(r);
      if (replace) {
        await this.#router.replace({ name: replace.path, params: replace.params, query: replace.query });
        return false;
      }
    }

    this.#store.commit('config/setCurrencies', r.Currencies);
    return true;
  }
  #getApiService(serviceName) {
    if (!this.#services[serviceName])
      return null;
    return this.#services[serviceName];
  }

  async request(payload) {
    const r = await this.#structure.action(payload);

    if (r?.error) {
      const replace = this.errorHandler(r);
      if (replace) {
        await this.#router.replace({ name: replace.path, params: replace.params, query: replace.query });
        return;
      }
      return r;
    }

    await this.#services.account.getRgConfig(true);
    return r;
  }
  getFetchFunction(serviceName, functionName, params = {}, hasKey = false, npOnly = false) {
    const service = this.#getApiService(serviceName);

    if (!service) return null;
    if (!service[functionName])
      return null;

    if (isExcludeGgpass(this.#site)) {
      if (!hasKey) {
        return service[functionName](params);
      } else {
        if (hasKey && npOnly) return service[functionName](params);
        else return null;
      }
    } else {
      if (!hasKey) {
        return service[functionName](params);
      } else {
        if (hasKey && npOnly) return null;
        else return service[functionName](params);
      }
    }
  }
  async createFetchFunction() {
    if (!this.currentFetchModels) return;

    const fetchFunctions = this.currentFetchModels.map(fetchModel => {
      const fetch = this.getFetchFunction(fetchModel.service, fetchModel.fetch, { ...fetchModel.params }, fetchModel.hasOwnProperty('npOnly'), fetchModel.npOnly);
      return fetch;
    }).filter(fetch => fetch !== null);

    if (fetchFunctions.length <= 0) return null;

    if (fetchFunctions.length > 1) {
      const results = await Promise.all(fetchFunctions).then((promises) => {
        const error = promises.find(x => x.error);
        if (error) {
          return error;
        }

        return promises.reduce((acc, cur, idx) => {
          acc[this.currentFetchKey[idx]] = cur;
          return acc;
        }, {});
      });
      return results;
    } else return fetchFunctions[0];
  }
  async fetch() {
    const r = await this.createFetchFunction();
    return r;
  }
  createAction() {
    const { service, api } = this.currentPageModel;
    if (!service && !api) {
      this.#structure.action = null;
      return;
    }
    this.#structure.action = payload => this.#services[service][api](payload);
  }
  errorHandler(r) {
    const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
    if (r.errorTemplate?.[apiErrorType.AUTH]) {
      return { path: 'AuthError', params: { ci: errorTemplate.ci, cci: errorTemplate.cci }, query: { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined } };
    }
    return false;
  }
  createErrorProxy(r) {
    return this.errorHandler(r);
  }
  async errorRouteReplace(r) {
    const info = this.errorHandler(r);
    if (info) {
      await this.#router.replace({ name: info.path, params: info.params, query: info.query });
      return true;
    }

    return false;
  }
  createConfig(config) {
    this.#structure.config = {
      ...config,
      isEnable: config[this.currentPageModel.enableKey],
      hasPending: config[this.currentPageModel.requestKey],
      verifyFreeze: config[this.currentPageModel.verifyFreezeKey],
      dailyLowerHangTime: config.DailyLowerHangTime,
      dailyUpperHangTime: config.DailyUpperHangTime,
      weeklyLowerHangTime: config.WeeklyLowerHangTime,
      weeklyUpperHangTime: config.WeeklyUpperHangTime,
      monthlyLowerHangTime: config.MonthlyLowerHangTime,
      monthlyUpperHangTime: config.MonthlyUpperHangTime,
      timeZoneType: config.TimeZoneType,
      countryCode: config.CountryCode,
      title: this.title
    };
    return this.#structure.config;
  }

  updateRouteName(routeName) {
    this.#routeName = routeName;
  }
  changeStep(step) {
    this.#step = step;
  }
  movePrevStep() {
    if (this.isFirstStep && this.currentStepIndex <= 0) return;
    this.step = this.stepList[this.currentStepIndex - 1];
  }

  async currencyInitialize() {
    let r;
    r = await this.#setLocalCurrency();
    if (!r) return r;
    r = await this.#setCurrencyList();
    return r;
  }
  async getSelfExclusion() {
    const r = await this.#services.account.getSelfExclusionType();
    if (r?.error) {
      const replace = this.errorHandler(r);
      if (replace) {
        await this.#router.replace({ name: replace.path, params: replace.params, query: replace.query });
        return false;
      }
    }
    return r;
  }
  calculateLimitHangTime(type, valueType, noLimit, currentValue, requestValue, isVerifyFreeze = false) {
    /**
     * TODO: GGPNL일 일 경우 request의 값이 없을 경우 current를 할당.
     * 단, ReLoginTime Or MonthlyLogin 일 경우는 제외
     * 향후 정리된 분기로 처리 필요. @eric.kim
     */
    if (['min', 'times'].includes(valueType) || this.#site !== siteIds.GGPNL) {
      if (!requestValue && !noLimit) return null;
      if (currentValue === requestValue) return null;
    }else {
      if(!requestValue) requestValue = currentValue;
    }

    const { config } = this.#structure;
    const hangTimeModel = {
      'daily': [config.dailyLowerHangTime, config.dailyUpperHangTime],
      'weekly': [config.weeklyLowerHangTime, config.weeklyUpperHangTime],
      'monthly': [config.monthlyLowerHangTime, config.monthlyUpperHangTime],
      'default': [config.dailyLowerHangTime, config.dailyUpperHangTime],
    };

    let hangTime = '';
    let hangTimeCount = 0;

    const [lowerTime, upperTime] = hangTimeModel[type];

    if (type === 'game') {
      if (isVerifyFreeze) {
        hangTime = 'verifyFreeze';
      } else {
        if (currentValue > requestValue) {
          if (upperTime > 0) {
            hangTime = upperTime;
          } else {
            hangTime = null;
          }
        }
        if (requestValue > currentValue) {
          if (lowerTime > 0) {
            hangTime = lowerTime;
          } else {
            hangTime = null;
          }
        }
      }
    } else {
      if (isVerifyFreeze) {
        hangTime = 'verifyFreeze';
      } else {
        if ((currentValue || currentValue === 0) && (noLimit || currentValue < requestValue)) {
          if (upperTime > 0) {
            hangTime = upperTime;
            hangTimeCount++;
          } else {
            hangTime = null;
          }
        }
        if (requestValue !== null && (currentValue === null || currentValue > requestValue)) {
          if (lowerTime > 0) {
            hangTime = lowerTime;
            hangTimeCount++;
          } else {
            hangTime = null;
          }
        }
      }
    }

    return { type, currentValue, requestValue, hangTime, hangTimeCount };
  }
  calculateGameHangTime(type, currentValue, requestValue, isVerifyFreeze = false) {
    if (currentValue === requestValue) return null;
    const { config } = this.#structure;
    const lowerTime = config.dailyLowerHangTime;
    const upperTime = config.dailyUpperHangTime;
    let hangTime = '';
    let hangTimeCount = 0;

    if (isVerifyFreeze) {
      hangTime = 'verifyFreeze';
    } else {
      if (currentValue) {
        if (upperTime > 0) {
          hangTime = upperTime;
          hangTimeCount++;
        } else {
          hangTime = null;
        }
      }
      if (requestValue) {
        if (lowerTime > 0) {
          hangTime = lowerTime;
          hangTimeCount++;
        } else {
          hangTime = null;
        }
      }
    }

    return { type, currentValue, requestValue, hangTime, hangTimeCount };
  }

  checkNoLimitVisible(propsHiddenLimit) {
    let showLimit = true;
    if (propsHiddenLimit) showLimit = false;
    if (hiddenNoLimitCountries.indexOf(this.#store.state.user.userInfo.CountryCode) >= 0) showLimit = false;
    if (this.#site === siteIds.WSOPON) {
      if (this.#routeName === routeNames.RgMyDepositLimit) showLimit = false;
      if (this.#routeName === routeNames.RgMyTotalBetAmountLimit) showLimit = false;
      if (this.#routeName === routeNames.RgMySingleBetAmountLimit) showLimit = false;
    }
    if (this.#site === siteIds.GGPCZ) {
      if (this.#routeName === routeNames.RgMyTotalBetAmountLimit) showLimit = false;
      if (this.#routeName === routeNames.RgMyLossLimit) showLimit = false;
    }
    return showLimit;
  }
  async redirectKycUrl({ redirectPath, additional, customStep, lang }) {
    this.#services.modal.block();
    try {
      const path = isLocal() ? '/responsible-gaming' : '/app';
      const originUrl = `${location?.origin}${path}/${redirectPath}`;
      const encodeOriginUrl = encodeURIComponent(originUrl);

      const tokenResponse = await this.#services.token.getToken();
      const token = tokenResponse?.data?.npToken?.accessToken;
      if(!token){
        this.#services.modal.unblock();
        return;
      }

      const kycUrl = process.env[`VUE_APP_ON_BOARDING_URL_${this.#site}`] || null;
      if (kycUrl === null) {
        this.#services.modal.unblock();
        return;
      }
      const queryString = { token, lang, redirectUri: encodeOriginUrl, additional, customStep };
      location.href = `${kycUrl}?${Object.keys(queryString).filter(x => queryString[x]).map(key => `${key}=${queryString[key]}`).join('&')}`;
      this.#services.modal.unblock();
      return true;
    } catch (err) {
      this.#services.modal.unblock();
      return false;
    }
  }
  getPageModel(routeName) {
    return this.#siteMap[routeName];
  }
}

export default {
  install(Vue, { services, store, router }) {
    Vue.prototype.$rg = new ResponsibleGamingService(services, store, router);
  }
};