export default class PromotionService {
  #proxyNpPromoApi;
  #proxyNpTpmApi;
  #optIn;

  constructor(services) {
    this.#proxyNpPromoApi = services.proxyNpPromoApi;
    this.#proxyNpTpmApi = services.proxyNpTpmApi;
  }

  async history({ PlayerPromotionId, CurrentPage, PageSize }) {
    try {
      const r = await this.#proxyNpPromoApi.proxyGet('/v1/promotion/history', { PlayerPromotionId, CurrentPage, PageSize });
      if (!r?.error) return r;
      else throw { code: 401, error: true };
    } catch (e) {
      console.log('error : promotion history', e);
      throw e;
    }
  }

  /**
   * @id GetPromotionEnableByTypeRequest
   * @returns {Promise<PromotionAccessible>}
   * */
  async promotionInfo() {
    try {
      return await this.#proxyNpPromoApi.proxyGet('/v1/promotion');
    } catch (e) {
      console.log('error : promotionInfo', e);
      throw e;
    }
  }

  /**
   * @id GetPromotionStatusRequest
   * @returns {Promise<PromotionStatus>}
   */
  async depositPromotion() {
    try {
      return await this.#proxyNpPromoApi.proxyGet('/v1/promotion/status');
    } catch (e) {
      console.log('error : depositPromotion', e);
      throw e;
    }
  }

  /**
   * @id GetSignUpPromotionStatusRequest
   * @returns {Promise<PromotionStatus>}
   */
  async signUpPromotion() {
    try {
      return await this.#proxyNpPromoApi.proxyGet('/v1/sign-up/promotion');
    } catch (e) {
      console.log('error : signUpPromotion', e);
      throw e;
    }
  }

  /**
   * @id ReferralPromotionStatusRequest
   * @returns {Promise<ReferralPromotionStatus>}
   */
  async referralPromotion() {
    try {
      return await this.#proxyNpPromoApi.proxyGet('/v1/referral/promotion/status');
    } catch (e) {
      console.log('error : referralPromotion', e);
      throw e;
    }
  }

  /**
   * @id OptInSignUpPromotionRequest
   * @returns {*}
   */
  async optInSignUp(reset) {
    try {
      if (!this.#optIn || reset) this.#optIn = await this.#proxyNpPromoApi.proxyPost('/v1/sign-up/promotion/reward');
      return this.#optIn;
    } catch (e) {
      console.log('error : optInSignUp', e);
      throw e;
    }
  }

  /** Promotion V2 ############################################################################################################################################################## */
  /**
   * @id GetFreeBonus
   * @returns {Promise<FreeBonus[]>}
   * */
  async getFreeBonus() {
    try {
      const r = await this.#proxyNpTpmApi.proxyGet('/accounts/promotions/free-bonus');
      if (!r?.error) return r;
      else throw { code: 401, error: true };
    } catch (e) {
      console.log('error : getFreeBonus', e);
      throw e;
    }
  }

  /**
   * @id GetPrizeHistory
   * @returns {Promise<PrizeHistory>}
   * */
  async getPrizeHistory(type, { offset, limit }) {
    try {
      const r = await this.#proxyNpTpmApi.proxyGet(`/accounts/promotions/${type}/histories`, { offset, limit });
      if (!r?.error) return r;
      else throw { code: 401, error: true };
    } catch (e) {
      console.log('error : getPrizeHistory', e);
      throw e;
    }
  }

  /**
   * @id GetDripBonus
   * @returns {Promise<DripBonus>}
   * */
  async getDripBonus() {
    try {
      const r = await this.#proxyNpTpmApi.proxyGet('/accounts/drip-bonus');
      if (!r?.error) return r;
      else throw { code: 401, error: true };
    } catch (e) {
      console.log('error : getDripBonus', e);
      throw e;
    }
  }

  /**
   * @id ActivateDripBonus
   * */
  async activateDripBonus(dripBonusId) {
    try {
      return await this.#proxyNpTpmApi.proxyPut(`/accounts/drip-bonus/${dripBonusId}/activate`);
    } catch (e) {
      console.log('error : activateDripBonus', e);
      throw e;
    }
  }

  /**
   * @id GetDailyFreebieBonus
   * @returns {Promise<DailyFreebie>}
   * */
  async getDailyFreebie() {
    try {
      return await this.#proxyNpTpmApi.proxyGet('/accounts/promotions/daily-freebie/prizes');
    } catch (e) {
      console.log('error : getDailyFreebie', e);
      throw e;
    }
  }

  /**
   * @id PostOptInDailyFreebieBonus
   * */
  async optInDailyFreebie() {
    try {
      return await this.#proxyNpTpmApi.proxyPost('/accounts/promotions/daily-freebie/opt-in');
    } catch (e) {
      console.log('error : optInDailyFreebie', e);
      throw e;
    }
  }

  /**
   * @id PayPromotionPrize
   * */
  async payPromotionPrize(prizeId) {
    try {
      return await this.#proxyNpTpmApi.proxyPost(`/accounts/promotions/prizes/${prizeId}`);
    } catch (e) {
      console.log('error : payPromotionPrize', e);
      throw e;
    }
  }

  /**
   * @id TncConsent
   * */
  async tncConsent(promotionTnCType, isAgreement) {
    try {
      return await this.#proxyNpTpmApi.proxyPost(`/accounts/promotions/tnc`, { promotionTnCType, isAgreement });
    } catch (e) {
      console.log('error : TncConsent', e);
      throw e;
    }
  }
}
