export default class RouterProxy {
  /** @type {VueRouter} */
  #router;
  #nextRoute;

  constructor(router) {
    this.#router = router;
  }

  push(...arg) {
    return this.#router?.push(...arg).catch(() => {});
  }

  replace(...arg) {
    return this.#router?.replace(...arg).catch(() => {});
  }

  get currentRoute() {
    return this.#router?.currentRoute;
  }

  setRouter(router) {
    this.#router = router;
  }

  get nextRoute() {
    return this.#nextRoute || this.currentRoute;
  }

  set nextRoute(v) {
    this.#nextRoute = v;
  }
}

