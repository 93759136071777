<template>
  <SearchDropSelect jumio-document-select v-if="list" :list="list" auto select-only :template="makeLabel" :value="model" @input="change" :translate="false" />
</template>

<script>
import Specific from '@shared/types/Specific';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';

export default {
  name: 'JumioDocumentSelect',
  lexicon: 'onBoarding.jumio.documentsSelect',
  components: { SearchDropSelect },
  props: {
    list: Specific,
    value: Specific,
  },
  data() {
    return {
      model: null,
    };
  },
  methods: {
    makeLabel(doc) {
      return `<p class="label-text"><b>${this.$t(doc.label)}</b><span>${!doc.period ? '' : `${this.$t(doc.period)}`}</span></p>`;
    },
    change(v) {
      this.model = v;
      this.$emit('input', v);
    }
  },
  mounted() {
    this.model = this.value;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[jumio-document-select][search-drop-select] { .mb(24); .br(0);
  input[type='text'] { .c(black); }
  > a { .h(); .pv(10); .bgc(@c-white) !important; .br(0); .fs(13); .c(#333);
    label { .c(inherit); .regular(); line-height: 1.2; }
    [font-icon] { .fs(inherit); .c(inherit); }
  }
  .list-container { .mt(-4); .h() !important; .bgc(@c-white); .bs(0, 6, 6, 0, 0.175);
    .list-inner { .pb(0);
      .empty { .-t(@c-b01); .c(#333); }
      .scroll-holder button { .ph(12); .-t(@c-b01); .c(#333); .fs(13); font-family: inherit;
        &:hover, &:focus, &.focus, &.active { .bgc(#f5f5f5); }
        .label-text{ .ml(8); }
      }
    }
  }

  .label-text {
    b { .semi-bold();  }
    b, span { .block(); }
  }
  @media (@tp-up) {
    .label-text {
      b { .mr(4); }
      b, span { .ib(); }
    }
  }
}
</style>
