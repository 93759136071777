<template>
  <div country-number-drop-select :class="theme" v-if="list">
    <SearchDropSelect v-model="model" @input="selected" :template="getView" size="sm" :list="list" :top-gap="topGap" :isFromLanding="isFromLanding" :parent-fill="true" @focus="$emit('focus')" @blur="$emit('blur')" :placeholder="placeholder" :auto-select="false"/>
  </div>
</template>

<script>
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import _, { isNull, isString } from 'lodash';

export default {
  name: 'CountryNumberDropSelect',
  components: { SearchDropSelect },
  props: {
    value: Specific,
    theme: { type: String, default: '' },
    isFromLanding: { type: Boolean, default: false },
    placeholder: String,
  },
  data() {
    return {
      list: null,
      model: null,
      userCountryInfo: null,
      topGap: 0
    };
  },
  computed: {
    blockCountries: state('env', 'blockCountries'),
    emptyStatus() {
      return this.$route.name === 'PersonalBirth';
    },
  },
  watch: {
    value: 'updateModel'
  },
  methods: {
    getView(item, selected) {
      return !selected ? `<em class="space-between"><span>${this.$t(`country.${item.CountryCode}`)}</span> <span>+${item.PhoneCountryCode}</span></em>` : `<em>${item.label}</em>`;
    },
    selected(v) {
      this.$emit('input', v);
    },
    async updateModel() {
      if(this.value) {
        if(isString(this.value) && this.list) this.model = this.list.find(o => o.value === this.value);
        else this.model = this.value;
      } else {
        const userCountry = await this.$services.base.getUserCountry();
        this.model = this.list?.find(o => o.value === userCountry.CountryCode);
        if (!this.model) this.model = this.list?.[0].value;
      }

      this.$emit('input', this.model);
    },
    async getList() {
      this.list = (await this.$services.base.getMobileCountries()).List.map(o => ({ label: `${o.CountryCode} +${o.PhoneCountryCode}`, value: o.CountryCode, ...o }));
      await this.updateModel();
    }
  },
  async mounted() {
    await this.getList();
    await this.updateModel();
    const element = this.$el;
    if(element instanceof HTMLElement) {
      this.topGap = +getComputedStyle(element).paddingTop.replace('px', '') || 0;
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[country-number-drop-select] { .wh(100%); .abs(); .lt(0,0);
  &.single { .h(48);
    [search-drop-select] {
      > a { .h(48); .br(6); box-shadow: 0 1px 0 0 rgba(200, 200, 200, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .bgc(rgba(255, 255, 255, 0.07)) !important;
        > label { .lh(50); .h(100%);}
      }
    }
  }
  .img-country { .w(18); .ib(); .mr(8);}
  .link { .abs(); .rb(0, -28); .c(@c-red); .semi-bold();
    a { .regular(); .-b(@c-red);}
  }
}
</style>