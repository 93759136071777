<template>
  <div func-protection-policy>
    <p class="desc">{{ $t('_.description') }}</p>
    <ValidationProvider :rules="'requiredCheck'">
      <CheckboxItem v-model="modelInfo.Protection" theme="dark">
        <span v-html="$t('_.accept', {URL: url})"></span>
      </CheckboxItem>
    </ValidationProvider>
  </div>
</template>

<script>
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'FundProtectionPolicy',
  components: { CheckboxItem },
  lexicon: 'onBoarding.fundProtectionPolicyStep',
  props: {
    modelInfo: /** @type {ModelInfo}*/Specific,
  },
  computed: {
    url() {
      return 'https://www.ggpoker.co.uk/terms-conditions/';
    }
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[func-protection-policy] {
  [checkbox-item] { .mt(12); .medium(); .c(#7F7F7F);
    > i { .-a(#ccc); }
    > em { .mt(1); }
    a { .c(@c-link); }
  }
}
</style>
