<template>
  <div lugas-deposit v-if="!isLoading">
    <div v-if="!hasError && !isProcessing">
      <p class="desc lg">{{ $t('_.description') }}</p>
      <LimitUnitField label="Monthly" v-model="monthlyLimit" :unitLabel="' '" :rules="crossProviderLimitRules" :max-length="9" :limit="limitAmount" @input="updateModel" />
      <p class="error-msg" v-if="apiErrorMsg">{{ $t(apiErrorMsg) }}</p>
      <ProgressButton button-name="submit" @click="submit" :disabled="disable" />
    </div>
    <div class="processing-view" v-if="isProcessing">
      <FontIcon name="auto-refresh" />
      <p class="desc lg" v-html="$t('_.loadingViewDescription', {amount: monthlyLimit})"></p>
    </div>
    <div class="error-view" v-if="hasError">
      <FontIcon name="exclamation-triangle"/>
      <p v-html="$t(`_.${errorCode}`, {email: csEmail})"></p>
      <ProgressButton button-name="close" @click="$emit('close')" />
    </div>
  </div>
</template>

<script>
import { makePrice } from '@shared/utils/currencyUtils.mjs';
import LimitUnitField from '@/views/components/pages/on-boarding/template/extra/LimitUnitField.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { state } from '@shared/utils/storeUtils.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'LugasDeposit',
  components: { LimitUnitField, ProgressButton, FontIcon },
  lexicon: 'onBoarding.lugasStep',
  mixins: [baseTemplate],
  data() {
    return {
      monthlyLimit: null,
      limitAmount: 1000,
      isLoading: true,
      apiErrorMsg: '',
      disable: false,
      isProcessing: false,
      hasError: false,
      errorCode: null,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    currencySymbol: state('user', 'currencySymbol'),
    crossProviderLimitRules() {
      return `requiredDepositLimitLugas:${this.currencySymbol},${this.csEmail},${this.limitAmount},${makePrice(this.limitAmount)}`;
    }
  },
  methods: {
    updateModel(value) {
      this.apiErrorMsg = '';
      this.disable = false;
      if (value > 1000) this.disable = true;
      this.$emit('input', value);
    },
    async initialize() {
      this.monthlyLimit = null;
      this.isLoading = false;
    },
    async submit() {
      this.isProcessing = true;
      let r;
      try { r = await this.$services.account.setLugasCentralFileLimit({ CrossProviderLimitAmount: this.monthlyLimit }); } catch (e) { r = e; }
      if (r?.error) {
        this.isProcessing = false;
        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        if (r.key === apiErrorCode.LUGAS_INTERNAL_ERROR) {
          this.errorCode = r.CustomerErrorParameters[0] ? r.CustomerErrorParameters[0].trim() : '';
          this.hasError = true;
        }
        this.apiErrorMsg = 'invalidAmount';
        this.disable = true;
        return;
      }
      else {
        this.isProcessing = false;
        this.$emit('update-status', true);
      }
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[lugas-deposit] {
  .desc { .mt(30); .mb(40); }
  .error-msg { .ml(calc(20% + 8px)); .mt(15); }
  .processing-view {
    [font-icon] { .block(); .mt(40); .tc(); .fs(58); animation: rotate-circle 2s linear infinite; .m(60, 0); }
  }
  .error-view { .tc(); .pt(40);
    [font-icon] { .fs(30); }
    p { .c(#7F7F7F); .mt(20);
      a { .c(#2680EB); }
    }
  }
}
</style>
