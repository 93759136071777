<template>
  <div confirm-personal-info>
    <p class="desc">
      {{ $t('_.confirmPersonalInfo.subTitle')}}
    </p>
    <ul>
      <li>
        <label>{{ $t('_.name') }}</label>
        <span>{{modelInfo.Email}}</span>
      </li>
      <li>
        <label>{{ $t('personalDetails.placeOfBirth') }}</label>
        <span>{{modelInfo.PlaceOfBirth}}</span>
      </li>
      <li>
        <label>{{ $t('personalDetails.citizenship') }}</label>
        <span>{{modelInfo.CityOfBirth}}</span>
      </li>
      <li>
        <label>{{ $t('personalDetails.birthNumber') }}</label>
        <span>{{modelInfo.NationalId}}</span>
      </li>
      <li>
        <label>{{ $t('mobileNumber') }}</label>
        <span>{{modelInfo.MobileNumber}}</span>
      </li>
      <li class="permanent">
        <label>{{ $t('_.permanentResidentialAddress') }}</label>
        <span>{{ address }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import {customDateFormat} from '@shared/utils/timeUtils.mjs';

export default {
  name: 'ConfirmPersonalInfo',
  lexicon: 'onBoarding',
  mixins: [baseTemplate],
  computed: {
    birth() {
      return customDateFormat(this.modelInfo.DateOfBirth);
    },
    address() {
      const {StreetName, StreetNumber, City, State, PostalCode, CountryName} = this.modelInfo;
      return `${StreetName} ${StreetNumber}, ${City}, ${State}, ${PostalCode}, ${CountryName}`;
    }
  },
  methods: {
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[confirm-personal-info] {
  p.desc { .fs(16); }
  ul { .mt(24); .flex-column(); .gap(16); .items-start(); .bgc(@c-w007); .-a(@c-w01); .p(16);
    li { .flex-column(); .items-start(); .gap(4);
      label { .medium(); .keep-all(); .fs(12); .o(.7);}
      span {  }
      &.permanent {

      }
    }
  }
  @media(@ml-up) {
    //ul {
    //  li { .flex-row();.gap(20);
    //    label { .w(200); .keep-all(); }
    //  }
    //}
  }
}
</style>