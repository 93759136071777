import { isExcludeGgpass } from '@/utils/baseUtil';
export default class CaptchaService {
  #services;
  #store;
  #key;
  #callback;
  #proxyNpPublicApi;
  #proxyOpApi;
  constructor(services) {
    this.#services = services;
    this.#store = services.store;
    this.#proxyNpPublicApi = services.proxyNpPublicApi;
    this.#proxyOpApi = services.proxyOpApi;
  }

  getReCaptchaKey() {
    try {
      this.#key = process.env.VUE_APP_RECAPTCHA_KEY_GGPOK;
      // if (!isExcludeGgpass(this.#store.state.env.site)) {
      //   this.#key = process.env.VUE_APP_RECAPTCHA_KEY_GGPOK;
      // } else {
      //   this.#key = process.env.VUE_APP_RECAPTCHA_KEY_GGPCOM;
      // }
      // this.#key = this.#store.state.user.recaptchaKey;
      return this.#key;
    } catch (e) {
      console.log('error : getRecaptchaKey');
      throw e;
    }
  }

  async getGeeCaptchaKey() {
    this.#key = this.#store.state.env.ggpass ? (await this.#proxyNpPublicApi.proxyGet(`/system/geetest/captcha`)).GeeTestInitialData : await this.#proxyOpApi.proxyGet('/op/v1/geetest');
    return this.#key;
  }

  async reCaptchaInitialize() {
    if (document.querySelector('#grecaptchaScript')) document.head.removeChild(document.querySelector('#grecaptchaScript'));

    await this.getReCaptchaKey();
    const script = document.createElement('script');
    script.id = 'grecaptchaScript';
    script.type = 'text/javascript';
    script.src = `//www.google.com/recaptcha/enterprise.js?render=${this.#key}`;
    script.setAttribute('async', '');
    script.setAttribute('defer', '');
    document.head.appendChild(script);
  }

  async recaptchaEnterprise(action) {
    const token = await grecaptcha.enterprise.execute(this.#key, { action });
    return { RecaptchaToken: token, RecaptchaAction: action, RecaptchaSiteKey: this.#key };
  }

  async setRecaptcha(action) {
    let res;
    await new Promise(resolve => {
      grecaptcha.enterprise.ready(async () => {
        res = await this.recaptchaEnterprise(action);
        resolve();
      });
    });
    return res;
  }

  async setGeeCaptcha() {
    let res;
    await new Promise(resolve => {
      this.render(r => {
        res = r;
        resolve();
      });
    });
    return res;
  }

  async render(callback) {
    await this.#geeCaptchaRender(callback);
  }

  /** enterprise 적용 후 문제 없으면 삭제 예정 */
  async #reCaptchaRender(callback) {
    this.#reCaptchaRemove();
    this.#callback = callback;

    await this.getReCaptchaKey();
    window.loaded = this.#reCaptchaLoadComplete.bind(this);

    const script = document.createElement('script');
    script.id = 'grecaptchaScript';
    script.type = 'text/javascript';
    script.src = `//www.google.com/recaptcha/api.js?onload=loaded&render=explicit`;
    script.setAttribute('async', '');
    script.setAttribute('defer', '');
    document.body.appendChild(script);

    const recaptchaDiv = document.createElement('div');
    recaptchaDiv.id = 'grecaptcha';
    document.body.appendChild(recaptchaDiv);
  }

  async #geeCaptchaRender(callback) {
    this.#geeCaptchaRemove();
    this.#callback = callback;

    await this.getGeeCaptchaKey();

    const script = document.createElement('script');
    script.id = 'geecaptchaScript';
    script.type = 'text/javascript';
    script.src = `https://static.geetest.com/static/js/gt.0.4.9.js`;
    script.setAttribute('async', '');
    script.setAttribute('defer', '');
    document.body.appendChild(script);

    script.onload = () => {
      const config = { gt: this.#key.gt, challenge: this.#key.challenge, offline: false, new_captcha: this.#key.newCaptcha, product: 'bind', width: '300px' };
      window.initGeetest(config, async captcha => {
        captcha.onReady(async () => {
          captcha.verify();
        }).onSuccess(() => {
          const r = captcha.getValidate();
          if (!r) callback('error');
          else {
            callback({ Challenge: r.geetest_challenge, SecCode: r.geetest_seccode, Validate: r.geetest_validate });
          }
        }).onError(() => {
          console.log('error : gee');
        });
      });
    };
  }

  /** enterprise 적용 후 문제 없으면 삭제 예정 */
  async #reCaptchaLoadComplete() {
    const target = document.body.querySelector('#grecaptcha');
    try {
      window.grecaptcha.render(target, {
        'sitekey': this.#key,
        'size': 'invisible',
        'expired-callback': () => { console.log('expired recaptcha'); },
        'callback': token => this.#callback({ ReCaptchaToken: token, ReCaptchaSiteKey: this.#key }),
        'error-callback': () => {
          console.log('error : captcha');
          window.grecaptcha.reset();
        },
      });
      window.grecaptcha?.execute();
    } catch (e) {
      console.log('error : recaptcha render', e);
    }
  }

  /** enterprise 적용 후 문제 없으면 삭제 예정 */
  #reCaptchaRemove() {
    const recaptchaEl = document.body.querySelector('#grecaptcha');
    const recaptchaScript = document.body.querySelector('#grecaptchaScript');
    const recaptchaBadgeEl = document.body.querySelectorAll('.grecaptcha');

    if (recaptchaEl) document.body.removeChild(recaptchaEl);
    if (recaptchaScript) document.body.removeChild(recaptchaScript);
    recaptchaBadgeEl.forEach(e => document.body.removeChild(e));
  }

  #geeCaptchaRemove() {
    const geecaptchaEl = document.body.querySelector('.geetest_panel');
    const geecaptchaScript = document.body.querySelector('#geecaptchaScript');

    if (geecaptchaEl) document.body.removeChild(geecaptchaEl);
    if (geecaptchaScript) document.body.removeChild(geecaptchaScript);
  }
}
