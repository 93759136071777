<template>
  <div daily-challenge-card :class="challengeInfo.status.toLowerCase()">
    <div class="day">{{ $t('promotion.day', {day: challengeInfo.dayNumber}) }}</div>
    <div class="value">{{ currency }}</div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';

export default {
  name: 'DailyChallengeCard',
  props: {
    challengeInfo: { /** @type {DailyBonusItem} */ type: Specific, required: true },
  },
  computed: {
    currency() {
      return this.$promotion.getCurrencyString(this.challengeInfo.bonusAmountCurrency, this.challengeInfo.bonusAmount);
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[daily-challenge-card] { .tc();
  .day { .p(4); .bgc(@c-darkBrown); .c(@c-khakiBrown); .lh(22); .semi-bold();}
  .value { .flex(); .justify-center(); .items-center(); .gap(12); .h(60); .p(10); .bgc(@c-shadowBlack); .fs(18); .c(@c-dimGrey); .bold();
    &::before { .cnt(); .w(48); .h(32); .contain('@{cdn}/promotion-bonus.png'); .bg-c(); .o(0.5); }
  }

  &.active { .-a(@c-brown);
    .day { background: linear-gradient(to right, @c-darkSand 5%, @c-bronzeBrown); .c(@c-white); }
    .value { .c(@c-blond);
      &::before { .o(1); }
    }
  }
  &.failed, &.completed { .rel();
    .day, .value { .o(0.5); }
    &::after { .cnt(); .wf(); .hf(); .abs(); .t(0); .l(0); .no-repeat(); .bg-c(); .bg-w(34); }
  }
  &.failed::after { .bg('@{cdn}/promotion-failed.png'); }
  &.completed::after { .bg('@{cdn}/promotion-check.png'); }
}
</style>
