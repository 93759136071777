<template>
  <div country-drop-select>
    <template v-if="model">
      <SearchDropSelect v-model="model" @input="selected" v-if="userInfo" :template="getView" type="country" :list="list" :size="size" @focus="$emit('focus')"  @blur="$emit('blur')" :placeholder="placeholder"/>
    </template>
  </div>
</template>

<script>
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { gpBrandIds, siteIds } from '@/constants/base/siteMap.mjs';
import _ from 'lodash';

export default {
  name: 'CountryDropSelect',
  components: { SearchDropSelect },
  props: {
    country: { type: Specific, default: null },
    size: { type: String, default: 'lg'},
    placeholder: String,
  },
  data() {
    return {
      list: null,
      model: null,
      blockItem: null,
    };
  },
  computed: {
    landingMode: state('query', 'landingMode'),
    refKey: state('query', 'refKey'),
    channelingName: state('query', 'channelingName'),
    blockCountries: state('env', 'blockCountries'),
    defaultSite: state('env', 'defaultSite'),
    site: state('env', 'site'),
    gpBrand: state('env', 'gpBrand'),
    userInfo: state('user', 'userInfo'),
    isExternal() {
      return (this.site !== siteIds.GGPCW && this.landingMode === 'warp') || this.channelingName;
    },
    isNullSite() {
      return [siteIds.TWOACE, siteIds.GGPOK, siteIds.DAVAOPOKER, siteIds.SEVENXL].includes(this.site);
    },
    isNatural8GpBrand() {
      return this.gpBrand === gpBrandIds.NATURAL8;
    }
  },
  watch: {
    userInfo: 'getList',
  },
  methods: {
    getView(item) {
      const code = item.value.toLowerCase();
      const img = this.size === 'lg' ? `<img class="img-country" src="https://ssl.gg-global-cdn.com/game/platform-front/img/${code}.svg" alt="country">` : '';
      return this.isRtl ? `<em>${item.label}</em>${img}` : `${img}<em>${item.label}</em>`;
    },
    async selected(v) {
      if (!v) return;
      this.$emit('input', v?.value || v);
    },
    async getList() {
      const request = this.isNullSite ? null : this.isNatural8GpBrand || !this.$route.query?.siteId ? 'None' : (this.defaultSite || this.site);
      this.list = (this.refKey ? await this.$services.base.getRefCountryList(this.refKey) : await this.$services.base.getCountryList(request))?.map(o => ({ label: this.$t(`country.${o.CountryCode}`), value: o.CountryCode, ...o }));
      if (this.isExternal && this.blockCountries.length) {
        _.remove(this.list, country => this.blockCountries.some(b => b.country === country.CountryCode));
      }
      this.originList = this.list;

      await this.$services.base.getUserCountry();
      const isCa = this.site === siteIds.WSOPON || location.host.includes('ggpoker.ca') || location.host.includes('wsop');
      this.model = this.list?.find(o => isCa && o.value === 'CA' || o.value === (this.country || this.userInfo.CountryCode)) || this.list?.[0];
      this.$emit('input', this.model?.CountryCode);
    }
  },
  async mounted() {
    await this.getList();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
.RTL {
  [country-drop-select] {
    .img-country { .tr(); .mr(0); .ml(8);
    }
  }
}
[country-drop-select] { .min-h(64); .rel();
  .img-country { .w(18); .ib(); .mr(8); .pt(4);
    &+em { .pt(2); }
  }
  .link { .abs(); .rb(0, -28); .c(@c-red); .medium();
    a { .regular(); .-b(@c-red);}
  }
}
</style>
