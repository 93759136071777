<template>
  <div display-current-transfer-account>
    <div class="content">
<!--      6월 배포 제외 -->
<!--      <LinkAmountItem :label="model.SiteName" :amount="amount" :country-code="countryCode" :country-name="model.CountryCode" :logo-url="model.LogoUrl" :tool-tip-message="model.ToolTipMessage" />-->
      <LinkAmountItem :label="model.SiteName" :amount="null" :country-code="countryCode" :country-name="model.CountryCode" :logo-url="model.LogoUrl" :tool-tip-message="null" />
<!--      <div v-if="showButton" class="footer">-->
<!--        <GpButton type="line-green" :disabled="!enableDebit" @click="$emit('cashOut')">{{ $t('myInfo.cashOut') }}</GpButton>-->
<!--        <GpButton type="submit-green" :disabled="!enableCredit" @click="$emit('addCash')">{{ $t('myInfo.addCash') }}</GpButton>-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>

import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import LinkAmountItem from '@/views/components/pages/my-page/template/link-account/LinkAmountItem.vue';
import Specific from '@shared/types/Specific';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';

export default {
  name: 'DisplayCurrentTransferAccount',
  components: { GpTitle, GpButton, LinkAmountItem },
  props: {
    model: Specific,
    hasAccounts: { type: Boolean, default: false, },
  },
  computed: {
    site: state('env', 'site'),
    amount() {
      return `${getCurrencySymbol(this.model.Currency)} ${this.model.Amount}`;
    },
    countryCode() {
      return this.model.CountryCode ? this.model.CountryCode.toLowerCase() : null;
    },
    showButton() {
      return false; // this.model.IsEnabled && this.hasAccounts;
    },
    enableDebit() {
      const debit = this.model.Debit;
      if (!debit) return false;
      if (debit?.description === 'ACCOUNT_UNVERIFIED') {
        return true;
      }
      return this.model.Debit ? this.model.Debit.available : false;
    },
    enableCredit() {
      const credit = this.model.Credit;
      if (!credit) return false;
      if (credit?.description === 'ACCOUNT_UNVERIFIED') {
        return true;
      }
      return this.model.Credit ? this.model.Credit.available : false;
    },
  },
};
</script>

<style lang="less">
@import '@/less/proj.less';
[display-current-transfer-account] {
  .content {.flex();.flex-dc(); .bgc(#fff); .gap(10); .br(16);}
  .footer {.flex(); .flex-dr(); .gap(10); .p(20); .pt(0);}
}

</style>
