import FpInfo from '@/views/components/pages/forgot-password/template/FpInfo.vue';
import FpSelect from '@/views/components/pages/forgot-password/template/FpSelect.vue';
import FpVerify from '@/views/components/pages/forgot-password/template/FpVerify.vue';
import FpReset from '@/views/components/pages/forgot-password/template/FpReset.vue';
import FpError from '@/views/components/pages/forgot-password/template/FpError.vue';

export const routeNames = {
  FpInfo: 'FpInfo',
  FpSelect: 'FpSelect',
  FpVerify: 'FpVerify',
  FpReset: 'FpReset',
  FpComplete: 'FpComplete',
  FpError: 'FpError',
  FpNotSupportSite: 'FpNotSupportSite',
};

const apiType = {
  Mobile: 'Mobile',
  Email: 'Email'
};

const siteModel = () => ({
  model: {
    CountryCode: null,
    MobileNumber: null,
    Email: null,
    Code: null,
    Token: null,
    Type: 'RESET_PASSWORD',
    Method: 'CODE',
    Password: null,
    ConfirmPassword: null,
    AccessToken: null,
    VerificationMethods: null,
    LoginMethod: null,
    NpApiType: 'Email',
    UserName: null,
    MessageType: 0,
    SendVerificationType: 'EMAIL',
    Language: null,

    PlayerEmailVerificationType: 'ForgotPassword',
    VerificationType: 'ForgotPassword',
  },

  [routeNames.FpInfo]: {
    title: 'fpTitle',
    step: 0,
    component: FpInfo,
    npOnly: false,
    service: 'verification',
    api: 'sendVerification',
    captcha: {
      use: {
        OP: true,
        NP: true,
      },
      action: {
        OP: { default: { action: 'RESET_PASSWORD' } },
        NP: { default: { action: 'ForgotPasswordGetMethod' } },
      }
    },
    prevRoute: {
      OP: null,
      NP: null,
    },
    nextRoute: {
      OP: routeNames.FpVerify,
      NP: routeNames.FpSelect,
    },

    btnName: 'next'
  },
  [routeNames.FpSelect]: {
    title: 'fpTitle',
    step: 1,
    npOnly: true,
    component: FpSelect,
    service: 'verification',
    api: 'sendFpVerification',
    captcha: {
      use: {
        OP: false,
        NP: true,
      },
      action: {
        OP: { default: null, },
        NP: {
          [apiType.Mobile]: { action: 'ForgotPasswordMobileSendCode' },
          [apiType.Email]: { action: 'ForgotPasswordEmailSendCode' },
        },
      }
    },
    prevRoute: {
      OP: routeNames.FpInfo,
      NP: routeNames.FpInfo,
    },
    nextRoute: {
      OP: null,
      NP: routeNames.FpVerify,
    },
    btnName: 'next'
  },
  [routeNames.FpVerify]: {
    title: 'fpTitle',
    step: 2,
    npOnly: false,
    component: FpVerify,
    service: 'verification',
    api: 'verifyCode',
    captcha: {
      use: {
        OP: false,
        NP: false,
      },
      action: {
        OP: { default: null },
        NP: { default: null, },
      }
    },
    nextRoute: {
      OP: routeNames.FpReset,
      NP: routeNames.FpReset,
    },
    prevRoute: {
      OP: routeNames.FpInfo,
      NP: routeNames.FpSelect,
    },
    btnName: 'verify'
  },
  [routeNames.FpReset]: {
    title: 'fpTitle',
    step: 3,
    npOnly: false,
    component: FpReset,
    service: 'user',
    api: 'sendResetPassword',
    captcha: {
      use: {
        OP: false,
        NP: false, //true,
      },
      action: {
        OP: { default: null, },
        NP: { default: null }, // { action: 'ForgotPasswordReset' },
      }
    },
    prevRoute: {
      OP: null,
      NP: null,
    },
    nextRoute: {
      OP: routeNames.FpComplete,
      NP: routeNames.FpComplete,
    },
    btnName: 'reset'
  },
  [routeNames.FpError]: {
    title: 'forgotPassword.title.somethingWentWrong',
    step: 4,
    npOnly: false,
    component: FpError,
    service: null,
    api: null,
    captcha: {
      use: {
        OP: false,
        NP: false,
      },
      action: {
        OP: { default: null },
        NP: { default: null },
      }
    },
    nextRoute: {
      OP: null,
      NP: null,
    },
    btnName: 'tryAgain'
  },
});



export default siteModel;