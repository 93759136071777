<template>
  <div transfer-history-item>
    <div class="header">
      <div class="date">{{ displayDate }}</div>
      <div class="status">
        <TooltipItem v-if="toolTipMessage" :text="toolTipMessage" direction="bottom" align="right">
          <FontIcon name="exclamation-circle-regular-sharp" />
        </TooltipItem>
        <GpBadge :type="statusType">{{ status }}</GpBadge>
      </div>
    </div>
    <div class="content">
      <div class="brand">
        <div><img :class="['img-country', ]" :src="debitLogoUrl" :alt="debitSiteId" width="28" height="28"></div>
        <div><p>{{ debitSiteId }}</p></div>
      </div>
      <div class="chevron">
        <FontIcon name="chevron-right" />
      </div>
      <div class="brand">
        <div><img :class="['img-country',]" :src="creditLogoUrl" :alt="creditSiteId" width="28" height="28"></div>
        <div><p>{{ creditSiteId }}</p></div>
      </div>
    </div>
    <div class="footer">
      <div v-if="debitAmount" class="debit">{{ displayAmount(debitCurrency, debitAmount) }}</div>
      <div v-if="creditAmount && debitCurrency!==creditCurrency" class="credit">{{ displayAmount(creditCurrency, creditAmount) }}</div>
    </div>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';
import TooltipItem from '@/views/components/gg-pass/TooltipItem.vue';
import GpBadge from '@/views/components/gg-pass/GpBadge.vue';
import { getDisplayAmount } from '@/utils/amountUtil';
import { bothErrorCodes } from '@/constants/base/my-page';
import { toFullDateT } from '@/utils/dateTimeUtil';

export default {
  name: 'TransferHistoryItem',
  props: {
    item: Specific,
  },
  components: { TooltipItem, FontIcon, GpBadge },
  computed: {
    credit() {
      if (this.item.credit) return this.item.credit;
      else if (this.item.cancel) return this.item.cancel;
      return null;
    },
    debit() {
      return this.item.debit;
    },
    creditSiteId() {
      return this.credit?.siteId || null;
    },
    debitSiteId() {
      return this.debit?.siteId || null;
    },
    debitCurrency() {
      return this.debit?.currencyId || null;
    },
    creditCurrency() {
      return this.credit?.currencyId || null;
    },
    debitAmount() {
      return this.debit?.amount || null;
    },
    creditAmount() {
      return this.credit?.amount || null;
    },
    debitLogoUrl() {
      return this.debit?.brandSite?.logoUrl;
    },
    creditLogoUrl() {
      return this.credit?.brandSite?.logoUrl;
    },
    toolTipMessage() {
      // 우선 순위 1. Debit, 2. Credit, 3. Cancel
      const debitResponseType = this.debit?.responseType;
      const creditResponseType = this.credit?.responseType;
      const cancelResponseType = this.cancel?.responseType;

      if (debitResponseType && debitResponseType !== 'SUCCESS') {
        // this.$t('sender') : this.$t('recipient');
        if (bothErrorCodes.includes(debitResponseType)) return this.$t(debitResponseType, { type: this.$t('sender') });
        if(debitResponseType === 'ACCOUNT_TRANSFER_BLOCK_PASSWORD_CHANGE') return this.$t('ACCOUNT_TRANSFER_BLOCK_PASSWORD_CHANGE_HISTORY')
        return this.$t(debitResponseType);
      }
      if (creditResponseType && creditResponseType !== 'SUCCESS') {
        if (bothErrorCodes.includes(creditResponseType)) return this.$t(creditResponseType, { type: this.$t('recipient') });
        if(creditResponseType === 'ACCOUNT_TRANSFER_BLOCK_PASSWORD_CHANGE') return this.$t('ACCOUNT_TRANSFER_BLOCK_PASSWORD_CHANGE_HISTORY')
        return this.$t(creditResponseType);
      }
      if (!this.item.credit && cancelResponseType && cancelResponseType !== 'SUCCESS') {
        return this.$t(cancelResponseType);
      }
      return null;
    },
    displayDate() {
      const locale = this.$i18n('locale');
      return toFullDateT(this.item.requestedAt, locale);
    },
    statusType() {
      switch (this.item.status) {
        case 'SUCCESS':
          return 'SUCCESS';
        case 'FAILED':
          return 'FAILED';
        case 'IN_PROGRESS':
          return 'PENDING';
        case 'PENDING':
          return 'PENDING';
        case 'PAUSED':
          return 'FAILED';
        case 'CANCELLED':
          return 'FAILED';
      }
    },
    status() {
      switch (this.item.status) {
        case 'SUCCESS':
          return this.$t('success');
        case 'FAILED':
          return this.$t('failed');
        case 'IN_PROGRESS':
          return this.$t('processing');
        case 'PENDING':
          return this.$t('processing');
        case 'PAUSED':
          return this.$t('failed');
        case 'CANCELLED':
          return this.$t('failed');
      }
    }
  },
  methods: {
    displayAmount(currency, amount) {
      return getDisplayAmount(currency, amount);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[transfer-history-item] {.p(20); .bgc(#fff); .c(black); .flex(); .flex-dc(); .gap(10); .br(16);
  .header {.flex(); .space-between(); .items-center();
    .date {.c(#737373); .fs(12);}
    .status {.flex(); .gap(5); .items-center();}
  }
  .chevron {
    [font-icon] {.fs(14);}
  }
  .content {.flex(); .gap(20);.items-end(); border-bottom: 1px solid #D9D9E5; .pb(10);
    .brand {flex: 1; .flex(); .flex-dc(); .justify-center(); .items-center(); .fs(14);}
    .img-country {.wh(28); object-fit: fill;}
  }
  .footer { .flex(); .flex-dc(); .items-end();
    .debit {.fs(14); font-weight: 600;}
    .credit {.fs(12); .c(#737373);}
  }
}
</style>
