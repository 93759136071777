import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import ssrMixin from '@shared/mixins/ssr';
import globalMixin from '@shared/mixins/global';
import entryClient from '@shared/ssr/entryClient';
import entryServer from '@shared/ssr/entryServer';

export default ({ App, mixin = null, createStore, createRouter, createServiceManager, beforeCreate }) => {
  /**
   *
   * @param cookies
   * @param beforeCreateRouterOnEntry Store 준비단계
   * @param beforeCreateOnEntry Router 생성 이후 Vue 생성되기 전 (entryClient 에서만 사용)
   */

  const createApp = async (cookies, beforeCreateRouterOnEntry, beforeCreateOnEntry) => {
    if (!Vue.globalsRegistered) {
      Vue.globalsRegistered = true;

      Vue.mixin(ssrMixin);
      Vue.mixin(globalMixin);
      if (mixin) Vue.mixin(mixin);
    }

    const store = createStore();
    const services = createServiceManager({ store, cookies });
    await beforeCreateRouterOnEntry(services, store);
    const router = await createRouter(store);

    sync(store, router);
    if (beforeCreateOnEntry) await beforeCreateOnEntry(services, store, router);

    beforeCreate(Vue, services, router, store);
    const app = new Vue({
      render: h => h(App),
      router,
      store,
    });

    return { app, router, store, services };
  };

  return {
    getEntryClient: entryClient(createApp),
    getEntryServer: entryServer(createApp),
  };
};
