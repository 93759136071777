import { render, staticRenderFns } from "./LocaleLink.vue?vue&type=template&id=6fd110ce"
import script from "./LocaleLink.vue?vue&type=script&lang=js"
export * from "./LocaleLink.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports