<template>
  <GpFormTemplate np-social-login>
    <template #header>
      <GpDescription :message="description" />
    </template>
    <template #content>
      <DisplaySocialList v-if="oAuths" :model="oAuths" :structure="structure" />
    </template>
    <!--    <template #footer="{validate, invalid}">-->
    <!--      <GpButton type="submit-green" @click="submit(validate)" :disabled="!!invalid || isApiError">{{ $t('save') }}</GpButton>-->
    <!--    </template>-->
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import npErrorHandler from '@/mixins/my-info/npErrorHandler';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import DisplaySocialList from '@/views/components/pages/my-page/template/views/DisplaySocialList.vue';

export default {
  name: 'NpSocialAccounts',
  components: { GpDescription, GpTitle, GpFormTemplate, GpButton, DisplaySocialList },
  mixins: [npErrorHandler],
  props: {
    structure: { type: Specific, default: null },
    model: Specific,
  },
  data() {
    return {
      oAuths: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    onePassInfo: state('user', 'onePassInfo'),
    csEmail: state('env', 'csEmail'),
    description() {
      /// return this.$t('onBoarding.surepayStepDescription', { csEmail: this.csEmail });
      return this.$t('myInfo.socialLinked.description');
      // 'You can check the social login accounts linked to your GGPass account.';
    },
  },
  methods: {
    async initialize() {
      // oAuths 객체
      // Oauth Type : GOOGLE, FACEBOOK, APPLE
      const oauths = this.onePassInfo?.oauths || [];
      // const testlist = [
      //   {
      //     "onepassOauthId": 689,
      //     "oauthType": "APPLE",
      //     "oauthEmail": "eric_test0012@mailinator.com",
      //     "emailVerified": true,
      //     "isPrivateEmail": false,
      //     "createdAt": "2024-06-13T03:08:52Z"
      //   },
      //   {
      //     "onepassOauthId": 689,
      //     "oauthType": "FACEBOOK",
      //     "oauthEmail": "eric_test0012@mailinator.com",
      //     "emailVerified": true,
      //     "isPrivateEmail": false,
      //     "createdAt": "2024-06-13T03:08:52Z"
      //   }
      // ]
      this.oAuths = oauths
    },
    async submit(validate) {
    },
  },

  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-social-login] {
}

</style>