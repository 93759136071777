import createPlugin from '@shared/plugins/validate';
import defaultRules from '@shared/plugins/validate/rules';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ValidationErrors from '@shared/plugins/validate/components/ValidationErrors.vue';
import moment from 'moment';
import ggpass from '@/plugins/validate/ggpass';
import { getBirth, getCnpInfo } from '@/utils/userUtil';
import { countryWritingPatterns, apostrophePattern, hyphenPattern, spacePattern, specialCharactersPattern } from '@shared/utils/regexUtils.mjs';
import { toYMD } from '@/utils/dateTimeUtil';

/**
 * personal Name에서 사용되는 정규식 체크
 * @param {string} name - 이름
 * @param {number} min - 최소 입력 값
 * @param {number} max - 최대 입력 값
 * @param {number} countryNumber - countryNumber
 * @returns {boolean}
 */
const validatePersonalName = ({ name, min = 0, max = 0, countryNumber = 0 }) => {
  const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
  const countryPattern = countryNumber ? (countryWritingPatterns.find(p => p.key === Number(countryNumber))?.value ?? '') : '';
  return new RegExp(`^[A-Za-z${hyphenPattern}${spacePattern}${apostrophePattern}${countryPattern}]${minMaxPattern}$`).test(name);
};

const rgLimit = ({ value, limit }) => {
  if (!value || isNaN(value)) value = 999999999;
  if (!limit || isNaN(limit)) limit = 999999999;
  return +value <= +limit;
};

/**
 * @param {ServiceManager} services
 * @returns {{}}
 */
// eslint-disable-next-line no-unused-vars
export const makeRules = (services = null) => ({
  ...defaultRules,
  ...ggpass,
  requiredNone: defaultRules.required,
  minMaxNone: defaultRules.minMax,
  minMaxAmount: defaultRules.minMax,
  requiredSelectNone: defaultRules.requiredSelect,
  /**
   * Personal Name Step Required Rule
   */
  requiredPersonalName: {
    params: ['min', 'max', 'countryNumber'],
    validate: (name, { min, max, countryNumber }) => defaultRules.required.validate(name)?.valid && validatePersonalName({ name, min, max, countryNumber }),
    computesRequired: true,
  },
  /**
   * Personal Name Step Rule
   */
  personalName: {
    params: ['min', 'max', 'countryNumber'],
    validate: (name, { min, max, countryNumber }) => validatePersonalName({ name, min, max, countryNumber }),
    computesRequired: true,
  },
  /**
   * Personal Birth Step Date of Birth Rule
   */
  dateOfBirth: {
    validate: values => {
      if (!values) return;
      const { year, month, day } = values;
      const m = month?.value;
      if (!year && !m && !day) return true;
      if (String(year).length !== 4) return false;
      const date = new Date(year, m - 1, day);
      const yearIsValid = parseInt(year, 10) === date.getFullYear();
      const monthIsValid = parseInt(m, 10) === date.getMonth() + 1;
      const dayIsValid = parseInt(day, 10) === date.getDate();
      return yearIsValid && monthIsValid && dayIsValid;
    },
    computesRequired: true,
  },
  /**
   * Personal Birth Step Place of Birth Required Rule
   */
  requiredPlaceOfBirth: {
    params: ['min', 'max', 'countryNumber'],
    validate: (value, { min, max, countryNumber }) => {
      if (!value) return;
      const validRequired = defaultRules.required.validate(value)?.valid;
      const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
      const countryPattern = countryNumber ? (countryWritingPatterns.find(p => p.key === Number(countryNumber))?.value ?? '') : '';
      return validRequired && (new RegExp(`^[A-Za-z0-9${spacePattern}${specialCharactersPattern}${countryPattern}]${minMaxPattern}$`)).test(value);
    },
    computesRequired: true,
  },
  /**
   * Personal Address Step Address required Rule
   */
  requiredAddress: {
    params: ['min', 'max', 'countryNumber'],
    validate: (value, { min, max, countryNumber }) => {
      if (!value) return;
      const validRequired = defaultRules.required.validate(value)?.valid;
      const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
      const countryPattern = countryNumber ? (countryWritingPatterns.find(p => p.key === Number(countryNumber))?.value ?? '') : '';
      return validRequired && (new RegExp(`^[A-Za-z0-9${spacePattern}${specialCharactersPattern}${countryPattern}]${minMaxPattern}$`)).test(value);
    },
    computesRequired: true,
  },
  /**
   * Personal Address Step Address Rule
   */
  address: {
    params: ['min', 'max', 'countryNumber'],
    validate: (value, { min, max, countryNumber }) => {
      const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
      const countryPattern = countryNumber ? (countryWritingPatterns.find(p => p.key === Number(countryNumber))?.value ?? '') : '';
      return (new RegExp(`^[A-Za-z0-9${spacePattern}${specialCharactersPattern}${countryPattern}]${minMaxPattern}$`)).test(value);
    },
    computesRequired: true,
  },
  /**
   * Required Number Rule
   */
  requiredNumber: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => {
      if (!value) return;
      const validRequired = defaultRules.required.validate(value)?.valid;
      const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
      return validRequired && (new RegExp(`^[0-9]${minMaxPattern}$`)).test(value);
    },
    computesRequired: true,
  },
  requiredDisposableIncome: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => {
      if (!value) return;
      const validRequired = defaultRules.required.validate(value)?.valid;
      const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
      return validRequired && (new RegExp(`^[0-9]${minMaxPattern}$`)).test(value);
    },
    computesRequired: true,
  },
  requiredEmployerName: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => {
      if (!value) return;
      const validRequired = defaultRules.required.validate(value)?.valid;
      const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
      return validRequired && (new RegExp(`^[0-9]${minMaxPattern}$`)).test(value);
    },
    computesRequired: true,
  },
  requiredDesiredLimit: {
    params: ['min', 'max', 'decimals'],
    validate: (value, { min, max, decimals = 2 }) => {
      if (!value) return;
      value = value.toString();
      const splitValue = value.split('.');
      const validRequired = splitValue.length > 1 ? (defaultRules.required.validate(value[0])?.valid && defaultRules.required.validate(value[1])?.valid) : defaultRules.required.validate(value)?.valid;
      const valueRequired = splitValue.length > 1 ? (new RegExp(`^\\d{${min},${max}}[.]\\d{1,${decimals}}$`)).test(value) : (new RegExp(`^\\d{${min},${max}}$`)).test(value);
      return validRequired && valueRequired;
    },
    computesRequired: true,
  },
  confirmAge: {
    params: ['age'],
    validate: (value, { age }) => {
      try {
        /* month value 와 moment 에서 사용하는 month value 값이 다름. value.month = 1 이면 1월 moment = 1이면 2월 */
        const v = { ...value };
        const { year, month, day } = v;
        const m = month?.value;
        if (!year && !m && !day) return true;
        const birthDate = toYMD(year + m + day);
        const currentDate = moment();
        return age <= currentDate.diff(birthDate, 'years');
      } catch {
        console.log('error : validate confirmAge');
      }
    },
  },
  requiredEmploymentStatus: defaultRules.requiredSelect,
  requiredOccupation: defaultRules.requiredSelect,
  requiredAffordabilityCheck: defaultRules.requiredCheck,
  requiredEmployerBusiness: defaultRules.required,
  requiredDepositLimitLugas: {
    params: ['currency', 'csEmail', 'limit', 'amount'],
    validate: (value, { limit }) => {
      const validRequired = defaultRules.required.validate(value)?.valid;
      return validRequired && rgLimit({ value, limit });
    },
    computesRequired: false,
  },
  requiredDepositLimit: {
    params: ['currency', 'limit', 'amount'],
    validate: (value, { limit }) => {
      const validRequired = defaultRules.required.validate(value)?.valid;
      return validRequired && rgLimit({ value, limit });
    },
    computesRequired: false,
  },
  depositLimit: {
    params: ['currency', 'limit', 'amount'],
    validate: (value, { limit }) => rgLimit({ value, limit }),
    computesRequired: false,
  },
  maximumDepositLimit: {
    params: ['currency', 'min', 'max'],
    validate: (value, { max }) => +value <= +max,
    computesRequired: false,
  },
  minimumDepositLimit: {
    params: ['currency', 'min'],
    validate: (value, { min }) => +value >= +min,
    computesRequired: false,
  },
  exceedMonthlyLimit: {
    params: ['limit'],
    validate: (value, { limit }) => +limit >= +value,
    computesRequired: false,
  },
  exceedWeeklyLimit: {
    params: ['limit'],
    validate: (value, { limit }) => +limit >= +value,
    computesRequired: false,
  },
  depositLimitDE: {
    params: ['currency', 'csEmail', 'limit', 'amount'],
    validate: (value, { limit }) => rgLimit({ value, limit }),
    computesRequired: false,
  },
  dailyLimit: {
    params: ['limit', 'day1', 'day2'],
    validate: (value, { limit }) => rgLimit({ value, limit }),
    computesRequired: true,
  },
  weeklyLimit: {
    params: ['limit', 'lowerPeriod', 'higherPeriod'],
    validate: (value, { limit }) => rgLimit({ value, limit }),
    computesRequired: true,
  },
  monthlyLimit: {
    params: ['limit', 'lowerPeriod', 'higherPeriod'],
    validate: (value, { limit }) => rgLimit({ value, limit }),
    computesRequired: true,
  },
  lossLimitDouble: {
    params: ['lowerPeriod', 'higherPeriod', 'limit'],
    validate: (value, { limit }) => {
      if (limit === 'null') return true;
      return Number(value) <= Number(limit);
    },
    computesRequired: true,
  },
  minimumLoginTimeLimit: {
    params: ['min'],
    validate: (value, { min }) => +value >= +min,
    computesRequired: false,
  },
  maximumLoginTimeLimit: {
    params: ['min', 'max'],
    validate: (value, { max }) => +value <= +max,
    computesRequired: false,
  },
  loginTimeMinMax: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => {
      if (!defaultRules.includeNumber(value) || !defaultRules.includeNumber(min) || !defaultRules.includeNumber(max)) return false;
      const minVal = Number(min);
      const maxVal = Number(max);
      return minVal <= Number(value) && maxVal >= Number(value);
    },
    computesRequired: false,
  },
  confirmMonth: value => +value.month,
  confirmDay: value => {
    function isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    }
    try {
      const day = +value.day;
      const month = +value.month;
      const year = +value.year;
      if (!month) return day < 31;
      const dayLimit = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (month === 2 && isLeapYear(year)) dayLimit[2] += 1;
      return day <= dayLimit[month];
    } catch {
      console.log('error : validate confirmDay');
      return false;
    }
  },
  bonusCode: value => value.match(/^[A-Za-z0-9-_.]+$/g),
  partnerCode: value => value.match(/^[A-Za-z0-9-_.]+$/g),
  containEmail: {
    params: ['email'],
    validate: (value, { email }) => {
      try {
        return !(value || '').toLowerCase().includes((email || '').split('@')[0].toLowerCase());
      } catch {
        console.log('error : validate containEmail');
        return false;
      }
    },
  },
// /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*_\-+=`|\\(){}\[\]:;"'<>,.?\/])[A-Za-z\d~!@#$%^&*_\-+=`|\\(){}\[\]:;"'<>,.?\/]{8,}$/.test(value),.
  includeNumberAndAlphabet: value => /^(?=.*[a-z])(?=.*\d)[A-Za-z\d~!@#$%^&*_\-+=`|\\(){}\[\]:;"'<>,.?\/]{8,}$/.test(value),
  requiredSignEmail: value => /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/i.test(value),
  requiredCnp: {
    validate: value => {
      const re = /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/;
      let bigSum = 0;
      let ctrlDigit = 0;
      let control = '279146358279',
        i = 0;
      if (re.test(value)) {
        for (i = 0; i < 12; i++) {
          bigSum += value[i] * control[i];
        }
        ctrlDigit = bigSum % 11;
        if (ctrlDigit === 10) {
          ctrlDigit = 1;
        }

        const n = value.split('');
        const v = Number((n[0] * 2 + n[1] * 7 + n[2] * 9 + n[3] * 1 + n[4] * 4 + n[5] * 6 + n[6] * 3 + n[7] * 5 + n[8] * 8 + n[9] * 2 + n[10] * 7 + n[11] * 9) % 11);
        if (+n[n.length - 1] !== +v && +(n[n.length - 1] + '0') !== +v) {
          return false;
        }

        if (ctrlDigit !== parseInt(value[12], 10)) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    computesRequired: true,
  },
  bsn: value => {
    if (value === '00000000000' || value.length !== 9) return false;
    const values = value.split('');
    const [a, b, c, d, e, f, g, h, i] = values.map((char) => parseInt(char, 10));
    const result = 9 * a + 8 * b + 7 * c + 6 * d + 5 * e + 4 * f + 3 * g + 2 * h + -1 * i;
    return result > 0 && result % 11 === 0;
  },
  date: value => {
    const year = value.substr(0, 2);
    const month = value.substr(2, 2);
    const day = value.substr(4, 2);
    const date = new Date(year, month - 1, day);
    const yearIsValid = parseInt(year, 10) === date.getFullYear();
    const monthIsValid = parseInt(month, 10) === date.getMonth() + 1;
    const dayIsValid = parseInt(day, 10) === date.getDate();

    return yearIsValid && monthIsValid && dayIsValid;
  },
  requiredNationalRegisterNumber: {
    params: ['inputYear', 'inputMonth', 'inputDay', 'inputGender'],
    validate: (value, { inputYear, inputMonth, inputDay, inputGender }) => {
      if (!defaultRules.required.validate(value)?.valid || value?.length < 15) return false;
      try {
        const nationalId = value.replace(/\D/g, '');
        if (!nationalId) return false;
        if (!/\d{11}/.test(nationalId)) return false;
        if (!inputYear || !inputMonth || !inputDay) return false;

        let toControl = nationalId.slice(0, 9);
        const control = nationalId.slice(9, 11);

        const nationalIdDate = nationalId.slice(0, 6);
        const nationalIdYear = parseInt(nationalIdDate.slice(0, 2), 10);
        let nationalIdMonth = parseInt(nationalIdDate.slice(2, 4), 10);
        const nationalIdDay = parseInt(nationalIdDate.slice(4, 6), 10);

        if (nationalIdMonth > 20 && nationalIdMonth < 40) nationalIdMonth -= 20;
        else if (nationalIdMonth > 40 && nationalIdMonth < 60) nationalIdMonth -= 40;

        const year = parseInt(inputYear.toString().slice(2, 6), 10);
        const month = parseInt(inputMonth, 10);
        const day = parseInt(inputDay, 10);

        if (year !== nationalIdYear || month !== nationalIdMonth || day !== nationalIdDay) return false;

        const nationalIdGender = parseInt(nationalId.slice(8, 9), 10);
        const isEvenNumber = nationalIdGender % 2 === 0;

        if ((inputGender === 'Male' && isEvenNumber === true) || (inputGender === 'Female' && isEvenNumber === false)) return false;

        if (inputYear >= 2000) toControl = `2${toControl.toString()}`;

        toControl = parseInt(toControl, 10);
        let modulo = 97 - (toControl % 97);
        modulo = modulo < 10 ? `0${modulo}` : modulo.toString();

        return control === modulo;
      } catch {
        return false;
      }
    },
    computesRequired: true,
  },
  birthNumber: {
    validate: value => {
      return Boolean(getBirth(value));
    },
    computesRequired: true,
  },
  requiredIban: {
    params: ['countryCode', 'countryNumber', 'min', 'max'],
    validate: (value, { countryCode, countryNumber, min, max }) => {
      if (!value) return;
      if (!countryCode) return false;
      const validRequired = defaultRules.required.validate(value)?.valid;
      const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
      const countryPattern = countryNumber ? (countryWritingPatterns.find(p => p.key === Number(countryNumber))?.value ?? '') : '';
      return validRequired && value.startsWith(countryCode) && (new RegExp(`^[A-Z0-9${spacePattern}${specialCharactersPattern}${countryPattern}]${minMaxPattern}$`)).test(value);
    },
    computesRequired: true,
  },
  channelingBonusSameValue: {
    params: ['sameValue'],
    validate: (value, { sameValue }) => {
      if (!value) return true;
      return !((value || '').toLowerCase() === (sameValue || '').toLowerCase());
      // return !(value === sameValue);
    },
    computesRequired: true,
  },
  channelingPartnerSameValue: {
    params: ['sameValue'],
    validate: (value, { sameValue }) => {
      if (!value) return true;
      return !((value || '').toLowerCase() === (sameValue || '').toLowerCase());
      // return !(value === sameValue);
    },
    computesRequired: true,
  },
});

export default {
  install(Vue, { services }) {
    const { validate, extend, checkValidate } = createPlugin();
    // noinspection JSUnresolvedVariable
    const rules = makeRules(services);
    extend(rules);
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
    Vue.component('ValidationErrors', ValidationErrors);
    Vue.prototype.$validate = validate;
    Vue.prototype.$checkValidate = checkValidate;

    Vue.prototype.$getError = async function (validator, errorCallback) {
      try {
        await Vue.prototype.$validate(validator, errorCallback);
        return null;
      } catch (err) {
        return err;
      }
    };
  },
};
