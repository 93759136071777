<template>
  <div personal-ok>
    <p v-html="$t('_.description-ok')"></p>
  </div>
</template>

<script>

export default {
  name: 'PersonalOk',
  lexicon: 'onBoarding.personal',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[personal-ok] {
  p { .fs(16); }
}
</style>
