<template>
  <div not-found>
    <ErrorTitle title="notFound.title" description="notFound.description" :options="errorOption" v-if="isReady"/>
  </div>
</template>

<script>
import { appendQuery } from '@shared/utils/urlUtils.mjs';
import { state } from '@shared/utils/storeUtils.mjs';
import { queries } from '@/utils/baseUtil';
import ErrorTitle from '@/views/components/extra/ErrorTitle.vue';

export default {
  name: 'NotFound',
  components: { ErrorTitle },
  data() {
    return {
      isReady: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    ggpass: state('env', 'ggpass'),
    csEmail: state('env', 'csEmail'),
    errorOption() {
      return { name: this.csEmail || 'help@ggpoker.com' };
    }
  },
  mounted() {
    const q = queries(this.ggpass);
    if (['/landing', '/redirect'].includes(location.pathname)) {
      const bonusTags = ['qtag', 'qtag1', 'btag', 'btag1'];
      Object.keys(bonusTags).forEach(o => this.$services.cookie.setBonusTag(o, q[o]));
      if (q.targeturl) {
        q.isTargetUrl = true;
        window.location.href = appendQuery(q.targeturl, q);
        return;
      }
      this.routePath('*');
    }
    this.isReady = true;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[not-found] { .rel(); .wh(100%, calc(var(--innerHeight) - 160px));
  [error-title] { .abs(); .lt(50%, 50%); .t-xyc();}
}
</style>