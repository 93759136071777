<template>
  <div reaffirm-wsop>
    <ReaffirmWsopInformation v-if="init" :model-info="modelInfo" :structure="structure" :item="reaffirmItem" @complete="complete" @start="start" />
    <template v-else>
      <component :is="component" :model-info="modelInfo" :structure="structure" :item="reaffirmItem" :template="template" :title="title" :form-tag="formTag" :form-class="formClass" :button-tag="buttonTag" :button-class="buttonClass" :button-name="buttonName" />
      <template v-if="!background">
        <p v-if="errorMsg" class="error">{{ errorMsg }}</p>
        <ProgressButton v-if="!isComplete" :button-name="buttonName" :progress="progress" :name="buttonTag" :class="buttonClass" :disabled="structure.error || Boolean(errorMsg) || invalid" @click="confirm" />
      </template>
    </template>
  </div>
</template>

<script>

import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';
import ReaffirmWsopInformation from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/wsop/ReaffirmWsopInformation.vue';
import ReaffirmWsopNames from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/wsop/ReaffirmWsopNames.vue';
import ReaffirmWsopBirth from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/wsop/ReaffirmWsopBirth.vue';
import ReaffirmWsopAddress from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/wsop/ReaffirmWsopAddress.vue';
import ReaffirmWsopOccupation from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/wsop/ReaffirmWsopOccupation.vue';
import ReaffirmWsopPep from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/wsop/ReaffirmWsopPep.vue';
import ReaffirmWsopComplete from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/wsop/ReaffirmWsopComplete.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
export default {
  name: 'ReaffirmWsop',
  lexicon: 'onBoarding.reaffirm.wsop',
  components: { ProgressButton, ReaffirmWsopInformation },
  mixins: [baseTemplate],
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      init: true,
      progress: false,
      templates: [
        { component: ReaffirmWsopNames, route: routeNames.personalName, api: 'editPersonalName', title: 'onBoarding.title.personalDetails', formTag: 'WSOPca_Step11_Personal_Details_Name_View', formClass: 'kyc-personal-details-view', buttonTag: 'WSOPca_Step12_Personal_Details_Name_Button', buttonClass: 'kyc-personal-details-btn' },
        { component: ReaffirmWsopBirth, route: routeNames.personalBirth, api: 'editPersonalBirth', title: 'onBoarding.title.personalDetails', formTag: 'WSOPca_Step13_Date_of_Birth_View', formClass: 'kyc-date-of-birth-view', buttonTag: 'WSOPca_Step14_Date_of_Birth_Button', buttonClass: 'kyc-date-of-birth-btn' },
        { component: ReaffirmWsopAddress, route: routeNames.personalAddress, api: 'editPersonalAddress', title: 'onBoarding.title.address', formTag: 'WSOPca_Step15_Address_View', formClass: 'kyc-address-view', buttonTag: 'WSOPca_Step16_Address_Button', buttonClass: 'kyc-address-btn', background: true },
        { component: ReaffirmWsopOccupation, route: routeNames.personalOccupation, api: 'editPersonalOccupation', title: 'onBoarding.occupation', formTag: 'WSOPca_Step17_Occupation_View', formClass: 'kyc-occupation-view', buttonTag: 'WSOPca_Step18_Occupation_Button', buttonClass: 'kyc-occupation-btn' },
        { component: ReaffirmWsopPep, route: routeNames.personalPep, api: 'updatePersonalPep', title: 'onBoarding.title.selfIdentification', formTag: 'WSOPca_Step19_PEP_View', formClass: 'kyc-pep-view', buttonTag: 'WSOPca_Step20_PEP_Button', buttonClass: 'kyc-pep-btn' },
      ],
    };
  },
  watch: {
    template() {
      this.item.title = this.title;
    }
  },
  computed: {
    env: state('env', 'env'),
    csEmail: state('env', 'csEmail'),
    step() {
      return this.structure.step;
    },
    reaffirmItem() {
      return this.structure?.list[this.step];
    },
    template() {
      return this.templates.find(o => o.route === this.customSteps?.[this.customStep]);
    },
    customStep() {
      return this.structure.customStep;
    },
    customSteps() {
      return this.structure.customSteps;
    },
    lastStep() {
      return this.customStep >= this.customSteps?.length - 1;
    },
    component() {
      return this.template?.component ?? ReaffirmWsopComplete;
    },
    api() {
      return this.template?.api;
    },
    title() {
      return this.template?.title;
    },
    background() {
      return this.template?.background;
    },
    formTag() {
      return this.template?.formTag;
    },
    formClass() {
      return this.template?.formClass;
    },
    buttonTag() {
      return this.template?.buttonTag;
    },
    buttonClass() {
      return this.template?.buttonClass;
    },
    buttonName() {
      return this.lastStep ? 'submit' : 'next';
    },
    isProd() {
      return this.env === 'production';
    },
    isComplete() {
      return this.component === ReaffirmWsopComplete;
    },
  },
  methods: {
    complete() {
      this.init = false;
      this.nextStep();
    },
    start() {
      this.init = false;
    },
    nextStep() {
      this.processing = false;
      this.structure.customStep++;
    },
    async confirm() {
      this.processing = true;
      await this.apiRequest();
    },
    async apiRequest() {
      if (!this.api) {
        this.nextStep();
      } else {
        this.progress = true;
        const r = await this.getApiResponse();
        this.progress = false;
        if (r?.error) {
          if(this.$onboarding.errorHandler(r, true)) return;
          this.structure.error = true;
          this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});

          if (!this.isProd) {
            console.log(`Api Request Error : onboarding.${this.api}`, r);
            this.$toast(r.desc, { type: 'fail', translate: false });
          }
        } else {
          await this.nextStep();
        }
      }
    },
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding[this.api]({ ...this.structure.model }); }
      catch (e) { return e; }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reaffirm-wsop] {
  p { .fs(16); }
}
</style>
