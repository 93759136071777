<template>
  <BaseModal gp-message-modal :title="title" :dim-close="false">
    <template #content>
      <div class="content">
        <p v-html="message" />
      </div>
    </template>
    <template #footer>
      <GpButton type="submit-green" @click="close">{{ $t('close') }}</GpButton>
    </template>
  </BaseModal>
</template>

<script>

import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
export default {
  name: 'GpMessageModal',
  components: { GpButton, GpDescription, BaseModal },
  props: {
    options: {
      title: { type: String, default: null },
      message: { type: String, default: null },
    }
  },
  data() {
    return {
      title: null,
      message: null,
    };
  },
  computed: {
    messageTypeList() {
      return [{ value: '0', label: 'SMS' }, { value: '1', label: 'CALL' }];
    },

  },
  methods: {
    close() {
      this.$modalCloseAll();
    },
  },
  mounted() {
    this.title = this.options.title;
    this.message = this.options.message;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-message-modal] {
  > .panel { .rel(); overflow: visible;
    .scroll-area {.h(calc(100% - 59px)); .flex(); .flex-dc(); overflow-y: visible;
      > .body { flex: 1; }
    }
  }
  .content {
    > p {.pt(0); .pb(20);}
  }
  .footer {.flex(); .gap(10);}
}
</style>