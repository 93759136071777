<template>
  <label my-title-label :class="{arrow}" @click="$emit('click')">
    <label>{{ name }}</label>
    <slot></slot>
    <FontIcon v-if="arrow" name="angle-right" />
  </label>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';
export default {
  name: 'GpTitle',
  components: { FontIcon },
  props: {
    name: { type: String, required: true },
    arrow: Boolean,
    extraComponent: Specific
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-title-label] { .block(); .p(20, 0, 10, 0); .c(@c-b08); .rel(); .flex(); .flex-dr(); .gap(5); .items-center(); font-weight: 500;
  &.arrow { .pointer(); .-b(@c-b01); }
}
</style>