<template>
  <div personal-ph>
    <p v-html="$t('_.description-ph')"></p>
  </div>
</template>

<script>

export default {
  name: 'PersonalPh',
  lexicon: 'onBoarding.personal',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[personal-ph] {
  p { .fs(16); }
}
</style>
