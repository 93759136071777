<template>
  <div floating-admin-button>
    <ColorButton type="gray-red" @click="home">Home</ColorButton>
    <ColorButton type="gray-red" @click="logout">Logout</ColorButton>
  </div>
</template>
<script>

import ColorButton from '@shared/components/common/ColorButton.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'FloatingAdminButton',
  components: { ColorButton },
  computed: {
    env: state('env', 'env'),
  },
  methods: {
    home() {
      this.replaceRouteName('Home');
    },
    async logout() {
      try { await this.$services.sign.logout({ useRedirect: false }); } catch (e) { /* empty */ }
      const path = this.env === 'local' ? '/sign-in' : '/app';
      window.location = path;
    }
  }
};
</script>

<style lang="less">
@import "@/less/proj.less";
[floating-admin-button] { .flex(); .fix(); .w(auto) !important; .rt(30, 30);
  > [color-button] + [color-button] { .ml(10); }
}
</style>