import { render, staticRenderFns } from "./RgTotalBetLimit.vue?vue&type=template&id=26d0f2b6"
import script from "./RgTotalBetLimit.vue?vue&type=script&lang=js"
export * from "./RgTotalBetLimit.vue?vue&type=script&lang=js"
import style0 from "./RgTotalBetLimit.vue?vue&type=style&index=0&id=26d0f2b6&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports