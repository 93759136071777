<template>
  <div input-self-exclusion>
    <div class="label">{{ $t('_.breakPeriod') }}</div>
    <RadioList type="vertical" :custom-radio="ResponsibleRadioItem" :list="itemList" v-model="group" @update="update" @input="input" />
  </div>
</template>

<script>

import Specific from '@shared/types/Specific';
import RadioList from '@/views/components/gg-pass/RadioList.vue';
import DropSelect from '@shared/components/common/input/DropSelect.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import { useExclusionList, exclusionGroupList, selfExclusionGroup } from '@/constants/base/responsible-gaming';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { state } from '@shared/utils/storeUtils.mjs';
import ResponsibleRadioItem from '@/views/components/pages/responsible-gaming/template/common/ResponsibleRadioItem.vue';

export default {
  name: 'InputSelfExclusion',
  lexicon: 'responsibleGaming.label',
  components: { RadioList },
  props: {
    model: Specific,
    current: Specific,
  },
  data() {
    return {
      group: null,
      ResponsibleRadioItem
    };
  },
  computed: {
    site: state('env', 'site'),
    groupLabel() {
      return {
        'CoolingOff': 'coolingOff',
        'Timeout': 'timeOut',
        'Exclusion': 'selfExclusion',
        'Custom': 'exclusionCustom'
      };
    },
    itemList() {
      const list = exclusionGroupList.filter(group => {
        if (!this.useExclusionModel.hasOwnProperty(group)) return false;
        return this.useExclusionModel[group].length > 0;
      }).map(group => {
        if (group === selfExclusionGroup.Custom) return { value: group, label: this.$t(`_.${this.groupLabel[group]}`), preset: this.inputPreset, renderingComponent: TextInputBox, type: 'text' };
        else return { value: group, label: this.$t(`_.${this.groupLabel[group]}`), preset: { list: this.useExclusionModel[group].map(v => ({ label: this.$t(`_.${v.label}`), value: v.value, modalLabel: `responsibleGaming.label.${v.label}` })), ...this.preset }, renderingComponent: SearchDropSelect, type: 'select' };
      });

      return [{ value: 'None', label: this.$t('_.none'), }, ...list];
    },
    useExclusionModel() {
      return useExclusionList?.[this.site] || useExclusionList[siteIds.GGPCOM];
    },
    preset() {
      return { translate: false, auto: true, selectOnly: true, };
    },
    inputPreset() {
      return { placeholder: this.$t('responsibleGaming.exclusionPlaceHolder'), maxLength: 5, inputMode: 'numeric', pattern: '[0-9]*', type: 'digit', digit: true, rules: 'required|customExclusionMin:90' };
    },
  },
  methods: {
    findSelectedValueGroup() {
      let isSearched = false;
      let group = 'None';
      if (!this.model.RequestSelfExclusionPeriodType) return group;

      Object.keys(this.useExclusionModel).forEach(key => {
        if (isSearched) return false;
        const exclusionGroups = this.useExclusionModel[key];
        const findExclusion = exclusionGroups.find(exclusion => this.model.RequestSelfExclusionPeriodType === exclusion.value);
        if (findExclusion) {
          group = findExclusion.group;
          isSearched = true;
        }
      });

      return group;
    },
    update(v) {
      if (this.group === selfExclusionGroup.Custom) {
        this.model.RequestSelfExclusionPeriodType = 'CustomSelfExclusion';
        this.model.RequestSelfExclusionPeriodLabel = 'responsibleGaming.label.exclusionCustom';
        this.$emit('input', v, this.group);
      } else {
        const list = this.itemList.find(item => item.value === this.group);
        if (list) {
          this.model.RequestSelfExclusionPeriodLabel = list.preset.list.find(item => item.value === v)?.modalLabel;
        }
        this.model.RequestSelfExclusionPeriodType = v;
        this.$emit('input', null, this.group);
      }
    },
    input(v) {
      if (v === 'None') {
        this.model.RequestSelfExclusionPeriodType = null;
        this.model.RequestSelfExclusionPeriodLabel = null;
      }
    }
  },
  beforeMount() {
    this.group = this.findSelectedValueGroup();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-self-exclusion] {.flex(); .flex-dc(); .br(4); .pv(20); .gap(5);
  .label {.c(black); font-weight: 600; .fs(16);}
  > [gp-radio-group] {.bgc(#EBEBEF); .br(16); .p(20); }
}
</style>