<template>
  <Modal client-request-message-modal theme="dark" @close="resolve">
    <template #title>
      {{ title }}
    </template>
    <div class="body" v-html="body" />
    <div class="bts">
      <ProgressButton button-name="close" @click="resolve" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'ClientRequestMessageModal',
  components: { ProgressButton, Modal },
  lexicon: 'signUp.resend',
  props: {
    options: Specific,
  },
  computed: {
    title() { return 'Client request message'; },
    body() {
      return `${JSON.stringify(this.options.message)}<br /> Send after 1 second`;
    },
  },
  methods: {
    resolve() {
      this.$emit('resolve');
    },
    close() {
      this.$emit('close');
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[verify-request-message-modal] {
  .bts { .flex(); .flex-dc(); .items-center(); }
  > .panel.large { .max-w(460); .mh(16); }
}
</style>
