<template>
  <table :class="theme" calendar>
    <thead>
    <tr>
      <td v-for="day in weekdays" :key="day">{{ $t(`dayOfTheWeek.${day}`) }}</td>
    </tr>
    </thead>
    <tbody v-if="row">
    <div class="spacer" />
    <tr v-for="i in row" :key="i">
      <template v-if="!isAllExt(i-1)">
        <td v-for="d in weekData(i-1)" :key="d.ymd" :class="{disabled: d.disabled,  past: today > d.ymd, today: today === d.ymd, selected: selectedOption(d), ext: d.ext, out: d.out, range: d.range, emphasis: d.ymd === _emphasis, start : isStart(d) && !isEnd(d), end: !isStart(d) && isEnd(d)}" @click="e => select(e,d)">
          <label class="selected-label" v-if="selectedLabel && selectedYMD === d.ymd">{{ selectedLabel }}</label>
          <span v-if="!hideExt || d.ext">{{ d.dto.getDate() }}</span>
        </td>
      </template>
    </tr>
    </tbody>
    <tbody v-else>
    <tr>
      <td class="no-month" colspan="7">{{ $t('monthRequired') }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { utc, getYMD, getM } from '@shared/utils/timeUtils.mjs';
import moment from 'moment';
import _ from 'lodash';
import Specific from '@shared/types/Specific';

export default {
  name: 'Calendar',
  props: {
    month: String, // 'YYYY-MM'
    emphasis: String, // 'YYYY-MM-DD'
    selected: {},
    selectedLabel: String,
    rangeStart: { type: String, default: '' },
    rangeEnd: { type: String, default: '' },
    rangeDate: { type: String, default: '', },
    hideExt: { type: Boolean, default: false },
    theme: String,
    min: Specific,
    max: Specific,
  },
  data() {
    return {
      weekdays: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
      arg: null,
      startDay: null,
      lastDate: null,
      row: null,
      ym: null,
      selectedDate: null,
    };
  },
  computed: {
    today() {
      return +moment().format('YYYYMMDD');
    },
    _emphasis() {
      return this.emphasis ? +moment(this.emphasis).format('YYYYMMDD') : null;
    },
    selectedYMD() {
      return this.parseDate(this.selected);
    },
    rangeStartYMD() {
      return this.parseDate(this.rangeStart);
    },
    rangeEndYMD() {
      return this.parseDate(this.rangeEnd);
    },
  },
  watch: {
    month: 'applyMonth',
    selected: 'applyMonth',
  },
  methods: {
    isAllExt(i) {
      return _.every(this.weekData(i), d => d.ext);
    },
    isStart(d) {
      const ymd = getYMD(d.m);
      return this.rangeStart && this.rangeEnd && this.rangeStart === ymd;
    },
    isEnd(d) {
      const ymd = getYMD(d.m);
      return this.rangeStart && this.rangeEnd && this.rangeEnd === ymd;
    },
    selectedOption(d) {
      return (this.selectedYMD === d.ymd || (this.rangeStart && this.rangeEnd && (+moment(this.rangeStart).format('YYYYMMDD') === d.ymd || +moment(this.rangeEnd).format('YYYYMMDD') === d.ymd)));
    },
    parseDate(dateString) {
      if (!dateString) return null;
      let m;
      if (dateString === 'today') m = moment();
      else {
        const matched = String(dateString).match(/^([+-]?\d+)\s+(day|month|week|year)$/);
        m = matched ? moment().add(+matched[1], matched[2]) : moment(dateString);
      }
      return +m.format('YYYYMMDD');
    },
    getBaseDTO() {
      return new Date(this.arg[1], this.arg[2] - 1, 1);
    },
    getDTO(r, c) {
      const dto = this.getBaseDTO();
      dto.setDate((((r - 1) * 7) + c + 2) - this.startDay);
      return dto;
    },
    dateData(r, c) {
      const dto = this.getDTO(r, c);
      const m = moment(dto);
      const ymd = +m.format('YYYYMMDD');
      const today = +moment().format('YYYYMMDD');
      const disabled = this.checkDisabled(m, ymd, today);
      const ext = this.month !== m.format('YYYY-MM');
      const out = (this.rangeStart && this.rangeEnd && ((this.rangeStartYMD < ymd) && (this.rangeEndYMD > ymd)));
      return { dto, m, ymd, ext, out, disabled };
    },

    checkDisabled(m, ymd, today) {
      if (this.min) {
        const minDate = moment(this.min);
        if (minDate > m) return true;
      }
      if (this.max) {
        const maxDate = moment(this.max);
        if (maxDate < m) return true;
      }
      return false;
    },

    weekData(r) {
      return [...Array(7)].map((_, c) => this.dateData(r, c));
    },
    applyMonth() {
      if (this.month) {
        this.arg = String(this.month).match(/^(\d{4})-(\d{2})$/);
        if (!this.arg) throw new Error('invalid month format (YYYY-MM)');
        const dto = this.getBaseDTO();
        this.startDay = dto.getDay();
        dto.setMonth(dto.getMonth() + 1);
        dto.setDate(0);
        this.lastDate = dto.getDate();
        this.row = Math.ceil((this.startDay + this.lastDate) / 7) + 1;
      }
    },
    select(e, d) {
      if (d.disabled) return;
      this.selectedDate = d.m.format('YYYYMMDD');
      this.$emit('select', d.m.format('YYYY-MM-DD'));
    }
  },
  mounted() {
    this.applyMonth();
  }
};
</script>

<style lang="less">
@import "~@shared/less/proj.less";
[calendar] { .w(100%); border-collapse: collapse; border-spacing: 0;
  thead {
    td {.pb(10); .-b(rgba(0, 0, 0, .1)); .fs(11); .medium(); }
  }
  tbody {
    .spacer { .h(10px); }
    td { .pointer;.rel; .h(36); .-box; .fs(12, 36); .w(14.3%);
      span {.abs; .wh(100%, 100%); .tc; .lb(0, 50%); .t-y(50%); z-index: 1; pointer-events: none;}

      &.disabled { color: #A1A1AA; cursor: default;
        &:hover {background-color: transparent;}
      }
      &.selected { .rel; .z(2);
        span {.c(black);}
        &:before {.cnt; z-index: 0; .abs; .wh(100%); .bgc(#E6F3E8); .lt(50%, 50%); .t-xyc;}
        &.start:before { .br-l(30%); }
        &.end {
          &:before { .br-r(30%); }
          span { .o(1); .bgc(transparent);}
        }
      }
      .selected-label { .abs; .t(-10); .l(0); .r(0); .c(#2c81ff); .fs(1); .z(1); }
      &.emphasis:not(.selected) {
        &:before {.cnt; z-index: 0; .abs; .wh(32); .bgc(@c-b01); .br(16); .lt(50%, 50%); .t-xyc;}
      }
      &.out { cursor: default;
        span { .o(.2); .bgc(@c-mint);}
      }
      &.range { .bgc(@c-b01);}
      &.ext { .bgc(#fff);
        &:before { content: none; }
        span { .o(.2); .c(black); }
        &.out, &.end, &.start { cursor: default;
          span { .o(.2); .bgc(@c-mint);}
        }
      }
      &:hover {.bgc(#F3FCF5)}
    }
  }
  td {.tc;}
  &.dark {
    thead {
      td { .c(white); }
    }
    tbody {
      td { .c(white);
        &.selected { .rel;
          span {.c(white);}
          &:before {.cnt; z-index: 0; .abs; .wh(32); .bgc(#C71F1F); .br(16); .lt(50%, 50%); .t-xyc;}
        }
        &.ext { .bgc(transparent);
          span { .o(.4); }
        }
      }
    }
  }
  .today {  .bgc(#D9F5F1FF); }
}
</style>
