<template>
  <GpFormTemplate np-transfer-history>
    <template #header>
      <GpDescription :message="$t('_.description')" />
    </template>
    <template #content>
      <div class="display-group">
        <InputTransferSearchBar :model="searchModel" @click="click" />
        <DisplayTransferHistory v-if="Histories" :model="Histories" @loadData="loadData" />
      </div>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import DisplayTransferHistory from '@/views/components/pages/my-page/template/views/DisplayTransferHistory.vue';
import InputTransferSearchBar from '@/views/components/pages/my-page/template/inputs/InputTransferSearchBar.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import { getYYYYMMDDTHHmmssZ } from '@shared/utils/timeUtils.mjs';
import moment from 'moment/moment';
import Specific from '@shared/types/Specific';

export default {
  name: 'NpTransferHistory',
  lexicon: 'myInfo.transferHistory',
  components: { InputTransferSearchBar, DisplayTransferHistory, GpFormTemplate, GpDescription },
  props: {
    model: Specific,
    structure: { type: Specific, default: null },
  },
  data() {
    return {
      Histories: null,
      HasNext: false,
      searchModel: {
        fromDateTime: null,
        toDateTime: null,
        status: null,
        offset: 0,
        limit: 6,
      },
      errorMsg: null,
      isLoading: false,
    };
  },

  computed: {
    site: state('env', 'site'),
  },
  methods: {
    async initialize() {
      const beginDate = getYYYYMMDDTHHmmssZ(moment().subtract(7, 'day').format('YYYY-MM-DD'));
      const endDate = getYYYYMMDDTHHmmssZ(moment().format('YYYY-MM-DD 23:59:59'));

      this.searchModel = {
        ...this.searchModel,
        fromDateTime: beginDate,
        toDateTime: endDate,
      };
      const histories = await this.$myinfo.fetch({payload : this.searchModel, block : true});
      this.HasNext = histories.hasNext;
      this.Histories = this.createHistoryList(histories?.list);
    },

    createHistoryList(list) {
      if (!list) return null;

      const histories = list.map(history => {
        const { transactions } = history;

        let debit = null;
        let credit = null;
        let cancel = null;

        if (transactions) {
          debit = transactions.find(tx => tx.type === 'DEBIT');
          credit = transactions.find(tx => tx.type === 'CREDIT');
          cancel = transactions.find(tx => tx.type === 'CANCEL');
        }

        return {
          ...history,
          debit: debit,
          credit: credit,
          cancel: cancel,
        };
      });
      return histories;
    },
    async click() {
      this.searchModel.offset = 0;
      const histories = await this.$myinfo.fetch({payload : this.searchModel, block : true});
      this.HasNext = histories.hasNext;
      this.Histories = this.createHistoryList(histories?.list);
    },
    async loadData() {
      if (this.isLoading) return;
      if (this.HasNext) {
        this.isLoading = true;
        this.searchModel.offset += 1;
        const histories = await this.$myinfo.fetch({payload : this.searchModel, block : true});
        this.HasNext = histories.hasNext;
        this.Histories = [...this.Histories, ...this.createHistoryList(histories?.list)];
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    this.structure.title = this.$myinfo.title;
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-transfer-history] {.flex(); .flex-dc();
  .display-group {.flex(); .flex-dc(); .gap(20); .h(100%);}
}

</style>