import { apiErrorCode, apiErrorType } from '@/constants/base/apiErrorCode';
import { bankIdStatus } from '@/constants/base/onboarding/kyc';
import { parseQuery } from '@shared/utils/urlUtils.mjs';

export default class BankIdController {
  #scope;
  #site;
  #lang;
  #services;
  #callback;
  #modelInfo;
  #status;
  #url;
  #intervalId;
  #intervalMilliSecond;
  #currentPollingCount;
  #limitPollingCount;

  constructor(scope, { services, site, lang, callback, modelInfo }) {
    this.#scope = scope;
    this.#modelInfo = modelInfo;
    this.#services = services;
    this.#site = site;
    this.#lang = lang;
    this.#callback = callback;
    this.#intervalMilliSecond = 5 * 1000;
    this.#limitPollingCount = 100;
    this.#restart();
  }

  /**
   * BankId redirect url 조회
   * @returns {<{Url: string}>}
   */
  async #getBankId() {
    // const r = await this.#services.kyc.verifyBankIdUrl(`${location.origin}/app/receiver-bank-id`);
    const r = await this.#services.kyc.verifyBankId('https://widget-ggpcom-test.ggcore.net/onboarding/receiver-bank-id');
    // 에러 발생 시 후 처리가 없음..
    // r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * Bank ID 결과 업데이트
   * @param {string} Code -callback URL로 받은 query string의 code 변수 값
   * @param {string} StateValue - callback URL로 받은 query string의 state 변수 값
   * @param {string} Error - callback URL로 받은 query string의 error 변수 값
   * @param {string} ErrorDescription - callback URL로 받은 query string의 error_description 변수 값
   * @param {string} RequestHost - 접속 중인 페이지의 hostname
   * @returns {Promise<*>}
   */
  async #putBankId({ Code, StateValue, Error, ErrorDescription }) {
    // const RequestHost = location.host;
    const RequestHost = 'https://widget-ggpcom-test.ggcore.net';
    const r = await this.#services.kyc.updateBankId({ Code, StateValue, Error, ErrorDescription, RequestHost });
    r?.error && this.#services.router.replace({name: 'AuthError'});
    return r;
  }
  /**
   * Bank ID 인증 상태 조회
   * @returns <Promise<{Status: string}>>
   */
  async #getBankIdStatus() {
    // const r = await this.#services.kyc.verifyBankIdUrl(`${location.origin}/verification/receiver-bank-id`);
    const r = await this.#services.kyc.verifyBankIdStatus();
    r?.error && this.#errorHandler(r);
    // USER_INFO_ALREADY_EXIST
    //   Multi Account Error View를 표기한다.
    // Default
    //   Fail View를 표기한다.
    return r;
  }
  /**
   * error 발생 시 에러 코드 별 처리 분기
   * @param {object} r - error 정보
   */
  #errorHandler(r) {
    if (r?.key === apiErrorCode.USER_INFO_ALREADY_EXIST) {
      /// Multi Account Error View를 표기한다. USER_INFO_ALREADY_EXIST, USER_INFO_ALREADY_EXIST
      // this.replaceRouteName('VerificationFailed', {errorTemplate: r.errorTemplate}, {ci: r.errorTemplate.ci, cci: r.errorTemplate.cci});
      const { CustomerErrorParameters, errorTemplate } = r;
      this.#services.router.replace({ name: 'VerificationFailed', params: {errorTemplate}, query: { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined }});
    } else {
      // Fail View를 표기한다.
      this.#goFailedView();
    }
    this.#callbackInfo();
  }

  /**
   * 초기화
   * @returns {Promise<void>}
   */
  async #initialize() {
    this.#clearPollingInfo();
    this.#url = null;
    this.#status = null;
  }

  /**
   * Polling 관련 정보 초기화
   */
  #clearPollingInfo() {
    this.#currentPollingCount = 0;
    this.#intervalId && clearInterval(this.#intervalId);
    this.#intervalId = null;
  }
  /**
   * bankId 진행 상태 반복 조회
   * 5초 마다 반복 호출을 하여 상태 Polling
   * 최대 100회가 넘어갈 경우 Failed 화면 노출
   * @returns {Promise<void>}
   */
  #pollingBankIdStatus() {
    this.#intervalId = setInterval(async () => {
      // currentMilliSecond이 최대 카운트보다 같거나 넘어갈 경우 Inverval을 초기화 시키고 ServiceAbort로 이동
      if (this.#limitPollingCount < this.#currentPollingCount) this.#callbackInfo(bankIdStatus.Default);
      else await this.#callbackInfo();

      this.#currentPollingCount++;
    }, this.#intervalMilliSecond);
  }

  /**
   * query parameter 정보 설정
   */
  async #setQueryInfo() {
    if (!location.search) {
      this.#callbackInfo(bankIdStatus.Ready);
    } else {
      await this.#putBankId(parseQuery(location.search)); // Code, StateValue, Error, ErrorDescription
      this.#callbackInfo();
    }
  }
  /**
   * 진행중인 bankId 현황 정보 반환
   * @returns {{lang, status, url}}
   */
  #getInfo() {
    return { lang: this.#lang, status: this.#status, url: this.#url };
  }
  /**
   * bankId 단계 초기화
   */
  #restart() {
    this.#initialize().then(() => this.#setQueryInfo());
  }

  /**
   * bankid status 정보 저장
   * @param {string} status - 설정할 bankid status
   */
  #setInfo(status) {
    if(status !== this.#status) this.#status = status;
  }
  /**
   * bankid 상태 값에 따른 화면 전환 처리
   * @param status
   */
  async #callbackInfo(status) {
    let r;
    let tempStatus = status;
    if (!tempStatus) {
      r = await this.#getBankIdStatus();
      if (r?.error) return;
      tempStatus = r.Status;
    }

    if (this.#status !== tempStatus) {
      this.#clearPollingInfo();
      switch (tempStatus) {
        case bankIdStatus.Ready:
          r = await this.#getBankId();
          if (r?.error) return;
          this.#url = r.Url;
          break;
        case bankIdStatus.Pending:
          this.#pollingBankIdStatus();
          break;
        case bankIdStatus.Verified:
          this.#services.router.replace({ name: 'ConfirmPersonalInfo'});
           break;
        case bankIdStatus.Default:
          this.#goFailedView();
          tempStatus = bankIdStatus.Default;
        default:
          break;
      }
    }

    this.#setInfo(tempStatus);
    this.#callback(this.#getInfo());
  }
  #goFailedView() {
    const errorTemplate = {[apiErrorType.VERIFICATION]: {content: 'errorPages.bankIdFailed.content'}};
    const CustomerErrorParameters = ['BankiD'];
    this.#services.router.replace({ name: 'VerificationFailed', params: {errorTemplate}, query: { p: encodeURIComponent(CustomerErrorParameters) }});
  }
  /**
   * BankId 이동
   */
  goBankId() {
    location.href = this.#url;
  }
}
