<template>
  <div regulatory-transition>
    <p v-html="$t('_.description1')" />
    <p v-html="$t('_.description2')" />
    <ul>
      <li v-for="(item, index) in items" :key="index" v-html="item" />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RegulatoryTransition',
  lexicon: 'onBoarding.regulatoryTransition',
  data() {
    return {
      items: [
        `1. ${this.$t('_.item1')}`,
        `2. ${this.$t('_.item2')}`,
        `3. ${this.$t('_.item3')}`,
        `4. ${this.$t('_.item4')}`,
        `5. ${this.$t('_.item5')}`,
        `6. ${this.$t('_.item6')}`,
        `7. ${this.$t('_.item7')}`,
      ],
    };
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[regulatory-transition] {
  > p + p { .mt(4); }
  > ul { .mt(24);
    li { .fs(20); }
  }
}
</style>
