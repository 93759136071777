<template>
  <div verify-citizenship-reviewing-documents>
    <FontIcon name="reviewing-document" />
    <h3 v-html="$t('onBoarding.title.reviewingDocuments')" />
    <p class="desc" v-html="$t('_.reviewingDocuments.description')" />
    <template>
      <h4 v-html="$t('onBoarding.title.proofCitizenship')" />
      <ul>
        <li v-for="(item,id) in list" :key="id">
          <div>
            <span class="name">{{ item.OriginalFileName }}</span>
            <span class="date">{{ item.CreatedAt && toFullDateTS(item.CreatedAt, locale) }}</span>
          </div>
          <span :class="getStatus(0)">{{ $t(`kyc.${getStatus(0)}`) }}</span>
        </li>
      </ul>
    </template>
    <ProgressButton button-name="close" @click="close" />
  </div>
</template>

<script>
import { citizenshipVerification, close, isClient } from '@/utils/clientUtil';
import { toFullDate, toFullDateTS } from '@/utils/dateTimeUtil';
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'VerifyCitizenshipReviewingDocuments',
  components: { FontIcon, ProgressButton },
  lexicon: 'kyc',
  data() {
    return {
      list: null,
    };
  },
  computed: {
    locale() { return this.$i18n('locale'); },
  },
  methods: {
    toFullDateTS,
    getDate(item) {
      const locale = this.$i18n('locale');
      return toFullDate(item.CreatedAt, locale);
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'reviewing';
        case 1:
          return 'approved';
        case 2:
          return 'rejected';
        case 3:
          return 'expired';
        default:
          return '';
      }
    },
    close() {
      isClient() ? citizenshipVerification(this) : close(this);
    },
    async apiRequest() {
      const r = await this.getApiResponse();
      this.list = r;
      if (r.error) {
        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          this.$toast(r.desc, { type: 'fail', translate: false });
          console.log(`Api Request Error : onboarding.getCitizenshipDocumentList`, r);
        }
      }
    },
    /**
     *
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ (await this.$services.onboarding.getCitizenshipDocumentList({ Status: 'Pending' }))?.List; }
      catch (e) { return e; }
    },
    async initialize() {
      await this.apiRequest();
    }
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-citizenship-reviewing-documents] { .tc();
  > [font-icon] { .block(); .mb(8); .fs(80); .mt(24); }
  > h3 { .fs(22, 27, normal); .regular(); .mb(24); }
  > p { .tl(); .fs(18, 22, normal); .c(#7F7F7F);
    > a { .c(#2680EB); .underline(); }
  }
  > h4 { .mt(24); .mb(14); .tl(); .fs(18, 22, normal); .regular(); .c(#ccc); }
  ul { .tl();
    li { .flex(); .flex-ai(); .space-between(); .gap(8);
      &:not(:last-child) { .-b(#7F7F7F); .pb(12); }
      > span { flex: 0; .block(); .fs(20, 24, normal); .medium();
        &.reviewing{.c(#FFC64D);}
        &.approved{.c(#CCC);}
        &.rejected{.c(#7F7F7F);}
        &.expired{.c(#FFC64D);}
      }
      div { flex: 1; .break-all();
        span { .block();
          &.name { .fs(16, 20, normal); .c(#7F7F7F); }
          &.date { .mt(4); .fs(16, 20, normal); .c(#7F7F7F); }
        }
      }
    }
    li + li { .mt(12); }
  }
}
</style>
