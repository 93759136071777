<template>
  <div email>
    <p v-html="$t('onBoarding.reaffirm.de.descriptionEmailVerify', {userEmail: modelInfo.EmailMasked} )" />
    <!--    <p v-html="$t('onBoarding.verifyEmailCode.descriptionInputEmail')" />-->
    <ProgressButton button-name="send" @click="confirm" :disabled="!!structure.limitTime || structure.error" />
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'Email',
  components: { ProgressButton },
  mixins: [baseTemplate],
  data() {
    return {
      captcha: null,
      captchaPass: false,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  methods: {
    async loadReCaptcha() {
      await new Promise(resolve => {
        grecaptcha.enterprise.ready(async () => {
          this.captcha = await this.$services.captcha.recaptchaEnterprise(this.action);
          resolve();
        });
      });
    },
    /**
     * 컴포넌트 내에서의 api request 처리
     * @returns {Promise<*|null>}
     */
    async initialize() {
      this.modelInfo.SendVerificationType = 'EMAIL';
      if (this.captchaPass) await this.loadReCaptcha();
    },
    async confirm() {
      await this.apiRequest();
      if (!this.structure.error) this.modelInfo.step = 1;
    },
    async apiRequest() {
      const r = await this.getApiResponse();
      if (r.error) {
        if (r?.key === 'limit') {
          this.structure.limitTime = +r?.value;
          return;
        }
        if (!(await this.apiErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail });
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.sendEmailVerification`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }
      }
    },
    /**
     *
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding.sendEmailVerification({ ...this.structure.model, ...this.captcha }); } catch (e) { return e; }
    },
    async apiErrorHandler(r) {
      const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
      this.replaceRouteName('VerificationFailed', { desc, errorTemplate }, { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined });
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[email] {}
</style>
