<template>
  <div alias-name v-if="modelInfo">
    <ValidationComponent :label="aliasLabel" name="_aliasName" :component="TrimTextInput" v-model="modelInfo.Alias" :preset="preset" />
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'AliasName',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  lexicon: 'personalDetails',
  data() {
    return {
      aliasLabel: `${this.$t('_.aliasPreferredName')} ${this.$t('optional')}`,
      TrimTextInput,
    };
  },
  computed: {
    preset() {
      return { maxLength: 50 };
    },
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[alias-name] {}
</style>