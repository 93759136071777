<template>
  <div iban-verification>
    <p v-html="$t('_.surepayStepDescription', {csEmail})"></p>
    <ValidationComponent :label="$t('iban')" name="_iban" :component="TrimTextInput" :rules="rules" :preset="preset" v-model="modelInfo.IbanNumber" :error-msg="errorMsg" />
    <ProgressButton :button-name="buttonName" @click="confirm" :disabled="!!structure.limitTime || structure.error || invalid" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Specific from '@shared/types/Specific';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'IbanVerification',
  lexicon: 'onBoarding',
  components: { ProgressButton, ValidationComponent },
  mixins: [baseTemplate],
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      SelectedBankCountry: null,
      BankCountry: null,
      BankCode: null,
      BankName: null,
      minLength: null,
      maxLength: null,
      TrimTextInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    preset() {
      return { maxLength: this.maxLength };
    },
    rules() {
      return `requiredIban:${this.modelInfo.CountryCode},${this.modelInfo.CountryWritingSystem},${this.minLength},${this.maxLength}`;
    },
    step() {
      return this.structure.step;
    },
    lastStep() {
      return this.step >= this.structure.list.length - 1;
    },
    currentItem() {
      return this.structure?.list[this.step];
    },
    service() {
      return this.currentItem.service[this.site] || this.currentItem.service.default || this.currentItem.service;
    },
    api() {
      return this.currentItem.api;
    },
    buttonName() {
      return this.currentItem.buttonName || (this.lastStep ? 'submit' : 'next');
    },
  },
  methods: {
    initialize() {
      const rules = [
        { countries: ['NO'], min: 15, max: 15},
        { countries: ['BE'], min: 16, max: 16},
        { countries: ['DK', 'FI', 'FO', 'GL', 'NL'], min: 18, max: 18},
        { countries: ['MK', 'SI'], min: 19, max: 19},
        { countries: ['AT', 'BA', 'EE', 'KZ', 'LT', 'LU'], min: 20, max: 20},
        { countries: ['CH', 'HR', 'LI', 'LV'], min: 21, max: 21},
        { countries: ['BG', 'BH', 'CR', 'DE', 'GB', 'GE', 'GG', 'IE', 'IM', 'JE', 'ME', 'RS', 'VA'], min: 22, max: 22},
        { countries: ['AE', 'GI', 'IL', 'IQ'], min: 23, max: 23},
        { countries: ['AD', 'CZ', 'ES', 'MD', 'PK', 'RO', 'SA', 'SE', 'SK', 'TN', 'VI'], min: 24, max: 24},
        { countries: ['PT'], min: 25, max: 25},
        { countries: ['IR', 'IS', 'TR'], min: 26, max: 26},
        { countries: ['FR', 'GR', 'IT', 'MC', 'MR', 'SM'], min: 27, max: 27},
        { countries: ['AL', 'AZ', 'BY', 'CY', 'DO', 'GT', 'HU', 'LB', 'PL'], min: 28, max: 28},
        { countries: ['BR', 'PS', 'QA', 'UA'], min: 29, max: 29},
        { countries: ['JO', 'KW', 'MU'], min: 30, max: 30},
        { countries: ['MT', 'SC'], min: 31, max: 31},
        { countries: ['LC'], min: 32, max: 32},
      ];

      const t = rules.find(o => o.countries.includes(this.modelInfo.CountryCode));
      if(t) {
        this.minLength = t.min;
        this.maxLength = t.max;
      }
    },
    /**
     * 컴포넌트 내에서의 api request 처리
     * @returns {Promise<*|null>}
     */
    async apiRequest() {
      const r = await this.getApiResponse();
      if (r?.error) {
        if(!(await this.apiErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : ${this.service}.${this.api}`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }

        return;
      }

      return r;
    },
    /**
     * api 결과 반환 처리
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services[this.service][this.api]({ ...this.structure.model }); }
      catch (e) { return e; }
    },
    /**
     * api Error Handler
     * @param {object} r - error 정보
     * @returns {Promise<boolean>}
     */
    async apiErrorHandler(r) {
      console.log('apiErrorHandler', r);
      const { desc, key } = r;
      switch (key) {
        case apiErrorCode.INTERNAL_ERROR:
          this.structure.error = true;
          this.structure.errorMsg = desc;
          return false;
      }

      return true;
    },
    async confirm() {
      await this.apiRequest();
      if (!this.structure.error) this.modelInfo.step = 1;
    }
  },
  created() {
    this.initialize();
    if (this.modelInfo.PlayerBankInfo?.IBAN) this.modelInfo.IbanNumber = this.modelInfo.PlayerBankInfo?.IBAN;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[iban-verification] {
  > * { .mb(20); .c(#d8d8d8);
    a { .c(@c-link); }
  }
}
</style>
