<template>
  <div first-deposit-challenge-card :class="challengeInfo.status.toLowerCase()">
    <div class="day">Day {{ challengeInfo.dayNumber }}</div>
    <div class="contents">
      <div class="count">
        <span>{{ ticket.ticketCount }} x </span>
      </div>
      <div class="name">hi{{ ticket.ticketName }}</div>
      <div class="value">{{ currency }}</div>
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';

export default {
  name: 'FirstDepositChallengeCard',
  props: {
    challengeInfo: { /** @type {DailyBonusItem} */ type: Specific, required: true },
  },
  computed: {
    ticket() {
      return this.challengeInfo.tickets[0];
    },
    currency() {
      return this.$promotion.getCurrencyString(this.ticket.ticketCurrency, this.ticket.ticketValue);
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[first-deposit-challenge-card] { .tc(); .bgc(@c-shadowBlack);
  .day { .p(4); .bgc(@c-darkBrown); .c(@c-khakiBrown); .lh(22); .semi-bold();}
  .contents { .p(16, 8); .bgc(@c-shadowBlack); }
  .count { .flex(); .justify-center(); .items-center();
    &::after { .cnt(); .ib(); .w(51); .h(40); .ml(8); .no-repeat('@{cdn}/promotion-tickets.png'); .contain(); }
  }
  .name { .mt(14); .mb(6); .fs(11); .c(@c-grey55); }
  .value { .bold(); .c(@c-blond); }

  &.none { .o(0.8);
    .count, .value { .o(0.7) }
  }
  &.active { .-a(@c-brown);
    .day { background: linear-gradient(90deg, rgba(164, 137, 84, 1) 0%, rgba(104, 80, 34, 1) 100%); .c(@c-white); }
    .contents::before { .o(1); }
  }
  &.failed, &.completed { .rel();
    &::after { .cnt(); .wf(); .hf(); .abs(); .t(0); .l(0); .bgc(rgba(0, 0, 0, 0.6)); .no-repeat(); .bg-c(); .bg-w(34); }
  }
  &.failed::after { .bg('@{cdn}/promotion-failed.png'); }
  &.completed::after { .bg('@{cdn}/promotion-check.png'); }
}
</style>
