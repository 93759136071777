import { apiErrorCode, apiErrorType } from '@/constants/base/apiErrorCode';
import { signicatStep, signicatStatus } from '@/constants/base/onboarding/kyc';
import { isUrl, parseQuery } from '@shared/utils/urlUtils.mjs';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { openBrowser } from '@/utils/clientUtil';

export default class SignicatController {
  #scope;
  #silent;
  #site;
  #lang;
  #services;
  #callback;
  #modelInfo;
  #step;
  #status;
  #url;
  #processingSeconds;
  #limitMilliSeconds;
  #intervalMilliSeconds;
  #mobile;

  constructor(scope, { services, site, lang, callback, modelInfo }) {
    this.#scope = scope;
    this.#modelInfo = modelInfo;
    this.#services = services;
    this.#site = site;
    this.#lang = lang;
    this.#callback = callback;
    this.#limitMilliSeconds = 5 * 60 * 1000;
    this.#intervalMilliSeconds = 10 * 1000;
    this.#mobile = this.#services.store.state.browser.mobile;
    this.restart();
  }

  /**
   * onboarding에서 활용하는 token을 3rd party 진행 시 자동 한시간 연장 되도록 요청
   * @returns {Promise<ResponseAuthInfo>}
   */
  async #postAutoRenewToken() {
    const r = await this.#services.token.autoRenewToken();
    r?.error && this.#errorHandler(r);
    return r;
  }

  /**
   * signicat 인증 Step 조회
   * @returns {Promise<SignicatStep>}
   */
  async #getStep() {
    const r = await this.#services.kyc.getSignicatStep();
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * signicat 신원 인증 상태 조회
   * @returns {Promise<VerifySignicatIdentity>}
   */
  async #postIdentity() {
    const path = this.#services.store.state.env.env === 'local' ? '/onboarding' : '/app';
    const redirectUrl = this.#mobile ? `${location.origin}${path}/receiver-signicat-idin?step=IdinVerification` : `${location.origin}${path}/receiver-signicat?step=IdinVerification`;

    const r = await this.#services.kyc.verifySignicatIdentity({Language: this.#lang, RedirectUrl: redirectUrl});
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * signicat 신원 인증 취소
   * @returns {Promise<*>}
   */
  async #postCancelIdentity() {
    // 에러가 발생해도 무시
    try { const r = await this.#services.kyc.verifySignicatCancelIdentity(); } catch (e) { /* empty */ }
  }
  /**
   * signicat 뱅크 인증 상태 조회
   * @param url
   * @param lang
   * @returns {Promise<VerifySignicatBank>}
   */
  async #postBank() {
    const path = this.#services.store.state.env.env === 'local' ? '/onboarding' : '/app';
    const redirectUrl = `${location.origin}${path}/receiver-signicat?step=EidvVerification&status=Processing`;
    const r = await this.#services.kyc.verifySignicatBank({Language: this.#lang, RedirectUrl: redirectUrl}, { silent: this.#silent});
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * error 발생 시 에러 코드 별 처리 분기
   * @param {object} r - error 정보
   */
  #errorHandler(r) {
    switch (r.key) {
      case apiErrorCode.KYC_MANUAL_UPLOAD_REQUIRED:
        // 수동 업로드 진행
        this.#setInfo(signicatStep.KycDocumentManual, null);
        break;
      default:
        // Fail View 노출 콜백
        this.#setInfo(this.#step, signicatStatus.Failed);
        break;
    }
  }
  /**
   * signicat step, status, process 정보 저장
   * @param {string|null} step - 설정할 signicat step
   * @param {string|null} status - 설정할 signicat status
   */
  #setInfo(step, status = null) {
    if(step !== this.#step) this.#step = step;
    if(status !== this.#status) this.#status = status;
  }
  /**
   * signicat 인증 Step 값에 따른 화면 전환 처리
   * @param step
   */
  async #callbackStep(step = null) {
    this.#url = null; // step 갱신 시 url 초기화

    let r;
    let tempStep = step;
    if (!tempStep) {
      r = await this.#getStep();
      if (r?.error) {
        this.#callback(this.#getInfo());
        return;
      }

      tempStep = r.Step;
    }

    if (this.#step !== tempStep) this.#setInfo(tempStep);
    this.#callback(this.#getInfo());
  }
  /**
   * signicat 신원, 뱅크 인증 상태 값에 따른 화면 전환 처리
   * @param step
   */
  async #callbackStatus(step) {
    this.#silent = [signicatStatus.Processing, signicatStatus.Pending].includes(this.#status);
    let tempStatus = this.#status;
    if ([signicatStep.IdinVerification, signicatStep.EidvVerification].includes(step)) {
      const r = step === signicatStep.IdinVerification ? await this.#postIdentity() : await this.#postBank();
      if (!r?.error) {
        if (r?.Status && r.Status !== tempStatus) {
          tempStatus = r.Status;
          if (!this.#url) this.#url = r.Link;
        }

        if (step === signicatStep.EidvVerification) {
          if ([signicatStatus.Processing, signicatStatus.Pending].includes(tempStatus)) { // 이미 한번 호출을 한 상태이므로 polling을 진행해야 함
            this.#processingSeconds = r.ProcessingSeconds;
            this.#callback(this.#getInfo());
            await this.#pollingBankStatus();
          }
        }
      } else {
        this.#callback(this.#getInfo());
        return;
      }
    }

    this.#setInfo(step, tempStatus);
    this.#callback(this.#getInfo());
  }
  /**
   * 초기화
   * @returns {Promise<void>}
   */
  async #initialize() {
    this.#step = signicatStep.IdinVerification;
    this.#status = null;
    this.#url = null;
  }
  /**
   * query parameter 정보 설정
   */
  #setQueryInfo() {
    const { step, status } = parseQuery(location.search);
    if (status?.toLowerCase() === signicatStatus.Canceled.toLowerCase()) { // 사용자의 실패로 취소되었을 경우 verification failed 화면 노출을 위해 status를 failed로 변경
      this.#setInfo(step, signicatStatus.Failed);
      this.#callback(this.#getInfo());
    } else if (status) { // status가 canceled이 아닐 경우 step에 해당하는 인증 상태 조회
      this.#setInfo(step);
      this.#callbackStatus(this.#step);
    } else { // 전달받은 status가 없다면 초기 상태 처리 진행
      this.#callbackStep();
    }
  }
  /**
   * 진행중인 signicat 현황 정보 반환
   * @returns {{lang, step, status, url}}
   */
  #getInfo() {
    return { lang: this.#lang, step: this.#step, status: this.#status, url: this.#url, ProcessingSeconds: this.#processingSeconds };
  }
  /**
   * Bank 인증 진행 상태 반복 조회
   * 10초 마다 반복 호출을 하여 상태 Polling
   * ProcessingSeconds가 5분(300)을 넘어갈 경우 KYC 수동 업로드 화면 노출
   * @returns {Promise<void>}
   */
  async #pollingBankStatus() {
    await sleep(this.#intervalMilliSeconds);
      // processingSeconds이 최대 시간보다 같거나 넘어갈 경우 Inverval을 초기화 시키고 KYC 수동 업로드로 이동
    if (this.#processingSeconds >= (this.#limitMilliSeconds / 1000)) await this.#callbackStatus(signicatStep.KycDocumentManual);
    else if(this.#status === signicatStatus.Pending) await this.#callbackStatus(signicatStep.EidvVerification);
  }
  /**
   * signicat 단계 초기화
   */
  async restart() {
    this.#initialize().then(() => this.#setQueryInfo());
  }
  /**
   * 화면에서 버튼이나 특정 이벤트 시 Step 갱신
   */
  updateStep() {
    this.#callbackStep();
  }
  /**
   * 화면에서 버튼이나 특정 이벤트 시 Status 갱신
   * @param {string} step - 전환 step
   */
  updateStatus(step) {
    if (!step) return;
    this.#callbackStatus(step);
  }
  /**
   * 초기화
   */
  clear() {
    this.#initialize();
  }
  /**
   * 재시도
   */
  async tryAgain() {
    await this.#postCancelIdentity();
    await sleep(1000);
    this.restart();
  }

  async openIdinSignicat() {
    if (typeof window === 'undefined') return;
    await this.#postAutoRenewToken();
    await openBrowser(this.#scope, this.#url);
  }

  async redirectIdinSignicat() {
    if (typeof window === 'undefined') return;
    await this.#postAutoRenewToken();
    location.href = this.#url;
  }
}
