import { render, staticRenderFns } from "./JumioManualInformation.vue?vue&type=template&id=75be9ff8&scoped=true"
import script from "./JumioManualInformation.vue?vue&type=script&lang=js"
export * from "./JumioManualInformation.vue?vue&type=script&lang=js"
import style0 from "./JumioManualInformation.vue?vue&type=style&index=0&id=75be9ff8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75be9ff8",
  null
  
)

export default component.exports