import { render, staticRenderFns } from "./VerifyCitizenshipInformation.vue?vue&type=template&id=33b41ef7"
import script from "./VerifyCitizenshipInformation.vue?vue&type=script&lang=js"
export * from "./VerifyCitizenshipInformation.vue?vue&type=script&lang=js"
import style0 from "./VerifyCitizenshipInformation.vue?vue&type=style&index=0&id=33b41ef7&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports