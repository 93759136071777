import Specific from '@shared/types/Specific';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { addResize, removeResize } from '@shared/utils/resizeUtil';

export default {
  props: {
    callComponent: { type: Object, required: true },
    targetEl: Specific,
    list: { type: [Array, Object], default: () => [] },
    value: { type: [Number, Boolean, String, Array], default: null },
    className: { type: String, default: '' },
    direction: { type: String, default: 'down' },
    theme: { type: String, default: 'white' },
    translate: { type: Boolean, default: true },
  },

  data() {
    return {
      mounted: false,
      styles: {},
      listHeight: 0,
      pageYOffset: 0,
      convertDirection: '',
      showList: null,
      clicked: false,
      selectedItem: null,
      isOn: false,
      activeId: -1,
      styleValue: null,
      maxHeight: null,
      ready: false,
    };
  },
  watch: {
    list: 'update',
  },
  computed: {
    uid() {
      return this._uid;
    },
    zIndex() {
      let maxZindex = 3;
      let component = this.callComponent;

      while (component.$parent) {
        const element = component.$el;
        if (!element.style) return maxZindex;

        const zIndex = element.style.zIndex || getComputedStyle(element)
          .getPropertyValue('z-index');

        if (zIndex !== 'auto' && Number(zIndex) > maxZindex) maxZindex = Number(zIndex);

        component = component.$parent;
      }

      return maxZindex + 1;
    },
    isMobile() {
      return !!(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i));
    },
  },
  methods: {
    elStyle() {
      if (!this.callComponent) return {};
      const parentRect = this.callComponent.$el.getBoundingClientRect();
      let t = parentRect.top + parentRect.height;
      this.convertDirection = parentRect.top > window.innerHeight - t && t + 300 > window.innerHeight ? 'up' : 'down';
      if (this.convertDirection === 'up') t = parentRect.top;
      return { zIndex: this.zIndex, width: `${parentRect.width}px`, left: `${parentRect.left}px`, top: `${t + 10}px` };
    },
    label(item) {
      return this.translate ? this.$t(item.label) || this.$t(item) : item.label || item;
    },
    renderingHtml() {
      const targetEl = this.targetEl || this.callComponent.$el;
      const { top, bottom, left, right, width, height } = targetEl.getBoundingClientRect();
      this.styles = { top, bottom, left, right, width, height };

      let dropBoxWrap = document.querySelector('#drop-box-wrap');
      if (!dropBoxWrap) {
        dropBoxWrap = document.createElement('div');
        dropBoxWrap.setAttribute('id', 'drop-box-wrap');
        document.body.appendChild(dropBoxWrap);
      }
      dropBoxWrap.appendChild(this.$el);
    },

    clickListHandler(e, item) {
      e?.stopImmediatePropagation();
      this.selectedItem = item;

      if (Array.isArray(this.value)) {
        let arr = this.value;
        if (!arr.includes(item)) arr.push(item);
        arr = [...new Set(arr)]; // 요소가 객체일 경우를 대비해서 중복 제거
        this.callComponent.change(arr, true);
        if (item) this.activeId = this.list.findIndex(o => o === item);
      } else {
        this.callComponent.change(item, true);
        if (item) this.activeId = this.list.findIndex(o => o.value === item.value);
      }

      this.clicked = true;
    },
    setListScrollYOffset() {
      const scrollBody = this.$refs.listBox;
      const selectedItem = scrollBody.querySelector('li.active');
      if (selectedItem) {
        scrollBody.scrollTop = selectedItem.offsetTop;
      }
    },
    changeValue(val) {
      this.showList = val;
    },
    update() {
      if (!this.showList) return;
      this.renderingHtml();
      this.pageYOffset = window.pageYOffset;
      this.$nextTick(() => {
        this.listHeight = this.$refs.listBox?.offsetHeight;
        this.setListScrollYOffset();
      });
      this.selectedItem = this.value;
    },
    async updateSize() {
      const style = this.elStyle();
      const windowH = window.innerHeight;
      const targetRect = (this.targetEl || this.callComponent.$el).getBoundingClientRect();
      if (!this.isOn) {
        const gap = this.convertDirection === 'up' ? windowH - (windowH - targetRect.y) : windowH - targetRect.y;
        this.floatingHeight = this.$refs.holder?.getBoundingClientRect().height;
        this.maxHeight = `${gap - 120}px`;
        style.maxHeight = this.maxHeight;
        this.styleValue = style;

        await sleep(60);
      }
      if (this.convertDirection === 'up') {
        if (this.$refs?.listBox) {
          const listRect = this.$refs.listBox.getBoundingClientRect();
          style.top = `${targetRect.y - listRect.height}px`;
          style.maxHeight = `${targetRect.y - 100}px`;
        }
      } else {
        style.top = `${targetRect.y + 10 + targetRect.height}px`;
      }
      this.styleValue = style;
      if (this.ready) this.isOn = true;
    },
  },
  created() {
    this.mounted = true;
    this.showList = this.list;
  },
  async mounted() {
    this.update();
    addResize(this.$el, this.updateSize);
  },
  beforeDestroy() {
    const dropBoxWrap = document.querySelector('#drop-box-wrap');
    dropBoxWrap?.removeChild(this.$el);
    removeResize(this.$el, this.updateSize);
  },
};
