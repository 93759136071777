<template>
  <div pagination v-if="cursorInfo && cursorInfo.totalCount > 0" :class="theme">
    <span class="left" :class="{ invisible: now === 0 }">
      <button @click="$emit('change', 0)" :disabled="now === 0">
        <FontIcon name="angle-double-left" />
      </button>
      <button @click="$emit('change', now - 1)" :disabled="now === 0">
        <FontIcon name="angle-left" />
      </button>
    </span>
    <a v-for="cursor in cursors" :key="cursor" :class="{active: cursor === now}" @click="$emit('change', cursor)">{{ cursor + 1 }}</a>
    <span class="right" :class="{ invisible: now === last }">
      <button @click="$emit('change', now + 1)" :disabled="now === last">
        <FontIcon name="angle-right" />
      </button>
      <button @click="$emit('change', last)" :disabled="now === last">
        <FontIcon name="angle-double-right" />
      </button>
    </span>
    <p class="total-row" v-if="showRow && theme === 'white'">{{ $t('total') }} {{ info.TotalCount || info.totalCount }} {{ $t('rows') }}</p>
  </div>
</template>

<script>
import { range } from '@shared/utils/commonUtils.mjs';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'Pagination',
  components: { FontIcon },
  props: {
    info: Specific,
    chunkSize: { type: Number, required: true },
    showSize: { type: Number, default: 5 },
    theme: { type: String, default: 'gray' },
    showRow: { type: Boolean, default: true },
  },
  computed: {
    cursorInfo() {
      return this.info;
    },
    now() {
      return this.cursorInfo.currentCursor;
    },
    last() {
      return Math.ceil(this.cursorInfo.totalCount / this.chunkSize) - 1;
    },
    start() {
      return Math.max(0, Math.min(this.now - Math.floor((this.showSize - 1) / 2), this.last - this.showSize + 1));
    },
    end() {
      return Math.min(this.last, this.start + this.showSize - 1);
    },
    cursors() {
      return range(this.start, this.end);
    },
  },
  mounted() {
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
.RTL {
  [pagination] {
    button [font-icon] { .ib(); .t-sx(-1); }
  }
}
[pagination] { .tc; .mt(20); .mb(10); .rel();
  button { .wh(20); .vam;
    svg { .wh(12);
      .stroke-target { stroke: #777; }
    }
    @{hover-press} {
      svg {
        .stroke-target { stroke: #fff; }
      }
    }
  }
  button + button { .ml(10); }
  a { .vam; .ib; .mh(24); .rel; .p(0, 2); .c(#777); .o(0.4); .fs(12);
    @{hover-press} { .o(1); }
    &.active { .o(1);
      &:after {.cnt; .abs; .wh(30); .bgc(@c-mint); .br(50%); .lt(50%, 50%); .t-xyc(); .o(.3); }
    }
  }
  a + a { .ml(0); }
  .invisible { .o(0.2); pointer-events: none; }

  &.dark {
    button { .c(white);

    }
    a { .c(white); .o(0.4);
      @{hover-press} { .o(1); }
      &.active { .o(1);
        &:after {.cnt; .abs; .lb(50%,0); .t-xc(); .wh(100%, 1); .bgc(@c-red); .o(1);}
      }
    }
  }
  .total-row { .abs(); .lb(50%, -30); .t-xc(); .fs(14); .c(@c-b05); }
  @media (@tp-up) {
    .total-row { .b(4); .l(auto); .r(-45); }
  }
}
</style>
