<template>
  <SumsubContainer sumsub-try-count-over>
    <FontIcon name="sharp-triangle-warning" />
    <p v-html="$t('_.description1')" />
    <p v-html="$t('_.description2', {csEmail})" />
    <ProgressButton button-name="close" @click="$emit('confirm', 'close')" />
    <a class="btn-update-field" @click="$emit('confirm', 'reset')">{{ $t('tryAgain') }}</a>
  </SumsubContainer>
</template>

<script>
import SumsubContainer from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubContainer.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';

// 차후 기획 추가 시 활용 예정ß
export default {
  name: 'SumsubTryCountOver',
  lexicon: 'onBoarding.sumsub.tryCountOver',
  components: { ProgressButton, SumsubContainer, FontIcon },
  props: {
    item: {type: Specific, default: null},
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  mounted() {
    if (this.item) this.item.title = 'onBoarding.title.personalDetailsCheck';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sumsub-try-count-over] { .tc();
  > [font-icon] { .block(); .mb(24); .fs(80); }
  > p { .tl(); .fs(18, 22, normal); .c(#7F7F7F);
    > a[href^='mailto'] { .c(#2680EB); .underline; }
  }
  > p + p { .mt(22); }
  > .btn-update-field { .mt(24); .fs(16, 20, normal); .regular(); .c(#2680EB); .-b(#2680EB); }
}
</style>