<template>
  <li rg-oasis-check-box>
    <CheckboxItem theme="light" :selected="active" :disabled="disabled" @change="click">{{ label }}</CheckboxItem>
  </li>
</template>

<script>
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import Specific from '@shared/types/Specific';
export default {
  name: 'OasisCheckBox',
  components: { CheckboxItem },
  props: {
    active: { type: Boolean, default: false, },
    value: Specific,
    label: { type: String, default: null },
    disabled: { type: Boolean, default: false, }
  },
  methods: {
    click(v) {
      this.$emit('change', this.value);
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-oasis-check-box] {
  [checkbox-item] {line-hight: 16px; .c(#333333);
    > i {border-color: #CCC;}
    > em {line-height: 16px;}
    &.disabled {
      > i {.bgc(#DDD);}
      > em {.c(#CCC);}
    }

  }
}
</style>