<template>
  <div grid-table :class="[{loading},theme]">
    <div class="table-wrapper" :style="{minWidth: minWidth}">
      <table ref="header" :style="{width: bodyWidth}" class="header">
        <colgroup>
          <col v-for="(col, idx) in cols" :key="idx + '-' + col" :width="col" />
        </colgroup>
        <thead>
        <slot name="contextHeader" />
        <template v-if="multipleHeader">
          <GridTableHead class="multi-header">
            <GridTableTH v-for="item in multipleHeader.first" @sort="sort" :key="item.label" :label="$t(item.label)" :class="item.className" :use-sort="item.noneSort ? false: useSort" :sort-field="sortField" :sort-type="sortType" :field="item.key" :colspan="item.colspan" :rowspan="item.rowspan" :tooltip="item.tooltip" />
          </GridTableHead>
          <GridTableHead class="multi-header" @updated="colInit" ref="head">
            <GridTableTH v-for="(item,id) in multipleHeader.second" @sort="sort" :key="item.label + id" :label="$t(item.subLabel || item.label)" :class="item.className" :use-sort="item.noneSort ? false: useSort" :sort-field="sortField" :sort-type="sortType" :field="item.key" :tooltip="item.tooltip" />
          </GridTableHead>
        </template>
        <GridTableHead v-else ref="head" @updated="colInit" @sort="sort" :use-sort="useSort" :sort-field="sortField" :sort-type="sortType">
          <th v-if="useOrder">
            <template v-if="order">
              <ColorButton class="btn-order">{{ $t('order') }}</ColorButton>
            </template>
          </th>
          <th v-else-if="useSelect" class="select">
            <CheckboxItem v-model="selectAll" theme="default" />
          </th>
          <slot :row="{}" :isHeader="true" />
        </GridTableHead>
        </thead>
      </table>
      <div :style="{paddingTop: headerHeight, maxHeight}" class="scroll-holder">
        <div class="no-list" v-if="isEmpty(list)">
          <slot name="no-list" />
        </div>
        <table ref="body">
          <colgroup>
            <col v-for="(col, idx) in cols" :key="idx + '-' + col" :width="col" />
          </colgroup>
          <tbody :class="{order, moving}">
          <tr v-for="(item, index) in items" :key="item[idField]" @click="$emit('click',item)" :class="rowClass ? rowClass(item) : ''">
            <td v-if="order" class="order-handle">
              <i class="icon-menu" @mousedown.stop="orderDrag(index, $event)"></i>
            </td>
            <td v-else-if="useSelect" class="select">
              <CheckboxItem v-model="selectedItems" :value="item[idField]" theme="default" />
            </td>
            <td v-else-if="useOrder" class="pl-2">
              {{ index + 1 }}
            </td>
            <slot :index="index" :row="item" :no="paging ? paging.listCount - (paging.pageNo - 1) * paging.perPage - index : items.length - index" />
          </tr>
          </tbody>
        </table>
      </div>
      <nav ref="footer" class="d-flex justify-content-between mt-3">
        <div>
          <slot name="footer-left" />
        </div>
        <template v-if="paging">
          <Pagination :show-size="10" :chunk-size="10" @change="changePage" />
        </template>
        <div v-else>
          <slot name="footer-center" />
        </div>
        <div>
          <slot name="footer-right" />
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import { closest } from '@shared/utils/domUtils.mjs';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import Pagination from '@shared//components/common/Pagination.vue';
import GridTableHead from '@shared/components/common/table/grid/GridTableHead.vue';
import GridTableTH from '@shared/components/common/table/grid/GridTableTH.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'GridTable',
  components: { GridTableTH, ColorButton, Pagination, CheckboxItem, GridTableHead },
  props: {
    idField: { type: String, default: null },
    paging: { type: Object, default: null },
    useSelect: { type: Boolean, default: false },
    useOrder: { type: Boolean, default: false },
    useSort: { type: Boolean, default: false },
    list: { type: Array, default: null },
    maxHeight: { type: String, default: 'none' },
    loading: { type: Boolean, default: false },
    rowClass: { type: Function },
    initSortField: { type: String, default: null },
    initSortType: { type: String, default: 'desc' },
    minWidth: { type: String, default: '1000px' },
    multipleHeader: { type: Specific, default: null },
    theme: { type: String, default: 'white' },
  },
  data() {
    return {
      multipleList: null,
      selectedItems: [],
      cols: [],
      headerHeight: '0',
      bodyWidth: '100%',
      order: null,
      moving: false,
      pageNo: 1,
      lastSize: 0,
      destroy: false,
      sortField: null,
      sortType: null,
    };
  },
  computed: {
    selectAll: {
      get() {
        if (!this.list || !this.list.length) return false;
        return this.list.every(row => ~this.selectedItems.indexOf(row[this.idField]));
      },
      set(v) {
        this.selectedItems = v && this.list ? this.list.map(row => row[this.idField]) : [];
      },
    },
    items() {
      return this.order || this.list;
    },
    sorted() {
      return this.useSort && this.sortField ? _orderBy(this.list, [
        row => {
          const v = _get(row, this.sortField);
          return v === '-' ? Number.MAX_VALUE : v;
        },
      ], [this.sortType]) : this.list;
    },
  },
  watch: {
    list() {
      this.selectedItems = [];
      this.order = null;
    },
    selectedItems(v) {
      this.$emit('selected', v);
    },
    paging(v) {
      this.pageNo = v.pageNo;
    },
    initSortField: 'updateSort',
  },
  methods: {
    changePage(page) {
      this.pageNo = page;
      this.$emit('change-page', page);
    },
    colInit() {
      let cols = this.$refs.head.$children.filter(c => c.$options.name === 'GridColumn').map(c => c.weight);

      if (this.multipleHeader) {
        cols = [];
        const group = _.groupBy(this.multipleHeader.second, 'parentLabel');
        this.multipleHeader.first.forEach(o => {
          if (group[o.label]) {
            group[o.label].forEach(c => cols.push(c.weight || 1));
          } else {
            cols.push(o.weight || 1);
          }
        });
      }

      if (this.useSelect || this.useOrder) cols.unshift(3);
      const sum = cols.reduce((acc, v) => acc + v, 0);
      const can = cols.map(v => `${(v / sum) * 100}%`);
      if (_isEqual(can, this.cols)) return;
      this.cols = can;
    },
    setHeaderWidth() {
      this.bodyWidth = this.$refs.body ? `${this.$refs.body.offsetWidth}px` : '100%';
      requestAnimationFrame(() => {
        this.headerHeight = `${this.$refs?.header?.offsetHeight}px`;
      });
    },
    startOrder() {
      this.order = [...this.list];
    },
    saveOrder() {
      this.$emit('reorder', this.order);
    },
    cancelOrder() {
      this.order = null;
    },
    orderDrag(idx, e) {
      const target = closest(e.target, 'tr');
      const siblings = target.parentNode.childNodes;
      const heights = _map(siblings, el => el.offsetHeight);
      const ordered = _map(siblings, (el, idx) => idx);
      const max = target.parentNode.offsetHeight;
      let position = idx;

      const sum = i => (i > 0 ? ordered.slice(0, i).map(x => heights[x]).reduce((a, v) => a + v) : 0);
      const transTo = (el, y) => {
        const b = max - el.offsetHeight;
        const top = y < 0 ? 0 : y > b ? b : y;
        const delta = top - el.offsetTop;
        el.style.transform = `translateY(${delta}px)`;
      };

      const align = drag => {
        let o = 0;
        ordered.forEach(idx => {
          if (siblings[idx] !== drag) transTo(siblings[idx], o);
          o += heights[idx];
        });
      };

      this.moving = true;
      target.classList.add('drag');
      const down = e.screenY;
      const startY = target.offsetTop;
      const move = e => {
        e.preventDefault();
        const top = startY + e.screenY - down;
        transTo(target, top);
        const bottom = top + target.offsetHeight;
        const points = ordered.map((idx, i) => heights[idx] * 0.5 + sum(i));
        const d = points.filter((v, i) => v > top && i < position).length;
        if (d > 0) {
          ordered.splice(position - d, 0, ordered.splice(position, 1));
          position -= d;
          align(target);
          return;
        }
        const u = points.filter((v, i) => v < bottom && i > position).length;
        if (u > 0) {
          ordered.splice(position + u, 0, ordered.splice(position, 1));
          position += u;
          align(target);
        }
      };
      const up = () => {
        target.classList.remove('drag');
        align();
        document.removeEventListener('mousemove', move);
        document.removeEventListener('mouseup', up);
        setTimeout(() => {
          this.moving = false;
          siblings.forEach(e => e.style.transform = null);
          this.order = ordered.map(idx => this.order[idx]);
        }, 300);
      };
      document.addEventListener('mousemove', move);
      document.addEventListener('mouseup', up);
    },
    tick() {
      if (this.destroy) return;
      if (this.lastSize !== this.$el.offsetWidth) {
        this.lastSize = this.$el.offsetWidth;
        this.setHeaderWidth();
      }
      requestAnimationFrame(this.tick);
    },
    sort(field) {
      if (this.sortField === field) {
        this.sortType = this.sortType === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortType = 'desc';
        this.sortField = field;
      }
      this.$emit('sort', this.sortField, this.sortType === 'asc');
    },
    scrollX() {
      this.$store.commit('setHideAction');
    },
    updateSort() {
      if (this.initSortField) {
        this.sortField = this.initSortField;
        this.sortType = this.initSortType;
      }
    }
  },
  updated() {
    this.setHeaderWidth();
  },
  mounted() {
    this.tick();
    this.setHeaderWidth();
    this.colInit();
    this.updateSort();
    this.$el.addEventListener('scroll', this.scrollX);
  },
  beforeDestroy() {
    this.destroy = true;
    this.$el.removeEventListener('scroll', this.scrollX);
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[grid-table] { .rel; .min-w(100%); overflow-x: auto;
  &::-webkit-scrollbar { .h(8); }
  .table-wrapper { .rel(); }
  .select { .tc();
    [checkbox-item] { .block(); .mh-c();
      em { .ml(0); }
    }
  }
  table { table-layout: fixed;
    .multi-header { .-a(@c-b02);
      th {
        //&:nth-of-type(1) { .-l()!important; }
      }
    }
    thead { .w(100%);
      tr {
        &:nth-of-type(2) {
          th { .-l(#aaa) !important; }
        }
      }
      th { .-t(#aaa); .-b(#aaa, 2); .p(8, 8); .fs(14); .tc() !important;
        &:not(:nth-of-type(1)) { .-l(#aaa); }
        > i { .ib; .rel; .wh(12, 8); .ml(5); .pointer();
          &:before, &:after { .abs; .cnt; .block; .-l(transparent, 4px); .-r(transparent, 4px); }
          &:before { .lb(0, -1); .-t(#545454, 4); }
          &:after { .lt(0, -1); .-b(#545454, 4); }
          &.desc:before { .-t(@c-mint, 4) }
          &.asc:after { .-b(@c-mint, 4) }
        }
      }
    }
    tbody { .rel;
      td { .-b(#ccc); .p(8, 8); .fs(14);
        .bold { .bold; }
      }
      &.moving tr { transition: transform 0.3s; box-shadow: inset 0 0 1px 0 #999; }
      tr { .rel; .bgc(#fff);
        &:nth-of-type(odd) { .bgc(rgba(0, 0, 0, .05)); }
        &.drag { z-index: 1; box-shadow: 0 0 5px #999; transition: none; }
      }

    }
  }
  .header { .abs; .lt; .bgc(#fff); z-index: 2; }
  .scroll-holder { .ib; overflow-y: auto; .rel; .-b(#ccc); }
  i.icon-menu { cursor: row-resize;
    &.dimmed { .o(0.2); cursor: no-drop; }
  }
  .no-list { .p(10); .fs(15); .tc; }
  &.loading:after { content: 'Loading...'; .abs; .lt; .f; display: flex; justify-content: center; flex-flow: column; .tc; background-color: rgba(0, 0, 0, 0.2); .bold; color: #fff; z-index: 10; .fs(20); }
  &.dark {
    table {
      thead {
        tr {
          th { .tl();
            &:not(:nth-of-type(1)) { .-l(); }
          }
        }
      }
      tbody {
        tr { .bgc(transparent) !important; }
      }
    }
    .header { .bgc(transparent); }
    .scroll-holder { .-b(); }
  }
}
</style>
