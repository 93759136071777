import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';

export const getDisplayAmount = (currency, amount) => {
  if (!amount) `${getCurrencySymbol(currency)} ${amount}`;
  if (amount <= 0) return `${getCurrencySymbol(currency)} ${amount}`;
  try {
    return `${getCurrencySymbol(currency)} ${parseFloat(amount).toFixed(2)}`;
  } catch (e) {
    return 0;
  }
};