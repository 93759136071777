<template>
  <MfaModalTemplate mfa-success>
    <template #content>
      <div class="img-group">
        <FontIcon name="check-over-circle-regular" />
        <p>{{ $t('_.googleAuthenticationStep5') }}</p>
      </div>
    </template>
    <!--    <template #footer>-->
    <!--      <GpButton type="submit-green" @click="close">{{ $t('ok') }}</GpButton>-->
    <!--    </template>-->
  </MfaModalTemplate>
</template>

<script>
import MfaModalTemplate from '@/views/components/pages/my-page/modal/components/MfaModalTemplate.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
export default {
  name: 'SuccessMfa',
  lexicon: 'myInfo',
  components: { MfaModalTemplate, GpButton, FontIcon },
  methods: {
    close() {
      this.$emit('verify');
      this.$modalCloseAll();
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[mfa-success] {
  .img-group { .flex(); .items-center(); .flex-dc(); .h(100%); .pt(30);
    > i { .fs(30); .c(@gp-green);}
    > p {.c(black); font-size: 16px !important; .pt(10);}
  }
}
</style>
