<template>
  <div email-search-input-select tabindex="0" @focus="focusIn" @blur="focusOut">
    <a :class="{ 'placeholder' : empty}" @click="toggle(null)">
      <TrimTextInput v-model="searchText" size="sm" theme="black" :placeholder="placeholder" :maxLength="maxLength" @focus="$emit('focus')"/>
    </a>
    <div class="list-container" :style="listContainerStyleValue" :class="[{'on' : isShow && showList.length}]">
      <div class="list-inner" :style="listInnerStyleValue">
        <div class="scroll-holder" ref="scroller" v-if="showList.length">
          <div class="size-holder">
            <button v-for="(item,id) in showList" :key="id" :tabindex="isOn ? '0' : '-1'" :class="{'active': selectedItem && item.label === selectedItem.label, 'focus': focusId === id}"
                    @click="updateItem(item)" v-html="getView(item)" @blur="id === showList.length - 1 ? toggle(false) : () => {}" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'EmailSearchInputSelect',
  components: { TrimTextInput },
  props: {
    value: { /** @type {BaseInfo} */ type: Specific, default: null },
    list: { type: Array, default: null },
    placeholder: { type: String, default: null },
    maxLength: { type: Number, default: 999 },
  },
  data() {
    return {
      focusId: -1,
      showList: [],
      searchText: '',
      selectedItem: null,
      isOn: false,
      isBottom: false,
      listContainerStyleValue: null,
      listInnerStyleValue: null,
      scrollHeight: null,
      isFocus: false,
      shiftDown: false,
    };
  },
  computed: {
    env: state('env', 'env'),
    site: state('env', 'site'),
    myInfo: state('user', 'userInfo'),
    isMobile: state('browser', 'mobile'),
    landingMode: state('query', 'landingMode'),
    empty() {
      return !this.selectedItem;
    },
    gap() {
      return 16;
    },
    searchGap() {
      return 60;
    },
    tabindex() {
      return (this.showList || []).length ? '0' : '-1';
    },
    isShow() {
      const searchText = this.searchText?.toLowerCase();
      const searchDomain = searchText?.split('@');
      return searchDomain && searchDomain[0].length > 0 && searchDomain[1]?.length > 0;
    },
    originList() {
      const label = this.searchText?.split('@')[0] ? `${this.searchText?.split('@')[0]}@` : '';
      return this.list?.map(i => ({ label: `${label}${i.label}`, value: i.value}));
    },
  },
  watch: {
    value: 'updateModel',
    searchText: 'updateSearch',
    basePaddingBottom: 'updatePosition',
    lang() {
      this.getList();
      if(this.originList) this.selectedItem = this.showList.find(o => o.value === this.selectedItem.value);
    },
    async list() {
      if(!this.originList?.length || !this.isShow) return;
      this.showList = this.isShow ? this.filterList(this.originList) : [];
      if(this.showList?.length) {
        await this.toggle(true);
        this.$el.querySelector('input').focus();
      }
    },
  },
  methods: {
    toggle(on) {
      if(this.isOn && on === null) return;
      this.isOn = on === null ? !this.isOn : on;
      if (this.isOn) {
        this.getList();
        this.updatePosition();
        if (this.selectedItem) this.scrollUpdate(null, this.getTargetId(this.selectedItem.value));
      } else {
        this.off();
      }
    },
    getList() {
      this.showList = this.isShow ? this.filterList(this.originList) : [];
      this.updateModel();
    },
    filterList(list) {
      return list.filter(item => item.label.toLowerCase().startsWith(this.searchText.toLowerCase()))?.slice(0, 5) ?? [];
    },
    async updatePosition() {
      if (!this.isOn || !this.$el) return;

      await sleep(60);
      const parentRect = this.$el.getBoundingClientRect();
      const l = parentRect.left;
      const w = parentRect.width;
      let t = parentRect.top + parentRect.height;
      let h = window.innerHeight - (parentRect.top + parentRect.height + 60);

      this.isBottom = t + h < window.innerHeight && h > 200;
      h = !this.isBottom ? Math.min(parentRect.top - 50, 300) : h;
      this.scrollHeight = `${h - this.searchGap}px`;

      const minHeight = 100; // 높이 최소 값
      let height = 0;
      if (!this.showList?.length) height = minHeight;
      else {
        const rowCount = this.$el.querySelector('.size-holder')?.children?.length || 0;
        const rowHeight = this.$el.querySelector('.size-holder > button')?.clientHeight || 0;
        height = Math.min(Math.min(h, (rowCount * rowHeight) + this.gap), 500);
        height = height < minHeight ? minHeight : height;
      }

      t = !this.isBottom ? (-height - 28) : this.$el.clientHeight + 4;
      this.listContainerStyleValue = { top: `${t}px`, height: `${height}px` };
      this.listInnerStyleValue = {height: '100%'};
    },
    scrollUpdate(key, id) {
      if (!this.showList.length) return;
      let idx = -1;
      if (id >= 0) {
        idx = id;
      } else if (key) {
        const group = this.showList.filter(o => o.label.slice(0, 1).toLowerCase() === key.toLowerCase());
        if (!group.length || this.focusId < 0) return;
        const targetId = this.getTargetId(this.showList[this.focusId]?.value) || 0;
        if (group.length > 0) {
          const groupId = group.findIndex(o => o.value === this.showList[this.focusId].value);
          idx = groupId < 0 || targetId + 1 >= this.focusId + (group.length - (groupId)) ? this.getTargetId(group[0].value) : targetId + 1;
        } else {
          idx = targetId;
        }
      }

      const scroller = this.$refs.scroller;
      if (idx < 0 || !scroller) return;
      const t = this.$el.querySelector('.size-holder');
      const h = (t.clientHeight - scroller.clientHeight) / (this.showList.length - 1);
      scroller.scrollTo(0, idx * h);
      this.focusId = idx;
    },
    getView(v, selected) {
      const template = this.updateTemplate;
      return template(v, selected);
    },
    updateTemplate(v) {
      return v.label;
    },
    updateFocus() {
      this.focusId = this.showList.findIndex(o => o.value === this.selectedItem?.value);
    },
    updateItem(item) {
      if (!item) return;

      this.selectedItem = item;
      this.searchText = item.label;
      this.$emit('input', this.selectedItem.label);
      this.updateFocus();
      this.off();
    },
    updateModel() {
      if (!this.showList.length) return;

      if (!this.selectedItem) {
        this.selectedItem = this.originList?.[0] || this.originList?.[0];
        this.searchText = this.selectedItem.label;
        this.$emit('input', this.selectedItem.label);
      }
    },
    updateSearch() {
      if (this.isShow) {
        this.showList = this.filterList(this.originList);
        this.updateFocus();
        this.updatePosition();
      } else {
        this.showList = [];
      }
    },
    off() {
      this.isOn = false;
      this.listContainerStyleValue = { height: 0 };
    },
    getTargetId(value) {
      return this.showList.findIndex(o => o.value === value);
    },
    checkedOpen() {
      if(this.shiftDown || !this.isFocus || this.isOn) return true;
      this.toggle(true, false);
      this.$el.querySelector('input').focus();
      return false;
    },
    keyDown(e) {
      switch (e.keyCode) {
        case 16:
          this.shiftDown = true;
          return;
        case 40:
          if(!this.checkedOpen()) return;
        case 9: // tab
          if (this.isOn) {
            e.preventDefault();
            this.$el.querySelector('input').focus();
            this.focusId = this.showList.length - 1 < this.focusId + 1 ? 0 : this.focusId + 1;
          } else {
            this.$emit('blur');
          }
          break;
        case 38:
          if(!this.checkedOpen()) return;
          if (this.isOn) {
            this.$el.querySelector('input').focus();
            this.focusId = (this.focusId - 1) < 0 ? (this.showList.length - 1 || 0) : this.focusId - 1;
          }
          break;
        case 13:
          this.updateItem(this.showList[this.focusId]);
          this.off();
          break;
        default:
          if (this.isOn && e.key?.length === 1 && !this.searchText?.length) this.scrollUpdate(e.key);
          return;
      }
      if (this.isOn && this.focusId >= 0) this.scrollUpdate(null, this.focusId);
    },
    keyUp(e) {
      switch(e.keyCode) {
        case 16:
          this.shiftDown = false;
          return;
      }
    },
    checkPoint(e) {
      const mx = e.pageX;
      const my = e.pageY;
      const size = 68;
      const list = this.$el.querySelector('.list-container');
      const pos = this.$el.getBoundingClientRect();
      const posW = pos.x + (my > pos.y + pos.height ? list.clientWidth : this.$el.clientWidth);
      const listH = list.clientHeight;
      const posH = pos.y + (this.isOn ? (this.isBottom ? listH + pos.height : -listH) : size);
      const gap = 30;
      const sy = window.scrollY;
      if (mx < pos.x || mx > posW || (this.isBottom && (my - sy < pos.y - gap || my - sy > posH)) || (!this.isBottom && (my - sy > pos.y + pos.height || my - sy < posH))) {
        this.off();
        this.$emit('blur');
      }
    },
    eventControl(remove) {
      if (remove) {
        document.body.removeEventListener('click', this.checkPoint);
        window.removeEventListener('resize', this.updatePosition);
        window.removeEventListener('keydown', this.keyDown);
        window.removeEventListener('keyup', this.keyUp);
      } else {
        document.body.addEventListener('click', this.checkPoint);
        window.addEventListener('resize', this.updatePosition);
        window.addEventListener('keydown', this.keyDown);
        window.addEventListener('keyup', this.keyUp);
      }
    },
    focusIn() {
      this.isFocus = true;
      this.$emit('focus');
    },
    focusOut() {
      this.isFocus = false;
    }
  },
  mounted() {
    /** For Caching List */
    this.getList();
    this.eventControl(false);
  },
  beforeDestroy() {
    this.eventControl(true);
  },
};
</script>

<style lang="less">
@import '@/less/proj.less';
[email-search-input-select] { .rel();
  > a { .flex(); .items-center(); .rel(); .p(0, 12); .w(100%); .h(36); .br(8); .bgc(transparent); .-box(); box-shadow: none; .fs(18); .c(white);
    > label { .medium();
      > em { .fs(18); }
      img { .mb(4); }
    }
    svg { .wh(16); .abs(); .rt(10, 45%); .t-yc();
      path { .fill(white); }
      &.on { .t-r(180deg); }
    }
    > [text-input] {.-a(); box-shadow: none; .w(100%);
      input { .pl(0); }
    }
  }
  .list-container { box-shadow: 2px 0 3px rgba(0, 0, 0, 0.5); .bgc(#1e1e1e); .br(8); .br-t(0); .abs(); .o(0); .crop(); .w(100%); .max-h(500); .h(0);
    &.on { .o(1); .z(10); }
    .list-inner { .pb(12); .hf(); .fs(14);
      .input-holder { .p(10, 12);}
      .scroll-holder { overflow-y: auto; .h(100%);
        label { .block(); .p(10, 16); .w(100%); .c(#999); .tl(); }
        button { .flex(); .p(10, 16); .w(100%); .c(white); .tl();
          &:hover, &:focus, &.focus { .bgc(@c-b02); }
          &.active { .bgc(@c-b04); }
          em { .fs(14); .w(100%); }
        }
      }
    }
    .list-footer > img { .flex(); .mr(15); .ml(auto); }
  }
  [text-input] {
    input { .fs(14, 20); .pb(4);}
  }
  .placeholder { .c(@c-w03); .regular(); }
}
</style>
