<template>
  <div occupation-ph>
    <ValidationComponent :label="$t('_.label.sourceOfFunds')" name="_sourceOfFunds" :component="SearchDropSelect" :rules="sourceOfFundsRules" :preset="sourceOfFundsPreset" :value="sourceOfFunds" @input="updateSourceOfFunds" />
    <ValidationComponent :label="$t('_.label.employmentStatus')" name="_employmentStatus" :component="SearchDropSelect" :rules="employmentStatusRules" :preset="employmentStatusPreset" :value="employmentStatus" @input="updateEmploymentStatus" />
    <ValidationComponent :key="modelInfo.OccupationType" :label="$t('_.label.occupationEmployed')" name="_occupation" :component="SearchDropSelect" :rules="occupationRules" :preset="occupationPreset" :value="occupation" @input="updateOccupation" />
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { state } from '@shared/utils/storeUtils.mjs';
import { kycEmploymentDocuments } from '@/constants/base/onboarding/kyc';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'OccupationPh',
  lexicon: 'onBoarding',
  mixins: [baseTemplate],
  components: { ValidationComponent },
  data() {
    return {
      SearchDropSelect,
      sourceOfFunds: null,
      sourceOfFundsList: [
        { label: this.$t('businessIncome'), value: 'BusinessIncome' },
        { label: this.$t('employment'), value: 'Employment' },
        { label: this.$t('inheritance'), value: 'Inheritance' },
        { label: this.$t('investmentsSavings'), value: 'InvestmentsSavings' },
        { label: this.$t('pensionRetirementIncome'), value: 'PensionRetirementIncome' },
      ],
      employmentStatus: null,
      employmentStatusList: null,
      occupation: null,
      allOccupationList: null,
      studentFilter: ['Student'],
      unemployedFilter: ['Unemployed'],
      retiredFilter: ['Retired'],
      selfEmployedFilter: ['Jeweler', 'Pawnbroker', 'ConvenienceStoreOwner', 'RestaurantOwner', 'RealEstateBroker', 'RetailStoreOwner', 'LiquorStoreOwner', 'CannabisStoreOwner', 'PrivatelyOwnedAutomatedTellerMachinesOwner', 'ProfessionalPokerPlayer', 'CryptocurrencyTrader', 'LuxuryGoodsDealer', 'Influencer', 'CommoditiesTrader', 'GasStationOwner'],
      employedExcludeFilter: ['Unemployed', 'Student', 'Jeweler', 'Pawnbroker', 'ConvenienceStoreOwner', 'RestaurantOwner', 'RealEstateBroker', 'RetailStoreOwner', 'LiquorStoreOwner', 'CannabisStoreOwner', 'PrivatelyOwnedAutomatedTellerMachinesOwner', 'ProfessionalPokerPlayer', 'CryptocurrencyTrader', 'LuxuryGoodsDealer', 'Influencer', 'CommoditiesTrader', 'GasStationOwner'],
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    userInfo: state('user', 'userInfo'),
    sourceOfFundsPreset() {
      return { list: this.sourceOfFundsList, placeholder: this.$t('_.sourceOfFunds.sourcePlaceHolder'), searchHandler:this.searchHandler };
    },
    employmentStatusPreset() {
      return { list: this.employmentStatusList, placeholder: this.$t('_.sourceOfFunds.employmentStatusPlaceHolder'), searchHandler:this.searchHandler };
    },
    occupationPreset() {
      return { list: this.occupationList, placeholder: this.$t('_.sourceOfFunds.occupationPlaceHolder'), searchHandler:this.searchHandler };
    },
    sourceOfFundsRules() {
      return 'requiredSelect';
    },
    employmentStatusRules() {
      return 'requiredEmploymentStatus';
    },
    occupationRules() {
      return 'requiredOccupation';
    },
    occupationList() {
      if (!this.employmentStatus || !this.allOccupationList) return null;
      switch (this.employmentStatus.value) {
        case 'Employed':
          return this.allOccupationList.filter(type => !this.employedExcludeFilter.includes(type.value));
        case 'SelfEmployed':
          return this.allOccupationList.filter(type => this.selfEmployedFilter.includes(type.value));
        case 'Retired':
          return this.allOccupationList.filter(type => this.retiredFilter.includes(type.value));
        case 'Student':
          return this.allOccupationList.filter(type => this.studentFilter.includes(type.value));
        case 'Unemployed':
          return this.allOccupationList.filter(type => this.unemployedFilter.includes(type.value));
        default:
          return null;
      }
    }
  },
  watch:{
    'occupationList': async function(){
      const currentOccupation = this.occupationList.find(v => v.value === this.modelInfo.OccupationType);
      if(!currentOccupation) {
        this.occupation = null;
        this.modelInfo.OccupationType = null;
      }
    }
  },
  methods: {
    async updateSourceOfFunds(item) {
      if(!item) return;
      this.sourceOfFunds = item;
      this.modelInfo.SourceOfFunds = item?.value;
    },
    async updateEmploymentStatus(item) {
      if(!item) return;
      this.employmentStatus = item;
      this.modelInfo.EmploymentType = item?.value;
    },

    updateOccupation(item) {
      if (!item) return;
      this.occupation = item;
      this.modelInfo.OccupationType = item?.value;
    },
    async getOccupationType() {
      let r;
      try {
        r = await this.$services.onboarding.getAllOccupationType();
      } catch (e) {
        r = e;
      }

      if (r.error) {
        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail });
        this.structure.errorDesc = r.desc;
      }

      return r;
    },
    searchHandler(originList, searchText){
      return originList.filter(o => o.label?.toLowerCase().includes(searchText) || o.value?.toLowerCase().includes(searchText));
    },
    async initialize() {
      this.getOccupationType().then(async r => {
        this.allOccupationList = r.OccupationTypes?.filter(type => type !== 'None').map(type => ({ label: this.$t(`occupation.${type}`), value: type }));
        this.employmentStatusList = kycEmploymentDocuments.map(doc => ({ label: this.$t(doc.label), value: doc.value }));
        if (this.modelInfo?.SourceOfFunds) this.sourceOfFunds = this.sourceOfFundsList.find(v => v.value === this.modelInfo.SourceOfFunds);
        if (this.modelInfo?.EmploymentType) {
          await sleep(10);
          this.employmentStatus = this.employmentStatusList.find(v => v.value === this.modelInfo.EmploymentType);
        }

        if (this.modelInfo?.OccupationType) {
          await sleep(10);
          this.occupation = this.allOccupationList.find(v => v.value === this.modelInfo.OccupationType);
        }
      });
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[occupation-ph] {.flex(); .flex-dc(); .gap(8);}
</style>
