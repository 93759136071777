<template>
  <div reviewing-documents>
    <p class="desc" v-html="$t('_.reviewingDocuments.description')" />
    <dl>
      <dt>{{ $t('_.uploadedDocuments') }}</dt>
      <dd v-if="pagingInfo">
        <ol v-for="(item,id) in currentPage" :key="id">
          <li>{{ getDate(item) }}</li>
          <li>
            <b v-if="useSelectFileType(item)">{{ $t(`_.documentType.${item.DocumentType}`) }}</b>
            <em>{{ item.FileName }}</em>
          </li>
          <li>{{ $t(`kyc.${getStatus(item.DocumentStatus)}`) }}</li>
        </ol>
      </dd>
    </dl>
    <Pagination v-if="info.length > 4" :chunk-size="chunk" @change="changedPage" :info="pagingInfo" theme="dark"/>
    <ProgressButton :button-name="buttonName" @click="confirm" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isMga } from '@/utils/baseUtil';
import { toFullDate } from '@/utils/dateTimeUtil';
import _ from 'lodash';
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Pagination from '@shared/components/common/Pagination.vue';

export default {
  name: 'ReviewingDocuments',
  components: { ProgressButton, Pagination },
  lexicon: 'kyc',
  props: {
    item: { type: Specific, default: null },
    info: /** @type { KycDocument[] } */Specific,
    complete: Boolean,
  },
  data() {
    return {
      pagingInfo: null,
      chunk: 4,
      cursor: 0,
    };
  },
  computed: {
    site: state('env', 'site'),
    buttonName() {
      return isMga(this.site) ? 'next' : 'complete';
    },
    currentPage() {
      return this.pagingInfo.list[this.pagingInfo.currentCursor];
    }
  },
  watch: {
    info: 'updatePaging',
  },
  methods: {
    changedPage(cursor) {
      this.pagingInfo.currentCursor = cursor;
    },
    useSelectFileType(item) {
      if (!item) return;
      return item.DocumentType && item.DocumentType !== '0';
    },
    getDate(item) {
      const locale = this.$i18n('locale');
      return toFullDate(item.UploadDate, locale);
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'reviewing';
        case 1:
          return 'approved';
        case 2:
          return 'rejected';
        case 3:
          return 'expired';
        default:
          return '';
      }
    },
    confirm() {
      isMga(this.site) ? this.$emit('next') : this.$emit('complete');
    },
    updatePaging() {
      if(this.info) {
        this.pagingInfo = {
          list: _.chunk(this.info, this.chunk),
          currentCursor: 0,
          totalCount: this.info.length,
        };
      }
    }
  },
  mounted() {
    this.updatePaging();
    if (this.item) this.item.title = 'onBoarding.title.reviewingDocuments';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reviewing-documents] {
  dl { .mt(32);
    dt { .fs(16); .mb(8); }
    dd {
      ol { .w(100%); .rel(); .pt(12); .-b(@c-w02); .pb(12);
        li { .c(@c-text-desc); .fs(14); .medium(); .ib();
          b { .c(@c-white); .mr(8); .fs(16); }
          em { .max-w(100); .ellipsis(); .ib(); .vat();}
          &:nth-of-type(1) { .block(); }
          &:nth-of-type(3) { .abs; .rt(0,12); .fs(16); .c(@c-yellow); }
        }
        &:not(:nth-of-type(1)) {  .mt(12); }
        &:last-child { .-b(); }
      }
    }
  }
  @media (@mm-up) {
    dl {
      dt {}
      dd {
        ol { .flex(); .space-between();
          li {
            &:nth-of-type(1) { .w(110); }
            &:nth-of-type(2) { .flex-grow(2);
              b { .block(); }
              em { .max-w(none); }
            }
          }
        }
      }
    }
  }
}
</style>
