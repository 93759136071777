<template>
  <ValidationComponent v-if="list" citizenship name="_citizenship" :label="$t('personalDetails.citizenship')" :component="SearchDropSelect" rules="requiredSelect" :value="nationality" :preset="preset" @input="updateValue" />
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import baseTemplate from '@/mixins/baseTemplate';
import Specific from '@shared/types/Specific';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'Citizenship',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  data() {
    return {
      SearchDropSelect,
      nationality: null,
      list: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    preset() {
      return { list: this.list, disable: this.disabled || this.disabledNationality, placeholder: this.placeholder };
    },
    emptyNationality() {
      return [siteIds.GGPDE, siteIds.GGPNL].includes(this.site);
    },
    placeholder() {
      return this.$t('nothingSelected');
    },
    disabledNationality() {
      return this.modelInfo.blockFields?.includes('Nationality') && !!this.modelInfo.Nationality;
    },
  },
  methods: {
    updateValue(v) {
      this.nationality = v;
      this.modelInfo.Nationality = v?.value;
    },
    async getList() {
      this.list = (await this.$services.base.getAllCountries()).List.map(o => ({ label: this.$t(`country.${o.CountryCode}`), value: o.CountryCode, ...o }));
    }
  },
  async mounted() {
    await this.getList();
    const nationality = this.list?.find(item => item.value === this.modelInfo.Nationality);
    if (nationality) this.nationality = nationality;
    else {
      const userCountryCode = (await this.$services.base.getUserCountry())?.CountryCode;
      const uesrNationality = this.list?.find(item => item.value === userCountryCode);
      this.list = [uesrNationality, ...this.list.filter(item => item !== uesrNationality)];
    }
    this.modelInfo.Nationality = nationality?.value;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[citizenship] {}
</style>