import moment from 'moment';

export default i18n => {
  const proto = (context, key, plural, noMark, options) => {
    const k = Array.isArray(key) ? key.filter(v => v).join('.') : key;
    if(!k) return '';
    return i18n.translate(k[0] === '_' ? context['$options'].lexicon + k.substr(1) : k, context.$i18n('lang'), plural, noMark, options);
  };

  /**
   * @param {string|string[]} key
   * @param {Object?} options
   * @returns {string}
   */
  const i18nT = function (key, options) {
    return proto(this, key, false, false, options);
  };

  /**
   * @param {string|string[]} key
   * @param {Object?} options
   * @returns {string|null}
   */
  const i18nTe = function (key, options) {
    return proto(this, key, false, true, options);
  };

  /**
   * @param {string|string[]} key
   * @param {number} options
   * @returns {string}
   */
  const i18nTc = function (key, options) {
    return proto(this, key, true, false, options);
  };

  const tv = (lang, params) => {
    const type = params._rule_;
    const options = { ...params, _name_: i18n.translate(['validation', 'names', params._field_].join('.'), lang, false, false) };
    return i18n.translate(['validation', 'rules', type].join('.'), lang, false, false, options);
  };

  /**
   * @param {{_rule_: string, _field_: string}} params
   * @return {string}
   */
  const i18nTv = function (params) {
    return tv(this.$i18n('lang'), params);
  };

  /**
   * @param {string|number} time
   * @param {string} dateFormat
   * @return {string}
   */
  const i18nFromNow = function (time, dateFormat = 'YYYY.MM.DD') {
    if (!time) return '';
    const target = +time - (this.$store.getters.timeDiff || 0);
    if (Math.abs(target - new Date()) < 1000) return this.$t('shared.justBefore');
    const date = moment(target);
    const timeDifference = Math.abs(moment.duration(date.diff(moment)).asDays());
    return timeDifference < 6 ? date.fromNow() : date.format(dateFormat);
  };

  /**
   * @param {Duration} duration
   * @return {string}
   */
  function i18nDuration(duration) {
    if (!duration) return '';
    return moment.duration(duration).humanize();
  }

  /**
   * @param {string|number} time
   * @param {string} key
   * @return {string}
   */
  const i18nDate = function (time, key = 'ymd') {
    return moment(+time).format(i18n.translate(`date.${key}`, this.$i18n('lang'), false, false));
  };

  /**
   * @param {'path'|'lang'|'country'|'locale'} key
   * @return {string}
   */
  const i18nProps = function (key) {
    const routeLocale = this.$store.state.route.params.locale;
    if (key === 'path') return i18n.pathByRouteLocale(routeLocale);
    if (key === 'lang') return i18n.languageByRouteLocale(routeLocale);
    if (key === 'locale') return i18n.localeByRouteLocale(routeLocale);
    if (key === 'country') return i18n.countryByRouteLocale(routeLocale);
    throw '[i18n] invalid key';
  };

  const directiveCommon = {
    bind(el, binding, vnode) {
      const key = [binding.arg === '_' ? vnode.context.$options.lexicon : binding.arg, ...Object.keys(binding.modifiers)].join('.');
      const run = locale => el.innerHTML = i18n.translate(key, locale, binding.name === 'tc', binding.name === 'te', binding.value);
      vnode.context.$watch('$store.state.route.params.locale', run);
      run(vnode.context.$i18n('lang'));
    },
  };

  const directivePlaceholder = {
    bind(el, binding, vnode) {
      const key = [binding.arg === '_' ? vnode.context.$options.lexicon : binding.arg, ...Object.keys(binding.modifiers)].join('.');
      const run = locale => el.setAttribute('placeholder', i18n.translate(key, locale, false, false, binding.value));
      vnode.context.$watch('$store.state.route.params.locale', run);
      run(vnode.context.$i18n('lang'));
    },
  };

  const directiveTv = {
    bind(el, binding, vnode) {
      const run = locale => el.innerHTML = tv(locale, binding.value);
      vnode.context.$watch('$store.state.route.params.locale', run);
      run(vnode.context.$i18n('lang'));
    },
  };

  return {
    directives: { common: directiveCommon, tv: directiveTv, placeholder: directivePlaceholder },
    prototypes: { i18nT, i18nTe, i18nTc, i18nTv, i18nFromNow, i18nDate, i18nProps, i18nDuration },
  };
};
