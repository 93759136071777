import { siteIds } from '@/constants/base/siteMap.mjs';

export const tncLink = {
  default: {
    dailyFreebie: {
      [siteIds.GGPCOM]: 'https://ggpcom-en.ggp-stage.com/promotions/daily-freebie/',
      [siteIds.GGPNG]: 'https://ggpcom-en.ggp-stage.com/promotions/daily-freebie/',
      [siteIds.GGPJP]: 'https://ggpcom-en.ggp-stage.com/ja/promotions/daily-freebie/',
      [siteIds.GGPPL]: 'https://pl-contents.ggp-stage.com/promotions/daily-freebie/',
      [siteIds.GGPCW]: 'https://ggpcom-en.ggp-stage.com/promotions/daily-freebie/',
      [siteIds.GGPBR]: 'https://ggpcom-en.ggp-stage.com/pt-br/promotions/daily-freebie/',
      [siteIds.GGPEU]: 'https://ggpcom-en.ggp-stage.com/promotions/daily-freebie/',
      [siteIds.GGPFI]: 'https://ggpcom-en.ggp-stage.com/fi/promotions/daily-freebie/',
      [siteIds.GGPHU]: 'https://ggpcom-en.ggp-stage.com/hu/promotions/daily-freebie/',
      [siteIds.GGPDE]: 'https://ggpcom-en.ggp-stage.com/de/promotions/daily-freebie/',
      [siteIds.GGPRO]: 'https://ggpcom-en.ggp-stage.com/ro/promotions/daily-freebie/',
      [siteIds.GGPNL]: 'https://ggpcom-en.ggp-stage.com/nl/promotions/daily-freebie/',
      [siteIds.GGPPH]: 'https://ggpcom-en.ggp-stage.com/promotions/daily-freebie/',
      [siteIds.GGPUK]: 'https://uk-contents.ggp-stage.com/promotions/daily-freebie/',
    },
    matchBonus: {
      [siteIds.GGPCOM]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPNG]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPJP]: 'https://ggpcom-en.ggp-stage.com/ja/promotions/welcome-bonus/',
      [siteIds.GGPPL]: 'https://pl-contents.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPCW]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPBR]: 'https://ggpcom-en.ggp-stage.com/pt-br/promotions/welcome-bonus/',
      [siteIds.GGPEU]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPHU]: 'https://ggpcom-en.ggp-stage.com/hu/promotions/welcome-bonus/',
      [siteIds.GGPRO]: 'https://ggpcom-en.ggp-stage.com/ro/promotions/welcome-bonus/',
      [siteIds.GGPPH]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPNL]: 'https://ggpcom-en.ggp-stage.com/nl/promotions/welcome-bonus/',
      [siteIds.GGPDE]: 'https://ggpcom-en.ggp-stage.com/de/promotions/welcome-bonus/',
      [siteIds.GGPFI]: 'https://ggpcom-en.ggp-stage.com/fi/promotions/welcome-bonus/',
      [siteIds.WSOPON]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.DAVAOPOKER]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPUK]: 'https://uk-contents.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPOK]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
    },
    freeBonus: {
      [siteIds.GGPCOM]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPNG]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPJP]: 'https://ggpcom-en.ggp-stage.com/ja/promotions/welcome-bonus/',
      [siteIds.GGPPL]: 'https://pl-contents.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPCW]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPBR]: 'https://ggpcom-en.ggp-stage.com/pt-br/promotions/welcome-bonus/',
      [siteIds.GGPEU]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPHU]: 'https://ggpcom-en.ggp-stage.com/hu/promotions/welcome-bonus/',
      [siteIds.GGPRO]: 'https://ggpcom-en.ggp-stage.com/ro/promotions/welcome-bonus/',
      [siteIds.GGPPH]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPNL]: 'https://ggpcom-en.ggp-stage.com/nl/promotions/welcome-bonus/',
      [siteIds.GGPDE]: 'https://ggpcom-en.ggp-stage.com/de/promotions/welcome-bonus/',
      [siteIds.GGPFI]: 'https://ggpcom-en.ggp-stage.com/fi/promotions/welcome-bonus/',
      [siteIds.DAVAOPOKER]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPUK]: 'https://uk-contents.ggp-stage.com/promotions/welcome-bonus/',
      [siteIds.GGPOK]: 'https://ggpcom-en.ggp-stage.com/promotions/welcome-bonus/',
    },
  },
  production: {
    dailyFreebie: {
      [siteIds.GGPCOM]: 'https://ggpoker.com/promotions/daily-freebie/',
      [siteIds.GGPNG]: 'https://ggpoker.com/promotions/daily-freebie/',
      [siteIds.GGPJP]: 'https://ggpoker.com/ja/promotions/daily-freebie/',
      [siteIds.GGPPL]: 'https://pl4-contents.ggpoker.com/promotions/daily-freebie/',
      [siteIds.GGPCW]: 'https://ggpoker.com/promotions/daily-freebie/',
      [siteIds.GGPBR]: 'https://ggpoker.com/pt-br/promotions/daily-freebie/',
      [siteIds.GGPEU]: 'https://ggpoker.com/promotions/daily-freebie/',
      [siteIds.GGPFI]: 'https://ggpoker.com/fi/promotions/daily-freebie/',
      [siteIds.GGPHU]: 'https://ggpoker.com/hu/promotions/daily-freebie/',
      [siteIds.GGPDE]: 'https://ggpoker.com/de/promotions/daily-freebie/',
      [siteIds.GGPRO]: 'https://ggpoker.com/ro/promotions/daily-freebie/',
      [siteIds.GGPNL]: 'https://ggpoker.com/nl/promotions/daily-freebie/',
      [siteIds.GGPPH]: 'https://ggpoker.com/promotions/daily-freebie/',
      [siteIds.GGPUK]: 'https://contents.ggpoker.co.uk/promotions/daily-freebie/',
    },
    matchBonus: {
      [siteIds.GGPCOM]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPNG]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPJP]: 'https://ggpoker.com/ja/promotions/welcome-bonus/',
      [siteIds.GGPPL]: 'https://pl4-contents.ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPCW]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPBR]: 'https://ggpoker.com/pt-br/promotions/welcome-bonus/',
      [siteIds.GGPEU]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPHU]: 'https://ggpoker.com/hu/promotions/welcome-bonus/',
      [siteIds.GGPRO]: 'https://ggpoker.com/ro/promotions/welcome-bonus/',
      [siteIds.GGPPH]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPNL]: 'https://ggpoker.com/nl/promotions/welcome-bonus/',
      [siteIds.GGPDE]: 'https://ggpoker.com/de/promotions/welcome-bonus/',
      [siteIds.GGPFI]: 'https://ggpoker.com/fi/promotions/welcome-bonus/',
      [siteIds.WSOPON]: 'https://ggpoker.ca/promotions/welcome-bonus/',
      [siteIds.DAVAOPOKER]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPUK]: 'https://contents.ggpoker.co.uk/promotions/welcome-bonus/',
      [siteIds.GGPOK]: 'https://pokerok.com/promotions/welcome-bonus',
    },
    freeBonus: {
      [siteIds.GGPCOM]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPNG]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPJP]: 'https://ggpoker.com/ja/promotions/welcome-bonus/',
      [siteIds.GGPPL]: 'https://pl4-contents.ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPCW]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPBR]: 'https://ggpoker.com/pt-br/promotions/welcome-bonus/',
      [siteIds.GGPEU]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPHU]: 'https://ggpoker.com/hu/promotions/welcome-bonus/',
      [siteIds.GGPRO]: 'https://ggpoker.com/ro/promotions/welcome-bonus/',
      [siteIds.GGPPH]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPNL]: 'https://ggpoker.com/nl/promotions/welcome-bonus/',
      [siteIds.GGPDE]: 'https://ggpoker.com/de/promotions/welcome-bonus/',
      [siteIds.GGPFI]: 'https://ggpoker.com/fi/promotions/welcome-bonus/',
      [siteIds.DAVAOPOKER]: 'https://ggpoker.com/promotions/welcome-bonus/',
      [siteIds.GGPUK]: 'https://contents.ggpoker.co.uk/promotions/welcome-bonus/',
      [siteIds.GGPOK]: 'https://pokerok.com/promotions/welcome-bonus',
    },
  },
};




































