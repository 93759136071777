<template>
  <TextInputBox v-model="model" :max-length="maxLength" :disabled="disabled" :placeholder="placeholder" @delete="deletePhoneNumber" />
</template>

<script>
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import Specific from '@shared/types/Specific';
export default {
  name: 'BusinessPhoneNumberInput',
  components: { TextInputBox },
  props: {
    name: { type: String, default: null, },
    placeholder: { type: String, default: null, },
    value: { type: [String, Number], default: null },
    rules: { type: String, default: '' },
    maxLength: { type: Number, default: 999 },
    disabled: { type: Boolean, default: false },

  },
  watch: {
    'model': 'updateModel',
    'value': 'updateValue'
  },
  data() {
    return {
      model: '',
    };
  },
  methods: {
    updateModel(v) {
      if(!v) return;
      this.$emit('input', this.updatePhoneNumber(v));
    },
    updateValue() {
      this.model = this.value;
    },
    formatPhoneNumber(v, isDelete) {
      const numRegexp = /[0-9]+/g;
      if (!numRegexp.test(v)) return '';
      const regexp = /([#]+)/g;
      let format = '(###) ###-####';
      const matches = format.match(regexp);
      const endChar = v.substr(-1);
      let val = v.match(numRegexp).join('');

      if (isDelete && format.replace(/#/g, '').includes(endChar)) {
        val = val.slice(0, -1);
      }

      if (val.length === 0) return '';

      let start = 0;
      matches.forEach(row => {
        format = format.replace(row, val.substr(start, row.length));
        start += row.length;
      });

      v = format;
      return v;
    },
    deletePhoneNumber() {
      return this.formatPhoneNumber(this.model, true);
    },
    updatePhoneNumber(v) {
      return this.formatPhoneNumber(v);
    },
  },
  mounted() {
    this.model = this.value;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';

</style>