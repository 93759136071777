import { render, staticRenderFns } from "./SignicatInformation.vue?vue&type=template&id=fed24fde&scoped=true"
import script from "./SignicatInformation.vue?vue&type=script&lang=js"
export * from "./SignicatInformation.vue?vue&type=script&lang=js"
import style0 from "./SignicatInformation.vue?vue&type=style&index=0&id=fed24fde&prod&lang=less"
import style1 from "./SignicatInformation.vue?vue&type=style&index=1&id=fed24fde&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fed24fde",
  null
  
)

export default component.exports