/**
 * @callback animateStepCallback
 * @param {number} value
 * @param {boolean} end
 */

import { easeOutQuint } from '@shared/modules/Easing';

class Transition {
  #live = false;
  #startTime;
  #start;
  #delta;
  #duration;
  #step;
  #ease;

  #loop = () => {
    let now = +new Date() - this.#startTime;
    if (now >= this.#duration) {
      now = this.#duration;
      this.#live = false;
    }
    try {
      this.#step(easeOutQuint(now, this.#start, this.#delta, this.#duration), this.#live);
    } catch (e) {
      this.#live = false;
    }
    if (this.#live) requestAnimationFrame(this.#loop);
  };

  constructor(start, end, duration, step, ease) {
    this.#startTime = +new Date();
    this.#live = true;
    this.#start = start;
    this.#delta = end - start;
    this.#duration = duration;
    this.#step = step;
    this.#ease = ease;
    requestAnimationFrame(this.#loop);
  }

  stop() {
    this.#live = false;
  }

  get isLive() {
    return this.#live;
  }
}


/**
 * @param {number} start
 * @param {number} end
 * @param {number} duration
 * @param {animateStepCallback} step
 * @param {function(elapsed: number, initialValue: number, amountOfChange: number, duration: number): number} ease
 * @returns {Transition}
 */
export const startTransition = (start, end, duration, step, ease = easeOutQuint) => {
  return new Transition(start, end, duration, step, ease);
};

