<template>
  <div sumsub-container>
    <slot />
  </div>
</template>

<script>

export default {
  name: 'SumsubContainer',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sumsub-container] {}
</style>
