<template>
  <GpFormTemplate rg-limit-history>
    <template #content>
      <div class="display-group">
        <InputSearchBar :model="searchModel" @click="()=>update(0)" />
        <DisplayLimitHistoryTable v-if="isLoad" :list="list" />
        <Pagination :chunk-size="pageChunk" :info="history" :showRow="false" @change="update" />
        <p v-if="showEmailContact" class="contact-email" v-html="$t('_.contactEmail', { csEmail: csEmail })" />
      </div>
    </template>
  </GpFormTemplate>
</template>
<script>
import { state } from '@shared/utils/storeUtils.mjs';
import Pagination from '@shared/components/common/Pagination.vue';
import pagination from '@shared/mixins/pagination';
import InputSearchBar from '@/views/components/pages/responsible-gaming/template/inputs/InputSearchBar.vue';
import DisplayLimitHistoryTable from '@/views/components/pages/responsible-gaming/template/views/DisplayLimitHistoryTable.vue';
import { getYYYYMMDDTHHmmssZ } from '@shared/utils/timeUtils.mjs';
import moment from 'moment';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import Specific from '@shared/types/Specific';
import { brandIds, siteIds } from '@/constants/base/siteMap.mjs';

export default {
  name: 'RgLimitHistory',
  lexicon: 'responsibleGaming',
  components: { DisplayLimitHistoryTable, InputSearchBar, Pagination, GpFormTemplate },
  mixins: [pagination],
  props: {
    structure: { type: Specific, default: null },
  },
  data() {
    return {
      tableSize: 20,
      isLoad: false,
      cursor: 0,
      searchConfig: null,
      searchModel: {
        DateBegin: null,
        DateEnd: null,
        LimitType: null,
        // iColumns: 4,
        // iDisplayLength: 6,
        // iDisplayStart: 0,
        // mDataProp_0: 'Date',
        // mDataProp_1: 'Type',
        // mDataProp_2: 'Duration',
        // mDataProp_3: 'Value',
        // sColumns: ',,,',
        // sEcho: 1
      },
    };
  },
  computed: {
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    csEmail: state('env', 'csEmail'),
    history() {
      return this.info;
    },
    list() {
      return this.info.items;
    },
    showEmailContact() {
      return [brandIds.WSOP, brandIds.GGPCOM, brandIds.GGPOKERUK].includes(this.brand);
    }
  },
  methods: {
    async initialize() {
      this.isLoad = false;
      this.searchConfig = this.structure.current;
      const beginDate = getYYYYMMDDTHHmmssZ(moment().subtract(7, 'day').format('YYYY-MM-DD'));
      const endDate = getYYYYMMDDTHHmmssZ(moment().format('YYYY-MM-DD'));

      this.searchModel = {
        ...this.searchModel,
        DateBegin: beginDate,
        DateEnd: endDate,
      };

      this.pageChunk = 6;
      await this.pageReset({
        service: this.$services.account, api: 'getPlayerRGTransactionHistoryRequest', cursor: 0, extra: {
          ...this.searchModel,
          LimitType: null,
        }
      });
      this.setPageChunk(this.pageChunk);
      this.isLoad = true;
    },
    async update(v) {
      await this.pageReset({ service: this.$services.account, api: 'getPlayerRGTransactionHistoryRequest', cursor: v, extra: this.searchModel });
    },
  },
  async mounted() {
    this.structure.showSubHeader = false;
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-limit-history] {
  .display-group {.flex(); .flex-dc(); .gap(20); .pt(20);}
  .desc { .pb(20);
    > p {.c(#999); .fs(12); line-height: 16px;}
  }
  [pagination] {
    > span {.c(#333); opacity: 1;}
    > a {
      &.active {.c(#fff); .bgc(#333); .wh(30); .br(50%); opacity: 0.8; .pt(2.5);
        &::after { content: none; }
      }
    }
  }
  .contact-email { .fs(14); .c(black); .medium();
    > a {.c(@c-link);}
  }
}

</style>