<template>
  <div home v-if="ready">
    <div class="holder" v-if="!token">
      <TextInput theme="dark" v-model="model.Email" @enter="login" />
      <PasswordInput theme="dark" v-model="model.Password" @enter="login" />
      <ColorButton type="red" @click="login">Login</ColorButton>
    </div>
    <div v-else class="holder">
      <ColorButton @click="goOnboarding">On Boarding</ColorButton>
      <ColorButton @click="goMyInfo">MyInfo</ColorButton>
      <ColorButton @click="goRg">ResponsibleGaming</ColorButton>
      <ColorButton @click="goPromotion">Promotion</ColorButton>
      <ColorButton type="red" @click="logout">Logout</ColorButton>
      <div class="verification-failed">
        <p>
          <br>
          <b>Move Verification Failed</b>
        </p>
        <p>
          <br>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError')">SessionExpired ACCESS_DENIED</ColorButton>

          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_LOCKED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_LOCKED)})">AccountLocked ACCOUNT_LOCKED</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_LOCKED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_LOGIN_LIMIT_ACCESS)}, {p: encodeURIComponent([JSON.stringify({ Limit: 'Limit', Loginavailable: 'loginavailable' })])})">Login Restriction ACCOUNT_LOGIN_LIMIT_ACCESS</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_LOCKED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.AISG_SELF_EXCLUDED)}, {p: encodeURIComponent([JSON.stringify({ Url: 'test.com', ContactInfo: 'CRUKS' })])})">SelfExcluded AISG_SELF_EXCLUDED</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_LOCKED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.CRUKS_SELF_EXCLUDED)}, {p: encodeURIComponent([JSON.stringify({ Url: 'test.com', ContactInfo: '' })])})">SelfExcluded CRUKS_SELF_EXCLUDED</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_LOCKED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.GAMSTOP_SELF_EXCLUDED)}, {p: encodeURIComponent([JSON.stringify({ Url: 'test.com', ContactInfo: '' })])})">SelfExcluded GAMSTOP_SELF_EXCLUDED</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_LOCKED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.OASIS_BAN_EXIST)})">SelfExcluded OASIS_BAN_EXIST</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_LOCKED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ETC)})">Session Expired ETC</ColorButton>

          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_SUSPENDED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.CLOSED)})">AccountSuspended(AccountLocked) CLOSED</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_SUSPENDED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.COMPLIANCE_CHECK)})">AccountSuspended(AccountLocked) COMPLIANCE_CHECK</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_SUSPENDED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.COMPLIANCE_CHECK_PERIOD)})">AccountSuspended(AccountLocked) COMPLIANCE_CHECK_PERIOD</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_SUSPENDED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.SECURITY)})">AccountSuspended(AccountLocked) SECURITY</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_SUSPENDED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.EXCLUSION_CHECK)}, {p: encodeURIComponent([JSON.stringify({ Url: 'test.com', ContactInfo: 'CRUKS' })])})">SelfExcluded EXCLUSION_CHECK</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_SUSPENDED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.MULTIACC_CHECK)})">AccountSuspended(AccountLocked) MULTIACC_CHECK</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_SUSPENDED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.BLOCKEDLISTACC_CHECK)})">AccountSuspended(AccountLocked) BLOCKEDLISTACC_CHECK</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('AuthError', {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_SUSPENDED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ETC)})">AccountSuspended(AccountLocked) ETC</ColorButton>
          <!--<ColorButton type="gray-red" @click="replaceRouteName('AuthError', null, {i:getObjectIndexByProperty(apiErrorCode, apiErrorCode.EXCEED_LOGIN_ATTEMPTS)})">AccountLocked EXCEED_LOGIN_ATTEMPTS</ColorButton>-->
        </p>
        <p>
          <br>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.RESPONSIBLE_GAMING), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.AISG_REGISTRATION_INVALID_INFORMATION) }})">AISG_REGISTRATION_INVALID_INFORMATION</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.AISG_REGISTRATION_INTERNAL_ERROR), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.AISG_REGISTRATION_INTERNAL_ERROR) }})">AISG_REGISTRATION_INTERNAL_ERROR</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.AISG_REGISTRATION_INVALID_OPERATION), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.AISG_REGISTRATION_INVALID_OPERATION) }})">AISG_REGISTRATION_INVALID_OPERATION</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.AISG_REGISTRATION_NOT_VERIFIED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.AISG_REGISTRATION_NOT_VERIFIED) }})">AISG_REGISTRATION_NOT_VERIFIED</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.AISG_REGISTRATION_INVALID_HID), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.AISG_REGISTRATION_INVALID_HID) }})">AISG_REGISTRATION_INVALID_HID</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.EXCLUDEDE_BYSERVICE), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.EXCLUDEDE_BYSERVICE) }})">EXCLUDEDE_BYSERVICE</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.USER_INFO_NOT_VERIFIED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.REJECT_FROM_BGC) }})">REJECT_FROM_BGC</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromAISG.content', type: 'onboarding', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.RESPONSIBLE_GAMING), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.AISG_FAILED_SELF_EXCLUSION_CHECK) }})">AISG_FAILED_SELF_EXCLUSION_CHECK</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.alreadyExist.content', type: 'onboarding', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.USER_INFO_ALREADY_EXIST), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.USER_INFO_ALREADY_EXIST) }}, {p: encodeURIComponent([JSON.stringify({ markingEmail: 'test@test.com' })])})">USER_INFO_ALREADY_EXIST</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.unknownError.content', type: 'onboarding', btnName: 'close', btnAction: 'close', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.INTERNAL_ERROR), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.INTERNAL_ERROR) }})">INTERNAL_ERROR</ColorButton>
        </p>
        <p>
          <br>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromAISG.content', type: 'onboarding', btnName: 'close', btnAction: 'close' }}, {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_LOCKED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.CRUKS_SELF_EXCLUDED)})">CRUKS_SELF_EXCLUDED</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.notVerified.title', content: 'errorPages.notVerified.content', type: 'onboarding', btnName: 'close', btnAction: 'close', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.USER_INFO_NOT_VERIFIED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.USER_INFO_NOT_VERIFIED) }})">DE USER_INFO_NOT_VERIFIED</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.accountSuspended.verificationSuspended', type: 'onboarding', btnName: 'close', btnAction: 'close', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_LOCKED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.GAMSTOP_SELF_EXCLUDED) }})">GAMSTOP_SELF_EXCLUDED</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.accountSuspended.verificationSuspended', type: 'onboarding', btnName: 'close', btnAction: 'close' , ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_SUSPENDED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCOUNT_SUSPENDED) }})">ACCOUNT_SUSPENDED</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.notVerified.title', content: 'errorPages.kycUploadRequired.content', type: 'onboarding', btnName: 'upload', btnAction: 'upload', ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.USER_INFO_NOT_VERIFIED), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.KYC_UPLOAD_REQUIRED) }})">KYC_UPLOAD_REQUIRED</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('VerificationFailed', {desc: 'desc', errorTemplate: { title: 'errorPages.verificationFailed.title', content: 'errorPages.accountSuspended.verificationSuspended', type: 'onboarding', btnName: 'close', btnAction: 'close' }}, {ci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.RESPONSIBLE_GAMING), cci:getObjectIndexByProperty(apiErrorCode, apiErrorCode.FAILED_SELF_EXCLUSION_CHECK)})">FAILED_SELF_EXCLUSION_CHECK</ColorButton>
        </p>
        <p>
          <br>
          <b>Sumsub</b>
        </p>
        <p>
          <br>
          <ColorButton type="gray-red" @click="replaceRouteName('TemplateViewer', null, { status: sumsubStatus.None })">Start</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('TemplateViewer', null, { status: sumsubStatus.Pending })">Pending</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('TemplateViewer', null, { status: sumsubStatus.ReCheck })">ReCheck</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('TemplateViewer', null, { status: sumsubStatus.TryCountOver })">TryCountOver</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('TemplateViewer', null, { status: sumsubStatus.ServiceAbort })">ServiceAbort</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('TemplateViewer', null, { status: sumsubStatus.DocumentInvalid })">DocumentInvalid</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('TemplateViewer', null, { status: sumsubStatus.CertificationFailed })">CertificationFailed</ColorButton>
          <ColorButton type="gray-red" @click="replaceRouteName('TemplateViewer', null, { status: sumsubStatus.Failed })">Failed</ColorButton>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@shared/components/common/input/TextInput.vue';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { sumsubStatus } from '@/constants/base/onboarding/kyc';
import { getObjectIndexByProperty } from '@/utils/valueUtil';

export default {
  name: 'Home',
  components: { TextInput, PasswordInput, ColorButton },
  data() {
    return {
      ready: false,
      model: {
        Email: null,
        Password: null,
        LoginMethodType: 'Email',
        Language: 'en'
      }
    };
  },
  computed: {
    env: state('env', 'env'),
    site: state('env', 'site'),
    token: state('user', 'token'),
    sumsubStatus() {
      return sumsubStatus;
    },
    apiErrorCode() {
      return apiErrorCode;
    },
    userInfo: state('user', 'userInfo'),
    isLocal() {
      return this.env === 'local';
    }
  },
  methods: {
    getObjectIndexByProperty,
    async login() {
      this.model.Email = this.model.Email.trim();
      this.model.Password = this.model.Password.trim();
      this.model.SiteId = this.site;

      const r = await this.$services.sign.login(this.model);
      if (r) {
        const path = this.isLocal ? '/sign-in' : '/app';
        window.location = path;
      }
    },
    goSignup() {
      this.routeName('SignUp');
    },
    goChannelingSignup() {

    },
    goOnboarding() {
      const path = this.isLocal ? '/onboarding' : '';
      window.location = `${path}?token=${this.token}`;
    },
    goMyInfo() {
      const path = this.isLocal ? '/myinfo' : '/app';
      window.location = `${path}?token=${this.token}`;
    },
    goRg() {
      const path = this.isLocal ? '/responsible-gaming' : '/app';
      window.location = `${path}?token=${this.token}`;
    },
    goPromotion() {
      const path = this.isLocal ? '/promotion' : '/app';
      window.location = `${path}?token=${this.token}`;
    },
    async logout() {
      await this.$services.sign.logout({ signIn: true});
    }
  },
  mounted() {
  },
  async created() {
    this.ready = true;
  }
};
</script>

<style lang="less">
@import "@/less/proj.less";
[home] {
  .holder {
    > * { .block(); .wf(); .mt(12); }
  }

  .verification-failed {
    [color-button] { .m(5); }
  }
}
</style>
