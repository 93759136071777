<template>
  <div input-transfer-search-bar>
    <div class="select-group">
      <InputComponent name="_selectDate" v-model="dateType" :component="SearchDropSelect" :preset="datePreset" @input="changeDateType" />
      <InputComponent name="_limitType" v-model="status" :component="SearchDropSelect" :preset="statusPreset" @input="updateStatus" />
      <a :class="['refresh-button', {'disable' : searchButtonDisabled}]" @click="click">
        <FontIcon name="search" />
      </a>
    </div>
    <div v-if="isCustomDate" class="custom-date-group">
      <InputComponent name="_selectDate" v-model="startDate" @input="changeStartDate" :component="DatePicker" :preset="customDatePreset" />
      <InputComponent name="_selectDate" v-model="endDate" @input="changeEndDate" :component="DatePicker" :preset="customEndDatePreset" />
    </div>
  </div>
</template>

<script>
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import Specific from '@shared/types/Specific';
import { getYYYYMMDDTHHmmssZ } from '@shared/utils/timeUtils.mjs';
import moment from 'moment/moment';
import FontIcon from '@shared/components/common/FontIcon.vue';
import DatePicker from '@/views/components/gg-pass/DatePicker.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';

export default {
  name: 'InputTransferSearchBar',
  components: { InputComponent, FontIcon },
  props: {
    model: Specific,
  },
  data() {
    return {
      status: 'All',
      dateType: '7',

      startDate: null,
      endDate: null,

      TextInputBox,
      DatePicker,
      SearchDropSelect,
    };
  },
  computed: {
    statusList() {
      const list = [
        { label: this.$t('anyStatus'), value: 'All' },
        { label: this.$t('success'), value: 'SUCCESS' },
        { label: this.$t('processing'), value: 'IN_PROGRESS' },
        { label: this.$t('failed'), value: 'FAILED' },
      ];
      return list;
    },

    dateTypeList() {
      return [
        { label: this.$t('responsibleGaming.label.last7Days'), value: '7' },
        { label: this.$t('responsibleGaming.label.last30Days'), value: '30' },
        { label: this.$t('responsibleGaming.label.last90Days'), value: '90' },
        { label: this.$t('responsibleGaming.label.last180Days'), value: '180' },
        { label: this.$t('responsibleGaming.label.customRange'), value: 'custom' },
      ];
    },

    searchButtonDisabled() {
      if (!this.isCustomDate) return false;
      else {
        if (!this.startDate || !this.endDate) return true;
        if (this.startDate > this.endDate) return true;

        return false;
      }
    },

    statusPreset() {
      return { placeholder: this.$t('responsibleGaming.label.allType'), list: this.statusList, translate: false,auto: true, selectOnly: true, parentFill: true, };
    },
    datePreset() {
      return { placeholder: this.$t('responsibleGaming.label.last7Days'), list: this.dateTypeList, translate: false, auto: true, selectOnly: true, };
    },
    isCustomDate() {
      return this.dateType === 'custom';
    },
    customDatePreset() {
      const minDate = moment().subtract(180, 'days').format('YYYY-MM-DD');
      const maxDate = this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      return { type: 'date', placeholder: this.$t('startDate'), min: minDate, max: maxDate };
    },
    customEndDatePreset() {
      const minDate = this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : moment().subtract(30, 'days').format('YYYY-MM-DD');
      const maxDate = moment().format('YYYY-MM-DD');
      return { type: 'date', min: minDate, max: maxDate, placeholder: this.$t('endDate') };
      // return { type: 'date', placeholder: this.$t('endDate') };
    }
    // customDatePreset() {
    //   return { type: 'date' };
    // },
    // customEndDatePreset() {
    //   const minDate = this.startDate ? toYMD(this.startDate) : '';
    //   return { type: 'date', min: minDate };
    // }
  },
  methods: {
    changeDateType(v) {
      if (!v) return;
      const beginDate = getYYYYMMDDTHHmmssZ(moment().subtract(v?.value, 'day').format('YYYY-MM-DD'));
      const endDate = getYYYYMMDDTHHmmssZ(moment().format('YYYY-MM-DD 23:59:59'));
      this.dateType = v?.value;
      if (v?.value !== 'custom') {
        this.model.fromDateTime = beginDate;
        this.model.toDateTime = endDate;
        this.startDate = null;
        this.endDate = null;
      } else {
        this.model.fromDateTime = this.startDate ? getYYYYMMDDTHHmmssZ(this.startDate) : null;
        this.model.toDateTime =  this.endDate ? getYYYYMMDDTHHmmssZ(moment(this.endDate).format('YYYY-MM-DD 23:59:59')) : null;
      }
    },
    updateStatus(v) {
      this.status = v;
      if (v?.value  === 'All') {
        this.model.status = null;
      } else {
        this.model.status = v?.value
      }
    },
    changeStartDate(v) {
      this.startDate = v;
      this.model.fromDateTime = getYYYYMMDDTHHmmssZ(v);
    },
    changeEndDate(v) {
      this.endDate = v;
      this.model.toDateTime = getYYYYMMDDTHHmmssZ(moment(v).format('YYYY-MM-DD 23:59:59'))
    },
    click() {
      if (this.searchButtonDisabled) return;
      this.$emit('click');
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-transfer-search-bar] { .bgc(#f8f8f8); .mt(10); .flex(); .flex-dc(); .gap(10);
  .select-group {.flex(); .gap(10); .items-center();
    > [gp-input-component] {flex: 3;}
    .refresh-button {.wh(40); .fs(16); .c(@gp-green); .flex(); .justify-center(); .items-center(); .-a(@gp-green); .br(8); .bgc(#fff);
      [font-icon] {.fs(16); .c(@gp-green);}
      &.disable { .-a(#A3A3A3); cursor: not-allowed;
        [font-icon] {.fs(16); .c(#A3A3A3);}
      }
    }
  }
  .custom-date-group {.flex(); .flex-dr(); .gap(10);
    > div {flex: 1;}
  }
}
</style>
