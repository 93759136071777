<template>
  <div temporary-account-login-de v-if="modelInfo">
    <p v-html="$t('onBoarding.temporaryAccountLoginDe.description1', { duration, endDate })" />
    <p v-html="$t('onBoarding.temporaryAccountLoginDe.description2')" />
    <div class="button-holder">
      <ColorButton progress-button :type="verifyLayerColor" @click="clickVerifyLayer">
        {{ $t('playNow') }}<br />
        {{ `(${$t('verifyLater')})` }}
      </ColorButton>
      <ColorButton progress-button :type="verifyNowColor" @click="clickVerifyNow">
        {{ $t('verifyNow') }}
      </ColorButton>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { state } from '@shared/utils/storeUtils.mjs';
import { close } from '@/utils/clientUtil';
import { siteIds } from '@/constants/base/siteMap';
import baseTemplate from '@/mixins/baseTemplate';
import ColorButton from '@shared/components/common/ColorButton.vue';

export default {
  name: 'TemporaryAccountLoginDe',
  components: { ColorButton },
  mixins: [baseTemplate],
  computed: {
    site: state('env', 'site'),
    theme: state('env', 'theme'),
    selectedLang: state('query', 'selectedLang'),
    widgetUrl: state('env', 'widgetUrl'),
    userInfo: state('user', 'userInfo'),
    token: state('user', 'token'),
    balance: state('user', 'balance'),
    isMobile: state('browser', 'mobile'),
    duration() {
      return this.site === siteIds.GGPDE ? '72 hours' : '30 Days';
    },
    endDate() {
      const isUtcFormat = this.userInfo.TemporaryVerificationExpireAt?.indexOf('Z') > -1;
      return moment(isUtcFormat ? this.userInfo.TemporaryVerificationExpireAt : `${this.userInfo.TemporaryVerificationExpireAt}Z`).utc().format('YYYY-MM-DD HH:mm');
    },
    verifyLayerColor() {
      return 'gray';
    },
    verifyNowColor() {
      return this.theme === 'GOLD' ? 'gold' : 'light-red';
    },
  },
  methods: {
    async clickVerifyLayer() {
      if (!isNaN(this.balance?.Amount) && this.balance?.Amount <= 0) await this.$onboarding.redirectCashier();
      else close(this);
    },
    clickVerifyNow() {
      this.modelInfo.step = 1;
    },
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[temporary-account-login-de] {
  > p { .c(@c-text-desc); .mb(24);
    strong { .c(@gp-bgc-gray); }
  }
  .button-holder {
    [color-button] { .block(); .lh(20); }
    > *:nth-of-type(1) {
      @{hover-press} { .bgc(#484b51); }
    }
    > *:nth-of-type(2) { .mt(12); }
  }
}
</style>