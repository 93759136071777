const view = path => () => import(/* webpackChunkName: "responsibleGaming" */ `../views/pages/responsible-gaming/${path}.vue`);
import { routeNames } from '@/constants/model/responsible-gaming';

export default ({ local }) => {
  const path = local ? 'responsible-gaming/' : 'app/';
  const list = [
    /** redirect */
    {
      path: `ResponsibleGaming`,
      redirect: `${path}info`,
    },
    /** root */
    {
      path: `${path}`,
      name: 'ResponsibleGaming',
      component: view('ResponsibleGaming'),
      // meta: { layout: 'full' },
      redirect: `${path}info`,
      children: [
        {
          path: 'deposit-limit-re-check',
          name: 'RgDepositLimitReCheck',
          component: view('RgDepositLimitReCheck'),
        },
        {
          path: 'info',
          name: 'RgInfo',
          component: view('ResponsibleInfo'),
        },
        {
          path: 'rg-my-deposit-limit',
          name: routeNames.RgMyDepositLimit,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-total-bet-limit',
          name: routeNames.RgMyTotalBetAmountLimit,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-loss-limit',
          name: routeNames.RgMyLossLimit,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-single-bet-limit',
          name: routeNames.RgMySingleBetAmountLimit,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-balance-limit',
          name: routeNames.RgMyBalanceLimit,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-login-time-limit',
          name: routeNames.RgMyLoginTimeLimit,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-re-login-time-limit',
          name: routeNames.RgMyReLoginTimeLimit,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-monthly-frequency',
          name: routeNames.RgMyMonthlyLoginFrequency,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-game-limit',
          name: routeNames.RgMyGameLimit,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-reality-check',
          name: routeNames.RgMyRealityCheckNotification,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-self-exclusion',
          name: routeNames.RgMySelfExclusion,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-automatic-reserve',
          name: routeNames.RgMyAutomaticReserveWin,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-my-external-monthly-limit',
          name: routeNames.RgExternalMonthlyLimit,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-limit-history',
          name: routeNames.RgLimitHistory,
          component: view('ResponsibleGamingProgress'),
        },
        {
          path: 'rg-two-ace',
          name: routeNames.RgTwoAceLossLimit,
          component: view('ResponsibleGamingProgress'),
        },
        { path: '*', redirect: '/not-found' }
      ]
    },
  ];
  return list;
};