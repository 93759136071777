<template>
  <div last-name-at-birth v-if="modelInfo">
    <ValidationComponent :class="{checked}" :preset="{disable: checked || disabledLastNameAtBirth}" :label="$t('personalDetails.lastNameAtBirth')" name="_lastNameAtBirth" :component="TrimTextInput" :rules="`requiredPersonalName:0,0,${countryWritingSystem}`" v-model="value" @input="updateValue" @focus="focus = true" @blur="focus = false" />
    <div class="check-box">
      <CheckboxItem theme="dark" :left="false" v-model="checked" @change="onChecked" :disabled="disabledLastNameAtBirth">{{$t('personalDetails.sameAsLastName')}}</CheckboxItem>
    </div>
  </div>
</template>

<script>
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'LastNameAtBirth',
  components: { ValidationComponent, CheckboxItem },
  mixins: [baseTemplate],
  data() {
    return {
      focus: false,
      checked: false,
      value: null,
      TrimTextInput,
    };
  },
  computed: {
    maidenName: {
      get() {
        return this.modelInfo.MaidenName;
      },
      set(v) {
        this.modelInfo.MaidenName = v;
      }
    },
    lastName() {
      return this.modelInfo.LastName;
    },
    countryWritingSystem() {
      return this.modelInfo.CountryWritingSystem;
    },
    disabledLastNameAtBirth() {
      return this.modelInfo.blockFields?.includes('MaidenName') && !!this.modelInfo.MaidenName;
    }
  },
  watch: {
    focus() {
      if (this.focus) this.checked = false;
    },
    lastName() {
      if (this.checked) this.updateValue(this.lastName);
    }
  },
  methods: {
    async updateValue(v) {
      this.maidenName = v;
      this.value = v;
    },
    onChecked() {
      this.checked && this.updateValue(this.lastName);
    }
  },
  mounted() {
    this.checked = this.lastName === this.maidenName;
    this.value = this.maidenName;
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[last-name-at-birth] { .rel();
  .checked {
    .holder { .bgc(@c-w01); box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(0, 0, 0,.3) inset;}
    input { .c(#7c7c7c); }
  }
  .check-box { .abs(); .rt(4, 4); .z(9);
  }
}
</style>