<template>
  <div czech-country-selector v-if="stateList">
    <ValidationComponent v-if="stateList" :value="modelInfo[stateKey]" name="_stateOfBirth" :component="SearchDropSelect" rules="required" :label="stateLabel" :preset="{list: stateList, disable: disable}" @input="updateState" :error-msg="stateErrorMsg" />
    <ValidationComponent v-if="cities.length" :value="city" name="_cityOfBirth" :component="SearchDropSelect" rules="required" :label="cityLabel" :preset="{list: cities, disable: disable}" @input="updateCity" :error-msg="cityErrorMsg" />
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
export default {
  name: 'CzechCountrySelector',
  mixins: [baseTemplate],
  lexicon: 'personalDetails',
  components: { ValidationComponent },
  props: {
    permanent: Boolean,
  },
  data() {
    return {
      city: null,
      stateList: null,
      SearchDropSelect,
      /** @type {{ o: { Cities: string[]} } || null}*/
      stateModel: null,
    };
  },
  computed: {
    stateLabel() {
      return this.permanent ? this.$t('_.stateOfBirth') : this.$t('_.stateOfBirth');
    },
    cityLabel() {
      return this.permanent ? this.$t('_.cityOfBirth') : this.$t('_.cityOfBirth');
    },
    stateKey() {
      return this.permanent ? 'State' : 'StateOfBirth';
    },
    cityKey() {
      return this.permanent ? 'City' : 'CityOfBirth';
    },
    cities() {
      return (this.stateModel?.o?.Cities || []).map(o => ({ label: o, value: o }));
    },
    stateErrorMsg() {
      return [apiErrorCode.INVALID_STATE].includes(this.errorMsg) ? this.$t(this.errorMsg, {fieldName: this.stateLabel}) : '';
    },
    cityErrorMsg() {
      return [apiErrorCode.INVALID_CITY, apiErrorCode.INVALID_CITY_LENGTH, apiErrorCode.INVALID_CITY_CHARACTER].includes(this.errorMsg) ? this.$t(this.errorMsg, {fieldName: this.cityLabel}) : '';
    }
  },
  methods: {
    updateState(v) {
      this.modelInfo[this.cityKey] = null;
      this.stateModel = v;
    },
    updateCity(v) {
      this.modelInfo[this.stateKey] = this.stateModel?.value;
      this.modelInfo[this.cityKey] = v?.value;
    },
  },
  async mounted() {
    this.stateList = (await this.$services.onboarding.getCzechState()).List.map(o => ({ label: o.Name, value: o.Name, o }));
    if (this.modelInfo?.[this.stateKey]) {
      this.stateModel = this.stateList.find(o => o.Name === this.modelInfo[this.stateKey]);
      this.city = this.modelInfo[this.cityKey];
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[czech-country-selector] {
  > div {
    &:nth-of-type(1) { .mb(8); }
  }
}
</style>
