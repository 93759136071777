<template>
  <div input-reality-check-notification>
    <div class="label">{{ $t('_.realityNotification') }}</div>
    <RadioList type="vertical" :custom-radio="ResponsibleRadioItem" :list="timeList" v-model="model.RequestInterval" />
  </div>
</template>

<script>

import Specific from '@shared/types/Specific';
import RadioList from '@/views/components/gg-pass/RadioList.vue';
import ResponsibleRadioItem from '@/views/components/pages/responsible-gaming/template/common/ResponsibleRadioItem.vue';
import { realityCheckIntervalType } from '@/constants/base/responsible-gaming';

export default {
  name: 'InputRealityCheckNotification',
  lexicon: 'responsibleGaming.label',
  components: { RadioList },
  props: {
    model: Specific,
    current: Specific,
  },
  data() {
    return {
      ResponsibleRadioItem
    };
  },
  computed: {
    timeList() {
      return this.current.AvailableIntervalTypes.map(type => {
        const { value, label } = realityCheckIntervalType[type];
        return {
          value: value,
          label: this.$t(label)
        };
      });
    }
  },
  methods: {}
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-reality-check-notification] {.flex(); .flex-dc(); .gap(5);
  .label {.c(black); font-weight: 600; .fs(16);}
  > [gp-radio-group] {.bgc(#EBEBEF); .br(16); .p(20); }
}
</style>