<template>
  <div occupation-uk>
    <ValidationComponent name="_employmentStatus" :label="$t('_.employmentStatus')" :value="modelInfo.EmploymentType" :preset="{list, auto: true, placeholder : $t('nothingSelected'), selectOnly: true}" rules="requiredNone" :component="SearchDropSelect" @input="updateOccupation" />
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'OccupationUk',
  lexicon: 'onBoarding',
  mixins: [baseTemplate],
  components: { ValidationComponent },
  data() {
    return {
      SearchDropSelect,
      list: [
        { label: this.$t('occupation.Employed'), value: 'Employed' },
        { label: this.$t('occupation.SelfEmployed'), value: 'SelfEmployed' },
        { label: this.$t('occupation.Retired'), value: 'Retired' },
        { label: this.$t('occupation.Student'), value: 'Student' },
        { label: this.$t('occupation.Unemployed'), value: 'Unemployed' },
      ],
    };
  },
  computed: {
    userInfo: state('user', 'userInfo'),
  },
  methods: {
    updateOccupation(e) {
      this.modelInfo.EmploymentType = e?.value;
    },
  },
  mounted() {
    const v = this.userInfo?.PersonalInfo?.EmploymentType || '';
    this.modelInfo.EmploymentType = v !== 'None' ? v : null;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[occupation-uk] {}
</style>
