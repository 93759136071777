<template>
  <div bonus-info>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BonusInfo',
  lexicon: 'promotion',
  props : {
    title: { type: String },
    tooltip: { type: String },
  }
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[bonus-info] { .p(20); .bgc(@c-bgBlack);
  //h4 { .mb(20); .c(@c-gray2); .regular(); }
  h5 { .mb(16); .fs(20); .semi-bold(); }
}
</style>
