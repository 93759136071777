<template>
  <GpFormTemplate rg-limit-history>
    <template v-if="title && description" #header>
      <GpTitle v-if="title" :name="$t(`_.title.${title}`)" />
      <GpDescription v-if="description" :message="pageDescription" />
      <GpDescription v-if="isDefaultStep" :message="$t('_.description.twoAceInitMessage')" />
    </template>
    <template #content>
      <component :is="getComponent" v-if="isLoad" :list="historyList" :model="model" :data="structure.current" type="twoAce" @click="click" />
    </template>
    <template v-if="isInputStep" #footer="{validate}">
      <div class="footer">
        <GpButton type="cancel-gray" @click="back">{{ $t('cancel') }}</GpButton>
        <GpButton type="submit-green" @click="submit" :disabled="disableButton">{{ $t('confirm') }}</GpButton>
      </div>
    </template>
  </GpFormTemplate>
</template>
<script>
import { state } from '@shared/utils/storeUtils.mjs';
import Pagination from '@shared/components/common/Pagination.vue';
import InputSearchBar from '@/views/components/pages/responsible-gaming/template/inputs/InputSearchBar.vue';
import DisplayLimitHistoryTable from '@/views/components/pages/responsible-gaming/template/views/DisplayLimitHistoryTable.vue';
import InputTwoAceLossLimit from '@/views/components/pages/responsible-gaming/template/inputs/InputTwoAceLossLimit.vue';
import ResponsibleConfirmModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleConfirmModal.vue';
import { STEP } from '@/constants/base/responsible-gaming';
import { bi_divide, bi_mod } from '@/utils/bistroUtils';
import rgModal from '@/mixins/responsible-gaming/rgModal';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
import npStepInfo from '@/mixins/my-info/npStepInfo';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';

export default {
  name: 'RgTwoAceLossLimit',
  lexicon: 'responsibleGaming',
  components: { InputSearchBar, Pagination, GpFormTemplate, GpDescription, GpButton, GpTitle },
  mixins: [npStepInfo],
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      isLoad: false,
      hangTimeHours: 6,
      exchangeRateKRWToGCO: 8000,
      list: [],
      model: {
        RequestDailyLossLimit: null,
      },
      CurrentDailyLossLimit: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    disableButton() {
      return this.CurrentDailyLossLimit + '' === this.model.RequestDailyLossLimit + '';
    },
    historyList() {
      return this.list.map(item => {
        const display1 = this.changeAmountKrw(item.DailyLossLimit * this.exchangeRateKRWToGCO);
        const display2 = this.changeAmountKrw(item.DailyLossLimit);

        return {
          Date: item.AppliedTime,
          Type: `${display1} ${this.$t('coin')} (${display2}${this.$t('ko.krw')})`,
          Status: item.TransactionStatus
        };
      });
    },

    getComponent() {
      return this.isDefaultStep ? DisplayLimitHistoryTable : InputTwoAceLossLimit;
    },
    pageDescription() {
      return this.isDefaultStep ? this.$t('responsibleGaming.description.twoAceLimit', { smallLimit: '8억', largeLimit: '56억' }) : this.$t('_.description.twoAceInput');
    }
  },
  methods: {
    async initialize() {
      this.isLoad = false;
      const result = await this.$services.account.getRGLossLimitHistoryRequest({
        AgentName: this.userInfo.AgentName,
        UserId: this.userInfo.UserId,
      });
      const { limit } = this.structure.current;

      this.list = result.RGLossLimitHistoryList;
      this.exchangeRateKRWToGCO = result.ExchangeRateKRWToGCO;
      this.CurrentDailyLossLimit = limit.CurrentDailyLossLimit;

      this.model.RequestDailyLossLimit = this.CurrentDailyLossLimit.toString();
      if (this.structure.config.hasOwnProperty('DailyUpperHangTime')) {
        this.hangTimeHours = this.structure.config.DailyUpperHangTime > 0 ? this.structure.config.DailyUpperHangTime : this.hangTimeHours;
      }
      this.isLoad = true;
    },
    click() {
      this.changeStep(STEP.INPUT);
    },
    async submit() {
      const current = this.CurrentDailyLossLimit;
      const request = parseInt(this.model.RequestDailyLossLimit);
      let desc = this.$t('responsibleGaming.modal.description.twoaceImmediately');
      if (current < request) desc = this.$t('responsibleGaming.modal.description.twoaceHangTime', { hour: this.hangTimeHours });

      this.$modal(ResponsibleConfirmModal, { title: { default: this.$t('responsibleGaming.modal.twoaceTitle') }, request: () => this.$rg.request(this.model), desc: desc, modalType: 'Message' });
    },
    back() {
      this.changeStep(STEP.DEFAULT);
    },
    changeAmountKrw(amount) {
      if (typeof amount !== 'number')
        return 0;

      let coin = amount;
      const units = [
        { label: this.$t('ko.billion'), value: 1e8 },
        { label: this.$t('ko.tenThousand'), value: 1e4 },

      ];
      const numList = [];

      units.forEach(item => {
        let quot = bi_divide(coin, item.value);
        let remainder = bi_mod(coin, item.value);

        if (quot > 0) {
          numList.push(quot + item.label);
          coin = remainder;
        }
      });

      if (parseInt(coin) > 0)
        numList.push(coin);

      if (numList.length > 2) {
        return numList.slice(0, 2).join(' ');
      } else {
        return numList.length > 0 ? numList.join(' ') : coin;
      }
    },
    changeStep(step) {
      this.$emit('changeStep', step);
      // this.step = step;
    }
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-limit-history] {
  .desc { .pb(20);
    > p {.c(#999); .fs(12); line-height: 16px;}
  }
  .footer {.flex(); .gap(10);}
}

</style>