import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';

import { i18n } from '@/plugins/i18n';
import signup from '@/router/sign-up';
import onboarding from '@/router/on-boarding';
import myinfo from '@/router/my-info';
import rg from '@/router/responsible-gaming';
import fp from '@/router/forgot-password';
import fi from '@/router/find-id';
import promotion from "@/router/promotion";
import LanguageRoot from '@/views/LanguageRoot.vue';
import NotFound from '@/views/pages/NotFound.vue';
import Home from '@/views/pages/Home.vue';
import NotSupportRegion from '@/views/pages/NotSupportRegion.vue';
import NotSupportCountry from '@/views/pages/NotSupportCountry.vue';
import Download from '@/views/pages/Download.vue';
import AuthError from '@/views/pages/on-boarding/AuthError.vue';
import NotProvide from '@/views/pages/NotProvide.vue';
import RestrictionNotice from '@/views/pages/RestrictionNotice.vue';

const notFound = [{ path: '/not-found', name: 'NotFound', component: NotFound }];
const notSupportRegion = [{ path: '/not-support-region', name: 'NotSupportRegion', component: NotSupportRegion }];
const notSupportCountry = [{ path: '/not-support-country', name: 'NotSupportCountry', component: NotSupportCountry }];
const authError = [{ path: '/auth-error/:ci?/:cci?', name: 'AuthError', props: true, component: AuthError }];

Vue.use(VueRouter);
Vue.use(Meta);

const local = process.env.VUE_APP_ENV === 'local';

const getRoutes = async store => {
  const appPaths = { signup, onboarding, myinfo, fp, rg, fi, promotion };
  const app = store.state.query.app;
  const token = store.state.user.token;
  const { ggpass, npOnly } = store.state.env;
  const path = () => {
    const now = appPaths[app];
    if (now) return now({ local, ggpass: store.state.env.ggpass, npOnly: store.state.env.npOnly, findKey: store.state.query.findKey });
    return [
      ...appPaths.myinfo({ local }),
      ...appPaths.fp({ local }),
      ...appPaths.onboarding({ local }),
      ...appPaths.rg({ local }),
      ...appPaths.promotion({ local }),
      ...appPaths.fi({ local, findKey: store.state.query.findKey }),
      ...appPaths.signup({ local, ggpass, npOnly }),
    ];
  };

  const routes = async () => {
    const list = [
      {
        path: '/',
        redirect: app ? '/app' : '/signup'
      },
      {
        path: 'download',
        redirect: 'client/download',
      },
      {
        path: 'client/download',
        name: 'Download',
        component: Download,
        meta: { layout: 'full' },
      },
      {
        path: 'not-provide',
        name: 'NotProvide',
        component: NotProvide,
      },
      {
        path: 'restriction-notice',
        name: 'RestrictionNotice',
        component: RestrictionNotice,
      },
      ...notSupportRegion,
      ...notSupportCountry,
      ...authError,
      ...notFound,
      ...path(),
      { path: '*', redirect: '/not-found' },
    ];
    if (['local', 'development', 'test', 'dr'].includes(process.env.VUE_APP_ENV)) list.unshift({ path: 'sign-in', name: 'Home', component: Home, });
    return list;
  };

  return routes();
};

export default async store => new VueRouter({
  mode: 'history',
  scrollBehavior: () => null,
  routes: [
    {
      path: `/:locale(${i18n.routeMatches()})?`,
      component: LanguageRoot,
      children: await getRoutes(store),
      linkActiveClass: 'active',
      linkExactActiveClass: 'active',
    },
  ],
});
