<template>
  <div verify-rg-loss-setting name="WSOPca_Step24_Loss_Limit_View" class="responsible-loss-limit-view">
    <LimitUnitField :label="isRolling ? $t('_.30Days') : $t('_.monthlyLimit')" :max-length="9" v-model="modelInfo.RequestMonthlyLossLimit" />
    <LimitUnitField :label="isRolling ? $t('_.7Days') : $t('_.weeklyLimit')" :max-length="9" :rules="weeklyRule" v-model="modelInfo.RequestWeeklyLossLimit" />
    <LimitUnitField :label="isRolling ? $t('_.24hours') : $t('_.dailyLimit')" :max-length="9" :rules="dailyRule" v-model="modelInfo.RequestDailyLossLimit" />
    <p class="list-star">{{ $t('_.lossLimit.description') }}</p>
  </div>
</template>

<script>
import LimitUnitField from '@/views/components/pages/on-boarding/template/extra/LimitUnitField.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'VerifyRgLossSetting',
  lexicon: 'onBoarding.setting',
  components: { LimitUnitField },
  mixins: [baseTemplate],
  data() {
    return {
      lossLimitType: 'Rolling',
    };
  },
  computed: {
    currencySymbol: state('user', 'currencySymbol'),
    weeklyRule() {
      if (!this.modelInfo.RequestDailyLossLimit && !this.modelInfo.RequestWeeklyLossLimit && !this.modelInfo.RequestMonthlyLossLimit) return '';
      return `monthlyLimit:${this.modelInfo.RequestMonthlyLossLimit},${this.$t('weekly')},${this.$t('monthly')}`;
    },
    dailyRule() {
      if (!this.modelInfo.RequestDailyLossLimit && !this.modelInfo.RequestWeeklyLossLimit && !this.modelInfo.RequestMonthlyLossLimit) return '';
      return `weeklyLimit:${this.modelInfo.RequestWeeklyLossLimit},${this.$t('daily')},${this.$t('weekly')}|monthlyLimit:${this.modelInfo.RequestMonthlyLossLimit},${this.$t('daily')},${this.$t('monthly')}`;
    },
    isRolling() {
      return this.lossLimitType === 'Rolling';
    },
  },
  methods: {
    async initialize() {
      this.lossLimitType = (await this.$services.account.getRgConfig()).LossLimitPeriodType;
      const { CurrentDailyLossLimit, CurrentWeeklyLossLimit, CurrentMonthlyLossLimit } = await this.$services.account.getLossLimit();
      this.modelInfo.RequestDailyLossLimit = CurrentDailyLossLimit;
      this.modelInfo.RequestWeeklyLossLimit = CurrentWeeklyLossLimit;
      this.modelInfo.RequestMonthlyLossLimit = CurrentMonthlyLossLimit;
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-rg-loss-setting] {
  [limit-unit-field] {
    label { flex-basis: 30%; .min-w(98); white-space: normal; }
    label + div { flex-basis: 70%; }
  }
}
</style>
