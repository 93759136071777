<template>
  <div np-conact-info>
    <div class="link-button-wrapper">
      <LabelComponent :value="mobileLabel" :label="$t('mobileNumber')" :btn-text="$t('edit')" @click="contactMobile" />
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import LabelComponent from '@/views/components/gg-pass/LabelComponent.vue';

export default {
  name: 'DisplayContactInfo',
  lexicon: 'myInfo',
  components: { FontIcon, LabelComponent },
  props: {
    model: Specific,
    step: Specific
  },
  computed: {
    emailButtonText() {
      return this.model.ContactEmail ? this.$t('edit') : this.$t('register');
    },
    mobileButtonText() {
      return this.model.ContactMobileNumber ? this.$t('edit') : this.$t('register');
    },
    mobileLabel() {
      return this.model.ContactMobileNumber && this.model.ContactMobileCountryCode ? `+${this.model.ContactMobileCountryCode} ${this.model.ContactMobileNumber}` : null;
    },
    emailBtnText(){
      return this.model.ContactEmail ? null : this.$t('edit');
    },
  },
  methods: {
    contactEmail() {
      if(!this.emailBtnText){ return; }
      this.routeName('NpContactEmailAddress');
    },
    contactMobile() {
      this.routeName('NpContactMobileNumber');
    }
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[np-conact-info] {.c(#000); .flex(); .flex-dc(); .gap(10);
  .link-button-wrapper { .bgc(#fff); .br(16); .p(20, 20, 20, 20);
  }
}
</style>