<template>
  <div copy-button>
    <p>{{ copyText }}</p>
    <div v-if="copied" class="popover-copied">{{ $t('myInfo.copied') }}</div>
    <button @click="copyCode">
      <FontIcon name="copy-regular" />
    </button>
  </div>
</template>
<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import { copyClipboard } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'CopyButton',
  components: { FontIcon },
  props: {
    copyText: { type: String, default: '' },
  },
  data() {
    return {
      copied: false,
      timer: null,
    };
  },
  methods: {
    copyCode() {
      copyClipboard(this.copyText);
      if (this.copied) return;
      this.copied = true;
      this.timer = setTimeout(() => this.copied = false, 1500);
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<style lang="less">
@import "@/less/proj.less";
[copy-button] { .flex(); .justify-center(); .items-center(); .gap(5); .rel();
  > p {.c(black); .fs(12);}
  > button > [font-icon] {.c(#737373); .fs(16);}
  .popover-copied {.abs(); .t(-25); .r(-25); .bgc(white); .p(0, 10, 0, 10); .br(8); .c(#737373);box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1); .br(8);
    //&::after {content: ''; .bgc(white); .p(5); .abs(); .b(-10); .l(50%); border-bottom-left-radius: 100px; border-bottom-right-radius: 100px; }
  }
}
</style>