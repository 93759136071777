import _get from 'lodash/get';

export default class StoreProxy {
  /** @type {Store} */
  #store;

  constructor(store) {
    this.#store = store;
  }

  /**
   * @param {string} module
   * @param {string} key
   * @returns {any}
   */
  getState(module, key) {
    return _get(this.#store, 'state.' + module + '.' + key);
  }

  /**
   * @param {string} module
   * @param {string} keys
   * @returns {object}
   */
  getStateObject(module, ...keys) {
    const result = {};
    keys.forEach(key => result[key] = this.getState(module, key));
    return result;
  }

  /**
   * @param {string} module
   * @param {string} keys
   * @returns {boolean}
   */
  every(module, ...keys) {
    return keys.every(key => this.getState(module, key));
  }

  /**
   * @param {string} module
   * @param {string} key
   * @returns {*}
   */
  getGetter(module, key) {
    return _get(this.#store, 'getters.' + module + '/' + key);
  }

  get state() {
    return this.#store.state;
  }

  get getters() {
    return this.#store.getters;
  }

  /**
   * @param {string} type
   * @param {any?} payload
   * @param {any?} options
   */
  commit(type, payload, options) {
    this.#store.commit(type, payload, options);
  }

  /**
   * @param {string} type
   * @param {any?} payload
   * @param {any?} options
   */
  dispatch(type, payload, options) {
    return this.#store.dispatch(type, payload, options);
  }

  setStore(store) {
    this.#store = store;
  }
}
