<template>
  <div display-limit-history-table>
    <div v-if="hasData" :class="['history-row', {'two-ace' : isTwoAce}]" v-for="(item, index) in list">
      <div class="content">
        <p class="type">{{ convertTypeText(item.Type) }}</p>
        <p class="duration">{{ item.Duration }}</p>
        <p v-if="!isTwoAce" class="value">
          {{ convertValueText(item.Type, item.Value, item.Currency) }}
        </p>
        <p class="date">{{ convertDateTime(item.Date) }}</p>
      </div>
      <p v-if="isTwoAce" class="status">
        <button v-if="index === 0 && item.Status === 'Applied'" class="change-button" @click="click(item.Status)">
          <span>{{ $t('responsibleGaming.label.twoaceModify') }}</span>
          <FontIcon name="angle-right" />
        </button>
        <span v-else class="status-text">{{ covertTwoaceText(item.Status) }}</span>
      </p>
    </div>
    <div v-if="!hasData" class="no-data">
      <FontIcon name="exclamation-circle-regular-sharp" />
      <p>{{ $t('responsibleGaming.label.NoData') }}</p>
    </div>
  </div>
</template>

<script>
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { realityCheckIntervalType, realityCheckIntervalValues, selfExclusionValues } from '@/constants/base/responsible-gaming';
import { toFullDateTS, toYMDTS } from '@/utils/dateTimeUtil';

export default {
  name: 'DisplayLimitHistoryTable',
  components: { FontIcon },
  lexicon: 'responsibleGaming',
  props: {
    list: { type: Array, default: null },
    type: { type: String, default: 'history' }
  },
  computed: {
    site: state('env', 'site'),
    selfExclusionValue() {
      return selfExclusionValues;
    },
    hasData() {
      return this.list && this.list.length > 0;
    },
    isTwoAce() {
      return this.site === siteIds.TWOACE;
    },
    isWSOP() {
      return this.site === siteIds.WSOPON;
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    isGGPCZ() {
      return this.site === siteIds.GGPCZ;
    },
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    loginTimeLabelSites() {
      return [siteIds.GGPCZ, siteIds.GGPNL].includes(this.site);
    },
    yesrsTimeOut() {
      return this.$t('responsibleGaming.label.yearsTimeOut');
    },
    daysTimeOut() {
      return this.isWSOP ? this.$t('responsibleGaming.label.wsopDaysTimeOut') : this.$t('responsibleGaming.label.daysTimeOut');
    }
  },
  methods: {
    convertDateTime(date) {
      const locale = this.$i18n('locale');
      if (this.isTwoAce) return toYMDTS(date);
      return toFullDateTS(date, locale);
    },
    convertTypeText(type) {
      switch (type) {
        case 'DepositLimit':
          return this.isGGPDE ? this.$t('_.title.localDepositLimit') : this.$t('responsibleGaming.title.depositLimit');
        case 'TotalBetLimit':
          return this.$t('_.title.totalBetLimit');
        case 'SingleBetLimit':
          return this.$t('_.title.singleBetLimit');
        case 'LossLimit':
          return this.$t('_.title.lossLimit');
        case 'LoginTimeLimit':
          return this.loginTimeLabelSites ? this.$t('_.title.loginTimeLimit') : this.$t('_.title.playTimeLimit');
        case 'ReLoginTimeLimit':
          return this.$t('_.title.reLoginLimit');
        case 'MonthlyLoginFrequency':
          return this.$t('_.title.monthlyLogin');
        case 'GameLimit':
          return this.$t('_.title.gameLimit');
        case 'SelfExclusion':
          return this.$t('_.title.selfExclusion');
        case 'Daily':
          return this.$t('daily');
        case 'Weekly':
          return this.$t('weekly');
        case 'Monthly':
          return this.$t('monthly');
        case 'CoolingOff':
          return this.$t('_.label.coolingOff');
        case 'Timeout':
          return this.isWSOP ? this.$t('_.label.breakInPlay') : this.$t('_.label.timeOut');
        case 'RealityCheckNotification':
          return this.$t('_.title.realityCheckNotification');
        case 'BalanceLimit':
          return this.isGGPNL ? this.$t('_.title.maximumBalance') : this.$t('_.title.balanceLimit');
        case 'AutomaticReserveWin':
          return this.$t('_.title.automaticReserveWin');
        default:
          return type;
      }
    },
    convertValueText(type, value, currency) {
      switch (type) {
        case 'DepositLimit':
        case 'TotalBetLimit':
        case 'SingleBetLimit':
        case 'LossLimit':
        case 'BalanceLimit': {
          if (!value) return this.$t('noLimit');
          return `${getCurrencySymbol(currency)}${value}`;
        }
        case 'LoginTimeLimit': {
          if (!value) return this.$t('noLimit');
          return `${value} ${this.$t('responsibleGaming.label.hr')}`;
        }
        case 'ReLoginTimeLimit': {
          if (!value) return this.$t('noLimit');
          return `${value} ${this.$t('responsibleGaming.label.min')}`;
        }
        case 'MonthlyLoginFrequency': {
          if (!value) return this.$t('noLimit');
          return `${value} ${this.$t('responsibleGaming.label.times')}`;
        }
        case 'SelfExclusion': {
          return this.convertExclusion(value);
        }
        case 'GameLimit': {
          if (value)
            return this.$t('responsibleGaming.label.restricted');
          else
            return this.$t('responsibleGaming.label.noRestrictions');
        }
        case 'RealityCheckNotification': {
          const { label } = realityCheckIntervalType[realityCheckIntervalValues[value]];
          return this.$t(label);
        }
        case 'AutomaticReserveWin': {
          if (!value) return this.$t('noLimit');
          return `${getCurrencySymbol(currency)}${value}`;
        }
        default:
          return !value && value !== 0 ? '-' : value;
      }
    },
    covertTwoaceText(status) {
      if (status === 'Applied') return this.$t('responsibleGaming.label.twoaceChangeComplete');
      else return this.$t('responsibleGaming.label.twoacePending');
    },
    convertExclusion(value) {
      switch (value) {
        case this.selfExclusionValue.HrCoolingOff24: {
          if (this.isWSOP) {
            return this.$t('responsibleGaming.label.dayBreakPlay');
          } else {
            return this.$t('responsibleGaming.label.hrCoolingOff24');
          }
        }
        case this.selfExclusionValue.HrTimeOut24: {
          if (this.isWSOP) {
            return this.$t('responsibleGaming.label.dayBreakPlay');
          } else {
            return this.$t('responsibleGaming.label.hrTimeOut24');
          }
        }
        case  this.selfExclusionValue.DaysTimeOut7:
          return '7 ' + this.daysTimeOut;
        case this.selfExclusionValue.DaysTimeOut30:
          return '30 ' + this.daysTimeOut;
        case this.selfExclusionValue.DaysTimeOut42:
          return '42 ' + this.daysTimeOut;
        case this.selfExclusionValue.DaysTimeOut60:
          return '60 ' + this.daysTimeOut;
        case this.selfExclusionValue.DaysTimeOut90:
          return '90 ' + this.daysTimeOut;
        case this.selfExclusionValue.DaysTimeOut120:
          return '120 ' + this.daysTimeOut;
        case this.selfExclusionValue.DaysTimeOut180:
          return '180 ' + this.daysTimeOut;
        case this.selfExclusionValue.DaysTimeOut365:
          return '1 ' + this.yesrsTimeOut;
        case this.selfExclusionValue.DaysTimeOut1825:
          return '5 ' + this.yesrsTimeOut;
        case this.selfExclusionValue.PermanentSelfExclusion:
          return this.$t('responsibleGaming.label.permanentSelfExclusion');
        case this.selfExclusionValue.HrCoolingOff72:
          return this.$t('responsibleGaming.label.hrCoolingOff72');
        default:
          return this.$t('responsibleGaming.label.none');
      }
    },
    click(status) {
      if (status !== 'Applied') return;
      this.$emit('click');
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[display-limit-history-table] {.flex(); .flex-dc(); .gap(10);
  .history-row {.c(black); .flex(); .space-between(); .items-center(); .pv(15); .bgc(#fff); .br(16); .p(20);
    .content { .flex(); .flex-dc(); .gap(1);
      .date {.fs(12); .c(#999);}
      .type {.fs(14); font-weight: 600;}
      .duration {.fs(14);}
      .value { .fs(14); .c(#737373); .pb(10); }
      .status {.fs(14);}
    }
    &.two-ace { .items-start();}
    .change-button {.c(@gp-green); .flex(); .items-center(); .gap(5);}
    .status-text {.pr(15);}
  }
  @media (@ml-up) {
    .flex-dr();
    flex-wrap: wrap;
    .history-row {.w(49%);}
  }
  .no-data {.c(black); .flex(); .flex-dc(); .justify-center(); .items-center(); .min-h(396); .gap(20); .bgc(#fff); .br(16); .w(100%);
    [font-icon] {.fs(20); .c(#737373);}
  }
}
</style>
