<template>
  <div credit-debit-card>
    <ValidationComponent name="_cardHolderName" :component="TextInput" rules="required" :label="$t('_.label.cardHolderName')" :preset="{ maxLength: 255 }" />
    <ValidationComponent name="_cardNumber" :component="TextInput" rules="required" :label="$t('_.label.cardNumber')" :preset="{ maxLength: 23 }" />
    <div class="half-holder">
      <ValidationComponent name="_expiryDate" :component="TextInput" rules="required" :label="$t('_.label.expiryDate')" :preset="{ maxLength: 4 }" />
      <ValidationComponent name="_cvv" :component="TextInput" rules="required" :label="$t('_.label.cvv')" :preset="{ maxLength: 3 } "/>
    </div>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
export default {
  name: 'CreditDebitCard',
  lexicon: 'onBoarding',
  computed: {
  },
  components: { ValidationComponent },
  data() {
    return {
      praxisInfo: null,
      TextInput,
    };
  },
  async mounted() {
    this.praxisInfo = await this.$services.kyc.requestHpfToken({Language: this.lang, PSPName: 'Praxis_CardGateway', ReturnUrl: location.host});
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[credit-debit-card] { .flex-column(); .gap(8); .flex-grow(1); .items-start();
  >* { .w(100%); }
  .half-holder { .flex(); .flex-grow(1); .gap(8);
    >* { .w(100%); }
  }
}
</style>
