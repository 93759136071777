<template>
  <div rg-display-exclusion-modal>
    <div class="content">
      <GpDescription :message="description" />
      <div class="caution-message-holder">
        <p class="caution-message">{{ $t('responsibleGaming.modal.message.selfExclusionMessage') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
export default {
  name: 'DisplayExclusionModal',
  components: { GpDescription, GpButton },
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
    caution: { type: String, default: null, }
  },
  computed: {},
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-display-exclusion-modal] {
  .content {.min-h(180);
    [gp-description] {
      > p {.pt(0); .fs(14); text-align: center;}
    }
    .caution-message-holder{ .ph(0);
      .caution-message{.c(red); word-break: break-word; .fs(12); line-height: 16px; .tc(); }
    }
  }
  .body {
    > p {.c(black);}
  }
  .footer {.flex(); .gap(10);}

  @media (@ml-up) {
    .content {.min-h(150);
      .caution-message-holder{ .ph(40);
      }
    }
  }
}
</style>