import { render, staticRenderFns } from "./DisplayContactInfo.vue?vue&type=template&id=179cbfaa"
import script from "./DisplayContactInfo.vue?vue&type=script&lang=js"
export * from "./DisplayContactInfo.vue?vue&type=script&lang=js"
import style0 from "./DisplayContactInfo.vue?vue&type=style&index=0&id=179cbfaa&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports