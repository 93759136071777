<template>
  <ImageSelector file-selector :img-only="false" @select="select" @result="result" :multiple="maxLength > 1" :class="{'disabled': maxLength === length}" ref="selector" :drag-input="dragInput">
    <template v-slot="{select}">
      <p>
        <FontIcon name="cloud-arrow-up-bold" />
      </p>
      <slot />
    </template>
  </ImageSelector>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import ImageSelector from '@shared/components/common/input/ImageSelector.vue';

export default {
  name: 'FileSelector',
  components: { FontIcon, ImageSelector },
  props: {
    maxLength: { type: Number, default: 5 },
    dragInput: Boolean,
  },
  data() {
    return {
      fileId: 0,
      fileList: [],
      isLast: false,
    };
  },
  computed: {
    length() {
      return this.fileList.length;
    }
  },
  methods: {
    select({ file, resolve, isLast }) {
      this.fileList.push({ file, type: '0', img: null });
      this.isLast = isLast;
      resolve();
    },
    result(img) {
      this.fileList[this.fileId].img = img;
      this.fileId ++;

      if(this.isLast) this.$emit('update', [...this.fileList]);
    },
    remove(id) {
      this.isLast = false;
      this.fileList.splice(id, 1);
      if(!this.length) this.$refs.selector.reset();
      this.fileId = this.length;

      this.$emit('update', [...this.fileList]);
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[file-selector] { .-a(@c-w05); .dashed(); .tc(); .bgc(@c-w01); .br(4); .mt(40);
  > label { .p(40); .fs(14); .c(#8c8c8c);
    > * {.fs(14); .c(#8c8c8c);}
    em { .c(@c-red); .-b(@c-red); }
  }
  [font-icon] { .fs(32); .c(#249135); }
  &.disabled { pointer-events: none; .o(.6);}
}
</style>
