import Specific from '@shared/types/Specific';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { routeNames } from '@/constants/model/my-page';
import GpMessageModal from '@/views/components/gg-pass/modal/GpMessageModal.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { bothErrorCodes } from '@/constants/base/my-page';

export default {
  props: {
    model: Specific,
  },
  data() {
    return {
      error: Specific,
      resendTime: 0,
      unWatchers: Specific,
      isAddWatch: true,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    apiErrorHandlerModel() {
      return {
        [routeNames.NpMobileNumber]: (error, type) => this.mobileEmailNumberErrorHandler(error, type),
        [routeNames.NpEmailAddress]: (error, type) => this.mobileEmailNumberErrorHandler(error, type),
        [routeNames.NpVerifyCode]: (error, type) => this.mobileEmailNumberErrorHandler(error, type),
        [routeNames.NpContactMobileNumber]: (error, type) => this.mobileEmailNumberErrorHandler(error, type),
        [routeNames.NpContactEmailAddress]: (error, type) => this.mobileEmailNumberErrorHandler(error, type),
        [routeNames.NpMfaMobileNumber]: (error, type) => this.mobileEmailNumberErrorHandler(error, type),
        [routeNames.NpMfaEmailAddress]: (error, type) => this.mobileEmailNumberErrorHandler(error, type),
        [routeNames.NpPersonalInfo]: (error) => this.personalInfoErrorHandler(error),
        [routeNames.NpChangeLoginPassword]: (error) => this.changePasswordErrorHandler(error),
        [routeNames.NpFundPassword]: (error) => this.changeFundPasswordErrorHandler(error),
        [routeNames.NpBalanceTransfer]: (key) => this.balanceTransferErrorHandler(key),
      };
    },
    isApiError() {
      return Object.keys(this.error).some(err => this.error[err] !== null);
    },
  },
  methods: {
    apiErrorHandler(error, type = false) {
      return this.apiErrorHandlerModel[this.$route.name](error, type);
    },
    statusHandler(status) {
      let error = false;
      let key = '';

      switch (status) {
        case 'USED' :
          key = 'RESET_PASSWORD_USED';
          break;
        case 'EXPIRED' :
          key = 'RESET_PASSWORD_EXPIRED';
          break;
      }
      if (error) {
        return { key, error: true };
      }
      return { key: null, error: false };
    },

    mobileEmailNumberErrorHandler(error, isEmail = false) {
      let { key, desc } = error;

      const method = isEmail ? 'emailAddress' : 'mobileNumber';

      switch (key) {
        case 'limit' :
          return error;
        case 'INVALID_REQUEST' :{
          key = isEmail ? "INVALID_REQUEST" : 'TELESIGN_INVALID_PHONE_NUMBER';
          return this.error.MobileNumber = this.$t(key);
        }
        case 'FAILED_SEND_EMAIL_VERIFICATION':
        case 'INVALID_EMAIL_LENGTH':
          key = 'INVALID_EMAIL';
          break;
        case apiErrorCode.INVALID_MOBILE_NUMBER:
          return this.error.MobileNumber = this.$t(key,);
        case 'EMAIL_RESEND_TERM_LIMITED' :
        case apiErrorCode.PHONE_RESEND_TERM_LIMITED : {
          const json = JSON.parse(desc);
          this.resendTime = parseInt(json.remainingTime);
          return { key: 'limit', value: this.resendTime };
        }
        case apiErrorCode.EXISTING_MOBILE_NUMBER :
          return this.error.MobileNumber = this.$t(key);
        case apiErrorCode.EXIST_MOBILE :
          return this.error.MobileNumber = this.$t(key);
        case 'ALREADY_VERIFIED_MOBILE' :
          return this.error.MobileNumber = this.$t(key);
        case 'RESEND_TERM_LIMITED' : {
          this.resendTime = parseInt(error.value);
          return { key: 'limit', value: this.resendTime };
        }
        case 'EXCEED_VERIFICATION_CODE_SEND_COUNT' :
          return this.error.MobileNumber = this.$t(key);
        case 'FAILED_SEND_MOBILE_VERIFICATIONCODE' :
          return this.error.MobileNumber = this.$t(key);
        case 'ALREADY_USED_MFA_VERIFICATION' :
          return this.error.MobileNumber = this.$t(key);
        case 'INVALID_VERIFICATIONCODE' :
          return this.error.VerificationCode = this.$t(key);
        case 'EXPIRED_VERIFICATIONCODE' :
          return this.error.VerificationCode = this.$t(key);
        case 'UNMATCHED_VERIFICATIONCODE' :
          return this.error.VerificationCode = this.$t(key);
        case 'UNMATCHED_VERIFICATION_CODE' :
          this.error.MobileNumber = this.$t(apiErrorCode.INVALID_VERIFICATIONCODE);
          this.error.VerificationCode = this.$t(apiErrorCode.INVALID_VERIFICATIONCODE);
          return;
        default :
          this.error.MobileNumber = this.$t(key, { fieldName: this.$t(method), method: this.$t(method) });
          this.error.VerificationCode = this.$t(key, { fieldName: this.$t(method), method: this.$t(method) });
          this.error.Email = this.$t(key, { fieldName: this.$t(method), method: this.$t(method) });
          return;
      }
    },
    personalInfoErrorHandler(error) {
      const { key, desc } = error;
      switch (key) {
        case apiErrorCode.INVALID_FIRSTNAME:
          this.error.FirstName = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('firstName') });
          break;
        case apiErrorCode.INVALID_LASTNAME:
          this.error.LastName = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('lastName') });
          break;
        case apiErrorCode.INVALID_GENDER:
          this.error.Gender = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('gender') });
          break;
        case apiErrorCode.INVALID_ADDRESS :
          this.error.Address = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('address') });
          break;
        case apiErrorCode.INVALID_BUILDING :
          this.error.BuildingAddress = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('building') });
          break;
        case apiErrorCode.INVALID_STATE :
          this.error.State = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('state') });
          break;
        case apiErrorCode.INVALID_DATEOFBIRTH:
          this.error.DateOfBirth = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('dateOfBirth') });
          break;
        case 'INVALID_POSTALCODE':
          this.error.PostalCode = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('postalCode') });
          break;
        case 'INVALID_CITY':
          this.error.City = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('city') });
          break;
        case 'INVALID_STREET_NAME':
          this.error.Street = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('street') });
          break;
        case 'INVALID_STREET_NUMBER':
          this.error.StreetNumber = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('streetNumber') });
          break;
        case 'INVALID_UNDERAGE':
          this.error.DateOfBirth = this.$t(key);
          break;
        case 'INVALID_UNDER21AGE':
          this.error.DateOfBirth = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('dateOfBirth') });
          break;
        case 'INVALID_ALIAS':
          this.error.Alias = this.$t('INVALID_PERSONAL_INFO', { fieldName: this.$t('alias') });
          break;
        case 'INVALD_KYC_STATUS':
        case 'ALREADY_VERIFIED_KYC':
          this.$modal(GpMessageModal, { title: this.$t('failed'), message: this.$t(key) });
          setTimeout(() => {
            this.$modalCloseAll();
          }, 5000);
          break;
        case 'LUGAS_INTERNAL_ERROR':
          this.$modal(GpMessageModal, { title: this.$t('failed'), message: this.$t(key, { email: this.csEmail }) });
          setTimeout(() => {
            this.$modalCloseAll();
          }, 5000);
          break;
        default:
          break;
      }
    },
    changePasswordErrorHandler(error) {
      let { key, desc } = error;
      switch (key) {
        case apiErrorCode.INVALID_REQUEST :
        case 'INVALID_PASSWORD' :
          key = 'INVALID_PASSWORD_LENGTH';
          break;
        case 'INVALID_CURRENT_PASSWORD' : {
          this.error.OldPassword = 'INVALID_CURRENT_PASSWORD'
            // this.$t('INVALID_CURRENT_PASSWORD');
          return;
        }
        case 'INVALID_PASSWORD_VERIFICATION': {
          this.error.OldPassword = 'INVALID_PASSWORD_VERIFICATION'
            // this.$t('INVALID_PASSWORD_VERIFICATION');
          return;
        }

      }
      this.error.Password = key;
        // this.$t(key);
    },
    changeFundPasswordErrorHandler(error) {
      let { key, desc } = error;
      switch (key) {
        case 'INVALID_FUND_PASSWORD_VERIFICATION' : {
          this.error.CurrentFundPassword = this.$t(key);
          return;
        }
        case apiErrorCode.INVALID_REQUEST :
          key = 'INVALID_FUND_PASSWORD_LENGTH';
          break;
        case 'INVALID_FUND_PASSWORD' :
          key = 'INVALID_PASSWORD';
          break;
      }
      this.error.FundPassword = this.$t(key);
    },

    balanceTransferErrorHandler(error) {
      let { key, desc } = error;

      if (bothErrorCodes.includes(key)) {
        // if (this.model.DebitErrorCode === key && this.model.CreditErrorCode === key) {
        //   return this.$t('BOTH_' + key);
        // } else {
        //   const type = this.model.DebitErrorCode === key ? this.$t('sender') : this.$t('recipient');
        //   return this.$t(key, { type: type });
        // }
        return this.$t(`BOTH_${key}`);
      }

      switch (key) {
        case 'INSUFFICIENT_AMOUNT': {
          return this.$t('validation.rules.balanceTransferMin', { currency: this.model.FromCurrencyId, minimumamount: this.model.FromAmount });
        }
        default : {
          return this.$t(key);
        }
      }
    },
    createErrorModel() {
      if (Object.keys(this.model).length <= 0) return null;

      return Object.keys(this.model).reduce((acc, cur) => {
        acc[cur] = null;
        return acc;
      }, {});
    },

    clearError() {
      Object.keys(this.model).forEach((key) => {
        this.error[key] = null;
      });
    },

    createWatches() {
      if (!this.isAddWatch) return null;
      Object.keys(this.model).forEach((key) => {
        this.unWatchers[key] = this.$watch(`model.${key}`, () => {
          this.error[key] = null;
        });
      });
    },
    destroyWatches() {
      Object.values(this.unWatchers).forEach((unwatch) => {
        if (typeof unwatch === 'function') unwatch();
      });
    },
    setResendTime(time) {
      this.resendTime = time;
    }
  },
  beforeMount() {
    this.error = this.createErrorModel();
    this.unWatchers = this.createErrorModel();

    this.createWatches();
  },
  beforeDestroy() {
    this.destroyWatches();
  }
};