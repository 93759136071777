import { render, staticRenderFns } from "./InputBalanceTransfer.vue?vue&type=template&id=60ea1318"
import script from "./InputBalanceTransfer.vue?vue&type=script&lang=js"
export * from "./InputBalanceTransfer.vue?vue&type=script&lang=js"
import style0 from "./InputBalanceTransfer.vue?vue&type=style&index=0&id=60ea1318&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports