<template>
  <div reaffirm-de-kyc-de>
    <template v-if="template">
      <ProgressTitle :default-label="title" is-final />
      <component :is="template" :structure="structure" :model-info="modelInfo" @step="stepTrigger" />
    </template>
  </div>
</template>

<script>
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue';
import VerifyJumio from '@/views/components/pages/on-boarding/template/VerifyJumio.vue';
import VerifyReadyDe from '@/views/components/pages/on-boarding/template/kyc/de/VerifyReadyDe.vue';
import SofortFailed from '@/views/components/pages/on-boarding/template/kyc/de/SofortFailed.vue';
import VerifyResendButton from '@/views/components/common/VerifyResendButton.vue';
import baseTemplate from '@/mixins/baseTemplate';
export default {
  name: 'ReaffirmDeKycDe',
  components: { VerifyResendButton, ProgressTitle },
  mixins: [baseTemplate],
  data() {
    return {
      step: 'ready',
      components: {
        ready: VerifyReadyDe,
        jumio: VerifyJumio,
        sofortFailed: SofortFailed,
      }
    };
  },
  computed: {
    title() {
      switch (this.step) {
        case 'jumio':
          return 'onBoarding.title.residenceDocumentUpload';
        case 'sofortFailed':
          return 'onBoarding.title.sofortFailed';
        default:
          return 'onBoarding.reaffirm.de.title';
      }
    },
    template() {
      return this.components[this.step];
    }
  },
  methods: {
    stepTrigger(step) {
      this.step = step;
      switch (step) {
        case 'sofort':
          this.goSofort();
          return;
      }
    },
    async goSofort() {
      const r = await this.$services.kyc.getSofortUrl({ RequestHost: location.host });
      location.href = r.Url;
    },
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reaffirm-de-kyc-de] {
  > p { .fs(16, 24); .mb(8); .medium(); }
  > a { .-a(@c-w01, 2px); .br(8); .block(); .rel(); .p(28, 24); .flex(); .items-center();
    > img { .h(40); }
    > div { .pl(24); .ml(24); .rel();
      &:before { .cnt(); .wh(1, 110%); .bgc(@c-w01); .abs(); .lt(0, 50%); .t-yc(); }
      h4 { .fs(20); .c(#FFC64D); .mb(8); }
      p { .fs(14, 20); }
    }
    &:nth-of-type(2) { .mt(24); }
  }
}
</style>