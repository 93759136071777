<template>
  <JumioContainer jumio-reviewing type="reviewing">
    <h3>{{ $t(title) }}</h3>
    <div class="desc">
      <p>{{ $t('_.desc.thankYouForUploading') }}</p>
    </div>
    <div class="contents">
      <p></p>
      <p v-html="$t('_.contents.weAppreciatePatience', { csEmail })" />
      <p v-html="$t('_.contents.verificationProcess')" />
    </div>
    <ProgressButton button-name="complete" @click="$emit('confirm')"/>
  </JumioContainer>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import JumioContainer from '@/views/components/pages/on-boarding/template/kyc/jumio/JumioContainer.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';

export default {
  name: 'JumioReviewing',
  lexicon: 'onBoarding.jumio.reviewing',
  components: { ProgressButton, JumioContainer },
  computed: {
    csEmail: state('env', 'csEmail'),
    title() {
      return '_.title';
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[jumio-reviewing] {
  h3 { .mt(16); }
  .contents { .tl;

  }
}
</style>