<template>
  <tr><slot /></tr>
</template>

<script>
export default {
  name: 'GridTableHead',
  props: {
    useSort: { type: Boolean },
    sortField: { type: String },
    sortType: { type: String },
  },
  data: () => ({ th: true }),
  updated() {
    this.$emit('updated');
  },
  methods: {
    sort(field) {
      if(field) this.$emit('sort', field);
    },
  },
};
</script>
