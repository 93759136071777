<template>
  <div match-bonus-component v-if="dripBonus">
    <section class="match-bonus">
      <div class="progress-circle">
        <svg viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
          <circle stroke="#262626" stroke-width="2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" stroke-dasharray="100" />
          <circle stp :stroke-dasharray="`${incrementProgress}, 100`" stroke="#C64040" stroke-width="2" stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
        </svg>
        <p class="progress-text">
          <span :class="{current: !isCancelled}">{{ dripBonus.dripCount }}</span>
          <span class="slash">/</span>
          <span>{{ maxDripCount }}</span>
        </p>
        <div class="chart-info">
          <div class="released">
            <p>
              <span :class="['value', {current: !isCancelled}]">{{ releaseBonus }}</span>
              <span class="text">{{ $t('_.releasedBonus') }}</span>
            </p>
          </div>
          <div class="match">
            <p>
              <span class="value">{{ matchBonus }}</span>
              <span class="text">{{ $t('promotion.type.matchBonus') }}</span>
            </p>
          </div>
        </div>
      </div>
      <p class="description">{{ $t('_.totalIncrement') }}</p>
    </section>
    <section>
      <div class="rake">
        <p>{{ $t('_.nextRelease') }}</p>
        <ProgressBar :width="rakeProgress" :start="getCurrencyValue(0, dripBonus.rakeCurrencyId)" :end="getCurrencyValue(dripBonus.rakeMax, dripBonus.rakeCurrencyId)" />
      </div>
    </section>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import Slider from '@shared/components/general/Slider.vue';
import { isEmpty } from 'lodash';
import PromotionTitle from '@/views/components/pages/promotion/PromotionTitle.vue';
import ContentsContainer from '@/views/components/pages/promotion/ContentsContainer.vue';
import BonusSection from '@/views/components/pages/promotion/BonusSection.vue';
import BonusHistory from '@/views/components/pages/promotion/BonusHistory.vue';
import ProgressBar from '@/views/components/pages/promotion/ProgressBar.vue';
import { getPromotionCurrencySymbol } from '@/utils/promotionCurrency';
import Specific from '@shared/types/Specific';

export default {
  name: 'MatchBonusComponent',
  lexicon: 'promotion.matchBonus',
  components: { ProgressBar, BonusHistory, BonusSection, ContentsContainer, PromotionTitle, Slider, FontIcon },
  props: {
    dripBonus: Specific,
  },
  computed: {
    dripAmount() {
      return this.dripBonus?.prizeItem?.value;
    },
    releaseBonus() {
      const v = this.dripBonus?.dripCount * this.dripAmount;
      return this.getCurrencyValue(v, this.dripBonus?.prizeItem?.currencyId);
    },
    matchBonus() {
      return this.getCurrencyValue(this.dripBonus?.maxAmount, this.dripBonus?.prizeItem?.currencyId);
    },
    maxDripCount() {
      return Math.ceil(this.dripBonus?.maxAmount / this.dripBonus?.prizeItem.value);
    },
    incrementProgress() {
      return ((this.dripBonus?.dripCount / this.maxDripCount) * 100).toFixed(2);
    },
    rakeProgress() {
      return this.dripBonus?.rake / this.dripBonus?.rakeMax;
    },
    isCancelled() {
      return this.dripBonus?.status === 'EXPIRED'; // [celina] 이거 상태 어떤걸로 할지 확인
    },
  },
  methods: {
    getCurrencyValue(v, currency = '$', decimal = 2) {
      const currencySymbol = getPromotionCurrencySymbol(currency);
      return `${currencySymbol}${v.toFixed(decimal)}`;
    },
    async activeDripBonus() {
      await this.$services.promotion.activeDripBonus({ dripBonusId: this.dripBonus.dripBonusId });
    },
  },
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[match-bonus-component] { .bgc(@c-bgBlack);
  .match-bonus { .tc(); }
  .current { .c(@c-blurRed); .semi-bold(); }
  .progress-circle { .rel(); .medium(); .tc();
    svg { .w(96); transform: rotate(90deg) rotateY(180deg);
      circle:last-of-type { animation: circle-fill 2s reverse; }
    }
    > p { .abs(); .t(50%); .l(50%); .t-xyc(); }

    .chart-info { .flex(); .gap(120); .space-between(); .abs(); .lt(50%, 50%); .t-xyc();
      .released { .flex(); .justify-end(); }
      .match { .flex(); .justify-start(); }
      > div { .w(0);
        span { .block(); }
        .value { .mb(4); .fs(18); .c(@c-white); }
        .text { .mt(8); .regular(); .fs(14); .c(@c-gray3); }
      }
    }
    .progress-text { .flex(); .gap(2); }
  }
  .match-bonus { .pt(40); }
  .description { .mt(16); }
  .rake { .p(20); .mt(24); .-t(#262626); .tc(); .c(#8a8e90);
    [progress-bar] { .mt(20); }
  }


  @media (@mm-up) {
    .progress-circle .chart-info { .gap(120); }
  }
  @media (@tp-up) {
    .progress-circle {
      svg { .w(120); }
      .chart-info { .gap(180); }
    }
  }

  @keyframes circle-fill {
    to { stroke-dasharray: 0 100; }
  }
}
</style>
