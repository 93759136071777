import createPlugin from '@shared/plugins/i18n';
import BI18n from '@shared/modules/BI18n';
import LocaleLink from '@shared/plugins/i18n/components/LocaleLink.vue';
import {PluginObject} from 'vue';
import {Route} from 'vue-router';
import {ObjectLiteral} from '@shared/types/utilType';
import {Locale, Language, Country, defaultLocale, localeLanguage as localeLanguageMap, localeCountry as localeCountryMap} from './bundle';

const data = {} as { [P in Language]: ObjectLiteral };
export const i18n = new BI18n<Locale, Language, Country>({data, localeLanguageMap, localeCountryMap, defaultLocale});

export const i18nLoad = async (route: Route) => {
  const lang = i18n.languageByRouteLocale(route.params.locale as Locale);
  if (data[lang]) return;
  const module = await import(`./bundle/${lang}.json`);
  data[lang] = module.default;
};

const i18nPlugin: PluginObject<undefined> = {
  install(Vue) {
    const {directives, prototypes} = createPlugin(i18n);
    Vue.directive('t', directives.common);
    Vue.directive('te', directives.common);
    Vue.directive('tc', directives.common);
    Vue.directive('tv', directives.tv);
    Vue.directive('placeholder', directives.placeholder);
    Vue.prototype.$t = prototypes.i18nT;
    Vue.prototype.$te = prototypes.i18nTe;
    Vue.prototype.$tc = prototypes.i18nTc;
    Vue.prototype.$tv = prototypes.i18nTv;
    Vue.prototype.$fromNow = prototypes.i18nFromNow;
    Vue.prototype.$date = prototypes.i18nDate;
    Vue.prototype.$i18n = prototypes.i18nProps;
    Vue.prototype.$changeLocale = function (locale: Locale) {
      this.$services.cookie.setLanguage(locale);
      this.$router.replace({name: this.$route.name, params: {...this.$route.params, locale: locale === defaultLocale ? null : locale}, query: this.$route.query});
    };
    Vue.component('LocaleLink', LocaleLink);
  },
};

export default i18nPlugin;
