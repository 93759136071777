export default () => ({
  namespaced: true,
  state: {
    token: null,
    qtag: null,
    qtag1: null,
    btag: null,
    btag1: null,
    landingMode: false,
    isFromLanding: false,
    isFromExternalUrl: false,
    channelingName: null,
    closeUrl: null,
    originId: null,
    invite: null,
    refKey: null,
    findKey: null,
    customStep: null,
    app: null,
    additional: null,
    restrict: null,
    selectedLang: null,
    aTag: null,
    citizenshipCode: null,
    stripLayout: null,
    agentCode: null,
    regionKey: null,
    channelingRegionList: null,
    mobileAnonymousId: null,
  },
  mutations: {
    setToken(state, value) {
      state.token = value;
    },
    setRestrict(state, value) {
      state.restrict = value;
    },
    setAdditional(state, value) {
      state.additional = value;
    },
    setChannelingName(state, value) {
      state.channelingName = value;
    },
    setCloseUrl(state, value) {
      state.closeUrl = value;
    },
    setOriginId(state, value) {
      state.originId = value;
    },
    setBonusCode(state, query) {
      state.qtag = query.qtag;
      state.qtag1 = query.qtag1;
      state.btag = query.btag;
      state.btag1 = query.btag1;
    },
    setIsFromExternalUrl(state, value) {
      state.isFromExternalUrl = value;
    },
    setInvite(state, value) {
      state.invite = value;
    },
    setIsFromLanding(state, value) {
      state.isFromLanding = value;
    },
    setLandingMode(state, value) {
      state.landingMode = value;
    },
    setRefKey(state, value) {
      state.refKey = value;
    },
    setFindKey(state, value) {
      state.findKey = value;
    },
    setCustomStep(state, value) {
      state.customStep = value;
    },
    setApp(state, value) {
      state.app = value;
    },
    setSelectedLang(state, value) {
      state.selectedLang = value;
    },
    setAtag(state, value) {
      state.aTag = value;
    },
    setCitizenshipCode(state, value) {
      state.citizenshipCode = value;
    },
    setStripLayout(state, value) {
      state.stripLayout = value;
    },
    setRegionKey(state, value) {
      state.regionKey = value;
    },
    setChannelingRegionList(state, value) {
      state.channelingRegionList = value;
    },
    setQtag(state, value) {
      state.qtag = value;
    },
    setMobileAnonymousId(state, value) {
      state.mobileAnonymousId = value;
    }
  }
});