<template>
  <div progress-bar>
    <p class="start" v-if="value.start">{{ value.start }}</p>
    <div class="bar-chart">
      <div class="bar" :style="`width: ${progressRate}`" />
      <p v-if="item&&item.name !== 'Match Bonus'">{{ value.current }} / {{ value.max }}</p>
    </div>
    <p class="end">
      <img :class="['img', value.img]" :src="`https://static.gg-cdn.com/game/platform-front/img/widget/promotion-${value.img}.png`" alt="bonus" v-if="value.img">
      <span class="text" v-if="value.end">{{ value.end }}</span>
    </p>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';

export default {
  name: 'ProgressBar',
  components: {},
  props: {
    statusInfo: { /** @type {PromotionStatus} */ type: Specific, required: true },
    completeAll: { type: Boolean, default: false },
  },
  computed: {
    item() {
      return this.statusInfo?.item;
    },
    value() {
      return this.getValue(this.completeAll ? 'Complete All' : this.item?.name);
    },
    progressRate() {
      return `${((this.value.current / this.value.max) * 100).toFixed(2)}%`;
    },
  },
  methods: {
    getValue(type) {
      switch (type) {
        case 'Complete All': {
          const o = (this.item?.dailyChallenges?.[0]) || {};
          o.items = o.items || [];
          return o && ({
            min: 0,
            max: o.items.length,
            current: o.items.reduce((count, item) => (item.status === 'Completed' ? ++count : count), 0),
            end: !o.completionRewardName && this.$promotion.getCurrencyString(o.completionRewardInstantCashAmountCurrency, o.completionRewardInstantCashAmount),
            img: o.completionRewardName ? 'tickets' : 'cdollar',
          });
        }
        case 'Match Bonus':
        case 'USDT First Time Deposit Bonus': {
          const o = (this.item?.matchBonuses?.[0]) || {};
          const amount = o.dailyTargetAmount === 0 ? 1 : o.dailyTargetAmount;
          const currency = this.statusInfo.promotionCurrency;
          return ({
            min: 0,
            max: amount,
            current: o.dailyTargetAmount === 0 ? 1 : o.dailyCurrentAmount,
            start: this.$promotion.getCurrencyString(currency, 0),
            end: o && this.$promotion.getCurrencyString(currency, amount),
          });
        }
        case 'First Deposit Bonus': {
          const o = (this.item?.dailyChallenges?.[0]) || {};
          return ({
            min: 0,
            max: o.handRequirements,
            current: Math.min(o.currentHandCount, o.handRequirements),
            end: this.$promotion.getCurrencyString(this.statusInfo.promotionCurrency, (this.statusInfo.exchangeRate * o.ticketValue)),
          });
        }
        default: {
          // Free Bonus & Sign-up Bonus
          const o = (this.item?.dailyChallenges?.[0]) || {};
          return ({
            min: 0,
            max: o.handRequirements,
            current: Math.min(o.currentHandCount, o.handRequirements),
            end: this.$promotion.getCurrencyString(o.bonusAmountCurrency, o.bonusAmount),
          });
        }
      }
    }
  },
};
</script>

<style lang="less">
@import "~@/less/promotion.less";
[progress-bar] { .flex(); .items-center(); .gap(10); .mt(16);
  .bar-chart { flex: 1; .flex(); .justify-start(); .items-center(); .rel(); .wf(); .h(20); .bgc(@c-nero); .br(2em); .fs(0.75em); .crop();
    .bar { .hf(); .br(2em); background: linear-gradient(to left, @c-blurRed, @c-darkRed); }
    p { .abs(); .t(50%); .l(0); .wf(); .tc(); .t-yc(); }
  }
  p { .bold(); .fs(1.125em); }
  .end { .tc();
    .cdollar { .w(38); }
    .tickets { .w(50); }
    span { .block(); }
  }
}
</style>
