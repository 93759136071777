<template>
  <div verify-rg-totalbet-setting>
    <LimitUnitField :label="isRolling ? $t('_.30Days') : $t('_.monthlyLimit')" :name="'_amount'" :max-length="9" :hasNoLimit="requestLimit.hasMonthlyNoLimit" :rules="monthlyRule" v-model="modelInfo.RequestMonthlyTotalBetLimit" @update="checkMonthlyNoLimit" />
    <LimitUnitField :label="isRolling ? $t('_.7Days') : $t('_.weeklyLimit')" :name="'_amount'" :max-length="9" :hasNoLimit="requestLimit.hasWeeklyNoLimit" :rules="weeklyRule" v-model="modelInfo.RequestWeeklyTotalBetLimit" @update="checkWeeklyNoLimit" />
    <LimitUnitField :label="isRolling ? $t('_.24hours') : $t('_.dailyLimit')" :name="'_amount'" :max-length="9" :hasNoLimit="requestLimit.hasDailyNoLimit" :rules="dailyRule" v-model="modelInfo.RequestDailyTotalBetLimit" @update="checkDailyNoLimit" />
    <p class="list-star">{{ $t('_.totalBetLimit.description') }}</p>
  </div>
</template>

<script>
import LimitUnitField from '@/views/components/pages/on-boarding/template/extra/LimitUnitField.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'VerifyRgTotalBetSetting',
  lexicon: 'onBoarding.setting',
  components: { LimitUnitField },
  mixins: [baseTemplate],
  data() {
    return {
      totalBetLimitType: null,
      requestLimit: {
        hasMonthlyNoLimit: false,
        isMonthlyNoLimit: false,
        hasWeeklyNoLimit: false,
        isWeeklyNoLimit: false,
        hasDailyNoLimit: false,
        isDailyNoLimit: false,
      },
      currentLimit: {
        CurrentMonthlyTotalBetLimit: null,
        CurrentWeeklyTotalBetLimit: null,
        CurrentDailyTotalBetLimit: null,
      },
    };
  },
  computed: {
    currencySymbol: state('user', 'currencySymbol'),
    monthlyRule() {
      if ((this.requestLimit.isMonthlyNoLimit) || (!this.modelInfo.RequestMonthlyTotalBetLimit)) return '';
      const exceedRule = this.currentLimit.CurrentMonthlyTotalBetLimit ? `depositLimit:${this.currencySymbol},${this.currentLimit.CurrentMonthlyTotalBetLimit},${this.currentLimit.CurrentMonthlyTotalBetLimit}|` : '';
      return exceedRule;
    },
    weeklyRule() {
      if ((this.requestLimit.isMonthlyNoLimit && this.requestLimit.isWeeklyNoLimit) || (!this.modelInfo.RequestWeeklyTotalBetLimit && !this.modelInfo.RequestMonthlyTotalBetLimit)) return '';
      const exceedRule = this.currentLimit.CurrentWeeklyTotalBetLimit ? `depositLimit:${this.currencySymbol},${this.currentLimit.CurrentWeeklyTotalBetLimit},${this.currentLimit.CurrentWeeklyTotalBetLimit}|` : '';
      return `${exceedRule}monthlyLimit:${this.modelInfo.RequestMonthlyTotalBetLimit},${this.$t('weekly')},${this.$t('monthly')}`;
    },
    dailyRule() {
      if ((this.requestLimit.isMonthlyNoLimit && this.requestLimit.isWeeklyNoLimit && this.requestLimit.isDailyNoLimit) || (!this.modelInfo.RequestDailyTotalBetLimit && !this.modelInfo.RequestWeeklyTotalBetLimit && !this.modelInfo.RequestMonthlyTotalBetLimit)) return '';
      const exceedRule = this.currentLimit.CurrentDailyTotalBetLimit ? `depositLimit:${this.currencySymbol},${this.currentLimit.CurrentDailyTotalBetLimit},${this.currentLimit.CurrentDailyTotalBetLimit}|` : '';
      return `${exceedRule}weeklyLimit:${this.modelInfo.RequestWeeklyTotalBetLimit},${this.$t('daily')},${this.$t('weekly')}|monthlyLimit:${this.modelInfo.RequestMonthlyTotalBetLimit},${this.$t('daily')},${this.$t('monthly')}`;
    },
    isRolling() {
      return this.totalBetLimitType === 'Rolling';
    },
  },
  methods: {
    checkMonthlyNoLimit(v) {
      this.isMonthlyNoLimit = v;
    },
    checkWeeklyNoLimit(v) {
      this.isWeeklyNoLimit = v;
    },
    checkDailyNoLimit(v) {
      this.isDailyNoLimit = v;
    },
    update(p, v) {
      this.modelInfo[p] = v;
    },
    async initialize() {
      this.totalBetLimitType = (await this.$services.account.getRgConfig()).TotalBetLimitPeriodType;
      const { CurrentDailyTotalBetLimit, CurrentWeeklyTotalBetLimit, CurrentMonthlyTotalBetLimit } = await this.$services.account.getTotalBetLimit();
      this.currentLimit = { CurrentDailyTotalBetLimit, CurrentWeeklyTotalBetLimit, CurrentMonthlyTotalBetLimit };

      this.modelInfo.RequestMonthlyTotalBetLimit = CurrentMonthlyTotalBetLimit;
      this.modelInfo.RequestWeeklyTotalBetLimit = CurrentWeeklyTotalBetLimit;
      this.modelInfo.RequestDailyTotalBetLimit = CurrentDailyTotalBetLimit;
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-rg-totalbet-setting] {
  > h4 { .fs(18); .medium(); .c(#ffc64d);}
  b { .c(#ffc64d); .medium(); }
  > h5 { .c(#ababab); .medium(); .fs(16, 24); .ls(-.2); .mb(16); .pt(20); .-t(#8b8b8b); }
  [limit-unit-field] {
    label { flex-basis: 30%; .min-w(98); white-space: normal; }
    label + div { flex-basis: 70%; }
  }
}
</style>
