<template>
  <GpFormTemplate v-if="load" self-exclusion>
    <template #header>
      <GpDescription v-if="description" :message="description" />
    </template>
    <template #content>
      <InputSelfExclusion :model="model" @input="input" />
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click="submit(validate)" :disabled="disabled || !!invalid">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import InputSelfExclusion from '@/views/components/pages/responsible-gaming/template/inputs/InputSelfExclusion.vue';
import ResponsibleConfirmModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleConfirmModal.vue';
import { hiddenOasisReasonList, useOasisSiteIds } from '@/constants/base/responsible-gaming';
import _ from 'lodash';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
import { siteIds } from '@/constants/base/siteMap.mjs';

export default {
  name: 'RgSelfExclusion',
  lexicon: 'responsibleGaming',
  components: { InputSelfExclusion, GpFormTemplate, GpDescription, GpButton },
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      errorMsg: null,
      current: {
        CurrentSelfExclusionPeriodType: null,
        ExclusionEndAt: null,
      },
      model: {
        OasisBanCauses: null,
        RequestSelfExclusionPeriodType: null,
        CustomSelfExclusionPeriodDay: null,
        RequestSelfExclusionPeriodLabel: null,
      },
      selectedGroup: null,
      load: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    useOasis() {
      return useOasisSiteIds.includes(this.site);
    },
    disabled() {
      const request = this.model.RequestSelfExclusionPeriodType;
      return this.current.CurrentSelfExclusionPeriodType === request || !request;
    },
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    }
  },
  methods: {
    async initialize() {
      // const result = await this.$rg.fetch();
      this.current = this.structure.current;
      this.load = true;
    },

    input(v, group) {
      this.model.CustomSelfExclusionPeriodDay = v;
      this.selectedGroup = group;
    },

    async request({ oasisList }) {
      if (oasisList !== null && _.isArray(oasisList) && this.useOasis) {
        this.model = {
          ...this.model,
          OasisBanCauses: oasisList,
        };
      } else {
        this.model = {
          ...this.model,
          OasisBanCauses: [99],
        };
      }
      const result = await this.$rg.request(this.model);
      if (result.error) {
        return result;
      } else {
        if (this.isGGPNL) {
          this.replaceRouteName('ResponsibleGaming');
          return;
        }
        const r = await this.$services.account.getRgConfig(true);
        if (r.error) {
          const replace = this.$rg.createErrorProxy(r);
          if (replace) {
            this.replaceRouteName(replace.path, replace.params, replace.query);
            return;
          }
        }
      }
      return null;
    },

    async submit(validate) {
      if (validate) {
        const invalid = await validate();
        if (!invalid) return;
      }

      const modalTitle = { default: this.$t('_.modal.title') };
      const modalDesc = this.$t('_.modal.description.selfExclusion', { comment: this.$t(this.model.RequestSelfExclusionPeriodLabel) });
      let type = 'Exclusion';
      if (this.useOasis) {
        if (!hiddenOasisReasonList.includes(this.selectedGroup)) type = 'Oasis';
        else type = 'Exclusion';
      }
      this.$modal(ResponsibleConfirmModal, { title: modalTitle, request: ({ oasisList }) => this.request({ oasisList }), desc: modalDesc, modalType: type, selfExclusion: true, });
    },
  },

  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[self-exclusion] {

}

</style>

