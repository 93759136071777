<template>
  <div promotion-pagination>
    <div>
      <button v-show="nowIndex > 0" @prev="prev">
        <FontIcon name="chevron-left" />
      </button>
    </div>
    <p>{{ nowIndex + 1 }}</p>
    <div>
      <button v-show="nowIndex < maxIndex" @next="$emit('next')">
        <FontIcon name="chevron-right" />
      </button>
    </div>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'PromotionPagination',
  components: { FontIcon },
  props: {
    info: Specific,
    slider: Specific,
    nowIndex: 0,
  },
  computed: {
    maxIndex() {
      return this.info?.length;
    }
  },
  methods: {
    updateIndex(v) {
      this.nowIndex = v;
    },
    prev() {
      this.
      this.$emit('prev')
    },
    next() {

    },
  }
};
</script>

<style scoped lang="less">
@import "@/less/tpm";

[promotion-pagination] { .flex(); .gap(12); .justify-end(); .items-center(); .mt(20); .c(#cccccc);
  > div { .wh(28); }
  button { .f(); .bgc(#202020); .c(inherit); }
}
</style>
