<template>
  <div verify-citizenship-birth>
    <div class="birth-holder">
      <DateOfBirth :modelInfo="modelInfo" :structure="structure" :item="item" />
      <GenderSelector :model-info="modelInfo" :structure="structure" />
    </div>
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import DateOfBirth from '@/views/components/extra/DateOfBirth.vue';
import GenderSelector from '@/views/components/extra/GenderSelector.vue';

export default {
  name: 'VerifyCitizenshipBirth',
  components: { GenderSelector, DateOfBirth },
  mixins: [baseTemplate],
  props: {
    formTag: { type: String, default: null },
    formClass: { type: String, default: null },
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[verify-citizenship-birth] {
  .birth-holder {
    > [gender-selector] { .mt(8); }
  }
}
</style>