<template>
  <SumsubContainer sumsub-document-invalid>
    <FontIcon name="sharp-triangle-warning" />
    <h3 v-html="$t('_.title')" />
    <p v-html="$t('_.guideMessage')" />
    <div>
      <ul>
        <li>{{$t('_.guideItem1')}}</li>
        <li>{{$t('_.guideItem2')}}</li>
        <li>{{$t('_.guideItem3')}}</li>
      </ul>
    </div>
    <p v-html="$t('_.description', {csEmail})" />
    <ProgressButton button-name="tryAgain" @click="$emit('confirm', 'restart')" />
  </SumsubContainer>
</template>

<script>
import SumsubContainer from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubContainer.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'SumsubDocumentInvalid',
  lexicon: 'onBoarding.sumsub.documentInvalid',
  components: { FontIcon, ProgressButton, SumsubContainer },
  props: {
    item: {type: Specific, default: null},
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  mounted() {
    if (this.item) this.item.title = 'onBoarding.title.accountVerification';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sumsub-document-invalid] { .tc();
  > h3 { .fs(22, 27, normal); .regular(); .mb(24); }
  > [font-icon] { .block(); .mb(24); .fs(80); .c(#df3320); }
  > p { .tl(); .fs(18, 22, normal); .c(#7F7F7F);
    > a[href^='mailto'] { .c(#2680EB); .underline; }
  }
  ul { .mv(22); .ml(16); .tl();
    li { .pl(4); .rel(); .c(#7F7F7F); .fs(16, 20, normal);
      &:before { .cnt(); .wh(3); .br(50%); .bgc(#7F7F7F); .abs(); .lt(-8,50%); .t-yc(); }
    }
  }
}
</style>
