<template>
  <div reaffirm-wsop-occupation :name="formTag" :class="formClass">
    <ValidationComponent v-if="occupationList" :label="occupationLabel" name="_occupation" :component="SearchDropSelect" rules="requiredSelectNone" :preset="occupationPreset" :value="occupation" @input="updateOccupation"/>
    <ValidationComponent :label="employerNameLabel" name="_employerName" :component="TrimTextInput" v-model="modelInfo.EmployerName" :preset="employerNamePreset" />
    <ValidationComponent :label="employerAddressLabel" name="_employerAddress" :component="TrimTextInput" v-model="modelInfo.EmployerAddress" :preset="employerAddressPreset" />
    <ValidationComponent :label="businessTelephoneLabel" name="_businessPhone" :component="TrimTextInput" v-model="modelInfo.BusinessPhoneNumber" :preset="businessPhonePreset" @input="updatePhoneNumber" @delete="deletePhoneNumber"/>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import baseTemplate from '@/mixins/baseTemplate';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'ReaffirmWsopOccupation',
  lexicon: 'onBoarding',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  props: {
    formTag: { type: String, default: null },
    formClass: { type: String, default: null },
  },
  data() {
    return {
      occupation: null,
      occupationList: null,
      occupationLabel: `${this.$t('_.occupation')}`,
      employerNameLabel: `${this.$t('_.employerName')} ${this.$t('optional')}`,
      employerAddressLabel: `${this.$t('_.employerAddress')} ${this.$t('optional')}`,
      businessTelephoneLabel: `${this.$t('_.businessTelephone')} ${this.$t('optional')}`,
      TrimTextInput,
      SearchDropSelect
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    occupationPreset() {
      return {
        list: this.occupationList,
        inputPlaceholder: this.$t('searchHere'),
      };
    },
    employerNamePreset() {
      return {maxLength: 50};
    },
    employerAddressPreset() {
      return {maxLength: 255};
    },
    businessPhonePreset() {
      return {maxLength: 14, telephone: true};
    },
  },
  methods: {
    async getOccupationType() {
      let r;
      try {
        r = await this.$services.onboarding.getAllOccupationType();
      } catch (e) {
        r = e;
      }

      if (r.error) {
        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;
      }

      return r;
    },
    async initialize() {
      const r = await this.getOccupationType();
      const allOccupation = r.OccupationTypes;
      this.occupationList = allOccupation?.filter(type => type !== 'None').map(type => ({ label: this.$t(`occupation.${type}`), value: type }));

      if (this.modelInfo.OccupationType && this.modelInfo.OccupationType !== 'None') {
        const occupation = allOccupation && allOccupation.find(occupation => occupation === this.modelInfo.OccupationType);
        this.occupation = occupation ? { label: this.$t(`occupation.${occupation}`), value: occupation } : null;
      }
    },
    updateOccupation(item) {
      if (!item) return;
      this.occupation = item;
      this.modelInfo.OccupationType = item.value;
    },
    formatPhoneNumber(v, isDelete) {
      const numRegexp = /[0-9]+/g;
      if (!numRegexp.test(v)) return '';
      const regexp = /([#]+)/g;
      let format = '(###) ###-####';
      const matches = format.match(regexp);
      const endChar = v.substr(-1);
      let val = v.match(numRegexp).join('');

      if (isDelete && format.replace(/#/g, '').includes(endChar)) {
        val = val.slice(0, -1);
      }

      if (val.length === 0) return '';

      let start = 0;
      matches.forEach(row => {
        format = format.replace(row, val.substr(start, row.length));
        start += row.length;
      });

      v = format;
      this.modelInfo.BusinessPhoneNumber = v;
    },
    deletePhoneNumber() {
      this.formatPhoneNumber(this.modelInfo.BusinessPhoneNumber, true);
    },
    updatePhoneNumber(v) {
      this.formatPhoneNumber(v);
    },
  },
  async created() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reaffirm-wsop-occupation] {
  >*:not(:nth-of-type(1)) { .mt(8); }
}
</style>