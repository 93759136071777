<template>
  <div mfa-modal-template>
    <div class="mfa-content">
      <p class="mfa-title">
        <slot name="title" />
      </p>
      <slot name="content" />
      <p class="mfa-description">
        <slot name="description" />
      </p>
    </div>
    <div class="mfa-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MfaModalTemplate'
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[mfa-modal-template] {.flex(); .flex-dc(); flex: 1; .rel();
  .mfa-content {.flex(); .flex-dc(); .gap(10); flex: 1; .pt(10); .items-center();}
  .mfa-title {.fs(14); text-align: center; .c(black); .w(100%); font-weight: 500; line-height: 16px;}
  .mfa-description {.fs(12); .c(#737373); text-align: center; line-height: 16px; .pt(10);}
  .mfa-footer {.flex(); .gap(10); .pb(10); .w(100%); .b(0); }
}
</style>