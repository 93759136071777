<template>
  <div drop-select ref="dropSelect" :class="[`no-drag`, { focus, error: error && !Boolean(dropListBox), readonly, disabled,clearable, open: Boolean(dropListBox), yet: !value, }, theme]" tabindex="1" @focus="toggleOpen">
    <a @click="toggleOpen">
      <input type="text" :class="['no-drag', variant && `btn-${variant}`]" :value="textValue" :placeholder="placeholder" readonly="readonly" :disabled="disabled" />
    </a>
    <input :name="name" type="hidden" :value="value" />
    <p class="angle" v-if="!disabled">
      <em class="icon angle" />
    </p>
    <button class="input-btn-x" v-if="selected && !readonly && clearable" @click="remove">
      <FontIcon name="close" />
    </button>
    <div class="list-holder"></div>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import SvgIcon from '@shared/components/common/SvgIcon.vue';
import dropSelect from '@shared/mixins/dropSelect';
import DropListBox from '@shared/components/common/input/DropListBox.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'DropSelect',
  components: { FontIcon, SvgIcon },
  mixins: [dropSelect],
  props: {
    obj: { default: null },
    labelValue: { type: Boolean, default: false },
    clearable: Boolean,
  },
  data() {
    return {};
  },
  mounted() {
    this.eventListenerHandler();
    this.update(true);
  },
  destroyed() {
    this.removeDropList();
    this.eventListenerHandler(true);
  },
  watch: {
    list: 'update',
    obj: 'update',
    value: 'update',
  },
  computed: {
    textValue() {
      const v = this.selected && (this.labelValue ? this.selected.value : this.selected.label || this.selected);
      return this.translate ? this.$t(v) : v;
    },
    listCreated() {
      let list;

      if (this.obj) {
        list = Object.entries(this.obj).map(([key, value]) => ({ label: value, value: key }));
      } else {
        list = this.split ? this.split.split(/[ ]*,[ ]*/) : this.list;
      }

      if (this.useBlank && list) {
        list = [...[{ value: null, label: this.placeholder || '-' }], ...list];
      }

      return list;
    },
  },
  methods: {
    toggleOpen() {
      if (this.disabled) return;

      if (this.dropListBox) this.removeDropList();
      else {
        this.$emit('focus');
        this.createDropList();
      }
    },
    createDropList() {
      if (this.readonly) return;
      this.removeDropList();
      this.dropListBox = new (Vue.extend(DropListBox))({
        parent: this.$root,
        propsData: {
          callComponent: this,
          className: this.variant,
          list: this.listCreated,
          value: this.selected?.value,
          theme: this.theme,
          translate: this.translate
        },
      }).$mount(this.$el.querySelector('.list-holder'));

      this.focus = true;
    },

    change(val, artificial) {
      if (this.auto) this.removeDropList(true);

      const selectedCandidate = (!this.placeholder.length && (val === undefined || val === null)) ? this.selected : val;
      const v = selectedCandidate && selectedCandidate.label ? selectedCandidate.value : selectedCandidate;
      if (artificial) this.$emit('select', this.value);
      this.selected = selectedCandidate;
      if (this.value === v) return;

      if (v !== undefined || this.ready) this.$emit('input', v);
      if (artificial) this.$emit('change', v);
      this.ready = true;
    },
    update() {
      let val = '';
      const list = this.listCreated;

      if (list) {
        val = _.find(list, item => item.value === this.value || item === this.value);
        if ((val === null || val === undefined) && !this.placeholder) {
          [val] = list;
        }
      }

      this.change(val);
    },
    remove() {
      this.selected = null;
      this.$emit('input', null);
      this.removeDropList(true);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

.error [input-drop-select]:before { .cnt; .abs; .lb(0, 0); .z(2); .w(100%); .-b(@c-red); }

[drop-select] { .crop; .wh(100%, 34); .-box; .pointer; .rel; .br(4); .pointer; .-a(@c-b01); .bgc(white);
  > .angle { .wh(8); .abs; .rt(10, 45%); .z(1); .t-yc; .flex-center;
    .icon { .-t(#333, 2); .-r(#333, 2); .t-r(135deg); .wh(100%);}
  }
  &.error {
    &::before { .cnt; .abs; .lb(0, 0); .z(2); .w(100%); .-b(@c-red); }
  }
  &.clearable {
    input[type='text'] { .pr(70); }
  }
  input[type='text'] { .rel; .z(2); .wh(100%); .pl(12); .pr(24); .tl; .fs(14, 50px); .regular; .c(@c-b07); .ellipsis(); .bgc(transparent); .pointer;
    &::placeholder { .c(@c-placeholder); }
    &:disabled {
      &::placeholder { .c(@c-b05);}
    }
  }

  &.open {
    input[type='text'] { .br-t(12); .-b; }
    > .angle { .t(55%);
      .icon { .t-r(315deg); }
    }
  }
  &.readonly { .bgc(#eee);
    input[type='text'] { pointer-events: none;cursor: default;}
  }
  &.product {
    input[type='text'] { .fs(14, 34); background: #2a2a2a; color: #fff; }
  }
  &.disabled {
    input { .bgc(@c-b01);}
  }

  &.black, &.dark { .bgc(transparent); .-a;
    input[type='text'] { .c(white); .fs(18); .medium();
      &::placeholder { .c(@c-w03); .regular(); }
    }
    > .angle { .bgc(transparent);
      .icon { .-t(@c-w05, 2); .-r(@c-w05, 2); }
    }
  }
}

.requested [drop-select].error {
  .-a(#f00) !important;
}

</style>
