export default {
  inserted(el, binding) {
    const options = {
      root: null,
      threshold: 0.2,
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          binding.value();
        }
      });
    }, options);

    observer.observe(el);
  },
};
