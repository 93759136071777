<template>
  <div gp-label-component>
    <div class="top">
      <label class="label">{{ label }}</label>
      <TextButton v-if="btnText" @click="$emit('click')" class="text-button">{{ btnText }}</TextButton>
    </div>
    <label class="value">{{ value || '-' }}</label>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import TextButton from '@/views/components/gg-pass/TextButton.vue';

export default {
  name: 'LabelComponent',
  components: { TextButton, FontIcon },
  props: {
    value: Specific,
    label: { type: String, default: null },
    btnText: { type: String, default: null },
    isVerified: { type: Boolean, default: false, },
  },
};
</script>

<style lang="less">
@import '@/less/proj.less';
[gp-label-component] {.c(#000); .flex(); .flex-dc(); .gap(0);
  .top {.flex(); justify-content: space-between;
    .label {.c(@gp-component-label); .fs(13);}
    > [gp-text-button] {
      > button {.fs(13); .c(@gp-green); font-weight: 500; text-decoration-line: none;}
    }
  }
  .value {.fs(14); font-weight: 500; word-break: break-all;}
}
</style>