<template>
  <div error-title>
    <img src="/img/oops_daniel.png" alt="">
    <h2>{{$t(title)}}</h2>
    <p v-html="$t(description, options)"/>
  </div>
</template>

<script>
import Specific from "@shared/types/Specific";

export default {
  name: "ErrorTitle",
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
    options: Specific
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[error-title] { .tc(); .c(@c-w06); .mt(40);
  h2 { .mv(12); .fs(22); .c(#fff); }
}
</style>