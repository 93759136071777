<template>
  <div not-support-region v-if="availableRegionInfo">
    <ErrorTitle title="accessDeny.title" description="accessDeny.description" :options="{name: ChannelingName}"/>
    <div class="support-list">
      <div class="group" v-for="group in Object.keys(availableRegionInfo)" :key="group">
        <p>{{ group }}</p>
        <ul>
          <li v-for="(country, index) in availableRegionInfo[group]" :key=index>
            <p class="name">{{ country[0] }}</p>
            <p class="lang">{{ country[1] }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorTitle from "@/views/components/extra/ErrorTitle.vue";
import { parseQuery } from '@shared/utils/urlUtils.mjs';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'NotSupportRegion',
  components: { ErrorTitle },
  computed: {
    env: state('env', 'env'),
  },
  data() {
    return {
      availableRegionInfo: null,
      ChannelingName: null,
    };
  },
  methods: {
    async initialize() {
      const queryItems = parseQuery(location.search);
      const isLocal = this.env === 'local';

      if(isLocal && !queryItems['channelingUrl']) {
        await this.replaceRouteName('NotFound');
        return;
      }

      const r = await this.$services.sign.getSignupUrl({ domain: isLocal ? decodeURIComponent(queryItems['channelingUrl']) : location.href });

      const regions = /** @type {{group, groupItem1, groupItem2, regionKey, regionName}[]} */ r.channelingRegions;
      if(!regions?.length) return;

      const o = {};
      this.ChannelingName = r.channelingName;
      for (const region of regions) {
        if (this.env === 'production' && region.groupItem1 === 'Czechia') continue;
        if (region.group in o) o[region.group].push([region.groupItem1, region.groupItem2]);
        else o[region.group] = [[region.groupItem1, region.groupItem2]];
      }
      this.availableRegionInfo = { ...o };
    }
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[not-support-region] { .pb(30);
  .support-list { .mt(30); .p(16); .-a(@c-w03);
    .group { .p(0, 12);
      & + .group { .mt(10); }
      ul { .flex(); flex-flow: wrap; .mt(10); .c(@c-w06);
        li { flex-basis: 25%; .mb(16); .pr(10); .fs(14);
          p { .fs(inherit); }
          .lang:before { .cnt('• '); .fs(1.4em); }
        }
      }
    }
  }
  @media (@mm-down) {
    .support-list .group ul li { flex-basis: 50%; }
  }
}
</style>