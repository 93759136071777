<template>
  <SumsubContainer sumsub-pending>
    <FontIcon name="auto-refresh" />
    <h3 v-html="$t('_.title')" />
    <p>
      {{$t('_.description1')}}<br />
      {{$t('_.description2')}}
    </p>
    <p v-html="$t('_.description3', {csEmail})" />
  </SumsubContainer>
</template>

<script>
import SumsubContainer from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubContainer.vue';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'SumsubPending',
  lexicon: 'onBoarding.sumsub.pending',
  components: { FontIcon, SumsubContainer },
  props: {
    item: {type: Specific, default: null},
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  mounted() {
    if (this.item) this.item.title = 'onBoarding.title.accountVerification';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sumsub-pending] { .tc();
  > [font-icon] { .block(); .mb(24); .p(6); .fs(48); animation: rotate-circle 2s linear infinite; .mt(24); }
  > h3 { .fs(22, 27, normal); .regular(); .mb(24); }
  > p { .tl(); .fs(18, 22, normal); .c(#7F7F7F);
    > a[href^='mailto'] { .c(#2680EB); .underline(); }
  }
  > p + p { .mt(22); }
}
</style>
