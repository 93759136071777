<template>
  <RSvg svg-icon :src="path" />
</template>

<script>
import RSvg from '@shared/components/common/RSvg.vue';

export default {
  name: 'SvgIcon',
  components: { RSvg },
  props: {
    type: { type: String, default: 'solid' },
    name: { type: String, default: null, required: true },
    defaultPath: { type: String, default: null },
  },
  computed: {
    path() {
      return this.defaultPath ? `${this.defaultPath}/${this.name}.svg` : `/svgs/${this.type}/${this.name}.svg`;
    }
  },
};
</script>

<style lang="less">
@import "~@shared/less/proj.less";
[svg-icon] { .wh(20); }
</style>
