<template>
  <BaseModal np-bank-info-modal :show-title="false">
    <template #content>
      <div class="modal-body">
        <div class="content">
          <p v-html="message" />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal-footer">
      <GpButton type="line-green" @click="close">{{ $t('cancel') }}</GpButton>
      <GpButton type="submit-green" @click="redirect" :progress="progress">{{ $t('ok') }}</GpButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>

import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'BankInfoModal',
  components: { GpButton, GpDescription, BaseModal },
  data() {
    return {
      progress: false,
    };
  },
  computed: {
    message(){
      return this.$t('myInfo.bankInfoModalMessage');
    }
  },
  methods: {
    close() {
      this.$modalCloseAll();
    },
    async redirect() {
      this.progress = true;
      await sleep(300);
      this.$myinfo.redirectKycUrl({ redirectPath: 'np-personal-info', lang: this.$i18n('lang'), customStep: 'VerifySurePay' });
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-bank-info-modal] {
  > .panel { .rel(); overflow: visible;
    .scroll-area {.h(calc(100% - 59px)); .flex(); .flex-dc(); overflow-y: visible;
      > .body { flex: 1; }
    }
  }
  .modal-body {.flex(); .flex-dc(); .gap(10); .justify-center(); .items-center();
    .icon {.fs(30); .c(@c-red);
      &.success {.c(@gp-green);}
    }
    .content { .ph(20); .w(100%);
      > p {.pt(0); .pb(20); text-align: center; .fs(14); .c(#000);}
    }
  }
  .modal-footer {.flex(); .gap(10);}
}
</style>
