import { payletterStatus } from '@/constants/base/onboarding/kyc';

export default class PayletterController {
  #scope;
  #site;
  #lang;
  #services;
  #callback;
  #modelInfo;
  #status;
  #token;
  #intervalId;
  #intervalMilliSecond;
  #opener;

  constructor(scope, { services, site, lang, callback, modelInfo }) {
    this.#scope = scope;
    this.#modelInfo = modelInfo;
    this.#services = services;
    this.#site = site;
    this.#lang = lang;
    this.#callback = callback;
    this.#intervalMilliSecond = 3 * 1000;
    this.#restart();
  }

  /**
   * payletter popup open url 조회
   * @returns {Promise<PayletterVerificationUrl>}
   */
  async #getUrl(method) {
    const path = this.#services.store.state.env.env === 'local' ? '/onboarding' : '/app';
    const redirectUrl = `${location.origin}${path}/receiver-payletter`;
    const r = await this.#services.kyc.verifyPayletter(method, redirectUrl, {silent: true});
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * payletter 상태 조회
   * @returns {Promise<KycTwoAceVerificationStatus>}
   */
  async #getStatus() {
    const r = await this.#services.kyc.getPayletterStatus(this.#token, {silent: true});
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * error 발생 시 에러 코드 별 처리 분기
   * @param {object} r - error 정보
   */
  #errorHandler(r) {
    this.#clearPollingInfo();
    this.#callbackInfo(payletterStatus.Failed);
  }

  /**
   * 초기화
   * @returns {Promise<void>}
   */
  async #initialize() {
    this.#clearPollingInfo();
    this.#status = null;
  }
  /**
   * 진행중인 payletter 현황 정보 반환
   * @returns {{statusInfo, lang, accessToken, applicantId, status}}
   */
  #getInfo() {
    return { lang: this.#lang, status: this.#status };
  }

  /**
   * Polling 관련 정보 초기화
   */
  #clearPollingInfo() {
    this.#intervalId && clearInterval(this.#intervalId);
    this.#intervalId = null;
  }
  /**
   * payletter 진행 상태 반복 조회
   * 3초 마다 반복 호출을 하여 상태 Polling
   * @returns {Promise<void>}
   */
  #pollingStatus() {
    this.#intervalId = setInterval(async () => this.#callbackInfo(), this.#intervalMilliSecond);
  }

  /**
   * payletter status 및 정보 저장
   * @param {string} status - 설정할 payletter status
   */
  #setInfo(status) {
    if(status !== this.#status) this.#status = status;
  }
  /**
   * payletter 상태 값에 따른 화면 전환 처리
   * @returns {Promise<void>}
   */
  async #callbackInfo(status = null) {
    let r;
    let tempStatus = status;
    if (!tempStatus) {
      r = await this.#getStatus();
      if (r?.error) return;
      tempStatus = r.Status;
    }

    if (this.#status !== tempStatus) {
      if (this.#existPopup()) {
        if (tempStatus === payletterStatus.Waiting) this.#pollingStatus();
        else this.#clearPollingInfo();
      }
    } else {
      if (!this.#existPopup()) {
        this.#clearPollingInfo();
        if (this.#status === payletterStatus.Waiting) tempStatus = payletterStatus.Ready;
      }
    }

    console.log('#callbackInfo', { status: tempStatus, existPopup: this.#existPopup() });

    this.#setInfo(tempStatus);
    this.#callback(this.#getInfo());
  }

  /**
   * payletter 단계 초기화
   */
  #restart() {
    this.#initialize();
  }
  /**
   * popup 유무 체크
   * @returns {boolean}
   */
  #existPopup() {
    return this.#opener && !this.#opener.closed;
  }
  /**
   * payletter open popup
   * @param {number} method - 0 : mobile, 1 : creditCard
   * @param {boolean} isMobile - mobile 여부
   * @returns {Promise<void>}
   */
  async openPopup(method, isMobile) {
    const r = await this.#getUrl(method);
    if (r?.error) return;

    this.#token = r.PayletterToken;
    const height = window.innerHeight < 820 ? window.innerHeight : 820;
    this.#opener = window.open(isMobile ? r.MobileUrl : r.PcUrl, 'PayletterVerification', `status=no,width=530,height=${height},resizable=yes,scrollbars=yes`);

    if (!this.#existPopup()) this.#callbackInfo(payletterStatus.PopupBlocked);
    else this.#callbackInfo();
  }

  /**
   * 초기화
   */
  initialize() {
    this.#initialize();
  }
}
