<template>
  <div modal :class="theme">
    <div class="dim" @click="dimClick"></div>
    <div class="panel" :class="size">
      <div class="header">
        <slot name="title" />
        <a class="close" @click="close" v-if="dimClose">
          <FontIcon name="close" />
        </a>
      </div>
      <div class="scroll-area">
        <slot />
      </div>
      <slot name="floating" />
    </div>
  </div>
</template>

<script>
import modal from '@shared/mixins/modal';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'Modal',
  components: { FontIcon },
  mixins: [modal],
  watch: {
    $route() {
      if (!this.noRouteClose) this.$emit('close');
    },
  },
  props: {
    theme: { type: String, default: 'light' },
    size: { type: String, default: 'large' },
    noRouteClose: { type: Boolean, default: false },
    dimClose: { type: Boolean, default: true },
  },
  methods: {
    dimClick() {
      if (this.dimClose) this.close();
    }
  }
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

[modal] { .abs; .f; .flex-center;
  .tc { .tc(); }
  .tr { .tr(); }
  .tl { .tl(); }
  [grid-table] {
    thead {
      th { .bgc(@c-b01); .fs(14);}
    }
  }
  > .dim { .abs; .f; .bgc(@c-b07); .lt; }
  > .panel { .rel; .br(20); .wh(100%, auto); .crop; box-shadow: 0 3px 6px 0 rgb(0, 0, 0, 0.5); .max-h(100%); .pb(20);
    > .header { .p(40, 20, 0); .rel; .bgc(@c-link); .p(20); .c(#fff); .bold(); .fs(20);
      &:nth-of-type(1) { .c(white); .tl(); }
      .close { .abs; .rt(20, 20); .z(100);
        [font-icon] { .block; .c(white); }
      }
    }
    .btn-close { .pt(12); .fr(); .mr(25);
      button { .c(#fff); .bgc(#b3bbc2); .-a(#a5b2b4); .p(9); .br(4); }
    }

    .bts { .flex-list(); .mt(32);
      button {.h(40); .fs(16, 38); .br(24); .max-w(50%); .mh-c();
        &:not(:nth-of-type(1)) { .ml(14); }
      }
    }
    > .scroll-area { .p(0, 20); overflow-y: auto;}
    &.normal { .p(0, 20);
      > .header { .p(22, 0, 12); .-b(@c-base-gray); .mb(15);
        .close { .r(0);}
        h2 {.fs(16);}
      }
      .scroll-area { .p(0, 0, 30); }
    }
    &.medium { .pb(40);
      .scroll-area { .p(0, 26); }
    }
  }
  &.light {
    > .panel { .bgc(#fff);
      > .header {
        .close {
          [font-icon] { .c(#191919); }
        }
      }
    }
  }

  &.dark {
    > .panel { .bgc(#161a1d); .br(4); .pb(0);
      > .header { .bgc(transparent); .-b(@c-w03); .fs(16); .p(12, 16);
        .close { .rt(12, 12);
          [font-icon] { .c(#fff); }
        }
      }
      .scroll-area { .c(#aeaeae); .tl(); .fs(14, 24); .medium(); .pv(24);}
    }
  }

  &.my-white {
    > .panel { .br(16); box-shadow: none; .bgc(#F7F7F8); .pb(0); .mh(20); .max-w(460);
      > .header { .pb(0); .bgc(transparent); .flex(); .justify-center(); .items-end();
        > p { .c(black); font-weight: 500; .pt(15); .tc(); }
        > a {
          .t(10); .r(10);
          > [font-icon] { .c(black); }
        }

      }
      .scroll-area { .c(#aeaeae); .tl(); .fs(14, 24); .pt(16); .pb(24);}
    }

    @media (max-width: 300px) {
      > .panel {.mh(0);}
    }
  }

  &.transparent {
    > .panel.large { .bgc(transparent); box-shadow: none; .br(0);
      > .header { .p(20, 0);
        .close { .rt(0, 24); }
        [font-icon] { .ib(); .w(20); .lh(20); .bgc(@c-base-gray); .br(4); .fs(18); .c(@c-text-gray); .tc(); }
      }
      .scroll-area {.p(0);}
    }
  }

  @media (@ml-up) {
    .p(10);
  }
  @media (@tp-up) {
    > .panel.large { .w(460);
    }
  }
  @media (@tl-up) {
  }
}
</style>
