<template>
  <div reaffirm-de-contact-email>
    <p v-html="$t('onBoarding.reaffirm.de.guideEmailVerify')" />
    <p v-html="$t('onBoarding.reaffirm.de.descriptionEmailVerify', {userEmail: modelInfo.EmailMasked} )" />
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'ReaffirmDeContactEmail',
  mixins: [baseTemplate],
  data() {
    return {
      captcha: null,
      captchaPass: false,
    };
  },
  methods: {
    async loadReCaptcha() {
      await new Promise(resolve => {
        grecaptcha.enterprise.ready(async() => {
          this.captcha = await this.$services.captcha.recaptchaEnterprise(this.action);
          resolve();
        });
      });
    },
    /**
     * 컴포넌트 내에서의 api request 처리
     * @returns {Promise<*|null>}
     */
    async initialize() {
      this.modelInfo.SendVerificationType = 'EMAIL';
      this.item.title = 'onBoarding.reaffirm.de.title';

      this.captchaPass = this.isStageOrProd;
      if (this.captchaPass) await this.loadReCaptcha();
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reaffirm-de-contact-email] {}
</style>
