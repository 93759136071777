<template>
  <div gp-badge :class="[classType, {'outline' : outline}]">
<!--    <span></span>-->
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'BtnBack',
  props: {
    type: { type: String, default: 'PENDING' }, // "PENDING" | "SUCCESS" | "FAILED" | "EXPIRED"
    outline: { type: Boolean, default: false, }
  },
  computed: {
    classType() {
      switch (this.type) {
        case 'PENDING' :
          return 'pending';
        case 'SUCCESS' :
          return 'success';
        case 'FAILED' :
          return 'failed';
        case 'EXPIRED' :
          return 'expired';
        case 'REQUEST':
          return 'request';
        case 'APPROVAL' :
          return 'approval';
        case 'OFF' :
          return 'off';
        case 'LOW' :
          return 'low';
        case 'MEDIUM' :
          return 'medium';
        case 'HIGH' :
          return 'high';
      }
    }
  },

};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-badge] { .fs(12); .p(0, 10, 0, 10); .br(30); .flex(); .gap(5); .justify-center(); .items-center(); .max-h(30);
  > span {.wh(6); .br(100);}
  &.success {.bgc(#CEEDD3); .c(@gp-green);
    > span {.bgc(@gp-green);}
  }
  &.failed {.bgc(#FFEAEA); .c(#ED4646);
    > span {.bgc(#ED4646);}
  }
  &.pending {.bgc(#E2E2E2); .c(#737373);
    > span {.bgc(#737373);}
  }
  &.expired {.bgc(#E4E4E7); .c(#a3a3a3);
    > span {.bgc(#a3a3a3);}
  }
  &.request {.bgc(#FFEFC5); .c(#D97706); .p(0);
    > span {.bgc(#D97706);}
  }
  &.approval {.bgc(#FFEFC5); .c(#7082FA); .p(0);
    > span {.bgc(#7082FA);}
  }
  &.off {.bgc(#E4E4E7); .c(#A3A3A3);}
  &.low {.bgc(#FBE6E6); .c(#ED4646);}
  &.medium {.bgc(#FFEFC5); .c(#D97806);}
  &.high {.bgc(#CEEDD3); .c(#249135);}
  &.outline {
    background: none !important;
  }
}
</style>